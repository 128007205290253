import React, { useEffect, useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { useTheme } from 'styled-components';
import * as S from './styled';

/*
data: lista de objetos com
    {
        id: valor do id correspondente,
        value: string do nome do objeto selecionado
    }

fieldName: nome da variável armazenada. Substitui a necessidade de:
    > handleChange={(e) => handleChange(e.target.value, 'name')}
    por:
        > handleChange={handleChange}
        > fieldName: 'name'

value: ids selecionados. Ex: '1, 3'

*/

const SelectList = ({
  value,
  handleChange,
  data,
  fieldName,
  label,
  selectAll,
  disabled,
  labelBackgroundColor, // Usado quando a borda outline aparece por tras da label
  multiple = true,
  ...props
}) => {
  const theme = useTheme();
  const [backupItems, setBackupItems] = useState([]);
  const [search, setSearch] = useState('');
  const [names, setNames] = useState([]);
  const [ids, setIds] = useState([]);

  const handleSelect = id => {
    if (ids.includes(id)) {
      const updateSelect = [];
      for (const i in ids) {
        if (ids[i] !== id) {
          updateSelect.push(ids[i]);
        }
      }
      formtedItems(updateSelect);
    } else {
      const updateSelect = [...ids, id];
      formtedItems(updateSelect);
    }
  };

  const clearSelection = () => {
    setNames([]);
    setIds([]);
    handleChange('', fieldName);
  };

  const selectAllOptions = () => {
    const __IDS = [];
    const __NAMES = [];

    for (const i in data) {
      __IDS.push(data[i].id);
      __NAMES.push(data[i].value);
    }

    setNames(__NAMES);
    setIds(__IDS);
    const idsString = __IDS.join(',');
    handleChange(idsString || '', fieldName);
  };

  useEffect(() => {
    setBackupItems(data);
    // Obs: Se algum bug foi encontrado nesse componente, tente remover a dependência do useEffect
  }, [data]);

  useEffect(() => {
    let items = [];
    if (value) {
      items = value.split(',');
    }

    for (const i in items) {
      if (Number(items[i])) {
        items[i] = Number(items[i]);
      }
    }

    const __IDS = [];
    const __NAMES = [];

    for (const i in data) {
      if (items.includes(data[i].id)) {
        __IDS.push(data[i].id);
        __NAMES.push(data[i].value);
      }
    }

    setNames(__NAMES);
    setIds(__IDS);
  }, [value]);

  useEffect(() => {
    const searchedParams = [];
    for (const i in data) {
      const name = data[i].value.toLowerCase();
      if (name.includes(search)) {
        searchedParams.push(data[i]);
      }
    }
    if (searchedParams.length === 0) {
      setBackupItems(data);
    } else {
      setBackupItems(searchedParams);
    }
    setBackupItems(searchedParams);
  }, [search, value]);

  const formtedItems = items => {
    const __IDS = [];
    const __NAMES = [];

    for (const i in data) {
      if (items.includes(data[i].id)) {
        __IDS.push(data[i].id);
        __NAMES.push(data[i].value);
      }
    }

    setNames(__NAMES);
    setIds(__IDS);
    const idsString = __IDS.join(',');
    handleChange(idsString || '', fieldName);
  };

  const ITEM_HEIGHT = 60;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        minHeight: 250,
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <div>
      <FormControl sx={{ width: '100%' }}>
        <InputLabel
          style={{
            color: theme.palette.system.black,
            backgroundColor: labelBackgroundColor ?? undefined,
          }}
          id="demo-multiple-checkbox-label"
        >
          {label}
        </InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple={multiple}
          value={names}
          input={<OutlinedInput label={`${label}**`} />}
          renderValue={selected => selected.join(', ')}
          MenuProps={MenuProps}
          disabled={disabled}
        >
          <S.SearchBox>
            <S.SearchInput
              type="text"
              placeholder="Buscar..."
              onChange={e => setSearch(e.target.value.toLowerCase())}
            />
            <SearchOutlinedIcon
              className="searchIcon"
              htmlColor={theme.palette.semantics.feedback.unknown.natural}
            />
          </S.SearchBox>

          <S.LineBox>
            <>
              {!selectAll && (
                <S.ClearSelection onClick={() => clearSelection()}>
                  Limpar Seleção
                </S.ClearSelection>
              )}

              {selectAll && ids.length > 0 && (
                <S.ClearSelection onClick={() => clearSelection()}>
                  Limpar Seleção
                </S.ClearSelection>
              )}

              {selectAll && !ids.length > 0 && (
                <S.ClearSelection onClick={() => selectAllOptions()}>
                  Selecionar Todos
                </S.ClearSelection>
              )}
            </>
          </S.LineBox>

          <S.OptionsArea>
            {backupItems.length > 0 &&
              backupItems
                .slice(0, backupItems.length > 150 ? 150 : undefined) // Limita a quantidade de itens exibidos, pra evitar lentidão no componente
                .map(item => (
                  <FormControlLabel
                    key={`${item.id}-${item.value}`}
                    control={<Checkbox checked={!!ids.includes(item.id)} />}
                    onChange={() => handleSelect(item.id)}
                    label={item.value}
                    value="start"
                  />
                ))}
            {backupItems.length === 0 && (
              <S.EmptyOptions>Nenhuma opção disponível</S.EmptyOptions>
            )}
          </S.OptionsArea>
        </Select>
      </FormControl>
    </div>
  );
};

export default SelectList;
