import styled, { css } from 'styled-components';

const containerModifier = {
  normal: () => css`
    input {
      font-size: 20px;
      min-width: 320px;
    }

    span {
      font-size: 20px;
      margin-right: 10px;
    }
  `,

  small: () => css`
    input {
      font-size: 18px;
      min-width: 255px;
      background-color: ${({ theme }) => theme.palette.system.transparent};
    }

    span {
      font-size: 18px;
      margin-right: 5px;
    }
  `,
};

export const Container = styled.div`
  ${({ theme, type }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${theme.palette.words.title.natural};

    input {
      border: none;
      border: 1px solid ${theme.palette.brand.primary.light};
      border-radius: 4px;
      font-weight: 600;
      margin-left: 10px;
      margin-right: 10px;
      padding: 0px 3px;

      &::placeholder {
        font-size: 14px;
        color: ${theme.palette.words.text.light};
      }
    }

    .counter {
      font-size: 10px;
      margin-right: 13px;
    }

    span {
      font-weight: 600;
      margin-left: 10px;
      cursor: pointer;
    }

    ${!!type && containerModifier[type]}
  `}
`;
