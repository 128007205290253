import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import styled from 'styled-components';

const Progress = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  svg {
    color: ${props => props.color || props.theme.palette.system.backdrop};
  }
`;

const CircularIndeterminate = ({ color }) => {
  return (
    <Progress color={color}>
      <CircularProgress />
    </Progress>
  );
};

export default CircularIndeterminate;
