import * as types from './types';

export function updatePageMap(lastDate) {
  return {
    type: types.SET_PAGEMAP_REQUEST,
    lastDate,
  };
}

export function updatePageMapSuccess(query) {
  return {
    type: types.SET_PAGEMAP_SUCCESS,
    payload: { query },
  };
}

export function updatePageMapFailure() {
  return {
    type: types.SET_PAGEMAP_FAILURE,
  };
}

export function setCurrentPlan(currentPlan) {
  return {
    type: types.SET_CURRENTPLAN,
    payload: { currentPlan },
  };
}
