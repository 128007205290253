// Pages
import GraficosPad from 'pages/Estatisticas/Pad';
import GraficosFrota from 'pages/Estatisticas/Frota';
import GraficosAvalicoes from 'pages/Estatisticas/Avaliacao';
import GraficosDesvioDirecao from 'pages/Estatisticas/Desvios';
import GraficosMotoristas from 'pages/Estatisticas/Motoristas/_Old';
import GraficosLayout from 'pages/Estatisticas/LayoutDescarga';
import GraficosDesviosDescarga from 'pages/Estatisticas/DesvioDescarga';
import GraficosAvalicoesDescarga from 'pages/Estatisticas/AvaliacaoDescarga';
import GraficosViolacoesTelemetria from 'pages/Estatisticas/ViolacoesTelemetria';

export default {
  '/estatisticas-avaliacoes': {
    date: true,
    page: <GraficosAvalicoes />,
  },

  '/estatisticas-avaliacoes-descarga': {
    date: true,
    defaults: ['filials', 'clients'],
    page: <GraficosAvalicoesDescarga />,
  },
  '/estatisticas-desvio': {
    date: true,
    defaults: ['filials', 'clients'],
    page: <GraficosDesvioDirecao />,
  },
  '/estatisticas-desvio-descarga': {
    date: true,
    defaults: ['filials', 'clients'],
    page: <GraficosDesviosDescarga />,
  },

  '/estatisticas-violacoes-telemetria': {
    date: true,
    defaults: ['filials', 'clients'],
    page: <GraficosViolacoesTelemetria />,
  },

  '/estatisticas-frota': {
    defaults: ['filials', 'clients'],
    page: <GraficosFrota />,
  },

  '/estatisticas-motoristas': {
    date: true,
    defaults: ['filials', 'clients'],

    page: <GraficosMotoristas />,
  },

  '/estatisticas-pad': {
    date: true,
    defaults: ['filials', 'clients'],
    page: <GraficosPad />,
  },

  '/estatisticas-layout': {
    date: true,
    defaults: ['filials', 'clients'],
    page: <GraficosLayout />,
  },
};
