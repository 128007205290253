import _ from 'lodash';
import { useEffect } from 'react';
import { useTable } from 'components/_Table/context';
import { _setStarted } from 'components/_Table/context/actions';

const control = [
  'selected',
  'selectedDefault',
  'count',
  'cachePerPage',
  'started',
  'filters',
];

export const Dynamic = ({ setQuery, keys = [], sortBy = {} }) => {
  const { state, dispatch } = useTable();
  const filters = state.filters || [];
  const userFilter = {};
  filters?.forEach(filter => (userFilter[filter?.key] = filter?.value));

  const usualState = { ...state, ...userFilter };

  const hasKeys = Object.keys(keys).every(key => usualState[key]);

  const objKeys = new Set([
    ...Object.keys(_.pickBy(usualState, (_, key) => !control.includes(key))),
    ...(hasKeys ? [] : keys),
  ]);

  const objValues = [
    ...Object.values(_.pickBy(usualState, (_, key) => !control.includes(key))),
    ...(hasKeys ? [] : ['', '']),
  ].slice(0, objKeys.size);

  useEffect(() => {
    let userKeys = _.pickBy(usualState, (_, key) => !control.includes(key));
    usualState.started &&
      setQuery &&
      setQuery(
        userKeys.sortBy
          ? userKeys
          : {
              ...userKeys,
              sortBy: { ...sortBy, desc: sortBy?.order === 'DESC' },
            },
      );
    !usualState.started && dispatch(_setStarted(!!usualState.search));
  }, [...objKeys, ...objValues, state.started]);

  return <></>;
};
