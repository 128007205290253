/* eslint-disable import/order */

// Styleds
import * as S from './styled';

// React
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

// Components
import { ExportGeralPdf } from './Export';
import BarGraph from 'components/Graphs/BarGraph';
import PieGraph from 'components/Graphs/PieGraph';
import SelectList from 'components/Inputs/SelectList';
import EmptyDataCard from 'components/Cards/EmptyDataCard';

// Services
import * as services from '../services';

// Actions
import { fequencyModule } from '../actions';

// Utils
import { formatNewDate } from 'utils/dates';

let timer = null; // Timer period

export const General = ({ printRefPdf }) => {
  // Redux
  const { id, geral, publico } = useSelector(state => state.conteudoProvider);

  // General States
  const [data, setData] = useState([]);
  const [aderencia, setAderencia] = useState([]);
  const [reprovacoes, setReprovacoes] = useState([]);
  const [firstFetch, setFirstFetch] = useState(true);
  const [selectedCompanies, setSelectedCompanies] = useState('');
  const [allTransportadoras, setAllTransportadoras] = useState([]);

  // ---------------------------------------REQUESTS---------------------------------------//

  const { refetch, isFetching: loading } = useQuery(
    [`/content-reports-${id}`, 1],
    () =>
      id &&
      services.getGraphs(id, {
        id_empresa: selectedCompanies ? selectedCompanies.split(',') : null,
      }),
    {
      refetchOnWindowFocus: false,
      onSuccess: data => {
        setData(data?.data);
        setAllTransportadoras(data?.data?.transportadoras);
      },
      onError: data => toast.error(data?.message),
    },
  );

  // -------------- Infos Graphs
  useEffect(() => {
    const graph = data?.graficos;

    // Aderencia
    let formatedAderencia = [
      {
        name: 'Concluídos',
        value: graph?.status[0]?.value,
      },
      {
        name: 'Em andamento',
        value: graph?.status[2]?.value,
      },
      {
        name: 'Não Iniciados',
        value: graph?.status[3]?.value,
      },
      {
        name: 'Reprovados',
        value: graph?.status[1]?.value,
      },
    ];
    setAderencia(formatedAderencia);

    // Reprovacoes
    let formatedReprovacoes = [
      {
        name: 'Reprovados',
        value: graph?.reprovacoes[0]?.value,
      },
      {
        name: 'Aprovados',
        value: graph?.reprovacoes[1]?.value,
      },
    ];
    setReprovacoes(formatedReprovacoes);
  }, [data]);

  useEffect(() => {
    if (!firstFetch) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        refetch();
      }, 700);
    }
    setFirstFetch(false);
  }, [selectedCompanies]);

  // --------------------------------------- RENDER ---------------------------------------//
  return (
    <>
      <S.InfoLine>
        <div className="period">{fequencyModule(geral)}</div>
        {allTransportadoras?.length && (
          <div className="period">
            <SelectList
              label="Filtrar por Empresa"
              data={
                allTransportadoras?.map(item => ({
                  id: item.id,
                  value: item.nome,
                })) || []
              }
              value={selectedCompanies}
              fieldName="empresas"
              handleChange={value => setSelectedCompanies(value)}
              style={{ minWidth: '180px' }}
            />
          </div>
        )}
      </S.InfoLine>
      <S.Container>
        <S.TotalBox>
          {(loading || new Date() >= new Date(geral?.data_inicio)) && (
            <BarGraph
              data={aderencia}
              colors="critical"
              title="Aderência do Conteúdo"
              tooltip
              loading={loading}
            />
          )}
          {new Date() < new Date(geral?.data_inicio) && !loading && (
            <EmptyDataCard
              image="frota.png"
              title="Conteúdo não iniciado!"
              subtitle={`Retorne a partir do dia ${formatNewDate(
                geral?.data_inicio,
              )} e veja o histórico de realização do conteúdo`}
            />
          )}
        </S.TotalBox>

        <S.MediumBox className="bar">
          <PieGraph
            data={aderencia}
            colors="critical"
            title="Aderência do Conteúdo"
            tooltip
            legend
            loading={loading}
          />
          <PieGraph
            data={reprovacoes}
            colors="critical-duo"
            title="Reprovacões"
            tooltip
            legend
            loading={loading}
          />
        </S.MediumBox>

        <ExportGeralPdf
          cont={geral}
          printRef={printRefPdf}
          aderencia={aderencia}
          reprovacoes={reprovacoes}
        />
      </S.Container>
    </>
  );
};
