import { isSameDay, format } from 'date-fns';

const staticRangeHandler = {
  range: {},
  isSelected(range) {
    const definedRange = this.range();
    return (
      isSameDay(range.startDate, definedRange.startDate) &&
      isSameDay(range.endDate, definedRange.endDate)
    );
  },
};

function createStaticRanges(ranges) {
  return ranges.map(range => ({ ...staticRangeHandler, ...range }));
}

// Períodos
export const defaultStaticRanges = ranges => {
  const values = ranges
    .map(range => ({
      label: format(new Date(range.value[0]), 'dd/MM/yyyy'),
      range: () => ({
        startDate: range.startDate,
        endDate: range.endDate,
        isLabel: true,
      }),
    }))
    ?.reverse()
    ?.filter((_, idx) => idx < 9)
    ?.reverse();

  return createStaticRanges(values);
};
