import api from 'services/api';

export const requestUsers = async () => {
  const res = await api.get('/configuracoes/usuarios');
  return res.data;
};

export const requestUser = async id => {
  const res = await api.get(`/configuracoes/usuarios/${id}`);
  if (res.status === 200) {
    return res.data?.data;
  }
  throw res.data;
};

export const updateUser = async data => {
  const res = await api.put('/configuracoes/user', data);
  return res?.data ?? res;
};

export const createUser = async data => {
  const res = await api.post('/configuracoes/userExists', data);
  return res;
};

export const requestLogs = async id => {
  const res = await api.get(`/configuracoes/log-usuario/${id}`);
  return res;
};

export const requestNiveis = async () => {
  const res = await api.get(`/niveis-usuarios`);
  return res.data?.data ?? [];
};
