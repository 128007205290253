import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// Components
import Loading from 'components/Loading';
import GhostButton from 'components/Buttons/Ghost';
import DefaultButton from 'components/Buttons/Default';

// Utils
import { useTheme } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { changeHeaderStatus } from 'store/modules/header/actions';
import * as services from './services';
import { itensBar } from './Sidebar/constants';

import { Sidebar } from './Sidebar';
import * as S from './styled';

const TemplateEvaluation = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const user = useSelector(state => state.auth?.user?.user);
  const userLevel = user.nivel;

  // Navigation Elements
  const navigate = useNavigate();
  const { id } = useParams();

  // Page Controllers
  const [changed, setChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [title, setTitle] = useState('Informações Gerais');
  const [recharge, setRecharge] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [saveImg, setSaveImg] = useState(false);
  const [initialDate, setInitialDate] = useState(null);
  const [finalDate, setFinalDate] = useState(null);

  // Request Content
  // O conteudo de cada body em request PUT/POST é setado em Data,
  // pelo componente que faz a request
  const [data, setData] = useState('');

  const [evaluation, setEvaluantion] = useState(null);

  // -----------------------------------RESQUEST--------------------------------------//

  useEffect(() => {
    if (recharge) {
      getEvaluation(id);
    }
  }, [recharge]);

  const getEvaluation = async id => {
    setLoading(true);
    const res = await services.requestEvaluation(id);

    if (res.success === false) {
      toast.error(res.message);
      return;
    }

    const ev = res.data.evaluation;
    const dataEval = res.data.evaluation.hora_inicial;

    await services.viewEvaluation(ev.id);
    if (ev.status === 'ASSINADA' || ev.status === 'PREENCHIDA') {
      setDisabled(true);
      toast.warning(
        'Avaliações assinadas ou preenchidas não podem ser modificadas',
      );
    } else {
      setDisabled(false);
    }

    if (userLevel > 3) setDisabled(true);

    const day = new Date(dataEval).getDay();

    // Inicio da semana da avaliaçao
    const beforeDate = new Date(dataEval).setDate(
      new Date(dataEval).getDate() - day,
    );
    setInitialDate(new Date(beforeDate).toDateString());

    // Fim da semana da avaliaçao
    const afterDate = new Date(beforeDate).setDate(
      new Date(beforeDate).getDate() + 6,
    );
    setFinalDate(new Date(afterDate).toDateString());

    if (ev.status === 'ARQUIVADA') {
      toast.error(
        `Avaliação ${ev.status}. Não foi possível recuperar os dados.`,
      );
      navigate('/*', { replace: true });
    } else {
      setEvaluantion(ev);
      setLoading(false);
    }
    setRecharge(false);
  };

  const updateInfos = async nextPage => {
    let res = null;

    switch (page) {
      // TODAS AS CHAMDAS DE REQUISIÇÕES PELO BOTAO INFERIOR DA PAGE ACONTECEM NESSE SWITCH
      // OS SERVICES DE UPDATE DEVEM SER CHAMADOS NESSA PAGINA
      case 0:
        res = await services.updateHeaderEvaluation(evaluation?.id, {
          event: 'updateHeader',
          data,
        });
        break;
      case 2:
        res = await services.updateEvidences(evaluation?.id, {
          event: 'updateEvidence',
          data,
        });
    }

    if (res.data.success) {
      toast.success(res.data.message);
      setChanged(false);
      setRecharge(true);
    } else {
      toast.error(res.data.message);
    }

    if (nextPage) {
      goNextPage();
    }
  };

  // -----------------------------------CONTROLLLER PAGES-----------------------------//
  const handleBackPage = () => {
    if (!page) {
      navigate(-1);
    } else {
      setPage(page - 1);
      setTitle(itensBar[page - 1].page);
    }
    setChanged(false);
  };

  const goNextPage = () => {
    setPage(page + 1);
    setTitle(itensBar[page + 1].page);
    setChanged(false);
  };

  const changePage = () => {
    const { Component } = itensBar.find(item => item.id === page);
    return (
      <Component
        evaluation={evaluation}
        setChanged={setChanged}
        setPage={setPage}
        setTitle={setTitle}
        setData={setData}
        setLoading={setLoading}
        setRecharge={setRecharge}
        disabled={disabled}
        saveImg={saveImg}
        setSaveImg={setSaveImg}
        initialDate={initialDate}
        finalDate={finalDate}
      />
    );
  };

  // -----------------------------------STATUS STYLE-----------------------------//
  useEffect(() => {
    const status = evaluation?.status;
    switch (status) {
      case 'PENDENTE':
        dispatch(
          changeHeaderStatus({
            status,
            color: theme.palette.semantics.feedback.attention.natural,
            background: theme.palette.semantics.feedback.attention.light,
          }),
        );
        break;
      case 'PREENCHIDA':
        dispatch(
          changeHeaderStatus({
            status,
            color: theme.palette.semantics.feedback.warning.natural,
            background: theme.palette.semantics.feedback.warning.light,
          }),
        );
        break;
      case 'ASSINADA':
        dispatch(
          changeHeaderStatus({
            status,
            color: theme.palette.semantics.feedback.information.natural,
            background: theme.palette.semantics.feedback.information.light,
          }),
        );
        break;
      default:
        return {};
    }
  }, [evaluation]);

  return (
    <S.Container>
      <Sidebar
        page={page}
        setPage={setPage}
        setTitle={setTitle}
        setChanged={setChanged}
      />
      <S.TemplatePage>
        <S.HeaderPage>
          <div className="titlePage">{title}</div>
          <div className="leftHeader">
            <DefaultButton
              onClick={() =>
                navigate(`/avaliacoes-descarga/pdf/${evaluation?.id}`)
              }
              loading={loading}
            >
              Imprimir
            </DefaultButton>
          </div>
        </S.HeaderPage>
        {loading && (
          <S.LoadingBox>
            <Loading />
          </S.LoadingBox>
        )}
        {!loading && evaluation && changePage()}
      </S.TemplatePage>
      {!loading && page !== 5 && page !== 4 && (
        <S.ControllerArea>
          <div className="statusArea">
            {changed && 'Alterações não salvas'}
            {!changed && 'Informações Atualizadas'}
          </div>

          <div className="buttonsArea">
            {page !== 0 && (
              <GhostButton
                className="backArea"
                onClick={() => handleBackPage()}
              >
                Voltar
              </GhostButton>
            )}
            {changed && (
              <DefaultButton
                className="backArea"
                onClick={() => updateInfos(false)}
                disabled={disabled}
              >
                Salvar
              </DefaultButton>
            )}

            {changed && !disabled && (
              <DefaultButton
                onClick={() => updateInfos(true)}
                disabled={disabled}
              >
                Salvar e Avançar
              </DefaultButton>
            )}

            {(!changed || disabled) && page !== 3 && (
              <DefaultButton onClick={() => goNextPage()}>
                Avançar
              </DefaultButton>
            )}
          </div>
        </S.ControllerArea>
      )}
    </S.Container>
  );
};

export default TemplateEvaluation;
