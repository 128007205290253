/* eslint-disable import/order */

// Styles
import * as S from './styled';

// React
import { useMemo } from 'react';

// Icons
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import KeyboardDoubleArrowLeftRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftRounded';
import KeyboardDoubleArrowRightRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowRightRounded';

// Context
import { useTable } from '../../../context';
import { _setPage } from '../../../context/actions';

export const Pagination = ({
  visibleItems = 5,
  disabled,
  hideExtraButton,
  hideNormalButton,
  hideDots,
}) => {
  const { state, dispatch } = useTable();
  const { pageIndex, pageSize, count } = state;

  const arrayFill = useMemo(() => {
    const pages = Math.ceil(count / pageSize);
    return Array(pages)?.fill(0);
  }, [count, pageSize]);

  const position = useMemo(() => {
    const view = visibleItems > 2 ? visibleItems : 3;
    if (pageIndex <= Math.floor(view / 2)) return 0;
    if (pageIndex > view) return Math.ceil(view / 2);
    return pageIndex - Math.floor(view / 2);
  }, [pageIndex]);

  const visiblePages = useMemo(() => {
    return visibleItems > 2
      ? Math.ceil(count / pageSize) > visibleItems
        ? visibleItems
        : Math.ceil(count / pageSize)
      : 3;
  }, [visibleItems, pageSize, count]);

  const handlePage = page => {
    dispatch(_setPage(page));
  };

  if (count === 0) return <></>;

  return (
    <S.Container disabled={disabled}>
      {!hideExtraButton && (
        <S.ArrowContainer
          onClick={() => handlePage(0)}
          disabled={pageIndex === 0}
          className="doble"
        >
          <KeyboardDoubleArrowLeftRoundedIcon className="icon doble_left" />
          <S.ArrowText>Primeira</S.ArrowText>
        </S.ArrowContainer>
      )}
      {!hideNormalButton && (
        <S.ArrowContainer
          onClick={() => handlePage(pageIndex > 0 ? pageIndex - 1 : pageIndex)}
          disabled={pageIndex === 0}
        >
          <KeyboardArrowLeftRoundedIcon className="icon left" />
          <S.ArrowText>Anterior</S.ArrowText>
        </S.ArrowContainer>
      )}

      {!hideDots && (
        <S.Slider visibleItems={visiblePages} data-testid="dots-slider">
          <S.Visualizer count={visiblePages * 2 + 1} position={position}>
            {arrayFill.map(
              (_, index) =>
                index >= pageIndex - visiblePages &&
                index <= pageIndex + visiblePages && (
                  <S.DotContainer
                    aria-label="dot"
                    data-testid={`dot-${index}`}
                    active={pageIndex === index}
                    disabled={pageIndex === index}
                    key={index}
                    onClick={() => handlePage(index)}
                  >
                    <p className="number">{index + 1}</p>
                  </S.DotContainer>
                ),
            )}
          </S.Visualizer>
        </S.Slider>
      )}

      {!hideNormalButton && (
        <S.ArrowContainer
          onClick={() =>
            handlePage(pageIndex < arrayFill.length ? pageIndex + 1 : pageIndex)
          }
          disabled={pageIndex === arrayFill?.length - 1}
        >
          <S.ArrowText>Próxima</S.ArrowText>
          <KeyboardArrowRightRoundedIcon className="icon right" />
        </S.ArrowContainer>
      )}
      {!hideExtraButton && (
        <S.ArrowContainer
          onClick={() => handlePage(arrayFill?.length - 1)}
          disabled={pageIndex === arrayFill?.length - 1}
          className="doble"
        >
          <S.ArrowText>Última</S.ArrowText>
          <KeyboardDoubleArrowRightRoundedIcon className="icon doble_right" />
        </S.ArrowContainer>
      )}
    </S.Container>
  );
};
