// Styled
import { useTheme } from 'styled-components';

// React
import { useEffect, useState } from 'react';

// Components
import DropZoneArchives from 'components/Inputs/DropZoneCustom';

// Components MUI
import { Tooltip } from '@mui/material';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import ModalComments from '../../components/ModalComments';
import * as S from './styled';

// Actions
import {
  addImageQuestion,
  changeComments,
  deleteImageQuestion,
  editImageQuestion,
} from '../actions';

export const OutQuestion = ({
  field,
  setSecoes,
  secoes,
  sectionId,
  hasError,
  preview,
}) => {
  const theme = useTheme();
  const [openComments, setOpenComments] = useState(false);

  const redirectFunction = (executable, idCampo, item) => {
    executable(idCampo, item, setSecoes, secoes, sectionId);
  };

  // ------------Forced Question-------------------//
  const [question, setQuestion] = useState('');

  useEffect(() => {
    if (field) {
      switch (field?.id_tipo_campo) {
        case 15:
          // Empresa
          setQuestion('Empresa');
          break;
        case 16:
          // Filial
          setQuestion('Filial');
          break;
        case 17:
          // Usuario
          setQuestion('Usuário');
          break;
        case 18:
          // Data Hora auto
          setQuestion('Data de Inicio');
          break;
        case 19:
          // Placa
          setQuestion('Placa');
          break;
        case 20:
          // Gestor
          setQuestion('Gestor');
          break;
        case 21:
          // Motorista
          setQuestion('Motorista');
          break;
        case 22:
          // Cliente
          setQuestion('Cliente');
          break;
        case 23:
          // Operação
          setQuestion('Operação');
          break;
        case 24:
          // Carregamento
          setQuestion('Carregamento');
          break;
        case 25:
          // Modelo do Veículo
          setQuestion('Modelo do Veículo');
          break;
        case 26:
          // Frota
          setQuestion('Frota');
          break;
        case 27:
          // Departamento
          setQuestion('Departamento');
          break;
        case 28:
          // Câmbio
          setQuestion('Câmbio');
          break;
        case 29:
          // Propriedade
          setQuestion('Propriedade');
          break;
        case 30:
          // Filial
          setQuestion('Filial');
          break;

        default:
          setQuestion(null);
      }
    }
  }, [field]);

  return (
    <S.Wrapper hasError={hasError} preview={preview}>
      <div className="headerArea">
        <span className="boxQuestion">
          <div className="question">{question}</div>
        </span>
        {!field.opcoes && (
          <S.IconsArea hasComment={field.observacoes} preview={preview}>
            {field.id_tipo_campo !== 14 && (
              <span>
                <DropZoneArchives
                  data={field.arquivos}
                  multiple
                  fileDir="formularios/evidencias"
                  addFunction={item =>
                    redirectFunction(addImageQuestion, field.id, item)
                  }
                  editFunction={item =>
                    redirectFunction(editImageQuestion, field.id, item)
                  }
                  deleteFunction={item =>
                    redirectFunction(deleteImageQuestion, field.id, item)
                  }
                  automaticSave
                  label="Capa Principal"
                  titleButton="Adicionar Capa"
                  minimal
                  leftText="Ver anexos"
                  haveLegend
                  tooltip="Adicionar anexo"
                />
              </span>
            )}

            <Tooltip title="Adicionar comentário" className="icons">
              <div
                className="boxComment"
                style={{ cursor: 'pointer' }}
                onClick={() => setOpenComments(true)}
              >
                <CommentOutlinedIcon
                  htmlColor={theme.palette.brand.primary.light}
                />
                <div className="hasCommnet">{field.observacoes ? 1 : 0}</div>
              </div>
            </Tooltip>
          </S.IconsArea>
        )}
      </div>
      {field.descricao && <div className="description">{field.descricao}</div>}

      <ModalComments
        open={openComments}
        onClose={() => setOpenComments(false)}
        comentario={field.observacoes}
        onChange={value =>
          changeComments(value, field.id, setSecoes, secoes, sectionId)
        }
      />
    </S.Wrapper>
  );
};
