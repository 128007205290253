import api from 'services/api';

export const selectNotifications = async () => {
  const res = await api.get('/select-notifications');
  return res.data;
};

export const getNotifications = async () => {
  const res = await api.get('/configuracoes/notificacoes');
  return res.data;
};

export const getTiposRelatorio = async query => {
  const { data } = await api.get('/telematics_relatorios/tipos', {
    params: query,
  });
  return data?.success ? data.data : [];
};

export const createRelatorioPermission = async data => {
  const res = await api.post(`/telematics_relatorios`, data);
  return res.data;
};

export const updateRelatorioPermission = async (id, data) => {
  const res = await api.put(`/telematics_relatorios/${id}`, data);
  return res.data;
};

export const inactivateRelatorioPermission = async id => {
  console.log('1');
  const res = await api.patch(`/telematics_relatorios/${id}/inactivate`);
  return res.data;
};

export const updateNotificationPermission = async data => {
  const res = await api.put(`/configuracoes/notificacoes/${data.id}`, data);
  return res.data;
};
