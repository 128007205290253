export const columns = [
  {
    header: 'Id',
    id: 'id',
    type: 'number',
    sort: true,
  },
  {
    header: 'Criado por',
    id: 'aluno.nome',
    type: 'string',
    sort: true,
    subRow: {
      prefix: 'Filial:',
      value: (_, item) => item?.aluno?.filial?.nome,
    },
  },
  {
    header: 'Ocorrência',
    id: 'ocorrencia',
    type: 'string',
    sort: true,
  },
  {
    header: 'Formulário',
    id: 'formulario',
    type: 'string',
    sort: false,
  },
  {
    header: 'Responsável',
    id: 'responsavel.nome',
    type: 'string',
    sort: false,
  },
  {
    header: 'Data de criação',
    id: 'data_evento',
    type: 'date',
    sort: true,
    subRow: {
      prefix: 'Vencimento:',
      type: 'date',
      value: (_, item) => item?.data_vencimento,
    },
  },
  {
    header: 'Status',
    id: 'status',
    type: 'string',
    sort: true,
    align: 'center',
    conditional: [
      {
        condition: value => value === 'FINALIZADO',
        style: theme => ({
          color: theme.palette.semantics.feedback.success.natural,
          backgroundColor: theme.palette.semantics.feedback.success.light,
          textAlign: 'center',
        }),
      },
      {
        condition: value => value === 'PENDENTE',
        style: theme => ({
          color: theme.palette.semantics.feedback.attention.natural,
          backgroundColor: theme.palette.semantics.feedback.attention.light,
          textAlign: 'center',
        }),
      },
    ],
  },
];
