import styled from 'styled-components';
import colors from 'styles/colors';

export const Wrapper = styled.div`
  border: 2px dashed ${colors.greyTiny};
  background-color: #fff;
  width: 272px;
  height: 392px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;

  span {
    margin-top: 15px;
    color: ${colors.greySubtitle};
    font-weight: 500;
  }

  &:hover {
    background-color: ${colors.greyBackgroud};
  }
`;
