import { InformacoesGerais } from './InfoGerais';
import { Publico } from './Publico';
import { Configuracoes } from './Configuracoes';
import { Revisar } from './Revisar';
import { Reports } from './Reports';

export const steps = [
  {
    title: 'Informações Gerais',
    pageTitle: 'Informações Gerais',
    page: 'Informações Gerais',
    step: 1,
    value: 0,
    Component: changed => InformacoesGerais(changed),
  },
  {
    title: 'Público',
    pageTitle: 'Público',
    page: 'Público',
    step: 2,
    value: 1,
    Component: changed => Publico(changed),
  },
  {
    title: 'Configurações',
    pageTitle: 'Configurações',
    page: 'Configurações',
    step: 3,
    value: 2,
    Component: changed => Configuracoes(changed),
  },
  {
    title: 'Revisar',
    pageTitle: 'Revisar e Publicar',
    page: 'Formulário',
    step: 4,
    value: 3,
    Component: changed => Revisar(changed),
  },
  {
    title: 'Reports',
    pageTitle: 'Reports',
    page: 'Reports',
    step: 5,
    value: 4,
    Component: changed => Reports(changed),
  },
];
