import React, { useState, useMemo } from 'react';
import colors from 'styles/colors';

import { Modal, IconButton, Icon, Grid } from '@mui/material';

import InfoCard from 'components/Cards/InfoCard';
import GhostButton from 'components/Buttons/Ghost';
import DefaultButton from 'components/Buttons/Default';
import Select from 'components/Inputs/Select';
import Input from 'components/Inputs/TextField';
import Calendar from 'components/Inputs/Calendar';
import * as S from './styled';

const StorePhoneModal = ({
  id,
  handleClose,
  handleConfirm,
  titleIconName = 'info',
  loading,
}) => {
  const titleIcon = (
    <Icon sx={{ color: colors.greenMain }} fontSize="medium">
      {titleIconName}
    </Icon>
  );

  const formatCelular = value => {
    if (!value) return '';
    if (String(value?.lenght) && String(value.length) > 15)
      return String(value.slice(0, 15));
    value = value.replace(/\D/g, '');
    value = value.replace(/(\d{2})(\d)/, '($1)-$2');
    value = value.replace(/(\d)(\d{4})$/, '$1-$2');

    return value;
  };

  const title = 'Cadastre um novo celular para este motorista';

  const subtitle =
    'É necessário informar um celular válido e único por motorista ativo para o sucesso deste cadastro';

  const [data, setData] = useState({
    phone: '',
  });

  const handleChange = (name, value) => {
    value = formatCelular(value);
    setData(prev => {
      return { ...prev, [name]: value };
    });
  };

  const checkConfirmButton = () => {
    let ready = true;
    if (!data.phone) ready = false;
    return ready;
  };

  const handleClickConfirm = () => {
    const req = {
      ...data,
      id,
    };
    handleConfirm(req);
  };

  const open = Boolean(id);

  return (
    <Modal open={open}>
      <S.Paper>
        <S.Header>
          <S.Title>
            {titleIcon}
            <h1>{title}</h1>
          </S.Title>

          <IconButton size="small" onClick={handleClose}>
            <Icon sx={{ color: colors.greySubtitle }}>close</Icon>
          </IconButton>
        </S.Header>

        <InfoCard message={subtitle} />

        <Grid container rowSpacing={0} columnSpacing={2} marginY={3}>
          <Grid item xs={12} sm={12}>
            {/* <Select
              required
              data={canais}
              value={data.phone}
              onChange={e => handleChange('phone', e.target.value)}
              name="canal"
              label="Canal de envio"
            /> */}
            <Input
              required
              label="Telefone celular"
              placeholder="Ex.: (00)-90000-0000"
              value={data.phone || ''}
              onChange={e => handleChange('phone', e.target.value)}
              name="phone"
              id="phone"
            />
          </Grid>
        </Grid>

        <S.Footer>
          <GhostButton size="medium" onClick={handleClose}>
            Cancelar
          </GhostButton>

          <DefaultButton
            onClick={handleClickConfirm}
            size="medium"
            style={{ marginLeft: '10px' }}
            disabled={!checkConfirmButton()}
            loading={loading}
          >
            Confirmar
          </DefaultButton>
        </S.Footer>
      </S.Paper>
    </Modal>
  );
};

export default StorePhoneModal;
