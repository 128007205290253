// React
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

// Components
import GhostButton from 'components/Buttons/Ghost';
import DefaultButton from 'components/Buttons/Default';
import SelectList from 'components/Inputs/SelectList';

// Components MUI
import List from '@mui/material/List';
import { Popover } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import ListItemText from '@mui/material/ListItemText';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ListItemButton from '@mui/material/ListItemButton';
import { getTiposPadrao } from 'pages/FadigaConfiguracoes/services';
import * as S from './styled';

// Utils

const Filters = ({ id, open, onClose, anchorEl, filter, setFilter }) => {
  const [prevFilter, setPrevFilter] = useState({ ...filter });
  const [filiaisFilter, setFiliaisFilter] = useState([]);
  const [selectedEmpresas, setSelectedEmpresas] = useState(false);
  const [openCollapseCliente, setOpenCollapseCliente] = useState(false);
  const [openCollapseResponsible, setOpenCollapseResponsible] = useState(false);
  const { user } = useSelector(state => state.auth.user);
  const userLevel = user?.nivel;
  const isProvider = user?.provider;

  const selects = useSelector(state => state.selects);

  const allUsers =
    selects.users?.map(i => {
      return {
        id: i.id,
        value: i.nome,
        id_da_filial: i.id_da_filial,
        nivel: i.nivel,
      };
    }) || [];
  const listaDesvioResponsavel = allUsers.filter(item =>
    userLevel > 1 ? user.usuario_filiais.includes(item.id_da_filial) : true,
  );

  useEffect(() => {
    setPrevFilter({ ...filter });
  }, [filter]);

  const { data: tiposPadrao = [] } = useQuery(
    ['desvios-padrao-fadiga', 'FADIGA'],
    () => getTiposPadrao({ categoria: ['FADIGA'] }),
    { staleTime: Infinity },
  );

  const tiposDesvios = tiposPadrao.map(tipo => ({
    value: tipo.tipo,
    id: tipo.id,
  }));

  const clientes =
    selects.clients?.map(i => {
      return { value: i.nome, id: i.id };
    }) || [];

  const filiais =
    selects.filials?.map(i => {
      return { value: i.nome, id: i.id };
    }) || [];

  const operations = [
    {
      id: 'PRIMARIA',
      value: 'Primária',
    },
    {
      id: 'SECUNDARIA',
      value: 'Secundária',
    },
    {
      id: 'ACUCAR',
      value: 'Açúcar',
    },
    {
      id: -1,
      value: 'Não Informado',
    },
  ];

  useEffect(() => {
    if (isProvider) {
      if (prevFilter?.empresas) {
        const count = prevFilter?.empresas.split(',').length;

        if (count === 1) {
          setSelectedEmpresas(true);
          const empresa = prevFilter?.empresas;
          const newFiliais = selects.empresas
            .find(company => company.id === Number(empresa))
            .filiais?.map(i => ({ id: i.id, value: i.nome }));
          setFiliaisFilter(newFiliais);
        } else {
          setSelectedEmpresas(false);
          setPrevFilter(_prevFilter => ({ ..._prevFilter, filiais: '' }));
        }
      } else {
        setSelectedEmpresas(false);
        setPrevFilter(_prevFilter => ({ ..._prevFilter, filiais: '' }));
      }
    }
  }, [prevFilter?.empresas, selects.empresas]);

  const handleChangeMultiple = (e, key) => {
    setPrevFilter(_prevFilter => {
      return { ..._prevFilter, [key]: e };
    });
  };

  const aplyFilter = () => {
    const _prevFilter = { ...prevFilter };
    Object.keys(_prevFilter).forEach(key => {
      if (!_prevFilter[key]) delete _prevFilter[key];
    });
    setFilter({ ..._prevFilter });
    onClose();
  };

  const reset = () => {
    setPrevFilter({ ...filter });
  };

  // Limpa TODOS os valores do estado e foca no botao de aplicar
  const handleClear = () => {
    setPrevFilter({});
    const buttonApply = document.getElementById('aplicar');
    buttonApply?.focus();
  };

  const toggleCollapse = collapseId => {
    setOpenCollapseCliente(state => !state && collapseId === 'client');
    setOpenCollapseResponsible(state => !state && collapseId === 'responsible');
  };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <S.Main>
        <S.Header>
          <h2>Filtros</h2>
          <S.ClearButton onClick={handleClear}>Limpar filtros</S.ClearButton>
        </S.Header>

        <S.SelectsContainer>
          <List>
            <S.CollapseContent>
              {isProvider ? (
                <>
                  <SelectList
                    value={prevFilter?.empresas}
                    handleChange={handleChangeMultiple}
                    fieldName="empresas"
                    label="Filtrar por Empresas"
                    data={selects.empresas}
                    selectAll
                  />
                  {selectedEmpresas && (
                    <SelectList
                      value={prevFilter?.filiais}
                      handleChange={handleChangeMultiple}
                      fieldName="filiais"
                      label="Filtrar por Filial"
                      data={filiaisFilter}
                      selectAll
                    />
                  )}
                  <SelectList
                    value={prevFilter?.operation}
                    handleChange={handleChangeMultiple}
                    fieldName="operation"
                    label="Filtrar por Operação"
                    data={operations}
                    selectAll
                  />
                  <SelectList
                    data={tiposDesvios}
                    value={prevFilter?.idDesvio}
                    label="Filtrar por Tipo de Desvio"
                    fieldName="idDesvio"
                    handleChange={handleChangeMultiple}
                    selectAll
                  />
                </>
              ) : (
                <>
                  <SelectList
                    value={prevFilter?.filiais}
                    handleChange={handleChangeMultiple}
                    fieldName="filiais"
                    label="Filtrar por Filial"
                    disabled={userLevel > 1}
                    data={filiais}
                    selectAll
                  />
                  <SelectList
                    data={tiposDesvios}
                    value={prevFilter?.idDesvio}
                    label="Filtrar por Tipo de Desvio"
                    fieldName="idDesvio"
                    handleChange={handleChangeMultiple}
                    selectAll
                  />
                </>
              )}
            </S.CollapseContent>
            {!isProvider && (
              <>
                <ListItemButton onClick={() => toggleCollapse('client')}>
                  {openCollapseCliente ? <ExpandLess /> : <ExpandMore />}
                  <ListItemText primary="Filtrar por Cliente e Operação" />
                </ListItemButton>
                <Collapse in={openCollapseCliente} timeout="auto" unmountOnExit>
                  <S.CollapseContent>
                    <SelectList
                      value={prevFilter?.client}
                      handleChange={handleChangeMultiple}
                      fieldName="client"
                      label="Filtrar por Cliente"
                      data={clientes}
                      selectAll
                    />
                    <SelectList
                      value={prevFilter?.operation}
                      handleChange={handleChangeMultiple}
                      fieldName="operation"
                      label="Filtrar por Operação"
                      data={operations}
                      selectAll
                    />
                  </S.CollapseContent>
                </Collapse>

                <ListItemButton onClick={() => toggleCollapse('responsible')}>
                  {openCollapseResponsible ? <ExpandLess /> : <ExpandMore />}
                  <ListItemText primary="Filtrar por Responsável" />
                </ListItemButton>
                <Collapse
                  in={openCollapseResponsible}
                  timeout="auto"
                  unmountOnExit
                >
                  <S.CollapseContent>
                    <SelectList
                      value={prevFilter?.responsavel ?? ''}
                      handleChange={handleChangeMultiple}
                      fieldName="responsavel"
                      label="Filtrar por Responsável"
                      data={listaDesvioResponsavel?.filter(
                        item =>
                          !prevFilter.filiais ||
                          item.nivel === 1 ||
                          prevFilter?.filiais
                            .split(',')
                            .includes(item.id_da_filial),
                      )}
                      selectAll
                    />
                  </S.CollapseContent>
                </Collapse>
              </>
            )}
          </List>
        </S.SelectsContainer>
      </S.Main>
      <S.Footer>
        <GhostButton
          onClick={() => {
            reset();
            onClose();
          }}
          size="medium"
        >
          Cancelar
        </GhostButton>

        <DefaultButton
          onClick={aplyFilter}
          size="medium"
          sx={{ marginLeft: '20px' }}
          id="aplicar"
        >
          APLICAR
        </DefaultButton>
      </S.Footer>
    </Popover>
  );
};

export default Filters;
