import produce from 'immer';

const INITIAL_STATE = {
  savedWeek: null,
  savedMonth: null,
  savedComponent: 1,
  currentWeek: null,
  lastView: null,
};

export default function myTasks(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@myTasks/CHANGE_CURRENT_DAY': {
        draft.savedWeek = action.payload.savedWeek;
        draft.savedComponent = state.savedComponent;
        draft.savedMonth = action.payload.savedMonth;
        draft.lastView = action.payload.lastView;
        break;
      }
      case '@myTasks/CHANGE_COMPONENT': {
        draft.savedComponent = action.payload.savedComponent;
        break;
      }
      case '@myTasks/CHANGE_CURRENT_WEEK': {
        draft.currentWeek = action.payload.currentWeek;
        break;
      }
      default:
    }
  });
}
