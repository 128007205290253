import colors from 'styles/colors';
import styled, { keyframes } from 'styled-components';
import { Modal } from '@mui/material';

const warning = keyframes`
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
`;

export const BigModal = styled(Modal)`
  transition: all ease 0.3s;
  margin-top: ${props => props.visible};
`;

export const Cover = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: relative;
  overflow: hidden;
`;

export const Header = styled.header`
  position: absolute;
  top: 0;
  width: 100%;
  height: 70px;
  display: flex;

  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 10px #999;
  padding: 0px 20px;
  z-index: 9999;
  background-color: #fff;

  .left {
    position: absolute;
    left: 0;
    margin-left: 30px;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  .right {
    position: absolute;
    right: 0;
    margin-right: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .stArea {
      margin-right: 0px;
      display: flex;
      align-items: center;

      .previewWeb {
        font-size: 18px;
        margin-right: 20px;
        font-weight: 600;
        background-color: ${colors.orangeBackground};
        color: ${colors.orangeAlert};
        border-radius: 20px;
        padding: 0px 25px;
        animation: ${warning} 2s ease infinite;
      }

      .timeST {
        font-size: 14px;
        font-weight: bold;
        margin-right: 10px;
      }
    }
  }

  .medium {
    img {
      width: 40px;
    }

    @media (max-width: 1415px) {
      img {
        display: none;
      }
    }
  }
`;

export const Progress = styled.div`
  display: flex;
  align-items: center;
  margin-right: 80px;

  .numeric {
    font-size: 12px;
    font-weight: 900;
    margin-right: 10px;
    color: ${colors.greySubtitle};

    @media (max-width: 1050px) {
      display: none;
    }
  }

  .barArea {
    width: 180px;
    margin: 0px 10px;
    position: relative;

    .filled {
      width: ${props => props.bar || 0}%;
      height: 10px;
      background-color: ${props => props.colored};
      border-radius: 10px;
      position: absolute;
      transition: all ease 1s;
    }
    .empty {
      width: 100%;
      height: 10px;
      background-color: #ddd;
      border: 1px solid ${colors.greyTiny};
      border-radius: 10px;
    }
  }

  @media (max-width: 930px) {
    display: none;
  }
`;

export const Ribbon = styled.div`
  position: absolute;
  background-color: ${props => props.color};
  width: 200px;
  height: 40px;
  font-weight: bold;
  font-size: 22px;
  color: ${colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(-45deg);
  z-index: 2;
  margin-top: 37px;
  margin-left: -50px;
  animation: scl 0.7s linear 1 forwards;

  @keyframes scl {
    0% {
      transform: rotate(-45deg) scale(3.5);
    }
    100% {
      transform: rotate(-45deg) scale(1);
    }
  }
`;

export const Body = styled.div`
  padding-top: 70px;
  height: 100%;
  display: flex;
  z-index: 1;
  background-color: red;
`;

export const Content = styled.div`
  flex: 2;
  background-color: ${colors.whiteBackground};
  height: 100%;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-x: hidden;
  position: relative;

  ::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px white;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #9090904d;
    border-radius: 10px;
  }

  &.block {
    pointer-events: none;
    overflow: hidden;
  }
`;

export const Bar = styled.div`
  flex: 1;
  background-color: #fff;
  border-left: 1px solid ${colors.greyTiny};
  margin-right: ${props => (props.visible ? '0px' : '-50%')};
  transition: all ease 0.3s;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;

  ::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px white;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${colors.grey};
    border-radius: 10px;
  }

  .iconAbs {
    position: fixed;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0px 0px 10px #999;
    width: 26px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-left: ${props => (props.visible ? '-15px' : '-25px')};
    cursor: pointer;
    transform: rotateY(${props => (props.visible ? '0deg' : '180deg')});
    transition: all ease 0.3s;

    &:hover {
      opacity: 0.8;

      .MuiSvgIcon-root {
        color: ${colors.greenMain};
      }
    }

    .MuiSvgIcon-root {
      color: ${colors.greyTitle};
      font-size: 12px;

      &:hover {
        color: ${colors.greenMain};
      }
    }
  }
`;

export const LineModule = styled.div`
  display: flex;
  width: 100%;
  height: 80px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${colors.greyTiny};
  padding: 0px 20px;
  cursor: pointer;
  transition: all ease 0.1s;

  &.shake {
    animation: tremer 0.15s;
    animation-iteration-count: 4;

    @keyframes tremer {
      0% {
        margin-left: 0;
      }
      25% {
        margin-left: 7px;
      }
      50% {
        margin-left: 0;
      }
      75% {
        margin-left: -7px;
      }
      100% {
        margin-left: 0;
      }
    }
  }

  &.rotate {
    animation: rotate 1s;
    animation-iteration-count: 1;

    @keyframes rotate {
      0% {
        transform: rotateX(0deg);
      }
      25% {
        transform: rotateX(90deg);
      }
      50% {
        transform: rotateX(180deg);
      }
      100% {
        transform: rotateX(0deg);
      }
    }
  }

  background-color: ${props => (props.active ? colors.greyBorder : '#fff')};

  .MuiSvgIcon-root {
    &.lock {
      color: ${colors.redDanger};
    }
  }

  &:hover {
    background-color: ${props =>
      props.lock ? 'transparent' : colors.greyBorder};

    .MuiSvgIcon-root {
      transition: all ease 0.3s;

      &.lock {
        color: ${colors.redDanger};
      }
    }
  }

  .left {
    .type {
      font-size: 18px;
      color: ${colors.greyTitle};
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .text {
        opacity: ${props => (props.lock ? 0.6 : 1)};
        margin-left: 5px;
      }
    }

    .status {
      font-size: 14px;
      font-weight: 600;
      color: ${colors.greySubtitle};

      margin-top: 5px;

      .fixed {
        font-weight: 500;
        color: ${colors.greySubtitle};
      }

      .defined {
        font-weight: 600;
        margin-left: 5px;
      }
    }
  }

  .right {
  }
`;

export const ZeroSelect = styled.div`
  padding: ${props => (props.view ? '0' : '30px 80px')};
  margin-top: ${props => (props.view ? '-72px' : '0px')};
  transition: all ease 0.3s;
  position: relative;
  width: 100%;

  .titleArea {
    z-index: 2;
    margin-bottom: ${props => (props.view ? '-180px' : '20px')};
    margin-top: ${props => (props.view ? '180px' : '0px')};
    margin-left: ${props => (props.view ? '80px' : '0px')};

    .title {
      opacity: ${props => (props.view ? 0 : 1)};
      transition: all ease 0.3s;
    }

    .title::first-letter {
      text-transform: uppercase;
    }

    .tags {
      margin-top: 10px;
      opacity: ${props => (props.view ? 0 : 1)};
    }
  }

  .background {
    height: ${props => (props.view ? '300px' : '350px')};
    background-image: url(${props => props.background});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    z-index: -1;
    width: 100%;

    .gradient {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      width: 100%;
      height: 100%;
      padding: 0px 120px;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.95) 0%,
        rgba(0, 0, 0, 0.5) 100%
      );
      opacity: ${props => (props.view ? 1 : 0)};
      transition: all ease 0.3s;

      .title {
        font-size: 30px;
        color: #fff;
        transition: all ease 0.3s;

        &::first-letter {
          text-transform: uppercase;
        }
      }

      .description {
        font-size: 18px;
        color: #fff;
        font-weight: 500;
        margin-top: 15px;
        transition: all ease 0.3s;
      }
    }
  }

  .descriptionArea {
    margin-top: 25px;

    .descriptionTab {
      display: inline-block;
      border-bottom: 5px solid ${colors.greenMain};
      color: ${colors.greyTitle};
      padding-bottom: 3px;
      font-weight: 600;
      font-size: 17px;
    }

    .descriptionText {
      margin-top: 15px;
      color: ${colors.greySubtitle};
      font-weight: 500;
    }
  }
`;

export const BoxView = styled.div`
  padding: 20px 70px;
  padding-bottom: 0px;
  margin-left: -${props => props.slide}%;
  max-width: 1300px;
  width: 100%;
  transition: all ease 0.5s;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  padding-bottom: 30px;
`;

export const PrevieWarning = styled.div`
  position: fixed;
  z-index: 999;
  width: 250px;
  background-color: rgba(0, 0, 0, 0.71);
  bottom: 0;
  right: 0;
  margin-bottom: 20px;
  margin-right: 20px;
  border-radius: 10px;
  padding: 10px;
  font-weight: 600;
  flex-direction: column;
  font-style: oblique;
  display: ${props => (props.view ? 'flex' : 'none')};

  .warning {
    color: #fff;
  }

  .close {
    align-self: end;
    font-weight: 600;
    color: ${colors.blueInfo};
    margin: 10px;
    cursor: pointer;
  }
`;
