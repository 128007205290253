import * as types from './types';

export function setFilter(query) {
  return {
    type: types.SET_FILTER,
    payload: { ...query },
  };
}

export function setGroupBy(query) {
  return {
    type: types.SET_GROUP_BY,
    payload: query,
  };
}

export function ResetFilter() {
  return {
    type: types.RESET_FILTER,
  };
}
