// Styles
import { useTheme } from 'styled-components';

// React
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Components
import { SvgIcon } from '@mui/material';
import { NotFoundConfig } from './NotFound';

// Material UI
import * as S from './styled';

// Contants
import { pages } from './constants';

const Configuracoes = () => {
  const user = useSelector(state => state.auth?.user?.user);
  const userNivel = user?.nivel;
  const isWhitelabel = user?.distribuidora?.contrato?.is_whitelabel;
  const planos = user?.planos;

  const pageMap = useSelector(state => state.pageMap?.pageMap);
  const paginas = [];
  pageMap.forEach(plan =>
    plan.paginas.forEach(pag => {
      if (pag.restricao_nivel >= userNivel) paginas.push(pag.pagina);
    }),
  );

  const theme = useTheme();
  let page = useLocation().pathname.split('/').pop();
  const navigate = useNavigate();
  const [component, setComponent] = useState(<></>);

  useEffect(() => {
    if (!page) page = 'usuarios';
    renderComponent();
  }, [page]);

  const renderComponent = () => {
    let currentPage = null;
    for (const i in pages) {
      if (pages[i].id === page) {
        currentPage = pages[i].element;
        setComponent(currentPage);
        return;
      }
    }

    if (!page) {
      currentPage = pages[0].element;
      setComponent(currentPage);
      return;
    }

    setComponent(<NotFoundConfig />);
  };

  return (
    <S.Container>
      <S.NavBar>
        {pages
          .filter(
            page =>
              (!page.whitelabel || (page.whitelabel && isWhitelabel)) &&
              paginas.includes(page.linkTo),
          )
          .map((i, k) => {
            let okPlans = true;
            let okLevel = true;

            if (i.plans)
              okPlans = planos.some(
                item => i.plans.includes(item.id_do_plano) && item.ativo,
              );
            if (i.levels) okLevel = i.levels.includes(user.nivel);

            if (okPlans && okLevel) {
              return (
                <span
                  onClick={() => navigate(i.linkTo, { replace: true })}
                  className={page === i.id ? 'navBarItem active' : 'navBarItem'}
                  key={k}
                >
                  <SvgIcon
                    component={i.icon}
                    stroke={
                      page === i.id
                        ? theme.palette.brand.secondary.natural
                        : theme.palette.brand.primary.light
                    }
                  />
                  <span>{i.page}</span>
                </span>
              );
            }
          })}
      </S.NavBar>
      <S.TemplatePage>
        <div className="alignContent">{component}</div>
      </S.TemplatePage>
    </S.Container>
  );
};

export default Configuracoes;
