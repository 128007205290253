const columnsTurmas = [
  {
    header: 'Id',
    id: 'id',
    type: 'string',
    sort: true,
    align: 'center',
  },
  {
    header: 'Nome',
    id: 'nome',
    type: 'string',
    sort: true,
    picture: 'foto',
  },
  {
    header: 'Tipo',
    id: 'tipoUser',
    type: 'string',
    sort: true,
    align: 'center',
  },
  {
    header: 'Status',
    id: 'ativa',
    type: 'string',
    sort: true,
    align: 'center',
    value: value => (value ? 'ATIVA' : 'INATIVA'),
    conditional: [
      {
        condition: value => value,
        style: theme => ({
          color: theme.palette.semantics.feedback.success.natural,
          backgroundColor: theme.palette.semantics.feedback.success.light,
          textAlign: 'center',
        }),
      },
      {
        condition: value => !value,
        style: theme => ({
          color: theme.palette.semantics.feedback.attention.natural,
          backgroundColor: theme.palette.semantics.feedback.attention.light,
          textAlign: 'center',
        }),
      },
    ],
  },
  {
    header: 'Transportadoras',
    id: 'n_transportadoras',
    type: 'string',
    sort: true,
    align: 'center',
  },
  {
    header: 'Atividades',
    id: 'atividades',
    type: 'number',
    sort: true,
    align: 'center',
  },
];

const columnsView = [
  {
    header: 'Id',
    id: 'id',
    type: 'string',
    sort: true,
    align: 'center',
  },
  {
    header: 'Nome',
    id: 'nome',
    type: 'string',
    sortType: 'basic',
    sort: true,
    picture: 'logo_url',
  },
  {
    header: 'Atividades Relacionadas',
    id: 'atividades_relacionadas',
    type: 'string',
    sort: true,
    align: 'center',
  },
  {
    header: 'Status',
    id: 'status',
    type: 'string',
    sort: true,
    align: 'center',
    value: value => (value === 'ATIVO' ? 'ATIVA' : 'INATIVA'),
    conditional: [
      {
        condition: value => value,
        style: theme => ({
          color: theme.palette.semantics.feedback.success.natural,
          backgroundColor: theme.palette.semantics.feedback.success.light,
          textAlign: 'center',
        }),
      },
      {
        condition: value => !value,
        style: theme => ({
          color: theme.palette.semantics.feedback.attention.natural,
          backgroundColor: theme.palette.semantics.feedback.attention.light,
          textAlign: 'center',
        }),
      },
    ],
  },
];

export { columnsTurmas, columnsView };
