import api from 'services/api';

export const getAcidentes = async (id, query) => {
  const { data } = await api.get(`/acidentes`, {
    params: { ...query, empresa: id },
  });
  return data;
};
export const getMultas = async (id, query) => {
  const { data } = await api.get(`/multas`, {
    params: { ...query, empresa: id },
  });
  return data;
};
