import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  max-width: 100%;
  background: #2e3240;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  font-weight: medium;
  padding: 0.25rem;
  .text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-transform: capitalize;
  }
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
`;
