import styled, { css } from 'styled-components';
import { makeStyles } from '@material-ui/styles';

export const Main = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SpacedRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  ${props =>
    props.modalImprimir
      ? 'margin-top: 20px; margin-right: 20px; margin-left: 20px;'
      : ''};
`;

export const ColumnWrapper = styled.div`
  ${({ theme, id }) => css`
    background: ${theme.palette.system.overlay};
    border-radius: 4px;
    ${id ? `border: 1px solid ${theme.palette.brand.primary.light}4D` : ''};
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: ${id ? '30px 25px' : '0px 25px; padding-bottom: 15px'};
  `}
`;

export const ImageContainer = styled.div`
  width: 100%;
  min-height: 100px;
  align-items: center;
  justify-content: center;
  position: relative;

  ${props =>
    props.file
      ? `:hover{
        width: 99%;
        height: 99%;
        opacity: 0.9;
        transition-duration: 300ms;
      }`
      : ''};

  img {
    width: 100%;
    border-radius: 5px;
  }

  .expandImage {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0.1;
    cursor: pointer;

    :hover {
      opacity: 1;
      transition-duration: 300ms;
    }
  }
`;

export const StyledLogoWrapper = styled.div`
  ${({ theme }) => css`
    margin-right: 10px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid ${theme.palette.brand.secondary.natural};
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;

export const StyledLogo = styled.div`
  ${({ theme, ...props }) => css`
    height: 42px;
    width: 42px;
    border-radius: 50%;
    border: ${props.border};
    background-image: ${props.backgroundImage};
    background-color: ${theme.palette.system.border};
    display: flex;
    align-items: center;
    justify-content: center;

    h2 {
      font-size: 20px;
      color: ${theme.palette.brand.primary.natural};
      text-align: center;
    }
  `}
`;

export const StyledTextWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    h1 {
      font-size: 16px;
      color: ${theme.palette.words.title.natural};
      font-weight: 600;
      text-transform: capitalize;
      text-align: left;
    }
    p {
      color: ${theme.palette.words.subtitle.natural};
      font-size: 14px;
      font-weight: 500;
      text-align: left;
    }
  `}
`;

export const ExpandImage = styled.div`
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.1;
  cursor: pointer;

  :hover {
    opacity: 1;
    transition-duration: 300ms;
  }
`;

export const IconDivClose = styled.div`
  position: absolute;
  z-index: 2;
  top: 20px;
  left: 20px;
  padding: 0px 0px 0px 0px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  transform: scale(1);
  transition: 0.3s ease-out;
  :hover {
    transform: scale(1.2);
  }
`;

export const CaeHeader = styled.div`
  ${({ theme }) => css`
    padding: 30px;
    display: flex;
    background-color: ${theme.palette.system.overlay};
    height: 75px;
    opacity: 1;

    p {
      margin-left: 6px;
      text-align: left;
      font: normal normal 900 20px/20px Texta;
      letter-spacing: 0px;
      color: ${theme.palette.words.title.natural};
      opacity: 1;
    }
  `}
`;

export const useStyles = makeStyles(theme => ({
  inputText: {
    margin: '10px 0px',
    width: '100%',
    maxWidth: '395px',
  },
  botaoAddPergunta: {
    border: `2px solid #656E8C`,
    borderRadius: '4px',
    color: '#656E8C',
    backgroundColor: 'transparent',
  },
}));
