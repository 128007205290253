import { Radio } from '@mui/material';
import { useEffect, useState } from 'react';
import colors from 'styles/colors';
import * as S from './styled';

export const EvaluationResponse = ({
  item,
  noBorder,
  checkedId,
  setCheckedId,
  setColoBox,
  margin = 0,
  opacity = 1,
  isFinished,
}) => {
  let resposta = item?.resposta;
  let hasId = resposta?.id_alternativa;
  let currentAlt = item?.alternativas?.find(
    alt => alt.id === resposta?.id_alternativa,
  );
  let isCorrect = currentAlt?.correta;

  useEffect(() => {
    if (!hasId) {
      setColoBox(colors.greyTiny);
    } else {
      if (isCorrect) {
        setColoBox(colors.greenSucces);
      } else {
        setColoBox(colors.redDanger);
      }
    }
  }, [item]);

  return (
    <S.Container noBorder={noBorder} margin={margin} opacity={opacity}>
      {hasId && (
        <S.Ribbon correct={isCorrect}>
          {hasId && isCorrect ? 'Correta' : 'Incorreta'}
        </S.Ribbon>
      )}
      <S.Question>{item?.pergunta}</S.Question>
      <S.ResponsesBox>
        {item?.alternativas?.map((alt, index) => (
          <S.Response key={index}>
            <Radio
              size="small"
              checked={checkedId === alt.id}
              disabled={(hasId || isFinished) && checkedId !== alt.id}
              onChange={() => {
                setColoBox(colors.greyTiny);
                setCheckedId(alt.id);
              }}
              id={`radio${alt.id}`}
            />
            <label htmlFor={`radio${alt.id}`} className="alternative">
              {alt?.alternativa}
            </label>
          </S.Response>
        ))}
      </S.ResponsesBox>
    </S.Container>
  );
};
