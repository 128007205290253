import GppBadOutlinedIcon from '@mui/icons-material/GppBadOutlined';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import OndemandVideoOutlinedIcon from '@mui/icons-material/OndemandVideoOutlined';
import SignalCellularAltOutlinedIcon from '@mui/icons-material/SignalCellularAltOutlined';

// Steps
import MeusConteudos from './MeusConteudos';
import Reprovacoes from './Reprovacoes';
import Certificados from './Certificados';
import Reports from './Reports';

export const tabs = [
  {
    index: 0,
    title: 'Meus Conteúdos',
    Icon: params => <OndemandVideoOutlinedIcon {...params} />,
    Component: params => <MeusConteudos {...params} />,
    link: '/capacitacao/perfil?page=conteudos',
    search: 'conteudos',
  },
  {
    index: 1,
    title: 'Reprovações',
    Icon: params => <GppBadOutlinedIcon {...params} />,
    Component: params => <Reprovacoes {...params} />,
    link: '/capacitacao/perfil?page=reprovacoes',
    search: 'reprovacoes',
  },
  {
    index: 2,
    title: 'Certificados',
    Icon: params => <EmojiEventsOutlinedIcon {...params} />,
    Component: params => <Certificados {...params} />,
    link: '/capacitacao/perfil?page=certificado',
    search: 'certificado',
  },
  {
    index: 3,
    title: 'Reports',
    Icon: params => <SignalCellularAltOutlinedIcon {...params} />,
    Component: params => <Reports {...params} />,
    link: '/capacitacao/perfil?page=reports',
    search: 'reports',
  },
];
