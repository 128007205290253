import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 100%;
  position: relative;
`;
export const NavBar = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.palette.system.overlay};
    width: 227px;
    height: calc(100vh - 70px);
    position: fixed;
    top: 0;
    left: 0;
    margin-left: 60px;
    margin-top: 70px;
    border-right: 2px solid ${theme.palette.system.backdrop};
    box-shadow: 9px 3px 12px -4px ${theme.palette.system.backdrop};
    position: fixed;
    z-index: 997;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 70px;

    .navBarItem {
      width: 100%;
      font-size: 16px;
      color: ${theme.palette.words.label.natural};
      font-weight: 600;
      opacity: 0.9;
      text-align: start;
      border-right: 4px solid ${theme.palette.system.transparent};
      padding: 13px 30px;
      cursor: pointer;
      display: flex;
      align-items: center;
      transition: all ease 0.3s;
      &:hover {
        background-color: ${theme.palette.semantics.feedback.unknown.light};
        border-right: 4px solid ${theme.palette.brand.secondary.natural};
      }
      span {
        margin-left: 10px;
      }
    }

    .active {
      border-right: 4px solid ${theme.palette.brand.secondary.natural};
      background-color: ${theme.palette.semantics.feedback.unknown.light};
    }
  `}
`;

export const TemplatePage = styled.div`
  margin-left: 230px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px 100px;

  .alignContent {
    width: 100%;
  }
`;
export const TitleArea = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: start;
    align-items: center;

    &.space {
      display: flex;
      justify-content: space-between;
    }

    &.flex {
      display: flex;
    }

    .textArea {
      color: ${theme.palette.words.title.natural};
      font-size: 24px;
      font-weight: 900;
    }

    .subTitle {
      margin-top: 10px;
      font-size: 16px;
      color: ${theme.palette.words.subtitle.natural};
      font-weight: 600;
    }

    .tooltipArea {
      display: flex;
      justify-content: start;
      align-items: center;
      margin-left: 5px;
    }
  `}
`;
