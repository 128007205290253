import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { formatNameDate } from 'utils/dates';
import ExportToExcel from 'utils/exportToCvs';

// Actions
import {
  setFilter,
  setGroupBy,
} from 'store/modules/filterFadigaDesempenho/actions';

// Componentes
import { Grid, Tooltip, SvgIcon } from '@mui/material';
import { HelpOutlineOutlined, SaveAlt } from '@mui/icons-material';
import Select from 'components/Inputs/Select';
import TableLocal from 'components/TableLocal';
import Graph from 'components/Graphs/ComposedChart';
import GhostButton from 'components/Buttons/Ghost';
import { ReactComponent as filterIco } from 'images/icons/filter.svg';
import { paletteColors } from 'styles/colors';
import { subHours } from 'date-fns';
import FilterModal from './FilterModal';

// Service
import * as S from '../styled';
import { columns } from './columns';
import { requestEstatisticas, requestExcel } from './services';
import EmpresaModal from './EmpresaModal';

const _valuesSelect = [
  { value: 'desvio', name: 'Desvios' },
  { value: 'operacoes', name: 'Operações' },
  { value: 'tipo_motorista', name: 'Tipos de Motorista' },
  { value: 'tipo_veiculo', name: 'Tipos de Veículo' },
];

const excelFields = [
  {
    label: 'Alcançado (Pts/Km)',
    value: 'value',
  },
  {
    label: 'Meta (Pts/Km)',
    value: 'meta',
  },
  {
    label: 'Período',
    value: 'periodo',
  },
  {
    label: 'Variação (Pts/Km',
    value: 'variacao',
  },
];

const sortBy = [
  {
    id: 'alcancado',
    desc: true,
  },
];

const DesempenhoGeral = () => {
  const theme = useTheme();
  const mainRef = useRef(null);
  const dispatch = useDispatch();
  const { user } = useSelector(prevFilter => prevFilter.auth.user);
  const userLevel = user?.nivel;
  const isProvider = user?.provider;
  const [filter, filterEstatisticas, groupBy] = useSelector(state => {
    return [
      isProvider ? state.filterProvider : state.filter,
      state.filterFadigaDesempenho.filter,
      state.filterFadigaDesempenho.groupBy,
    ];
  });

  const [tableData, setTableData] = useState([]);
  const [indicadores, setIndicadores] = useState(null);
  const [series, setSeries] = useState([]);

  const [empresaModal, setEmpresaModal] = useState(false);
  const [filterModalOpen, setFilterModalOpen] = useState(false);

  const countFilter = Object.getOwnPropertyNames(filterEstatisticas).length;
  const chartColors = paletteColors.default;

  const [graphLoading, setgraphLoading] = useState(true);
  const [loadingExcel, setLoadingExcel] = useState(false);

  const valuesSelect = useMemo(() => {
    return [
      isProvider
        ? { value: 'empresas', name: 'Empresas' }
        : { value: 'client', name: 'Clientes' },
      !isProvider && { value: 'filial', name: 'Filiais' },
      ..._valuesSelect,
    ].filter(i => !!i);
  }, [isProvider]);

  const setFilterEstatisticas = handleChange => {
    const newState = handleChange(filterEstatisticas);
    dispatch(setFilter(newState));
  };

  // Fetch data
  const fetchGraficos = async query => {
    setgraphLoading(true);
    const res = await requestEstatisticas(isProvider, query);
    if (res.data?.success) {
      setIndicadores(res.data.data);
      setTableData(
        res.data.data.map((item, index) => ({
          ...item,
          initialDate: subHours(new Date(filter.initialDate), 3),
          finalDate: subHours(new Date(filter.finalDate), 3),
          fill: chartColors[index % chartColors.length],
        })),
      );
      setSeries(res.data.series);
    } else if (res.data?.message) toast.error(res.data.message);
    setgraphLoading(false);
  };

  const formatArrayFilter = useCallback(
    filters => {
      const _filters = {};
      Object.keys(filters).forEach(key => {
        _filters[key] = filters[key].split(',');
      });

      // Se nivel 2 ou 3 sempre filtra filial
      if (userLevel > 1) _filters.filial = [user?.id_da_filial];
      _filters.categoria = ['FADIGA'];

      return _filters;
    },
    [userLevel, user],
  );

  useEffect(() => {
    if (!groupBy) {
      dispatch(setGroupBy(isProvider ? 'empresas' : 'client'));
    }
  }, [isProvider]);

  useEffect(() => {
    if (groupBy) {
      const query = {
        initialDate: subHours(new Date(filter.initialDate), 3),
        finalDate: subHours(new Date(filter.finalDate), 3),
        ...formatArrayFilter(filterEstatisticas),

        groupBy,
      };
      if (query.carregado?.length)
        query.carregado = query.carregado.map(item =>
          item === 'true' ? 1 : item === 'false' ? 0 : item,
        );
      if (query.agregado?.length)
        query.agregado = query.agregado.map(item =>
          item === 'true' ? 1 : item === 'false' ? 0 : item,
        );
      fetchGraficos(query);
    }
  }, [filter, filterEstatisticas, groupBy]);

  const clearFilter = filterId => {
    setFilterEstatisticas(filter => {
      const _filter = { ...filter };
      delete _filter[filterId];
      return _filter;
    });
  };

  const handleChangeGroup = e => {
    if (
      isProvider &&
      e.target.value === 'filial' &&
      (!filterEstatisticas.empresas ||
        filterEstatisticas.empresas?.split(',').length !== 1)
    ) {
      setEmpresaModal(true);
      return;
    }

    // limpa filtro correspondente ao agrupamento
    switch (e.target.value) {
      case 'client':
        clearFilter('client');
        break;
      case 'empresas':
        clearFilter('empresas');
        clearFilter('filial');
        break;
      case 'filial':
        clearFilter('filial');
        break;
      case 'desvio':
        clearFilter('idDesvio');
        break;
      case 'operacoes':
        clearFilter('operacoes');
        break;
      case 'tipo_motorista':
        clearFilter('motoristas');
        break;
      case 'tipo_veiculo':
        clearFilter('idVeiculo');
        break;
    }
    dispatch(setGroupBy(e.target.value));
  };

  // Exportção excel
  const handleExcel = async () => {
    setLoadingExcel(true);

    const formatedDate = formatNameDate(new Date());
    const newQuery = {
      ...filter,
      ...formatArrayFilter(filterEstatisticas),
      groupBy,
      excelFields: [
        {
          label: valuesSelect.find(i => i.value === groupBy).name,
          value: 'name',
        },
        ...excelFields,
      ],
    };
    if (newQuery.carregado?.length)
      newQuery.carregado = newQuery.carregado.map(item =>
        item === 'true' ? 1 : item === 'false' ? 0 : item,
      );
    if (newQuery.agregado?.length)
      newQuery.agregado = newQuery.agregado.map(item =>
        item === 'true' ? 1 : item === 'false' ? 0 : item,
      );

    const res = await requestExcel(isProvider, newQuery);
    if (res.data && res.data?.data?.excel) {
      ExportToExcel({
        excel: res.data.data.excel,
        name: `estatisticas_fadiga_${formatedDate}`,
      });
      toast.success(res.data.message);
    } else if (res.data.message) toast.error(res.data?.message);
    setLoadingExcel(false);
  };

  // Formatação das colunas
  const formatColumns = useCallback(
    col =>
      col.map(i => {
        if (i.id === 'titulo')
          return {
            ...i,
            Header: valuesSelect.find(i => i.value === groupBy)?.name ?? '',
            Cell: ({ row }) => {
              return (
                <S.TableTitle>
                  <S.TableLegend color={row.original.fill} />
                  <S.TableName>{row.original.name}</S.TableName>
                </S.TableTitle>
              );
            },
          };

        return i;
      }),
    [groupBy],
  );

  // renders
  const renderIndicadorGraph = () => (
    <div
      style={{
        marginTop: '20px',
        position: 'relative',
        maxWidth: mainRef?.current?.clientWidth || '100%',
      }}
    >
      <Graph
        data={indicadores}
        barSeries={series}
        barLabel={series?.length ? series : []}
        barColors="default"
        lineSeries={['meta']}
        lineLabel={['Meta de Pontos/Km']}
        lineColors="status"
        title="Pontos/Km"
        loading={graphLoading}
        rightYAxisMax={dataMax => Number((dataMax + dataMax * 0.05).toFixed(4))}
        tooltip
        legend
        tabComponent={
          <div
            style={{
              display: 'flex',
              gap: '1rem',
            }}
          >
            <Select
              data={valuesSelect}
              value={groupBy}
              handleChange={handleChangeGroup}
              disabled={graphLoading}
            />
            <S.StyledButton
              textcolor={theme.palette.words.text.natural}
              backgroundcolor="transparent"
              startIcon={<SvgIcon component={filterIco} />}
              height="52px"
              variant="contained"
              onClick={() => setFilterModalOpen(true)}
              disabled={graphLoading}
            >
              Filtros
              <S.Count count={!!countFilter}>{countFilter}</S.Count>
            </S.StyledButton>
          </div>
        }
      />
    </div>
  );

  const renderTable = () => (
    <div style={{ marginTop: '20px' }}>
      <TableLocal
        columns={formatColumns(columns)}
        data={tableData}
        sortBy={sortBy}
        permitIsSortedOccur
        tableType
        loading={graphLoading}
        bulkActions={[]}
      />
    </div>
  );

  const renderMain = () => (
    <>
      <S.Main ref={mainRef}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} xl={12}>
            {renderIndicadorGraph()}
          </Grid>
          <Grid item xs={12} md={12} xl={12}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                margin: '1rem 0',
              }}
            >
              <div />
              <S.Title>
                Resumo{' '}
                <Tooltip
                  title="Resumo das informações apresentadas no gráfico"
                  placement="top"
                >
                  <HelpOutlineOutlined
                    sx={{ fontSize: '1rem' }}
                    htmlColor={theme.palette.words.text.light}
                  />
                </Tooltip>
              </S.Title>
              <GhostButton
                startIcon={<SaveAlt />}
                loading={loadingExcel}
                size="medium"
                onClick={() => handleExcel()}
                style={{ marginLeft: '10px' }}
              >
                EXPORTAR
              </GhostButton>
            </div>
          </Grid>
          <Grid item xs={12} md={12} xl={12}>
            {renderTable()}
          </Grid>
        </Grid>
      </S.Main>

      <FilterModal
        open={filterModalOpen}
        filter={filterEstatisticas}
        setFilter={setFilterEstatisticas}
        handleClose={() => setFilterModalOpen(false)}
        groupBy={groupBy}
      />
      {isProvider && (
        <EmpresaModal
          open={empresaModal}
          handleClose={() => setEmpresaModal(null)}
          handleConfirm={empresa => {
            setFilterEstatisticas(filter => ({ ...filter, empresas: empresa }));
            dispatch(setGroupBy('filial'));
          }}
        />
      )}
    </>
  );

  return renderMain();
};

export default DesempenhoGeral;
