import api from 'services/api';

export const requestRanking = async query => {
  const res = await api.get(`/empresas/ranking`, { params: query });
  return res;
};

export const requestResumo = async query => {
  const res = await api.get(`/empresas/ranking/resumo`, { params: query });
  return res;
};

export const requestExcel = async query => {
  const res = await api.get('/excel-provider/empresas_ranking', {
    params: query,
  });
  return res.data || res;
};
