import { paletteColors, colors } from 'styles/colors';

export const objetos = [
  { name: 'BEBIDA', value: 'BEBIDA' },
  { name: 'ALIMENTO', value: 'ALIMENTO' },
  { name: 'CIGARRO', value: 'CIGARRO' },
  { name: 'OUTRO', value: 'OUTRO' },
];

export const howToColor = (criticality, theme) => {
  switch (criticality) {
    case 'MODERADA':
      return theme.palette.semantics.feedback.warning.natural;
    case 'GRAVE':
      return theme.palette.semantics.feedback.attention.natural;
    case 'GRAVÍSSIMA':
      return theme.palette.semantics.feedback.attention.natural;
    default:
      return theme.palette.semantics.feedback.information.natural;
  }
};
