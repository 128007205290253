import produce from 'immer';
import * as types from './types';

const INITIAL_STATE = {
  pageMap: null,
  currentPlan: null,
  last_pageMap: null,
};

export default function pageMap(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case types.SET_PAGEMAP_SUCCESS: {
        draft.pageMap = action.payload.query.pageMap;
        draft.last_pageMap = action.payload.query.last_pageMap;
        break;
      }
      case types.SET_CURRENTPLAN: {
        draft.currentPlan = action.payload.currentPlan;
        break;
      }
      default:
    }
  });
}
