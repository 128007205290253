export const get_lista_justificativas = titulo => {
  if (
    [
      'Interação com objeto',
      'Sem cinto',
      'Motorista sem uniforme',
      'Objetos soltos na cabine',
      'Cortina fechada',
      'Ingestão de Água',
      'Celular',
    ].includes(titulo)
  ) {
    return ['Desvio Duplicado', 'Desvio Incompatível'];
  }
  if (['Distração', 'Mão fora do volante'].includes(titulo)) {
    return ['Desvio Duplicado', 'Manutenção', 'Desvio Incompatível'];
  }
  // if (['Carona'].includes(titulo)) {
  //   return [
  //     'Desvio Duplicado',
  //     'Carona Autorizada',
  //     'Manutenção',
  //     'Desvio Incompatível',
  //   ];
  // }

  return [
    'Desvio Duplicado',
    'Carona Autorizada',
    'Manutenção',
    'Desvio Incompatível',
  ];
};
