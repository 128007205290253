import styled from 'styled-components';

export const Container = styled.div`
  padding-bottom: 30px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  position: relative;
  width: 100%;
  overflow: hidden;
`;
