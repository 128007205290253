import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme, isSelected }) => css`
    flex: 1;
    background-color: #fff;
    border: 2px solid
      ${isSelected
        ? theme.palette.brand.secondary.natural
        : theme.palette.words.text.light};
    height: 273px;
    border-radius: 5px;
    margin: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: all ease 0.3s;
    padding: 20px 0px;

    &:hover {
      border-color: ${theme.palette.brand.secondary.natural};
      background-color: rgba(245, 245, 245, 10);
      transform: scale(1.05);
    }
  `}
`;

export const Header = styled.div`
  ${({ theme, isSelected }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 40px;

    .titleArea {
      font-weight: 600;
      color: ${isSelected
        ? theme.palette.brand.secondary.natural
        : theme.palette.words.subtitle.natural};
    }
  `}
`;

export const Content = styled.div`
  ${({ theme, isSelected }) => css`
    display: flex;
    align-items: center;
    width: 70%;

    .boxItem {
      width: 100%;
      display: flex;
      justify-content: space-between;
      border: 1px solid
        ${isSelected
          ? theme.palette.brand.secondary.natural
          : theme.palette.semantics.feedback.unknown.natural};
      border-radius: 5px;
      height: 50px;

      .option {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        border-right: 1px solid
          ${isSelected
            ? theme.palette.brand.secondary.natural
            : theme.palette.semantics.feedback.unknown.natural};

        label {
          margin-left: 5px;
          color: ${props =>
            props.isSelected
              ? theme.palette.brand.secondary.natural
              : theme.palette.words.subtitle.natural};
          font-weight: 600;
          font-size: 15px;
        }
      }

      .option:last-child {
        border: none;
      }
    }
  `}
`;

export const Footer = styled.div``;
