import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    border: 2px solid ${theme.palette.system.divider};
    border-radius: 5px;
  `}
`;

export const Header = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${theme.palette.semantics.feedback.unknown.light};
    padding: 12px 20px;
    cursor: pointer;

    .textHeader {
      font-weight: bold;
      color: ${theme.palette.words.title.natural};
      font-size: 14px;
      text-transform: uppercase;
    }

    .rightArea {
      display: flex;

      .countArea {
        color: ${theme.palette.words.subtitle.natural};
        font-weight: 600;
      }

      .expandArea {
        margin-left: 5px;
        cursor: pointer;
      }
    }
  `}
`;

export const Body = styled.div``;

export const Row = styled.span`
  ${({ theme, ...props }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px 20px;
    background-color: ${props.value
      ? theme.palette.brand.primary.background
      : theme.palette.system.overlay};
    border-bottom: 2px solid ${theme.palette.system.border};

    &:last-child {
      border: none;
    }

    .answerArea {
      display: inline-block;
      color: ${theme.palette.words.title.natural};
      font-weight: 500;

      .MuiSvgIcon-root {
        font-size: 20px;
        margin-bottom: -5px;
        margin-left: 5px;
      }
    }

    .responseArea {
      display: flex;
      align-items: center;

      .anomalyArea {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }

        span {
          margin-left: 5px;
          margin-right: 10px;
          color: ${theme.palette.words.subtitle.natural};
          font-weight: 600;
        }
      }

      .newInfraction {
        color: ${theme.palette.semantics.feedback.attention.natural};
        font-weight: 600;
        cursor: pointer;
        margin-right: 10px;
        margin-left: 10px;

        &:hover {
          opacity: 0.8;
        }
      }

      .boxArea {
        display: flex;
        padding: 2px 10px;

        &.disabled {
          opacity: 0.5;

          span {
            cursor: default;
          }
        }

        span {
          padding: 6px 10px;
          font-weight: 600;
          cursor: pointer;
        }

        .yes {
          border: 2px solid
            ${props.resp === 'SIM'
              ? theme.palette.semantics.feedback.success.natural
              : theme.palette.semantics.feedback.unknown.natural};
          border-right: 2px solid
            ${props.resp === 'NAO'
              ? theme.palette.semantics.feedback.attention.natural
              : 'none'};
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
          background-color: ${props.resp === 'SIM'
            ? theme.palette.semantics.feedback.success.natural
            : ''};
          color: ${props.resp === 'SIM'
            ? theme.palette.words.text.contrast
            : theme.palette.words.text.light};
        }

        .no {
          border: 2px solid
            ${props.resp === 'NAO'
              ? theme.palette.semantics.feedback.attention.natural
              : theme.palette.semantics.feedback.unknown.natural};
          border-left: none;
          border-right: none;
          background-color: ${props.resp === 'NAO'
            ? theme.palette.semantics.feedback.attention.natural
            : ''};
          color: ${props.resp === 'NAO'
            ? theme.palette.words.text.contrast
            : theme.palette.words.text.light};
        }

        .na {
          border: 2px solid
            ${props.resp === 'N/A'
              ? theme.palette.semantics.feedback.information.natural
              : theme.palette.semantics.feedback.unknown.natural};
          border-left: 2px solid
            ${props.resp === 'NAO'
              ? theme.palette.semantics.feedback.attention.natural
              : 'none'};
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
          background-color: ${props.resp === 'N/A'
            ? theme.palette.semantics.feedback.information.natural
            : ''};
          color: ${props.resp === 'N/A'
            ? theme.palette.words.text.contrast
            : theme.palette.words.text.light};
        }

        .nona {
          border: 2px solid
            ${props.resp === 'NAO'
              ? theme.palette.semantics.feedback.attention.natural
              : theme.palette.semantics.feedback.unknown.natural};
          border-left: 2px solid
            ${props.resp === 'NAO'
              ? theme.palette.semantics.feedback.attention.natural
              : 'none'};
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
          background-color: ${props.resp === 'NAO'
            ? theme.palette.semantics.feedback.attention.natural
            : ''};
          color: ${props.resp === 'NAO'
            ? theme.palette.words.text.contrast
            : theme.palette.words.text.light};
        }
      }
    }
  `}
`;
