// Pages
import { Multas } from 'pages/Multas';
import { CriarMulta } from 'pages/Multas/Criar';
import { DetalheMulta } from 'pages/Multas/Detalhe';
import AddMultas from 'pages/Multas/AddMultas';
import Historico from 'pages/Historico';

export default {
  '/multas': {
    back: -1,
    title: 'Multas',
    page: <Multas />,
  },
  '/multas/:id': {
    title: 'Multa',
    subTitle: 'Multas',
    id: true,
    back: -1,
    page: <DetalheMulta />,
  },
  '/multas/criar': {
    title: 'Novo registro',
    subTitle: 'Multas',
    back: -1,
    page: <CriarMulta />,
  },
  '/multas/editar/:id': {
    title: 'Multa',
    subTitle: 'Multas',
    id: true,
    back: -1,
    page: <CriarMulta edit />,
  },
  '/multas/add-multas': {
    back: '/multas',
    title: 'Adicionar em Massa - Multas',
    defaults: [],
    page: <AddMultas />,
  },
  '/logs/multas/:id': {
    back: -1,
    title: 'Histórico da Multa',
    id: true,
    defaults: [],
    page: (
      <Historico
        back={-1}
        url="/multas/:id/logs"
        formatReturn={data =>
          data.map(log => ({
            ...log,
            data: log.data ?? log.createdAt,
            evento: log.evento ? log.evento.evento : '',
          }))
        }
      />
    ),
  },
};
