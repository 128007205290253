import produce from 'immer';
import * as types from './types';

const INITIAL_STATE = {
  contents: [],
  trySave: false,
  medias: [],
  tampleteId: null,
};

export default function createContents(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case types.SET_DATA: {
        draft.contents = action.payload.data;
        break;
      }

      case types.CHANGE_STATE_SAVE: {
        draft.trySave = action.payload.isValid;
        break;
      }

      case types.SET_MEDIA: {
        draft.medias = action.payload.data;
        break;
      }

      case types.RESET_STATE: {
        draft.contents = [];
        break;
      }
      case types.IS_TAMPLETE: {
        draft.tampleteId = action.payload.data;
        break;
      }
      default:
    }
  });
}
