import { formatNewDate } from 'utils/dates';

const emptyState = {
  title: 'Ops! Você não tem nenhuma carona disponível.',
  subtitle: 'Verifique os filtros aplicados!',
};

export const columns2 = [
  {
    header: 'Id',
    id: 'id',
    sort: true,
    type: 'number',
    align: 'center',
    width: 100,
  },
  {
    header: 'Transportadora',
    id: 'avaliacao.filial.empresa.nome',
    sort: false,
    type: 'string',
  },
  {
    header: 'Filial',
    id: 'avaliacao.filial.nome',
    sort: false,
    type: 'string',
  },
  {
    header: 'Placa',
    id: 'avaliacao.caminhao.placa',
    sort: false,
    type: 'string',
    style: { textTransform: 'uppercase' },
  },
  {
    header: 'Data Ocorrência',
    id: 'data_ocorrencia',
    align: 'center',
    sort: true,
    value: value => formatNewDate(value),
  },
];

export { emptyState };
