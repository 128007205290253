/* eslint-disable import/order */

// Styles
import * as S from './styled';
import colors from 'styles/colors';

// React
import { useState } from 'react';
import { toast } from 'react-toastify';

// Components
import { ModalSort } from '../ModalSort';
import { InitialGuide } from '../../Inicio';
import { ModalConfig } from '../ModalConfig';
import { Switch } from 'components/Inputs/Switch';

// Components MUI
import TvOutlinedIcon from '@mui/icons-material/TvOutlined';
import ListRoundedIcon from '@mui/icons-material/ListRounded';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import BookmarkAddedOutlinedIcon from '@mui/icons-material/BookmarkAddedOutlined';

// Constants
import * as services from '../services';

export const FirstSteps = ({ guide, setSections }) => {
  const steps = guide?.etapas;
  const [stepItem, setStepItem] = useState(null);
  const [modalConfig, setModalConfig] = useState(false);
  const [modalSortGuide, setModalSortGuide] = useState(false);
  const [modalGuide, setModalGuide] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleCheck = async id => {
    const res = await services.updateFirstStatus(id);
    if (res.success) {
      toast.success(res.message);
      const newSteps = guide?.etapas.map(step => {
        if (step.id === id) {
          return { ...step, ativa: !step.ativa };
        }
        return step;
      });
      setSections({ ...guide, etapas: newSteps });
    } else {
      toast.error(res.message);
    }
  };

  const setSteps = async newStep => {
    setLoading(true);

    const newSteps = steps.map((step, index) => {
      if (step.id === newStep.id) {
        return { ...newStep, ordem: index + 1 };
      }
      return { ...step, ordem: index + 1 };
    });

    const newData = { ...guide, etapas: newSteps };
    const res = await services.updateContent(newData);
    setLoading(false);

    if (res.success) {
      toast.success(res.message);
      setSections(newData);
    } else {
      toast.error(res.message);
    }
  };

  const setNewOrder = async newOrder => {
    const res = await services.orderFirstSteps(newOrder);

    if (res.success) {
      toast.success(res.message);
      setSections({ ...guide, etapas: newOrder });
      return true;
    }
    return false;
  };

  return (
    <S.Container>
      <S.OrderLine>
        <button onClick={() => setModalGuide(true)}>
          <TvOutlinedIcon className="prev" />
          Ver Prévia
        </button>
        <button onClick={() => setModalSortGuide(true)}>
          <ListRoundedIcon />
          Ordenar etapas
        </button>
      </S.OrderLine>
      {guide?.etapas?.map((step, key) => (
        <S.BoxLine key={key} active={step.ativa}>
          <div className="page">
            <BookmarkAddedOutlinedIcon
              htmlColor={step?.ativa ? colors.greenSucces : colors.greyTiny}
            />
            <div className="title">{step?.titulo}</div>
          </div>
          <div className="actions">
            <Switch
              check={step?.ativa}
              setCheckbox={() => handleCheck(step.id)}
            />
            <EditOutlinedIcon
              onClick={() => {
                setStepItem(step);
                setModalConfig(true);
              }}
              className="edit"
              htmlColor={colors.greenMain}
            />
          </div>
        </S.BoxLine>
      ))}

      <ModalConfig
        edit={stepItem}
        open={modalConfig}
        index={stepItem ? steps.indexOf(stepItem) : null}
        handleClose={() => {
          setModalConfig(false);
          setStepItem(null);
        }}
        setSteps={setSteps}
        loading={loading}
        firstSteps
      />

      <ModalSort
        title="Ordenar primeiros passos"
        data={steps}
        open={modalSortGuide}
        handleClose={() => setModalSortGuide(false)}
        handleSave={setNewOrder}
      />

      {modalGuide && (
        <InitialGuide
          open={modalGuide}
          handleClose={() => setModalGuide(false)}
          info={{ etapas: steps }}
          preview
        />
      )}
    </S.Container>
  );
};
