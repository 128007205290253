import styled, { css } from 'styled-components';

const Main = styled.div`
  display: flex;
  flex-direction: column;
`;

const Status = styled.div`
  padding: 4px 10px;
  display: flex;
  border-radius: 50px;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  background-color: ${props => `${props.color}30`};

  p {
    color: ${props => props.color};
    font: normal normal bold 18px/18px Texta;
  }
`;

const SpacedRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const ColumnWrapper = styled.div`
  ${({ theme }) => css`
    background: ${theme.palette.system.overlay};
    border-radius: 4px;
    border: 1px solid ${theme.palette.brand.primary.light}4d;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 25px;
  `}
`;

const StyledLogoWrapper = styled.div`
  ${({ theme }) => css`
    margin-right: 10px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid ${theme.palette.brand.secondary.natural};
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;

const StyledLogo = styled.div`
  ${({ theme }) => css`
    height: 42px;
    width: 42px;
    border-radius: 50%;
    border: ${props => props.border};
    background-image: ${props => props.backgroundImage};
    background-color: ${theme.palette.semantics.feedback.unknown.light};
    display: flex;
    align-items: center;
    justify-content: center;

    h2 {
      font-size: 20px;
      color: ${theme.palette.brand.primary.natural};
      text-align: center;
    }
  `}
`;

const StyledTextWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    h1 {
      font-size: 16px;
      color: ${theme.palette.words.title.natural};
      font-weight: 600;
      text-transform: capitalize;
      text-align: left;
    }
    p {
      color: ${theme.palette.words.subtitle.natural};
      font-size: 14px;
      font-weight: 500;
      text-align: left;
    }
  `}
`;

const NavigateButton = styled.button`
  width: 52px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: white;
  border-radius: 52px;
  transition: all 0.2s;
  margin-right: 15px;

  :hover {
    background: rgba(250, 250, 250, 1);
  }

  :active {
    background: rgba(240, 240, 240, 1);
  }

  ${props =>
    props.disabled &&
    `
    pointer-events: none;
  `}
`;

export {
  Main,
  Status,
  SpacedRow,
  ColumnWrapper,
  StyledLogoWrapper,
  StyledLogo,
  StyledTextWrapper,
  NavigateButton,
};
