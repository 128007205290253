// Pages
import { Equipe } from 'pages/Equipe';
import { Estatisticas } from 'pages/Equipe/Estatisticas';
import DesempenhoEquipe from 'pages/Equipe/DesempenhoEquipe';

export default {
  '/equipe': {
    back: -1,
    title: 'Equipe',
    date: false,
    page: <Equipe />,
  },
  '/equipe/:id': {
    back: -1,
    title: 'Equipe',
    date: false,
    page: <Estatisticas />,
  },
  '/equipe/desempenho-equipe': {
    back: -1,
    title: 'Desempenho da equipe',
    date: false,
    page: <DesempenhoEquipe />,
  },
};
