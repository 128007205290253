/* eslint-disable import/order */

// Styles
import * as S from './styled';
import { useTheme } from 'styled-components';

// Material UI
import { IconButton } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

export const MediaCard = ({ content, counters, pressMinus, pressMore }) => {
  const theme = useTheme();
  const { counterKey, type, Icon, label } = content;

  return (
    <S.Container counter={counters[counterKey] > 0} className="box">
      <div className="header">
        <S.Counter counter={counters[counterKey]}>
          {counters[counterKey]}
        </S.Counter>
      </div>
      <div className="content">
        <Icon
          sx={{
            marginBottom: 1,
            color:
              counters[counterKey] > 0
                ? theme.palette.brand.secondary.natural
                : theme.palette.words.text.light,
          }}
        />
        <label className="typeCard">{label}</label>
      </div>
      <div className="footer">
        <IconButton onClick={() => pressMinus(counterKey, type)} size="small">
          <RemoveCircleOutlineIcon
            sx={{
              color:
                counters[counterKey] > 0
                  ? theme.palette.brand.secondary.natural
                  : theme.palette.words.text.light,
            }}
          />
        </IconButton>
        <IconButton size="small" onClick={() => pressMore(counterKey, type)}>
          <AddCircleOutlineIcon
            sx={{
              color:
                counters[counterKey] > 0
                  ? theme.palette.brand.secondary.natural
                  : theme.palette.words.text.light,
            }}
          />
        </IconButton>
      </div>
    </S.Container>
  );
};
