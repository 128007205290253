import React, { useMemo } from 'react';

import Tabs from 'components/Tabs';

import { GraphBar } from './GraphBar';
import PieGraph from 'pages/Desvios/Estatisticas/TratativasDesvios/components/PieGraph';

import * as S from './styled';
import { useQuery } from 'react-query';
import { getRevisoes } from '../../services';

const tabs = [
  {
    label: 'CRITICIDADE',
    value: 'CRITICIDADE',
  },
  {
    label: 'DESVIOS',
    value: 'DESVIOS',
  },
  {
    label: 'EMPRESAS',
    value: 'EMPRESAS',
  },
];

const subs = [
  {
    text: 'Novas solicitações de exclusão',
    color: '#4B5166',
  },
  {
    text: 'Em reavaliação',
    color: '#0C12F2',
  },
  {
    text: 'Aprovadas',
    color: '#BDCC30',
  },
  {
    text: 'Rejeitadas',
    color: '#FFA801',
  },
  {
    text: 'Vencidas',
    color: '#F64E60',
  },
];

export const RevisoesExclusao = ({ id, filter, groupBy, setGroupBy }) => {
  const { isLoading: isLoadingRevisoes, data: revisoes = [] } = useQuery(
    ['equipe-revisoes', id, groupBy, filter],
    () => getRevisoes(id, { ...filter, groupBy }),
    {
      refetchOnWindowFocus: false,
    },
  );

  const chartData = useMemo(() => {
    const data = revisoes.reduce((acc, item) => {
      const name = Object.keys(item)[0];
      acc.push({
        name,
        ...item[name],
      });
      return acc;
    }, []);
    if (groupBy !== 'CRITICIDADE') data.sort((a, b) => b.total - a.total);
    return data;
  }, [revisoes, groupBy]);

  const pieData = useMemo(
    () =>
      chartData.reduce(
        (acc, item) => {
          acc.find(i => i.name === 'Novas solicitações de exclusão').value +=
            item['Novas solicitações de exclusão'];
          acc.find(i => i.name === 'Aprovadas').value += item.Aprovadas;
          acc.find(i => i.name === 'Rejeitadas').value += item.Rejeitadas;
          acc.find(i => i.name === 'Em reavaliação').value +=
            item['Em reavaliação'];
          acc.find(i => i.name === 'Vencidas').value += item.Vencidas;
          return acc;
        },
        [
          { name: 'Novas solicitações de exclusão', value: 0 },
          { name: 'Em reavaliação', value: 0 },
          { name: 'Aprovadas', value: 0 },
          { name: 'Rejeitadas', value: 0 },
          { name: 'Vencidas', value: 0 },
        ],
      ),
    [chartData],
  );

  return (
    <S.Container>
      <div className="middle">
        <PieGraph
          data={pieData}
          loading={isLoadingRevisoes}
          colors={['#4B5166', '#0C12F2', '#BDCC30', '#FFA801', '#F64E60']}
        />
        <div
          style={{
            width: '80%',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginLeft: 60,
              paddingBottom: 14,
            }}
          >
            <Tabs
              onChange={(e, val) => setGroupBy(val)}
              value={groupBy}
              items={tabs}
            />
          </div>
          {isLoadingRevisoes || revisoes.length ? (
            <GraphBar data={chartData} loading={isLoadingRevisoes} />
          ) : (
            <div
              style={{
                color: 'rgb(101, 110, 140)',
                fontSize: 18,
                height: '250px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              Não existem dados para serem exibidos, tente usar os filtros
            </div>
          )}
        </div>
      </div>
      <div className="footer">
        {subs.map(item => (
          <>
            <div className="point" style={{ backgroundColor: item.color }} />
            <div className="text">{item?.text || ''}</div>
          </>
        ))}
      </div>
    </S.Container>
  );
};
