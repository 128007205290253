import React from 'react';
import * as S from './styled';

export const Item = ({ text, index, infractions }) => {
  return (
    <S.Container>
      <div className="text">
        <div className="name" title={text}>
          #{index} {text}
        </div>
        <div className="desvios">{infractions} desvios</div>
      </div>
    </S.Container>
  );
};
