import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setItems } from 'store/modules/formularios/actions';

import { Tooltip } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import * as S from './styled';

const InputEdit = ({ item, type = 'normal' }) => {
  // General States
  const [inEdit, setInEdit] = useState(null);
  const [value, setValue] = useState(item.titulo);

  // Redux
  const dispatch = useDispatch();
  const { secoes } = useSelector(state => state.formularios);

  const setAvalableEdition = () => {
    setInEdit(true);
  };

  const confirmChanges = () => {
    setInEdit(false);
    const cloneItems = JSON.parse(JSON.stringify(secoes));
    for (const i in cloneItems) {
      if (cloneItems[i].index === item.index) {
        cloneItems[i].titulo = value || `Seção ${item.index}`;
      }
    }

    dispatch(setItems(cloneItems));
  };

  return (
    <S.Container type={type}>
      {inEdit && (
        <>
          <input
            value={value}
            onChange={e => setValue(e.target.value.slice(0, 50))}
            maxLength={50}
            placeholder="Preencha um titulo para a seção"
            autoFocus
            onBlur={confirmChanges}
          />

          <div className="counter">{`${value.length}/50`}</div>
        </>
      )}

      {!inEdit && <span onClick={() => setInEdit(true)}>{item.titulo}</span>}

      {inEdit && (
        <Tooltip title="Concluir">
          <CheckOutlinedIcon
            onClick={confirmChanges}
            style={{ cursor: 'pointer' }}
          />
        </Tooltip>
      )}

      {!inEdit && (
        <Tooltip title="Editar">
          <EditOutlinedIcon
            onClick={setAvalableEdition}
            style={{ cursor: 'pointer', marginRight: '10px' }}
          />
        </Tooltip>
      )}
    </S.Container>
  );
};

export default InputEdit;
