export const fields = [
  {
    label: 'ID',
    value: 'id',
    selected: true,
    default: true,
  },
  {
    label: 'Violação',
    value: 'violacao.descricao',
    selected: true,
    default: true,
  },
  {
    label: 'Motorista',
    value: 'motorista.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Filial',
    value: 'motorista.filial.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Status',
    value: 'status',
    selected: true,
    default: true,
  },
  {
    label: 'Responsável',
    value: 'responsavel.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Data ocorrência',
    value: 'data_ocorrencia',
    selected: true,
    default: true,
  },
  {
    label: 'Data feedback',
    value: 'data_feedback',
    selected: true,
    default: true,
  },
  {
    label: 'Pontuação ativa',
    value: 'pontuacao',
    selected: true,
    default: true,
  },
  {
    label: 'Pontuação inicial',
    value: 'violacao.pontos',
    selected: true,
    default: true,
  },
];

export const violacoesFields = [
  {
    label: 'Violação',
    value: 'descricao',
    selected: true,
    default: true,
  },
  {
    label: 'Status',
    value: 'status',
    selected: true,
    default: true,
  },
  {
    label: 'Pontuação',
    value: 'pontos',
    selected: true,
    default: true,
  },
  {
    label: 'Decaimento (dias)',
    value: 'decaimento_tempo',
    selected: true,
    default: true,
  },
  {
    label: 'Prazo (dias)',
    value: 'prazo',
    selected: true,
    default: true,
  },
  {
    label: 'Plano de ação',
    value: 'plano_de_acao',
    selected: false,
    default: false,
  },
];

export const resetExcelFields = fields => {
  return fields.map(i => {
    if (!i.default) i.selected = true;
    return i;
  });
};

export const tabs = [
  {
    value: 0,
    label: 'Pendente',
    status: 'PENDENTE',
  },
  {
    value: 1,
    label: 'Em análise',
    status: 'ANALISE',
  },
  {
    value: 2,
    label: 'Finalizada',
    status: 'APROVADA',
  },
  {
    value: 3,
    label: 'Deletada',
    status: 'DELETADA',
  },
];
