import React from 'react';
import { useTheme } from 'styled-components';
import { Check } from '@mui/icons-material';
import ConfirmModal from 'components/ConfirmModal';

const FinishModal = ({ ids, handleConfirm, handleClose, loading }) => {
  const theme = useTheme();
  return (
    <ConfirmModal
      handleClose={handleClose}
      open={Boolean(ids)}
      title={
        ids.length > 1
          ? `Deseja finalizar os desvios selecionados? (${ids.length})`
          : 'Deseja finalizar o desvio selecionado?'
      }
      titleIcon={
        <Check
          sx={{ color: theme.palette.brand.secondary.natural }}
          fontSize="medium"
        />
      }
      subtitle={
        ids.length > 1
          ? `Os desvios serão movidos para o status Finalizados. As alterações serão aplicadas em TODOS os desvios selecionados.`
          : 'O desvio será movido para o status Finalizados.'
      }
      buttonText="Confirmar"
      onClick={handleConfirm}
      loading={loading}
    />
  );
};

export default FinishModal;
