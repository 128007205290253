import { celMask, cnpjMask } from 'utils/masks';

export const fields = {
  1: [
    {
      label: 'Cliente',
      type: 'input',
      required: true,
      name: 'nome',
      default: '',
    },
    {
      label: 'IBM Cliente',
      type: 'input',
      required: true,
      name: 'ibm',
      default: '',
    },
    {
      label: 'Data',
      type: 'date',
      required: true,
      disabled: true,
      name: 'createdAt',
      default: new Date(),
    },
    {
      label: 'Pessoa de Contato',
      type: 'input',
      required: false,
      name: 'contato',
      default: '',
    },
    {
      label: 'Telefone de Contato',
      type: 'input',
      required: true,
      name: 'telefone_contato',
      mask: celMask,
      default: '',
    },
    {
      label: 'Base/Terminal',
      type: 'select',
      required: true,
      dynamic: 'bases',
      name: 'id_da_base',
      default: '',
    },
    {
      label: 'DCM',
      type: 'select',
      required: true,
      tooltip: 'Descarga controlada por motorista',
      dynamic: 'booleans',
      name: 'dcm',
      default: '',
    },
    {
      label: 'Tamanho Máx. Caminhão (m³)',
      type: 'select',
      required: true,
      dynamic: 'tamanhos',
      name: 'tamanho_maximo',
      default: '',
    },
    {
      label: 'Ct com bomba',
      type: 'select',
      required: true,
      tooltip: 'É necessário ct com bomba para efetuar a descarga.',
      dynamic: 'booleans',
      name: 'bomba',
      default: '',
    },
  ],
  4: [
    {
      label: 'Cliente',
      type: 'input',
      required: true,
      name: 'nome',
      default: '',
    },
    {
      label: 'CNPJ',
      type: 'input',
      required: true,
      name: 'ibm',
      mask: cnpjMask,
      default: '',
    },
    {
      label: 'Data',
      type: 'date',
      required: true,
      disabled: true,
      name: 'createdAt',
      default: '',
    },
    {
      label: 'Pessoa de Contato',
      type: 'input',
      required: false,
      name: 'contato',
      default: '',
    },
    {
      label: 'Telefone de Contato',
      type: 'input',
      required: true,
      name: 'telefone_contato',
      default: '',
      mask: celMask,
    },
    {
      label: 'Base/Terminal',
      type: 'select',
      required: true,
      dynamic: 'bases',
      name: 'id_da_base',
      default: '',
    },
    {
      label: 'Tamanho Máx. Caminhão (m³)',
      type: 'select',
      required: true,
      dynamic: 'tamanhos',
      name: 'tamanho_maximo',
      default: '',
    },
    {
      label: 'CT com bomba',
      type: 'select',
      required: true,
      tooltip: 'É necessário ct com bomba para efetuar a descarga.',
      dynamic: 'booleans',
      name: 'bomba',
      default: '',
    },
    {
      label: 'Tipo de cliente',
      type: 'select',
      required: true,
      dynamic: 'clients',
      name: 'tipo_de_cliente',
      default: '',
    },
    {
      label: 'Tipo de tancagem',
      type: 'select',
      required: true,
      dynamic: 'tancagem',
      name: 'tipo_de_tancagem',
      default: '',
    },
  ],
};

export const booleans = [
  { name: 'SIM', value: 'SIM' },
  { name: 'NÃO', value: 'NAO' },
];

export const clients = [
  { name: 'IPIRANGA EMPRESAS', value: 'EMPRESA' },
  {
    name: 'POSTO REVENDEDOR',
    value: 'REVENDEDOR',
  },
];

export const tancagem = [
  { name: 'ÁEREO', value: 'AEREO' },
  {
    name: 'SUBTERRÂNEO',
    value: 'SUBTERRANEO',
  },
];
