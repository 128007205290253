import styled from 'styled-components';

const Main = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: flex-start;
`;

const VideoContainer = styled.div`
  height: 70vh;
  border-radius: 10px;

  video {
    border-radius: 10px;
    height: 70vh;
  }
`;

export { VideoContainer, Main };
