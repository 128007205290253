import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 90%;
  max-width: 50rem;
  margin: auto;
  padding-bottom: 3rem;

  .map {
    height: 480px;
  }
`;

export const Title = styled.div`
  margin: 1rem 0;
`;

export const FormWrapper = styled.div`
  margin: 1rem 0;
`;

export const Section = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SectionTitle = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    h2: {
      font: normal normal 900 20px/20px Texta;
      color: ${theme.palette.words.subtitle.natural};
    }
  `}
`;

export const SwitchSection = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 16px;
`;
