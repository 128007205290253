// Styles

// React
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { reset } from 'store/modules/formularios/actions';
import { ReactComponent as file } from 'images/icons/sidebar/file.svg';

// Page Components
import Tabs from 'components/Tabs';
import ExcelModal from 'components/ExcelModal';
import GhostButton from 'components/Buttons/Ghost';
import { Divider } from '@mui/material';
import { SaveAlt } from '@mui/icons-material';
import ExportToExcel from 'utils/exportToCvs';
import { formatNameDate, formatNewDate } from 'utils/dates';
import { trackEvent } from 'utils/mixpanel';
import { useDebounce } from 'use-debounce';
import Tag from '../components/Tag';
import Title from '../components/Title';
import Search from '../components/Search';
import Loading from '../components/Loading';
import ModalResponse from '../components/ModalResponse';
// Geral Components
import Templates from '../components/Templates';
import SliderTag from '../components/SliderTag';

// Material Components

// Utils
import * as services from './services';
import { fields, resetFields } from '../constants';
import * as S from './styled';
import ModalSign from '../components/ModalSign';

let timer = null;
let timerRoute = null;

const FormulariosParaMim = () => {
  // Patch
  const location = useLocation();
  const params = location.state;
  const pathname = location.pathname.slice(13);

  // Redux
  const dispatch = useDispatch();
  const user = useSelector(state => {
    return state.auth?.user?.user;
  });
  const filterForm = useSelector(state => state.filterFormularioMeus);

  // General States
  const [currentTab, setCurrentTab] = useState(0);
  const [firstCharge, setFirstCharge] = useState(true);
  const [concluidos, setConcluidos] = useState(null);
  const [filteredConcluidos, setFilteredConcluidos] = useState(null);
  const [templates, setTemplates] = useState(null);
  const [filteredTemplates, setFilteredTemplates] = useState(null);
  const [tags, setTags] = useState([]);
  const [modalResponse, setModalResponse] = useState(false);
  const [inSearch, setInSearch] = useState(false);
  const [formResponse, setFormResponse] = useState(null);
  const [modalView, setModalView] = useState(false);
  const [query, setQuery] = useState({
    card: null,
    tag: 'Todos',
    search: '',
  });
  const [searchTrackEvent] = useDebounce(query.search, 1000);

  // -------------------------------------PAGE CONTROLLER---------------------------------------//

  const {
    data: res,
    isError,
    isLoading: loading,
    refetch,
  } = useQuery(['my-forms', 1], () => services.GetForms(), {
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    const responseForm = async id => {
      const res = await services.getFormResponse(id);

      if (res.success) {
        setFormResponse(res.data);
        setModalResponse(true);
      } else {
        toast.error(res.message || 'Erro ao carregar formulário');
      }
    };

    const idFormulario = params?.idFormulario;
    if (idFormulario) responseForm(idFormulario);
  }, [params]);

  useEffect(() => {
    if (loading) return null;

    if (!isError && res.data) {
      setTemplates(res.data.ativos);
      setConcluidos(res.data.concluidos);

      setTags(res.data.tags);
      if (pathname !== 'criar') dispatch(reset());
    } else {
      toast.error(res.message || 'Erro ao carregar formulários');
    }
  }, [res]);

  const handlePageChange = (e, tab) => {
    trackEvent(user, `FORMULÁRIOS: ${tab === 0 ? 'Ativos' : 'Concluídos'}`);
    setCurrentTab(tab);
  };

  // Controll back arrow in window when modal is opened in same tab (BETA VERSION)
  useEffect(() => {
    if (!modalResponse && !firstCharge) {
      timerRoute = setTimeout(() => {
        window.onpopstate = event => {
          event.preventDefault();
        };
      }, 100);
    }

    return function cleanup() {
      clearTimeout(timerRoute);
    };
  }, [modalResponse, firstCharge]);

  // ------------------------------------FILTERS CONTROLLER-------------------------------------//
  const handleQuery = object => {
    setQuery({ ...query, ...object });
  };

  useEffect(() => {
    if (filterForm) setQuery({ ...query, ...filterForm });
  }, [filterForm]);

  // TAB ONE (Ativos)
  useEffect(() => {
    // Block exection if tab is direfent of 0
    if (currentTab !== 0) {
      return null;
    }

    let hasFilter = [];
    const temps = templates;
    let status = null;

    if (!temps || firstCharge) {
      setFilteredTemplates(null);
      setFirstCharge(false);
      return;
    }

    status = query?.status ? query.status.split(',') : false;
    if (status.length) {
      hasFilter = temps.filter(temp => status.includes(temp?.status));
    } else {
      hasFilter = temps;
    }

    if (query.search) {
      hasFilter = hasFilter.filter(temp => {
        const turmasName = temp.turmas
          .map(item => item.nome.toLowerCase())
          .join('*');

        if (
          temp.titulo
            .toLowerCase()
            .includes(query.search.toLocaleLowerCase()) ||
          temp.tags
            .toString()
            .toLocaleLowerCase()
            .includes(query.search.toLocaleLowerCase()) ||
          turmasName.includes(query.search.toLocaleLowerCase())
        ) {
          return temp;
        }
      });
    }

    if (query.tag !== 'Todos') {
      hasFilter = hasFilter.filter(temp => temp.tags.includes(query.tag));
    }

    setInSearch(true);
    clearTimeout(timer);

    timer = setTimeout(() => {
      if (!query.search && query.tag === 'Todos' && !status) {
        setFilteredTemplates(null);
      } else {
        setFilteredTemplates(hasFilter);
      }
      setInSearch(false);
    }, 400);
  }, [query, templates, currentTab]);

  // TAB TWO (Concluidos)
  useEffect(() => {
    // Block exection if tab is direfent of 0
    if (currentTab !== 1) {
      return null;
    }

    let hasFilter = concluidos;

    if (!hasFilter || firstCharge) {
      setFilteredConcluidos(null);
      setFirstCharge(false);
      return;
    }

    if (query.search) {
      hasFilter = hasFilter.filter(temp => {
        if (
          temp.titulo
            .toLowerCase()
            .includes(query.search.toLocaleLowerCase()) ||
          temp.tags
            .toString()
            .toLocaleLowerCase()
            .includes(query.search.toLocaleLowerCase())
        ) {
          return temp;
        }
      });
    }

    if (query.tag !== 'Todos') {
      hasFilter = hasFilter.filter(temp => temp.tags.includes(query.tag));
    }

    setInSearch(true);
    clearTimeout(timer);

    timer = setTimeout(() => {
      if (!query.search && query.tag === 'Todos' /* && !status */) {
        setFilteredConcluidos(null);
      } else {
        setFilteredConcluidos(hasFilter);
      }
      setInSearch(false);
    }, 400);
  }, [query, concluidos, currentTab]);

  useEffect(() => {
    // Valida busca com pelo menos 3 caracteres para o MixPanel
    if (searchTrackEvent.length >= 3) {
      trackEvent(user, 'Busca Formulários Para Mim', null, searchTrackEvent);
    }
  }, [searchTrackEvent]);

  // -------------------------- EXCEL ------------------------------------------//

  const [openExcelModal, setOpenExcelModal] = useState(false);
  const [excelFields, setExcelFields] = useState(fields);
  const [loadingExcel, setLoadingExcel] = useState(false);

  const handleRequestExcel = async () => {
    const newFields = excelFields.filter(item => item.selected === true);
    const formatedDate = formatNameDate(new Date());

    const excelArr = [];
    const data = filteredTemplates || templates;
    data.forEach(template => {
      let customizedFields = {};
      const multiplo = template.multiplo;

      newFields.forEach(item => {
        const { label } = item;
        let value =
          template[item.value] || template[item.value] === 0
            ? template[item.value]
            : '';

        // Formatação de value para datas
        if (
          label === 'Criado em' ||
          label === 'Data Encerramento' ||
          label === 'Última edição' ||
          label === 'Data Início'
        )
          value = formatNewDate(value);

        if (
          label === 'Gera Ocorrências' ||
          label === 'Obrigatório' ||
          label === 'Necessita Aprovação' ||
          label === 'Múltiplo'
        )
          value = value ? 'Sim' : 'Não';

        if (
          label === 'Responsável aprovação' ||
          label === 'Empresa' ||
          label === 'Criador' ||
          label === 'Responsável ocorrência'
        )
          value = value.nome;

        // Se for múltiplo ignora periodo e frequencia para não confundir
        if (multiplo && (label === 'Frequência' || label === 'Período'))
          value = '--';

        customizedFields = { ...customizedFields, [label]: value };
      });
      excelArr.push(customizedFields);
    });

    setLoadingExcel(true);
    ExportToExcel({
      excel: excelArr,
      name: `formularios_${formatedDate}`,
    });
    setExcelFields(resetFields(fields));
    setLoadingExcel(false);
    setOpenExcelModal(false);
  };

  // ------------------------- CALLED BY HISTORY -----------------------//
  const [formHist, setFormHist] = useState(null);
  const [loadingLine, setLoadingLine] = useState(false);
  const [currentHist, setCurrentHist] = useState(null);
  const [loadingHistoryLine, setLoadingHistoryLine] = useState(null);

  const getCurrentResponses = async (histId, formId) => {
    setCurrentHist(+histId);

    if (!histId) {
      toast.error(`Sem respostas disponíveis para esse aluno`);
      return null;
    }

    setLoadingLine(true);
    setCurrentHist(+histId);
    setLoadingHistoryLine(+histId);
    let res = await services.getFormResponseHistory(
      formId,
      histId,
      user.id,
      'GESTOR',
    );

    if (res.success) {
      setFormHist(res);
      setModalView(true);
    } else {
      toast.error(res.message);
    }
    setLoadingLine(false);
    setLoadingHistoryLine(false);
  };

  // ------------------------------------- RENDER ------------------------------------------//

  return (
    <S.Container>
      <S.Header>
        <Title>Meus Formulários</Title>

        <span className="buttonArea">
          <GhostButton
            startIcon={<SaveAlt />}
            size="medium"
            onClick={() => setOpenExcelModal(true)}
            children="Exportar"
            style={{ marginRight: '10px' }}
          />
        </span>
      </S.Header>
      <Tabs
        value={currentTab}
        items={[
          {
            value: 0,
            label: `Ativos ${templates ? `(${templates.length})` : ''}`,
          },
          {
            value: 1,
            label: `Concluídos ${concluidos ? `(${concluidos.length})` : ''}`,
          },
        ]}
        onChange={handlePageChange}
      />

      <Divider />

      <SliderTag width="72vw">
        {!loading &&
          tags.map((item, key) => (
            <Tag
              children={item}
              key={key}
              onClick={() => handleQuery({ tag: item })}
              active={item === query.tag}
              disabled={item === query.tag}
            />
          ))}
        {loading && <Tag loading />}
      </SliderTag>

      <S.SearchArea>
        <Search
          value={query.search}
          setValue={value => handleQuery({ search: value })}
        />
        <p>{query.tag}</p>
      </S.SearchArea>
      <S.Body inSearch={inSearch}>
        {!loading && templates && currentTab === 0 && !modalResponse && (
          <Templates
            templates={filteredTemplates || templates}
            conteudos={null}
            query={query}
            inSearch={inSearch}
            setModalResponse={setModalResponse}
            setFormResponse={setFormResponse}
            getCurrentResponses={getCurrentResponses}
            loadingHistoryLine={loadingHistoryLine}
            forMe
          />
        )}
        {!loading && concluidos && currentTab === 1 && !modalResponse && (
          <Templates
            templates={filteredConcluidos || concluidos}
            conteudos={null}
            query={query}
            inSearch={inSearch}
            setModalResponse={setModalResponse}
            setFormResponse={setFormResponse}
            getCurrentResponses={getCurrentResponses}
            loadingHistoryLine={loadingHistoryLine}
            forMe
          />
        )}
        {loading && <Loading />}
      </S.Body>

      {modalResponse && formResponse && (
        <ModalResponse
          open={modalResponse}
          form={formResponse}
          backPath="/formularios/para-mim"
          onClose={() => {
            refetch();
            setModalResponse(false);
          }}
        />
      )}
      {openExcelModal && (
        <ExcelModal
          onClick={handleRequestExcel}
          open={openExcelModal}
          handleClose={() => setOpenExcelModal(false)}
          title="Selecionar os campos de Excel"
          titleIcon={file}
          subtitle="Selecione abaixo:"
          setData={setExcelFields}
          data={excelFields}
          loading={loadingExcel}
        />
      )}

      {formHist && modalView && (
        <ModalSign
          open={modalView}
          onClose={() => {
            setModalView(false);
            setFormHist(false);
            setCurrentHist(null);
          }}
          form={formHist}
          histId={currentHist}
          refetch={getCurrentResponses}
          loading={loadingLine}
          isHistory
          backPath="/formularios/para-mim"
          histories={
            (!currentTab ? templates : concluidos).find(
              item => item.id == formHist?.data?.form?.id,
            )?.historico
          }
        />
      )}
    </S.Container>
  );
};

export default FormulariosParaMim;
