export function changeTaskStatus({
  savedWeek,
  savedComponent,
  savedMonth,
  lastView,
}) {
  return {
    type: '@myTasks/CHANGE_CURRENT_DAY',
    payload: { savedWeek, savedComponent, savedMonth, lastView },
  };
}

export function changeTaskComponent({ savedComponent }) {
  return {
    type: '@myTasks/CHANGE_COMPONENT',
    payload: { savedComponent },
  };
}

export function changeCurrentWeek({ currentWeek }) {
  return {
    type: '@myTasks/CHANGE_CURRENT_WEEK',
    payload: { currentWeek },
  };
}
