import styled from 'styled-components';

export const Container = styled.div`
  border-left: 1px solid #95aac9;
  font: normal normal medium 16px/16px Texta;
  padding-left: 0.5rem;
  max-width: 20rem;
  color: #4b5166;
`;

export const Title = styled.div`
  font-weight: 900;
`;

export const Text = styled.div`
  color: #4b5166;
`;
