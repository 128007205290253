// O estado inicial é um objeto vazio. As keys são alocadas a medida
// que for usando as tabelas. São elas:
// avaliacoes, avaliacoes-descarga, desvios, desvios-descarga...
// ... TODAS as chamas de components/Table

// !!! Não precisa definir um outro para cesso provider, pois o store é resetado

import * as types from './types';

const INITIAL_STATE = {};

export default function tables(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.SET_PARAMS: {
      const { tableName, data } = action.payload;
      const newData = { ...state[tableName], ...data };
      const newState = { ...state, [tableName]: newData };
      return newState;
    }
    case types.RESET_PARAMS: {
      const { tableName } = action.payload;
      const newData = { ...state[tableName], ...INITIAL_STATE[tableName] };
      const newState = { ...state, [tableName]: newData };
      return newState;
    }
    default:
      return state;
  }
}
