import produce from 'immer';
import * as types from './types';

const INITIAL_STATE = {
  filter: {},
  groupBy: 'QUANTIDADE',
};

export default function filterTelemetriaAreaRisco(
  state = INITIAL_STATE,
  action,
) {
  return produce(state, draft => {
    switch (action.type) {
      case types.SET_FILTER: {
        return { ...state, filter: { ...action.payload } };
      }
      case types.SET_GROUP_BY: {
        return { ...state, groupBy: action.payload };
      }
      case types.RESET_FILTER: {
        return INITIAL_STATE;
      }
      default:
    }
  });
}
