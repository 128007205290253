import api from 'services/api';

export const getGuideCheck = async () => {
  const res = await api.get('/first-steps-status');
  return res.data;
};

export const getGuide = async () => {
  const res = await api.get('/first-steps-data');
  return res.data;
};
