import React, { useState, useEffect, useRef } from 'react';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { SettingsOutlined } from '@mui/icons-material';
import { useTheme } from 'styled-components';
import { useNavigate } from 'react-router';
import { trackEvent } from 'utils/mixpanel';
import { useQuery } from 'react-query';
import { usePlans } from 'hooks/usePlans';

// Components
import Card from 'components/Cards/Indicador';
import Loading from 'components/Loading';
import GhostButton from 'components/Buttons/Ghost';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import { DefaultTable } from 'components/_Table/templates/default';
import { columns, _cards } from './constants';

import * as S from './styled';

// service
import * as api from './services';

const AutomacaoCapacitcao = ({ pageDefault }) => {
  const theme = useTheme();
  const initial = useRef(true);
  const { isProvider, hasTorre, hasTorrePlus } = usePlans();
  const [query, setQuery] = useState({});
  const [resetTable, setResetTable] = useState(false);
  const [data, setData] = useState([]);
  const [cards, setCards] = useState(_cards);
  const [loading, setLoading] = useState(true);
  const [statusCard, setStatusCard] = useState(null);

  useState(false);
  const [loadingLines, setLoadingLines] = useState([]);

  // Plano safe para disponibilizar aba de disponibilidade
  const { currentPlan } = useSelector(state => {
    return state.pageMap;
  });

  const filter = useSelector(state =>
    isProvider ? state.filterProvider : state.filter,
  );
  const filterCapacitacao = useSelector(
    state => state.filterCapacitacaoAutomacao,
  );

  // Nível e filial do usuário
  const user = useSelector(state => {
    return state.auth?.user?.user;
  });

  const navigate = useNavigate();

  useQuery(
    ['content', query, statusCard],
    () => api.getContent({ ...query, status: statusCard }),
    {
      refetchOnWindowFocus: false,
      onSuccess: data => {
        setData(data);
        resetTable && setResetTable(false);
        setLoading(false);
      },
    },
  );

  useQuery(['cards', query], () => api.getCards(query), {
    refetchOnWindowFocus: false,
    onSuccess: data => {
      setCards(prevState => {
        return prevState.map(card => {
          return {
            ...card,
            value: data.find(item => item.type === card.type)?.value,
          };
        });
      });
    },
  });

  useEffect(() => {
    if (initial.current) {
      initial.current = false;
      return;
    }
    setResetTable(true);
  }, [filter]);

  const handleClickCards = name => {
    trackEvent(user, 'Automação capacitação ordenação cards');
    setStatusCard(name);
  };

  const renderTop = () => {
    return (
      <>
        <S.TitleWrapper>
          <h1>Conteúdos enviados de forma automática</h1>

          <div>
            <GhostButton
              startIcon={<SettingsOutlined />}
              size="medium"
              onClick={() => navigate('/automacao/configuracoes')}
            >
              CONFIGURAÇÕES
            </GhostButton>
          </div>
        </S.TitleWrapper>
      </>
    );
  };

  const renderCards = () => {
    return (
      <Grid container spacing={2} marginBottom="25px">
        {cards?.map(card => {
          return (
            <Grid item key={card.name} xs={12} md={4} xl={4}>
              <Card
                value={card.value}
                icon={card.icon}
                text={card.text}
                handleClick={() => handleClickCards(card.type)}
                selected={card.selected}
              />
            </Grid>
          );
        })}
      </Grid>
    );
  };

  // ---------------------  RENDER: FROTA ---------------------
  const formatColumns = columns => {
    columns =
      !hasTorre && !hasTorrePlus
        ? columns.filter(item => item.id !== 'empresa_nome')
        : columns;
    return columns.map(item => {
      if (item.id === 'acoes') {
        return {
          header: 'AÇÕES',
          type: 'string',
          align: 'center',
          children: (_, item) => {
            return (
              <div
                onClick={() =>
                  navigate(`/automacao/capacitacao/detalhes/${item.id}`)
                }
              >
                <ExitToAppIcon sx={{ color: '#95AAC9' }} />
              </div>
            );
          },
        };
      }

      if (item.id === 'conteudo_nome') {
        return {
          header: 'CONTEÚDO',
          sort: true,
          type: 'string',
          children: (_, item) => (
            <div
              style={{
                color: '#BDCC30',
                textDecoration: 'underline',
                fontSize: 11,
                userSelect: 'none',
              }}
              onClick={() =>
                navigate(`/automacao/capacitacao/detalhes/${item.id}`)
              }
            >
              {item?.conteudo_nome.length > 50
                ? `${item?.conteudo_nome.substring(0, 50)}...`
                : item?.conteudo_nome}
            </div>
          ),
        };
      }

      return item;
    });
  };

  const renderTable = () => {
    return (
      <DefaultTable
        data={data || []}
        columns={formatColumns(columns)}
        searchKeys={['Buscar conteúdo']}
        setQuery={q => {
          setQuery({
            ...filter,
            client: filter.client ? filter.client.split(',') : '',
            filial: filter.filial ? filter.filial.split(',') : '',
            empresas: filter.empresas ? filter.empresas.split(',') : '',
            desvio: filterCapacitacao.desvio
              ? filterCapacitacao.desvio.split(',')
              : '',
            ...q,
          });
        }}
        local={false}
        loading={loading}
        reset={resetTable}
        placeholder="Buscar conteudo"
        sortBy={{ id: 'motorista_nome', order: 'ASC' }}
        loadingSelection={loadingLines}
        searchEvent={search => trackEvent(user, 'Busca conteúdo', null, search)}
      />
    );
  };

  // Componentes da página
  const renderMain = () => (
    <>
      <S.Main>
        {renderTop()}
        {renderCards()}
        {renderTable()}
      </S.Main>
    </>
  );

  return loading ? <Loading /> : renderMain();
};

export default AutomacaoCapacitcao;
