import {
  getResponsaveisVagas,
  getCidade,
  getStatusVagas,
} from 'constants/_SERVICES/user';

// Pages
import Vagas from 'pages/Contratacao';
import TemplateConfigVagas from 'pages/Contratacao/Detalhe_Criacao';

export default {
  '/contratacao/vagas': {
    date: true,
    pageFilterName: 'filterVagas',
    defaults: [],
    filters: [
      {
        name: 'responsavel',
        placeholder: 'Filtrar por Responsáveis',
        mode: 'multiple',
        data: async () => getResponsaveisVagas(),
      },
      {
        name: 'status',
        placeholder: 'Filtrar por Status',
        mode: 'single',
        data: async () => getStatusVagas(),
      },
      {
        name: 'cidade',
        placeholder: 'Filtrar por Cidade',
        mode: 'single',
        data: async () => getCidade(),
      },
    ],
    page: <Vagas />,
  },

  '/contratacao/vagas/criar': {
    back: -1,
    subTitle: ' Vagas',
    title: 'Criar Vaga',
    page: <TemplateConfigVagas />,
  },
  '/contratacao/vagas/editar/:id': {
    back: -1,
    subTitle: ' Vagas',
    title: 'Editar Vaga',
    id: true,
    page: <TemplateConfigVagas />,
  },
};
