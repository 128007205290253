import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  flex-direction: column;
  .space {
    margin-right: 10px;
  }
  .container-custom-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;
    padding: 10px;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px;
    width: 100%;
  }
`;

export const ContentCards = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  .container-custom-card {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 10px;
    border-radius: 4px;
    margin-top: 24px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px;
    min-width: 20%;
  }
  .text_custom_card {
    font-size: 14px;
    justify-content: center;
    font-style: normal;
    text-align: left;
  }
`;

export const ContentGraphMiddle = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 5px;
  margin-top: 15px;
  .container-graph {
    width: 70%;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px;
    border-radius: 4px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .container-radar-graph {
    width: 29%;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px;
    border-radius: 4px;
    padding-left: 20px;
    padding-right: 20px;
    margin-right: 20px;
  }
`;
export const ContentGraphBottom = styled.div`
  display: flex;
  flex-direction: ${p => (p.print ? 'column' : 'row')};
  width: 100%;
  .container-graph {
    width: ${p => (p.print ? '100%' : '70%')};
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px;
    border-radius: 4px;
  }

  .card1 {
    width: 60%;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px;
    border-radius: 4px;
    padding: 15px;
    margin-left: 20px;
  }
  .card2 {
    display: flex;
    flex-direction: column;
    padding: 15px;
    text-align: center;
    justify-content: space-between;
    width: 40%;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px;
    border-radius: 4px;
    margin-left: 25px;
    .top {
      font-size: 16px;
      font-style: normal;
      font-weight: medium;
      letter-spacing: 0px;
      color: #4b5166;
    }
    .middle-total {
      text-align: center;
      font-size: 64px;
      font-weight: 900;
      font-style: normal;
      color: #4b5166;
    }
    .middle-sub {
      text-align: center;
      font-size: 16px;
      font-style: normal;
      color: #95aac9;
    }
    .bottom {
      font-size: 16px;
      font-weight: 900;
      font-style: normal;
      letter-spacing: 0px;
      color: #f64e60;
    }
  }
  .title {
    font-size: 24px;
    font-weight: 900;
    letter-spacing: 0px;
    color: #4b5166;
    margin-top: 20px;
  }
  .sub-title {
    text-align: left;
    font-size: 20px;
    font-weight: medium;
    letter-spacing: 0px;
    color: #4b5166;
  }
  .sub-title > span {
  }
  .sub-container {
    height: 106px;
    border-left: 3px solid #95aac94d;
    border-radius: 5px;
    padding: 10px;
    margin-top: 15px;
    .text1 {
      text-align: left;
      font-size: 16px;
      font-weight: medium;
      letter-spacing: 0px;
      color: #4b5166;
    }
    .text2 {
      text-align: left;
      font-size: 20px;
      font-weight: 900;
      letter-spacing: 0px;
      color: #4b5166;
    }
  }
`;
