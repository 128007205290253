import React, { useEffect, useState } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  ReferenceLine,
  Tooltip,
} from 'recharts';
import { startOfMonth, subMonths } from 'date-fns';

// components custom
import { PeriodPicker } from 'pages/Estatisticas/Components/PeriodPicker';

// styles
import * as S from './styled';
import { ptMonths } from 'utils/dates';

const CustomTick = props => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="middle"
        fill="#95AAC9"
        transform="rotate(0)"
      >
        <tspan x={0} dy="20">
          {payload.value}
        </tspan>
      </text>
    </g>
  );
};

export const GraficoDesviosTratadoNoprazo = ({
  data = [],
  handleDate,
  metaValue = 10,
  title = '',
  filter = null,
}) => {
  const [finalDate, setFinalDate] = useState(new Date());
  const [initialDate, setIntitalDate] = useState(
    startOfMonth(subMonths(new Date(), 11)),
  );

  // Converte mes e ano. Ex: 05/2023 >> maio-2023
  const dataRender = data?.slice(-12).map(item => {
    const mesIndex = item.mes - 1;
    return {
      ano: `${ptMonths[mesIndex]?.substring(0, 3) ?? item.mes}-${item.ano}`,
      x: Number(item.x),
      meta: item.meta,
    };
  });

  const maxValue = dataRender?.reduce(
    (max, item) => (item.x > max ? item.x : max),
    dataRender[0]?.x || 0,
  );

  const minValue = dataRender?.reduce(
    (min, item) => (item.x < min ? item.x : min),
    dataRender[0]?.x || 0,
  );

  return (
    <S.Container>
      <div className="container-filters">
        <div className="title">{title}</div>
        <div className="flex-gap">
          <PeriodPicker
            finalDate={finalDate}
            initialDate={initialDate}
            period="lastYear"
            periodAdd="month"
            onChange={date => {
              setFinalDate(date.finalDate);
              setIntitalDate(date.initialDate);
              handleDate(date);
            }}
          />
          {filter}
        </div>
      </div>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          height={300}
          data={dataRender}
          margin={{ top: 5, right: 50, left: 0, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="ano" height={70} tick={<CustomTick />} interval={0} />
          <YAxis domain={[minValue, maxValue]} />

          <Line
            type="linear"
            dataKey="x"
            stroke="#0C12F2"
            dot={props => {
              const { index, payload } = props;
              const isLastDot = index === data.length - 1;
              return (
                <circle
                  cx={props.cx}
                  cy={props.cy}
                  r={5}
                  fill={isLastDot ? 'none' : '#0C12F2'} // Se for o último ponto, não preenche
                  stroke={isLastDot ? '#0C12F2' : 'none'} // Se for o último ponto, define a cor da borda
                  strokeWidth={isLastDot ? 2 : 0} // Se for o último ponto, define a largura da borda
                />
              );
            }}
          />
          <Tooltip formatter={(value, name, props) => [value, `Tratativas:`]} />
          <ReferenceLine
            y={metaValue}
            label={{
              value: `Meta ${metaValue}`,
              position: 'insideBottomRight',
              fill: 'red',
              style: {
                fontSize: 12,
                fontWeight: 'bold',
              },
            }}
            stroke="red"
            strokeDasharray="3 3"
          />
        </LineChart>
      </ResponsiveContainer>
    </S.Container>
  );
};
