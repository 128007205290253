import React from 'react';
import * as S from './styled';

export const Table = ({ data = [], columns = [] }) => {
  return (
    <S.Table>
      <S.THead>
        <S.Tr>
          {columns.map(col => (
            <S.Th align={col.align} width={col.width} key={col.id}>
              {col.header}
            </S.Th>
          ))}
        </S.Tr>
      </S.THead>
      <S.TBody>
        {data.length ? (
          data.map((item, idx) => (
            <S.Tr key={idx}>
              {columns.map(col => (
                <S.Td align={col.align} key={col.id}>
                  {col.children instanceof Function
                    ? col.children(item[col.id], item)
                    : col.value instanceof Function
                    ? col.value(item[col.id], item)
                    : item[col.id] ?? '-'}
                </S.Td>
              ))}
            </S.Tr>
          ))
        ) : (
          <S.Tr>
            {columns.map(col => (
              <S.Td align={col.align} key={col.id}>
                -
              </S.Td>
            ))}
          </S.Tr>
        )}
      </S.TBody>
    </S.Table>
  );
};
