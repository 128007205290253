import api from '../../services/api';

export const requestNotifications = async () => {
  const res = await api.get(`/notificacoes`);
  return res;
};

export const requestAlertas = async () => {
  const { data } = await api.get(`/alertas`);
  return data?.data ?? [];
};
