import React, { useCallback } from 'react';
import { useTheme } from 'styled-components';
import { Modal, IconButton, Icon } from '@mui/material';
import PaletteOutlinedIcon from '@mui/icons-material/PaletteOutlined';
import DefaultButtonPopover from 'components/Buttons/DefaultPopover';
import GhostButton from 'components/Buttons/Ghost';
import { ReactComponent as CheckIcon } from 'images/icons/inputs/check.svg';
import { useQuery } from 'react-query';
import * as themeServices from 'styles/theme/services';

import DropZone from '../../../../Capacitacao/DropZone';
import * as S from './styled';

const ID_TIPO_PRIMARIA = 1;
const ID_TIPO_SECUNDARIA = 2;

const SettingsModal = ({
  distribuidoraId,
  open,
  logoURL,
  handleClose,
  whitelabelConfig,
  setWhitelabelConfig,
  handlePreview,
  handleSaveLogo,
}) => {
  const theme = useTheme();
  const { data: themes = [] } = useQuery(
    ['whitelabelThemes'],
    () => themeServices.requestWhitelabelOptions(),
    {
      refetchOnWindowFocus: false,
    },
  );

  const handleChange = useCallback((name, value) => {
    setWhitelabelConfig(state => ({ ...state, [name]: value }));
  }, []);

  let primaryTheme = whitelabelConfig?.id_da_whitelabel_primaria ?? null;
  let secondaryTheme = whitelabelConfig?.id_da_whitelabel_secundaria ?? null;

  const body = (
    <S.Paper>
      <S.Header>
        <S.Title>
          <PaletteOutlinedIcon
            sx={{
              color: theme.palette.brand.secondary.natural,
              marginRight: '10px',
            }}
            fontSize="medium"
          />
          <h1>Personalizar</h1>
        </S.Title>

        <IconButton size="small" onClick={() => handleClose(false)}>
          <Icon sx={{ color: theme.palette.words.subtitle.natural }}>
            close
          </Icon>
        </IconButton>
      </S.Header>

      <S.Main>
        <p>Adicionar Logotipo</p>
        <S.DropContainer>
          <DropZone
            width="200px"
            height="200px"
            fileUrl={logoURL}
            setFileUrl={file => handleSaveLogo(file)}
            image_ref={`/whitelabels/logos/${distribuidoraId}/`}
            deleteIcon
          />
        </S.DropContainer>
        <br />
        <h2>Cores do sistema</h2>
        <p>Selecione a cor principal</p>
        <S.ThemeSelector>
          {themes
            .filter(_theme => _theme.tipo == ID_TIPO_PRIMARIA)
            .map(_theme => (
              <S.ThemeOption
                key={_theme.cor}
                color={_theme.cor}
                onClick={() =>
                  handleChange('id_da_whitelabel_primaria', _theme.id)
                }
              >
                {primaryTheme === _theme.id && <CheckIcon />}
              </S.ThemeOption>
            ))}
        </S.ThemeSelector>
        <p>Selecione a cor dos botões</p>
        <S.ThemeSelector>
          {themes
            .filter(_theme => _theme.tipo == ID_TIPO_SECUNDARIA)
            .map(_theme => (
              <S.ThemeOption
                key={_theme.cor}
                color={_theme.cor}
                onClick={() =>
                  handleChange('id_da_whitelabel_secundaria', _theme.id)
                }
              >
                {secondaryTheme === _theme.id && <CheckIcon />}
              </S.ThemeOption>
            ))}
        </S.ThemeSelector>
      </S.Main>
      <S.Footer>
        <GhostButton size="medium" onClick={() => handleClose(false)}>
          Cancelar
        </GhostButton>

        <DefaultButtonPopover
          size="medium"
          sx={{ marginLeft: '10px' }}
          onClick={() => {
            handlePreview();
            handleClose();
          }}
        >
          Pré-visualizar
        </DefaultButtonPopover>
      </S.Footer>
    </S.Paper>
  );

  return (
    <div>
      <Modal open={open} onClose={() => handleClose(false)}>
        {body}
      </Modal>
    </div>
  );
};

export default SettingsModal;
