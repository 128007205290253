import { InfoOutlined } from '@mui/icons-material';
import { TooltipCustom } from 'components/Tooltip';
import * as S from './styled';

const infoMessage =
  'Gerencie seus motoristas. Você pode editar, ativar e inativar motoristas.';

const cardsMotoristas = [
  {
    name: 'motorista_ativos',
    value: 0,
    text: 'Num de ativos / Faixa contratada',
    icon: 'lupa_verde.svg',
    selected: false,
  },
  {
    name: 'desvios',
    value: 0,
    text: 'Motoristas sem desvios registrados',
    icon: 'lupa_verde.svg',
    selected: false,
  },
];

const columnsMotoristas = [
  {
    header: 'Nome',
    id: 'nome',
    type: 'string',
    sort: true,
    picture: 'foto',
    width: 350,
  },
  {
    header: 'Função',
    id: 'funcao',
    type: 'string',
    sort: true,
  },
  {
    header: 'Cliente',
    id: 'distribuidora.nome',
    sort: true,
    type: 'string',
  },
  {
    header: 'Status',
    id: 'status',
    sort: true,
    type: 'string',
    align: 'center',
    width: 250,
    conditional: [
      {
        condition: value => value === 'ATIVO',
        style: theme => ({
          color: theme.palette.semantics.feedback.success.natural,
          backgroundColor: theme.palette.semantics.feedback.success.light,
          textAlign: 'center',
        }),
      },
      {
        condition: value => value === 'INATIVO',
        style: theme => ({
          color: theme.palette.semantics.feedback.attention.natural,
          backgroundColor: theme.palette.semantics.feedback.attention.light,
          textAlign: 'center',
        }),
      },
    ],
    attention: (_, item) => {
      if (!item.assinatura_adesao)
        return {
          text: 'Termo de confidencialidade pendente.',
        };
    },
  },
];
const columnsMotoristasTorre = [
  {
    header: 'Nome',
    id: 'nome',
    type: 'string',
    sort: true,
    picture: 'foto',
    width: 350,
  },
  {
    header: 'Função',
    id: 'funcao',
    type: 'string',
    sort: true,
  },
  {
    header: 'Empresa',
    id: 'empresa.nome',
    sort: true,
    type: 'string',
  },
  {
    header: 'Filial',
    id: 'filial.nome',
    sort: true,
    type: 'string',
  },
  {
    header: 'Status',
    id: 'status',
    sort: true,
    type: 'string',
    align: 'center',
    width: 250,
    conditional: [
      {
        condition: value => value === 'ATIVO',
        style: theme => ({
          color: theme.palette.semantics.feedback.success.natural,
          backgroundColor: theme.palette.semantics.feedback.success.light,
          textAlign: 'center',
        }),
      },
      {
        condition: value => value === 'INATIVO',
        style: theme => ({
          color: theme.palette.semantics.feedback.attention.natural,
          backgroundColor: theme.palette.semantics.feedback.attention.light,
          textAlign: 'center',
        }),
      },
    ],
    attention: (_, item) => {
      if (!item.assinatura_adesao)
        return {
          text: 'Termo de confidencialidade pendente.',
        };
    },
  },
];

const columnsCreateMany = [
  {
    Header: 'Matricula',
    id: 'matricula',
    accessor: d => (d.matricula ? String(d.matricula) : '-'),
    sortType: 'basic',
    show: true,
    Cell: ({ row }) => (
      <h3 style={{ color: '#2E3240', fontWeight: '600' }}>
        {row.original.matricula}
      </h3>
    ),
  },
  {
    Header: 'Nome',
    id: 'nome',
    accessor: d => (d.nome ? String(d.nome) : '-'),
    sortType: 'basic',
    show: true,
    Cell: ({ row }) => <h3>{row.original.nome}</h3>,
  },
  {
    Header: 'CPF',
    id: 'cpf',
    accessor: d => (d.cpf ? String(d.cpf) : '-'),
    sortType: 'basic',
    Cell: ({ row }) => <h3>{row.original.cpf}</h3>,
  },
  {
    Header: 'Celular',
    id: 'celular',
    accessor: d => (d.celular ? String(d.celular) : '-'),
    sortType: 'basic',
    Cell: ({ row }) => <h3>{row.original.celular}</h3>,
  },
  {
    Header: 'Função',
    id: 'funcao',
    accessor: d => (d.funcao ? String(d.funcao) : '-'),
    sortType: 'basic',
    show: true,
    Cell: ({ row }) => <h3>{row.original.funcao}</h3>,
  },
  {
    Header: 'Gênero',
    id: 'genero',
    accessor: d => (d.genero ? String(d.genero) : '-'),
    sortType: 'basic',
    show: true,
    Cell: ({ row }) => <h3>{row.original.genero}</h3>,
  },
  {
    Header: 'Data de Nascimento',
    id: 'data_de_nascimento',
    accessor: d => (d.date_de_nascimento ? String(d.date_de_nascimento) : '-'),
    sortType: 'basic',
    show: true,
    Cell: ({ row }) => <h3>{row.original.date_de_nascimento}</h3>,
  },
  {
    Header: 'Data de Admissão',
    id: 'data_de_admissao',
    accessor: d => (d.date_de_admissao ? String(d.date_de_admissao) : '-'),
    sortType: 'basic',
    show: true,
    Cell: ({ row }) => <h3>{row.original.date_de_admissao}</h3>,
  },
  {
    Header: 'Empresa Terceira',
    id: 'empresa_terceira',
    accessor: d => (d.empresa_terceira ? String(d.empresa_terceira) : '-'),
    sortType: 'basic',
    show: true,
    Cell: ({ row }) => <h3>{row.original.empresa_terceira}</h3>,
  },
  {
    Header: 'Filial',
    id: 'filial',
    accessor: d => (d.filial ? String(d.filial) : '-'),
    sortType: 'basic',
    show: true,
    Cell: ({ row }) => <h3>{row.original.filial}</h3>,
  },
  {
    Header: 'CNH',
    id: 'cnh',
    accessor: d => (d.cnh ? String(d.cnh) : '-'),
    sortType: 'basic',
    show: true,
    Cell: ({ row }) => <h3>{row.original.cnh}</h3>,
  },
  {
    Header: 'Primeira CNH',
    id: 'primeira_cnh',
    accessor: d => (d.primeiraa_cnh ? String(d.primeiraa_cnh) : '-'),
    sortType: 'basic',
    show: true,
    Cell: ({ row }) => <h3>{row.original.primeiraa_cnh}</h3>,
  },
  {
    Header: 'Validade CNH',
    id: 'validade_cnh',
    accessor: d => (d.validadee_cnh ? String(d.validadee_cnh) : '-'),
    sortType: 'basic',
    show: true,
    Cell: ({ row }) => <h3>{row.original.validadee_cnh}</h3>,
  },
  {
    Header: 'Estado Civíl',
    id: 'estado_civil',
    accessor: d => (d.estado_civil ? String(d.estado_civil) : '-'),
    sortType: 'basic',
    show: true,
    Cell: ({ row }) => <h3>{row.original.estado_civil}</h3>,
  },
  {
    Header: 'Fumante',
    id: 'fuma',
    accessor: d => (d.fuma ? String(d.fuma) : '-'),
    sortType: 'basic',
    show: true,
    Cell: ({ row }) => <h3>{row.original.fuma}</h3>,
  },
];
const fields = [
  {
    label: 'ID',
    value: 'id',
    selected: true,
    default: true,
  },
  {
    label: 'Matrícula',
    value: 'matricula',
    selected: false,
    default: false,
  },
  {
    label: 'Nome',
    value: 'nome',
    selected: true,
    default: true,
  },
  {
    label: 'CPF',
    value: 'cpf',
    selected: true,
    default: true,
  },
  {
    label: 'Status',
    value: 'status',
    selected: true,
    default: true,
  },
  {
    label: 'Função',
    value: 'funcao',
    selected: false,
    default: false,
  },
  {
    label: 'Gênero',
    value: 'genero',
    selected: false,
    default: false,
  },
  {
    label: 'Data de nascimento',
    value: 'data_de_nascimento',
    selected: false,
    default: false,
  },
  {
    label: 'Data de admissão',
    value: 'data_de_admissao',
    selected: false,
    default: false,
  },
  {
    label: 'Filial',
    value: 'filial.nome',
    selected: false,
    default: false,
  },
  {
    label: 'Cliente',
    value: 'distribuidora.nome',
    selected: false,
    default: false,
  },
  {
    label: 'Empresa terceira',
    value: 'empresa_terceira',
    selected: false,
    default: false,
  },
  {
    label: 'CNH',
    value: 'cnh',
    selected: false,
    default: false,
  },
  {
    label: 'Primeira CNH',
    value: 'primeira_cnh',
    selected: false,
    default: false,
  },
  {
    label: 'Validade CNH',
    value: 'validade_cnh',
    selected: false,
    default: false,
  },
  {
    label: 'Estado civil',
    value: 'estado_civil',
    selected: false,
    default: false,
  },
  {
    label: 'Fumante',
    value: 'fumante',
    selected: false,
    default: false,
  },
  {
    label: 'Celular',
    value: 'celular',
    selected: false,
    default: false,
  },
  {
    label: 'Desativado em',
    value: 'disabled_at',
    selected: false,
    default: false,
  },
  {
    label: 'Agregado',
    value: 'agregado',
    selected: true,
    default: true,
  },
  {
    label: 'Assinatura adesão',
    value: 'assinatura_adesao',
    selected: false,
    default: false,
  },
  {
    label: 'Cadastrado em',
    value: 'createdAt',
    selected: false,
    default: false,
  },
];

const resetExcelFields = fields => {
  return fields.map(i => {
    if (!i.default) i.selected = false;
    return i;
  });
};

export {
  infoMessage,
  cardsMotoristas,
  columnsMotoristas,
  fields,
  resetExcelFields,
  columnsCreateMany,
  columnsMotoristasTorre,
};
