import produce from 'immer';
import * as types from './types';

const INITIAL_STATE = {
  status: '',
  turma: '',
  // isEditable: [],
  // reportsFilters: [],
};

export default function filterConteudo(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case types.SET_FILTER_CONTEUDO: {
        draft.status = action.payload.query.status;
        draft.turma = action.payload.query.turma;
        break;
      }
      // case types.REPORTS_FILTERS: {
      //   draft.reportsFilters = action.payload.reportsFilters;
      //   break;
      // }
    }
  });
}
