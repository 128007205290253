import api from '../../../../services/api';

export const requestEmpresasGeral = async query => {
  const res = await api.get(`/provider/empresas/estatisticas/geral`, {
    params: query,
  });
  return res;
};

export const requestEmpresasPerfil = async (id, query) => {
  const res = await api.get(`/provider/empresas/estatisticas/perfil/${id}`, {
    params: query,
  });
  return res;
};

export const requestEmpresasInfractions = async (id, query) => {
  const res = await api.get(
    `/provider/empresas/estatisticas/perfil/desvios/${id}`,
    {
      params: query,
    },
  );
  return res;
};
