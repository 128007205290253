import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import { filter } from 'lodash';

const tabs_empresa = [
  {
    label: 'CRITICIDADE',
    value: 'CRITICIDADE',
  },
  {
    label: 'DESVIOS',
    value: 'DESVIOS',
  },
  {
    label: 'EMPRESAS',
    value: 'EMPRESAS',
  },
];

const tabs_client = [
  {
    label: 'CRITICIDADE',
    value: 'CRITICIDADE',
  },
  {
    label: 'DESVIOS',
    value: 'DESVIOS',
  },
  {
    label: 'CLIENTES',
    value: 'CLIENTES',
  },
  {
    label: 'FILIAIS',
    value: 'FILIAIS',
  },
];

const legends_empresa = [
  {
    text: 'Desvios finalizados',
    color: '#4B5166',
  },
  {
    text: 'Movidos para pendentes',
    color: '#BDCC30',
  },
  {
    text: 'Solicitações de exclusão',
    color: '#0C12F2',
  },
  {
    text: 'Movidos para pendentes após vencimento',
    color: '#F64E60',
  },
  {
    text: 'Excluídos',
    color: '#FFA801',
  },
];

const legends_client = [
  {
    text: 'Desvios finalizados',
    color: '#4B5166',
  },
  {
    text: 'Desvios abertos',
    color: '#FFA801',
  },
  {
    text: 'Movidos para pendentes',
    color: '#BDCC30',
  },
  {
    text: 'Movidos para pendentes após vencimento',
    color: '#F64E60',
  },
];

const StyledBadge = styled(Badge)(
  ({ theme, badgeColor, badgeBackgroundColor }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: badgeBackgroundColor,
      color: badgeColor,
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: 'ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }),
);

const formatDataGraphPie = (data, condition) => {
  const soma = data.reduce(
    (acc, obj) => {
      acc.status_aberto += parseInt(obj.status_aberto || 0);
      acc.status_finalizado += parseInt(obj.status_finalizado || 0);
      acc.status_pendente += parseInt(obj.status_pendente || 0);
      acc.status_pendente_pos += parseInt(obj.status_pendente_pos || 0);
      acc.excluidos += parseInt(obj.excluidos || 0);
      acc.sol_exclusao += parseInt(obj.sol_exclusao || 0);
      return acc;
    },
    {
      status_aberto: 0,
      status_finalizado: 0,
      status_pendente: 0,
      status_pendente_pos: 0,
      excluidos: 0,
      sol_exclusao: 0,
    },
  );

  const dataFormated = [
    {
      id: 'status_finalizado',
      name: 'Desvios finalizados',
      value: soma.status_finalizado,
      color: '#4B5166',
    },
    {
      id: 'status_aberto',
      name: 'Desvios abertos',
      value: soma.status_aberto,
      color: '#FFA801',
    },
    {
      id: 'status_pendente',
      name: 'Movidos para pendentes',
      value: soma.status_pendente,
      color: '#BDCC30',
    },
    {
      id: 'sol_exclusao',
      name: 'Solicitações de exclusão',
      value: soma.sol_exclusao,
      color: '#0C12F2',
    },
    {
      id: 'status_pendente_pos',
      name: 'Movidos para pendentes após vencimento',
      value: soma.status_pendente_pos,
      color: '#F64E60',
    },
    {
      id: 'excluidos',
      name: 'Excluídos',
      value: soma.excluidos,
      color: '#FFA801',
    },
  ];

  return dataFormated.filter(item => {
    if (condition) {
      return item.id !== 'status_aberto';
    }
    return item.id !== 'sol_exclusao' && item.id !== 'excluidos';
  });
};
const calculateTotalsAndPercentages = data => {
  const processedData = data.map(item => {
    const total =
      parseInt(item?.status_pendente_pos || 0) +
      parseInt(item?.status_pendente || 0) +
      parseInt(item?.status_aberto || 0) +
      parseInt(item?.excluidos || 0) +
      parseInt(item?.sol_exclusao || 0) +
      parseInt(item?.status_finalizado || 0);
    return {
      name: item.name,
      total: parseFloat(item?.average_minutes || 0),
      value: total,
    };
  });

  const grandTotal = processedData.reduce((sum, item) => sum + item.value, 0);

  const finalData = processedData.map(item => ({
    ...item,
    valueAbs: item.value,
    value: ((item.value / grandTotal) * 100).toFixed(2),
  }));

  return finalData;
};

export {
  tabs_empresa,
  tabs_client,
  legends_empresa,
  legends_client,
  StyledBadge,
  formatDataGraphPie,
  calculateTotalsAndPercentages,
};
