import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useParams, useNavigate } from 'react-router-dom';
import { SubdirectoryArrowRight } from '@mui/icons-material';

import Button from 'components/Buttons/Ghost';
import { formatNewHour } from 'utils/dates';

import clockIcon from 'images/icons/ico-dash-clock.svg';
import { useTheme } from 'styled-components';
import { requestLogs } from '../services';
import * as S from './styled';

const LogFrota = ({}) => {
  const theme = useTheme();
  const { id } = useParams();
  const navigate = useNavigate();

  const [logs, setLogs] = useState(null);

  const fetch = async () => {
    const res = await requestLogs(id);
    if (res.data?.data) setLogs(res.data.data);
    else if (res.data?.message) toast.error(res.data.message);
  };

  useEffect(() => {
    fetch();
  }, []);

  const renderStepCard = ({ id, hour, title, description }) => (
    <S.CardContent>
      <h3 style={{ color: theme.palette.brand.primary.light }}>{hour}</h3>

      <img src={clockIcon} alt="clockIcon" />

      <S.TextContent>
        <h3>
          <span style={{ textTransform: 'capitalize', fontWeight: 900 }}>
            {title.split('_')[0]}:
          </span>
        </h3>

        <h3>{description}</h3>
      </S.TextContent>
    </S.CardContent>
  );

  return (
    <S.Wrapper>
      <S.Header>
        <S.Title>
          <h1>Histórico de alterações</h1>
          <h2>{`> Caminhão #${id}`}</h2>
        </S.Title>

        <Button
          startIcon={
            <SubdirectoryArrowRight sx={{ transform: 'rotate(180deg)' }} />
          }
          onClick={() => navigate(`/frota`)}
        >
          Voltar para frota
        </Button>
      </S.Header>

      <S.Card>
        {logs &&
          logs.length > 0 &&
          logs.map(item =>
            renderStepCard({
              hour: formatNewHour(item.data),
              title: item.evento,
              description: item.descricao,
            }),
          )}
      </S.Card>
    </S.Wrapper>
  );
};

export default LogFrota;
