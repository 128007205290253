import { Radio } from '@mui/material';
import * as S from './styled';

export const EvaluationView = ({ item, noBorder }) => {
  return (
    <S.Container noBorder={noBorder}>
      <S.Question>{item?.pergunta}</S.Question>
      <S.ResponsesBox>
        {item?.alternativas?.map((alt, index) => (
          <S.Response key={index}>
            <Radio size="small" disabled />
            <div className="alt">{alt?.alternativa}</div>
          </S.Response>
        ))}
      </S.ResponsesBox>
    </S.Container>
  );
};
