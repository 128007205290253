export const templateFile =
  'https://firebasestorage.googleapis.com/v0/b/onisysweb.appspot.com/o/ModelosUpload%2Fcadastro_de_motoristas_%20provider.xlsx?alt=media&token=285b7c94-751f-4e51-b3a3-06ba87f4cbcb';

export const text = {
  baixarModelo:
    'Baixe em seu computador a planilha modelo para a inserção em massa de motoristas. Fique atento a formatação dos dados e informações obrigatórias!',
  fazerUpload:
    'Selecione a planilha "modelo" preenchida com os dados dos motoristas que serão adicionados. Em seguida clique em "FAZER UPLOAD" e acompanhe o envio dos dados.',
  sendoEnviado:
    'Seu arquivo está sendo enviado! Aguarde até que esteja 100% concluído o processo de envio do arquivo.',
  contemErros: 'Fique atento! Sua planilha contém os seguintes erros:',
  successo:
    'Motoristas carregados com sucesso! Verifique na página de motoristas.',
};
