import React from 'react';
import Tabs from 'components/Tabs';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
} from 'recharts';
import { usePlans } from 'hooks/usePlans';

import * as S from './styled';
import { Skeleton } from '@mui/material';

export const tabs = [
  {
    value: 'criticidade',
    label: 'Criticidade',
  },
  {
    value: 'desvios',
    label: 'Desvios',
  },
  {
    value: 'clientes',
    label: 'Clientes',
    provider: false,
  },
  {
    value: 'filiais',
    label: 'Filiais',
    provider: false,
  },
  {
    value: 'empresas',
    label: 'Empresas',
    provider: true,
  },
];

export const Movimentacoes = ({ data = [], groupBy, setGroupBy, loading }) => {
  const { isProvider } = usePlans();
  return (
    <S.Container>
      <S.Flex className="justify-between mb-05">
        <S.Title>Movimentações no período</S.Title>

        <Tabs
          centered
          value={groupBy}
          items={tabs.filter(
            tab =>
              tab.provider === null ||
              tab.provider === undefined ||
              tab.provider === isProvider,
          )}
          onChange={(_, value) => setGroupBy(value)}
        />
      </S.Flex>
      <ResponsiveContainer width="100%" height={240}>
        {loading ? (
          <Skeleton
            style={{ marginTop: '1rem' }}
            height={240}
            animation="wave"
            variant="rounded"
          />
        ) : (
          <BarChart
            data={data}
            margin={{
              top: 16,
              right: 16,
              left: 16,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="name" // default
              axisLine={false}
            />
            <YAxis
              type="number"
              domain={[0, dataMax => Math.round(dataMax + 10)]}
              tickCount={8}
              allowDecimals
              axisLine={false}
            />
            <Tooltip />

            <Bar
              dataKey="pendentes" // default
              radius={16}
              barSize={16}
              onMouseOver={false}
              fill="#4b5166"
            />
            <Bar
              dataKey="finalizados" // default
              radius={16}
              barSize={16}
              onMouseOver={false}
              fill="#bdcc30"
            />
          </BarChart>
        )}
      </ResponsiveContainer>
      <S.Flex className="justify-end mt-05">
        <S.Legend>
          <div className="point" />
          Movidos para pendentes
        </S.Legend>
        <S.Legend>
          <div className="point primary" />
          Desvios finalizados
        </S.Legend>
      </S.Flex>
    </S.Container>
  );
};
