import styled, { css } from 'styled-components';

export const Container = styled.div`
  .boxLoading {
    position: relative;
    width: 100%;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
  }
`;

export const Paragraph = styled.p`
  ${({ theme, ...props }) => css`
    font-size: ${props.fontsize ? props.fontsize : '16px'};
    color: ${props.color ? props.color : theme.palette.words.text.light};
    font-weight: ${props.fontweight ? props.fontweight : 400};
    min-width: ${props.minwidth};
  `}
`;

export const ColDriver = styled.div`
  display: flex;
  flex-direction: column;
  text-transform: capitalize;
`;
