import styled, { css, keyframes } from 'styled-components';
import { OutlinedInput } from '@mui/material';

const jump = keyframes`
    0% {
        padding-top: 0px;
    }
    50% {
        padding-top: 4px;
    }
    100% {
        padding-top: 0px;
    }
`;

const Container = styled.div`
  position: relative;
  transition: all ease 0.9s;
`;

export const Input = styled(OutlinedInput)`
  ${({ theme }) => css`
    .MuiOutlinedInput-notchedOutline {
      border: 2px solid ${theme.palette.brand.primary.light};
    }
    .MuiOutlinedInput-notchedOutline.error {
      border: 2px solid ${theme.palette.semantics.feedback.attention.natural};
    }
    .MuiInputBase-input.MuiOutlinedInput-input {
      color: ${theme.palette.words.subtitle.natural};
      font-size: 16px;
      font-weight: 400;
      font-family: 'Texta';
      opacity: 1;
      padding: 13.5px 14px;
    }
    .Mui-disabled {
      background-color: ${theme.palette.semantics.feedback.unknown.light},
      border-radius: '5px',
    }
`}
`;

const Label = styled.p`
  ${({ theme }) => theme.palette.words.label.natural};
  font: normal medium 14px/20px Texta;
  margin-bottom: 5px;
`;

const Selecteds = styled.div`
  color: ${({ theme }) => theme.palette.semantics.feedback.unknown.dark};
  font: normal medium 14px/20px Texta;
  margin-bottom: 5px;
  position: absolute;
  margin-top: 15px;
  margin-left: 10px;
  opacity: ${props => props.opacity};
  transition: all ease 0.5s;
`;

const Required = styled.span`
  font-size: 20px;
  font-weight: 500;
  color: red;
`;

const SearchBox = styled.div`
  width: 100%;
  padding: 3px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  position: relative;

  .searchIcon {
    margin-left: -30px;
  }
`;

export const LineBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  position: relative;
`;

const SearchInput = styled.input`
  ${({ theme }) => css`
    width: 80%;
    padding: 5px 8px;
    border: none;
    border: 1px solid ${theme.palette.semantics.feedback.unknown.natural};
    border-radius: 5px;
    font-size: 16px;

    &::placeholder {
      font-size: 16px;
    }

    &:focus {
      border: 1px solid ${theme.palette.brand.secondary.natural};
    }
  `}
`;
const ClearSelection = styled.div`
  ${({ theme }) => css`
    padding: 5px;
    text-align: center;
    color: ${theme.palette.semantics.feedback.unknown.dark};
    font-weight: 600;
    width: 100%;
    background-color: ${theme.palette.brand.secondary.background};
    cursor: pointer;
  `}
`;

export const containerFilters = styled.div`
  text-align: left;
  font-size: 16px;
  font-weight: 900;
  letter-spacing: 0px;
  color: #4b5166;
  padding: 5px;
  margin-bottom: 5px;
  .groups-filter {
    background: #ffffff;
    border: 1px solid #95aac94d;
    border-radius: 4px;
    color: #4b5166;
    font-weight: 500;
    margin: 5px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: left;
  }
  .groups-filter > span {
    font-weight: 900;
  }

  .groups-filter:hover {
    background-color: #4b5166;
    color: #fff;
    cursor: pointer;
    user-select: none;
  }
  .title-group {
    margin-bottom: 10px;
  }
`;

const OptionsArea = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 20px;
`;

const OptionItem = styled.div`
  max-width: 15ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100px;
  .person-type {
    background: ${({ color }) => `${color}20` ?? '#4b516620'};
    border-radius: 12px;
    margin-right: 5px;
    color: ${({ color }) => color ?? '#4b5166'};
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
  }
`;

const LabelSearch = styled.label`
  display: flex;
  justify-content: start;
  align-items: center;

  span {
    margin-left: 15px;
    font-weight: 700;
    color: ${({ theme }) => theme.palette.semantics.feedback.unknown.dark};
  }
`;

const EmptyOptions = styled.span`
  text-align: center;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  color: ${({ theme }) => theme.palette.words.placeholder.light};
`;
const SeeMore = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 20px;
  height: 50px;

  .textSeeMore {
    font-size: 18px;
    font-weight: 600;
    color: #4b5166;
    cursor: pointer;
  }

  .divIcon {
    background-color: red;
    animation: ${jump} 1s ease infinite;
    position: relative;
    display: flex;
    justify-content: center;

    .icon {
      cursor: pointer;
      position: absolute;
    }
  }
`;

export {
  Label,
  Required,
  SearchBox,
  SearchInput,
  LabelSearch,
  OptionsArea,
  OptionItem,
  ClearSelection,
  Selecteds,
  Container,
  EmptyOptions,
  SeeMore,
};
