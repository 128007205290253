import { Divider, Grid } from '@mui/material';
import { useSelector } from 'react-redux';
// Utils
import { formatNewDate, formatNewHour } from 'utils/dates';

import TextInput from 'components/Inputs/TextField';
import * as S from './styled';

export const ExportOcorrenciaPdf = ({
  ocorrencia,
  aluno,
  printRef,
  responsaveis,
}) => {
  const logo = require('images/onisys.png');
  const capa = require(`images/defaultFormulario.jpg`);
  const { user } = useSelector(state => state.auth.user);
  const companyLogo = user?.filial?.empresa?.logo_url;

  const getresponsavel = id => {
    const resp = responsaveis.find(item => item.value === id);
    return resp?.name || '-';
  };
  // -------------------------------------------------Retorno de cada gráfico--------------------------------------------------//
  return (
    <S.ModalMUI open={false} onClose={() => {}} disableEscapeKeyDown>
      <S.Container ref={printRef}>
        <S.Infos>
          <div className="frmatIdent">
            <div className="logoArea">
              <img src={logo} />
            </div>
            <div className="titleForm">Ocorrência PAD - ID {ocorrencia.id}</div>
          </div>
        </S.Infos>
        <S.CardHeader>
          <h1>Informações da Ocorrência</h1>
        </S.CardHeader>
        <S.Header
          bg={
            ocorrencia?.arquivo_evidencia || ocorrencia?.desvio?.img_ocorrencia
          }
        >
          <div className="topInfos">
            <div className="coverArea">
              <div className="background" />
            </div>
            <div className="textsArea">
              <Grid container>
                <Grid item xs={12}>
                  <div className="normalText">Nome:</div>
                  <S.InputToPDF>{aluno?.nome}</S.InputToPDF>
                </Grid>
                <Grid item xs={12}>
                  <div className="normalText">Filial:</div>
                  <S.InputToPDF>{aluno?.filial?.nome}</S.InputToPDF>
                </Grid>
                <Grid item xs={6} style={{ paddingRight: 5 }}>
                  <div className="normalText">Pontuação:</div>
                  <S.InputToPDF>
                    {` ${ocorrencia?.driverStatus?.pontuacao || '-'}`}
                    {'  Pontos'}
                  </S.InputToPDF>
                </Grid>
                <Grid item xs={6}>
                  <div className="normalText">Nivel:</div>
                  <S.InputToPDF>
                    {ocorrencia?.driverStatus?.nivel?.nivel || '-'}
                  </S.InputToPDF>
                </Grid>
                <Grid item xs={12}>
                  <div className="normalText">Ação:</div>
                  <S.InputToPDF>
                    {ocorrencia?.driverStatus?.nivel?.restricoes || '-'}
                  </S.InputToPDF>
                </Grid>
                <Grid item xs={12}>
                  <div className="normalText">Violação:</div>
                  <S.InputToPDF>
                    {ocorrencia?.violacao.descricao || '-'}
                  </S.InputToPDF>
                </Grid>
                <Grid item xs={12}>
                  <div className="normalText">Plano de ação:</div>
                  <S.InputToPDF>
                    {ocorrencia?.violacao?.plano_de_acao || '-'}
                  </S.InputToPDF>
                </Grid>
                <Grid item xs={6} style={{ paddingRight: 5 }}>
                  <div className="normalText">Pontuação:</div>
                  <S.InputToPDF>
                    {ocorrencia?.violacao.pontos || '-'}
                  </S.InputToPDF>
                </Grid>
                <Grid item xs={6}>
                  <div className="normalText">Data Expiração:</div>
                  <S.InputToPDF>
                    {formatNewHour(aluno?.disabled_at) || '-'}
                  </S.InputToPDF>
                </Grid>
                <Grid item xs={6} style={{ paddingRight: 5 }}>
                  <div className="normalText">Data:</div>
                  <S.InputToPDF>
                    {formatNewHour(ocorrencia?.data_ocorrencia) || '-'}
                  </S.InputToPDF>
                </Grid>
                <Grid item xs={6}>
                  <div className="normalText">Data Feedback:</div>
                  <S.InputToPDF>
                    {formatNewHour(ocorrencia?.data_feedback) || '-'}
                  </S.InputToPDF>
                </Grid>
                <Grid item xs={6} style={{ paddingRight: 5 }}>
                  <div className="normalText">Status:</div>
                  <S.InputToPDF>{ocorrencia?.status}</S.InputToPDF>
                </Grid>
                <Grid item xs={6}>
                  <div className="normalText">Responsável:</div>
                  <S.InputToPDF>
                    {ocorrencia?.responsavel?.nome || '-'}
                  </S.InputToPDF>
                </Grid>{' '}
                <Grid item xs={12}>
                  <div className="normalText">Observações:</div>
                  <S.InputToPDF>
                    {ocorrencia?.observacao_responsavel || '-'}
                  </S.InputToPDF>
                </Grid>
                {ocorrencia.data_encerramento && (
                  <Grid item xs={6} padding={1}>
                    <div className="normalText">Finalizada em:</div>
                    <S.InputToPDF>
                      ${formatNewHour(ocorrencia?.data_encerramento)}
                    </S.InputToPDF>
                  </Grid>
                )}
                {ocorrencia.data_exclusao && (
                  <Grid item xs={6} padding={1}>
                    <div className="normalText">Excluída em:</div>
                    <S.InputToPDF>
                      ${formatNewHour(ocorrencia?.data_exclusao)}
                    </S.InputToPDF>
                  </Grid>
                )}
              </Grid>
            </div>
          </div>
        </S.Header>
        <S.CardHeader>
          <h1>Assinaturas</h1>
        </S.CardHeader>
        <S.Header>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <div className="containerSignatures">
                <div className="signaturesUser">
                  {ocorrencia?.responsavel?.nome.toUpperCase()} -
                  {' aprovado digitalmente em: '}
                  {formatNewDate(new Date(ocorrencia?.responsavel?.createdAt))}
                </div>
                <div className="lineApprover" />
                <div className="containerSignaturesTipe">Responsável</div>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="containerSignatures">
                <div className="signaturesUser">
                  {ocorrencia.id_do_responsavel_analise ? (
                    `${getresponsavel(
                      ocorrencia.id_do_responsavel_analise,
                    ).toUpperCase()} aprovado digitalmente em: `
                  ) : (
                    <span>Aguardando Assinatura</span>
                  )}
                  {ocorrencia?.status === 'APROVADA'}
                  {ocorrencia?.status === 'APROVADA' &&
                    formatNewDate(new Date(ocorrencia?.updatedAt))}
                </div>
                <div className="lineApprover" />
                <div className="containerSignaturesTipe">Aprovador</div>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="containerSignatures">
                <div className="signaturesUser">
                  {aluno.nome.toUpperCase()}{' '}
                </div>
                <div className="lineApprover" />
                <div className="containerSignaturesTipe">Motorista</div>
              </div>
            </Grid>
          </Grid>
        </S.Header>
      </S.Container>
    </S.ModalMUI>
  );
};
