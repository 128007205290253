import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  .legend-container {
    width: 100%;
    display: flex;
    gap: 1rem;
  }
`;

export const Legend = styled.div`
  display: flex;
  gap: 0.25rem;
  font-weight: medium;
  font-size: 14px;
  line-height: 12px;
  color: #95aac9;

  .point {
    width: 12px;
    height: 12px;
    background-color: #95aac9;
    border-radius: 100%;
  }
  .primary {
    background-color: #0c12f2;
  }
`;
