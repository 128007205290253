import React from 'react';
import Input from 'components/Inputs/Calendar';
import { Controller } from 'react-hook-form';

const Calendar = ({ control, name, rules, errors, ...props }) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { value, onChange } }) => (
        <Input
          style={{ maxWidth: '100%' }}
          value={value}
          onChange={newDate => onChange(newDate)}
          error={errors?.[name]?.message || null}
          {...props}
        />
      )}
    />
  );
};

export default Calendar;
