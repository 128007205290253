import React, { useEffect, useMemo, useState } from 'react';
import * as S from './styled';
import { startOfMonth, subMonths } from 'date-fns';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import { Divider } from '@mui/material';

// components custom
import Cards from 'pages/Home/FatorRisco/Cards/Card';
import { HistoryPoints } from './HistoryPoints';
import { GraphRDesvio } from './GraphRDesvios';
import GraphRadar from './RadarGraph';

// icons custom
import { ReactComponent as LikeIcon } from 'images/icons/profile_drivers/like.svg';
import { ReactComponent as DSLike } from 'images/icons/profile_drivers/dislike.svg';

// services
import * as API from './services';
import { usePlans } from 'hooks/usePlans';

export const dateMap = {
  '30DIAS': '30 dias',
  '3MESES': '3 meses',
  '6MESES': '6 meses',
  '12MESES': '12 meses',
};

const Variacao = ({ variacao, period, print }) => {
  return (
    <div className="container-custom-card">
      <div style={{ padding: '0 16px' }}>
        {!variacao?.isUp ? (
          <LikeIcon width={print ? 30 : 50} />
        ) : (
          <DSLike width={print ? 30 : 50} />
        )}
      </div>
      {variacao ? (
        <div className="text_custom_card">
          Houve {variacao?.isUp ? 'um' : 'uma'}{' '}
          <span style={{ color: '#4B5166', fontWeight: 900 }}>
            {variacao?.isUp ? 'aumento' : 'redução'} de{' '}
            {variacao?.porcentagem ?? 0}
          </span>
          <br />
          no desvio{' '}
          <span style={{ color: '#BDCC30', fontWeight: 900 }}>
            {variacao?.fator_risco ?? ''}
          </span>{' '}
          nos últimos {dateMap[period]}
        </div>
      ) : (
        <div className="text_custom_card">Nenhum dado retornado.</div>
      )}
    </div>
  );
};

const Card1 = ({ fatoresGraveGravissimo }) => {
  return (
    <div className="card1">
      <div>
        <div className="title">Desvios graves e gravíssimos</div>
        <div className="sub-title">
          <span
            style={{
              color: 'red',
            }}
          >
            {fatoresGraveGravissimo.porcentagemCriticidade?.isUp ? '▲' : '▼'}{' '}
            {fatoresGraveGravissimo.porcentagemCriticidade?.text || ''}{' '}
          </span>
          no último mês
        </div>
        <div className="sub-container">
          <div className="text1">Desvio crítico mais recorrente</div>
          <div className="text2">
            {fatoresGraveGravissimo?.desvio?.fator_risco}{' '}
            {fatoresGraveGravissimo?.desvio?.quantidade &&
              `(${fatoresGraveGravissimo?.desvio?.quantidade})`}
          </div>
        </div>
      </div>
    </div>
  );
};

const Card2 = ({ motoristasSuspensos }) => (
  <div className="card2">
    <div className="top">Motoristas que já foram suspensos</div>
    <div>
      <div className="middle-total">
        {motoristasSuspensos?.porcentagem ?? '0'}
      </div>
      <div className="middle-sub">
        {motoristasSuspensos?.total_motoristas_suspensos ?? '0'} de{' '}
        {motoristasSuspensos?.total_motoristas ?? '0'} no total
      </div>
    </div>
    <div />
  </div>
);

export const Estatisticas = ({
  filters,
  period,
  print = false,
  setLoadingData,
}) => {
  const params = useParams();
  const { hasTelemetria, hasMonitoramento } = usePlans();

  const [historyData, setHistoryData] = useState([]);
  const [historyDates, setHistoryDates] = useState({
    finalDate: new Date(),
    initialDate: startOfMonth(subMonths(new Date(), 11)),
  });
  const [historyTab, setHistoryTab] = useState(
    hasTelemetria && !hasMonitoramento ? 'ptsKm' : 'ptsH',
  );
  const [radarTab, setRadarTab] = useState(
    hasTelemetria && !hasMonitoramento ? 'ptsKm' : 'ptsH',
    'ptsH',
  );

  const { data: _historyData, isLoading: isLoadingHistory } = useQuery(
    ['empresa-historico-pontos', params.id, historyDates],
    () => params.id && API.getHistorico(params.id, { ...historyDates }),
    {
      refetchOnWindowFocus: false,
    },
  );

  const { isFetching, data: radarData } = useQuery(
    ['empresa-radar-desvios', params.id, filters, radarTab],
    () =>
      params.id &&
      API.getRadarDesvio(radarTab, {
        ...filters,
        empresas: [params.id],
        tab: 'Desvios',
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: 300000,
    },
  );

  const { data: cards } = useQuery(
    ['empresa-historico-cards', params.id, filters],
    () => params.id && API.getCards(params.id, { ...filters }),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: 300000,
    },
  );

  const { data: dataRadarDeRisco } = useQuery(
    ['empresa-historico-risco', params.id, filters],
    () => params.id && API.getRadarDeRisco(params.id, { ...filters }),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: 300000,
    },
  );

  const { data: fatoresGraveGravissimo } = useQuery(
    ['empresa-historico-graves', params.id],
    () => params.id && API.getFatoresRiscoGraves(params.id),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: 300000,
    },
  );

  const { data: variacao } = useQuery(
    ['empresa-historico-variacao', params.id, filters],
    () => params.id && API.getVariacao(params.id, filters),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: 300000,
    },
  );

  const { data: motoristasSuspensos } = useQuery(
    ['empresa-historico-variacao', params.id],
    () => params.id && API.getMotoristasSuspensos(params.id),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: 300000,
    },
  );

  useEffect(() => {
    if (_historyData?.success) {
      const _data =
        _historyData?.empresa?.reduce((acc, item, index) => {
          acc.push({
            name: item.period,
            x: historyTab === 'ptsH' ? item.pontos_horas : item.pontos_kms,
            factor2:
              historyTab === 'ptsH'
                ? _historyData?.global?.[index]?.pontos_horas ?? 0
                : _historyData?.global?.[index]?.pontos_kms ?? 0,
            meta: _historyData ?? 0,
          });
          return acc;
        }, []) ?? [];
      setHistoryData(_data);
    } else {
      setHistoryData([]);
    }
  }, [_historyData, historyTab]);

  useEffect(() => {
    if (setLoadingData instanceof Function) {
      setLoadingData(isLoadingHistory || isFetching);
    }
  }, [isLoadingHistory, isFetching]);

  const graphTabs = useMemo(() => {
    const tabs = [];
    if (hasMonitoramento) tabs.push({ value: 'ptsH', label: 'PONTOS/HORA' });
    if (hasTelemetria) tabs.push({ value: 'ptsKm', label: 'PONTOS/KM' });
    return tabs;
  }, [hasMonitoramento, hasTelemetria]);

  return (
    <S.Container>
      {print && (
        <S.ContentCards print>
          <Variacao variacao={variacao?.data} period={period} print={print} />
        </S.ContentCards>
      )}
      <S.ContentCards>
        {cards?.data?.map((item, k) => (
          <>
            <Cards
              key={k}
              width="calc(33.33% - 10px)"
              title={item.title}
              percentage={item?.value === 'NaN' ? 0 : item?.value}
              colorTotal={item?.color ?? null}
              valueFooter={
                <>
                  {!!item.meta && (
                    <div>
                      Meta: <span>{item.meta}</span>
                    </div>
                  )}
                  {!!item.prev_month && (
                    <div>
                      Último período:{' '}
                      <span style={{ color: item.prev_month_color }}>
                        {item.isUp ? '▲' : '▼'}
                        {item.prev_month}
                      </span>
                    </div>
                  )}
                </>
              }
            />
            <div className="space" />
          </>
        ))}
        {!print && <Variacao variacao={variacao?.data} />}
      </S.ContentCards>
      <S.ContentGraphMiddle>
        <div className="container-radar-graph">
          <GraphRadar data={dataRadarDeRisco?.data ?? []} print={print} />
        </div>
        <div className="container-graph">
          <GraphRDesvio
            print={print}
            data={radarData?.data || []}
            tabsItems={graphTabs}
            handleTabClick={value => {
              setRadarTab(value);
            }}
            currentSelection={radarTab}
            isLoading={isFetching}
            metaValue={radarData?.meta ?? 10}
          />
        </div>
      </S.ContentGraphMiddle>
      <div style={{ width: '100%' }}>
        <Divider />
      </div>
      <br />
      <S.ContentGraphBottom print={print}>
        <div className="container-graph">
          <HistoryPoints
            print={print}
            data={historyData || []}
            tab={historyTab}
            tabs={graphTabs}
            handleChangeTab={value => setHistoryTab(value)}
            dates={historyDates}
            handleDate={dates => setHistoryDates(dates)}
          />
        </div>
        {!print && (
          <>
            <Card1
              fatoresGraveGravissimo={fatoresGraveGravissimo?.data ?? {}}
            />
            <Card2 motoristasSuspensos={motoristasSuspensos?.data ?? null} />
          </>
        )}
      </S.ContentGraphBottom>
      <br />
      {print && (
        <S.ContentGraphBottom print>
          <Card1 fatoresGraveGravissimo={fatoresGraveGravissimo?.data ?? {}} />
          <Card2 motoristasSuspensos={motoristasSuspensos?.data ?? null} />
        </S.ContentGraphBottom>
      )}
    </S.Container>
  );
};
