import api from 'services/api';

export const GetContents = async () => {
  const res = await api.get('/provider/training/contents');
  return res.data;
};

export const CreateTitleForm = async titulo => {
  const res = await api.post('/form', { titulo });
  return res;
};

export const SaveFields = async data => {
  const res = await api.put(`/form/${data.id}`, data);
  return res.data;
};

export const GetConteudo = async id => {
  const res = await api.get(`/provider/training/contents/${id}`);
  return res.data;
};

export const UseTemplate = async id => {
  const res = await api.get(`/provider/training/template/${id}`);
  return res.data;
};
