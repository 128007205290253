import { toast } from 'react-toastify';

export const verifyErrors = (fields, data, setErrors) => {
  let errors = {};
  fields.forEach(field => {
    if (
      field.name === 'arquivo_croqui' &&
      field.required &&
      !data.arquivo_croqui
    ) {
      setErrors &&
        toast.error('Insira uma imagem para "Local de Entrega/Croqui"');
      errors.arquivo_croqui = 'Campo obrigatório';
    } else if (
      field.name === 'tanques' &&
      field.required &&
      !data.tanques.length
    ) {
      setErrors && toast.error('Insira pelo menos um tanque');
      errors.tanques = 'Campo obrigatório';
    }
  });

  if (data?.tanques?.length) {
    const err = {};
    for (let i in data.tanques) {
      const tanque = data?.tanques[i];
      if (!tanque?.id_do_produto) {
        err[i] = {
          ...err[i],
          produto: 'Campo obrigatório',
        };
      }

      if (!tanque?.capacidade) {
        err[i] = {
          ...err[i],
          capacidade: 'Campo obrigatório',
        };
      }
    }

    if (Object.keys(err).length) {
      errors.tanques = err;
      setErrors && toast.error('Preencha todos os campos dos tanques');
    }
  }

  setErrors && setErrors(errors);
  return !!Object.keys(errors).length;
};
