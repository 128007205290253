// Styled
import { useTheme } from 'styled-components';

// React
import { useDispatch } from 'react-redux';
import React, { useState, memo } from 'react';
import { setItems } from 'store/modules/formularios/actions';

// Components
import ConfirmModal from 'components/ConfirmModal';

// Components Material
import { ListItem, Divider } from '@mui/material';
import { DeleteOutline } from '@mui/icons-material';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

// Drag Lib
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import TextAreaEditable from '../TextAreaEditable';
import ContentItems from '../ContentItems';
import AddComponent from '../AddComponent';
import InputEdit from '../InputEditable';
import LineSection from '../LineSection';
import * as S from './styled';

const Secoes = ({ items, setExpanded, pathname }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [deleteSection, setDeleteSection] = useState(null);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = ({ destination, source }) => {
    if (!destination) return;

    const reordered = reorder(items, source.index, destination.index);

    dispatch(setItems(reordered));
  };

  const confirmDeleteSection = () => {
    let cloneItems = JSON.parse(JSON.stringify(items));
    const notRemove = [];

    cloneItems = cloneItems.map(item => {
      if (item.index !== deleteSection) {
        notRemove.push(item);
      }
    });

    dispatch(setItems(notRemove));
    setDeleteSection(null);
  };

  const DraggableListItem = ({ item, index }) => {
    return (
      <Draggable draggableId={String(item.index)} index={index}>
        {provided => (
          <ListItem
            ref={provided.innerRef}
            {...provided.draggableProps}
            sx={{
              padding: '0px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <LineSection item={item} />

            <S.Card key={item.index}>
              <S.HeaderCard>
                <span className="preDivider">
                  <span>
                    <span {...provided.dragHandleProps}>
                      <MenuOutlinedIcon style={{ cursor: 'move' }} />
                    </span>
                    <InputEdit item={item} index={item.index} />
                  </span>
                  {items.length > 1 && (
                    <DeleteOutlineOutlinedIcon
                      htmlColor={
                        theme.palette.semantics.feedback.attention.natural
                      }
                      onClick={() => setDeleteSection(item.index)}
                      style={{ cursor: 'pointer' }}
                    />
                  )}
                </span>
                <Divider />
              </S.HeaderCard>

              <TextAreaEditable item={item} />

              <ContentItems
                items={item.campos}
                section={item.index}
                pathname={pathname}
              />

              <S.ComponentsArea>
                <AddComponent setExpanded={setExpanded} item={item} />
              </S.ComponentsArea>
            </S.Card>
          </ListItem>
        )}
      </Draggable>
    );
  };

  const DraggableList = memo(({ items, onDragEnds }) => {
    return (
      <DragDropContext onDragEnd={onDragEnds}>
        <Droppable droppableId="droppable-list">
          {provided => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {items.map((item, index) => {
                return (
                  <DraggableListItem
                    item={item}
                    index={index}
                    key={String(item.index)}
                  />
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  });

  return (
    <S.Container>
      <DraggableList items={items} onDragEnds={onDragEnd} />

      <LineSection />

      <ConfirmModal
        open={deleteSection !== null}
        buttonText="Excluir"
        isNegative
        titleIcon={
          <DeleteOutline
            sx={{ color: theme.palette.semantics.feedback.attention.natural }}
            fontSize="medium"
          />
        }
        title="Tem certeza que deseja excluir essa seção?"
        subtitle={
          !pathname
            ? 'Todos os compoenentes da seção serão apagados'
            : 'Todos os dados referentes a seção serão excluídos da plataforma Onisys'
        }
        onClick={() => confirmDeleteSection()}
        handleClose={() => setDeleteSection(null)}
      />
    </S.Container>
  );
};

export default Secoes;
