import styled from 'styled-components';
import colors from 'styles/colors';

export const Box = styled.div`
  border: 2px solid
    ${props => (props.isCheck ? colors.greenMain : colors.greyTiny)};
  width: ${props => (props.preview ? '11px' : '22px')};
  height: ${props => (props.preview ? '11px' : '22px')};
  border-radius: 24px;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2px;
  pointer-events: ${props => (props.disabled ? 'none' : 'all')};
  background-color: ${props => (props.isCheck ? colors.greenMain : '#0000')};

  input {
    display: none;
  }

  @media (max-width: 1260px) {
    width: ${props => (props.preview ? '14px' : '20px')};
    height: ${props => (props.preview ? '14px' : '20px')};
  }

  .pointer {
    width: 7px;
    height: 7px;
    background-color: ${props => (props.isCheck ? '#FFF' : '#0000')};
    border-radius: 10px;
  }
`;

export const Label = styled.label`
  display: flex;
  align-items: center;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  font-size: ${props => (props.preview ? '14px' : '16px')};

  span {
    margin-left: 5px;
    font-weight: 600;
    color: ${props =>
      props.hasError ? colors.redDanger : colors.greySubtitle};
  }

  @media (max-width: 1260px) {
    font-size: 14px;
  }
`;
