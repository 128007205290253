// Pages
import GuiaUsuario from 'pages/Guia/Page';
import Catalogo from 'pages/Guia/Catalago';

export default {
  '/guia-usuario': {
    back: -1,
    title: 'Guia do Usuário',
    defaults: [],
    page: <GuiaUsuario />,
  },
  '/catalogo': {
    back: -1,
    title: 'Catálogo',
    defaults: [],
    page: <Catalogo />,
  },
};
