import styled from 'styled-components';

export const Container = styled.div`
  .boxLoading {
    position: relative;
    width: 100%;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
  }
`;

export const Action = styled.div`
  margin-top: 1.5rem;
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`;
