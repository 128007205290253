import * as S from './styled';

export const SideBar = ({ tab, setTab, tabs = [], loading }) => {
  return (
    <S.SideBar>
      {tabs?.map((t, index) => (
        <div
          className={t?.value === tab ? 'item active' : 'item'}
          key={index}
          onClick={() => !loading && setTab(t?.value)}
        >
          <label>{t?.label}</label>
          <S.Loading isLoading={loading} />
        </div>
      ))}
    </S.SideBar>
  );
};
