export const fields = {
  1: [
    {
      name: 'avaliacao_risco',
      required: true,
    },
    {
      name: 'imagens',
      required: false,
    },
  ],
  4: [
    {
      name: 'avaliacao_risco',
      required: false,
    },
    {
      name: 'imagens',
      required: true,
    },
  ],
};
