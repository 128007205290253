import { toast } from 'react-toastify';

export const verifyErrors = (fields, data) => {
  let errors = [];
  fields.forEach(field => {
    if (field.required && !data[field.name]) {
      errors.push(field.name);
      toast.error(`Insira uma imagem para criar a CAE`);
    }
  });
  return !!errors?.length;
};
