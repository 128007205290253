import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { AddCircleOutline } from '@mui/icons-material';

import Tabs from 'components/Tabs';
import DefaultButtonPopover from 'components/Buttons/DefaultPopover';
import { DefaultTable } from 'components/_Table/templates/default';
import { Switch } from 'components/Inputs/Switch';
import { trackEvent } from 'utils/mixpanel';
import * as S from './styled';

// Services
import * as request from './services';

// Columns
import { columnsNiveis2, columnsViolacoes2 } from './columns';

// ConfigCard
import ConfigCard from './ConfigCard';

// Modais
import AddViolacaoModal from './AddViolacaoModal';
import AddNivelModal from './AddNivelModal';
import EditViolacaoModal from './EditViolacaoModal';
import EditNivelModal from './EditNivelModal';

// Components

const pageTabs = [
  { value: 'violacoes', label: 'Violações' },
  { value: 'niveis', label: 'Níveis' },
];

const PadRanking = () => {
  const user = useSelector(state => {
    return state.auth?.user?.user;
  });
  const [pageTab, setPageTab] = useState('violacoes');

  const [configViolacoes, setConfigViolacoes] = useState(false);
  const [configNiveis, setConfigNiveis] = useState(false);

  const [niveis, setNiveis] = useState(null);
  const [violacoes, setViolacoes] = useState(null);
  const [editViolacao, setEditViolacao] = useState(null);
  const [editNivel, setEditNivel] = useState(null);

  const [loading, setLoading] = useState(false);

  const [openAddViolacaoModal, setOpenAddViolacaoModal] = useState(false);
  const [openAddNivelModal, setOpenAddNivelModal] = useState(false);
  const [openEditViolacaoModal, setOpenEditViolacaoModal] = useState(false);
  const [openEditNivelModal, setOpenEditNivelModal] = useState(false);

  const [updated, setUpdated] = useState(false);
  const [stopProgress, setStopProgress] = useState(true);

  const hasConfig = async () => {
    const config = await request.requestConfig();

    if (config.success) {
      setConfigViolacoes(config.data.violacoes);
      setConfigNiveis(config.data.niveis);
    }
  };

  const handlePageTab = (event, newValue) => {
    trackEvent(user, `CONF. PAD: ${newValue}`);
    setPageTab(newValue);
  };

  // ============ Funções para VIOLAÇÕES =====================
  // TODO Aprender como passar o objeto inteiro! Não somente o ID!
  const handleAtivarViolacao = async (id, viol) => {
    trackEvent(user, `CONF. PAD: ATIVAR VIOLAÇÃO`);
    let violacao = null;
    if (viol) {
      violacao = viol;
    } else {
      violacao = violacoes.find(item => String(item.id) === String(id));
    }

    if (violacao.status && violacao.status === 'ATIVO')
      return toast.success('Essa violação já está ativa');

    if (violacao.padrao) {
      delete violacao.id;
      delete violacao.createdAt;
      delete violacao.updatedAt;
      violacao.switch = true;
      const res = await request.registerViolacao(violacao);
      violacao = res.data;
    } else {
      violacao.status = 'ATIVO';
      const res = await request.editViolacao(violacao);
    }

    fetchData();

    // Se ativar, deve editar imediatamente!
    if (violacao.status === 'ATIVO') {
      violacao.isNew = true;
      setEditViolacao(violacao);
      setOpenEditViolacaoModal(true);
    }
  };

  const handleDesativarViolacao = async (id, viol) => {
    trackEvent(user, `CONF. PAD: DESATIVAR VIOLAÇÃO`);

    let violacao = null;
    if (viol) {
      violacao = viol;
    } else {
      violacao = violacoes.find(item => String(item.id) === String(id));
    }

    if (!violacao.status || violacao.status === 'INATIVO')
      return toast.success('Essa violação já está inativa');

    violacao.status = 'INATIVO';
    const res = await request.editViolacao(violacao);
    if (res.success) {
      fetchData();
      toast.success(res.message);
    } else toast.error(res.message);
  };

  const handleEditViolacao = id => {
    trackEvent(user, 'CONF. PAD: EDITAR VIOLAÇÃO');

    const violacao = violacoes.find(item => String(item.id) === String(id));

    if (violacao) {
      setEditViolacao(violacao);
      setOpenEditViolacaoModal(true);
    } else toast.error('Não foi possível encontrar essa violação');
  };

  // ============ Funções para NIVEIS ========================
  // TODO Aprender como passar o objeto inteiro! Não somente o ID!
  const handleEditNivel = id => {
    trackEvent(user, 'CONF. PAD: EDITAR NÍVEL');
    const editing = niveis.find(n => String(n.id) === String(id));
    if (editing) {
      setEditNivel(editing);
      setOpenEditNivelModal(true);
    } else setOpenEditNivelModal(false);
  };

  const handleDeleteNivel = async id => {
    trackEvent(user, 'CONF. PAD: EXCLUIR NÍVEL');

    if (niveis && niveis.length > 0) {
      const lastNivel = niveis.reduce((max, nivel) =>
        max.pontuacao_final > nivel.pontuacao_final ? max : nivel,
      );
      if (parseInt(lastNivel.id, 10) !== parseInt(id, 10)) {
        toast.error(
          `Remova primeiro o nível com maior pontuação: ${lastNivel.nivel}`,
        );
      } else {
        const res = await request.deleteNivel(id);
        if (res.success) toast.success(res.message);
        else toast.error(res.message);
        setUpdated(true);
      }
    }
  };

  const actionsViolacoes = [
    {
      title: 'Ativar',
      function: handleAtivarViolacao,
    },
    {
      title: 'Desativar',
      function: handleDesativarViolacao,
    },
    {
      title: 'Editar',
      function: handleEditViolacao,
    },
  ];

  const actionsNiveis = [
    {
      title: 'Editar',
      function: handleEditNivel,
    },
    {
      title: 'Excluir',
      function: handleDeleteNivel,
    },
  ];

  const fetchData = async () => {
    setLoading(true);

    const res = await request.requestViolacoes();
    if (res.success) {
      setViolacoes(res.rows);
    } else toast.error('Não foi possível encontrar a lista de violações.');

    const res2 = await request.requestNiveis();
    if (res2.success) setNiveis(res2.data);
    else toast.error('Não foram encontrados níveis cadastrados');

    setUpdated(false);
    setLoading(false);
  };

  useEffect(() => {
    hasConfig();
    fetchData();
  }, [updated]);

  const renderHeader = () => {
    return (
      <>
        <S.TitleWrapper>
          <h1>Configurações PAD</h1>

          {pageTab === 'violacoes' && niveis?.length > 0 && (
            <div>
              <DefaultButtonPopover
                startIcon={<AddCircleOutline />}
                size="medium"
                sx={{ marginLeft: '10px' }}
                onClick={() => {
                  trackEvent(user, `CONF. PAD: ADICIONAR VIOLAÇÃO`);
                  setOpenAddViolacaoModal(true);
                }}
              >
                ADICIONAR VIOLACAO
              </DefaultButtonPopover>
            </div>
          )}
          {pageTab === 'niveis' && (
            <div>
              <DefaultButtonPopover
                startIcon={<AddCircleOutline />}
                size="medium"
                sx={{ marginLeft: '10px' }}
                onClick={() => setOpenAddNivelModal(true)}
              >
                ADICIONAR PONTUAÇÃO
              </DefaultButtonPopover>
            </div>
          )}
        </S.TitleWrapper>

        <Tabs value={pageTab} items={pageTabs} onChange={handlePageTab} />
      </>
    );
  };

  const switchCol = () => {
    const newCols = columnsViolacoes2.map(col => {
      if (col.id === 'status') {
        return {
          ...col,
          switch: {
            value: value => value === 'ATIVO',
            onChange: (value, item) => {
              value
                ? handleAtivarViolacao(null, item)
                : handleDesativarViolacao(null, item);
            },
          },
        };
      }
      return col;
    });
    return newCols;
  };

  const renderTable = () => {
    if ((configViolacoes && configNiveis) || loading) {
      if (pageTab === 'violacoes') {
        return (
          <DefaultTable
            data={violacoes || []}
            columns={switchCol()}
            actions={actionsViolacoes}
            searchKeys={['descricao', 'pontos']}
            loading={loading}
            placeholder="Buscar violação"
            sortBy={{ id: 'status', order: 'DESC' }}
            searchEvent={search =>
              trackEvent(user, 'Busca Configurações do PAD', null, search)
            }
          />
        );
      }
      if (pageTab === 'niveis') {
        return (
          <DefaultTable
            data={niveis || []}
            columns={columnsNiveis2}
            actions={actionsNiveis}
            searchKeys={['pontuacao_inicial', 'descricao']}
            loading={loading}
            placeholder="Buscar por nível"
          />
        );
      }
    }
    return (
      <ConfigCard
        image="padconfig.png"
        title={
          configViolacoes === false
            ? 'Comece a configurar as violações por aqui.'
            : 'Ótimo! Agora só falta configurar os níveis de pontuação por aqui'
        }
        subtitle="Selecione ou cadastre os itens que serão aplicadas ações disciplinares!"
        configViolations={configViolacoes}
        configNiveis={configNiveis}
      />
    );
  };

  return (
    <S.Main>
      {renderHeader()}
      {renderTable()}

      {openAddViolacaoModal && (
        <AddViolacaoModal
          open={openAddViolacaoModal}
          handleClose={() => setOpenAddViolacaoModal(false)}
          title="Criar Nova Ocorrência"
          closeModal={setOpenAddViolacaoModal}
          setUpdated={setUpdated}
          stopProgress={setStopProgress}
        />
      )}

      {openAddNivelModal && (
        <AddNivelModal
          open={openAddNivelModal}
          handleClose={() => setOpenAddNivelModal(false)}
          title="Criar Novo Nível"
          niveis={niveis}
          closeModal={setOpenAddNivelModal}
          setUpdated={setUpdated}
          stopProgress={setStopProgress}
        />
      )}

      {openEditViolacaoModal && (
        <EditViolacaoModal
          open={openEditViolacaoModal}
          handleClose={() => setOpenEditViolacaoModal(false)}
          title="Editar Violação"
          violations={violacoes}
          edited={editViolacao}
          closeModal={setOpenEditViolacaoModal}
          setUpdated={setUpdated}
        />
      )}

      {openEditNivelModal && (
        <EditNivelModal
          open={openEditNivelModal}
          handleClose={() => setOpenEditNivelModal(false)}
          title="Editar Nível"
          niveis={niveis}
          edited={editNivel}
          closeModal={setOpenEditNivelModal}
          setUpdated={setUpdated}
        />
      )}
    </S.Main>
  );
};

export default PadRanking;
