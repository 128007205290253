import produce from 'immer';
import * as types from './types';

const INITIAL_STATE = {
  id: null,
  status: null,
  titulo: null,
  capa: null,
  currentElement: {}, // Nao usar
  currentSection: null,
  gerais: null,
  secoes: [],
  isEditable: true,
};

export default function formularios(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case types.SET_SECTION: {
        draft.currentSection = action.payload.currentSection;
        break;
      }
      case types.SET_ITEMS: {
        draft.secoes = action.payload.secoes;
        break;
      }
      case types.SET_ELEMENT: {
        // Nao user
        draft.currentElement = action.payload.item;
        break;
      }
      case types.SET_TITLE: {
        draft.titulo = action.payload.title;
        break;
      }
      case types.SET_ID: {
        draft.id = action.payload.id;
        break;
      }
      case types.SET_CAPA: {
        draft.capa = action.payload.capa;
        break;
      }
      case types.SET_STATUS: {
        draft.status = action.payload.status;
        break;
      }
      case types.SET_GERAIS: {
        draft.gerais = action.payload.gerais;
        break;
      }
      case types.IS_EDITABLE: {
        draft.isEditable = action.payload.isEditable;
        break;
      }
      case types.RESET_STATE: {
        draft.id = null;
        draft.capa = null;
        draft.status = null;
        draft.titulo = null;
        draft.secoes = [];
        draft.currentElement = {}; // nao usar
        draft.currentSection = null;
        draft.isEditable = true;
        break;
      }
      default:
    }
  });
}
