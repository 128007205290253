export const fields = [
  {
    label: 'ID',
    value: 'id',
    selected: true,
    default: true,
  },
  {
    label: 'Motorista',
    value: 'motorista.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Filial',
    value: 'filial.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Cliente',
    value: 'distribuidora.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Veículo',
    value: 'caminhao.placa',
    selected: true,
    default: true,
  },
  {
    label: 'Frota',
    value: 'caminhao.frota',
    selected: true,
    default: true,
  },
  {
    label: 'Responsável',
    value: 'avaliador.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Itens avaliados',
    value: 'n_de_itens_avaliados',
    selected: true,
    default: true,
  },
  {
    label: 'Itens atendidos',
    value: 'itens_atendidos',
    selected: true,
    default: true,
  },
  {
    label: 'Atendimento',
    value: 'percentual_de_atendimento',
    selected: true,
    default: true,
  },
  {
    label: 'Pontuação',
    value: 'pontuacao',
    selected: true,
    default: true,
  },
  {
    label: 'Tempo de avaliação',
    value: 'horas',
    selected: true,
    default: true,
  },
  {
    label: 'Previsão de conclusão',
    value: 'data_feedback',
    selected: true,
    default: true,
  },
  {
    label: 'Data da viagem',
    value: 'data_avaliacao',
    selected: true,
    default: true,
  },
  {
    label: 'Hora início',
    value: 'hora_inicial',
    selected: true,
    default: true,
  },
  {
    label: 'Hora fim',
    value: 'hora_final',
    selected: true,
    default: true,
  },
  {
    label: 'Data preenchimento',
    value: 'data_finalizacao',
    selected: true,
    default: true,
  },
  {
    label: 'Data processamento',
    value: 'data_processamento',
    selected: true,
    default: true,
  },
  {
    label: 'Disponibilizado Em',
    value: 'data_pendente',
    selected: true,
    default: true,
  },
  {
    label: 'Placa reboque',
    value: 'placa_reboque',
    selected: true,
    default: true,
  },
  {
    label: 'Local',
    value: 'local',
    selected: true,
    default: true,
  },
  {
    label: 'Status',
    value: 'status',
    selected: true,
    default: true,
  },
  {
    label: 'Justificativa indisponibilidade',
    value: 'log',
    selected: true,
    default: true,
  },
  {
    label: 'Data assinatura',
    value: 'assinada_em',
    selected: true,
    default: true,
  },
  {
    label: 'Observações',
    value: 'observacoes',
    selected: true,
    default: true,
  },
  {
    label: 'Semana',
    value: 'semana',
    selected: true,
    default: true,
  },
  {
    label: 'Justificativa arquivamento',
    value: 'justificativa',
    selected: true,
    default: true,
  },
  {
    label: 'Tipo',
    value: 'tipo',
    selected: true,
    default: true,
  },
  {
    label: 'Contestação',
    value: 'contestacao',
    selected: true,
    default: true,
  },
  {
    label: 'Testemunha',
    value: 'testemunha.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Data assinatura testemunha',
    value: 'assinatura_testemunha_em',
    selected: true,
    default: true,
  },
  {
    label: 'Status revisão cliente',
    value: 'revisao_da_operacao',
    selected: true,
    default: true,
  },
  {
    label: 'Data revisão cliente',
    value: 'data_revisao',
    selected: true,
    default: true,
  },
  {
    label: 'Data avaliação cliente',
    value: 'data_revisao_distribuidora',
    selected: true,
    default: true,
  },
  {
    label: 'Observação revisão cliente',
    value: 'motivo_revisao',
    selected: true,
    default: true,
  },
  {
    label: 'Data video',
    value: 'data_video_original',
    selected: true,
    default: true,
  },
];

export const resetFields = fields => {
  return fields.map(i => {
    if (!i.default) i.selected = false;
    return i;
  });
};

export const tabs = [
  {
    value: 0,
    label: 'Pendentes',
  },
  {
    value: 1,
    label: 'Preenchidas',
  },
  {
    value: 2,
    label: 'Assinadas',
  },
  {
    value: 3,
    label: 'Arquivadas',
  },
];

export const justificativas = [
  'Vídeo não disponibilizado',
  'Defeito DVR',
  'Defeito cartão de memória',
  'Defeito Câmera',
  'Folga',
  'Férias',
  'Outra operação',
  'Afastamento',
  'Avaliação Já Realizada no Mês',
  'Outra',
];
