import styled from 'styled-components';
import Card from '@mui/material/Card';

const StyledCard = styled(Card)`
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  position: relative;
`;

const Header = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TotalContainer = styled.div`
  position: absolute;
  width: 80px;
  top: 175px;
  left: 80px;
  text-align: center;
  /* z-index: 2; */

  h2 {
    color: ${({ theme }) => theme.palette.words.subtitle.light};
    font: normal 600 32px Texta;
  }
`;

const LegendContainer = styled.div`
  position: absolute;
  width: 50%;
  height: 250px;
  top: 80px;
  right: 10px;
  display: flex;
  /* z-index: 2; */
`;

const LegendItem = styled.div`
  display: flex;
  flex-direction: column;

  h2 {
    color: ${props => props.color};
    font: normal 900 32px Texta;
  }

  h3 {
    color: ${({ theme }) => theme.palette.words.subtitle.light};
    font: normal 600 18px Texta;
  }
`;

const BoxLoading = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background-color: ${({ theme }) => theme.palette.system.overlay};
`;

export {
  StyledCard,
  Header,
  TotalContainer,
  LegendContainer,
  LegendItem,
  BoxLoading,
};
