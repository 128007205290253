/* eslint-disable import/order */

// Styles
import * as S from './styled';
import colors from 'styles/colors';

// React
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

// Componets
import TextInput from 'components/Inputs/TextField';
import DropZoneArchives from 'components/Inputs/DropZoneCustom';

// Material UI
import { IconButton } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

export const Imagem = ({
  item,
  setChanged,
  media,
  index,
  setOpenConfirmDelete,
  deleteMedia,
  error,
  isPublished,
}) => {
  const contents = useSelector(state => state.createContents.contents);

  const [bannerURL, setBannerURL] = useState(item.link);
  const [bannerURLTexto, setBannerURLTexto] = useState(item.titulo);
  const [bannerURLDescription, setBannerURLDescription] = useState(
    item.descricao,
  );

  const getId = () => {
    if (contents.find(a => a.id)) {
      return contents[0].id;
    }
    return new Date().getTime();
  };

  useEffect(() => {
    Object.assign(media[index], {
      link: bannerURL,
      tipo: 'IMAGEM',
      descricao: bannerURLDescription,
      titulo: bannerURLTexto,
    });
    setChanged(true);
  }, [bannerURLTexto, bannerURLDescription, bannerURL]);

  const hanldeDelete = () => {
    if (bannerURLTexto || bannerURLDescription || bannerURL) {
      setOpenConfirmDelete(true);
    } else {
      setChanged(true);
      deleteMedia();
    }
  };

  return (
    <div style={{ width: '100%' }}>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <IconButton onClick={() => hanldeDelete()}>
          <DeleteOutlineOutlinedIcon htmlColor={colors.redDanger} />
        </IconButton>
      </div>
      <S.Container>
        <TextInput
          id={`input_text_${index}`}
          label="Titulo"
          placeholder="Titulo"
          value={bannerURLTexto}
          onChange={e => setBannerURLTexto(e.target.value.slice(0, 200))}
          error={error?.titulo && !bannerURLTexto}
          disabled={isPublished && !!item.id}
        />
        <TextInput
          label="Descrição"
          placeholder="Descrição"
          value={bannerURLDescription}
          onChange={e => setBannerURLDescription(e.target.value.slice(0, 200))}
          error={error?.descricao && !bannerURLDescription}
          disabled={isPublished && !!item.id}
        />
        <S.MediaArchive>
          <DropZoneArchives
            data={bannerURL ? { imagem_url: bannerURL, legenda: '' } : null}
            multiple={false}
            fileDir={`capacitacao/media/images/${getId()}/`}
            addFunction={el =>
              setBannerURL(Array.isArray(el) ? el[0].imagem_url : '')
            }
            deleteFunction={() => setBannerURL('')}
            automaticSave
            titleButton="Alterar Imagem"
            onlyRead={isPublished && !!item.id}
            unicCenter
            noTopButton
            error={error?.link && !bannerURL}
            label="Imagem/Folder"
            maxSize={20}
          />
        </S.MediaArchive>
      </S.Container>
    </div>
  );
};
