import GhostButton from 'components/Buttons/Ghost';
import Button from 'components/Buttons/Default';
import MenuIcon from '@mui/icons-material/Menu';
import { ArrowBackIos } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { useState } from 'react';
import {
  Content,
  Container,
  Nav,
  LogoWrapper,
  LinksWrapper,
  ShowButton,
  MobileMenu,
  BackButton,
} from './styled';

const Header = ({ hasNavbar = true, ...props }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);
  return (
    <Container>
      <Content>
        {hasNavbar ? (
          <LogoWrapper src="https://uploads-ssl.webflow.com/5f2c6214ab715c5971920e7f/63faa558303f2048ad2f8218_logo-new.svg" />
        ) : (
          <BackButton onClick={() => navigate(-1)}>
            <ArrowBackIos
              fontSize="20px"
              htmlColor={theme.palette.words.subtitle.natural}
            />
            <h2>Voltar</h2>
          </BackButton>
        )}
        {hasNavbar ? (
          <>
            <Nav>
              <ul>
                <li>
                  <a href="#">Para Embarcador</a>
                </li>
                <li>
                  <a href="#">Para Transportador</a>
                </li>
                <li>
                  <a href="https://www.onisys.com.br/planos">Planos</a>
                </li>
                <li>
                  <a href="#">Sobre</a>
                </li>
                <li>
                  <a href="https://www.onisys.com.br/clientes">Clientes</a>
                </li>
              </ul>
            </Nav>
            <LinksWrapper>
              <Button>Experimente</Button>
              <a href="/login">Entrar</a>
            </LinksWrapper>
            <ShowButton>
              <GhostButton
                style={{ minWidth: '3.5rem', minHeight: '3.5rem' }}
                onClick={() => setShowMenu(show => !show)}
              >
                <MenuIcon />
              </GhostButton>
            </ShowButton>
          </>
        ) : (
          <LinksWrapper>
            <a href="tel:+5531987212142">+55 31 98721-2142</a>
          </LinksWrapper>
        )}
      </Content>
      <MobileMenu active={showMenu}>
        <ul>
          <li>
            <a>Para Embarcador</a>
          </li>
          <li>
            <a>Para Transportador</a>
          </li>
          <li>
            <a href="https://www.onisys.com.br/planos">Planos</a>
          </li>
          <li>
            <a>Sobre</a>
          </li>
          <li>
            <a href="https://www.onisys.com.br/clientes">Clientes</a>
          </li>
          <li>
            <Button>Experimente</Button>
          </li>
          <li>
            <a href="/login">Entrar</a>
          </li>
        </ul>
      </MobileMenu>
    </Container>
  );
};

export default Header;
