// Styled

// React
import { useEffect, useState } from 'react';

// Components
import TextInput from 'components/Inputs/TextField';

// Components MUI
import { Divider } from '@mui/material';
import { WithQuestion } from '..';
import * as S from './styled';

// Utils
import { changeText } from '../../actions';

export const ResponseNumero = ({
  field,
  secoes,
  setSecoes,
  sectionId,
  errosObrigatorios,
  preview,
  approveView,
}) => {
  const redirectFunction = (executable, idCampo, texto) => {
    setCleanField(true);
    executable(texto, idCampo, setSecoes, secoes, sectionId);
  };

  const formatNumber = (value, idCampo) => {
    if (Number(value) || value == 0) {
      redirectFunction(changeText, idCampo, value);
    }
  };

  // ------------------------------------ERROS-------------------------------------------//
  const [hasErrorObrigatorio, setHasErrorObrigatorio] = useState(false);
  const [cleanField, setCleanField] = useState(false);

  useEffect(() => {
    // Reset fields
    setHasErrorObrigatorio(false);
    setCleanField(false);
    if (approveView) return null;

    if (errosObrigatorios) {
      for (const i in errosObrigatorios) {
        const erros = errosObrigatorios[i];
        if (sectionId === erros.idSecao && field.id === erros.idCampo) {
          setHasErrorObrigatorio(true);
        }
      }
    }
  }, [errosObrigatorios]);

  return (
    <>
      <S.QuestionBox
        hasError={hasErrorObrigatorio && !cleanField}
        className={`fieldClass-${field.id}`}
      >
        <WithQuestion
          field={field}
          secoes={secoes}
          setSecoes={setSecoes}
          sectionId={sectionId}
          hasError={hasErrorObrigatorio && !cleanField}
          preview={preview}
          approveView={approveView}
        />
        <S.ResponseArea>
          <S.BlockInput>
            <TextInput
              noAsterisk
              value={field.resposta}
              onChange={e => formatNumber(e.target.value.slice(0, 3), field.id)}
              error={hasErrorObrigatorio && !cleanField}
              disabled={approveView}
            />
            <div className="counter">Máx: 999</div>
          </S.BlockInput>
        </S.ResponseArea>
      </S.QuestionBox>
      <Divider />
    </>
  );
};
