import styled, { css } from 'styled-components';
import Select from '@mui/material/Select';

export const StyledTextField = styled(Select)`
  ${({ theme }) => css`
    .MuiSelect-select {
      border: 2px solid ${theme.palette.brand.primary.light};
    }

    &.error {
      .MuiSelect-select {
        border: 2px solid ${theme.palette.semantics.feedback.attention.natural};
      }
    }
  `}
`;

const Container = styled.div`
  position: relative;
  transition: all ease 0.9s;
`;

const Label = styled.p`
  ${({ theme, ...props }) => css`
    color: ${props.visible
      ? theme.palette.words.label.light
      : theme.palette.words.label.natural};
    font: normal medium 14px/20px Texta;
    margin-bottom: 5px;
    position: ${props.visible ? 'static' : 'absolute'};
    margin-top: ${props.visible ? '0px' : '15px'};
    margin-left: ${props.visible ? '10px' : '10px'};

    transition: all ease 1s;
  `}
`;

const Selecteds = styled.div`
  color: ${({ theme }) => theme.palette.words.label.natural};
  font: normal medium 14px/20px Texta;
  margin-bottom: 5px;
  position: absolute;
  margin-top: 15px;
  margin-left: 10px;
  opacity: ${props => props.opacity};
  transition: all ease 0.5s;
`;

const Required = styled.span`
  font-size: 20px;
  font-weight: 500;
  color: red;
`;

const SearchContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding: 3px 10px;
`;

const SearchBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .searchIcon {
    margin-left: -30px;
  }
`;

const SearchInput = styled.input`
  ${({ theme }) => css`
    width: 100%;
    padding: 5px 8px;
    border: none;
    border: 1px solid ${theme.palette.system.divider};
    border-radius: 5px;
    font-size: 16px;

    &::placeholder {
      font-size: 16px;
    }

    &:focus {
      border: 1px solid ${theme.palette.brand.secondary.natural};
    }
  `}
`;

const ClearSelection = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    padding: 5px;
    color: ${theme.palette.words.label.natural};
    font-weight: 600;
    width: 100%;
    background-color: ${theme.palette.semantics.feedback.unknown.light};
    cursor: pointer;
  `}
`;

const OptionsArea = styled.div``;

const LabelSearch = styled.label`
  display: flex;
  justify-content: start;
  align-items: center;

  span {
    margin-left: 15px;
    font-weight: 700;
    color: ${({ theme }) => theme.palette.words.label.natural};
  }
`;

const EmptyOptions = styled.span`
  text-align: center;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  color: ${({ theme }) => theme.palette.words.label.light};
`;

const InputWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: start;
    p {
      margin-bottom: 5px;
    }
    .MuiFilledInput-root {
      color: ${theme.palette.words.text.light};
      border: 1px solid ${theme.palette.semantics.feedback.unknown.light};
      background: ${theme.palette.semantics.feedback.unknown.light} 0% 0%
        no-repeat padding-box !important;
      &:before {
        border-bottom: none !important;
      }
      &:after {
        border-bottom: none !important;
      }
      input {
        /* padding: 16.5px 14px!important; */
      }
    }
  `}
`;

export {
  Label,
  Required,
  SearchContainer,
  SearchBox,
  SearchInput,
  LabelSearch,
  OptionsArea,
  ClearSelection,
  Selecteds,
  Container,
  EmptyOptions,
  InputWrapper,
};
