import { Body } from './Body';
import { TD } from './Body/TRow';
import { TH } from './Body/THead';
import { Footer } from './Footer';
import { Header } from './Header';
import { Dynamic } from './Dynamic';
import { Bulk } from './Header/Bulk';
import { Provider } from './Provider';
import { Input } from './Header/Input';
import { Container } from './Container';
import { Filter } from './Header/Filter';
import { Counter } from './Footer/Counter';
import { Pagination } from './Footer/Pagination';
import { DoubleInput } from './Header/DoubleInput';

export const Table = {
  TD,
  TH,
  Bulk,
  Body,
  Dynamic,
  Input,
  Filter,
  Header,
  Footer,
  Counter,
  Provider,
  Pagination,
  Container,
  DoubleInput,
};
