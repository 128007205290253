import styled, { css } from 'styled-components';

import { Modal } from '@mui/material';

export const ModalItems = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NullableBackground = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    background-color: ${theme.palette.system.transparent};
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`;

export const Container = styled.div`
  ${({ theme, small }) => css`
    background-color: ${theme.palette.system.overlay};
    width: 578px;
    height: ${small};
    border-radius: 5px;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding-bottom: 80px;

    &::-webkit-scrollbar {
      border-radius: 15px;
    }
  `}
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  width: 100%;
  padding: 25px 30px 5px;

  div {
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      font-size: 20px;
      font-weight: 600;
      margin-left: 20px;
    }
  }
`;

export const Body = styled.div`
  width: ${props => props.widthBox};
  height: 100%;
  display: flex;
  margin-left: ${props => (props.step === 1 ? '0px' : '-100%')};
  transition: all ease 0.5s;
  padding-bottom: 100px;
`;

export const Footer = styled.div`
  ${({ theme, isFinal }) => css`
    width: 100%;
    height: 80px;
    border-top: 2px solid ${theme.palette.brand.primary.light};
    position: absolute;
    bottom: 0;
    background-color: ${theme.palette.system.overlay};
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    display: flex;
    justify-content: ${isFinal ? 'end' : 'space-between'};
    align-items: center;
    padding: 30px;
  `}
`;

export const BoxStep = styled.div`
  width: 100%;
  padding: 10px 30px;
`;
