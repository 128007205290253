/* eslint-disable import/order */

// Pages (in order)
import { PerfilEmpresa } from './Perfil&Empresa';
import { Notificacoes } from './Notificacoes';
import { Relatorios } from './Relatorios';
import { Filiais } from './Filiais';
import { Clientes } from './Clientes';
import { Usuarios } from './Usuarios';
import { Motoristas } from './Motoristas';
import { MinhasBases } from './MinhasBases';
import { Envios } from './Envios';
import { Seguranca } from './Seguranca';
import { Guia } from 'pages/Guia/Configs';
import { GuiaProvider } from 'pages/Provider/Guia/Configs';
import { Configuracoes } from './Configuracoes';
import { EscalationList } from './EscalationList';
import { Apis } from './Apis';

// Icons
import { ReactComponent as empresa } from '../../images/icons/configuracoes/empresa.svg';
import { ReactComponent as filial } from '../../images/icons/configuracoes/filial.svg';
import { ReactComponent as clientes } from '../../images/icons/configuracoes/clientes.svg';
import { ReactComponent as usuarios } from '../../images/icons/configuracoes/usuarios.svg';
import { ReactComponent as motoristas } from '../../images/icons/configuracoes/motoristas.svg';
import { ReactComponent as notificacoes } from '../../images/icons/configuracoes/notificacoes.svg';
import { ReactComponent as envios } from '../../images/icons/configuracoes/envios.svg';
import { ReactComponent as seguranca } from '../../images/icons/configuracoes/seguranca.svg';
import { ReactComponent as configuracoes } from '../../images/icons/configuracoes/configuracoes.svg';
import { ReactComponent as relatorios } from '../../images/icons/configuracoes/relatorios.svg';
import { ReactComponent as escalation } from '../../images/icons/configuracoes/icone-escalation.svg';
import { ReactComponent as api } from '../../images/icons/configuracoes/icone-api.svg';

/* Se alterar alguma ordem de página, lembrar de alterar a regra de validação no index.js */
export const pages = [
  {
    value: 0,
    page: 'Perfil & Empresa',
    id: 'perfil',
    linkTo: '/configuracoes/perfil',
    icon: empresa,
    element: <PerfilEmpresa />,
  },
  {
    value: 1,
    page: 'Filiais',
    id: 'filiais',
    linkTo: '/configuracoes/filiais',
    icon: filial,
    element: <Filiais />,
    levels: [1],
  },
  {
    value: 2,
    page: 'Clientes',
    id: 'clientes',
    linkTo: '/configuracoes/clientes',
    icon: clientes,
    element: <Clientes />,
    levels: [1],
  },
  {
    value: 3,
    page: 'Usuários',
    id: 'usuarios',
    linkTo: '/configuracoes/usuarios',
    icon: usuarios,
    element: <Usuarios />,
    levels: [1],
  },
  {
    value: 4,
    page: 'Motoristas',
    id: 'motoristas',
    linkTo: '/configuracoes/motoristas',
    icon: motoristas,
    element: <Motoristas />,
    plans: [150],
    levels: [1, 2, 3],
  },
  {
    value: 5,
    page: 'Notificacoes',
    id: 'notificacoes',
    linkTo: '/configuracoes/notificacoes',
    icon: notificacoes,
    element: <Notificacoes />,
  },
  {
    value: 6,
    page: 'Relatórios',
    id: 'relatorios',
    linkTo: '/configuracoes/relatorios',
    icon: relatorios,
    element: <Relatorios />,
    // levels: [1],
    plans: [190, 200],
  },
  {
    value: 7,
    page: 'Minhas bases',
    id: 'bases',
    linkTo: '/configuracoes/bases',
    icon: usuarios,
    element: <MinhasBases />,
    plans: [120, 130, 140],
    levels: [1, 2, 3],
  },
  {
    value: 8,
    page: 'Envios',
    id: 'envios',
    linkTo: '/configuracoes/envios',
    icon: envios,
    element: <Envios />,
    plans: [140],
    levels: [1, 2, 3],
  },
  {
    value: 9,
    page: 'Segurança',
    id: 'seguranca',
    linkTo: '/configuracoes/seguranca',
    icon: seguranca,
    element: <Seguranca />,
  },
  {
    value: 10,
    page: 'Guia',
    id: 'guia',
    linkTo: '/configuracoes/guia',
    icon: configuracoes,
    element: <Guia />,
    company: [163],
  },
  {
    value: 11,
    page: 'Guia Embarcador',
    id: 'guia-provider',
    linkTo: '/configuracoes/guia-provider',
    icon: configuracoes,
    element: <GuiaProvider />,
    company: [163],
  },
  {
    value: 12,
    page: 'Configurações',
    id: 'configuracoes',
    linkTo: '/configuracoes/configuracoes',
    icon: configuracoes,
    element: <Configuracoes />,
    levels: [1],
  },
  {
    value: 13,
    page: 'Escalation List',
    id: 'escalation-list',
    linkTo: '/configuracoes/escalation-list',
    icon: escalation,
    element: <EscalationList />,
    plans: [200],
    levels: [1],
  },
  {
    value: 14,
    page: 'API',
    id: 'api',
    linkTo: '/configuracoes/api',
    icon: api,
    element: <Apis />,
    levels: [1, 2, 3, 4],
  },
];
