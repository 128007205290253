import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;

    .title {
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      font-weight: 600;
      text-transform: uppercase;
      color: ${theme.palette.words.title.natural};
      cursor: pointer;
      min-width: 200px;
      max-width: 100%;
      flex: 1;
    }

    .currentTitle {
      span {
        margin-right: 10px;
      }
    }

    span {
      margin-left: 10px;
    }

    .bar {
      width: 100%;
      height: 1px;
      background-color: ${theme.palette.brand.primary.light};
      z-index: 1;
      flex: 2;
    }
  `}
`;
