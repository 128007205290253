import React, { useState, useMemo } from 'react';
import { useTheme } from 'styled-components';

import { Modal, IconButton, Icon, Grid } from '@mui/material';

import InfoCard from 'components/Cards/InfoCard';
import GhostButton from 'components/Buttons/Ghost';
import DefaultButton from 'components/Buttons/Default';
import TextInput from 'components/Inputs/TextField';
import InputAttachedFile from 'components/Inputs/InputAttachedFile2';
import * as S from './styled';

const FinishModal = ({
  ids,
  handleClose,
  handleConfirm,
  titleIconName = 'check',
  loading,
  hasJustify,
}) => {
  const theme = useTheme();
  const title = useMemo(() => {
    return ids.length > 1
      ? `Deseja finalizar os desvios selecionados? (${ids.length})`
      : 'Deseja finalizar o desvio selecionado?';
  }, [ids]);

  const subtitle = useMemo(() => {
    return ids.length > 1
      ? 'Os desvios serão movidos para Finalizados. As alterações serão aplicadas em TODOS os desvios selecionados.'
      : 'O desvio será movido para aba Finalizados.';
  }, [ids]);

  // Icone do header variável com titleIconName
  const titleIcon = (
    <Icon
      sx={{ color: theme.palette.brand.secondary.natural }}
      fontSize="medium"
    >
      {titleIconName}
    </Icon>
  );

  // Objeto de dados a serem preenchidos
  // Provável que tanha mais coisa depois
  const [data, setData] = useState({
    arquivo_justificativa: '',
  });

  const handleChange = (name, value) => {
    setData(prev => {
      return { ...prev, [name]: value };
    });
  };

  // Bloqueia botão de confirmar quando há item sem preencher
  const checkReady = () => {
    let ready = true;
    if (data.arquivo_justificativa.length < 2 && hasJustify) ready = false;
    return ready;
  };

  const handleClickConfirm = () => {
    const req = { ids };
    if (hasJustify) req.arquivo_justificativa = data.arquivo_justificativa;
    handleConfirm(req);
  };

  const open = Boolean(ids);

  return (
    <Modal open={open}>
      <S.Paper>
        <S.Header>
          <S.Title>
            {titleIcon}
            <h1>{title}</h1>
          </S.Title>

          <IconButton size="small" onClick={handleClose}>
            <Icon sx={{ color: theme.palette.words.subtitle.natural }}>
              close
            </Icon>
          </IconButton>
        </S.Header>

        <InfoCard message={subtitle} />

        {hasJustify && (
          <Grid container rowSpacing={0} columnSpacing={2} marginY={3}>
            <Grid item xs={12} sm={12}>
              {/* <TextInput
                required
                label="Arquivo Justificativa"
                value={data.arquivo_justificativa}
                onChange={e =>
                  handleChange('arquivo_justificativa', e.target.value)
                }
                name="arquivo_justificativa"
              /> */}

              <InputAttachedFile
                idInput="input_file_finish_desvio"
                required
                label="Arquivo Justificativa"
                inputLabel="Anexar Evidência"
                fileUrl={data.arquivo_justificativa}
                setFileUrl={value =>
                  handleChange('arquivo_justificativa', value)
                }
                fileDir={`/desvios/${ids[0]}`}
                // Tá aqui uma gambiarra fruto do bulk action. ids[0] será a pasta do arquivo em comum.
                // Mas como nunca é apagado não tem problema
                // deleteFromStorage não apagar arquivo antigo
              />
            </Grid>
          </Grid>
        )}

        <S.Footer>
          <GhostButton size="medium" onClick={handleClose}>
            Cancelar
          </GhostButton>

          <DefaultButton
            onClick={handleClickConfirm}
            size="medium"
            style={{ marginLeft: '10px' }}
            disabled={!checkReady()}
            loading={loading}
          >
            Confirmar
          </DefaultButton>
        </S.Footer>
      </S.Paper>
    </Modal>
  );
};

export default FinishModal;
