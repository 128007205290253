import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme, transparent }) => css`
    background-color: ${transparent
      ? 'transparent'
      : theme.palette.system.white};
    border: 2px solid
      ${transparent ? 'transparent' : theme.palette.system.border};
    border-radius: 4px;
    width: 100%;
  `}
`;
