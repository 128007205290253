import styled from 'styled-components';

const Main = styled.div(({ theme, open }) => ({
  background: theme.palette.system.overlay,
  boxShadow: `0px -3px 6px ${theme.palette.system.shadow}`,
  width: '100%',
  height: '60px',
  position: 'fixed',
  bottom: '0px',
  left: '0px',
  display: open ? 'flex' : 'none',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '5px 20px 5px 80px',
}));

const Wrapper = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  h2: {
    font: 'normal normal 900 16px/23px Texta',
    color: theme.palette.brand.primary.natural,
  },

  h3: {
    font: 'normal normal 600 16px/23px Texta',
    color: theme.palette.brand.primary.natural,
    marginLeft: '5px',
  },
}));

export { Main, Wrapper };
