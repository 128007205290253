// Icons
import { ReactComponent as user } from 'images/icons/sidebar/user.svg';
import { ReactComponent as file } from 'images/icons/sidebar/file.svg';
import { ReactComponent as inicio } from 'images/icons/sidebar/inicio.svg';
import { ReactComponent as assinatura } from 'images/icons/avaliacao/assinatura.svg';
import { ReactComponent as desvios } from 'images/icons/avaliacao/desvios.svg';
import { ReactComponent as historico } from 'images/icons/avaliacao/historico.svg';

// Components
import { InformacoesGerais } from '../InfoGerais';
import { Formulario } from '../Formulario';
import { Evidencias } from '../Evidencias';
import { Assinaturas } from '../Assinaturas';
import { Historico } from '../Historico';
import { Desvios } from '../Desvios';

export const itensBar = [
  {
    id: 0,
    page: 'Informações Gerais',
    linkTo: '',
    icon: user,
    Component: evaluation => InformacoesGerais(evaluation),
  },
  {
    id: 1,
    page: 'Formulário',
    linkTo: '',
    icon: file,
    Component: evaluation => Formulario(evaluation),
  },
  {
    id: 2,
    page: 'Evidências',
    linkTo: '',
    icon: inicio,
    Component: evaluation => Evidencias(evaluation),
  },
  {
    id: 3,
    page: 'Assinaturas',
    linkTo: '',
    icon: assinatura,
    Component: evaluation => Assinaturas(evaluation),
  },
  {
    id: 4,
    page: 'Desvios na Avaliação',
    linkTo: '',
    icon: desvios,
    Component: evaluation => Desvios(evaluation),
  },
  {
    id: 5,
    page: 'Histórico de Alterações',
    linkTo: '',
    icon: historico,
    Component: evaluation => Historico(evaluation),
  },
];
