import { Fragment, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import * as S from './styled';

import Empty from '../Empty';
import AddCard from '../AddCard';
import ContentCard from '../ContentCards';

const Templates = ({
  query,
  templates,
  inSearch,
  setModalAdd,
  setFullModal,
  forMe,
  setModalResponse,
  setFormResponse,
  getCurrentResponses,
  loadingHistoryLine,
}) => {
  const [items, setItems] = useState(7);

  return (
    <>
      {templates.length > 0 && (
        <S.Global>
          <S.Wrapper>
            {!forMe && <AddCard onClick={() => setModalAdd(true)} />}
            {templates?.map((temp, key) => (
              <Fragment key={key}>
                {key < items && (
                  <ContentCard
                    key={key}
                    template={temp}
                    setFullModal={setFullModal}
                    setModalResponse={setModalResponse}
                    setFormResponse={setFormResponse}
                    forMe={forMe}
                    getCurrentResponses={getCurrentResponses}
                    loadingHistoryLine={loadingHistoryLine}
                  />
                )}
              </Fragment>
            ))}
          </S.Wrapper>
          {templates.length > items && (
            <S.SeeMore onClick={() => setItems(items + 8)}>
              <span className="textSeeMore">Ver Mais</span>
              <div className="divIcon">
                <KeyboardArrowDownIcon className="icon" />
              </div>
            </S.SeeMore>
          )}
        </S.Global>
      )}
      {templates.length <= 0 && (
        <Empty query={query} inSearch={inSearch} setModalAdd={setModalAdd} />
      )}
    </>
  );
};

export default Templates;
