const columnsTurmas = [
  {
    Header: 'Id',
    id: 'id',
    accessor: d => String(d.id),
    sortType: 'basic',
    show: true,
    disableSortBy: false,
    centered: true,
  },
  {
    Header: 'Nome',
    id: 'nome',
    accessor: d => String(d.nome),
    sortType: 'basic',
    show: true,
    disableSortBy: false,
    centered: false,
  },
  {
    Header: 'Tipo',
    id: 'tipo',
    accessor: d => String(d.tipoUser),
    show: true,
    disableSortBy: false,
    centered: true,
  },
  {
    Header: 'Status',
    id: 'ativa',
    accessor: d => String(d.ativa),
    show: true,
    disableSortBy: false,
    centered: true,
  },
  {
    Header: 'Transportadoras',
    id: 'n_transportadoras',
    accessor: d => String(d.n_transportadoras),
    show: true,
    disableSortBy: false,
    centered: true,
  },
  {
    Header: 'Atividades',
    id: 'atividades',
    accessor: d => Number(d.atividades),
    show: true,
    disableSortBy: false,
    centered: true,
  },
  {
    Header: 'Ações',
    id: 'acoes',
    accessor: 'acoes',
    show: true,
    disableSortBy: true,
    centered: false,
  },
];

const columnsList = [
  {
    Header: 'Id',
    id: 'id',
    accessor: d => String(d.id),
    sortType: 'basic',
    show: true,
    disableSortBy: false,
    centered: true,
  },
  {
    Header: 'Nome',
    id: 'nome',
    accessor: d => String(d.nome),
    sortType: 'basic',
    show: true,
    disableSortBy: false,
    centered: false,
  },
  {
    Header: 'Tipo',
    id: 'tipo',
    accessor: d => String(d.tipoUser),
    show: true,
    disableSortBy: false,
    centered: true,
  },
  {
    Header: 'Status',
    id: 'ativa',
    accessor: d => String(d.ativa),
    show: true,
    disableSortBy: false,
    centered: true,
  },
  {
    Header: 'Transportadoras',
    id: 'n_transportadoras',
    accessor: d => String(d.n_transportadoras),
    show: true,
    disableSortBy: false,
    centered: true,
  },
  {
    Header: 'Atividades',
    id: 'atividades',
    accessor: d => Number(d.atividades),
    show: true,
    disableSortBy: false,
    centered: true,
  },
];

export { columnsTurmas, columnsList };
