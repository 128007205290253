import {
  getFaixas,
  getAcao,
  getStatusTelemetria,
  getDesviosTelemetria,
  getResponsaveis,
  getVeiculos,
  getCondicoes,
  getCarregamentos,
  getCriticidade,
  getMotoristas,
} from 'constants/_SERVICES/user';

// Pages
import TelemetriaAcoes from 'pages/TelemetriaAcoes';
import TelemetriaDesvios from 'pages/TelemetriaDesvios';
import TelemetriaRanking from 'pages/TelemetriaRanking';
import LogAcoesSuspensoes from 'pages/TelemetriaAcoes/Historico';
import TelemetriaEstatisticas from 'pages/TelemetriaEstatisticas';
import LogTelemetriaDesvio from 'pages/TelemetriaDesvios/Historico';
import TelemetriaConfiguracoes from 'pages/TelemetriaConfiguracoes';
import DetalheTelemetriaDesvios from 'pages/TelemetriaDesvios/Detalhe';
import EditDesvio from 'pages/TelemetriaConfiguracoes/ConfigDesvios/EditDesvio';
import DetalheAcoesSuspensoes from 'pages/TelemetriaAcoes/DetalheAcoesSuspensoes';
import LogTelemetriaConfiguracoes from 'pages/TelemetriaConfiguracoes/Historico';
import LogTelemetriaConfiguracoesAcao from 'pages/TelemetriaConfiguracoes/ConfigAcoesSuspensao/Historico';
import EditVelocidade from 'pages/TelemetriaConfiguracoes/ConfigVelocidades/EditVelocidade';
import EditCercaEletronica from 'pages/TelemetriaConfiguracoes/ConfigCercaEletronica/EditCercaEletronica';

export default {
  '/telemetria/configuracoes': {
    back: -1,
    defaults: [],
    page: <TelemetriaConfiguracoes />,
  },

  '/telemetria/configuracoes/desvios': {
    back: -1,
    defaults: [],
    page: <TelemetriaConfiguracoes />,
  },

  '/telemetria/configuracoes/desvios/criar': {
    back: -1,
    title: 'Adicionar Desvio',
    defaults: [],
    page: <EditDesvio novo />,
  },

  '/telemetria/configuracoes/desvios/:id': {
    back: -1,
    title: 'Editar Desvio',
    defaults: [],
    page: <EditDesvio />,
  },

  '/telemetria/configuracoes/velocidades': {
    back: -1,
    defaults: [],
    page: <TelemetriaConfiguracoes />,
  },

  '/telemetria/configuracoes/velocidades/criar': {
    back: -1,
    title: 'Adicionar Velocidade',
    defaults: [],
    page: <EditVelocidade novo />,
  },

  '/telemetria/configuracoes/velocidades/:id': {
    back: -1,
    title: 'Editar Velocidade',
    defaults: [],
    page: <EditVelocidade />,
  },

  '/telemetria/configuracoes/cerca-eletronica': {
    back: -1,
    defaults: [],
    page: <TelemetriaConfiguracoes />,
  },

  '/telemetria/configuracoes/cerca-eletronica/criar': {
    back: -1,
    defaults: [],
    title: 'Adicionar Cerca Eletrônica',
    page: <EditCercaEletronica novo />,
  },

  '/telemetria/configuracoes/cerca-eletronica/:id': {
    back: -1,
    defaults: [],
    title: 'Editar Cerca Eletrônica',
    page: <EditCercaEletronica />,
  },

  '/telemetria/configuracoes/acoes-suspensao': {
    back: -1,
    defaults: [],
    page: <TelemetriaConfiguracoes />,
  },

  '/logs/telemetria/configuracoes/desvios/:id': {
    back: -1,
    title: 'Histórico da Configuração do Desvio',
    id: true,
    defaults: [],
    page: <LogTelemetriaConfiguracoes />,
  },

  '/logs/telemetria/configuracoes/velocidades/:id': {
    back: -1,
    title: 'Histórico da Configuração de Velocidade',
    id: true,
    defaults: [],
    page: <LogTelemetriaConfiguracoes />,
  },

  '/logs/telemetria/configuracoes/cerca-eletronica/:id': {
    back: -1,
    title: 'Histórico da Configuração de Cerca Eletrônica',
    id: true,
    defaults: [],
    page: <LogTelemetriaConfiguracoes />,
  },

  '/logs/telemetria/configuracoes/acoes-suspensao/:id': {
    back: -1,
    title: 'Histórico da Configuração de Ação/Suspensão',
    id: true,
    defaults: [],
    page: <LogTelemetriaConfiguracoesAcao />,
  },

  '/telemetria/acoes-suspensao': {
    date: true,
    pageFilterName: 'filterAcoesSuspensoes',
    defaults: [],
    filters: [
      {
        name: 'faixa',
        placeholder: 'Filtrar por Faixa',
        mode: 'single',
        data: async () => getFaixas(),
      },
      {
        name: 'acaoDisciplinar',
        placeholder: 'Filtrar por Ação disciplinar',
        mode: 'single',
        data: async () => getAcao(),
      },
      {
        name: 'status',
        placeholder: 'Filtrar por Status',
        mode: 'single',
        data: async () => getStatusTelemetria(),
      },
    ],
    page: <TelemetriaAcoes />,
  },

  '/telemetria/acoes-suspensao/:id': {
    back: '/telemetria/acoes-suspensao',
    title: 'Ação Disciplinar / Suspensão',
    id: true,
    defaults: [],
    page: <DetalheAcoesSuspensoes />,
  },

  '/logs/telemetria/acoes-suspensao/:id': {
    back: -1,
    title: 'Histórico da Ação Disciplinar / Suspensão',
    id: true,
    defaults: [],
    page: <LogAcoesSuspensoes />,
  },

  '/telemetria/desvios': {
    date: true,
    pageFilterName: 'filterTelemetriaDesvios',
    defaults: ['clients', 'filials'],
    filters: [
      {
        name: 'desvio',
        placeholder: 'Filtrar por Desvio',
        mode: 'single',
        data: async () => getDesviosTelemetria(),
      },
      {
        name: 'responsavel',
        placeholder: 'Filtrar por Responsável',
        mode: 'single',
        data: async () => getResponsaveis(),
      },
      {
        name: 'criticidade',
        placeholder: 'Filtrar por Criticidade',
        mode: 'multiple',
        data: async () => getCriticidade(),
      },
    ],
    page: <TelemetriaDesvios />,
  },

  '/telemetria/desvios/:id': {
    title: 'Desvio',
    id: true,
    status: true,
    back: '/telemetria/desvios',
    defaults: [],
    page: <DetalheTelemetriaDesvios />,
  },

  '/logs/telemetria/desvios/:id': {
    back: -1,
    title: 'Histórico do desvio',
    id: true,
    defaults: [],
    page: <LogTelemetriaDesvio />,
  },

  '/telemetria/ranking': {
    date: true,
    defaults: ['clients', 'filials', 'operations'],
    pageFilterName: 'filterTelemetriaRanking',
    filters: [
      {
        name: 'desvio',
        placeholder: 'Filtrar por Desvio',
        mode: 'single',
        section: 'Desvio, Veículo e Motorista',
        data: async () => getDesviosTelemetria(),
      },
      {
        name: 'veiculo',
        placeholder: 'Filtrar por Tipo de Veículo',
        mode: 'single',
        section: 'Desvio, Veículo e Motorista',
        data: async () => getVeiculos(),
      },
      {
        name: 'motoristas',
        placeholder: 'Filtrar por Tipo de Motorista',
        mode: 'single',
        section: 'Desvio, Veículo e Motorista',
        data: async () => getMotoristas(),
      },
      {
        name: 'pista_molhada',
        placeholder: 'Filtrar por Condições da Pista',
        mode: 'single',
        section: 'Condições',
        data: async () => getCondicoes(),
      },
      {
        name: 'carregado',
        placeholder: 'Filtrar por Carregamento',
        mode: 'single',
        section: 'Condições',
        data: async () => getCarregamentos(),
      },
    ],
    page: <TelemetriaRanking />,
  },

  '/telemetria/estatisticas': {
    date: true,
    defaults: [],
    page: <TelemetriaEstatisticas />,
  },

  '/telemetria/estatisticas/desempenho-geral': {
    date: true,
    defaults: [],
    page: <TelemetriaEstatisticas />,
  },

  '/telemetria/estatisticas/areas-de-risco': {
    date: true,
    defaults: [],
    page: <TelemetriaEstatisticas />,
  },

  '/telemetria/estatisticas/fatores-de-risco': {
    date: true,
    defaults: [],
    page: <TelemetriaEstatisticas />,
  },

  '/telemetria/estatisticas/tratativa-de-desvios': {
    date: true,
    defaults: [],
    page: <TelemetriaEstatisticas />,
  },
};
