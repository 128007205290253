import styled, { css } from 'styled-components';
import { Collapse } from '@mui/material';

const ContainerModifier = {
  hover: ({ theme }) => css`
    border: 2px solid ${theme.palette.secondary.main};
    cursor: pointer;
    opacity: 0.8;
  `,
};

const ContentModifier = {
  open: ({ theme }) => css`
    color: ${theme.palette.secondary.main};
  `,
};

export const Container = styled.div`
  ${({ theme, open }) => css`
    width: 129px;
    height: 40px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid ${theme.palette.system.border};
    background-color: ${theme.palette.system.highlight};
    color: ${theme.palette.words.text.light};
    transition: all 0.2s ease-in-out;
    gap: 5px;

    p {
      font-size: 15px;
      font-weight: 500;
      ${open && ContentModifier.open({ theme })}
    }

    .icon--filter {
      ${open && ContentModifier.open({ theme })}
    }

    &:hover {
      ${ContainerModifier.hover({ theme })}
    }

    ${open && ContainerModifier.hover({ theme })}
  `}
`;

export const Badge = styled.div`
  ${({ theme, visible }) => css`
    width: 18px;
    height: 18px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    background-color: ${theme.palette.semantics.feedback.attention.natural};
    color: ${theme.palette.words.text.contrast};
    opacity: ${visible ? 1 : 0};
    transition: all 0.25s ease-in-out;
  `}
`;

export const Filters = styled.div`
  ${({ theme }) => css`
    width: 414px;
  `}
`;

export const Header = styled.div`
  ${({ theme }) => css`
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  `}
`;

export const Title = styled.p`
  ${({ theme }) => css`
    font-weight: 900;
    color: ${theme.palette.primary.main};
    font-size: 20px;
  `}
`;

export const Clean = styled.p`
  ${({ theme }) => css`
    text-decoration: underline;
    color: ${theme.palette.words.subtitle.natural};
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  `}
`;

export const Body = styled.div`
  padding: 0px 15px;
  padding-bottom: 15px;
`;

export const Line = styled.div`
  ${({ theme, open }) => css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 40px;
    gap: 5px;
    cursor: pointer;
    transition: all 0.15s ease-in-out;

    &:hover {
      opacity: 0.9;
      background-color: ${theme.palette.system.border};
    }

    .arrow {
      transition: all 0.2s ease-in-out;
      transform: ${open ? 'rotate(0deg)' : 'rotate(-90deg)'};
    }
  `}
`;

export const Collapsed = styled(Collapse)`
  margin-top: 10px;
`;

export const Label = styled.p`
  ${({ theme }) => css`
    font-weight: 700;
    color: ${theme.palette.words.text.natural};
  `}
`;

export const LineBadge = styled.div`
  ${({ theme, hasItem }) => css`
    width: 16px;
    height: 16px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    background-color: ${theme.palette.semantics.feedback.attention.natural};
    color: ${theme.palette.words.text.contrast};
    opacity: ${hasItem ? 1 : 0};
    transition: all 0.25s ease-in-out;
  `}
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
`;
