// React
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

// Components
import Loading from 'components/Loading';
import Select from 'components/Inputs/Select';
import TextInput from 'components/Inputs/TextField';
import DropZoneArchives from 'components/Inputs/DropZoneCustom';

// Utils
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { steps } from '../constants';
import * as services from '../services';

// CKEditor
import * as S from './styled';

export const InformacoesGerais = ({
  trySave,
  setTrySave,
  setChanged,
  setVagaId,
  vagaId,
  setCurrentStep,
  data,
  setData,
}) => {
  const [loading, setLoading] = useState(false);

  // Get Reponsibles
  const selects = useSelector(state => {
    return state.selects;
  });
  const allUsers =
    selects.users?.map(i => {
      return { value: i.id, name: i.nome };
    }) || [];

  // Image Controller
  const addImage = item => {
    changingMonitor('imagem_vaga', item[0].imagem_url);
  };

  const deleteImage = () => {
    changingMonitor('imagem_vaga', '');
  };

  const changingMonitor = (key, value) => {
    const newData = { ...data };
    newData[key] = value;
    setData(newData);
    setChanged(true);
  };

  // Monitoring when need save
  useEffect(() => {
    if (trySave) {
      if (!data.titulo) {
        toast.error('Preencha um título para a vaga');
        setTrySave(false);
        return;
      }

      if (!data.id_usuario) {
        toast.error('Selecione um responsável para a vaga');
        setTrySave(false);
        return;
      }

      if (!data.descricao) {
        toast.error('Preencha uma descrição para a vaga');
        setTrySave(false);
        return;
      }

      const dataSend = {
        secao: 'Gerais',
        capa: data.imagem_vaga,
        titulo: data.titulo,
        descricao: data.descricao,
        responsavel: data.id_usuario,
      };

      saveInfos(dataSend);
    }
  }, [trySave]);

  const saveInfos = async dataSend => {
    setLoading(true);
    let res = null;
    if (vagaId) {
      res = await services.updateVacancy(dataSend, vagaId);
    } else {
      res = await services.createNewVacancy(dataSend);
    }
    if (res.success) {
      toast.success(res.message);
      setVagaId(res.id);
      setTrySave(false);
      setChanged(false);
      setCurrentStep(steps[1]);
    } else {
      setTrySave(false);
      toast.error(res.message);
    }

    setLoading(false);
  };

  // -------------------------------------RENDER-----------------------------------------//
  return (
    <>
      {!loading && (
        <S.Box>
          <div className="container">
            <div className="item">
              <TextInput
                required
                label="Título"
                placeholder="Insira um título"
                value={data.titulo}
                onChange={e => changingMonitor('titulo', e.target.value)}
              />
            </div>
            <div className="item">
              <Select
                value={data.id_usuario || ''}
                data={allUsers}
                handleChange={value =>
                  changingMonitor('id_usuario', value.target.value)
                }
                label="Responsável"
                placeholder="Selecione um responsável"
                required
              />
            </div>
          </div>

          <div className="editor">
            <p className="title">
              Descrição<span className="required">*</span>
            </p>
            <CKEditor
              editor={ClassicEditor}
              config={{
                language: { ui: 'pt', content: 'pt' },
                toolbar: [
                  'heading',
                  '|',
                  'bold',
                  'italic',
                  'link',
                  'bulletedList',
                  'numberedList',
                  '|',
                  'blockQuote',
                  'undo',
                  'redo',
                ],
                maxLength: '20',
              }}
              data={data.descricao}
              height="25em"
              onChange={(e, editor) =>
                changingMonitor('descricao', editor.getData())
              }
            />
          </div>
          <div className="drop">
            <DropZoneArchives
              data={
                data.imagem_vaga
                  ? { imagem_url: data.imagem_vaga, legenda: '' }
                  : null
              }
              multiple={false}
              fileDir="vagas/capas"
              addFunction={addImage}
              deleteFunction={deleteImage}
              automaticSave
              label="Adicionar Capa"
              noTopButton
            />
          </div>
        </S.Box>
      )}
      {loading && (
        <S.LoadingBox>
          <Loading />
        </S.LoadingBox>
      )}
    </>
  );
};
