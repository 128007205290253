import React, { useState } from 'react';

// Components
import { Divider } from '@mui/material';
import Tabs from 'components/Tabs';
import { Acidentes } from './Acidentes';
import { Multas } from './Multas';

export const tabs = [
  { value: 'acidentes', label: 'Acidentes' },
  { value: 'multas', label: 'Multas' },
];

export const AcidentesMultas = () => {
  const [tab, setTab] = useState('acidentes');

  return (
    <>
      <Tabs value={tab} items={tabs} onChange={(_, value) => setTab(value)} />

      <Divider />

      <br />

      {tab === 'acidentes' && <Acidentes />}
      {tab === 'multas' && <Multas />}
    </>
  );
};
