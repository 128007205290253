export const levels = [
  {
    value: 1,
    text: 'Administrador',
    description:
      'Acesso total a todas as funcionalidades e todas as filiais. Pode editar configurações da empresa. Pode adicionar ou remover configurações dos fluxos de trabalho.  ',
  },
  {
    value: 2,
    text: 'Gerente',
    description:
      'Pode acessar as informações da sua filial. Pode adicionar ou remover configurações dos fluxos de trabalho.',
  },
  {
    value: 3,
    text: 'Membro',
    description:
      'Visualização da sua filial e das atividades direcionados a eles. Não pode editar, inserir ou remover configurações pré-definidas',
  },
];

export const sortBy = [
  {
    id: 'data',
    desc: true,
  },
];

export const tabs = [
  {
    value: 0,
    label: 'Ativos',
  },
  {
    value: 1,
    label: 'Inativos',
  },
];
