import styled, { css } from 'styled-components';

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  margin-bottom: 20px;
`;

export const Wrapper = styled.div`
  ${({ theme }) => css`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background-color: ${theme.palette.system.overlay};
    box-shadow: 0px -5px 15px ${theme.palette.brand.primary.light}1A;
    z-index: 900;
    //align-content: center;
  `}
`;

export const Paragraph = styled.p`
  ${({ theme, ...props }) => css`
    font-size: ${props.fontsize ? props.fontsize : '16px'};
    color: ${props.color ? props.color : theme.palette.brand.primary.natural};
    font-weight: ${props.fontweight ? props.fontweight : 400};
    min-width: ${props.minwidth};
    margin-top: 10px;
  `}
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const ColDriver = styled.div`
  ${({ theme, pontuacao }) => css`
    display: flex;
    flex-direction: column;
    text-transform: capitalize;
    ${pontuacao &&
    `
    h1{
      color: ${theme.palette.semantics.feedback.warning.natural} !important;
    }
  `}

    h1 {
      font-size: 14px;
      font-weight: 600;
      color: ${theme.palette.brand.primary.natural};
    }
  `}
`;

export const Main = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    label {
      display: flex;
      align-items: center;

      input {
        display: none;
      }

      .imgArea {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 2px solid ${theme.palette.brand.secondary.natural};
        padding: 3px;
        display: flex;
        align-items: center;
        justify-content: center;

        .borderImage {
          width: 100%;
          height: 100%;

          img {
            width: 100%;
            height: 100%;
            border-radius: 100px;
          }
        }

        .empty {
          background-color: ${theme.palette.system.border};
          width: 100%;
          height: 100%;
          border-radius: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 900;
          color: ${theme.palette.words.title.natural};
        }
      }

      .textArea {
        display: flex;
        align-items: center;
        color: ${theme.palette.words.subtitle.natural};
        margin-left: 10px;

        span {
          margin-left: 5px;
        }
      }
    }
  `}
`;

export const Pontos = styled.div`
  ${({ theme, position, total }) => css`
    color: ${theme.palette.semantics.feedback.attention.natural};
    padding: 5px 0px;
    font-weight: 600;
    background-color: ${theme.palette.semantics.feedback.attention.light};
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    font-size: 12px;
    ${position > 10 &&
    `
      color: ${theme.palette.semantics.feedback.success.natural};
      background-color: ${theme.palette.semantics.feedback.success.light};
    `}
    ${position < Math.abs(total - 12) &&
    `
      color: ${theme.palette.semantics.feedback.attention.dark};
      background-color: ${theme.palette.semantics.feedback.attention.light};
    `}
  `}
`;
