import styled from 'styled-components';

const SectionFilter = styled.div`
  height: 40px;
  width: max-content;
  padding: 10px 0px;
  margin: 5px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;

  h3 {
    font: normal normal 600 16px/26px Texta;
    color: ${({ theme }) => theme.palette.words.caption.natural};
  }

  .MuiSvgIcon-root {
    margin-right: 5px;
    transform: ${props => (props.expanded ? 'rotate(90deg)' : '')};
    transition: transform 0.2s;
  }
`;

export { SectionFilter };
