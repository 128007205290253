import styled, { css } from 'styled-components';
import { Button } from '@mui/material';

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const Label = styled.p`
  color: ${({ theme }) => theme.palette.words.subtitle.natural};
  height: 28px;
  font: normal medium 14px/20px Texta;
  margin-bottom: 5px;
  span {
    font-size: 20px;
    font-weight: 500;
    color: red;
  }
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
`;

const StyledButton = styled(Button)`
  ${({ theme, disabled, ...props }) => css`
    && {
      color: ${props.textcolor};
      background: ${props.backgroundcolor};
      border: 2px solid
        ${disabled
          ? props.backgroundcolor
          : props.haserror
          ? theme.palette.semantics.feedback.attention.natural
          : theme.palette.brand.primary.light};
      font: normal normal 400 16px/24px Texta;
      height: 53px;
      width: 100%;
      padding: 5px 15px;
      transition: opacity 300ms;
      justify-content: flex-start;

      :hover {
        background: ${props.backgroundcolor};
        border: 2px solid
          ${props.haserror
            ? theme.palette.semantics.feedback.attention.natural
            : theme.palette.brand.primary.dark};
        opacity: 0.7;
      }
    }
  `}
`;

export { InputWrapper, Label, Main, StyledButton };
