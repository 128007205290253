export const columnsTotal = [
  {
    Header: 'Nome',
    id: 'aluno',
    identifier: 'nome',
    accessor: d => String(d.aluno.nome),
    sortType: 'basic',
    show: true,
    disableSortBy: true,
  },
  {
    Header: 'Status',
    id: 'concluido',
    identifier: 'status',
    accessor: d => Number(d.concluido),
    sortType: 'basic',
    show: true,
    disableSortBy: false,
    centered: true,
  },

  {
    Header: 'Ocorrências',
    id: 'ocorrencias',
    identifier: 'ocorrencias',
    accessor: d => Number(d.ocorrencias.total),
    sortType: 'basic',
    show: true,
    disableSortBy: false,
    centered: true,
  },

  {
    Header: 'Aprovado',
    id: 'data_assinatura',
    identifier: 'aprovado',
    accessor: d => Date(d.data_assinatura),
    sortType: 'basic',
    show: true,
    disableSortBy: false,
    centered: true,
  },
  {
    Header: 'Ações',
    id: 'acoes',
    identifier: 'acoes',
    accessor: d => null,
    sortType: 'basic',
    show: true,
    disableSortBy: true,
  },
];
