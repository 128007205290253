import LoadingCp from 'components/Loading';
import * as S from './styled';

const Loading = () => {
  return (
    <S.Box>
      <LoadingCp />
    </S.Box>
  );
};

export default Loading;
