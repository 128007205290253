// Pages
import LoginPage from 'pages/Login';
import RecuperarSenha from 'pages/RecuperarSenha';

export default {
  '/login': {
    page: <LoginPage />,
  },

  '/login-na-estrada': {
    page: <LoginPage />,
  },

  '/recuperar-acesso': {
    page: <RecuperarSenha />,
  },
};
