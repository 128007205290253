import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;
`;

export const Title = styled.span`
  font-weight: 900;
  font-size: 2.5rem;
  font-style: Text;
  text-transform: capitalize;
  color: #4b5166;
`;

export const Avatar = styled.div`
  position: relative;
  width: fit-content;
  margin: auto;
`;

export const Circle = styled.div`
  width: 8rem;
  height: 8rem;
  border-radius: 4rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #95aac9;
  box-shadow: 0px 3px 6px #00000029;
  font-family: Texta;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  span {
    font-size: 4rem;
    font-weight: bold;
    color: white;
    text-transform: uppercase;
  }
`;

export const Content = styled.div`
  width: 100%;
`;

export const StatusCircle = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: ${({ success }) => (success ? '#1BC5BD' : '#F64E60')};
`;

export const Flex = styled.div`
  display: flex;
  color: #4b5166;

  &.full-width {
    width: 100%;
  }
  &.mt-05 {
    margin-top: 0.5rem;
  }
  &.gap-1 {
    gap: 1rem;
  }
  &.gap-05 {
    gap: 0.25rem;
  }
  &.align-center {
    align-items: center;
  }
  &.justify-between {
    justify-content: space-between;
  }
  .bold {
    font-weight: bold;
  }
`;
