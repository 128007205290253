import colors from 'styles/colors';
import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fff;
  height: 170px;
  flex: 1;
  border-radius: 5px;
  margin: 15px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: all ease 0.3s;
  border: 2px solid ${props => (props.counter ? colors.greenMain : colors.grey)};

  &:hover {
    background-color: rgba(245, 245, 245, 10);
    transform: scale(1.05);
  }

  &:hover .footer {
    opacity: 1;
  }

  .header {
    display: flex;
    width: 100%;
    height: 20vh;
    align-items: center;
    justify-content: flex-end;
  }

  .typeCard {
    color: ${props => (props.counter ? colors.greenMain : colors.grey)};
    font-weight: 600;
  }

  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 60vh;
    align-items: center;
    justify-content: center;
  }

  .footer {
    display: flex;
    height: 20vh;
    width: 100%;
    padding: 5px;
    align-items: center;
    justify-content: flex-end;
    opacity: 0;
  }
`;

export const Counter = styled.div`
  display: flex;
  width: 22px;
  height: 22px;
  background: ${props => (props.counter > 0 ? colors.greenMain : colors.grey)};
  margin: 8px;
  border-radius: 100px;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
`;
