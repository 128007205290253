import * as types from './types';

export function setFilter(query) {
  return {
    type: types.SET_FILTER_CONTEUDO,
    payload: { query },
  };
}
// export function setReportsFilters(reportsFilters) {
//   return {
//     type: types.REPORTS_FILTERS,
//     payload: { reportsFilters },
//   };
// }
