// Styled

// React
import { useEffect, useState } from 'react';

// Components
import Radio from 'pages/Fomularios/Fields/Components/Radio';
import DropZoneArchives from 'components/Inputs/DropZoneCustom';

// Components MUI
import { Divider } from '@mui/material';
import { WithQuestion } from '..';
import * as S from './styled';

// Actions
import { changeCheck, addImage, editImage, deleteImage } from '../../actions';

export const ResponseEscolhaUnica = ({
  field,
  secoes,
  setSecoes,
  sectionId,
  errosEvidencias,
  errosObrigatorios,
  preview,
  previewWeb,
  approveView,
}) => {
  const [cleanError, setCleanError] = useState([]);

  const redirectFunction = (executable, idResposta, idCampo, item) => {
    if (errosEvidencias) setCleanError([...cleanError, idResposta]);
    executable(idResposta, idCampo, item, setSecoes, secoes, sectionId);
  };

  const [hasErrorObrigatorio, setHasErrorObrigatorio] = useState(false);
  const [hasErrorEvidencia, setHasErrorEvidencia] = useState(false);
  const [wrongOptions, setWrongOptions] = useState([]);

  useEffect(() => {
    // Reset fields
    setHasErrorObrigatorio(false);
    setHasErrorEvidencia(false);
    setWrongOptions([]);
    setCleanError([]);
    if (approveView) return null;

    if (errosObrigatorios) {
      for (const i in errosObrigatorios) {
        const erros = errosObrigatorios[i];
        if (sectionId === erros.idSecao && field.id === erros.idCampo) {
          setHasErrorObrigatorio(true);
        }
      }
    }

    if (errosEvidencias) {
      const wrongs = [];
      let hasError = false;
      for (const i in errosEvidencias) {
        const erros = errosEvidencias[i];
        if (sectionId === erros.idSecao && field.id === erros.idCampo) {
          const { opcoes } = field;
          for (const j in opcoes) {
            if (opcoes[j].id === erros.idResposta) {
              wrongs.push(erros.idResposta);
              hasError = true;
            }
          }
        }
      }
      setWrongOptions(wrongs);
      setHasErrorEvidencia(hasError);
    }
  }, [errosEvidencias, errosObrigatorios]);

  return (
    <>
      <S.QuestionBox
        hasError={hasErrorObrigatorio && !cleanError.length}
        className={`fieldClass-${field.id}`}
        preview={preview}
      >
        <WithQuestion
          field={field}
          secoes={secoes}
          setSecoes={setSecoes}
          sectionId={sectionId}
          hasError={hasErrorObrigatorio && !cleanError.length}
          preview={preview}
          approveView={approveView}
        />
        {field.opcoes.map((opcao, key) => (
          <S.ResponseArea isChecked={opcao.checked} key={key} preview={preview}>
            <span className="checkarea">
              <Radio
                value={opcao.checked}
                label={opcao.item}
                onChange={() =>
                  redirectFunction(changeCheck, opcao.id, field.id, 'unic')
                }
                error={hasErrorObrigatorio && !cleanError.length}
                preview={preview}
                disabled={approveView || preview || previewWeb}
              />
            </span>
            {opcao.evidencia && (
              <div className="iconsBox">
                <DropZoneArchives
                  data={opcao.arquivos}
                  multiple={false}
                  fileDir="formularios/evidencias"
                  addFunction={item =>
                    redirectFunction(addImage, opcao.id, field.id, item)
                  }
                  editFunction={item =>
                    redirectFunction(editImage, opcao.id, field.id, item)
                  }
                  deleteFunction={item =>
                    redirectFunction(deleteImage, opcao.id, field.id, item)
                  }
                  saveNow
                  setSaveNow={() => {}}
                  minimal
                  minimalText={!approveView ? 'Adicionar evidência' : null}
                  minimalRequired
                  onlyRead={approveView}
                  haveLegend
                  newImagesByModal="Alterar Evidência"
                  error={
                    hasErrorEvidencia &&
                    wrongOptions.includes(opcao.id) &&
                    !cleanError.includes(opcao.id)
                  }
                  tooltip={approveView}
                />
              </div>
            )}
          </S.ResponseArea>
        ))}
      </S.QuestionBox>
      <Divider />
    </>
  );
};
