/* eslint-disable import/order */

// Styles
import * as S from './styled';
import { Box, Title } from '../styled';

// React
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

// Components
import Select from 'components/Inputs/Select';
import Input from 'components/Inputs/TextField';
import DataHour from 'components/Inputs/DataHour';
import CircularProgress from '@mui/material/CircularProgress';

// Actions
import { verifyErrors } from './actions';

// Services
import * as services from '../services';
import { validarCNPJ } from 'utils/masks';

// Constants
import { fields, booleans, tancagem, clients } from './constants';
import { setDate } from 'date-fns';

export const Gerais = ({
  setChanged,
  trySave,
  setTrySave,
  setCurrentStep,
  posto,
  tamanhos,
  cliente,
  bases,
  setPosto,
  steps,
  setLoading,
  id,
  setId,
  isEdit,
}) => {
  const { user } = useSelector(state => state.auth.user);
  const executor = posto?.executor || user;

  const [loadingField, setLoadingField] = useState(false);
  const [errors, setErrors] = useState([]);
  const [data, setData] = useState(() => {
    const obj = {
      janela_horario: posto?.janela_horario,
      endereco: posto?.endereco,
      estado: posto?.estado,
    };
    fields[cliente]?.forEach(item => {
      obj[item.name] =
        posto?.[item.name] || fields[cliente]?.[item.name]?.default;
    });
    return obj;
  });

  useEffect(() => {
    setChanged(verifyErrors(compose, data));
  }, []);

  // ---------------------COMPOSE DATA---------------------//

  const dynamicData = {
    bases,
    tamanhos,
    booleans,
    clients,
    tancagem,
  };

  const compose = fields[cliente]?.map(item => ({
    ...item,
    data: dynamicData[item.dynamic],
  }));

  // -----------------------ACTIONS------------------------//
  const handleInput = (name, value, label) => {
    setData({ ...data, [name]: value });
    setChanged(true);
  };

  const handleBlur = async (name, value, label) => {
    const clearErrors = () => {
      setErrors(errors => {
        if (errors?.includes(name))
          return [...errors].filter(err => err !== name);
        return [...errors];
      });
    };

    if (label === 'CNPJ') {
      if (validarCNPJ(value)) {
        const cleanCnpj = value.replace(/\D/g, '');
        setLoadingField(name);
        const res = await services.checkCNPJ(cleanCnpj);
        setLoadingField(null);
        const atributes = ['nome', 'endereco', 'estado'];

        if (res?.razao_social) {
          const { logradouro, bairro, cep, estado } = res.estabelecimento;
          setData({
            ...data,
            [atributes[0]]: res.razao_social,
            [atributes[1]]: `${logradouro}, ${bairro}, ${cep}, ${
              estado.sigla || ''
            }`,
            [atributes[2]]: estado.sigla,
          });
        } else {
          toast.error('Falha ao consultar CNPJ.');
        }
        clearErrors();
      } else {
        setErrors(errors => {
          if (!errors?.includes(name)) return [...errors, name];
          return [...errors];
        });
        toast.error('CNPJ inválido.');
      }
    } else if (value) {
      clearErrors();
    }
  };

  // -----------------------SAVE------------------------//
  useEffect(() => {
    if (trySave) {
      const hasErrors = verifyErrors(compose, data, setErrors);
      if (hasErrors) {
        toast.error('Preencha todos os campos obrigatórios');
        setTrySave(false);
      } else {
        save();
      }
    }
  }, [trySave]);

  const save = async () => {
    setLoading(true);
    let res = null;
    if (id) {
      res = await services.saveUpdate({ ...posto, ...data, etapa: 1 }, id);
    } else {
      res = await services.saveInfos({
        etapa: 1,
        ...data,
        id_da_distribuidora: cliente,
      });
    }

    setTrySave(false);
    setLoading(false);
    if (res.success) {
      setChanged(false);
      toast.success(res?.message);
      setPosto(res?.posto);
      setId(res?.posto?.id);
      !isEdit && setCurrentStep(steps[1]);
    } else {
      toast.error(res.message);
    }
  };

  // -----------------------RENDER------------------------//
  return (
    <S.Container>
      <Title>Informações do Executor</Title>
      <Box>
        <S.Top>
          <div className="image">
            <div className="circle">
              {executor?.foto && <img src={executor?.foto} alt="avatar" />}
              {!executor?.foto && <p className="letter">{executor?.nome[0]}</p>}
            </div>
          </div>
          <div className="infos">
            <div className="name">{executor?.nome}</div>
            <div className="company">Filial: {executor?.filial?.nome}</div>
          </div>
        </S.Top>
        <S.Bottom>
          <Input value={executor?.funcao} label="Função" disabled />
        </S.Bottom>
      </Box>

      <Title>Informações do Cliente</Title>
      <Box>
        <S.Grid>
          {compose.map((field, idx) => (
            <S.Item key={idx}>
              {field.type === 'select' && (
                <Select
                  required={field.required}
                  data={field?.data || []}
                  label={field.label}
                  value={data?.[field.name]}
                  handleChange={e => handleInput(field.name, e.target.value)}
                  error={errors?.includes(field.name) && !data?.[field.name]}
                />
              )}
              {field.type === 'input' && (
                <Input
                  label={field.label}
                  required={field.required}
                  value={data?.[field.name]}
                  disabled={loadingField && loadingField === field.name}
                  onChange={e => {
                    handleInput(
                      field.name,
                      field.mask ? field.mask(e.target.value) : e.target.value,
                      field.label,
                    );
                  }}
                  onBlur={e => {
                    handleBlur(
                      field.name,
                      field.mask ? field.mask(e.target.value) : e.target.value,
                      field.label,
                    );
                  }}
                  error={errors?.includes(field.name)}
                  endIcon={
                    loadingField &&
                    loadingField === field.name && (
                      <CircularProgress size={16} />
                    )
                  }
                />
              )}
              {field.type === 'date' && (
                <DataHour
                  label={field.label}
                  required={field.required}
                  disabled={field.disabled}
                  error={errors?.includes(field.name) && !data?.[field.name]}
                />
              )}
            </S.Item>
          ))}
        </S.Grid>
      </Box>

      <Title>Endereço e Horário</Title>
      <Box>
        <Input
          label="Janela de Recebimento"
          value={data?.janela_horario}
          onChange={e => handleInput('janela_horario', e.target.value)}
        />
        <Input
          label="Endereço"
          value={data?.endereco}
          onChange={e => handleInput('endereco', e.target.value)}
        />
        {cliente === 4 && (
          <Input
            label="Estado"
            value={data?.estado}
            onChange={e => handleInput('estado', e.target.value)}
          />
        )}
      </Box>
    </S.Container>
  );
};
