import styled, { css } from 'styled-components';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

export const StyledCard = styled(Card)`
  ${({ theme, ...props }) => css`
    width: 100%;
    margin: 15px;
    height: 84px;
    display: flex;
    padding: 0 15px;
    flex-direction: row;
    transition: all ease 0.3s;
    opacity: ${props.disabled ? '0.7' : '1'};
    cursor: ${props.ck};
    border: ${props.border};
    h1 {
      color: ${theme.palette.words.title.natural};
      font-size: ${props => (props.smalltype ? '14px' : '22px')};
      /* font-size: 22px; */
      font-weight: 900;
    }
    p {
      color: ${theme.palette.words.text.natural};
      font: normal normal medium 16px/16px Texta;
      font-size: ${props => (props.smalltype ? '14px' : '16px')};
    }

    @media (max-width: 1257px) {
      p {
        /* font-size: 12px; */
        font-size: ${props => (props.smalltype ? '12px' : '16px')};
        font-weight: ${props => (props.smalltype ? '600' : '500')};
      }
    }
  `}
`;

export const StyledGrid = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: center;
  h1 {
    text-align: center;
  }
  p {
    text-align: left;
    color: #4b5166;
  }

  @media (max-width: 1137px) {
    img {
      width: 30px;
      height: 30px;
    }
  }
`;

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${theme.palette.system.overlay};
    border-radius: 5px;

    .card {
      width: 100%;
      height: 100%;
      background-color: ${theme.palette.system.overlay};
      transform: scale(0.5);
    }
  `}
`;
