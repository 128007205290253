// React
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';

// Components
import Loading from 'components/Loading';
import ImageModal from 'components/ImageModal';
import GhostButton from 'components/Buttons/Ghost';
import TextInput from 'components/Inputs/TextField';
import ImageDesvio from 'pages/Desvio/ImageDesvio';
import InputAttachedFile from 'components/Inputs/InputAttachedFile2';
import { ReactComponent as HistoricoIcon } from 'images/icons/sidebar/historico.svg';

// Material UI
import { Divider, Grid } from '@mui/material';
import NoPhotographyOutlinedIcon from '@mui/icons-material/NoPhotographyOutlined';
import {
  RouteOutlined,
  CalendarTodayOutlined,
  WarningAmber,
} from '@mui/icons-material';

// Redux
import { changeHeaderStatus } from 'store/modules/header/actions';

// Utils
import { useTheme } from 'styled-components';
import { formatNewDate, formatNewHour, formatNewHourClean } from 'utils/dates';
import DefaultButton from 'components/Buttons/Default';
import { getStatusColor, howToColor } from '../constants';

// Services
import * as request from '../services';
import * as S from './styled';

const InfractionDetail = () => {
  const theme = useTheme();
  // Parametro da página desvio/:id
  const { id } = useParams();
  const navigate = useNavigate();

  // Dados do redux
  const dispatch = useDispatch();
  const { clients } = useSelector(state => {
    return state.selects;
  });

  // Desvio que pode ser manipulado e desvio original (que é lido)
  const [infraction, setInfraction] = useState(null);
  const [originalInfraction, setOriginalInfraction] = useState(null);
  const [expandedImage, setExpandedImage] = useState(false);

  const componentRef = useRef();

  const fetchInfraction = async () => {
    const res = await request.fetchInfraction(id);
    if (res.data?.data) {
      const desv = res.data.data;
      setInfraction(desv);
      setOriginalInfraction(desv);
    } else if (res.data?.message) toast.error(res.data.message);
  };

  useEffect(() => {
    fetchInfraction();
  }, []);

  const handleChange = (key, value) => {
    setInfraction(prev => {
      return { ...prev, [key]: value };
    });
  };

  // Set Status Color in Header
  useEffect(() => {
    if (infraction) {
      const color = getStatusColor(infraction.status, theme);
      dispatch(
        changeHeaderStatus({
          status: infraction.status,
          ...color,
        }),
      );
    }
  }, [infraction]);

  //  ------------------   RENDERIZACAO --------------------------------
  const renderHeader = () => {
    return (
      <S.SpacedRow>
        <S.Status color={getStatusColor(infraction.status, theme)}>
          <h1>Detalhes do Desvio</h1>
        </S.Status>

        <S.SpacedRow>
          <GhostButton
            startIcon={<HistoricoIcon />}
            size="medium"
            onClick={() => navigate(`/logs/desvio-descarga/${id}`)}
            sx={{ marginLeft: '20px' }}
          >
            HISTÓRICO DE ALTERAÇÕES
          </GhostButton>
        </S.SpacedRow>
      </S.SpacedRow>
    );
  };

  // Lado Esquerdo do detalhe do desvio
  const renderTravelInfo = () => {
    const hasImage = infraction.motorista.foto
      ? infraction.motorista.foto
      : false;
    return (
      <S.ColumnWrapper>
        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={12} display="flex" justifyContent="center">
            {infraction?.motorista.assinatura_adesao && (
              <ImageDesvio infraction={infraction} onlyImage={false} />
            )}
            {!infraction?.motorista.assinatura_adesao && (
              <S.NotForbiden>
                <NoPhotographyOutlinedIcon
                  className="icon"
                  htmlColor={theme.palette.brand.primary.light}
                />
                <span>
                  Exibição de imagem e vídeo não autorizados pelo motorista
                </span>
              </S.NotForbiden>
            )}
          </Grid>

          <Grid item xs={12} sm={12} display="flex" flexDirection="row" mt={1}>
            <S.StyledLogoWrapper>
              <S.StyledLogo backgroundImage={null}>
                <h2>{infraction.motorista.filial?.nome[0]}</h2>
              </S.StyledLogo>
            </S.StyledLogoWrapper>

            <S.StyledTextWrapper>
              <h1>{`Filial: ${infraction.motorista.filial?.nome.toLowerCase()}`}</h1>
            </S.StyledTextWrapper>
          </Grid>

          <Divider style={{ margin: '10px 0px 10px 10px', width: '100%' }} />

          <Grid item xs={8} sm={8}>
            <TextInput
              id="Desvio"
              label="Desvio"
              value={infraction.tipo.descricao}
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={4} sm={4}>
            <TextInput
              id="criticidade"
              label="Criticidade"
              value={infraction.tipo.criticidade}
              InputProps={{ readOnly: true }}
              variant="filled"
              startIcon={
                <WarningAmber
                  sx={{ color: howToColor(infraction.tipo.criticidade, theme) }}
                />
              }
              fillcolor={howToColor(infraction.tipo.criticidade, theme)}
            />
          </Grid>

          {infraction.avaliacao.local && (
            <Grid item xs={12} sm={12}>
              <TextInput
                id="trejeto"
                label="Trajeto"
                value={infraction.avaliacao.local}
                inputProps={{ readOnly: true }}
                startIcon={
                  <RouteOutlined
                    sx={{
                      rotate: '90deg',
                      color: theme.palette.brand.primary.light,
                    }}
                  />
                }
                variant="filled"
              />
            </Grid>
          )}

          <Grid item xs={6} sm={6}>
            <TextInput
              id="reportadoem"
              label="Reportado em"
              value={formatNewDate(infraction.createdAt)}
              inputProps={{ readOnly: true }}
              startIcon={
                <CalendarTodayOutlined
                  sx={{ color: theme.palette.brand.primary.light }}
                />
              }
              variant="filled"
            />
          </Grid>

          <Grid item xs={6} sm={6}>
            <TextInput
              id="datadesvio"
              label="Data do Desvio"
              value={formatNewHourClean(infraction.data_desvio, true)}
              startIcon={
                <CalendarTodayOutlined
                  sx={{ color: theme.palette.brand.primary.light }}
                />
              }
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>

          {infraction.status === 'DELETADO' && (
            <Grid item xs={12} sm={12}>
              <TextInput
                id="motivoexclusao"
                label="Motivo da exclusão"
                value={infraction.motivo_exclusao}
                inputProps={{ readOnly: true }}
                variant="filled"
              />
            </Grid>
          )}

          {infraction.status === 'DELETADO' &&
            infraction.observacao_exclusao && (
              <Grid item xs={12} sm={12}>
                <TextInput
                  id="observacaoexclusao"
                  label="Observação da exclusão"
                  value={infraction.observacao_exclusao}
                  inputProps={{ readOnly: true }}
                  variant="filled"
                />
              </Grid>
            )}
        </Grid>
      </S.ColumnWrapper>
    );
  };

  // Lado direito detalhe do desvio
  const renderInfractionInfo = () => {
    return (
      <S.ColumnWrapper>
        <Grid container columnSpacing={2} rowSpacing={1}>
          <Grid
            item
            xs={12}
            sm={12}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <div className="imageArea">
              {infraction.distribuidora.logo_url && (
                <img src={infraction.distribuidora.logo_url} />
              )}
              {!infraction.distribuidora.logo_url && (
                <span className="letters">
                  {infraction.distribuidora.nome[0]}
                </span>
              )}
            </div>
            <span className="distributorName">
              {infraction.distribuidora.nome}
            </span>
          </Grid>

          <Grid item xs={7} sm={7}>
            <TextInput
              id="empresa"
              label="Empresa"
              value={infraction?.motorista?.filial?.empresa?.nome}
              onChange={e => {}}
              placeholder="Empresa não encontrada"
              disabled
              props={{
                readOnly: true,
              }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={5} sm={5}>
            <TextInput
              id="placa"
              label="Placa"
              value={infraction?.caminhao?.placa}
              onChange={e => {}}
              placeholder=""
              disabled
              props={{
                readOnly: true,
              }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={7} sm={7}>
            <TextInput
              id="responsavel"
              label="Responsável"
              value={infraction.responsavel?.nome}
              onChange={e => {}}
              placeholder="Responsável não definido"
              disabled
              props={{
                readOnly: true,
              }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={5} sm={5}>
            <TextInput
              id="data_previsao"
              label="Previsão de Conclusão"
              value={formatNewDate(infraction.data_previsao)}
              onChange={() => {}}
              placeholder="Sem Previsão"
              disabled
              props={{
                readOnly: true,
              }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextInput
              id="plano_de_acao"
              placeholder="Plano de Ação Não Definido"
              label="Plano de Ação"
              onChange={() => {}}
              value={infraction.plano_de_acao}
              multiline
              rows={2}
              props={{
                readOnly: true,
              }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <InputAttachedFile
              idInput="input_file_detalhe_desviodescarga"
              required
              label="Arquivo Justificativa"
              inputLabel="Sem evidencias anexadas"
              fileUrl={infraction.arquivo_justificativa}
              setFileUrl={value => handleChange('arquivo_justificativa', value)}
              fileDir={`/desvios/${id}`}
              disabled
              // deleteFromStorage nao apagar arquivo antigo
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextInput
              label="Observações"
              placeholder="Sem observações"
              onChange={() => {}}
              multiline
              rows={4}
              value={infraction.observacao}
              props={{
                readOnly: true,
              }}
              variant="filled"
            />
          </Grid>
        </Grid>
      </S.ColumnWrapper>
    );
  };

  return infraction && originalInfraction ? (
    <>
      <S.Main ref={componentRef}>
        {renderHeader()}
        <Grid container spacing="20px" marginTop="10px">
          <Grid item xs={12} sm={12} md={6}>
            {renderTravelInfo()}
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            {renderInfractionInfo()}
          </Grid>
        </Grid>
      </S.Main>

      <ImageModal
        open={expandedImage}
        handleClose={() => setExpandedImage(false)}
        image={infraction.img_ocorrencia}
      />
    </>
  ) : (
    <Loading />
  );
};

export default InfractionDetail;
