export const fields = [
  {
    label: 'ID',
    value: 'id',
    selected: true,
    default: true,
  },
  {
    label: 'Cliente',
    value: 'distribuidora.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Empresa',
    value: 'empresa.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Filial',
    value: 'filial.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Origem',
    value: 'origem',
    selected: true,
    default: true,
  },
  {
    label: 'Destino',
    value: 'destino',
    selected: true,
    default: true,
  },
  {
    label: 'Responsável',
    value: 'usuario.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Arquivo',
    value: 'arquivo',
    selected: true,
    default: true,
  },
  {
    label: 'Criado em',
    value: 'createdAt',
    selected: true,
    default: true,
  },
  {
    label: 'Modificado em',
    value: 'updatedAt',
    selected: true,
    default: true,
  },
];

export const resetFields = fields => {
  return fields.map(i => {
    if (!i.default) i.selected = false;
    return i;
  });
};
