import api from 'services/api';
import qs from 'qs';
import { toast } from 'react-toastify';

export const fetchAllContents = async () => {
  const res = await api.get(`/training/aluno-contents`);
  return res.data;
};

export const fetchAllReproved = async () => {
  const res = await api.get(`/training/aluno-reproved`);
  return res.data;
};

export const fetchAllCertificates = async () => {
  const res = await api.get(`/training/aluno-certificates`);
  return res.data;
};

export const fetchAllReports = async () => {
  const res = await api.get(`/training/aluno-reports`);
  return res.data;
};

export const showContent = async (id, params) => {
  const res = await api.get(`/training/show-content/${id}}`, {
    params,
  });
  if (res.data.success) {
    return res.data;
  }
  toast.error(res.data.message);
  return [];
};

export const tryNewTest = async id => {
  const res = await api.post(`/training/content/new-try/${id}`);
  return res.data;
};

export const requestCertificate = async (id, query) => {
  const res = await api.get(
    `/training/request-acertificate/${id}?${qs.stringify(query)}`,
  );
  return res;
};
