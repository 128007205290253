import { formatNewDate } from 'utils/dates';
import { hasReview } from 'pages/Desvios/constants';
import { ReactComponent as Analise } from 'images/icons/tooltip/analise.svg';

export const columns = [
  {
    header: 'Id',
    id: 'id',
    type: 'number',
    sort: true,
    width: 100,
  },
  {
    header: 'Placa',
    id: 'placa',
    type: 'string',
    sort: true,
    tooltip: true,
  },
  {
    header: 'Desvio',
    id: 'titulo',
    type: 'string',
    sort: true,
    tooltip: true,
  },
  {
    header: 'Data Desvio',
    id: 'data_desvio',
    type: 'string',
    sort: true,
    value: (_, item) => formatNewDate(item?.data_desvio),
  },
  {
    header: 'Vencimento',
    id: 'data_vencimento',
    type: 'string',
    sort: true,
    value: (_, item) => formatNewDate(item?.data_vencimento),
  },
  {
    header: 'Responsável',
    id: 'responsavel_nome',
    type: 'string',
    sort: true,
    tooltip: true,
  },
  {
    header: 'Criticidade',
    id: 'criticidade',
    type: 'string',
    sort: true,
    width: 200,
    align: 'center',
    style: {
      textTransform: 'uppercase',
    },
    conditional: [
      {
        condition: value => true,
        style: theme => ({
          color: theme?.palette?.semantics?.feedback?.information?.natural,
          backgroundColor:
            theme?.palette?.semantics?.feedback?.information?.light,
          textAlign: 'center',
        }),
      },
      {
        condition: value => value === 'MODERADA',
        style: theme => ({
          color: theme?.palette?.semantics?.feedback?.warning?.natural,
          backgroundColor: theme?.palette?.semantics?.feedback?.warning?.light,
          textAlign: 'center',
        }),
      },
      {
        condition: value => value === 'GRAVÍSSIMA' || value === 'GRAVE',
        style: theme => ({
          color: theme?.palette?.semantics?.feedback?.attention?.natural,
          backgroundColor:
            theme?.palette?.semantics?.feedback?.attention?.light,
          textAlign: 'center',
        }),
      },
    ],
    attention: (_, item) => {
      if (item.em_analise)
        return {
          text:
            item.em_analise === 'ANALISE'
              ? 'Desvio em análise.'
              : 'Desvio com retorno de análise.',
          iconIn: <Analise />,
          iconOut: <Analise />,
        };
      const msg = hasReview(item);

      if (msg)
        return {
          text: msg,
        };
    },
  },
];
