import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { SubdirectoryArrowRight } from '@mui/icons-material';
import { Logs } from 'components/Logs';
import GhostButton from 'components/Buttons/Ghost';
import * as S from './styled';
import { requestHistory } from './services';

const Historico = ({ url = '', back = '', formatReturn }) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);

  const _url = url.includes(':id') ? url.replace(':id', id) : url;
  const _back =
    typeof back === 'string' && back.includes(':id')
      ? back.replace(':id', id)
      : back;

  useEffect(() => {
    const getLogs = async () => {
      const res = await requestHistory(_url);
      if (res.data?.data)
        setLogs(() => {
          if (formatReturn instanceof Function)
            return formatReturn(res.data.data);
          return res.data.data;
        });
      setLoading(false);
    };
    getLogs();
  }, []);

  return (
    <S.Main>
      <S.SpacedRow>
        <GhostButton
          startIcon={
            <SubdirectoryArrowRight sx={{ transform: 'rotate(180deg)' }} />
          }
          size="medium"
          onClick={() => _back && navigate(_back)}
        >
          Voltar
        </GhostButton>
      </S.SpacedRow>

      <Logs logs={logs} loading={loading} />
    </S.Main>
  );
};

export default Historico;
