export const verifyErrors = (fields, data, setErrors) => {
  let errors = [];
  fields.forEach(field => {
    if (field.required && !data[field.name] && field.name !== 'createdAt') {
      errors.push(field.name);
    }
  });
  setErrors && setErrors(errors);
  return !!errors?.length;
};
