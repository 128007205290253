import styled, { css, keyframes } from 'styled-components';
import { OutlinedInput } from '@mui/material';
import { MenuItem } from '@mui/material';

// Função para converter cor hexadecimal para RGBA
const hexToRGBA = (hex, opacity) => {
  let r = 0;
  let g = 0;
  let b = 0;
  // 3 dígitos
  if (hex.length === 4) {
    r = parseInt(hex[1] + hex[1], 16);
    g = parseInt(hex[2] + hex[2], 16);
    b = parseInt(hex[3] + hex[3], 16);
  }
  // 6 dígitos
  else if (hex.length === 7) {
    r = parseInt(hex[1] + hex[2], 16);
    g = parseInt(hex[3] + hex[4], 16);
    b = parseInt(hex[5] + hex[6], 16);
  }
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

const jump = keyframes`
    0% {
        padding-top: 0px;
    }
    50% {
        padding-top: 4px;
    }
    100% {
        padding-top: 0px;
    }
`;

const Container = styled.div`
  position: relative;
  transition: all ease 0.9s;
`;

export const Input = styled(OutlinedInput)`
  ${({ theme }) => css`
    .MuiOutlinedInput-notchedOutline {
      border: none;
    }
    .MuiOutlinedInput-notchedOutline.error {
      border: none;
    }
    .MuiInputBase-input.MuiOutlinedInput-input {
      color: ${theme.palette.words.subtitle.natural};
      font-size: 16px;
      font-weight: 400;
      font-family: 'Texta';
      opacity: 1;
    }
    .Mui-disabled {
      background-color: ${theme.palette.semantics.feedback.unknown.light};
      border-radius: '5px';
      border: none;
    }
  `}
`;

const Label = styled.p`
  color: red;
  font: normal medium 14px/20px Texta;
  margin-bottom: 5px;
`;

const Selecteds = styled.div`
  color: ${({ theme }) => theme.palette.semantics.feedback.unknown.dark};
  font: normal medium 14px/20px Texta;
  margin-bottom: 5px;
  position: absolute;
  margin-top: 15px;
  margin-left: 10px;
  opacity: ${props => props.opacity};
  transition: all ease 0.5s;
`;

const Required = styled.span`
  font-size: 20px;
  font-weight: 500;
  color: red;
`;

const SearchBox = styled.div`
  width: 100%;
  //padding: 3px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  position: relative;

  .searchIcon {
    margin-left: -30px;
  }
`;

export const LineBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  position: relative;
`;

const SearchInput = styled.input`
  ${({ theme }) => css`
    width: 90%;
    padding: 5px 8px;
    border: none;
    border: 1px solid ${theme.palette.semantics.feedback.unknown.natural};
    border-radius: 5px;
    font-size: 16px;

    &::placeholder {
      font-size: 16px;
    }

    &:focus {
      border: 1px solid ${theme.palette.brand.secondary.natural};
    }
  `}
`;
const ClearSelection = styled.div`
  ${({ theme }) => css`
    padding: 5px;
    text-align: center;
    color: ${theme.palette.semantics.feedback.unknown.dark};
    font-weight: 600;
    width: 100%;
    background-color: ${theme.palette.brand.secondary.background};
    cursor: pointer;
  `}
`;

const OptionsArea = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 20px;
`;

const Item = styled(MenuItem)`
  border: 1px solid #95aac94d;
  border-radius: 4px;
  margin-left: 10px;
  margin-bottom: 4px;
  margin-right: 10px;
  padding: 8px 16px 12px 16px;
  .status {
    text-align: center;
    width: 100px;
    font-weight: 700;
    color: ${p => p.color};
    border-radius: 10px;
    margin-right: 10px;
    background-color: ${p => hexToRGBA(p.color, 0.1)};
    padding: 1px 6px 1px 6px;
  }
`;

const LabelSearch = styled.label`
  display: flex;
  justify-content: start;
  align-items: center;

  span {
    margin-left: 15px;
    font-weight: 700;
    color: ${({ theme }) => theme.palette.semantics.feedback.unknown.dark};
  }
`;

const EmptyOptions = styled.span`
  text-align: center;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  color: ${({ theme }) => theme.palette.words.placeholder.light};
`;
const SeeMore = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 20px;
  height: 50px;

  .textSeeMore {
    font-size: 18px;
    font-weight: 600;
    color: #4b5166;
    cursor: pointer;
  }

  .divIcon {
    background-color: red;
    animation: ${jump} 1s ease infinite;
    position: relative;
    display: flex;
    justify-content: center;

    .icon {
      cursor: pointer;
      position: absolute;
    }
  }
`;

export {
  Label,
  Required,
  SearchBox,
  SearchInput,
  LabelSearch,
  OptionsArea,
  ClearSelection,
  Selecteds,
  Container,
  EmptyOptions,
  SeeMore,
  Item,
};
