import * as types from './types';

export function setFilterProvider(payload) {
  return {
    type: types.SET_FILTER_PROVIDER,
    payload,
  };
}

export function ResetFilterProvider() {
  return {
    type: types.RESET_FILTER_PROVIDER,
  };
}
