// Styled

// React
import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setItems } from 'store/modules/formularios/actions';

// Components

// Components MI
import { ListItem } from '@mui/material';

// Drag Lib
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import BoxItem from '../BoxItem';
import * as S from './styled';

const ContentItems = ({ items, section, pathname }) => {
  // Redux
  const dispatch = useDispatch();
  const formularios = useSelector(state => state.formularios);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = ({ destination, source }) => {
    // dropped outside the list
    if (!destination) return;
    if (source.index === destination.index) return;

    const reordered = reorder(items, source.index, destination.index);

    const sections = JSON.parse(JSON.stringify(formularios.secoes));
    for (const i in sections) {
      if (sections[i].index === section) {
        sections[i].campos = reordered;
      }
    }

    dispatch(setItems(sections));
  };

  const DraggableListItem = ({ item, index }) => {
    return (
      <Draggable draggableId={String(item.index)} index={index}>
        {provided => (
          <ListItem
            component="div"
            ref={provided.innerRef}
            {...provided.draggableProps}
            sx={{
              padding: '0px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <BoxItem
              item={item}
              section={section}
              referenceDrag={provided.dragHandleProps}
              pathname={pathname}
            />
          </ListItem>
        )}
      </Draggable>
    );
  };

  const DraggableList = memo(({ items, onDragEnds }) => {
    return (
      <DragDropContext onDragEnd={onDragEnds}>
        <Droppable droppableId="droppable-list-into">
          {provided => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {items.map((item, index) => {
                return (
                  <DraggableListItem
                    item={item}
                    index={index}
                    key={String(item.index)}
                  />
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  });

  return (
    <S.Container className="interalContainer">
      <DraggableList items={items} onDragEnds={onDragEnd} />
    </S.Container>
  );
};

export default ContentItems;
