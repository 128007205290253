import React from 'react';

const EmptyState = ({ title, subtitle, icon, print = false }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px',
        textAlign: 'center',
        color: '#999999',
        fontSize: '24px',
        height: print ? 200 : 300,
        backgroundColor: '#f0f0f0',
        opacity: 0.6,
        boxShadow: '0px 3px 6px #00000029',
      }}
    >
      {icon && <div>{icon}</div>}
      <p
        style={{
          marginTop: '10px',
          color: 'rgb(75, 81, 102)',
          fontWeight: 900,
        }}
      >
        {title || 'Não há dados para exibir'}
      </p>
      <p>{subtitle || 'Tente outro filtro'}</p>
    </div>
  );
};

export default EmptyState;
