import styled, { css } from 'styled-components';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import { Pagination } from '@mui/material';

// -----------------------GENERAL COMPONENTS-----------------------------------//

export const Wrapper = styled(Grid)`
  ${({ theme, ...props }) => css`
    #searchForm input {
      padding: 10px;
      color: ${theme.palette.words.label.light};
      opacity: 1;
    }

    #searchForm fieldset {
      border: 1px solid ${theme.palette.system.divider};
      border-radius: 4px;
      padding: 10px;
    }

    .css-2dhmxe-MuiGrid-root {
      padding-left: ${props.custom ? '0px' : '16px'};
    }

    .topPageContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: ${props.custom
        ? theme.palette.system.transparent
        : theme.palette.system.white};
      padding-bottom: 20px;
      border: ${props.custom
        ? 'none'
        : `1px solid ${theme.palette.system.border}`};
    }
  `}
`;

export const Table = styled.table`
  width: 100%;
  text-align: left;
  border-spacing: 0px;
  position: relative;
  background: ${({ theme, ...props }) =>
    props.custom ? theme.palette.system.white : theme.palette.system.black};
  border-left: 1px solid ${({ theme }) => theme.palette.system.border};
  border-right: 1px solid ${({ theme }) => theme.palette.system.border};
`;

export const TableRow = styled.tr`
  height: 50px;
  margin: 0;
  position: relative;
  &:last-child {
    padding: ${props => props.lastChildWidth && '10px'};
    width: ${props => props.lastChildWidth && '10px'};
  }
  &:nth-last-child(2) {
    padding: ${props => props.lastChildWidth && '10px'};
    width: ${props => props.lastChildWidth && '10px'};
  }

  .clicked--row {
    cursor: pointer;
  }
`;

export const TableCheckbox = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  border: 1px solid
    ${({ theme, ...props }) =>
      props.check || props.indeterminate
        ? theme.palette.brand.secondary.natural
        : theme.palette.system.divider};
  border-radius: 4px;
  cursor: pointer;

  .checkIcon {
    display: ${props => (props.check ? 'block' : 'none')};
    width: 18px;
  }

  input {
    display: none;
  }
`;

// -----------------------TOP COMPONENTS--------------------------------------//

export const BulkOptions = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-right: 15px;

  .button {
    display: flex;
  }
`;

export const BulkItem = styled.div`
  font-size: 15px;
  color: ${({ theme }) => theme.palette.words.text.light};
  font-weight: 700;
  opacity: 0.9;
  margin: 0px 10px;
  cursor: default;

  .option&:hover {
    color: ${({ theme }) => theme.palette.brand.secondary.light};
    cursor: pointer;
  }
`;

export const ItemPopover = styled.p`
  color: ${({ theme }) => theme.palette.words.text.natural};
  font-size: 16px;
  font-weight: 600;
`;

export const DoubleInput = styled.div`
  display: flex;
  align-items: center;
`;

// -----------------------HEADER COMPONENTS-----------------------------------//

export const TableHead = styled.thead`
  width: 100%;
  background: ${({ theme }) => theme.palette.semantics.feedback.unknown.light};
  color: ${({ theme }) => theme.palette.words.text.light};
  text-transform: uppercase;
`;

export const TableHeader = styled.th`
  padding: 10px 18px;
  cursor: pointer;
  font-size: 14px;
  span {
    position: relative;
    svg {
      position: absolute;
      top: 0;
    }
  }
`;

// -----------------------BODY COMPONENTS------------------------------------//

export const TableBody = styled.tbody`
  color: ${({ theme }) => theme.palette.brand.primary.natural};
`;

export const TableData = styled.td`
  background: ${({ theme, ...props }) =>
    props.viewed ? theme.palette.system.disabled : theme.palette.system.white};
  height: 50px;
  padding: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.system.divider};
`;

// -----------------------FOOTER COMPONENTS----------------------------------//

export const Footer = styled.div`
  ${({ theme, blockSizePage }) => css`
    color: ${theme.palette.words.text.light};
    display: flex;
    width: 100%;
    justify-content: ${blockSizePage ? 'end' : 'space-between'};
    padding: 0 22px;
    align-items: center;
    height: 100px;

    select {
      margin: 0 25px;
    }
  `}
`;

export const FooterCol = styled.div`
  display: flex;
  align-items: center;
`;

export const Control = styled.button`
  margin-left: 15px;
  display: flex;
  flex-direction: justify-content;
  align-items: center;
  cursor: pointer;
  background: none;
  border: none;
`;

export const Pages = styled(Pagination)`
  margin: 0px 10px;
  &.MuiPagination-root {
    color: ${({ theme }) => theme.palette.words.text.light};
  }
`;
