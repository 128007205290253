import { useQuery } from 'react-query';
import { Item } from './Item';
import Loading from 'components/Loading';
import * as C from '../styled';
import * as S from './styled';
import * as api from './services';

// Components

export const Relatorios = () => {
  const {
    data: allRelatorios = [],
    isLoading,
    refetch,
  } = useQuery(['relatorios-tipos-usuario'], () => api.getRelatorios(), {
    refetchOnWindowFocus: false,
  });

  return (
    <S.Container>
      {isLoading ? (
        <div className="boxLoading">
          <Loading />
        </div>
      ) : (
        <>
          <C.TitleArea
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
            }}
          >
            <span className="textArea">Relatórios por e-mail</span>
          </C.TitleArea>
          <S.ListContainer>
            {allRelatorios.map(item => (
              <Item
                key={item.id || item.id_relatorio_tipo}
                item={item}
                refetch={refetch}
              />
            ))}
          </S.ListContainer>
        </>
      )}
    </S.Container>
  );
};
