import {
  getCriticidade,
  getResponsaveis,
  getTiposDesvio,
} from 'constants/_SERVICES/user';

// Pages
import Violacoes from 'pages/Violacoes';
import Violacao from 'pages/Violacoes/Detalhe';
import LogViolacao from 'pages/Violacoes/Historico';

export default {
  '/violacoes': {
    date: true,
    pageFilterName: 'filterViolacao',
    filters: [
      {
        name: 'tipo',
        placeholder: 'Filtrar por Tipo',
        mode: 'multiple',
        section: 'Violação',
        data: async () => getTiposDesvio(),
      },
      {
        name: 'responsavel',
        placeholder: 'Filtrar por Responsáveis',
        mode: 'multiple',
        section: 'Violação',
        data: async () => getResponsaveis(),
      },
      {
        name: 'criticidade',
        placeholder: 'Filtrar por Criticidade',
        mode: 'multiple',
        section: 'Violação',
        data: async () => getCriticidade(),
      },
    ],
    defaults: ['filials', 'clients'],
    page: <Violacoes />,
  },

  '/logs/violacao/:id': {
    back: -1,
    title: 'Histórico da Violação',
    id: true,
    defaults: [],
    page: <LogViolacao />,
  },

  '/violacao/:id': {
    back: -1,
    title: 'Violação',
    id: true,
    defaults: [],
    page: <Violacao />,
  },
};
