const palette = {
  brand: {
    primary: {
      light: '#95AAC9',
      natural: '#494F65',
      dark: '#373f54',
      background: '#F9FBFD',
    },
    secondary: {
      light: '#CCD955',
      natural: '#BDCC30',
      dark: '#97a326',
      background: '#F1F5D5',
    },
  },
  primary: {
    light: '#95AAC9',
    main: '#494F65',
    dark: '#373f54',
  },
  secondary: {
    light: '#CCD955',
    main: '#BDCC30',
    dark: '#97a326',
  },
  semantics: {
    feedback: {
      attention: {
        light: '#F6C9CB',
        natural: '#F64E60',
        dark: '#DF2B34',
      },
      warning: {
        light: '#ffde9e',
        natural: '#FFA801',
        dark: '#E3B40B',
      },
      success: {
        light: '#cff0ed',
        natural: '#1BC5BD',
        dark: '#006d68',
      },
      information: {
        light: '#DBE9F3',
        natural: '#3699FF',
        dark: '#0c12f2',
      },
      unknown: {
        light: '#F2F2F2',
        natural: '#CAD4E4',
        dark: '#4F4F4F',
      },
    },
  },
  words: {
    text: {
      light: '#95AAC9',
      natural: '#4F4F4F',
      dark: '#000000',
      contrast: '#FFFFFF',
    },
    title: {
      light: '#C3C3C3',
      natural: '#4B5166',
      dark: '#3d465d',
      contrast: '#FFFFFF',
    },
    subtitle: {
      light: '#95AAC9',
      natural: '#656E8C',
      dark: '#000000',
      contrast: '#EFEFEF',
    },
    caption: {
      light: '#C3C3C3',
      natural: '#4F4F4F',
      dark: '#000000',
      contrast: '#FFFFFF',
    },
    label: {
      light: '#C3C3C3',
      natural: '#4F4F4F',
      dark: '#000000',
      contrast: '#FFFFFF',
    },
    button: {
      light: '#C3C3C3',
      natural: '#4F4F4F',
      dark: '#000000',
      contrast: '#FFFFFF',
    },
    link: {
      light: '#C3C3C3',
      natural: '#4F4F4F',
      dark: '#000000',
      contrast: '#FFFFFF',
    },
    placeholder: {
      light: '#C3C3C3',
      natural: '#4F4F4F',
      dark: '#000000',
      contrast: '#FFFFFF',
    },
    disabled: {
      light: '#C3C3C3',
      natural: '#4F4F4F',
      dark: '#000000',
      contrast: '#FFFFFF',
    },
  },
  system: {
    transparent: 'transparent',
    background: 'transparent',
    border: '#EDF2F9',
    overlay: '#FFFFFF',
    highlight: '#FFFFFF',
    disabled: '#F2F2F2',
    divider: '#E0E0E0',
    scrollbar: '#707070',
    backdrop: '#22222222',
    shadow: '#00000028',
    white: '#FFF',
    black: '#000000',
  },
};
export default palette;
