import React, { useState } from 'react';
import { addMonths } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Calendar } from 'react-date-range';

import { Popover } from '@mui/material';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';

import { useTheme } from 'styled-components';
import { formatNewDate } from 'utils/dates';
import * as S from './styled';

/*
Componente Calendar editavel de "react-date-range"
value e onChange: controlam a data selecionada
futureDate e pastDate: bloqueiam ou permitem datas futuras e passadas
anchorEl: controla modal
*/
const CustomCalendar = ({
  value,
  onChange,
  readOnly,
  futureDate = true,
  pastDate = true,
  label,
  required,
  id,
  minDate = null,
  noAsterisk,
  error,
  disabled,
  ...props
}) => {
  const theme = useTheme();
  // Controle do modal
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const anchorId = open ? 'date-popover' : undefined;

  // Datas maxima e minima a serem selecionadas
  const maxDate = futureDate ? addMonths(new Date(), 12) : new Date();
  const minDateDefault = pastDate ? addMonths(new Date(), -12) : new Date();

  const handleData = date => {
    setAnchorEl(null);
    onChange(date);
  };

  // Controle do modal
  const handleClick = event => {
    if (!readOnly) setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // String do botao do filtro
  const getDateString = () => {
    const str = formatNewDate(value);
    if (str) return str;
    return ' __ /__ /__ ';
  };

  return (
    <S.InputWrapper>
      {!noAsterisk && (
        <S.Label>
          {label}
          {required && <span>*</span>}
        </S.Label>
      )}

      <S.StyledButton
        id={id}
        textcolor={
          anchorEl
            ? theme.palette.brand.secondary.natural
            : theme.palette.words.subtitle.natural
        }
        backgroundcolor={disabled ? 'rgb(242, 242, 242)' : 'transparent'}
        endIcon={anchorEl ? <ArrowDropUp /> : <ArrowDropDown />}
        aria-describedby={anchorId}
        onClick={handleClick}
        haserror={error ? 'true' : undefined}
        disabled={disabled}
        {...props}
      >
        {getDateString()}
      </S.StyledButton>

      <Popover
        id={anchorId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <S.Main>
          <Calendar
            minDate={minDate !== null ? minDate : minDateDefault}
            maxDate={maxDate}
            onChange={handleData}
            showMonthAndYearPickers={false}
            showMonthArrow // default
            showDateDisplay
            showPreview // default
            dayDisplayFormat="d" // default
            weekdayDisplayFormat="EEEEEE"
            dateDisplayFormat="MMM d, yyyy" // default
            monthDisplayFormat={' '}
            moveRangeOnFirstSelection={false} // default
            locale={ptBR}
            months={1}
            direction="horizontal"
            preventSnapRefocus
            calendarFocus="backwards"
            value={value}
            style={{ width: '100%' }}
            color={theme.palette.brand.secondary.natural}
          />
        </S.Main>
      </Popover>
    </S.InputWrapper>
  );
};

export default CustomCalendar;
