import { Divider, Grid } from '@mui/material';
import { useSelector } from 'react-redux';

// Utils
import { formatNewDate, formatNewHour } from 'utils/dates';

import * as S from './styled';

export const ExportDesviosPdf = ({ data, printRef }) => {
  const logo = require('images/onisys.png');
  const { user } = useSelector(state => state.auth.user);
  const companyLogo = user?.filial?.empresa?.logo_url;

  // -------------------------------------------------Retorno de cada gráfico--------------------------------------------------//

  return (
    <S.ModalMUI open={false} onClose={() => {}} disableEscapeKeyDown>
      <S.Container ref={printRef}>
        <S.Infos>
          <div className="formtIdent">
            <div className="logoArea">
              <img src={logo} />
            </div>
          </div>
          <div className="formIdent">
            <span>{`Motorista: ${data?.motorista?.nome || ' - '}`}</span>
            <div className="titleForm">Desvio: ID {data.id}</div>
            <div className="logoCompany">
              <img src={companyLogo} />
            </div>
          </div>
        </S.Infos>
        <S.CardHeader>
          <h1>Informações do Desvio</h1>
          <S.Header bg={data?.img_ocorrencia || ''}>
            <div className="topInfos">
              <div className="coverArea">
                <div className="background" />
              </div>
              <div className="textsArea">
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <div className="normalText">Motorista</div>
                    <div className="inputBox">
                      <p>{data?.motorista?.nome || '-'}</p>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div className="normalText">Filial</div>
                    <div className="inputBox">
                      <p>{data?.motorista?.filial?.nome || '-'}</p>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="normalText">Desvio</div>
                    <div className="inputBox">
                      <p>{data?.tipo?.descricao || '-'}</p>
                    </div>
                  </Grid>{' '}
                  <Grid item xs={6}>
                    <div className="normalText">Reportado em</div>
                    <div className="inputBox">
                      <p>{formatNewHour(data?.createdAt) || ''}</p>
                    </div>
                  </Grid>{' '}
                  <Grid item xs={6}>
                    <div className="normalText">Data do desivo</div>
                    <div className="inputBox">
                      <p>{formatNewHour(data?.data_desvio) || '-'}</p>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="normalText">Criticidade</div>
                    <div className="inputBox">
                      <p>{data?.tipo?.criticidade || '-'}</p>
                    </div>
                  </Grid>
                  {data?.avaliacao?.local && (
                    <Grid item xs={12}>
                      <div className="normalText">Trajeto</div>
                      <div className="inputBox">
                        <p>{data?.avaliacao?.local}</p>
                      </div>
                    </Grid>
                  )}
                </Grid>
              </div>
            </div>
          </S.Header>
        </S.CardHeader>

        <S.CardHeader style={{ marginTop: '30px' }}>
          <h1>Informações complementares</h1>
          <S.Header>
            <div className="topInfos">
              <div className="textsArea">
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <div className="normalText">Responsável</div>
                    <div className="inputBox">
                      <p>{data?.responsavel?.nome || '-'}</p>
                    </div>
                  </Grid>{' '}
                  <Grid item xs={4}>
                    <div className="normalText">Previsão de conclusão</div>
                    <div className="inputBox">
                      <p>{formatNewHour(data?.data_previsao) || '-'}</p>
                    </div>
                  </Grid>
                  <Grid item xs={12} className="page-break">
                    <div className="normalText">Plano de ação</div>
                    <div className="inputBoxArea">
                      <p>{data?.plano_de_acao || '-'}</p>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="normalText">Arquivo Justificativa</div>
                    <div className="inputBox">
                      <p>{data?.arquivo_justificativa || '-'}</p>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="normalText">Observaçôes</div>
                    <div className="inputBoxArea">
                      <p>{data?.observacao || '-'}</p>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          </S.Header>
        </S.CardHeader>
      </S.Container>
    </S.ModalMUI>
  );
};
