/* eslint-disable import/order */

// Styles
import * as S from './styled';

// React
import { toast } from 'react-toastify';
import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';

// Components
import { Users } from './Users';
import Tabs from 'components/Tabs';
import { General } from './General';
import GhostButton from 'components/Buttons/Ghost';
import ExportToExcel from 'utils/exportToCvs';

// Components MUI
import { SaveAlt } from '@mui/icons-material';
import { Divider, Popover } from '@mui/material';

// Utils
import { tabs } from './constants';
import * as services from './services';

export const Reports = () => {
  // Tab States
  const { id } = useSelector(state => state.conteudoProvider);

  const printRefPdf = useRef();
  const printRefCompany = useRef();

  const [currentTab, setCurrentTab] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [dataPrint, setDataPrint] = useState([]);
  const [loadingExport, setLoadingExport] = useState(false);

  const exportGraph = useReactToPrint({
    content: () => printRefPdf.current,
    onBeforeGetContent: () => setLoadingExport(true),
    onAfterPrint: () => {
      setLoadingExport(false);
    },
    copyStyles: true,
    documentTitle: `conteudo_id_${id}`,
  });

  const exportExcelCompany = async () => {
    setLoadingExport(true);
    let res = await services.exportExcel(id);
    if (res.success) {
      toast.success(res.data.message);
      ExportToExcel({
        excel: res?.data?.excelSheet,
        name: `conteudo_id_empresas_${id}`,
      });
      setLoadingExport(false);
    } else {
      toast.error(res.data.message);
      setLoadingExport(false);
    }
  };

  const exportPdfCompany = async () => {
    setLoadingExport(true);
    let res = await services.exportPDF(id);
    if (res.success) {
      setDataPrint(res.data);
      printPdfCompany();
    } else {
      toast.error(res.data.message);
      setLoadingExport(false);
    }
  };

  const printPdfCompany = useReactToPrint({
    content: () => printRefCompany.current,
    onBeforeGetContent: () => setLoadingExport(true),
    onAfterPrint: () => {
      setLoadingExport(false);
    },
    copyStyles: true,
    documentTitle: `conteudo_id_empresas_${id}`,
  });

  return (
    <S.Container>
      <S.HeaderLine>
        <Tabs
          value={currentTab}
          items={tabs}
          onChange={(e, tab) => setCurrentTab(tab)}
        />
        <GhostButton
          children="Exportar"
          icon={<SaveAlt />}
          onClick={
            currentTab === 0
              ? () => exportGraph()
              : event => setAnchorEl(event.currentTarget)
          }
          loading={loadingExport}
        />
        <Popover
          sx={{ transform: 'translate(0px, 2px)' }}
          id={anchorEl ? 'popover' : undefined}
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          onClick={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <S.Popup>
            <button onClick={() => exportExcelCompany()}>Excel</button>
            <button onClick={() => exportPdfCompany()}>PDF</button>
          </S.Popup>
        </Popover>
      </S.HeaderLine>
      <Divider style={{ marginBottom: '30px' }} />

      <S.SliderBox>
        {currentTab === 0 && (
          <S.SlideComponent className="general">
            <General printRefPdf={printRefPdf} />
          </S.SlideComponent>
        )}
        {currentTab === 1 && (
          <S.SlideComponent className="users">
            <Users printRefPdf={printRefCompany} dataPrint={dataPrint} />
          </S.SlideComponent>
        )}
      </S.SliderBox>
    </S.Container>
  );
};
