/* eslint-disable import/order */

// Styles
import * as S from './styled';

// React
import { useEffect, useState } from 'react';

// Components
import { Input } from '../Input';

// Components MUI
import SearchIcon from '@mui/icons-material/Search';
import SyncAltIcon from '@mui/icons-material/SyncAlt';

// Context
import { useTable } from '../../../context';
import { _setSearch, _setTable } from '../../../context/actions';

let timer = null;

export const DoubleInput = ({
  placeholderLeft = 'Buscar por Origem',
  placeholderRight = 'Buscar por Destino',
  Icon = SearchIcon,
  MediumIcon = SyncAltIcon,
  keys = ['origem', 'destino'],
  loading,
  local,
  searchEvent,
  ...props
}) => {
  const { dispatch } = useTable();
  const [initial, setInitial] = useState(true);

  const [values, setValues] = useState({});

  const handleValue = item => {
    setValues({ ...values, ...item });
  };

  useEffect(() => {
    if (!initial) {
      const time = local ? 0 : 800;
      clearTimeout(timer);

      timer = setTimeout(() => {
        dispatch(_setTable(values));
        (values[keys[0]] || values[keys[1]]) &&
          searchEvent &&
          searchEvent(values);
      }, time);
    } else {
      setInitial(false);
      dispatch(_setTable({ [keys[0]]: '', [keys[1]]: '' }));
    }
  }, [values]);

  const alterValues = () => {
    setValues({
      [keys[0]]: values[keys[1]] || '',
      [keys[1]]: values[keys[0]] || '',
    });
  };

  return (
    <S.Root>
      <Input
        placeholder={placeholderLeft}
        value={values[keys[0]]}
        onChange={e => handleValue({ [keys[0]]: e.target.value })}
        local={local}
        loading={loading}
        {...props}
      />
      <MediumIcon
        data-testid="medium-icon"
        className="medium-icon"
        onClick={alterValues}
      />
      <Input
        placeholder={placeholderRight}
        value={values[keys[1]]}
        onChange={e => handleValue({ [keys[1]]: e.target.value })}
        local={local}
        loading={loading}
        {...props}
      />
    </S.Root>
  );
};
