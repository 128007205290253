import { SvgIcon } from '@mui/material';
import { ReactComponent as meu } from 'images/icons/acidentes/icone_meu.svg';
import { ReactComponent as fora } from 'images/icons/acidentes/icone_fora.svg';

export const colorsGravidade = {
  LEVE: '#1BC5BD',
  MÉDIA: '#0C12F2',
  GRAVE: '#FFA801',
  GRAVÍSSIMA: '#F64E60',
};

export const columns = [
  {
    header: 'Motorista',
    id: 'motorista.matricula',
    type: 'string',
    tooltip: true,
    sort: true,
    attention: (_, item) => {
      if (item.provider)
        return {
          left: true,
          text: `Editado por ${item.distribuidora?.nome}.`,
          iconIn: '',
          iconOut: <SvgIcon component={meu} />,
        };

      return {
        left: true,
        text: `Editado por ${item.empresa?.nome}.`,
        iconIn: '',
        iconOut: <SvgIcon component={fora} />,
      };
    },
  },
  {
    header: 'Filial',
    id: 'motorista.filial.nome',
    type: 'string',
    sort: true,
  },
  {
    header: 'Data da infracao',
    id: 'data_multa',
    type: 'date-hour',
    sort: true,
    value: value => new Date(value),
  },
  {
    header: 'Tipo de infração',
    id: 'multa_tipo.titulo',
    type: 'string',
    tooltip: true,
    sort: true,
  },
  {
    header: 'Local',
    id: 'endereco',
    type: 'string',
    tooltip: true,
    sort: true,
  },
  {
    header: 'Gravidade',
    id: 'multa_tipo.gravidade',
    type: 'string',
    sort: true,
    align: 'center',
    conditional: [
      {
        condition: value => value === 'LEVE',
        style: theme => ({
          color: theme.palette.semantics.feedback.success.natural,
          backgroundColor: theme.palette.semantics.feedback.success.light,
          textAlign: 'center',
        }),
      },
      {
        condition: value => value === 'MÉDIA',
        style: theme => ({
          color: theme.palette.semantics.feedback.information.natural,
          backgroundColor: theme.palette.semantics.feedback.information.light,
          textAlign: 'center',
        }),
      },
      {
        condition: value => value === 'GRAVE',
        style: theme => ({
          color: theme.palette.semantics.feedback.warning.natural,
          backgroundColor: theme.palette.semantics.feedback.warning.light,
          textAlign: 'center',
        }),
      },
      {
        condition: value => value === 'GRAVÍSSIMA',
        style: theme => ({
          color: theme.palette.semantics.feedback.attention.natural,
          backgroundColor: theme.palette.semantics.feedback.attention.light,
          textAlign: 'center',
        }),
      },
    ],
  },
];
