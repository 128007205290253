// Styles
import { useTheme } from 'styled-components';

// React
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as file } from 'images/icons/sidebar/file.svg';
import { changeTabEvaluation } from 'store/modules/filterAvaliacoes/actions';

// Componentes
import Tabs from 'components/Tabs';
import SaveModal from './SaveModal';
import ModalCriacao from './ModalCriacao';
import ExcelModal from 'components/ExcelModal';
import ConfirmModal from 'components/ConfirmModal';
import GhostButton from 'components/Buttons/Ghost';
import CardIndicadores from 'components/Cards/Indicador';
import { DefaultTable } from 'components/_Table/templates/default';

// Componentes do material
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import { SaveAlt } from '@mui/icons-material';

// Utils
import { trackEvent } from 'utils/mixpanel';
import { formatNameDate } from 'utils/dates';
import ExportToExcel from 'utils/exportToCvs';
import { getRouterQueryParams } from 'utils/router-query-params';

// Serviços
import * as request from './services';

// Columns
import {
  columnsArchived2,
  columnsFilled2,
  columnsProcessing2,
  columnsPending2,
  columnsSigned2,
  columnsUnavailable2,
} from './columns';

// Columns
import { fields, resetFields, justificativas, tabs } from './constants';

const Avaliacoes = () => {
  const theme = useTheme();
  // Navigate
  const navigate = useNavigate();

  // Redux
  const dispatch = useDispatch();
  const filter = useSelector(state => state.filter);
  const user = useSelector(state => state.auth?.user?.user);
  const userLevel = user.nivel;
  const { responsavel, carga, evaluationTab } = useSelector(
    state => state.filterAvaliacoes,
  );

  // General States
  const [query, setQuery] = useState(null);
  const [loadingTab, setLoadingTab] = useState(false);
  const [responsibles, setResponsibles] = useState(null);
  const [warningModal, setWarningModal] = useState(false);
  const [selectedEvaluations, setSelectedEvaluations] = useState(null);

  // -------------------------TABS (TOP PAGE)---------------------------------------//

  const getCol = () => {
    switch (evaluationTab) {
      case 1:
        return columnsProcessing2;
      case 2:
        return columnsPending2;
      case 3:
        return columnsFilled2;
      case 4:
        return columnsSigned2;
      case 5:
        trackEvent(user, 'Avaliações Arquivadas');
        return columnsArchived2;
      default:
        return columnsUnavailable2;
    }
  };

  const handlePageChange = (e, tab) => {
    setSelectedCards(null);
    setLoadingTab(true);
    dispatch(changeTabEvaluation({ tab }));
  };

  // -------------------------CARDS (TOP PAGE)---------------------------------------//
  const [selectedCard, setSelectedCards] = useState(null);

  const handleClickCard = type => {
    if (type === selectedCard) setSelectedCards(null);
    else setSelectedCards(type);
  };

  const renderCards = () => {
    const cards = resCards?.data?.data || [{}, {}, {}, {}, {}];

    return (
      <Grid item>
        <Grid container spacing={2}>
          {cards.map((item, idx) => {
            let md = 4;
            if (12 / cards.length < 4) md = 12 / cards.length;
            return (
              <Grid item key={idx} md={md} xs={12} sm={6}>
                <CardIndicadores
                  value={item.ids?.length}
                  smalltype={cards.length > 3 ? 'true' : undefined}
                  icon="Grupo12741.svg"
                  text={item.text}
                  border={`1px solid ${theme.palette.brand.primary.light}4D`}
                  handleClick={
                    item.type !== 'VIDEOS_PROCESSADOS'
                      ? () => handleClickCard(item.type)
                      : null
                  }
                  selected={selectedCard === item.type}
                  loading={loadingCards}
                  disabled={isFetching}
                />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    );
  };

  // -------------------------- BULK MENU & ACTION COLUMN---------------------------//

  const [folga, setFolga] = useState('');
  const [justify, setJustify] = useState('');
  const [witness, setWitness] = useState('');
  const [loadingLines, setLoadingLines] = useState([]);
  const [openWitnessSelection, setOpenWitnessSelection] = useState(false);
  const [openArchiveEvaluations, setOpenArchiveEvaluations] = useState(false);
  const [openUnarchiveEvaluations, setOpenUnarchiveEvaluations] =
    useState(false);

  const bulk = [
    {
      title: 'Arquivar avaliação',
      function: setOpenArchiveEvaluations,
      visible: () => [0, 2, 3].includes(evaluationTab) && userLevel < 4,
    },
    {
      title: 'Selecionar testemunha',
      function: setOpenWitnessSelection,
      visible: () => [2, 3].includes(evaluationTab) && userLevel < 4,
    },
    {
      title: 'Restaurar Avaliação',
      function: setOpenUnarchiveEvaluations,
      visible: () => [5].includes(evaluationTab) && userLevel < 4,
    },
  ];

  const openNewTab = id => {
    let newTab = null;
    newTab = window.open(`/avaliacoes/${id}`, id);
    return newTab;
  };

  const handleViewEvaluation = async id => {
    await request.viewEvaluation(id);
    setLoadingLines([id]);
    fetchData();
  };

  const handleArchiveEvaluation = async id => {
    const ids = selectedEvaluations.length > 0 ? selectedEvaluations : [id];
    setLoadingLines(ids);

    const body = {
      event: 'archiveEvaluation',
      data: {
        status: 'ARQUIVADA',
        justificativa: justify,
        data_folga: folga,
        ids,
      },
      id,
    };

    const res = await request.archiveEvaluation(body);
    if (res.success) {
      toast.success('Sucesso ao arquivar avaliação!');
      setOpenArchiveEvaluations(null);
      fetchData();
      fetchCards();
      setJustify('');
      setFolga('');
    } else {
      setLoadingLines([]);
      toast.error(res.message);
    }
  };

  const handleUnarchiveEvaluation = async id => {
    const ids = selectedEvaluations.length > 0 ? selectedEvaluations : [id];
    setLoadingLines(ids);
    const body = {
      event: 'unarchiveEvaluation',
      data: {
        ids,
      },
      id,
    };

    try {
      await request.archiveEvaluation(body);
      toast.success('Sucesso ao resturar avaliação!');
      fetchCards();
      fetchData();
    } catch (error) {
      setLoadingLines([]);
      const { message } = error.response.data;
      toast.error(message);
    }
    setOpenUnarchiveEvaluations(false);
  };

  const handleSelectWitnessEvaluation = async id => {
    let ids = [];
    ids =
      selectedEvaluations && selectedEvaluations.length > 0
        ? selectedEvaluations
        : [id];

    if (!witness) {
      toast.error('Selecione uma testemunha');
      return;
    }

    setLoadingLines(ids);

    const body = {
      event: 'bulk',
      data: {
        id_da_testemunha: witness,
        avaliacoes: ids,
      },
    };

    const res = await request.selectWitnessEvaluation(body);
    if (res.data.success) {
      fetchCards();
      fetchData();
      toast.success('Sucesso ao selecionar testemunha das avaliações!');
    } else {
      setLoadingLines([]);
      toast.error(res.data.message);
    }
    setOpenWitnessSelection(null);
    setWitness('');
  };

  const handleNavigateSamePage = id => {
    return navigate(`/avaliacoes/${id}`);
  };

  const actions = [
    {
      title: 'Arquivar avaliação',
      function: id => {
        setOpenArchiveEvaluations(id);
        trackEvent(user, 'Arquivar Avaliação');
      },
      visible: () => [0, 2, 3].includes(evaluationTab) && userLevel < 4,
    },
    {
      title: 'Criar Avaliação',
      function: id => {
        setWarningModal(id);
        trackEvent(user, 'Criar Avaliação');
      },
      visible: () =>
        [0].includes(evaluationTab) &&
        !selectedEvaluations?.length &&
        userLevel < 4,
    },
    {
      title: 'Imprimir',
      function: id => {
        navigate(`pdf/${id}`);
        trackEvent(user, 'Imprimir Avaliação');
      },
      visible: () =>
        [3, 4].includes(evaluationTab) && !selectedEvaluations?.length,
    },
    {
      title: 'Selecionar testemunha',
      function: id => {
        setOpenWitnessSelection(id);
        trackEvent(user, 'Testemunha Adicionada');
      },
      visible: () => [2, 3, 4].includes(evaluationTab) && userLevel < 4,
    },
    {
      title: 'Marcar como visto',
      function: id => handleViewEvaluation(id),
      visible: () =>
        [2, 3, 4].includes(evaluationTab) && !selectedEvaluations?.length,
    },
    {
      title: 'Abrir em nova guia',
      function: id => openNewTab(id),
      visible: () =>
        [2, 3, 4].includes(evaluationTab) && !selectedEvaluations?.length,
    },
    {
      title: 'Restaurar Avaliação',
      function: id => setOpenUnarchiveEvaluations(id),
      visible: () => [5].includes(evaluationTab) && userLevel < 4,
    },
  ];

  // -------------------------- REQUESTS ------------------------------------------//
  const [resetTable, setResetTable] = useState(false);

  const {
    refetch: fetchData,
    isFetching,
    isLoading: loadingEvaluations,
    data: resData,
  } = useQuery(
    ['evaluations-index', query],
    () => query && request.requestAvaliacoesList(query),
    {
      refetchOnWindowFocus: false,
      onSuccess: () => {
        setLoadingLines([]);
        setLoadingTab(false);
        resetTable && setResetTable(false);
      },
    },
  );

  const {
    refetch: fetchCards,
    isLoading: loadingCards,
    data: resCards,
  } = useQuery(
    ['evaluations-cards', { ...filter, responsavel, carga, evaluationTab }],
    () =>
      request.requestCards({
        ...filter,
        responsavel,
        carga,
        status: evaluationTab,
      }),
    {
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    if (openWitnessSelection) {
      fetchResponsibles(openWitnessSelection);
    }
  }, [openWitnessSelection]);

  const fetchResponsibles = async id => {
    if (responsibles === null) {
      const res = await request.requestResponsibles(id);
      setResponsibles(res.data.data);
    }
  };

  // -------------------------- EXCEL ------------------------------------------//

  const [openExcelModal, setOpenExcelModal] = useState(false);
  const [excelFields, setExcelFields] = useState(fields);
  const [loadingExcel, setLoadingExcel] = useState(false);

  const handleRequestExcel = async () => {
    const newFields = excelFields.filter(item => item.selected === true);
    const formatedDate = formatNameDate(new Date());

    let { initialDate } = query;
    let { finalDate } = query;
    initialDate = Date.parse(initialDate);
    finalDate = Date.parse(finalDate);

    if (finalDate - initialDate > 7889400000 && !selectedEvaluations.length) {
      toast.error('Não é possível exportar períodos maiores que 3 meses');
      return;
    }

    const newQuery = {
      ...query,
      responsavel,
      carga,
      status: evaluationTab,
      excelFields: newFields,
      ids: selectedEvaluations,
    };

    setLoadingExcel(true);
    const res = await request.requestExcel(newQuery);

    if (!res.data.success) {
      toast.error(res.data.message);
      setLoadingExcel(false);
      return;
    }

    if (res.data && res.data.data.excel) toast.success(res.data.message);
    ExportToExcel({
      excel: res.data.data.excel,
      name: `avaliacoes_${formatedDate}`,
    });
    setExcelFields(resetFields(fields));
    setLoadingExcel(false);
    setOpenExcelModal(false);
  };

  const initial = useRef(true);
  useEffect(() => {
    if (initial.current) {
      initial.current = false;
      return;
    }

    setResetTable(true);
  }, [filter, responsavel, carga, evaluationTab, selectedCard]);

  useEffect(() => {
    setJustify('');
    setWitness('');
  }, [openArchiveEvaluations, openWitnessSelection]);

  const mode = getRouterQueryParams({ location: useLocation }).get('mode');

  useEffect(() => {
    if (!mode) return;

    const tab = tabs.find(
      item => item.label.toLowerCase() === mode.toLowerCase(),
    )?.value;

    tab &&
      dispatch(
        changeTabEvaluation({
          tab,
        }),
      );
  }, [mode]);

  return (
    <Grid container>
      <Grid container>
        <Grid
          marginBottom={1}
          item
          md={12}
          display="flex"
          justifyContent="space-between"
        >
          <h1>Avaliação Direção</h1>
          <GhostButton
            children="Exportar"
            startIcon={<SaveAlt />}
            onClick={() => {
              setOpenExcelModal(true);
              trackEvent(user, 'Exportar Planilha');
            }}
          />
        </Grid>
        <Grid marginBottom={0} item md={12} display="flex">
          <Tabs
            value={evaluationTab}
            items={tabs}
            onChange={handlePageChange}
            last
            disabled={isFetching}
          />
        </Grid>
      </Grid>
      <Grid marginBottom={3} item md={12}>
        <Divider />
      </Grid>
      <Grid marginBottom={3} item md={12}>
        {renderCards()}
      </Grid>
      <Grid item md={12}>
        <DefaultTable
          data={resData?.data?.rows || []}
          columns={getCol()}
          setSelectedRows={setSelectedEvaluations}
          loading={loadingEvaluations || loadingTab}
          pageCount={resData?.data?.count || 0}
          visualizedKey="visto"
          local={false}
          bulk={bulk}
          actions={evaluationTab === 1 ? undefined : actions}
          reset={resetTable}
          onClickRow={
            evaluationTab !== 1 && evaluationTab !== 0 && evaluationTab !== 5
              ? handleNavigateSamePage
              : false
          }
          loadingSelection={loadingLines}
          setQuery={q =>
            setQuery({
              ...q,
              ...filter,
              responsavel,
              carga,
              status: evaluationTab,
              card: selectedCard,
            })
          }
          searchEvent={search =>
            trackEvent(user, 'Busca Avaliação de Direção', null, search)
          }
          sortBy={{
            id: evaluationTab ? 'data_avaliacao' : 'updatedAt',
            order: 'DESC',
          }}
          placeholder="Buscar Motoristas, ID..."
          empty={{
            title: 'Ops! Você não tem nenhuma avaliação.',
            description: 'Verifique os filtros selecionados.',
          }}
        />
      </Grid>
      <SaveModal
        onClick={() => handleArchiveEvaluation(openArchiveEvaluations)}
        open={!!openArchiveEvaluations}
        handleClose={() => setOpenArchiveEvaluations(false)}
        title="Deseja arquivar esta Avaliação?"
        titleIcon={file}
        subtitle="Selecione uma justificativa para o arquivamento."
        disabledSave
        value={justify}
        handleChange={setJustify}
        valueFolga={folga}
        setFolga={setFolga}
        data={justificativas.map(item => ({ value: item, name: item }))}
        selectLabel="Justificativa"
      />
      <ConfirmModal
        handleClose={() => setOpenUnarchiveEvaluations(false)}
        open={!!openUnarchiveEvaluations}
        title="Tem certeza que deseja restaurar?"
        subtitle="As avaliações resturadas voltam ao estado anterior"
        buttonText="Confirmar"
        onClick={() => handleUnarchiveEvaluation(openUnarchiveEvaluations)}
      />
      {responsibles && (
        <SaveModal
          onClick={() => handleSelectWitnessEvaluation(openWitnessSelection)}
          open={!!openWitnessSelection}
          handleClose={() => setOpenWitnessSelection(false)}
          title="Selecione a testemunhas da avaliação"
          titleIcon={file}
          subtitle="Selecione um usuário para testemunha."
          disabledSave
          value={witness}
          handleChange={setWitness}
          selectLabel="Testemunha"
          data={responsibles.map(item => ({
            value: item.id,
            name: item.nome,
          }))}
        />
      )}
      {openExcelModal && (
        <ExcelModal
          onClick={handleRequestExcel}
          open={openExcelModal}
          handleClose={() => setOpenExcelModal(false)}
          title="Selecionar os campos de Excel"
          titleIcon={file}
          subtitle="Selecionar abaixos:"
          setData={setExcelFields}
          data={excelFields}
          loading={loadingExcel}
        />
      )}

      {warningModal && (
        <ModalCriacao
          open
          onClose={setWarningModal}
          onClick={() => navigate(`/avaliacoes/criar/${warningModal}`)}
        />
      )}
    </Grid>
  );
};

export default Avaliacoes;
