/* eslint-disable import/order */
import * as S from './styled';
import { useTheme } from 'styled-components';

// React
import { toast } from 'react-toastify';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

// Components
import DataHour from 'components/Inputs/DataHour';
import GhostButton from 'components/Buttons/Ghost';
import TextInput from 'components/Inputs/TextField';
import DefaultButton from 'components/Buttons/Default';
import DropZoneArchives from 'components/Inputs/DropZoneCustom';

// Services
import { createCae } from '../../services';

// Components MUI
import {
  Divider,
  Grid,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import { CalendarTodayOutlined } from '@mui/icons-material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';

// Utils
import { addDays } from 'date-fns';
import { formatNewDate, formatNewHour } from 'utils/dates';

export const ModalCae = ({
  posto,
  open,
  questao,
  setOpen,
  setPosto,
  item,
  setItem,
  answers,
  setAnswers,
}) => {
  const theme = useTheme();
  const user = useSelector(state => state.auth?.user?.user);
  const [loading, setLoading] = useState(false);

  const [cae, setCae] = useState({
    status: 'ABERTO',
    operacao: posto?.distribuidora,
    nome_motorista: null,
    placa: null,
    id_da_filial: null,
    caminhao: null,
    observacao_cae: null,
    imagem_cae: null,
    data_ocorrencia: new Date(),
    data_limite: addDays(new Date(), questao?.prazo_bloqueio),
    titulo: questao && questao?.abreviacao,
    posto,
    questao,
    id_do_aprovador: user.id,
    usuario: user,
  });

  const handleChange = (key, value) => {
    setCae({ ...cae, [key]: value });
  };

  const handleCreateCae = async () => {
    setLoading(true);
    const res = await createCae(cae);
    setLoading(false);
    if (res.success) {
      toast.success('CAE criado com sucesso!');
      setPosto({
        ...posto,
        cae: [...posto?.cae, { ...res?.cae, questao: cae?.questao }],
      });
      setAnswers(
        answers?.map(a =>
          a.id_da_questao === item ? { ...a, resposta: 'NAO' } : a,
        ),
      );
      handleClose();
    } else {
      toast.error(res.message);
    }
  };

  const addImage = data => {
    const imagem_cae = data[0].imagem_url;
    setCae({ ...cae, imagem_cae });
  };

  const delImage = () => {
    setCae({ ...cae, imagem_cae: null });
  };

  const handleClose = () => {
    setItem(null);
    setOpen(null);
  };

  //  ------------------   RENDER --------------------------------

  // Lado Esquerdo do detalhe da CAE
  const renderDriverInfo = () => {
    return (
      <S.ColumnWrapper>
        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={12}>
            <DropZoneArchives
              data={
                cae?.imagem_cae
                  ? {
                      imagem_url: cae?.imagem_cae,
                      legenda: '',
                    }
                  : null
              }
              multiple={false}
              fileDir="layout"
              addFunction={addImage}
              deleteFunction={delImage}
              automaticSave
              noTopButton
              unicCenter
            />
          </Grid>

          <Divider style={{ margin: '10px 10px 10px 10px', width: '100%' }} />

          <Grid item xs={6} sm={6}>
            <TextInput
              label="Motorista"
              onChange={e => handleChange('nome_motorista', e.target.value)}
              value={cae?.nome_motorista ? cae?.nome_motorista : ''}
              inputProps={{ readOnly: false }}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <TextInput
              id="placa"
              label="Placa do Veículo"
              value={cae?.placa ? cae?.placa : ''}
              onChange={e => handleChange('placa', e.target.value)}
              inputProps={{ readOnly: false }}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={4} sm={4}>
            <TextInput
              id="cliente"
              label="Cliente"
              value={posto?.distribuidora?.nome}
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={4} sm={4}>
            <TextInput
              id="transportadora"
              label="Transportadora"
              value={cae?.usuario.filial.empresa.nome}
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={4} sm={4}>
            <TextInput
              id="filial"
              label="Filial"
              value={cae?.usuario.filial.nome}
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextInput
              label="Descrição da Ocorrência"
              placeholder="Observação da Ocorrência"
              onChange={e => handleChange('observacao_cae', e.target.value)}
              multiline
              rows={4}
              value={cae?.observacao_cae}
              inputProps={{ readOnly: false }}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </S.ColumnWrapper>
    );
  };

  // Lado direito detalhe do desvio
  // Dados editáveis
  const renderCaeInfo = () => {
    const severidade = [1, 2, 3, 4];

    const getStatusColor = status => {
      switch (status) {
        case 'ABERTO':
          return theme.palette.semantics.feedback.warning.natural;
        case 'PENDENTE':
          return theme.palette.semantics.feedback.warning.natural;
        case 'FINALIZADO':
          return theme.palette.semantics.feedback.success.natural;
        case 'DELETADO':
          return theme.palette.semantics.feedback.attention.natural;
      }
    };

    return (
      <S.ColumnWrapper>
        <Grid container columnSpacing={2} rowSpacing="24px">
          <Grid item xs={6} sm={6}>
            <TextInput
              id="titulo"
              label="Título do Evento"
              value={cae?.questao?.abreviacao}
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>
          <Grid item xs={2} sm={2}>
            <TextInput
              id="codigo"
              label="Código"
              value={cae?.questao?.id}
              InputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={4} sm={4}>
            <TextInput
              id="situacao"
              label="Situação Atual"
              value={cae?.status}
              variant="filled"
              fillcolor={getStatusColor(cae?.status)}
              inputProps={{ readOnly: true }}
            />
          </Grid>

          <Grid item xs={6} sm={6}>
            <TextInput
              id="reportadoem"
              label="Reportado em"
              value={formatNewDate(new Date())}
              inputProps={{ readOnly: true }}
              startIcon={
                <CalendarTodayOutlined
                  sx={{ color: theme.palette.brand.primary.light }}
                />
              }
              variant="filled"
            />
          </Grid>

          <Grid item xs={6} sm={6}>
            <DataHour
              disabled={false}
              label="Data e hora do Ocorrido"
              value={cae?.data_ocorrencia}
              onChange={value => handleChange('data_ocorrencia', value)}
              variant="outlined"
              required
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextInput
              id="endereco"
              label="Localização / Endereço"
              value={cae?.posto?.endereco ? cae?.posto?.endereco : ''}
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <h3>Severidade do Evento</h3>
            <FormControl component="fieldset">
              <RadioGroup
                row
                value={cae?.questao?.severidade}
                style={{ justifyContent: 'space-between', paddingTop: '15px' }}
              >
                {severidade.map(item => (
                  <FormControlLabel
                    key={`${item}`}
                    value={item}
                    control={<Radio readOnly />}
                    label={item}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={6} sm={6}>
            <TextInput
              id="consequencia"
              label="Consequência"
              value={cae?.questao?.consequencia}
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={6} sm={6}>
            <TextInput
              id="data_limite"
              label="Data Limite Adequação"
              value={
                cae?.data_ocorrencia &&
                formatNewHour(
                  cae?.data_ocorrencia &&
                    addDays(
                      new Date(cae?.data_ocorrencia),
                      cae?.questao?.prazo_bloqueio,
                    ),
                )
              }
              startIcon={
                <CalendarTodayOutlined
                  sx={{ color: theme.palette.brand.primary.light }}
                />
              }
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>
        </Grid>
      </S.ColumnWrapper>
    );
  };

  return (
    <S.ModalMUI
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <S.Container>
        <S.Header>
          <div className="left">
            <AddCircleOutlineRoundedIcon
              htmlColor={theme.palette.brand.secondary.natural}
            />
            <p>Criar CAE</p>
          </div>
          <div className="right">
            <CloseRoundedIcon
              htmlColor={theme.palette.brand.primary.light}
              onClick={!loading && handleClose}
            />
          </div>
        </S.Header>

        <Divider style={{ width: '100%' }} />

        <S.Main>
          {renderDriverInfo()}
          {renderCaeInfo()}
        </S.Main>

        <Divider style={{ width: '100%' }} />

        <S.Footer>
          <GhostButton
            id="btnCancelar"
            size="medium"
            onClick={handleClose}
            style={{ marginLeft: '10px' }}
            disabled={loading}
          >
            CANCELAR
          </GhostButton>

          <DefaultButton
            id="btnSalvar"
            size="medium"
            onClick={() => handleCreateCae()}
            style={{ marginLeft: '10px' }}
            loading={loading}
          >
            SALVAR
          </DefaultButton>
        </S.Footer>
      </S.Container>
    </S.ModalMUI>
  );
};
