import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { useTheme } from 'styled-components';
import * as S from './styled';

const Stepper = ({ steps, currentStep }) => {
  const theme = useTheme();
  return (
    <S.Container>
      {steps &&
        steps.map(step => {
          return (
            <S.Box
              key={step.step}
              className={step.step !== 1 ? 'stepBox flex' : 'stepBox'}
              active={currentStep === step.step}
              passed={currentStep >= step.step}
            >
              {step.step !== 1 && (
                <div className="stepBar">
                  <div className="out" />
                  <div className="in" />
                </div>
              )}

              {currentStep > step.step && (
                <div className="infoArea">
                  <span className="number filled">
                    <CheckCircleRoundedIcon
                      style={{ width: '30px', height: '30px' }}
                      htmlColor={
                        theme.palette.semantics.feedback.success.natural
                      }
                    />
                  </span>

                  <span
                    className="title"
                    style={{
                      color: theme.palette.semantics.feedback.success.natural,
                    }}
                  >
                    {step.title}
                  </span>
                </div>
              )}

              {currentStep <= step.step && (
                <div className="infoArea">
                  <span className="number">{step.step}</span>
                  <span className="title">{step.title}</span>
                </div>
              )}
            </S.Box>
          );
        })}
    </S.Container>
  );
};

export default Stepper;
