import React, { useEffect, useState } from 'react';
import * as S from './styled';
import { saveConfig, getConfig } from '../services';
import { useQuery } from 'react-query';
import { usePlans } from 'hooks/usePlans';
import { useNavigate } from 'react-router';

// components
import { Switch } from 'components/Inputs/Switch';
import Divider from '@mui/material/Divider';
import Tabs from 'components/Tabs';
import { toast } from 'react-toastify';

const Configuracao = () => {
  const navigate = useNavigate();
  const { isProvider } = usePlans();
  const [currentTab, setCurrentTab] = useState(1);
  const [dataConfig, setDataConfig] = useState([]);
  const [dataConfigProvider, setDataConfigProvider] = useState([]);
  const [selectedButtons, setSelectedButtons] = useState({
    ativo: false,
    obrigatorio: false,
  });

  const [currentContent, setCurrentContent] = useState({});
  const [tabs, setTabs] = useState([]);

  useQuery(['config-capacitacao'], () => getConfig(), {
    refetchOnWindowFocus: false,
    onSuccess: data => {
      const provider = data.data.provider;
      const dataConfig = data.data.data || [];
      setDataConfig(dataConfig);
      setDataConfigProvider(provider);
      if (provider) {
        setTabs(
          provider.map(item => ({
            value: item.distribuidora.id,
            label: item.distribuidora.nome,
            status: item.distribuidora.nome,
          })),
        );
      }

      setSelectedButtons(prevState => ({
        ativo: data.data.data.ativo,
        obrigatorio: data.data.data.obrigatorio,
      }));
    },
  });

  const handleSaveConfig = async config => {
    const res = await saveConfig(dataConfig.id_empresa, config);
    if (res.success) {
      toast.success('Configurações salvas com sucesso');
    }
  };

  const handleButtonClick = value => {
    const newConfig = {
      ...selectedButtons,
      obrigatorio: value,
    };
    setSelectedButtons(newConfig);
    handleSaveConfig(newConfig);
  };

  const switchChange = () => {
    const newConfig = {
      ...selectedButtons,
      ativo: !selectedButtons.ativo,
    };
    setSelectedButtons(newConfig);
    handleSaveConfig(newConfig);
  };

  useEffect(() => {
    if (dataConfigProvider?.length > 0) {
      const current = dataConfigProvider.find(
        item => item.distribuidora.id === currentTab,
      );
      setCurrentContent(current);
    }
  }, [currentTab]);

  return (
    <S.Main>
      <div className="title">Automações</div>
      <Divider />
      <div className="container-swith">
        <div>
          <Switch
            check={selectedButtons.ativo}
            setCheckbox={() => switchChange()}
            textOff={
              <div className="style-text-sw">
                Enviar conteúdos de forma automática
              </div>
            }
            textOn={
              <div className="style-text-sw">
                Enviar conteúdos de forma automática
              </div>
            }
          />
        </div>
        <div className="container-btn">
          <div
            className={`btn ${!selectedButtons.obrigatorio ? 'selected' : ''}`}
            onClick={() => handleButtonClick(false)}
          >
            Enviar conteúdos
          </div>
          <div
            className={`btn ${selectedButtons.obrigatorio ? 'selected' : ''}`}
            onClick={() => handleButtonClick(true)}
          >
            Enviar conteúdos obrigatórios
          </div>
        </div>
      </div>
      <div className="content">
        <div className="subtitle">Com funciona o disparo automático?</div>
        <div className="text">
          O disparo acontece no início de cada mês, levando em conta a pontuação
          do mês anterior. Motoristas que ficaram acima da meta de pontos
          configurada por você na sessão de configurações irão receber um
          conteúdo automático referente ao maior fator de risco dele naquele
          mês.
        </div>
        <div className="container-btn-meta">
          <div className="btn-meta">Meta de ptos/km: 10</div>
          <div className="btn-meta">Meta de ptos/hr: 12</div>
          <div
            className="btn-meta2"
            onClick={() => navigate('/configuracoes/configuracoes')}
          >
            IR PARA CONFIGURAÇÕES
          </div>
        </div>
      </div>
      {!isProvider && (
        <div className="container-tabs">
          <Tabs
            items={tabs}
            onChange={(_, val) => setCurrentTab(val)}
            value={currentTab}
          />
          <Divider />

          <div className="container-swith">
            <div>
              <Switch
                check={currentContent?.ativo}
                setCheckbox={() => {}}
                textOff={
                  <div className="style-text-sw">
                    Enviar conteúdos de forma automática
                  </div>
                }
                textOn={
                  <div className="style-text-sw">
                    Enviar conteúdos de forma automática
                  </div>
                }
              />
            </div>
            <div className="container-btn">
              <div className={`btn2 ${currentContent.ativo ? 'selected' : ''}`}>
                Enviar conteúdos
              </div>
              <div
                className={`btn2 ${
                  currentContent.obrigatorio ? 'selected' : ''
                }`}
              >
                Enviar conteúdos obrigatórios
              </div>
            </div>
          </div>
        </div>
      )}
    </S.Main>
  );
};

export default Configuracao;
