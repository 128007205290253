import { Divider } from '@mui/material';
import { useSelector } from 'react-redux';

// Columns
import Table from 'components/Table';
import { formatNewHour } from 'utils/dates';
import * as S from './styled';
import {
  columnsTotal,
  columnsApprove,
  columnsOcurrence,
  columnsSmall,
} from './columns';

import { format } from 'date-fns';

export const ExportFormPdf = ({ form, data, printRef, period, noCards }) => {
  let cards = data?.cards || [];
  let dataTable = data?.rows || [];

  // Redux
  const formularios = useSelector(state => state.formularios);

  const gerais = form.gerais;
  const hasRepet = formularios?.gerais?.repetir;

  const logo = require('images/onisys.png');
  const capa = require(`images/defaultFormulario.jpg`);

  const { user } = useSelector(state => state.auth.user);
  const comapnyLogo = user?.filial?.empresa?.logo_url;

  const limit = value => {
    if (value?.length > 15) {
      return `${value?.toLowerCase().slice(0, 13)}...`;
    }
    return value?.toLowerCase();
  };

  // -------------------------------------------------FORMAT TABLE--------------------------------------------------//
  const formatTable = arr => {
    arr.forEach(i => {
      if (i.identifier === 'nome') {
        i.Cell = function ({ row }) {
          return (
            <S.ColDriver>
              <S.Paragraph className="normal name">
                {row?.original?.aluno?.nome?.toLowerCase()}
              </S.Paragraph>
              <S.Paragraph className="small">
                Filial: {row?.original?.aluno?.filial?.nome}
              </S.Paragraph>
            </S.ColDriver>
          );
        };
      } else if (i.identifier === 'status') {
        i.Cell = function ({ row }) {
          return (
            <S.ColDriver>
              {row.original.concluido && (
                <>
                  <S.Paragraph className="green">Concluído</S.Paragraph>
                  <S.Paragraph className="small" center>
                    Data: {formatNewHour(row.original.data_concluido)}
                  </S.Paragraph>
                </>
              )}
              {!row.original.concluido && !row.original.justificado && (
                <>
                  <S.Paragraph
                    className={
                      row.original.concluido === null ? 'red' : 'orange'
                    }
                  >
                    {row.original.concluido === null ? 'Pendente' : 'Iniciado'}
                  </S.Paragraph>
                  <S.Paragraph className="small" center>
                    {row.original.concluido === null
                      ? 'Aguardando início'
                      : 'Aguardando conclusão'}
                  </S.Paragraph>
                </>
              )}

              {!row.original.concluido && row.original.justificado && (
                <>
                  <S.Paragraph className="grey">Justificado</S.Paragraph>
                  <S.Paragraph className="small" center>
                    {limit(row.original.justificativa)}
                  </S.Paragraph>
                </>
              )}
            </S.ColDriver>
          );
        };
      } else if (i.identifier === 'ocorrencias') {
        i.Cell = function ({ row }) {
          return (
            <S.ColDriver>
              <S.Paragraph className="normal" center>
                {row?.original?.ocorrencias?.total}
              </S.Paragraph>
            </S.ColDriver>
          );
        };
      } else if (i.identifier === 'contador') {
        i.Cell = function ({ row }) {
          return (
            <S.ColDriver>
              <S.Paragraph className="normal" center>
                {row?.original?.contador}
              </S.Paragraph>
            </S.ColDriver>
          );
        };
      } else if (i.identifier === 'aprovado') {
        i.Cell = function ({ row }) {
          return (
            <S.ColDriver>
              <S.Paragraph
                className={row?.original?.data_assinatura ? 'green' : 'orange'}
              >
                {formatNewHour(row?.original?.data_assinatura) ||
                  'Não aprovado'}
              </S.Paragraph>
              <S.Paragraph className="small cap" center>
                Aprovador:{' '}
                {formtApproveName(
                  row.original?.formulario?.responsavel_aprovador?.nome,
                ) || '-'}
              </S.Paragraph>
            </S.ColDriver>
          );
        };
      }
    });
    return arr;
  };

  const formatTypeColumns = () => {
    if (gerais?.aprovador && gerais?.ocorrencias) {
      return columnsTotal;
    }
    if (gerais?.aprovador) {
      return columnsApprove;
    }
    if (gerais?.ocorrencias) {
      return columnsOcurrence;
    }
    return columnsSmall;
  };

  const formtApproveName = nome => {
    if (nome?.length > 15) {
      return `${nome?.toLowerCase().slice(0, 13)}...`;
    }
    return nome?.toLowerCase();
  };

  return (
    <S.ModalMUI open={false} onClose={() => {}} disableEscapeKeyDown>
      <S.Container ref={printRef}>
        <S.Infos>
          <div className="logoArea">
            <img src={logo} />
          </div>
          <div className="formIdent">
            <span>Formulários - ID {form?.id}</span>
            {period && (
              <span>
                Período {format(new Date(period[0]), 'dd/MM/yy')} -{' '}
                {format(new Date(period[1]), 'dd/MM/yy')}
              </span>
            )}
          </div>
        </S.Infos>
        <S.CardHeader>
          <h1>Informações da Ocorrência</h1>

          <S.Header bg={gerais?.capa || capa}>
            <div className="topInfos">
              <div className="coverArea">
                <div className="background" />
              </div>
              <div className="textsArea">
                <div className="titleForm">{gerais?.titulo}</div>
                <div className="descriptionForm">{gerais?.descricao}</div>
              </div>
            </div>
            <Divider />
            {!noCards && (
              <div className="bottomInfos">
                <div className="card">
                  {/* <div className="logoCompany">
                  <img src={comapnyLogo} />
                </div> */}
                  <div className="cardInfos">
                    <div className="title">Usuários que finalizaram</div>
                    <div className="numbers">
                      {cards[0]?.value || 0}/{cards[0]?.turmas || 0}
                    </div>
                  </div>
                </div>
                {form?.gerais?.aprovador && (
                  <>
                    <div className="bar" />
                    <div className="card">
                      <div className="cardInfos">
                        <div className="title">Pendentes de aprovação</div>
                        <div className="numbers">{cards[1]?.value || 0}</div>
                      </div>
                    </div>
                  </>
                )}
                <div className="bar" />
                <div className="card">
                  <div className="cardInfos">
                    <div className="title">Não concluídos</div>
                    <div className="numbers">{cards[2]?.value || 0}</div>
                  </div>
                </div>
                <div className="bar" />
                <div className="card">
                  <div className="cardInfos">
                    <div className="title">Justificados</div>
                    <div className="numbers">{cards[3]?.value || 0}</div>
                  </div>
                </div>
              </div>
            )}
          </S.Header>
        </S.CardHeader>

        {/* <S.TableArea> */}
        <S.CardHeader>
          <h1>Assinaturas</h1>
          <div class="container">
            <Table
              inputLabel=""
              data={dataTable || []}
              columns={formatTable(formatTypeColumns())}
              pageCount={data?.count || 0}
              fetchData={() => {}}
              updated={false}
              query={{}}
              initialSortBy={[{ id: 'status', desc: false }]}
              permitIsSortedOccur
              invisibleSelect
              tableType
              empty={{
                title: hasRepet
                  ? 'Ops! Você não tem nenhuma resposta para esse período'
                  : 'Ops! Você não tem nenhuma resposta para esse formulário',
                subtitle: hasRepet
                  ? 'Verifique o período selecionado'
                  : 'Aguarde até que os alunos iniciem as respostas',
              }}
            />
          </div>
          {/* <div className="coverInputBottom" /> */}
        </S.CardHeader>
        {/* </S.TableArea> */}
      </S.Container>
    </S.ModalMUI>
  );
};
