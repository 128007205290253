import styled, { css } from 'styled-components';

export const QuestionBox = styled.div`
  ${({ theme, hasError }) => css`
    padding: 20px;
    background-color: ${hasError
      ? theme.palette.semantics.feedback.attention.title
      : theme.palette.system.highlight};
  `}
`;

export const ResponseArea = styled.div`
  ${({ theme, preview, hasError, isChecked }) => css`
    margin-top: 18px;
    display: flex;
    width: 100%;
    min-height: 28px;
    background-color: ${hasError
      ? theme.palette.semantics.feedback.attention.title
      : theme.palette.system.transparent};

    .iconsBox {
      margin-left: 50px;
      opacity: ${isChecked ? 1 : 0};
      transition: all ease-in 0.3s;
      pointer-events: ${isChecked ? 'all' : 'none'};
      display: ${preview ? 'none' : 'block'};
    }
  `}
`;
