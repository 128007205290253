import api from 'services/api';

export const viewMidia = async id => {
  const res = await api.put(`/training/content/read-midia/${id}`);
  return res.data;
};

export const responseQuestion = async data => {
  const res = await api.post(`/training/content/answer-question`, data);
  return res.data;
};

export const evaluateContent = async (id, data) => {
  const res = await api.put(`/training/content/evaluate/${id}`, data);
  return res.data;
};

export const tryNewTest = async id => {
  const res = await api.post(`/training/content/new-try/${id}`);
  return res.data;
};
