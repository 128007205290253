import styled, { css } from 'styled-components';
import { Button } from '@mui/material';

export const Container = styled.div``;

export const Main = styled.div`
  display: flex;
`;

export const Header = styled.div`
  padding: 22px 0px 0px 0px;
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    font-size: 24px;
    color: ${theme.palette.brand.primary.natural};
    margin-top: -15px;
    margin-bottom: 5px;
    font-weight: 900;
    letter-spacing: 1.1px;
  `}
`;

export const TableTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const TableLegend = styled.div`
  ${({ color }) => css`
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: ${color};
  `}
`;

export const TableName = styled.div`
  ${({ theme, center }) => css`
    color: ${theme.palette.words.title.natural};
    text-transform: capitalize;
    font-weight: 600;
    font-size: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: ${center ? 'center' : 'start'};
  `}
`;

export const Points = styled.div`
  ${({ theme, red }) => css`
    width: 100%;
    max-width: 180px;
    color: ${red
      ? theme.palette.semantics.feedback.attention.natural
      : theme.palette.semantics.feedback.success.natural};
    background-color: ${red
      ? theme.palette.semantics.feedback.attention.light
      : theme.palette.semantics.feedback.success.light};
    border-radius: 30px;
  `}
`;

export const StyledButton = styled(Button)`
  && {
    text-transform: none;
    color: ${props => props.textcolor};
    background: ${props => props.backgroundcolor};
    border: 2px solid ${props => props.theme.palette.brand.primary.light};
    font: normal normal 400 16px/24px Texta;
    height: ${props => props.height || '38px'};
    padding: 5px 15px;
    transition: opacity 300ms;
    box-shadow: none;

    :hover {
      background: ${props => props.backgroundcolor};
      border-color: black;
      opacity: 0.7;
    }

    :disabled {
      background: ${props => props.theme.palette.system.disabled};
      opacity: 0.5;
    }
  }
`;

export const Count = styled.p`
  ${({ theme, ...props }) => css`
    font: normal normal 900 12px/16px Texta;
    color: ${theme.palette.words.text.contrast};
    display: ${props.count ? 'block' : 'none'};
    background: ${theme.palette.brand.primary.natural};
    width: 16px;
    border-radius: 8px;
    position: absolute;
    top: -3px;
    right: -3px;
  `}
`;

export const BoxLoading = styled.div`
  margin-left: -2rem;
  transform: scale(0.7);
`;

export const MapContainer = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
  position: relative;

  .map {
    height: 450px;
  }
`;

export const MapHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
`;

export const MapFilters = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: 0.5rem;

    #searchForm input {
      padding: 10px;
      color: ${theme.palette.words.label.light};
      opacity: 1;
    }

    #searchForm fieldset {
      border: 1px solid ${theme.palette.system.divider};
      border-radius: 4px;
      padding: 10px;
    }
  `}
`;

export const MapActions = styled.div`
  display: flex;
  gap: 0.5rem;
`;
