import api from 'services/api';

export const getEmpresas = async () => {
  const response = await api.get('/provider/empresas');
  return response.data;
};

export const updateEmpresa = async (id, data) => {
  const response = await api.put(`/empresas/${id}/contato`, { data });
  return response.data;
};
