// Styles

// React
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';

// Components
import { Checkbox } from '@mui/material';
import Loading from 'components/Loading';
import Select from 'components/Inputs/Select';
import Calendar from 'components/Inputs/Calendar';
import TextInput from 'components/Inputs/TextField';

// Utils
import { brStates } from 'utils/brazilianStates';

// Lib
import NumberFormat from 'react-number-format';
import { useNavigate } from 'react-router-dom';
import { tipos_contratacao, tipos_tempo_experiencia } from './constants';
import * as services from '../services';
import * as S from './styled';

export const Detalhes = ({
  trySave,
  setTrySave,
  setChanged,
  vagaId,
  setVagaId,
  data,
  setData,
  isEdit,
}) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const changingMonitor = (key, value) => {
    const newData = { ...data };
    newData[key] = value;
    setData(newData);
    setChanged(true);
  };

  // City and UF Controller
  const [cidades, setCidades] = useState([]);
  const [filterCity, setFilterCity] = useState([]);

  useEffect(() => {
    const getDistricts = async () => {
      const res = await services.cityComplet();
      const cts = res.map(item => ({
        name: item.nome,
        value: item.nome,
        uf: item.municipio.microrregiao.mesorregiao.UF.sigla,
      }));
      setCidades(cts);
      currentCity(cts);
    };
    getDistricts();
  }, []);

  useEffect(() => {
    currentCity(cidades);
  }, [data.uf]);

  const currentCity = cds => {
    const newCity = [];
    const names = [];
    for (const i in cds) {
      if (cds[i].uf === data.uf && !names.includes(cds[i].name)) {
        newCity.push(cds[i]);
        names.push(cds[i].name);
      }
    }
    setFilterCity(newCity);
  };

  // -------------------------------------HANDLE SAVE------------------------------------------//

  useEffect(() => {
    if (trySave) {
      const dataSend = {
        secao: 'Detalhes',
        uf: data.uf,
        cidade: data.cidade,
        quantidade: Number(data.quantidade),
        tipo_contratacao: data.tipo_contratacao,
        data_inicio: data.data_inicio,
        data_fim: data.data_fim,
        tempo_experiencia: data.tempo_experiencia,
        salario: data.salario,
        ocultar: data.ocultar,
      };

      if (!data.uf) {
        toast.error('Selecione um estado');
        setTrySave(false);
        return;
      }

      if (!data.cidade) {
        toast.error('Selecione uma cidade');
        setTrySave(false);
        return;
      }

      if (!data.data_inicio) {
        toast.error('Selecione uma data de início');
        setTrySave(false);
        return;
      }

      if (!data.data_fim) {
        toast.error('Selecione uma data final');
        setTrySave(false);
        return;
      }

      if (new Date(data.data_inicio) > new Date(data.data_fim)) {
        toast.error('A data inicial não pode ser maior que a final');
        setTrySave(false);
        return;
      }

      saveInfos(dataSend);
    }
  }, [trySave]);

  const saveInfos = async dataSend => {
    setLoading(true);
    const res = await services.updateVacancy(dataSend, vagaId);

    if (res.success) {
      toast.success(res.message);
      setTrySave(false);
      setChanged(false);
      setVagaId(res.id);
      if (!isEdit) {
        navigate('/contratacao/vagas');
      }
    } else {
      setTrySave(false);
      toast.error(res.message);
    }

    setLoading(false);
  };

  return (
    <>
      {!loading && (
        <S.Box>
          <div className="unicSelect">
            <span className="little">
              <Select
                label="Estado"
                data={brStates}
                value={data.uf || ''}
                handleChange={value =>
                  changingMonitor('uf', value.target.value, true)
                }
                required
              />
            </span>
            <span className="bigger">
              <Select
                label="Cidade"
                data={filterCity}
                disabled={!data.uf}
                value={filterCity.length > 0 ? data.cidade || '' : ''}
                handleChange={value =>
                  changingMonitor('cidade', value.target.value)
                }
                required
              />
            </span>
          </div>
          <div className="container">
            <TextInput
              label="Quantidade de vagas"
              data={[]}
              type="number"
              value={data.quantidade || ''}
              onChange={e => changingMonitor('quantidade', e.target.value)}
            />
            <Select
              label="Tipo de contratação"
              data={tipos_contratacao.map(item => ({
                name: item,
                value: item,
              }))}
              value={
                data.tipo_contratacao === 'tempo_integral'
                  ? 'Tempo integral'
                  : data.tipo_contratacao || ''
              }
              handleChange={value =>
                changingMonitor('tipo_contratacao', value.target.value)
              }
            />
          </div>
          <div className="container">
            <Calendar
              value={new Date(data.data_inicio)}
              onChange={e => changingMonitor('data_inicio', e)}
              futureDate
              pastDate={false}
              label="Data de início"
              style={{ maxWidth: '100%' }}
              required
            />

            <Calendar
              value={new Date(data.data_fim)}
              minDate={new Date(data.data_inicio)}
              onChange={e => changingMonitor('data_fim', e)}
              futureDate
              pastDate={false}
              label="Data de finalização"
              style={{ maxWidth: '100%' }}
              required
            />
          </div>
          <div className="container">
            {tipos_tempo_experiencia && (
              <Select
                label="Tempo de experiência"
                data={tipos_tempo_experiencia.map(item => ({
                  name: item,
                  value: item,
                }))}
                value={
                  data.tempo_experiencia !== '0'
                    ? data.tempo_experiencia || ''
                    : ''
                }
                handleChange={value =>
                  changingMonitor('tempo_experiencia', value.target.value)
                }
              />
            )}

            <label className="boxPayment">
              <span>Salário</span>
              <NumberFormat
                value={data.salario || ''}
                prefix="R$"
                decimalSeparator=","
                allowedDecimalSeparators={[',']}
                decimalScale={2}
                displayType="input"
                type="text"
                thousandSeparator="."
                allowNegative={false}
                onValueChange={e =>
                  changingMonitor('salario', e.value.replace('.', ','))
                }
                placeholder="R$"
                maxLength={13}
                className="InputTextLibary"
              />
            </label>
          </div>

          <div className="container">
            <label style={{ cursor: 'pointer' }}>
              <Checkbox
                checked={data.ocultar}
                onChange={() => changingMonitor('ocultar', !data.ocultar)}
              />
              <span>Empresa oculta</span>
            </label>
          </div>
        </S.Box>
      )}
      {loading && (
        <S.LoadingBox>
          <Loading />
        </S.LoadingBox>
      )}
    </>
  );
};
