import styled from 'styled-components';

export const TableContainer = styled.div`
  width: 100%;
  margin-top: 16px;
  border: 1px solid #95aac94d;
  border-radius: 4px;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    padding: 8px;
    border-bottom: 1px solid #ddd;
    text-align: left;
  }

  th {
    background-color: #edf2f9;
    text-transform: uppercase;
  }

  tr:nth-child(even) {
    background-color: #edf2f9;
  }
`;
