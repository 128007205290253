// Pages
import TurmasFiscalizacao from 'pages/Fomularios/Turmas';
import AlunosFiscalizacao from 'pages/Fomularios/Alunos';
import ViewTurmaFiscalizacao from 'pages/Fomularios/Turmas/viewTurma';

export default {
  '/fiscalizacao/turmas': {
    back: -1,
    title: 'Alunos e Turmas',
    id: true,
    defaults: [],
    page: <TurmasFiscalizacao />,
  },

  '/fiscalizacao/turmas/criar': {
    back: -1,
    title: 'Alunos e Turmas',
    id: true,
    defaults: [],
    page: <TurmasFiscalizacao />,
  },

  '/fiscalizacao/viewTurmas/:id': {
    back: -1,
    title: 'Adicionar alunos',
    subTitle: 'Turmas',
    id: true,
    defaults: [],
    page: <ViewTurmaFiscalizacao />,
  },

  '/fiscalizacao/alunos': {
    back: -1,
    title: 'Alunos e Turmas',
    id: true,
    defaults: [],
    page: <AlunosFiscalizacao />,
  },
};
