import styled, { keyframes } from 'styled-components';

const jump = keyframes`
    0% {
        padding-top: 0px;
    }
    50% {
        padding-top: 4px;
    }
    100% {
        padding-top: 0px;
    }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const SeeMore = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 20px;
  height: 50px;

  .textSeeMore {
    font-size: 18px;
    font-weight: 600;
    color: #4b5166;
    cursor: pointer;
  }

  .divIcon {
    background-color: red;
    animation: ${jump} 1s ease infinite;
    position: relative;
    display: flex;
    justify-content: center;

    .icon {
      cursor: pointer;
      position: absolute;
    }
  }
`;
