import styled, { css } from 'styled-components';

const ContainerModifier = {
  checked: ({ theme }) => css`
    border: 1px solid ${theme.palette.brand.secondary.natural};
    background-color: ${theme.palette.brand.secondary.natural};
  `,
};

export const Container = styled.div`
  ${({ theme, checked }) => css`
    width: 24px;
    height: 24px;
    border: 1px solid ${theme.palette.words.subtitle.light};
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    ${checked && ContainerModifier.checked({ theme })}

    .MuiSvgIcon-root {
      font-size: 18px;
      color: ${theme.palette.words.text.contrast};
      opacity: ${checked ? 1 : 0};
    }
  `}
`;

export const Input = styled.input`
  width: 100%;
  height: 100%;
  display: none;
`;
