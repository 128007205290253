/* eslint-disable import/order */

/// Styles
import * as S from './styled';

// Components MUI
import { Tooltip } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

// Context
import { useTable } from '../../../context';
import { _setTable } from '../../../context/actions';

export const TH = ({
  children,
  sort,
  up,
  down,
  access,
  selection,
  loading,
  align = 'left',
  description,
}) => {
  const { dispatch } = useTable();

  const toggleSort = () => {
    if (sort && !loading && access) {
      dispatch(
        _setTable({
          sortBy: {
            id: access,
            order: up ? 'DESC' : 'ASC',
            desc: up,
          },
          pageIndex: 0,
        }),
      );
    }
  };

  return (
    <S.Container
      align={align}
      hover={sort}
      onClick={toggleSort}
      isLoading={loading}
      data-testid="th"
    >
      <S.TH selection={selection}>{children}</S.TH>
      {sort && (
        <S.IconsContainer visible={sort} data-testid="sort">
          <ArrowDropUpIcon className={up ? 'up active' : 'up'} />
          <ArrowDropDownIcon className={down ? 'down active' : 'down'} />
        </S.IconsContainer>
      )}
      {description && (
        <td className="descriptor">
          <Tooltip title={description} placement="top">
            <InfoOutlinedIcon data-testid="description" />
          </Tooltip>
        </td>
      )}
    </S.Container>
  );
};
