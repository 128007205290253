import * as types from './types';

// Atenção, não persistir esse redux

const INITIAL_STATE = [];

export default function companies(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.SET_COMPANIES: {
      return [...action.payload];
    }
    default:
      return state;
  }
}
