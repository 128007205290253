import {
  getStatusEmpresa,
  getVeiculos,
  getMotoristas,
  getDesviosTiposPadrao,
} from 'constants/_SERVICES/provider';

// PAGES
import Empresas from 'pages/Provider/Empresas';
import LogEmpresa from 'pages/Provider/Empresas/History';
import { PerfilEmpresa } from 'pages/Provider/Empresas/PerfilEmpresa';
import RankingEmpresas from 'pages/Provider/RankingEmpresas';
import { EmpresasEstatisticas } from 'pages/Estatisticas/Empresas';

export default {
  '/empresas': {
    date: true,
    defaults: [], // ['operations'],
    pageFilterName: 'filterEmpresaProvider',
    page: <Empresas />,
  },
  '/empresas/:id': {
    title: 'Perfil da Empresa',
    back: -1,
    defaults: [],
    page: <PerfilEmpresa />,
  },

  '/logs/empresas/:id': {
    back: -1,
    defaults: [],
    page: <LogEmpresa />,
  },

  '/empresas/ranking': {
    date: true,
    title: 'Ranking Empresas',
    defaults: ['empresas', 'operations'],
    pageFilterName: 'filterEmpresasRankingProvider',
    filters: [
      {
        name: 'desvio',
        placeholder: 'Filtrar por Desvio',
        section: 'Desvio, Veículo e Motorista',
        mode: 'multiple',
        data: async () => getDesviosTiposPadrao(),
      },
      {
        name: 'veiculo',
        placeholder: 'Filtrar por Veículo',
        section: 'Desvio, Veículo e Motorista',
        mode: 'single',
        data: async () => getVeiculos(),
      },
      {
        name: 'motoristas',
        placeholder: 'Filtrar por Tipo de Motorista',
        section: 'Desvio, Veículo e Motorista',
        mode: 'single',
        data: async () => getMotoristas(),
      },
    ],
    page: <RankingEmpresas />,
  },
  '/empresas/estatisticas': {
    title: 'Estatísticas',
    back: -1,
    defaults: [],
    page: <EmpresasEstatisticas />,
  },
};
