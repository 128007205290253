import styled, { css } from 'styled-components';
import { Pagination } from '@mui/material';

export const HeaderPage = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .rightBox {
    display: flex;
    align-items: center;

    .buttonRanking {
      margin-right: 10px;
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    }
  }
`;

export const Table = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.palette.system.highlight};
    border-radius: 4px;
    width: calc(100vw - 135px);
  `}
`;

export const TableLegends = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 80px;
    background-color: ${theme.palette.system.highlight};
    border: 1px solid ${theme.palette.semantics.feedback.unknown.light};
    border-bottom: none;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 30px;

    .inputArea {
      position: relative;
      display: flex;
      align-items: center;

      .icon {
        position: absolute;
        margin-left: 10px;
      }
    }

    .legendArea {
      display: flex;

      @media (max-width: 1003px) {
        display: none;
      }
    }

    @media (max-width: 1053px) {
      padding: 0px 10px;
    }
  `}
`;

export const Input = styled.input`
  ${({ theme }) => css`
    width: 280px;
    padding: 10px 10px 10px 36px;
    outline: none;
    background-color: transparent;
    border: 1px solid ${theme.palette.semantics.feedback.unknown.light};
    border-radius: 6px;
    font-size: 16px;
    color: ${theme.palette.words.text.light};
    background-color: ${theme.palette.brand.primary.background};

    &::placeholder {
      color: ${theme.palette.words.text.light};
      font-weight: 500;
    }
  `}
`;

export const LegendItem = styled.div`
  display: flex;
  align-items: center;
  margin: 0px 10px;

  .item {
    font-size: 14px;
    font-weight: 600;
    color: ${props => props.color};
    margin-left: 5px;
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 1233px) {
    .item {
      font-size: 12px;
    }
  }

  @media (max-width: 1162px) {
    .icon {
      display: none;
    }
  }
`;

export const TableHeader = styled.div`
  ${({ theme }) => css`
    height: 50px;
    width: 100%;
    background-color: ${theme.palette.brand.primary.background};
    display: flex;
    align-items: center;
    padding: 0px 20px;
    font-weight: 600;
    color: ${theme.palette.words.text.light};
    text-transform: uppercase;
    font-size: 15px;
    position: relative;

    .headerMotoristas {
      flex: 2;
      display: flex;
      align-items: center;

      .column {
        cursor: pointer;
      }

      .arrows {
        display: flex;
        flex-direction: column;
        cursor: pointer;

        .top {
          margin-bottom: -7px;
        }

        .bottom {
          margin-top: -7px;
        }
      }
    }

    .headerMes {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 6;

      .circleArrow {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 30px;
        background-color: ${theme.palette.system.highlight};
        cursor: pointer;

        &.left {
          margin-right: 10px;
        }

        &.right {
          margin-left: 10px;
        }
      }

      .currentMonths {
        width: 250px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .backCurrent {
      font-size: 14px;
      position: absolute;
      right: 0;
      margin-right: 20px;
      color: ${theme.palette.words.subtitle.natural};
      text-transform: lowercase;
      pointer-events: none;
      opacity: 0;
      transition: all ease 0.5s;

      &.visible {
        cursor: pointer;
        pointer-events: all;
        opacity: 1;
      }

      &::first-letter {
        text-transform: uppercase;
      }

      &:hover {
        opacity: 0.7;
      }
    }

    @media (max-width: 871px) {
      .backCurrent {
        display: none;
      }
    }
  `}
`;

export const TableBody = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    border: 1px solid ${theme.palette.semantics.feedback.unknown.light};
    border-top: none;
    border-bottom: none;
  `}
`;

export const Motoristas = styled.div`
  ${({ theme, ...props }) => css`
    flex: 2;
    border-right: 1px solid ${theme.palette.semantics.feedback.unknown.light};
    margin-left: 15px;

    .motoristaItem {
      display: flex;
      align-items: center;
      height: ${props.heightLine};

      .circleIdentifier {
        width: 35px;
        height: 35px;
        border: 1px solid ${theme.palette.brand.secondary.natural};
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;

        .firstLetter {
          width: 30px;
          height: 30px;
          border-radius: 50px;
          background-color: ${theme.palette.brand.primary.background};
          font-weight: 600;
          display: flex;
          justify-content: center;
          align-items: center;
          color: ${theme.palette.words.title.natural};
        }

        .profile {
          width: 28px;
          border-radius: 30px;
        }
      }

      .motoristaName {
        margin-left: 10px;
        font-weight: 600;
        color: ${theme.palette.words.title.natural};
      }
    }
  `}
`;

export const Cronograma = styled.div`
  ${({ theme, ...props }) => css`
    flex: 6;
    overflow-x: hidden;

    .limitDriverBox {
      width: calc(100% * 4);
      margin-left: -${props.currentMargin - 800}%;
      display: flex;
      height: ${props.heightLine};
      transition: all ease 0.5s;

      .evaluationsLine {
        width: calc(100% / 12);
        flex: 1;
        display: flex;
        border-right: 1px solid
          ${theme.palette.semantics.feedback.unknown.light};
        padding: 0px 10px;

        .seeMore {
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }

        .evaluation {
          flex: 1;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          .iconContainer {
            width: 45px;
            height: 45px;
            border: 1px solid ${theme.palette.semantics.feedback.unknown.light};
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            cursor: pointer;

            &:hover {
              opacity: 0.7;
            }

            &.opc {
              opacity: 0.5;
              cursor: not-allowed;
            }
          }
        }
      }
    }

    @media (max-width: 1325px) {
      flex: 4;

      .limitDriverBox {
        width: calc(100% * 6);
        margin-left: -${props.currentMargin - 600}%;
      }
    }

    @media (max-width: 920px) {
      flex: 3;

      .limitDriverBox {
        width: calc(100% * 12);
        margin-left: -${props.currentMargin}%;
      }
    }
  `}
`;

export const MonthLines = styled.div`
  ${({ theme, ...props }) => css`
    display: flex;
    width: 100%;
    border: 1px solid ${theme.palette.semantics.feedback.unknown.light};
    border-top: none;
    border-bottom: none;

    .notUsed {
      flex: 2;
      margin-left: 15px;
      border-right: 1px solid ${theme.palette.semantics.feedback.unknown.light};
    }

    .containerMonths {
      flex: 6;
      overflow-x: hidden;

      .monthsList {
        display: flex;
        width: calc(100% * 4);
        margin-left: -${props.currentMargin - 800}%;
        transition: all ease 0.5s;

        .month {
          width: calc(100% / 12);
          flex: 1;
          font-weight: 600;
          color: ${theme.palette.words.subtitle.natural};
          font-size: 15px;
          text-transform: uppercase;
          padding: 10px 20px;
          border-right: 1px solid
            ${theme.palette.semantics.feedback.unknown.light};
        }
      }
    }

    @media (max-width: 1325px) {
      .containerMonths {
        flex: 4;

        .monthsList {
          width: calc(100% * 6);
          margin-left: -${props.currentMargin - 600}%;
        }
      }
    }

    @media (max-width: 920px) {
      .containerMonths {
        flex: 3;

        .monthsList {
          width: calc(100% * 12);
          margin-left: -${props.currentMargin}%;
        }
      }
    }
  `}
`;

export const PopoverBox = styled.div`
  ${({ theme, ...props }) => css`
    padding: 10px 20px;
    width: 300px;
    background-color: ${theme.palette.system.overlay};
    border-radius: 5px;

    .topArea {
      display: flex;
      align-items: center;

      .evaluationId {
        margin-left: 10px;
        font-weight: 600;
        color: ${theme.palette.words.title.natural};
        font-size: 18px;
      }
    }

    .medium {
      .infos {
        display: flex;
        flex-direction: column;
        margin: 10px;

        .week {
          font-weight: 500;
          color: ${theme.palette.words.title.natural};
          font-size: 14px;
        }

        .data {
          font-weight: 500;
          color: ${theme.palette.words.title.natural};
          font-size: 14px;
        }
      }
    }

    .bottomArea {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .statusBox {
        text-transform: capitalize;
        color: ${props.color};
        background-color: ${props.bg};
        font-weight: 600;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        width: 100px;
      }

      .viewBox {
        font-size: 14px;
        font-weight: 600;
        color: ${theme.palette.words.title.natural};
        cursor: pointer;

        &:hover {
          opacity: 0.7;
        }
      }
    }
  `}
`;

export const TableFooter = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px;
    border: 1px solid ${theme.palette.semantics.feedback.unknown.light};
    border-top: none;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;

    .counter {
      display: flex;
      align-items: center;
    }
  `}
`;

export const Footer = styled.div`
  ${({ theme, ...props }) => css`
    color: #7d9eb5;
    display: flex;
    width: 100%;
    justify-content: ${props.blockSizePage ? 'end' : 'space-between'};
    padding: 0 22px;
    align-items: center;
    height: 100px;

    select {
      margin: 0 25px;
    }
  `}
`;

export const FooterCol = styled.div`
  display: flex;
  align-items: center;
`;

export const Control = styled.button`
  margin-left: 15px;
  display: flex;
  flex-direction: justify-content;
  align-items: center;
  cursor: pointer;
  background: none;
  border: none;
`;

export const Pages = styled(Pagination)`
  ${({ theme }) => css`
    margin: 0px 10px;

    &.MuiPagination-root {
      color: ${theme.palette.words.subtitle.natural};
    }
  `}
`;

export const Loading = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 500px;
    background-color: ${theme.palette.system.overlay};
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    &.hasBorders {
      border: 1px solid ${theme.palette.semantics.feedback.unknown.light};
      border-top: none;
    }

    .isEmpty {
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h1 {
        font-size: 28px;
      }

      h4 {
        color: ${theme.palette.words.subtitle.natural};
      }
    }
  `}
`;

export const AdicinarDiv = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
      width: 100%;
      height: 30px;
      border: none;
      background: transparent;
      font: normal normal 600 16px/23px Texta;
      text-align: left;
      color: ${theme.palette.words.subtitle.natural};
      padding: 0px 15px;
      margin: 4px 0px;

      :hover {
        background: #eee;
      }
    }

    span {
      height: 40px;
    }

    h2 {
      font: normal normal 600 16px/23px Texta;
      color: ${theme.palette.words.subtitle.natural};
    }
  `}
`;
