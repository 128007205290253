export const columns = isProvider => [
  {
    header: 'DESVIO',
    id: 'fator_risco',
    type: 'string',
    sort: false,
  },
  {
    header: isProvider ? 'EMPRESA' : 'MOTORISTA',
    id: 'nome',
    type: 'string',
    sort: true,
  },
  {
    header: 'RECORRÊNCIA NO PERÍODO',
    id: 'quantidade',
    sort: true,
    type: 'number',
    align: 'center',
  },
  {
    header: 'TOTAL DE PONTOS',
    id: 'pontos',
    sort: true,
    type: 'number',
    align: 'center',
  },
  {
    header: 'PTOS/KM',
    id: 'pontosKms',
    sort: true,
    type: 'number',
    align: 'center',
    value: value => Number(value).toFixed(2),
  },
  {
    header: 'PTOS/HORA',
    id: 'pontosHoras',
    sort: true,
    type: 'number',
    align: 'center',
    value: value => Number(value).toFixed(2),
  },
];
