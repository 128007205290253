import {
  getDesviosFadiga,
  getResponsaveis,
  getCriticidade,
  getVeiculos,
  getMotoristas,
} from 'constants/_SERVICES/user';

// Pages
import FadigaDesvios from 'pages/Provider/FadigaDesvios';
import FadigaRanking from 'pages/Provider/FadigaRanking';
import LogFadigaDesvio from 'pages/Provider/FadigaDesvios/Historico';
import DetalheFadigaDesvios from 'pages/Provider/FadigaDesvios/Detalhe';
import FadigaConfiguracoes from 'pages/FadigaConfiguracoes';
import FadigaEstatisticas from 'pages/FadigaEstatisticas';
import EditDesvio from 'pages/FadigaConfiguracoes/EditDesvio';
import LogFadigaConfiguracao from 'pages/FadigaConfiguracoes/Historico';

export default {
  '/fadiga/configuracoes': {
    back: -1,
    defaults: [],
    page: <FadigaConfiguracoes />,
  },

  '/fadiga/configuracoes/criar': {
    back: -1,
    title: 'Adicionar Desvio',
    defaults: [],
    page: <EditDesvio novo />,
  },

  '/fadiga/configuracoes/:id': {
    back: -1,
    title: 'Editar Desvio',
    defaults: [],
    page: <EditDesvio />,
  },

  '/logs/fadiga/configuracoes/:id': {
    back: -1,
    title: 'Histórico da configuração',
    id: true,
    defaults: [],
    page: <LogFadigaConfiguracao />,
  },

  '/fadiga/desvios': {
    date: true,
    pageFilterName: 'filterFadigaDesviosProvider',
    defaults: ['empresas', 'filials', 'motoristas'],
    filters: [
      {
        name: 'desvio',
        placeholder: 'Filtrar por Desvio',
        mode: 'single',
        data: async () => getDesviosFadiga(),
      },
      {
        name: 'criticidade',
        placeholder: 'Filtrar por Criticidade',
        mode: 'multiple',
        data: async () => getCriticidade(),
      },
    ],
    page: <FadigaDesvios />,
  },

  '/fadiga/desvios/:id': {
    title: 'Desvio',
    id: true,
    status: true,
    back: '/fadiga/desvios',
    defaults: [],
    page: <DetalheFadigaDesvios />,
  },

  '/logs/fadiga/desvios/:id': {
    back: -1,
    title: 'Histórico do desvio',
    id: true,
    defaults: [],
    page: <LogFadigaDesvio />,
  },

  '/fadiga/ranking': {
    date: true,
    defaults: ['empresas', 'operations'],
    pageFilterName: 'filterFadigaRankingProvider',
    filters: [
      {
        name: 'desvio',
        placeholder: 'Filtrar por Desvio',
        mode: 'single',
        section: 'Desvio, Veículo e Motorista',
        data: async () => getDesviosFadiga(),
      },
      {
        name: 'veiculo',
        placeholder: 'Filtrar por Tipo de Veículo',
        mode: 'single',
        section: 'Desvio, Veículo e Motorista',
        data: async () => getVeiculos(),
      },
      {
        name: 'motoristas',
        placeholder: 'Filtrar por Tipo de Motorista',
        mode: 'single',
        section: 'Desvio, Veículo e Motorista',
        data: async () => getMotoristas(),
      },
    ],
    page: <FadigaRanking />,
  },

  '/fadiga/estatisticas': {
    date: true,
    defaults: [],
    page: <FadigaEstatisticas />,
  },

  '/fadiga/estatisticas/desempenho-geral': {
    date: true,
    defaults: [],
    page: <FadigaEstatisticas />,
  },

  '/fadiga/estatisticas/areas-de-risco': {
    date: true,
    defaults: [],
    page: <FadigaEstatisticas />,
  },

  '/fadiga/estatisticas/fatores-de-risco': {
    date: true,
    defaults: [],
    page: <FadigaEstatisticas />,
  },

  '/fadiga/estatisticas/tratativa-de-desvios': {
    date: true,
    defaults: [],
    page: <FadigaEstatisticas />,
  },
};
