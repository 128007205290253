import React from 'react';
import * as S from './styled';
import { Item } from './Item';
import GraficoBarraVertical from './Grafico';
import { Skeleton } from '@mui/material';

export const FatoresRisco = ({
  data,
  selectedCriticity,
  setSelectedCriticity,
  isLoading = false,
}) => {
  const criticidades = [
    { name: 'PRIMÁRIA', value: 0 },
    { name: 'MODERADA', value: 0 },
    { name: 'GRAVE', value: 0 },
    { name: 'GRAVÍSSIMA', value: 0 },
  ];
  const fatores = data.reduce((acc, curr) => {
    const criticidade = criticidades.find(i => i.name === curr.criticidade);
    if (criticidade) criticidade.value += 1;

    const item = acc.find(i => i.name === curr.desvio);
    if (item) item.value += 1;
    else acc.push({ name: curr.desvio, value: 1 });
    return acc;
  }, []);

  fatores.sort((a, b) => b.value - a.value);

  return (
    <S.Container>
      <S.Title>Maiores fatores de risco</S.Title>
      <S.Content>
        <S.GraphContainer>
          <GraficoBarraVertical
            data={criticidades.map(item => ({
              ...item,
              selected: selectedCriticity === item.name,
            }))}
            onBarClick={data =>
              setSelectedCriticity(selected =>
                selected !== data.name ? data.name : null,
              )
            }
          />
        </S.GraphContainer>
        <S.ContainerList>
          {isLoading
            ? [1, 2, 3, 4].map((item, index) => (
                <Skeleton key={item?.endereco} width="100%" animation="wave">
                  <Item index={index + 1} text="" />
                </Skeleton>
              ))
            : fatores
                .slice(0, 4)
                .map((item, index) => (
                  <Item
                    index={index + 1}
                    key={index}
                    text={item.name || ''}
                    infractions={item.value}
                  />
                ))}
        </S.ContainerList>
      </S.Content>
    </S.Container>
  );
};
