import styled from 'styled-components';
import colors from 'styles/colors';

export const Container = styled.div`
  position: relative;
  width: 50%;
  overflow: hidden;
  background-color: ${colors.white};
  margin-top: 20px;
  border: 1px solid ${colors.greyTiny};
  border-radius: 5px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 60px;

  .line {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    position: absolute;
    left: 0;
    margin-left: 20px;

    .title {
      font-size: 30px;
      font-weight: 900;
      margin-left: 10px;
      color: ${colors.greyTitle};
    }

    .page {
      font-size: 23px;
      font-weight: 700;
      color: ${colors.greySubtitle};
      margin-left: 10px;
      margin-bottom: -3px;
      color: ${colors.blueInfo};
    }

    &:hover {
      opacity: 0.6;
      transition: all 0.2s ease-in-out;
    }
  }

  .actions {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    right: 0;
    margin-right: 20px;
    gap: 25px;

    .preview {
      background-color: transparent;
      border: none;
      color: ${colors.greySubtitle};
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 3px;
      font-size: 16px;
      font-weight: 600;

      .MuiSvgIcon-root {
        color: ${colors.greySubtitle};
      }

      &:hover {
        opacity: 0.6;
      }
    }
  }
`;

export const Body = styled.div`
  padding: 20px;
  width: 100%;

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;

    .item {
      position: relative;
      overflow: hidden;
      width: 100%;

      .info {
        font-size: 13px;
        color: ${colors.blueInfo};
        font-weight: 600;
        margin-left: 10px;
      }

      .label {
        font-size: 15px;
        font-weight: 500;
        margin-top: 5px;
        color: ${colors.greySubtitle};

        .req {
          font-weight: 700;
          font-size: 17px;
          color: ${colors.redDanger};
        }

        &.p {
          margin-bottom: 7px;
        }
      }

      .counter {
        display: flex;
        align-items: center;
        position: absolute;
        right: 0;
        bottom: 0;
        margin-right: 7px;
        margin-bottom: 25px;
        font-size: 12px;
        font-weight: 600;
        color: ${colors.grey};

        &.rows {
          margin-bottom: 5px;
        }
      }

      .checkArea {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-top: 20px;

        &.start {
          justify-content: flex-start;
          margin-top: 10px;
          margin-left: 30px;
        }
      }

      .off {
        opacity: 0.5;
        transition: all 0.2s ease-in-out;
      }
    }
  }
`;

export const Steps = styled.div`
  margin-top: 20px;
  width: 100%;

  .etp {
    font-weight: 700;
    font-size: 25px;
    color: ${colors.greyTitle};
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .MuiSvgIcon-root {
      margin-left: 10px;
      color: ${colors.greyTiny};
      cursor: pointer;
    }
  }

  .lineStep {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 150px;
    width: 100%;
    margin-top: 10px;
    border-bottom: 1px solid ${colors.grey};
    position: relative;

    .medium {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      position: absolute;
      margin: 0px 50px;

      .prevImg {
        width: 192px;
        height: 108px;
        margin-right: 30px;

        img {
          width: 100%;
          height: 100%;
        }

        .noImg {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          font-weight: 600;
          flex-direction: column;
          color: ${colors.greySubtitle};
          border: 1px solid ${colors.greySubtitle};
          border-radius: 4px;

          .MuiSvgIcon-root {
            color: ${colors.greySubtitle};
            font-size: 22px;
            margin-bottom: 8px;
          }
        }
      }

      .infos {
        .title {
          color: ${colors.greySubtitle};
          font-weight: 500;

          span {
            font-size: 18px;
            font-weight: 700;
            color: ${colors.greyTitle};
            margin-right: 5px;
          }
        }
      }
    }

    .actions {
      display: flex;
      align-items: center;
      justify-content: space-around;
      position: absolute;
      gap: 30px;
      right: 0;

      .MuiSvgIcon-root {
        cursor: pointer;

        &:hover {
          opacity: 0.6;
        }
      }
    }
  }
`;

export const ButtonAdd = styled.div`
  width: 100%;
  height: 50px;
  border: 2px dashed ${colors.greySubtitle};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
  margin-bottom: 80px;
  font-weight: 700;
  color: ${colors.greySubtitle};
  font-size: 17px;
  text-transform: uppercase;
  cursor: pointer;

  .MuiSvgIcon-root {
    color: ${colors.greySubtitle};
    margin-right: 5px;
  }

  &.small {
    margin-bottom: 20px;
  }

  &:hover {
    opacity: 0.6;
    transition: all 0.1s ease-in-out;
    background-color: ${colors.greyBorder};
  }
`;

export const Footer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px;
  gap: 10px;
  background-color: ${colors.white};
`;
