import { IconButton } from '@mui/material';
import styled, { css } from 'styled-components';

const Main = styled.div`
  display: flex;
  flex-direction: column;
`;

const Status = styled.div`
  padding: 4px 10px;
  display: flex;
  border-radius: 50px;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  background-color: ${props => `${props.color}30`};

  p {
    color: ${props => props.color};
    font: normal normal bold 18px/18px Texta;
  }
`;

const SpacedRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const ColumnWrapper = styled.div`
  ${({ theme }) => css`
    background: ${theme.palette.system.overlay};
    border-radius: 4px;
    border: 1px solid ${theme.palette.brand.primary.light}4d;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 25px;
  `}
`;

const StyledLogoWrapper = styled.div`
  ${({ theme }) => css`
    margin-right: 10px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid ${theme.palette.brand.secondary.natural};
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;

const StyledLogo = styled.div`
  ${({ theme }) => css`
    height: 42px;
    width: 42px;
    border-radius: 50%;
    border: ${props => props.border};
    background-image: ${props => props.backgroundImage};
    background-color: ${theme.palette.semantics.feedback.unknown.light};
    display: flex;
    align-items: center;
    justify-content: center;

    h2 {
      font-size: 20px;
      color: ${theme.palette.brand.primary.natural};
      text-align: center;
    }
  `}
`;

const StyledTextWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    h1 {
      font-size: 16px;
      color: ${theme.palette.words.title.natural};
      font-weight: 600;
      text-transform: capitalize;
      text-align: left;
    }
    p {
      color: ${theme.palette.words.subtitle.natural};
      font-size: 14px;
      font-weight: 500;
      text-align: left;
    }
  `}
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-top: 15px;
`;

const AbsoluteIconButton = styled(IconButton)`
  ${({ theme }) => css`
    background-color: ${theme.palette.system.overlay};
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    position: absolute;
    top: 45%;
    z-index: 1;
    :hover {
      background: rgba(255, 255, 255, 0.9);
    }
  `}
`;

const BoxLoading = styled.div`
  width: 80px;
  transform: scale(0.6);
`;

export {
  Main,
  BoxLoading,
  Status,
  SpacedRow,
  ColumnWrapper,
  StyledLogoWrapper,
  StyledLogo,
  StyledTextWrapper,
  AbsoluteIconButton,
  Footer,
};
