import styled from 'styled-components';

export const ChildrenWrapper = styled.div`
  padding: 35px;
  padding-top: ${({ alertIsOpen }) => (alertIsOpen ? '85px' : '35px')};
  width: 100%;
`;

export const Body = styled.div`
  position: fixed;
  display: flex;
  width: calc(100% - 60px);
  height: ${p =>
    p.isFullScreenMode ? 'calc(100% - 0px)' : 'calc(100% - 70px)'};
  overflow-y: ${p => (p.isFullScreenMode ? 'hidden' : 'scroll')};
  overflow-x: hidden;
  margin-top: ${p => (p.isFullScreenMode ? '0px' : '70px')};
  margin-left: ${p => (p.isFullScreenMode ? '30px' : '60px')};
`;

export const HeaderMain = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
