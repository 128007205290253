import React from 'react';
import { useQuery } from 'react-query';
import { useTheme } from 'styled-components';

import Loading from 'components/Loading';
import { formatNewHour } from 'utils/dates';
import { getCriticidadeColor } from 'pages/Desvios/constants';

import * as S from './styled';
import { requestDriversLastInfractions } from '../../services';

export const ListaDesvios = ({ id, filters }) => {
  const theme = useTheme();
  const { isFetching, data } = useQuery(
    ['motoristas-ultimos-desvios', id, filters],
    () => filters && requestDriversLastInfractions(id, filters),
    {
      refetchOnWindowFocus: false,
      enabled: !!id,
    },
  );

  return (
    <S.Container>
      <S.Header>
        <S.Title>Últimos desvios</S.Title>
      </S.Header>
      {isFetching ? (
        <S.BoxLoading>
          <Loading />
        </S.BoxLoading>
      ) : (
        <S.List>
          {data?.data?.data?.map((item, idx) => (
            <li key={idx}>
              <S.StyledCard>
                <p className="title">{item.titulo}</p>
                <S.Criticity>
                  <S.Circle
                    color={getCriticidadeColor(item.criticidade, theme)}
                  />
                  <p>{item.criticidade}</p>
                </S.Criticity>
                <p>{formatNewHour(item.data_desvio)}</p>

                <p className="address" title={item.endereco}>
                  {item.endereco}
                </p>
              </S.StyledCard>
            </li>
          ))}
        </S.List>
      )}
    </S.Container>
  );
};
