import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme, ...props }) => css`
    background-color: #fff;
    border-radius: 5px;
    border: 2px solid
      ${props.isError
        ? theme.palette.semantics.feedback.attention.natural
        : theme.palette.system.divider};
    display: flex;
  `}
`;

export const Left = styled.div`
  ${({ theme }) => css`
    flex: 2;
    border-right: 1px solid ${theme.palette.system.divider};
    padding: 20px 30px;
  `}
`;

export const Right = styled.div`
  flex: 1;
`;

export const TitleArea = styled.div`
  ${({ theme }) => css`
    margin-bottom: 15px;
    display: flex;
    align-items: center;

    h3 {
      font-size: 22px;
      font-weight: 900;
      color: ${theme.palette.words.title.natural};
      margin-left: 10px;
    }
  `}
`;

export const QuestionsArea = styled.div`
  ${({ theme }) => css`
    margin-bottom: 20px;

    p {
      font-size: 17px;
      font-weight: 900;
      color: ${theme.palette.words.title.natural};
    }
  `}
`;

export const OptionsArea = styled.div`
  ${({ theme }) => css`
    margin-top: 25px;

    .headerLine {
      span {
        color: ${theme.palette.words.subtitle.natural};
        font-size: 16px;
        font-weight: 600;
      }

      .op {
        margin-left: 40px;
      }
    }

    .options {
      margin-top: 10px;

      .option {
        display: flex;
        align-items: center;

        .text {
          margin-left: 47px;
          font-weight: 600;
          color: ${theme.palette.words.subtitle.natural};

          &::first-letter {
            text-transform: uppercase;
          }
        }
      }

      .add {
        margin-top: 10px;
        cursor: pointer;
        display: inline-flex;
        transition: all 0.1s ease-in-out;

        &:hover {
          .addIcon {
            color: ${theme.palette.brand.secondary.natural};
          }

          .addText {
            color: ${theme.palette.brand.secondary.natural};
          }
        }

        .addIcon {
          margin-left: 9px;
        }

        .addText {
          margin-left: 50px;
        }
      }
    }
  `}
`;

export const InputOption = styled.input`
  ${({ theme, isError }) => css`
    border: none;
    border-bottom: 1px solid
      ${isError
        ? theme.palette.semantics.feedback.attention.natural
        : theme.palette.system.divider};
    margin-left: 47px;
    margin-right: 20px;
    width: 40%;
    font-weight: 600;
    color: ${theme.palette.words.subtitle.natural};
    font-size: 16px;

    &::placeholder {
      color: ${isError
        ? theme.palette.semantics.feedback.attention.natural
        : theme.palette.words.text.light};
      font-size: 14px;
    }
  `}
`;

export const FeedbackArea = styled.div`
  ${({ theme }) => css`
    p {
      font-size: 17px;
      font-weight: 900;
      color: ${theme.palette.words.title.natural};
    }
  `}
`;

export const HeaderRight = styled.div`
  padding: 20px 30px;
  display: flex;
  justify-content: end;
`;

export const BodyRight = styled.div`
  ${({ theme }) => css`
    .titleBody {
      font-size: 14px;
      font-weight: 700;
      color: ${theme.palette.words.title.natural};
      text-transform: uppercase;
      margin-left: 20px;
    }
  `}
`;

export const QuestionsLine = styled.div`
  margin-top: 10px;
`;

export const Question = styled.div`
  ${({ theme, isError, isEmpty, isSelected }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 45px;
    transition: all 0.1s ease-in-out;
    cursor: pointer;
    background-color: ${isError
      ? theme.palette.semantics.feedback.attention.light
      : isSelected
      ? theme.palette.system.border
      : 'transparent'};

    &:hover {
      background-color: ${theme.palette.system.border};
    }

    .left {
      display: flex;
      padding-left: 20px;
      align-items: center;
      height: 100%;
      width: 100%;

      .index {
        font-size: 18px;
        font-weight: bold;
        color: ${isError
          ? theme.palette.semantics.feedback.attention.natural
          : theme.palette.words.title.natural};
      }

      .text {
        margin-left: 15px;
        font-weight: 600;
        color: ${isError
          ? theme.palette.semantics.feedback.attention.natural
          : isEmpty
          ? theme.palette.words.text.light
          : theme.palette.words.title.natural};

        &::first-letter {
          text-transform: uppercase;
        }
      }
    }

    .right {
      display: flex;
      align-items: center;

      .bar {
        width: 3px;
        height: 45px;
        background-color: ${isSelected
          ? theme.palette.brand.secondary.natural
          : 'transparent'};
        margin-left: 15px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        transition: all 0.2s ease-in-out;
      }
    }
  `}
`;
