import React, { useEffect, useState, ref } from 'react';
import { useTheme } from 'styled-components';

import { Grid } from '@mui/material';
import TextInput from 'components/Inputs/TextField';
import GhostButton from 'components/Buttons/Ghost';
import InputAttachedFile from 'components/Inputs/InputAttachedFile2';
import { updateDetails } from '../../services';

import * as S from '../styled';
import { toast } from 'react-toastify';

const Config = ({ data }) => {
  const [url, setUrl] = useState('');
  const [obs, setObs] = useState('');

  useEffect(() => {
    setUrl(data?.arquivo_evidencia || '');
    setObs(data?.observacao || '');
  }, []);

  const handleSave = async () => {
    const obj = {
      observacao: obs,
      arquivo_evidencia: url,
    };
    const res = await updateDetails(data?.id, obj);

    if (res.success) {
      toast.success('Salvo com sucesso');
    } else {
      toast.error('Erro ao salvar');
    }
  };

  return (
    <S.ColumnWrapper>
      <Grid container columnSpacing={2} rowSpacing={1}>
        <Grid item xs={8} sm={8}>
          <TextInput
            id="pts_km"
            label="Ptos/km"
            onChange={e => {}}
            placeholder="-"
            disabled
            value={data?.pontos_hr}
            props={{
              readOnly: true,
            }}
            variant="filled"
          />
        </Grid>
        <Grid item xs={4} sm={4}>
          <TextInput
            id="pts_hr"
            label="Ptos/hr"
            onChange={e => {}}
            placeholder="-"
            disabled
            value={data?.pontos_km}
            props={{
              readOnly: true,
            }}
            variant="filled"
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextInput
            id="observacao"
            value={obs}
            placeholder="Inserir observacao"
            label="Observações"
            onChange={e => {
              setObs(e.target.value);
            }}
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <InputAttachedFile
            idInput="input_file_detalhe"
            label="Anexar Arquivo"
            inputLabel="Anexar Arquivo"
            setFileUrl={setUrl}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <GhostButton
            id="btnSalvar"
            size="medium"
            onClick={() => handleSave()}
          >
            SALVAR
          </GhostButton>
        </Grid>
      </Grid>
    </S.ColumnWrapper>
  );
};

export default Config;
