import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

// Components
import SaveModal from 'pages/Avaliacoes/SaveModal';
import ListPage from '../../components/ListPage';
import ConfirmModal from 'components/ConfirmModal';
import { ReactComponent as file } from 'images/icons/sidebar/file.svg';

// Services
import {
  requestResponsibles,
  archiveEvaluation,
  selectWitnessEvaluation,
  viewEvaluation,
} from 'pages/Avaliacoes/services';
import { trackEvent } from 'utils/mixpanel';
import { requestAvaliacoesList, requestCards } from './services';
import { formatArrFilters } from 'pages/Desvios/constants';
import { justificativas, tabs as statusTabs } from 'pages/Avaliacoes/constants';
import {
  columnsUnavailable2,
  columnsProcessing2,
  columnsPending2,
  columnsFilled2,
  columnsSigned2,
  columnsArchived2,
} from 'pages/Avaliacoes/columns';

import { usePlans } from 'hooks/usePlans';

const defaultFilters = {
  client: '',
  filial: '',
  operation: '',
  responsavel: '',
  carga: '',
  status: 0,
  sortBy: { id: 'data_avaliacao', order: 'DESC' },
};

export const AvaliacoesDirecao = () => {
  // Redux e hooks
  const params = useParams();
  const { isProvider } = usePlans();
  const user = useSelector(state => state.auth?.user?.user);
  const filter = useSelector(state =>
    isProvider ? state.filterProvider : state.filter,
  );

  // General States
  const [query, setQuery] = useState(null);
  const [loadingTab, setLoadingTab] = useState(true);
  const [loadingLines, setLoadingLines] = useState([]);
  const [responsibles, setResponsibles] = useState(null);
  const [filterPage, setFilterPage] = useState(defaultFilters);

  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedEvaluations, setSelectedEvaluations] = useState(null);

  // -------------------------- BULK MENU & ACTION COLUMN---------------------------//

  const [folga, setFolga] = useState('');
  const [justify, setJustify] = useState('');
  const [witness, setWitness] = useState('');
  const [resetTable, setResetTable] = useState(false);
  const [openWitnessSelection, setOpenWitnessSelection] = useState(false);
  const [openArchiveEvaluations, setOpenArchiveEvaluations] = useState(false);
  const [openUnarchiveEvaluations, setOpenUnarchiveEvaluations] =
    useState(false);

  // -------------------------TABS (TOP PAGE)---------------------------------------//
  const getCol = () => {
    switch (filterPage.status) {
      case 1:
        return columnsProcessing2.filter(i => i.id !== 'motorista.nome');
      case 2:
        return columnsPending2.filter(i => i.id !== 'motorista.nome');
      case 3:
        return columnsFilled2.filter(i => i.id !== 'motorista.nome');
      case 4:
        return columnsSigned2.filter(i => i.id !== 'motorista.nome');
      case 5:
        return columnsArchived2.filter(i => i.id !== 'motorista.nome');
      default:
        return columnsUnavailable2.filter(i => i.id !== 'motorista.nome');
    }
  };

  const handleViewEvaluation = async id => {
    await viewEvaluation(id);
    setLoadingLines([id]);
    fetchData();
  };

  const handleArchiveEvaluation = async id => {
    const ids = selectedEvaluations.length > 0 ? selectedEvaluations : [id];
    setLoadingLines(ids);

    const body = {
      event: 'archiveEvaluation',
      data: {
        status: 'ARQUIVADA',
        justificativa: justify,
        data_folga: folga,
        ids,
      },
      id,
    };

    const res = await archiveEvaluation(body);
    if (res.success) {
      toast.success('Sucesso ao arquivar avaliação!');
      fetchData();
      fetchCards();
      setOpenArchiveEvaluations(null);
      setJustify('');
      setFolga('');
    } else {
      setLoadingLines([]);
      toast.error(res.message);
    }
  };

  const handleUnarchiveEvaluation = async id => {
    const ids = selectedEvaluations.length > 0 ? selectedEvaluations : [id];
    setLoadingLines(ids);

    const body = {
      event: 'unarchiveEvaluation',
      data: {
        ids,
      },
      id,
    };

    try {
      await archiveEvaluation(body);
      toast.success('Sucesso ao resturar avaliação!');
      fetchCards();
      fetchData();
    } catch (error) {
      setLoadingLines([]);
      const { message } = error.response.data;
      toast.error(message);
    }
    setOpenUnarchiveEvaluations(false);
  };

  const handleSelectWitnessEvaluation = async id => {
    let ids = [];
    ids =
      selectedEvaluations && selectedEvaluations.length > 0
        ? selectedEvaluations
        : [id];

    if (!witness) {
      toast.error('Selecione uma testemunha');
      return;
    }

    setLoadingLines(ids);

    const body = {
      event: 'bulk',
      data: {
        id_da_testemunha: witness,
        avaliacoes: ids,
      },
    };

    const res = await selectWitnessEvaluation(body);
    if (res.data.success) {
      fetchCards();
      fetchData();
      toast.success('Sucesso ao selecionar testemunha das avaliações!');
    } else {
      setLoadingLines([]);
      toast.error(res.data.message);
    }
    setOpenWitnessSelection(null);
    setWitness('');
  };

  const actions = [
    {
      title: 'Arquivar avaliação',
      function: id => {
        setOpenArchiveEvaluations(id);
        trackEvent(user, 'Arquivar Avaliação');
      },
      visible: () => [0, 2, 3].includes(filterPage.status),
    },
    {
      title: 'Imprimir',
      function: id => {
        navigate(`pdf/${id}`);
        trackEvent(user, 'Imprimir Avaliação');
      },
      visible: () =>
        [3, 4].includes(filterPage.status) && !selectedEvaluations?.length,
    },
    {
      title: 'Selecionar testemunha',
      function: id => {
        setOpenWitnessSelection(id);
        trackEvent(user, 'Testemunha Adicionada');
      },
      visible: () => [2, 3, 4].includes(filterPage.status),
    },
    {
      title: 'Marcar como visto',
      function: id => handleViewEvaluation(id),
      visible: () =>
        [2, 3, 4].includes(filterPage.status) && !selectedEvaluations?.length,
    },
    {
      title: 'Abrir em nova guia',
      function: id => handleOpenNewTab(id),
      visible: () =>
        [2, 3, 4].includes(filterPage.status) && !selectedEvaluations?.length,
    },
    {
      title: 'Restaurar Avaliação',
      function: id => setOpenUnarchiveEvaluations(id),
      visible: () => [5].includes(filterPage.status),
    },
  ];

  const bulk = [
    {
      title: 'Arquivar avaliação',
      function: setOpenArchiveEvaluations,
      visible: () => [0, 2, 3].includes(filterPage.status),
    },
    {
      title: 'Selecionar testemunha',
      function: setOpenWitnessSelection,
      visible: () => [2, 3].includes(filterPage.status),
    },
    {
      title: 'Restaurar Avaliação',
      function: setOpenUnarchiveEvaluations,
      visible: () => [5].includes(filterPage.status),
    },
  ];

  // -------------------------- REQUESTS ------------------------------------------//

  const {
    refetch: fetchData,
    isFetching,
    data: resData,
  } = useQuery(
    ['avaliacoes-direcao', params?.id, query],
    () => query && requestAvaliacoesList(params?.id, query),
    {
      refetchOnWindowFocus: false,
      onSuccess: () => {
        setLoadingLines([]);
        setLoadingTab(false);
        resetTable && setResetTable(false);
      },
    },
  );

  // Atualiza cards de acordo com os filtros selecionados
  // Atualiza tabela após cards
  const {
    refetch: fetchCards,
    isLoading: loadingCards,
    data: resCards,
  } = useQuery(
    [
      'cards-avaliacoes-direcao',
      params?.id,
      {
        ...filter,
        ...formatArrFilters(filterPage),
      },
    ],
    () =>
      requestCards(params?.id, {
        ...filter,
        ...formatArrFilters(filterPage),
      }),
    {
      refetchOnWindowFocus: false,
    },
  );

  const initial = useRef(true);
  useEffect(() => {
    if (initial.current) {
      initial.current = false;
      return;
    }

    setResetTable(true);
  }, [filter, filterPage, selectedCard]);

  useEffect(() => {
    setJustify('');
    setWitness('');
  }, [openArchiveEvaluations, openWitnessSelection]);

  // -------------------------- REQUESTS ------------------------------------------//

  useEffect(() => {
    if (openWitnessSelection) fetchResponsibles(openWitnessSelection);
  }, [openWitnessSelection]);

  const fetchResponsibles = async id => {
    if (responsibles === null) {
      const res = await requestResponsibles(id);
      setResponsibles(res.data.data);
    }
  };

  // Troca do tab (filterPage.status)
  const handleStatusTab = async (event, newValue) => {
    setSelectedCard(null);
    setLoadingTab(true);
    setFilterPage(state => ({ ...state, status: newValue }));
  };

  // Só habilita clique no card quando nao há nada carregando
  // Verifica se card clicado há valor válido: nao nulo e maior que 0.
  const handleClickCard = type => {
    if (!loadingCards && !isFetching) {
      const cards = resCards?.data || [];
      const card = cards.find(item => item.type === type);
      if (card.ids?.length) {
        if (type === selectedCard) setSelectedCard(null);
        else setSelectedCard(type);
      }
    }
  };

  const handleOpenNewTab = id => {
    return window.open(`/avaliacoes/${id}`);
  };

  // Configura as tabs da tela
  const headerTabs = {
    value: filterPage.status,
    items: statusTabs,
    onChange: handleStatusTab,
    disabled: loadingCards || isFetching,
  };

  const filters = {
    data: filterPage,
    defaultFilters,
    setFilter: setFilterPage,
    loading: isFetching || loadingCards,
  };

  return (
    <>
      <ListPage
        filters={filters}
        datePicker
        headerTabs={headerTabs}
        cards={
          resCards?.data?.map(card => ({
            ...card,
            value: card.ids?.length,
            icon: 'Grupo12741.svg',
            disabled: isFetching || card.type === 'VIDEOS_PROCESSADOS',
          })) || []
        }
        selectedCard={selectedCard}
        handleClickCard={handleClickCard}
        loadingCards={loadingCards}
        tableProps={{
          data: resData?.data?.rows || [],
          columns: getCol(),
          setSelectedRows: setSelectedEvaluations,
          loading: isFetching || loadingTab,
          pageCount: resData?.data?.count || 0,
          visualizedKey: 'visto',
          local: false,
          bulk,
          actions: filterPage.status === 1 ? undefined : actions,
          reset: resetTable,
          onClickRow: filterPage.status !== 3 ? handleOpenNewTab : null,
          loadingSelection: loadingLines,
          setQuery: q =>
            setQuery({
              ...filter,
              ...formatArrFilters(filterPage),
              ...q,
              card: selectedCard,
            }),
          searchEvent: search =>
            trackEvent(user, 'Busca Avaliação de Direção', null, search),
          sortBy: {
            id: filterPage.status ? 'data_avaliacao' : 'updatedAt',
            order: 'DESC',
          },
          placeholder: `Buscar por ID`,
          empty: {
            title: 'Ops! Você não tem nenhuma avaliação.',
            description: 'Verifique os filtros selecionados.',
          },
        }}
      />

      <SaveModal
        onClick={() => handleArchiveEvaluation(openArchiveEvaluations)}
        open={!!openArchiveEvaluations}
        handleClose={() => setOpenArchiveEvaluations(false)}
        title="Deseja arquivar esta Avaliação?"
        titleIcon={file}
        subtitle="Selecione uma justificativa para o arquivamento."
        disabledSave
        value={justify}
        handleChange={setJustify}
        valueFolga={folga}
        setFolga={setFolga}
        data={justificativas.map(item => ({ value: item, name: item }))}
        selectLabel="Justificativa"
      />
      <ConfirmModal
        handleClose={() => setOpenUnarchiveEvaluations(false)}
        open={!!openUnarchiveEvaluations}
        title="Tem certeza que deseja restaurar?"
        subtitle="As avaliações resturadas voltam ao estado anterior"
        buttonText="Confirmar"
        onClick={() => handleUnarchiveEvaluation(openUnarchiveEvaluations)}
      />
      {responsibles && (
        <SaveModal
          onClick={() => handleSelectWitnessEvaluation(openWitnessSelection)}
          open={!!openWitnessSelection}
          handleClose={() => setOpenWitnessSelection(false)}
          title="Selecione a testemunhas da avaliação"
          titleIcon={file}
          subtitle="Selecione um usuário para testemunha."
          disabledSave
          value={witness}
          handleChange={setWitness}
          selectLabel="Testemunha"
          data={responsibles.map(item => ({
            value: item.id,
            name: item.nome,
          }))}
        />
      )}
    </>
  );
};
