import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import Skeleton from '@mui/material/Skeleton';

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: '#fff',
          padding: '10px',
          border: '1px solid #ccc',
        }}
      >
        <p className="label">{`${label}`}</p>
        {payload.map((item, index) => (
          <p key={index} style={{ color: item.color }}>
            {`${item.name} : ${item.value}`}
          </p>
        ))}
      </div>
    );
  }

  return null;
};

export const GraphBar = ({ loading = false, data = [] }) => {
  const colors = ['#F64E60', '#FFA801', '#0C12F2', '#1BC5BD'].reverse();

  const CustomTick = ({ x, y, payload, index }) => {
    const originalLabel = payload.value;
    const words = originalLabel.split(' ');
    let firstLine = '';
    let secondLine = '';

    if (words.length > 1) {
      const midpoint = Math.ceil(words.length / 2);
      firstLine = words.slice(0, midpoint).join(' ');
      secondLine = words.slice(midpoint).join(' ');
    } else {
      firstLine = originalLabel;
    }

    const abbreviateLine = line => {
      if (line.length > 15) {
        return `${line.substring(0, 13)}...`;
      }
      return line;
    };

    firstLine = abbreviateLine(firstLine);
    secondLine = abbreviateLine(secondLine);

    return (
      <text
        x={x}
        y={y + 12}
        fill={data.length === 4 ? colors[index] : '#4B5166'}
        fontSize={14}
        fontWeight="bold"
        textAnchor="middle"
      >
        <tspan x={x} dy="0em">
          {firstLine}
        </tspan>
        {secondLine && (
          <tspan x={x} dy="1.2em">
            {secondLine}
          </tspan>
        )}
      </text>
    );
  };

  const maxValue = Math.max(
    ...data.map(item => Math.max(item.status_aberto, item.status_finalizado)),
  );

  const minWidth = 1000;
  const calculatedWidth = data.length * 100;
  const chartWidth = Math.max(minWidth, calculatedWidth);

  return (
    <div
      style={{ width: '100%', overflowX: 'auto', overflowY: 'hidden' }}
      className="barScroll"
    >
      {!loading ? (
        <BarChart
          width={chartWidth}
          height={200}
          data={data}
          margin={{
            top: 5,
            right: 5,
            left: 5,
            bottom: 15,
          }}
          barCategoryGap={50}
          barGap={4}
        >
          <CartesianGrid horizontal vertical={false} />
          <Tooltip content={<CustomTooltip />} />

          <XAxis
            dataKey="name"
            tick={<CustomTick />}
            interval={0}
            textAnchor="end"
          />
          <YAxis domain={[0, maxValue]} />
          <Bar
            dataKey="Novas solicitações de exclusão"
            fill="#4B5166"
            name="Novas solicitações de exclusão"
            barSize={10}
            radius={[4, 4, 0, 0]}
          />
          <Bar
            dataKey="Em reavaliação"
            fill="#0C12F2"
            name="Em reavaliação"
            barSize={10}
            radius={[4, 4, 0, 0]}
          />
          <Bar
            dataKey="Aprovadas"
            name="Aprovadas"
            fill="#BDCC30"
            barSize={10}
            radius={[4, 4, 0, 0]}
          />
          <Bar
            dataKey="Rejeitadas"
            fill="#FFA801"
            barSize={10}
            name="Rejeitadas"
            radius={[4, 4, 0, 0]}
          />
          <Bar
            dataKey="Vencidas"
            fill="#F64E60"
            name="Vencidas"
            barSize={10}
            radius={[4, 4, 0, 0]}
          />
        </BarChart>
      ) : (
        <div
          style={{
            marginLeft: 50,
            transform: 'translateY(-10px)',
          }}
        >
          <Skeleton height={250} />
        </div>
      )}
    </div>
  );
};
