import React, { useCallback, useEffect, useState } from 'react';
import { subHours } from 'date-fns';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

// Actions
import { setFilter } from 'store/modules/filterTelemetriaTratativaDesvios/actions';

// Componentes
import { Grid, SvgIcon } from '@mui/material';
import { SaveAlt } from '@mui/icons-material';
import PieGraph from 'components/Graphs/PieGraph';
import Graph from 'components/Graphs/ComposedChart';
import Tab from 'components/Tabs';
import GhostButton from 'components/Buttons/Ghost';
import { ReactComponent as filterIco } from 'images/icons/filter.svg';

// Service
import { formatNewDate, formatNameDate } from 'utils/dates';
import * as S from '../styled';
import { requestEstatisticas, exportToExcel } from './services';
import Filters from './Filters';

const valuesRadar = [
  { value: 'TIPO', label: 'POR TIPO' },
  { value: 'STATUS', label: 'POR STATUS' },
];

const TratativaDesvios = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { user } = useSelector(prevFilter => prevFilter.auth.user);
  const userLevel = user?.nivel;
  const isProvider = user?.provider;
  const [filter, filterTratativa] = useSelector(state => {
    return [
      isProvider ? state.filterProvider : state.filter,
      state.filterTelemetriaTratativaDesvios.filter,
    ];
  });

  const countFilter = Object.getOwnPropertyNames(filterTratativa).length;
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingExcel, setLoadingExcel] = useState(false);
  const [graphFilter, setGraphFilter] = useState({
    graphId: null,
    value: null,
  });

  const [data, setData] = useState([]);
  const [topFive, setTopFive] = useState([]);
  const [foraPrazo, setForaPrazo] = useState([]);

  const [radarDesviosTipo, setRadarDesviosTipo] = useState([]);
  const [radarDesviosStatus, setRadarDesviosStatus] = useState([]);
  const [tempoMedio, setTempoMedio] = useState([]);
  const [metaTempoMedio, setMetaTempoMedio] = useState(10);
  const [justificativas, setJustificativas] = useState([]);
  const [radarDesviosTab, setRadarDesviosTab] = useState('TIPO');

  const setFilterTratativa = newState => {
    dispatch(setFilter(newState));
  };

  const formatArrayFilter = useCallback(
    filters => {
      const _filters = {};
      Object.keys(filters).forEach(key => {
        _filters[key] = filters[key].split(',');
      });

      // Se nivel 2 ou 3 sempre filtra filial
      if (userLevel > 1) _filters.filiais = [user?.id_da_filial];
      return _filters;
    },
    [userLevel, user],
  );

  // aplica o filtro de coluna
  const filterData = useCallback(
    (graphId, data, graphFilter) => {
      const _data = [...data];
      if (!graphFilter.graphId || graphFilter.graphId === graphId) return _data;

      if (graphFilter.graphId === 'topFive')
        return _data.filter(
          item => item.nome_responsavel === graphFilter.value,
        );

      if (graphFilter.graphId === 'foraPrazo')
        return _data.filter(item => item.nome_empresa === graphFilter.value);

      if (graphFilter.graphId === 'radarDesvios')
        return _data.filter(item => item.desvio === graphFilter.value);

      if (graphFilter.graphId === 'justificativas')
        return _data.filter(item => item.motivo_exclusao === graphFilter.value);

      return _data;
    },
    [data, isProvider],
  );

  // obtem os dados dos graficos
  const reduceData = useCallback((graphId, _data, property, graphFilter) => {
    const total = _data.length;

    const groupedData = _data.reduce((acc, objeto) => {
      const key = objeto[property] ?? 'Indefinido';
      if (acc[key]) {
        acc[key] += 1;
      } else {
        acc[key] = 1;
      }
      return acc;
    }, {});

    const formatted = Object.entries(groupedData).map(([name, quantidade]) => {
      return {
        name,
        value: quantidade,
        percentual:
          graphFilter.graphId !== graphId && graphFilter.value
            ? null
            : ((quantidade / total) * 100).toFixed(2),
        selected: graphFilter.graphId === graphId && graphFilter.value === name,
      };
    });
    formatted.sort((a, b) => b.value - a.value);
    return formatted;
  }, []);

  // Fetch data
  const fetchData = async query => {
    setLoading(true);
    const res = await requestEstatisticas(query);
    if (res.data?.success) {
      if (res.data?.data instanceof Array) {
        setData(res.data?.data);
      }
      if (res.data?.indicadores instanceof Array) {
        const meta = res.data?.indicadores.find(
          indicador =>
            indicador.id_indicador === 9 && indicador.inactivate_at === null,
        )?.valor;
        setMetaTempoMedio(meta ?? 10);
      }
    } else if (res.data?.message) toast.error(res.data.message);
    setLoading(false);
  };

  useEffect(() => {
    const query = {
      ...filter,
      initialDate: subHours(new Date(filter.initialDate), 3),
      finalDate: subHours(new Date(filter.finalDate), 3),
      ...formatArrayFilter(filterTratativa),
    };

    fetchData(query);
  }, [filter, filterTratativa]);

  // Set TopFive
  useEffect(() => {
    if (data.length) {
      let filtered = data.filter(item => item.status === 'FINALIZADO');
      filtered = filterData('topFive', filtered, graphFilter);
      const formatted = reduceData(
        'topFive',
        filtered,
        'nome_responsavel',
        graphFilter,
      );

      setTopFive(formatted.slice(0, 5));
    } else setTopFive([]);
  }, [data, graphFilter]);

  // Set ForaPrazo
  useEffect(() => {
    if (data.length) {
      let filtered = data.filter(
        item => item.status === 'FINALIZADO' && item.fora_prazo,
      );
      filtered = filterData('foraPrazo', filtered, graphFilter);
      const formatted = reduceData(
        'foraPrazo',
        filtered,
        'nome_empresa',
        graphFilter,
      );

      setForaPrazo(formatted);
    } else setForaPrazo([]);
  }, [data, graphFilter]);

  // Set radarDesvios
  useEffect(() => {
    if (data.length) {
      let filteredTipo = data.filter(
        item =>
          (!isProvider || item.status !== 'ABERTO') &&
          item.status !== 'DELETADO',
      );
      filteredTipo = filterData('radarDesvios', filteredTipo, graphFilter);
      let formattedTipo = reduceData(
        'radarDesvios',
        filteredTipo,
        'desvio',
        graphFilter,
      );

      if (formattedTipo.length > 6) {
        const ultimos = formattedTipo.slice(6, formattedTipo.length);
        const { value, percentual } = ultimos.reduce(
          (acc, item) => {
            const percentual = Number(item.percentual);
            acc.value += item.value;
            acc.percentual += percentual;
            return acc;
          },
          { value: 0, percentual: 0 },
        );

        formattedTipo = [
          ...formattedTipo.slice(0, 6),
          { name: 'Outros', percentual, value },
        ];
      }

      let filteredStatus = data.filter(
        item => !isProvider || item.status !== 'ABERTO',
      );
      filteredStatus = filterData('radarDesvios', filteredStatus, graphFilter);
      const formattedStatus = reduceData(
        '',
        filteredStatus,
        'status',
        graphFilter,
      );

      setRadarDesviosTipo(formattedTipo);
      setRadarDesviosStatus(formattedStatus);
    } else {
      setRadarDesviosTipo([]);
      setRadarDesviosStatus([]);
    }
  }, [data, graphFilter]);

  // Set tempoMedio
  useEffect(() => {
    if (data.length) {
      let filtered = data.filter(item => item.status === 'FINALIZADO');
      filtered = filterData('tempoMedio', filtered, graphFilter);

      const groupedData = filtered.reduce((acc, objeto) => {
        if (acc[objeto.tempo_medio]) {
          acc[objeto.tempo_medio].diferenca_dias += objeto.diferenca_dias;
          acc[objeto.tempo_medio].quantidade += 1;
        } else {
          acc[objeto.tempo_medio] = {
            diferenca_dias: objeto.diferenca_dias,
            quantidade: 1,
          };
        }
        return acc;
      }, {});

      const formatted = Object.entries(groupedData).map(
        ([name, { quantidade, diferenca_dias }]) => {
          return {
            name,
            value: Math.floor(diferenca_dias / quantidade),
            meta: metaTempoMedio,
          };
        },
      );

      setTempoMedio(formatted);
    } else {
      setTempoMedio([]);
    }
  }, [data, graphFilter, metaTempoMedio]);

  // Set justificativas
  useEffect(() => {
    if (data.length) {
      let filtered = data.filter(item => item.status === 'DELETADO');
      filtered = filterData('justificativas', filtered, graphFilter);
      const formattedTipo = reduceData(
        'justificativas',
        filtered,
        'motivo_exclusao',
        graphFilter,
      );

      setJustificativas(formattedTipo);
    } else {
      setJustificativas([]);
    }
  }, [data, graphFilter]);

  const handleChangeGraphFilter = (graphId, value) => {
    if (!graphFilter.graphId || graphFilter.graphId === graphId)
      !graphFilter.value || graphFilter.value !== value
        ? setGraphFilter({ graphId, value })
        : setGraphFilter({ graphId: null, value: null });
  };

  // Exportção excel
  const handleExcel = async () => {
    try {
      let excelData = {};
      if (isProvider)
        excelData = {
          'Desv. Concluídos Fora do Prazo': foraPrazo.map(item => ({
            EMPRESA: item.name,
            QUANTIDADE: item.value,
            'DATA INICIAL': formatNewDate(
              subHours(new Date(filter.initialDate), 3),
            ),
            'DATA FINAL': formatNewDate(
              subHours(new Date(filter.finalDate), 3),
            ),
          })),
        };
      else {
        excelData = {
          'TOP 5 Responsáveis': topFive.map(item => ({
            RESPONSÁVEL: item.name,
            QUANTIDADE: item.value,
            'PERCENTUAL DO TODO %': Number(item.percentual),
            'DATA INICIAL': formatNewDate(
              subHours(new Date(filter.initialDate), 3),
            ),
            'DATA FINAL': formatNewDate(
              subHours(new Date(filter.finalDate), 3),
            ),
          })),
        };
      }

      excelData = {
        ...excelData,
        'Radar de Desvios (tipo)': radarDesviosTipo.map(item => ({
          'TIPO DE DESVIOS': item.name,
          QUANTIDADE: item.value,
          'PERCENTUAL DO TODO %': Number(item.percentual),
          'DATA INICIAL': formatNewDate(
            subHours(new Date(filter.initialDate), 3),
          ),
          'DATA FINAL': formatNewDate(subHours(new Date(filter.finalDate), 3)),
        })),
        'Radar de Desvios (status)': radarDesviosStatus.map(item => ({
          STATUS: item.name,
          QUANTIDADE: item.value,
          'DATA INICIAL': formatNewDate(
            subHours(new Date(filter.initialDate), 3),
          ),
          'DATA FINAL': formatNewDate(subHours(new Date(filter.finalDate), 3)),
        })),
        'Tempo Médio Tratativa Desvios': tempoMedio.map(item => ({
          PERÍODO: item.name,
          REALIZADO: item.value,
          META: Number(item.meta),
          'DATA INICIAL': formatNewDate(
            subHours(new Date(filter.initialDate), 3),
          ),
          'DATA FINAL': formatNewDate(subHours(new Date(filter.finalDate), 3)),
        })),
        'Radar Justificativas Exclusão': justificativas.map(item => ({
          JUSTIFICATIVA: item.name,
          QUANTIDADE: item.value,
          'DATA INICIAL': formatNewDate(
            subHours(new Date(filter.initialDate), 3),
          ),
          'DATA FINAL': formatNewDate(subHours(new Date(filter.finalDate), 3)),
        })),
      };
      const formatedDate = formatNameDate(new Date());

      exportToExcel(
        excelData,
        `tratativa_de_desvios_telemetria_${formatedDate}`,
      );
      toast.success('Arquivo gerado com sucesso!');
    } catch (err) {
      toast.error('Falha ao gerar arquivo.');
    }
  };

  // renders
  const renderHeader = () => (
    <S.MapHeader>
      <S.MapFilters>
        <S.StyledButton
          textcolor={theme.palette.words.text.natural}
          backgroundcolor="transparent"
          startIcon={<SvgIcon component={filterIco} />}
          height="45px"
          style={{ marginTop: '3px' }}
          onClick={event => setAnchorEl(event.currentTarget)}
          disabled={loading}
        >
          Filtros
          <S.Count count={!!countFilter}>{countFilter}</S.Count>
        </S.StyledButton>
      </S.MapFilters>
      <S.MapActions>
        <GhostButton
          startIcon={<SaveAlt />}
          loading={loadingExcel}
          size="medium"
          onClick={() => handleExcel()}
          style={{ marginLeft: '10px' }}
        >
          EXPORTAR
        </GhostButton>
      </S.MapActions>
    </S.MapHeader>
  );

  const rightYAxisMax = datamax => {
    return datamax > 100
      ? Math.ceil(datamax / 100) * 100
      : datamax > 10
      ? Math.ceil(datamax / 10) * 10
      : 10;
  };

  const tabComponent = (
    <div
      style={{
        display: 'flex',
        justifyContent: 'end',
      }}
    >
      <Tab
        width="230px"
        value={radarDesviosTab}
        items={valuesRadar}
        onChange={(e, value) => {
          setRadarDesviosTab(value);
        }}
      />
    </div>
  );

  // renders
  const renderTopFiveGraph = () => (
    <Graph
      data={topFive}
      barSeries={['value']}
      barLabel={['Quantidade']}
      barColors="default"
      rightYAxisMax={rightYAxisMax}
      lineYAxis
      leftYAxisMax={100}
      lineSeries={['percentual']}
      lineLabel={['Percentual do Todo']}
      lineColors="status"
      linePostFix="%"
      title="Top 5 Responsáveis"
      loading={loading}
      tooltip
      legend
      onBarClick={data => handleChangeGraphFilter('topFive', data.name)}
    />
  );

  const renderForaPrazoGraph = () => (
    <Graph
      data={foraPrazo}
      barSeries={['value']}
      barLabel={['Quantidade']}
      barColors="default"
      rightYAxisMax={rightYAxisMax}
      lineSeries={[]}
      lineLabel={[]}
      title="Desvios Concluídos Fora do Prazo"
      loading={loading}
      tooltip
      legend
      onBarClick={data => handleChangeGraphFilter('foraPrazo', data.name)}
    />
  );

  const renderRadarDesviosGraph = () => (
    <Graph
      data={radarDesviosTipo}
      barSeries={['value']}
      barLabel={['Quantidade']}
      barColors="default"
      rightYAxisMax={rightYAxisMax}
      lineYAxis
      leftYAxisMax={100}
      lineSeries={['percentual']}
      lineLabel={['Percentual do todo']}
      lineColors="status"
      linePostFix="%"
      title="Radar de Desvios"
      loading={loading}
      tooltip
      legend
      tabComponent={tabComponent}
      onBarClick={data => handleChangeGraphFilter('radarDesvios', data.name)}
    />
  );

  const renderRadarDesviosPie = () => (
    <PieGraph
      data={radarDesviosStatus}
      colors="critical"
      title={`Radar de Desvios `}
      loading={loading}
      tooltip
      legend
      tabComponent={tabComponent}
    />
  );

  const renderTempoMedioLine = () => (
    <Graph
      data={tempoMedio}
      barSeries={[]}
      lineSeries={['value', 'meta']}
      lineLabel={['Realizado', 'Meta de tratativa']}
      lineColors="meta"
      rightYAxisMax={rightYAxisMax}
      title="Tempo Médio de Tratativa de Desvios (em dias)"
      loading={loading}
      tooltip
      legend
    />
  );

  const renderJustificativasBar = () => (
    <Graph
      data={justificativas}
      barSeries={['value']}
      barLabel={['Quantidade']}
      barColors="default"
      rightYAxisMax={rightYAxisMax}
      title="Radar de Justificativas Exclusão"
      loading={loading}
      tooltip
      legend
      onBarClick={data => handleChangeGraphFilter('justificativas', data.name)}
    />
  );

  const renderMain = () => (
    <>
      <S.Main>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} xl={12}>
            {renderHeader()}
          </Grid>
          <Grid item xs={12} md={6} xl={6}>
            {isProvider ? renderForaPrazoGraph() : renderTopFiveGraph()}
          </Grid>
          <Grid item xs={12} md={6} xl={6}>
            {radarDesviosTab === 'TIPO'
              ? renderRadarDesviosGraph()
              : renderRadarDesviosPie()}
          </Grid>
          <Grid item xs={12} md={6} xl={6}>
            {renderTempoMedioLine()}
          </Grid>
          <Grid item xs={12} md={6} xl={6}>
            {renderJustificativasBar()}
          </Grid>
        </Grid>
      </S.Main>
      <Filters
        id="simple-popover"
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        filter={filterTratativa}
        setFilter={setFilterTratativa}
      />
    </>
  );

  return renderMain();
};

export default TratativaDesvios;
