// Styled
import { useTheme } from 'styled-components';

// React
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setItems, setCapa } from 'store/modules/formularios/actions';

// Compoenents
import ConfirmModal from 'components/ConfirmModal';
import GhostButton from 'components/Buttons/Ghost';
import DefaultButton from 'components/Buttons/Default';
import DropZoneArchives from 'components/Inputs/DropZoneCustom';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ReportGmailerrorredOutlinedIcon from '@mui/icons-material/ReportGmailerrorredOutlined';

// Components Material
import CloseIcon from '@mui/icons-material/Close';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import TitleEdit from '../TitleEditable';
import ModalItems from '../ModalItems';
import Section from '../Section';
import * as S from './styled';

// Utils
import * as services from '../../services';
import { formatStatus } from '../../actions';
import { initializeForm } from '../../constants';

const FullModal = ({ open, onClose, pathname, backPath }) => {
  const theme = useTheme();
  // Capa default Header
  const img = require(`images/defaultFormulario.jpg`);

  // Navigate
  const navigate = useNavigate();

  // General States
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modifier, setModifier] = useState(false);
  const [firstSet, setFirstSet] = useState(true);

  // Modals
  const modalRef = useRef(null);
  const [exitModal, setExitModal] = useState(false);
  const [modalConfirmAdd, setModalConfirmAdd] = useState(false);

  // Redux
  const disptach = useDispatch();
  const formularios = useSelector(state => state.formularios);

  useEffect(() => {
    if (formularios.secoes.length === 0 || !formularios.secoes) {
      disptach(setItems(initializeForm));
    }
    setFirstSet(false);
  }, []);

  // ----------------------Modal Controller---------------------------------//
  const [visible, setVisible] = useState('100vh');
  const [footer, setFooter] = useState(false);

  useEffect(() => {
    if (open) {
      openModal();
    }
  }, [open]);

  const openModal = () => {
    setTimeout(() => {
      setVisible('0px');
    }, 300);
    setTimeout(() => {
      setFooter(true);
    }, 500);
  };

  const backModal = () => {
    setVisible('100vh');
    setFooter(false);
    setTimeout(() => {
      onClose();
    }, 300);
  };

  // Controll back arrow in window when modal is opened in same tab (BETA VERSION)
  useEffect(() => {
    if (backPath) {
      window.onpopstate = event => {
        event.preventDefault();
        navigate(backPath);
      };
    }
  }, [backPath]);

  // --------------------------------------------------------------------------------------//

  useEffect(() => {
    if (!firstSet) {
      setModifier(true);
    }
  }, [formularios.titulo, formularios.secoes, formularios.capa]);

  const addImage = item => {
    disptach(setCapa(item[0].imagem_url));
  };

  const deleteImage = () => {
    disptach(setCapa(null));
  };

  // -------------------------------------SAVE AREA---------------------------------------//
  const verifyForm = isModal => {
    if (!formularios.titulo) {
      toast.error('Insira um título para o formulário');
      return;
    }

    const items = formularios.secoes;
    for (const i in items) {
      if (!items[i].campos.length) {
        toast.error(
          `Adicione um item em "${items[i].titulo}" ou apague a seção`,
        );
        return;
      }
    }

    if (isModal) {
      setModalConfirmAdd(true);
    } else {
      onClose();
      if (!pathname) {
        navigate(`/formularios/configuracao/${formularios.id}`);
      }
    }
  };

  const confirmAdd = async () => {
    setLoading(true);
    const res = await services.SaveFields({
      id: formularios.id,
      pagina: 'secoes',
      capa: formularios.capa,
      titulo: formularios.titulo,
      secoes: formularios.secoes.map((secao, idx) => ({
        ...secao,
        ordem: idx,
        campos: secao?.campos?.map((campo, key) => ({
          ...campo,
          ordem: key,
          opcoes: campo?.opcoes?.map((opcao, index) => ({
            ...opcao,
            ordem: index,
          })),
        })),
      })),
    });
    if (res.success) {
      toast.success(res.message);
      setModalConfirmAdd(false);
      disptach(setItems(res.data.secoes));
      onClose(true);
      if (!pathname) {
        navigate(`/formularios/configuracao/${formularios.id}`);
      }
    } else {
      toast.error(res.message);
    }
    setLoading(false);
  };

  return (
    <S.BigModal open={open} disableEscapeKeyDown visible={visible}>
      <S.Cover>
        <S.Header>
          <FormatListBulletedIcon
            htmlColor={theme.palette.brand.secondary.naturaln}
          />

          <TitleEdit />

          <div className="rigthArea">
            {formatStatus(formularios.status, theme)}
            <CloseIcon
              htmlColor={theme.palette.words.title.natural}
              onClick={
                !modifier && pathname
                  ? () => backModal()
                  : () => setExitModal(true)
              }
              style={{ cursor: 'pointer' }}
            />
          </div>
        </S.Header>
        <S.Body>
          <ModalItems
            reference={modalRef}
            expanded={expanded}
            setExpanded={setExpanded}
          />
          <S.Content className="contentControllScroll">
            <DropZoneArchives
              data={
                formularios.capa
                  ? { imagem_url: formularios.capa, legenda: '' }
                  : { imagem_url: img, legenda: '' }
              }
              multiple={false}
              fileDir="formularios/capas"
              addFunction={addImage}
              deleteFunction={deleteImage}
              automaticSave
              label="Adicionar Capa"
              noTopButton
              unicCenter
            />
            <Section
              items={formularios.secoes}
              setExpanded={setExpanded}
              pathname={pathname}
            />
          </S.Content>
        </S.Body>
        <S.Footer footer={footer}>
          <div className="statusLine">
            {modifier ? 'Alterações não salvas' : 'Informações atualizadas'}
          </div>
          <span>
            <GhostButton children="Voltar" onClick={() => setExitModal(true)} />
            {modifier && (
              <DefaultButton
                children={!pathname ? 'Salvar e Avançar' : 'Salvar Alterações'}
                style={{ marginLeft: '20px' }}
                onClick={() => verifyForm(true)}
              />
            )}
            {!modifier && (
              <DefaultButton
                children={!pathname ? 'Avançar' : 'Concluir'}
                style={{ marginLeft: '20px' }}
                onClick={() => verifyForm()}
              />
            )}
          </span>
        </S.Footer>

        <ConfirmModal
          title="Tem certeza que deseja sair?"
          subtitle={
            !pathname
              ? 'O formulário ficará salvo como rascunho'
              : 'Alterações não salvas serão descartadas'
          }
          open={exitModal}
          titleIcon={
            <ReportGmailerrorredOutlinedIcon
              htmlColor={theme.palette.semantics.feedback.warning.natural}
            />
          }
          handleClose={() => setExitModal(false)}
          onClick={() => {
            backModal();
            setExitModal(false);
            if (formularios.status === 'Rascunho') {
              navigate(`/formularios`);
            }
          }}
          buttonText="Confirmar"
        />

        <ConfirmModal
          title="Tem certeza que deseja salvar?"
          titleIcon={
            <CheckCircleOutlineOutlinedIcon
              htmlColor={theme.palette.brand.secondary.naturaln}
            />
          }
          subtitle="Verifique todos os campos antes de concluir a ação"
          open={modalConfirmAdd}
          handleClose={() => setModalConfirmAdd(false)}
          onClick={() => confirmAdd()}
          buttonText="Confirmar"
          loading={loading}
        />
      </S.Cover>
    </S.BigModal>
  );
};

export default FullModal;
