import React, { useState } from 'react';

import { Divider, SvgIcon } from '@mui/material';
import Tabs from 'components/Tabs';
import Cards from 'pages/_templates/ListPage/components/Cards';
import { DefaultTable } from 'components/_Table/templates/default';

import * as S from './styled';
import CustomDateRangePicker from 'components/Header/DateRangePicker';
import { ReactComponent as filterIco } from 'images/icons/filter.svg';
import { StyledButton } from 'pages/Desvios/Estatisticas/DesempenhoGeral/styled';
import { useTheme } from 'styled-components';
import Filters from './Filters';

const ListPage = ({
  total,
  datePicker = false,
  filters = null,
  cards = null,
  headerTabs = null,
  selectedCard = null,
  disableCards = false,
  loadingCards = false,
  handleClickCard = null,
  tableProps = {},
  preTable = null,
}) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);

  const countFilter = filters
    ? Object.getOwnPropertyNames(filters.data).filter(
        item => !['status', 'sortBy'].includes(item) && filters?.data[item],
      ).length
    : 0;

  return (
    <>
      {headerTabs && (
        <S.Flex>
          {total ? (
            <S.Total>
              Total de desvios: <span>{total}</span>
            </S.Total>
          ) : (
            <></>
          )}
          <div style={{ width: '100%' }}>
            <Tabs last {...headerTabs} />
            <Divider />
          </div>
          {(filters || datePicker) && (
            <S.Flex>
              {datePicker && <CustomDateRangePicker height="45px" />}
              {filters && (
                <StyledButton
                  textcolor={theme.palette.words.text.natural}
                  backgroundcolor="transparent"
                  startIcon={<SvgIcon component={filterIco} />}
                  height="45px"
                  onClick={event => setAnchorEl(event.currentTarget)}
                  disabled={filters.loading}
                >
                  Filtros
                  <S.Count count={!!countFilter}>{countFilter}</S.Count>
                </StyledButton>
              )}
            </S.Flex>
          )}
        </S.Flex>
      )}

      {!!cards && (
        <Cards
          cards={cards}
          selectedCard={selectedCard}
          disableCards={disableCards}
          loadingCards={loadingCards}
          handleClickCard={handleClickCard}
        />
      )}

      <S.TableContainer>
        {preTable}
        <div style={{ width: '100%' }}>
          <DefaultTable {...tableProps} />
        </div>
      </S.TableContainer>

      {filters && (
        <Filters
          id="filter-popover"
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          filter={filters.data}
          setFilter={filters.setFilter}
          defaultFilters={filters.defaultFilters}
        />
      )}
    </>
  );
};

export default ListPage;
