import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 1rem;
`;

export const TableHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  .flex {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
`;

export const Title = styled.div`
  width: 100%;
  padding-bottom: 8px;
  display: flex;
  align-items: center;
  font-weight: 900;
  font-size: 24px;
  color: #4b5166;
  border-bottom: 1px solid #95aac94d;
`;
