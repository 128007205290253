// Styles
import * as S from './style.js';
import { useTheme } from 'styled-components';

// React
import { toast } from 'react-toastify';
import React, { useState, useEffect } from 'react';

// Components
import Loading from 'components/Loading';
import GhostButton from 'components/Buttons/Ghost';
import DefaultButton from 'components/Buttons/Default';
import { FilterTable } from 'components/_Table/templates/filter';

// Components MUI
import { Divider, Modal } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';

// Utils
import { columns } from './columns';
import {
  getMotoristas,
  createTurmaAluno,
  inativateGestor,
  inativateDriver,
} from './services';
import { getTurmas } from '../services';
import { useSelector } from 'react-redux';

const Alunos = ({
  openModalAluno,
  behaviorCloseModal,
  id_turma,
  fetchData,
  tipoUser,
}) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);

  // tabelas
  const [selectedRow, setSelectedRow] = useState([]);

  const [turmas, setTurmas] = useState([]);
  const [dataUsuario, setDataUsuario] = useState([]);

  const selects = useSelector(state => state.selects);

  const fetchUsers = async () => {
    setLoading(true);
    const res = await getMotoristas(tipoUser);
    if (res.data.success) {
      const motoristas = res.data.drivers;
      const { users } = res.data;
      motoristas.map(item => {
        Object.assign(item, {
          filial_name: item.filial.nome,
          turmas: item.turmas,
          conteudos: item.conteudos,
        });
        return item;
      });
      users.map(item => {
        Object.assign(item, {
          filial_name: item.filial.nome,
          turmas: item.turmas,
          conteudos: item.conteudos,
        });
        return item;
      });
      setDataUsuario(users.concat(motoristas));
    } else {
      toast.error(res.data.message);
    }
    setLoading(false);
  };

  const fetchTurmas = async () => {
    let res = await getTurmas();
    res = res.data.data.map(item => {
      return { label: item.nome, value: item.id };
    });
    setTurmas(res);
  };

  useEffect(() => {
    fetchUsers();
    fetchTurmas();
  }, [openModalAluno]);

  const findTypeUser = id => {
    const result = dataUsuario.find(item => {
      return Number(item.id) === Number(id);
    });
    return result?.tipo;
  };

  const AdicionarALuno = async () => {
    const alunos = [];
    selectedRow.map(id => {
      const type = findTypeUser(id);
      alunos.push({
        id_usuario: type === 'Usuário' ? Number(id) : null,
        id_motorista: type === 'Motorista' ? Number(id) : null,
      });
    });
    const res = await createTurmaAluno({
      id_turma: Number(id_turma),
      alunos,
    });
    if (res.data.success) {
      toast.success(res.data.message);
      behaviorCloseModal();
      fetchData();
    } else {
      toast.error(res.data.message);
    }
  };

  const handleSetExcluir = async id => {
    const data = dataUsuario.find(item => Number(item.id) === Number(id));
    const status = !data.status ? 'ATIVO' : 'INATIVO';
    if (data.tipo === 'Usuário') {
      const res = await inativateGestor(id, { status });
      if (res.data.success) {
        toast.success(res.data.message);
        fetchUsers();
      } else {
        toast.error(res.data.message);
      }
    } else if (data.tipo === 'Motorista') {
      const res = await inativateDriver(id);
      if (res.data.success) {
        toast.success(res.data.message);
        fetchUsers();
      } else {
        toast.error(res.data.message);
      }
    }
  };

  const bulk = [
    {
      title: 'Adicionar Alunos',
      function: () => AdicionarALuno(),
    },
  ];

  const actions = [
    {
      function: id => handleSetExcluir(id),
      title: 'Excluir',
    },
    {
      function: async id => {
        const type = findTypeUser(id);
        const res = await createTurmaAluno({
          id_turma: Number(id_turma),
          alunos: [
            {
              id_usuario: type === 'Usuário' ? Number(id) : null,
              id_motorista: type === 'Motorista' ? Number(id) : null,
            },
          ],
        });
        if (res.data.success) {
          toast.success(res.data.message);
          behaviorCloseModal();
          fetchData();
        } else {
          toast.error(res.data.message);
        }
      },
      title: 'Inserir',
    },
  ];

  const filters = [
    {
      label: 'Filtro por Turma',
      placeholder: 'Selecione a turma',
      data: turmas,
      mode: 'single',
      keysToSearch: ['nomes_turmas'],
    },
    {
      label: 'Filtro por Tipo',
      placeholder: 'Selecione o tipo',
      data: [
        { label: 'Motorista', value: 'Motorista' },
        { label: 'Usuário', value: 'Usuário' },
      ],
      mode: 'single',
      keysToSearch: ['tipo'],
    },
    {
      label: 'Filtro por Cliente',
      placeholder: 'Selecione o cliente',
      data: selects.clients.map(i => ({ label: i.nome, value: i.id })),
      mode: 'single',
      keysToSearch: ['clients'],
    },
  ];

  return (
    <Modal
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      open={openModalAluno}
      onClose={behaviorCloseModal}
      disableEscapeKeyDown={false}
    >
      <S.Container>
        {loading && <Loading />}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '10px',
          }}
        >
          <div className="leftHeader">
            <PersonAddAltOutlinedIcon
              htmlColor={theme.palette.brand.secondary.natural}
            />
            <h1>Selecionar usuários</h1>
          </div>
          <IconButton onClick={() => behaviorCloseModal()}>
            <CloseOutlinedIcon />
          </IconButton>
        </div>
        <Divider />
        <div className="table-container">
          <div
            style={{
              opacity: loading ? 0 : 1,
              pointerEvents: loading ? 'none' : 'all',
            }}
          >
            <FilterTable
              data={dataUsuario || []}
              columns={columns}
              filters={filters}
              fixedItems={4}
              transparent
              searchKeys={['nome', 'filial_name']}
              placeholder="Buscar por aluno"
              sortBy={{ id: 'id', order: 'DESC' }}
              setSelectedRows={setSelectedRow}
              bulk={bulk}
              actions={actions}
              empty={{
                title: 'Nenhuma aluno encontrado',
                description: `Seus alunos aparecerão aqui.`,
                image: 'frota.png',
              }}
            />
          </div>
        </div>
        <div className="button-container">
          <GhostButton onClick={() => behaviorCloseModal()}>
            CANCELAR
          </GhostButton>
          <div style={{ marginRight: 10 }} />
          <DefaultButton onClick={AdicionarALuno}>SALVAR</DefaultButton>
        </div>
      </S.Container>
    </Modal>
  );
};

export default Alunos;
