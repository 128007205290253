export const placement = (value, mode, data, placeholder) => {
  const isFilled =
    (mode === 'single' && value) ||
    (mode === 'multiple' && value && value?.split(',')?.length > 0);

  if (!isFilled) return placeholder;

  if (mode === 'single')
    return limitText(data?.find(item => item?.value === value)?.label);
  if (mode === 'multiple')
    return limitText(
      data
        ?.filter(item => value?.split(',')?.includes(item?.value?.toString()))
        ?.map(item => item?.label)
        ?.join(', '),
    );
};

const limitText = (text, limit = 40) => {
  if (text?.length <= limit) return text;
  return `${text?.slice(0, limit)}...`;
};

export const selectionLabel = (mode, value) => {
  if (mode === 'single') return 'Limpar seleção';
  if (mode === 'multiple')
    return value && value?.split(',')?.length > 0
      ? 'Limpar seleção'
      : 'Selecionar todos';
};

export const valueMultiple = value => value?.split(',')?.filter(i => i);
