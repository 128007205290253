// Styles
import * as S from './styled';
import { useTheme } from 'styled-components';

// React
import { useRef } from 'react';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setFilter } from 'store/modules/filterPad/actions';

// Components
import Tabs from 'components/Tabs';
import ReviewModal from './ReviewModal';
import ExcelModal from 'components/ExcelModal';
import ConfirmModal from 'components/ConfirmModal';
import GhostButton from 'components/Buttons/Ghost';
import AddOcorrenciaModal from './AddOcorrenciaModal';
import CardIndicadores from 'components/Cards/Indicador';
import { DefaultTable } from 'components/_Table/templates/default';
import DefaultButtonPopover from 'components/Buttons/DefaultPopover';

// Components MUI
import {
  AddCircleOutline,
  SaveAlt,
  SettingsOutlined,
} from '@mui/icons-material';
import Grid from '@mui/material/Grid';
import { Divider } from '@mui/material';

// Utils
import { columns } from './columns';
import { trackEvent } from 'utils/mixpanel';
import { formatNameDate } from 'utils/dates';
import ExportToExcel from 'utils/exportToCvs';
import { ReactComponent as file } from 'images/icons/sidebar/file.svg';
import { fields, resetExcelFields, tabs } from './constants';

// Services
import * as request from './services';

const initialize = [{}, {}, {}];

// ---------------------------------------PAD PAGE--------------------------------------------//

const PAD = () => {
  const theme = useTheme();
  // GENERAL STATES
  const [query, setQuery] = useState(null);
  const [loadingLines, setLoadingLines] = useState([]);
  const [selectedOcorrencias, setSelectedOcorrencias] = useState([]);

  // MODAL STATES
  const [openConfirmFinishModal, setOpenConfirmFinishModal] = useState(false);
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);
  const [openConfirmRefuseModal, setOpenConfirmRefuseModal] = useState(false);
  const [openAddOcorrenciaModal, setOpenAddOcorrenciaModal] = useState(false);

  // EXCEL STATES
  const [excelFields, setExcelFields] = useState(fields);
  const [loadingExcel, setLoadingExcel] = useState(false);
  const [openExcelModal, setOpenExcelModal] = useState(false);

  // NAVIGATE
  const navigate = useNavigate();

  // REDUX
  const dispatch = useDispatch();
  const filter = useSelector(state => state.filter);
  const filterPad = useSelector(state => state.filterPad);
  const user = useSelector(state => state.auth?.user?.user);

  // -------------------- UTILIDADE -------------------------//

  // --------------------TOP PAGE-----------------------------//
  const renderTop = () => (
    <>
      <S.TitleWrapper>
        <h1>PAD</h1>
        <div>
          <GhostButton
            startIcon={<SaveAlt />}
            size="medium"
            onClick={() => {
              trackEvent(user, 'EXPORTAR PAD');
              setOpenExcelModal(true);
            }}
          >
            EXPORTAR
          </GhostButton>

          <GhostButton
            startIcon={<SettingsOutlined />}
            size="medium"
            sx={{ marginLeft: '10px' }}
            onClick={() => {
              trackEvent(user, 'CONFIGURAR PAD');
              navigate('/pad/configuracoes');
            }}
          >
            CONFIGURAÇÕES
          </GhostButton>

          <DefaultButtonPopover
            startIcon={<AddCircleOutline />}
            size="medium"
            sx={{ marginLeft: '10px' }}
            onClick={() => {
              trackEvent(user, 'ADICIONAR OCORRÊNCIA PAD');
              setOpenAddOcorrenciaModal(true);
            }}
            disabled={loadingOcorrencias}
          >
            ADICIONAR OCORRÊNCIA
          </DefaultButtonPopover>
        </div>
      </S.TitleWrapper>
    </>
  );

  // ------------------- TABS -------------------------------//
  const handleTabChange = (event, newValue) => {
    trackEvent(user, `CLICK TAB ${tabs[newValue].status} PAD`);
    dispatch(
      setFilter({
        ...filterPad,
        tab: newValue,
      }),
    );
  };

  // ---------------------CARDS -----------------------------//
  const iconsCard = [
    'Grupo12752.svg',
    'Grupo12753.svg',
    'Grupo12753.svg',
    'Grupo12754.svg',
  ];

  const [cardIds, setCardIds] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);

  const handleClickCard = (type, ids) => {
    trackEvent(user, `CLICK CARD ${type} PAD`);
    if (type === selectedCard) {
      setSelectedCard(null);
      setCardIds(null);
    } else {
      setSelectedCard(type);
      setCardIds(ids);
    }
  };

  // ---------------------TABLE-----------------------------//

  // Bulk Menu
  const [selectedIds, setSelectedIds] = useState([]);

  // Excel Fields
  const handleRequestExcel = async () => {
    setLoadingExcel(true);

    const newFields = excelFields.filter(item => item.selected === true);
    const formatedDate = formatNameDate(new Date());

    const newQuery = { ...filter, ...filterPad, excelFields: newFields };

    const res = await request.requestExcel(newQuery);
    if (res.data && res.data?.data?.excel)
      ExportToExcel({
        excel: res.data.data.excel,
        name: `ocorrencias_pad${formatedDate}`,
      });
    else if (res.data.message) toast.error(res.data?.message);

    setExcelFields(resetExcelFields(fields));
    setLoadingExcel(false);
    setOpenExcelModal(false);
  };

  const handleNavigate = ids => {
    trackEvent(user, `VISUALIZAR OCORRÊNCIA PAD ${ids}`);
    navigate(`/ocorrencia/${ids}`);
  };

  const openNewTab = id => {
    window.open(`/ocorrencia/${id}`);
  };

  const handleFinishOcorrencies = async id => {
    setSelectedIds(id);
    setOpenConfirmFinishModal(true);
  };

  const finishOcorrencies = async () => {
    const ids = typeof selectedIds === 'object' ? selectedIds : [selectedIds];
    setLoadingLines(ids);
    const res = await request.finishOcorrencia(ids);

    if (res.success) {
      toast.success(res.message);
      fetchData();
      fetchCards();
    } else {
      setLoadingLines([]);
      toast.error(res.message);
    }

    setOpenConfirmFinishModal(false);
    setSelectedIds([]);
  };

  const handleRefuseOcorrencies = async id => {
    setSelectedIds(id);
    setOpenConfirmRefuseModal(true);
  };

  const refuseOcorrencies = async () => {
    const ids = typeof selectedIds === 'object' ? selectedIds : [selectedIds];
    setLoadingLines(ids);
    const res = await request.refuseOcorrencia(ids);

    if (res.success) {
      toast.success(res.message);
      fetchData();
      fetchCards();
    } else {
      setLoadingLines([]);
      toast.error(res.message);
    }

    setOpenConfirmRefuseModal(false);
    setSelectedIds([]);
  };

  const handleDeleteOcorrencies = async id => {
    setSelectedIds(id);
    setOpenConfirmDeleteModal(true);
  };

  const deleteOcorrencies = async () => {
    const ids = typeof selectedIds === 'object' ? selectedIds : [selectedIds];
    setLoadingLines(ids);
    res = await request.deleteOcorrencia(ids);

    if (res.success) {
      toast.success(res.message);
      fetchData();
      fetchCards();
    } else {
      setLoadingLines([]);
      toast.error(res.message);
    }

    setOpenConfirmDeleteModal(false);
    setSelectedIds([]);
  };

  const [reviewId, setReviewId] = useState(null);
  const [loadingReview, setLoadingReview] = useState(false);

  const handleReviewOcorrencies = async id => {
    setReviewId(id);
  };

  const reviewOcorrencies = async data => {
    setLoadingReview(true);

    const res = await request.reviewOcorrencia(data);
    if (res.success) {
      toast.success(res.message);
    } else if (res.message) toast.error(res.message);

    fetchData();
    fetchCards();
    setReviewId(null);
    setLoadingReview(false);
  };

  const actions = [
    {
      title: 'Finalizar',
      function: id => {
        trackEvent(user, 'Finalizar PAD');
        handleFinishOcorrencies(id);
      },
      visible: () => [0, 1].includes(filterPad.tab),
    },
    {
      title: 'Deletar',
      function: id => {
        trackEvent(user, 'DELETAR OCORRENCIA PAD');
        handleDeleteOcorrencies(id);
      },
      visible: () => [0, 1, 2].includes(filterPad.tab),
    },
    {
      title: 'Enviar para análise',
      function: id => {
        trackEvent(user, 'Enviar para análise PAD');
        id && handleReviewOcorrencies(id);
      },
      visible: () =>
        [0].includes(filterPad.tab) && selectedOcorrencias.length < 2,
    },
    {
      title: 'Ver detalhe',
      function: id => {
        trackEvent(user, 'VER DETALHES PAD');
        handleNavigate(id);
      },
      visible: () =>
        [0, 1, 2].includes(filterPad.tab) && selectedOcorrencias.length < 2,
    },
    {
      title: 'Abrir em nova guia',
      function: id => {
        trackEvent(user, 'ABRIR EM NOVA GUIA PAD');
        openNewTab(id);
      },
      visible: () =>
        [0, 1, 2].includes(filterPad.tab) && selectedOcorrencias.length < 2,
    },
    {
      title: 'Imprimir',
      function: id => {
        trackEvent(user, 'NAVEGAR PARA OCORRENCIA');
        navigate(`/ocorrencia/${id}`);
      },
      visible: () => selectedOcorrencias.length < 2,
    },
    {
      title: 'Recusar',
      function: id => handleRefuseOcorrencies(id),
      visible: () => [1].includes(filterPad.tab),
    },
  ];

  const bulk = [
    {
      title: 'Finalizar',
      function: handleFinishOcorrencies,
      visible: () => filterPad.tab !== 2 && filterPad.tab !== 3,
    },
    {
      title: 'Deletar',
      function: handleDeleteOcorrencies,
      visible: () => filterPad.tab !== 3,
    },
    {
      title: 'Recusar',
      function: handleRefuseOcorrencies,
      visible: () => filterPad.tab === 1,
    },
  ];

  // ---------------------GENERAL REQUEST-------------------//
  const initial = useRef(true);
  const [resetTable, setResetTable] = useState(false);

  useEffect(() => {
    if (initial.current) {
      initial.current = false;
      return;
    }

    setResetTable(true);
  }, [filter, filterPad, selectedCard]);

  const {
    refetch: fetchData,
    isLoading: loadingOcorrencias,
    isFetching,
    data: resData,
  } = useQuery(
    ['pad-index', query],
    () => query && request.requestOcorrenciasPad(query),
    {
      refetchOnWindowFocus: false,
      onSuccess: () => resetTable && setResetTable(false),
    },
  );

  const {
    refetch: fetchCards,
    isLoading: loadingCards,
    data: resCards,
  } = useQuery(
    [
      'pad-cards',
      { ...filter, ...filterPad, tabStatus: tabs[filterPad.tab].status },
    ],
    () =>
      request.requestCards2({
        ...filter,
        ...filterPad,
        tabStatus: tabs[filterPad.tab].status,
      }),
    {
      refetchOnWindowFocus: false,
    },
  );

  const { data: resDrivers } = useQuery(
    ['pad-drivers'],
    () => request.selectDrivers(),
    { refetchOnWindowFocus: false },
  );
  const drivers = resDrivers?.data || [];

  const { data: resViolacoes } = useQuery(
    ['pad-violacoes'],
    () => request.selectViolacoes(),
    { refetchOnWindowFocus: false },
  );
  const violacoes = resViolacoes?.data || [];

  const { data: resResponsaveis } = useQuery(
    ['pad-responsaveis'],
    () => request.selectResponsibles(),
    { refetchOnWindowFocus: false },
  );
  const responsibles =
    resResponsaveis?.data.map(item => ({
      value: item.id,
      name: item.nome,
    })) || [];

  const { data: resClients } = useQuery(
    ['pad-clients'],
    () => request.selectClients(),
    { refetchOnWindowFocus: false },
  );

  const clients =
    resClients?.data.map(item => ({
      value: item.id,
      name: item.nome,
    })) || [];

  const renderCards = () => {
    const cards = resCards?.data?.data || initialize;

    return cards?.map((item, k) => {
      let md = 4;
      if (12 / cards?.length < 4) md = 12 / cards?.length;
      return (
        <Grid
          item
          md={md}
          key={k}
          style={{ fontSize: '13px', fontWeight: 600 }}
        >
          <CardIndicadores
            value={item.ids?.length}
            icon={iconsCard[k]}
            text={item?.text}
            border={`1px solid ${theme.palette.brand.primary.light}4D`}
            handleClick={() => handleClickCard(item?.type, item?.ids)}
            selected={selectedCard === item.type}
            loading={loadingCards}
            disabled={isFetching}
            smalltype={cards.length > 3 ? 'true' : undefined}
          />
        </Grid>
      );
    });
  };

  return (
    <Grid container>
      <Grid marginBottom={3} item md={12}>
        {renderTop()}
        <Tabs
          value={filterPad.tab}
          items={tabs}
          onChange={handleTabChange}
          last
          disabled={loadingOcorrencias}
        />
        <Divider />
      </Grid>
      <>
        <Grid marginBottom={3} item md={12}>
          <Grid item>
            <Grid container spacing={2}>
              {renderCards()}
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12}>
          <DefaultTable
            data={resData?.data?.rows || []}
            columns={columns[filterPad.tab]}
            setSelectedRows={setSelectedOcorrencias}
            loading={loadingOcorrencias}
            pageCount={resData?.data?.count || 0}
            local={false}
            bulk={bulk}
            actions={actions}
            reset={resetTable}
            onClickRow={handleNavigate}
            loadingSelection={loadingLines}
            setQuery={q =>
              setQuery({
                ...q,
                cardIds,
                ...filter,
                ...filterPad,
                card: selectedCard,
                tabStatus: tabs[filterPad.tab].status,
              })
            }
            sortBy={{ id: 'id', order: 'ASC' }}
            placeholder="Buscar por ID ou Motorista"
            searchEvent={search =>
              trackEvent(user, 'Busca PAD Ocorrências', null, search)
            }
          />
        </Grid>
      </>
      <ConfirmModal
        handleClose={() => setOpenConfirmFinishModal(false)}
        open={!!openConfirmFinishModal}
        title="Deseja finalizar esta(s) ocorrência(s)?"
        subtitle="O status desta(s) ocorrência(s) será atualizado para Finalizado."
        buttonText="Confirmar"
        onClick={() => finishOcorrencies()}
      />
      <ConfirmModal
        handleClose={() => setOpenConfirmDeleteModal(false)}
        open={!!openConfirmDeleteModal}
        title="Deseja excluir ocorrência(s)?"
        subtitle="Seus pontos deixarão de contar para os respectivos motoristas."
        buttonText="Confirmar"
        onClick={() => deleteOcorrencies()}
      />
      <ConfirmModal
        handleClose={() => setOpenConfirmRefuseModal(false)}
        open={!!openConfirmRefuseModal}
        title="Deseja recusar ocorrência(s)?"
        subtitle="O status desta(s) ocorrência(s) será atualizado para Pendente."
        buttonText="Confirmar"
        onClick={() => refuseOcorrencies()}
      />
      {reviewId && (
        <ReviewModal
          handleConfirm={reviewOcorrencies}
          ids={reviewId}
          handleClose={() => setReviewId(null)}
          responsaveis={responsibles}
          loading={loadingReview}
        />
      )}
      {openAddOcorrenciaModal && (
        <AddOcorrenciaModal
          open={openAddOcorrenciaModal}
          handleClose={() => setOpenAddOcorrenciaModal(false)}
          title="Criar Nova Ocorrência"
          drivers={drivers}
          violations={violacoes}
          responsibles={responsibles}
          clients={clients}
          closeModal={setOpenAddOcorrenciaModal}
          setUpdated={() => {
            fetchData();
            fetchCards();
          }}
        />
      )}

      {openExcelModal && (
        <ExcelModal
          onClick={handleRequestExcel}
          open={openExcelModal}
          handleClose={() => setOpenExcelModal(false)}
          title="Selecionar os campos de Excel"
          titleIcon={file}
          subtitle="Selecionar abaixo:"
          setData={setExcelFields}
          data={excelFields}
          loading={loadingExcel}
        />
      )}
    </Grid>
  );
};

export default PAD;
