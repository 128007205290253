// Icons
import { ReactComponent as gerais } from '../../../../images/formulario/gerais.svg';
import { ReactComponent as publico } from '../../../../images/formulario/publico.svg';
import { ReactComponent as config } from '../../../../images/formulario/config.svg';
import { ReactComponent as formulario } from '../../../../images/formulario/formulario.svg';
import { ReactComponent as reports } from '../../../../images/formulario/reports.svg';

// Components
import { InformacoesGerais } from '../../Config/InfoGerais';
import { Publico } from '../../Config/Publico';
import { Configuracoes } from '../../Config/Configuracoes';
import { Revisar } from '../../Config/Revisar';

export const itensBar = [
  {
    id: 0,
    page: 'Informações Gerais',
    linkTo: '',
    icon: gerais,
    Component: params => InformacoesGerais(params),
  },
  {
    id: 1,
    page: 'Público',
    linkTo: '',
    icon: publico,
    Component: params => Publico(params),
  },
  {
    id: 2,
    page: 'Configurações',
    linkTo: '',
    icon: config,
    Component: params => Configuracoes(params),
  },
  {
    id: 3,
    page: 'Formulário',
    linkTo: '',
    icon: formulario,
    Component: params => Revisar(params),
  },
  {
    id: 4,
    page: 'Reports',
    linkTo: '',
    icon: reports,
    Component: params => Revisar(params),
  },
];
