import { toast } from 'react-toastify';
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setSortBy } from 'store/modules/filterDesvios/actions';

// Componentes Reutilizaveis
import Tabs from 'components/Tabs';
import GhostButton from 'components/Buttons/Ghost';
import CardIndicadores from 'components/Cards/Indicador';

// Componentes do material
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';

// Serviços
import {
  changeCardExclusaoDesvio,
  changeTabExclusaoDesvio,
} from 'store/modules/provider/filterExclusaoDesvio/actions';

// Utils
import ExportToExcel from 'utils/exportToCvs';
import { formatNameDate } from 'utils/dates';
import { SaveAlt } from '@mui/icons-material';

import {
  columnsApproved2,
  columnsRejected2,
  columnsRevaluation2,
  columnsPending2,
} from './columns';
import * as request from './services';
import { fields } from './constants';
import JustifyModal from './JustifyModal';

import { DefaultTable } from 'components/_Table/templates/default';
import { useQuery } from 'react-query';
import { useTheme } from 'styled-components';

const ExclusaoDesvio = () => {
  const theme = useTheme();
  // Navigate
  const navigate = useNavigate();

  // Redux
  const dispatch = useDispatch();
  const filter = useSelector(state => state.filterProvider);
  const filterPage = useSelector(state => state.filterExclusaoDesvio);

  // General States
  const [col, setCol] = useState([]);
  const [query, setQuery] = useState(null);
  const [resetTable, setResetTable] = useState(false);
  const [loadingTab, setLoadingTab] = useState(true);
  const [loadingLines, setLoadingLines] = useState([]);
  const [selectedEvaluations, setSelectedEvaluations] = useState(null);

  // -------------------------TABS (TOP PAGE)---------------------------------------//
  const [oderTable, setOrderTable] = useState([]);

  const tabs = [
    {
      value: 0,
      label: 'Pendentes',
    },
    {
      value: 1,
      label: 'Reavaliação',
    },
    {
      value: 2,
      label: 'Aprovados',
    },
    {
      value: 3,
      label: 'Rejeitados',
    },
  ];

  useEffect(() => {
    switch (filterPage.exclusaoTab) {
      case 0:
        setCol(columnsPending2);
        setOrderTable({ id: 'data_exclusao', order: 'DESC' });
        break;
      case 1:
        setCol(columnsRevaluation2);
        setOrderTable({ id: 'data_revisao_distribuidora', order: 'DESC' });
        break;
      case 2:
        setCol(columnsApproved2);
        setOrderTable({ id: 'data_revisao_distribuidora', order: 'DESC' });
        break;
      case 3:
        setCol(columnsRejected2);
        setOrderTable({ id: 'data_revisao_distribuidora', order: 'DESC' });
        break;
    }
  }, [filterPage.exclusaoTab]);

  const handlePageChange = (e, tab) => {
    setLoadingTab(true);
    dispatch(changeCardExclusaoDesvio({ card: null }));
    dispatch(changeTabExclusaoDesvio({ tab }));
  };

  // -------------------------CARDS (TOP PAGE)---------------------------------------//

  const { selectedCard } = filterPage;

  const handleClickCard = type => {
    if (type === selectedCard)
      dispatch(changeCardExclusaoDesvio({ card: null }));
    else dispatch(changeCardExclusaoDesvio({ card: type }));
    setResetTable(true);
  };

  const renderCards = () => {
    const cards = resCards?.data?.data || [{}, {}, {}];
    return (
      <Grid item>
        <Grid container spacing={2}>
          {cards &&
            cards.map((item, idx) => {
              let md = 4;
              if (12 / cards.length < 4) md = 12 / cards.length;
              return (
                <Grid item key={idx} md={md} xs={12} sm={6}>
                  <CardIndicadores
                    value={item.ids?.length}
                    icon="Grupo12741.svg"
                    text={item.text}
                    border={`1px solid ${theme.palette.brand.primary.light}4D`}
                    handleClick={
                      item.type !== 'APROVADAS' &&
                      item.type !== 'RECUSADAS' &&
                      item.type !== 'TOTAL'
                        ? () => handleClickCard(item.type)
                        : null
                    }
                    selected={selectedCard === item.type}
                    loading={loadingCards}
                    disabled={isFetching}
                  />
                </Grid>
              );
            })}
        </Grid>
      </Grid>
    );
  };

  // -------------------------- BULK MENU & ACTION COLUMN---------------------------//

  const [justify, setJustify] = useState('');
  const [openRejectChanges, setOpenRejectChanges] = useState(false);
  const [openApproveChanges, setOpenApproveChanges] = useState(false);
  const [openMoreEvidences, setOpenMoreEvidences] = useState(false);

  const bulk = [
    {
      title: 'Aprovar',
      function: setOpenApproveChanges,
      visible: () => [0, 1].includes(filterPage.exclusaoTab),
    },
    {
      title: 'Rejeitar',
      function: setOpenRejectChanges,
      visible: () => [0, 1].includes(filterPage.exclusaoTab),
    },
  ];

  // ACTIONS
  const handleApproveChanges = async id => {
    const ids = selectedEvaluations.length > 0 ? selectedEvaluations : [id];
    setLoadingLines(ids);
    const body = {
      event: 'approveToggle',
      data: {
        ids,
        justify,
      },
    };

    const res = await request.toggleOperation(body);
    if (res.data.success) {
      fetchData();
      fetchCards();
      toast.success(res.data.message);
      setJustify('');
    } else {
      setLoadingLines([]);
      toast.error(res.data.message);
    }

    setOpenApproveChanges(false);
  };

  const handleRejectChanges = async id => {
    const ids = selectedEvaluations.length > 0 ? selectedEvaluations : [id];

    setLoadingLines(ids);

    const body = {
      event: 'rejectToggle',
      data: {
        ids,
        justify,
      },
    };

    const res = await request.toggleOperation(body);
    if (res.data.success) {
      fetchData();
      fetchCards();
      toast.success(res.data.message);
      setJustify('');
    } else {
      setLoadingLines([]);
      toast.error(res.data.message);
    }

    setOpenRejectChanges(false);
  };

  const handleMoreEvidences = async id => {
    if (justify.length < 10)
      return toast.warn(
        'Você precisa fornecer uma justificativa com pelo menos 10 caracteres.',
      );
    setLoadingLines([id]);

    const body = {
      event: 'moreEvidences',
      data: {
        ids: [id],
        justify,
      },
    };

    const res = await request.toggleOperation(body);
    if (res.data.success) {
      fetchData();
      fetchCards();
      toast.success(res.data.message);
      setJustify('');
    } else {
      setLoadingLines([]);
      toast.error(res.data.message);
    }

    setOpenMoreEvidences(false);
  };

  const getIndex = id => {
    const _data = [...(resData?.data?.rows ?? [])];
    dispatch(setSortBy(query.sortBy));

    const dataIndex = _data.findIndex(item => item.id == id);
    if (dataIndex !== -1) {
      return query.pageSize * query.pageIndex + dataIndex;
    }
    return '';
  };

  const handleNewTab = id => {
    window.open(`/desvios/${id}?index=${getIndex(id)}`);
  };

  const handleOpenSameTab = id => {
    return navigate(`/desvios/${id}?index=${getIndex(id)}`);
  };

  const handleView = async id => {
    const ids = selectedEvaluations.length > 0 ? selectedEvaluations : [id];

    setLoadingLines(ids);

    const body = {
      event: 'view',
      data: {
        ids,
      },
    };

    const res = await request.toggleOperation(body);
    if (res.data.success) {
      fetchData();
      fetchCards();
      toast.success(res.data.message);
    } else {
      setLoadingLines([]);
      toast.error(res.data.message);
    }

    setOpenApproveChanges(false);
  };

  const actions = [
    {
      title: 'Abrir em nova guia',
      visible: () =>
        [0, 1].includes(filterPage.exclusaoTab) && !selectedEvaluations?.length,
      function: handleNewTab,
    },
    {
      title: 'Aprovar',
      visible: () => [0, 1].includes(filterPage.exclusaoTab),
      function: setOpenApproveChanges,
    },
    {
      title: 'Rejeitar',
      visible: () => [0, 1].includes(filterPage.exclusaoTab),
      function: setOpenRejectChanges,
    },
    {
      title: 'Solicitar Evidências',
      visible: () =>
        [0].includes(filterPage.exclusaoTab) && !selectedEvaluations?.length,
      function: setOpenMoreEvidences,
    },
    {
      title: 'Marcar como visto',
      visible: (_, item) =>
        [0, 1].includes(filterPage.exclusaoTab) &&
        !selectedEvaluations?.length &&
        !item?.visto,
      function: handleView,
    },
    {
      title: 'Ver detalhe',
      visible: () =>
        [0, 1].includes(filterPage.exclusaoTab) && !selectedEvaluations?.length,
      function: handleOpenSameTab,
    },
  ];

  // -------------------------- REQUESTS ------------------------------------------//
  const initial = useRef(0);
  useEffect(() => {
    if (initial.current < 2) {
      initial.current++;
      return;
    }

    setResetTable(true);
  }, [filter, filterPage]);

  const {
    isLoading: loadingInfractions,
    isFetching,
    refetch: fetchData,
    data: resData,
  } = useQuery(
    ['exclude-infra-index', query],
    () => query && request.requestDesviosList(query),
    {
      refetchOnWindowFocus: false,
      onSuccess: () => {
        setLoadingTab(false);
        setLoadingLines([]);
        resetTable && setResetTable(false);
      },
    },
  );

  const {
    isLoading: loadingCards,
    data: resCards,
    refetch: fetchCards,
  } = useQuery(
    ['exclude-infra-cards', { ...filter, ...filterPage }],
    () =>
      request.requestCards({
        ...filter,
        ...filterPage,
        status: filterPage.exclusaoTab,
      }),
    {
      refetchOnWindowFocus: false,
    },
  );

  // -------------------------- EXCEL ------------------------------------------//

  const [excelFields, setExcelFields] = useState(fields);

  const handleRequestExcel = async () => {
    const newFields = excelFields.filter(item => item.selected === true);
    const formatedDate = formatNameDate(new Date());

    let { initialDate } = query;
    let { finalDate } = query;
    initialDate = Date.parse(initialDate);
    finalDate = Date.parse(finalDate);

    if (finalDate - initialDate > 7889400000 && !selectedEvaluations.length) {
      toast.error('Não é possível exportar períodos maiores que 3 meses');
      return;
    }

    const newQuery = {
      ...query,
      ...filterPage,
      status: filterPage.exclusaoTab,
      excelFields: newFields,
      ids: selectedEvaluations,
    };

    const res = await request.requestExcel(newQuery);
    if (!res.data.success) {
      toast.error(res.data.message);
      return;
    }

    if (res.data && res.data.data.excel)
      ExportToExcel({
        excel: res.data.data.excel,
        name: `exclusao_desvios_${formatedDate}`,
      });
  };

  return (
    col && (
      <Grid container>
        <Grid container>
          <Grid
            marginBottom={3}
            item
            md={12}
            display="flex"
            justifyContent="space-between"
          >
            <h1>Exclusão de Desvio</h1>
            <GhostButton
              children="Exportar"
              startIcon={<SaveAlt />}
              onClick={() => handleRequestExcel()}
            />
          </Grid>
          <Grid marginBottom={0} item md={12} display="flex">
            <Tabs
              value={filterPage.exclusaoTab}
              items={tabs}
              onChange={handlePageChange}
              disabled={isFetching}
            />
          </Grid>
        </Grid>
        <Grid marginBottom={3} item md={12}>
          <Divider />
        </Grid>
        <Grid marginBottom={3} item md={12}>
          {renderCards()}
        </Grid>
        <Grid item md={12}>
          <DefaultTable
            persist="exclusao-desvio-provider"
            data={resData?.data?.rows || []}
            columns={col}
            setSelectedRows={setSelectedEvaluations}
            loading={loadingInfractions || loadingTab}
            pageCount={resData?.data?.total || 0}
            local={false}
            bulk={bulk}
            actions={
              filterPage.exclusaoTab === 2 || filterPage.exclusaoTab === 3
                ? undefined
                : actions
            }
            visualizedKey="visto"
            loadingSelection={loadingLines}
            reset={resetTable}
            onClickRow={handleOpenSameTab}
            setQuery={q =>
              setQuery({
                ...q,
                ...filter,
                ...filterPage,
                status: filterPage.exclusaoTab,
                card: selectedCard,
              })
            }
            sortBy={oderTable}
            placeholder="Buscar ID, placa..."
            empty={{
              title: 'Ops! Você não tem nenhuma solicitação de exclusão',
              description: 'Verifique os filtros selecionados.',
            }}
          />
        </Grid>

        <JustifyModal
          handleClose={() => setOpenApproveChanges(false)}
          open={!!openApproveChanges}
          title="Tem certeza que deseja aprovar?"
          subtitle="Desvios deletados serão movidos para aba de aprovados"
          buttonText="Confirmar"
          onClick={() => handleApproveChanges(openApproveChanges)}
          value={justify}
          onChange={setJustify}
        />

        <JustifyModal
          handleClose={() => setOpenRejectChanges(false)}
          open={!!openRejectChanges}
          title="Tem certeza que deseja rejeitar?"
          subtitle="Desvios recusados serão movidos para aba de rejeitados"
          buttonText="Confirmar"
          onClick={() => handleRejectChanges(openRejectChanges)}
          value={justify}
          onChange={setJustify}
        />

        <JustifyModal
          handleClose={() => setOpenMoreEvidences(false)}
          open={!!openMoreEvidences}
          title="Tem certeza que deseja solicitar evidências?"
          subtitle="Desvios com solicitação aberta serão movidos para aba de reavaliação"
          buttonText="Confirmar"
          onClick={() => handleMoreEvidences(openMoreEvidences)}
          value={justify}
          onChange={setJustify}
        />
      </Grid>
    )
  );
};

export default ExclusaoDesvio;
