import styled, { css } from 'styled-components';
import { Modal } from '@mui/material';

export const ModalMUI = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  ${({ theme }) => css`
    width: 500px;
    height: 200px;
    border-radius: 5px;
    background-color: ${theme.palette.system.overlay};
    display: flex;
    flex-direction: column;
  `}
`;

export const Header = styled.div`
  ${({ theme }) => css`
    display: flex;
    border-bottom: 1px solid ${theme.palette.system.divider};
    height: 60px;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;

    .left {
      display: flex;
      align-items: center;
      gap: 10px;

      .title {
        font-size: 20px;
        color: ${theme.palette.words.text.natural};
        font-weight: 900;
      }

      .MuiSvgIcon-root {
        color: ${theme.palette.brand.secondary.natural};
      }
    }

    .right {
      .MuiSvgIcon-root {
        color: ${theme.palette.brand.primary.natural};
        cursor: pointer;
      }
    }
  `}
`;

export const Body = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: 10px 20px;
`;

export const Footer = styled.div`
  ${({ theme }) => css`
    height: 60px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    padding: 0px 10px;

    border-top: 1px solid ${theme.palette.system.divider};
  `}
`;
