export const getColumns = ({
  readOnly,
  handleAtivar,
  handleDesativar,
  client = false,
}) => {
  const columns = [
    {
      header: 'Status',
      id: 'ativo',
      type: 'boolean',
      switch: {
        value: (_, item) => item.ativo,
        onChange: (value, item) =>
          value ? handleAtivar(null, item) : handleDesativar(null, item),
      },
      sort: true,
      width: 105,
    },
    {
      header: 'Título',
      id: 'titulo',
      type: 'string',
      sort: true,
      width: 250,
      tooltip: true,
      conditional: [
        {
          condition: _ => true,
          style: (theme, item) => ({
            color: item.cor,
            textTransform: 'uppercase',
          }),
        },
      ],
    },
    {
      header: 'Pontuação inicial',
      id: 'limiar',
      type: 'number',
      sort: true,
      align: 'center',
    },
    {
      header: 'Pontuação final',
      id: 'limiar_final',
      type: 'number',
      sort: true,
      align: 'center',
    },
    {
      header: 'Ação disciplinar',
      id: 'acao_disciplinar.nome',
      type: 'string',
      attention: (_, item) => {
        if (client && item.is_obrigatorio)
          return {
            text: 'Suspensão/Capacitação Obrigatório',
          };
      },
    },
    {
      header: 'Capacitação',
      id: 'conteudo.titulo',
      type: 'string',
      tooltip: true,
      value: (v, item) =>
        item.conteudo
          ? item.conteudo.titulo
          : item.acao_suspensao_reincidencia?.length
          ? item.acao_suspensao_reincidencia
              ?.map(r => r.conteudo?.titulo ?? '')
              .join(', ')
          : '-',
    },
  ];

  return readOnly ? columns.slice(1) : columns;
};
