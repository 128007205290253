import styled, { css } from 'styled-components';

export const Paper = styled.div`
  ${({ theme }) => css`
    position: absolute;
    width: 712px;
    overflow: scroll;
    background-color: ${theme.palette.system.overlay};
    border: 0px 5px 20px ${theme.palette.system.border};
    padding: 25px;
    margin: 0 auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `}
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
`;

export const Title = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    h1 {
      color: ${theme.palette.words.title.natural};
      font: normal 900 20px Texta;
      margin-left: 10px;
      margin: 0;
      padding: 0;
    }
  `}
`;

export const Main = styled.div`
  display: flex;
  width: 100%;
  flex-grow: 1;
  align-items: center;
  flex-direction: column;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 15px;
`;

export const StyledToast = styled.div`
  ${({ theme }) => css`
    margin-top: 20px;
    font-size: 16px;
    color: ${theme.palette.words.title.natural};

    span {
      font-weight: 800;
    }

    p {
      margin: 10px 0px;
    }
  `}
`;
