// Pages
import ConfiguracoesProvider from 'pages/Provider/Configuracoes';
import LogUsuario from 'pages/Configuracoes/Usuarios/History';
import { PerfilUsuario } from 'pages/Configuracoes/Usuarios/PerfilUsuario';
import LogParametrizacoes from 'pages/Provider/Configuracoes/Configuracoes/History';
import Historico from 'pages/Historico';
import { Empresas } from 'pages/Provider/Configuracoes/Empresas';

export default {
  '/configuracoes/perfil': {
    defaults: [],
    back: -1,
    subTitle: 'Minha Conta',
    title: 'Perfil & Empresa',
    page: <ConfiguracoesProvider />,
  },
  '/configuracoes/empresas': {
    defaults: [],
    back: -1,
    subTitle: 'Minha Conta',
    title: 'Empresas',
    page: <ConfiguracoesProvider />,
  },
  '/configuracoes/personalizacao': {
    defaults: [],
    back: -1,
    subTitle: 'Minha Conta',
    title: 'Personalização',
    page: <ConfiguracoesProvider />,
  },

  '/configuracoes/usuarios': {
    defaults: [],
    subTitle: 'Minha Conta',
    title: 'Usuários',
    back: -1,
    page: <ConfiguracoesProvider />,
  },

  '/configuracoes/usuarios/criar': {
    back: -1,
    subTitle: 'Minha Conta',
    title: 'Novo usuário',
    defaults: [],
    page: <PerfilUsuario />,
  },

  '/configuracoes/usuarios/:id': {
    back: -1,
    subTitle: 'Minha Conta',
    title: 'Perfil do usuário',
    defaults: [],
    page: <PerfilUsuario />,
  },

  '/configuracoes/bases': {
    defaults: [],
    subTitle: 'Minha Conta',
    title: 'Minhas Bases',
    back: -1,
    page: <ConfiguracoesProvider />,
  },

  '/configuracoes/notificacoes': {
    defaults: [],
    subTitle: 'Minha Conta',
    title: 'Notificações',
    back: -1,
    page: <ConfiguracoesProvider />,
  },

  '/configuracoes/relatorios': {
    back: -1,
    subTitle: 'Minha Conta',
    title: 'Relatórios',
    defaults: [],
    page: <ConfiguracoesProvider />,
  },

  '/configuracoes/seguranca': {
    defaults: [],
    subTitle: 'Minha Conta',
    title: 'Segurança',
    back: -1,
    page: <ConfiguracoesProvider />,
  },

  '/configuracoes/configuracoes': {
    defaults: [],
    back: -1,
    subTitle: 'Minha Conta',
    title: 'Configurações',
    page: <ConfiguracoesProvider />,
  },

  '/configuracoes/escalation-list': {
    back: -1,
    subTitle: 'Configurações',
    title: 'Escalation List',
    defaults: [],
    page: <ConfiguracoesProvider />,
  },

  '/configuracoes/escalation-list/nova-list': {
    back: -1,
    subTitle: 'Escalation List',
    title: 'Nova lista',
    defaults: [],
    page: <ConfiguracoesProvider />,
  },

  '/logs/configuracoes/escalation-list/:id': {
    back: -1,
    title: 'Histórico da Configuração do Escalation List',
    id: true,
    defaults: [],
    page: (
      <Historico
        back={-1}
        url="/escalation-list/:id/logs"
        formatReturn={data =>
          data.map(log => ({
            ...log,
            data: log.data ?? log.createdAt,
            evento: log.evento?.evento ?? '',
          }))
        }
      />
    ),
  },

  '/configuracoes/guia': {
    back: -1,
    subTitle: 'Minha Conta',
    title: 'Guia do Usuário',
    defaults: [],
    page: <ConfiguracoesProvider />,
  },
  '/configuracoes/guia-provider': {
    back: -1,
    subTitle: 'Minha Conta',
    title: 'Guia do Usuário Provider',
    defaults: [],
    page: <ConfiguracoesProvider />,
  },
  '/logs/usuario/:id': {
    back: '/configuracoes/usuarios',
    title: 'Histórico de alterações - Usuário',
    defaults: [],
    page: <LogUsuario />,
  },
  '/logs/parametrizacoes': {
    back: -1,
    title: 'Histórico de alterações - Configurações',
    defaults: [],
    page: <LogParametrizacoes />,
  },
};
