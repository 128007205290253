import styled, { css } from 'styled-components';

export const Container = styled.div`
  padding: 10px;
  display: flex;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  border-radius: 4px;
  border: 1px solid #95aac94d;
  .main-card {
    display: flex;
    align-items: center;
    flex-direction: column;
    .title {
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: medium;
      letter-spacing: 0px;
      color: #4b5166;
      opacity: 1;
    }
    .middle {
      text-align: center;
      font-size: 64px;
      font-style: normal;
      font-weight: 900;
      letter-spacing: 0px;
      color: #4b5166;
      opacity: 1;
    }
    .bottom {
      text-align: center;
      font-size: 11px;
      font-weight: 900;
      font-style: normal;
      letter-spacing: 0px;
      color: #bdcc30;
      opacity: 1;
    }

    .all-cards {
    }
  }
`;
export const Cards = styled.div`
  height: 96px;
  width: 100%;
  background-color: #f9fbfd;
  border: 1px solid #95aac94d;
  border-radius: 4px;
  margin-top: 8px;
  overflow: hidden;
  padding: 8px;
  .container-info {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
  }
  .container-info div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .container-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 34px;
    height: 34px;
    border-radius: 8px;
  }
  .icon-color1 {
    background: #f64e6033;
  }
  .icon-color2 {
    background: #95aac94d;
  }
  .title-card {
    font-weight: 900;
    font-size: 32px;
    letter-spacing: 0px;
    color: #4b5166;
    margin-left: 10px;
  }
  .title-bottom {
    text-align: left;
    font-size: 14px;
    font-style: normal;
    font-weight: medium;
    letter-spacing: 0px;
    color: #4b5166;
  }
`;
