/* eslint-disable import/order */

// Styles
import * as S from './styled';
import colors from 'styles/colors';

// React
import ReactPlayer from 'react-player';
import { useSelector } from 'react-redux';
import React, { useState, useEffect, useRef } from 'react';

// Components
import TextInput from 'components/Inputs/TextField';
import { toast } from 'react-toastify';

// Material UI
import { IconButton } from '@mui/material';
import BackupOutlinedIcon from '@mui/icons-material/BackupOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

// Utils
import { firestorage } from 'utils/firebase';

export const VideoUploading = ({
  item,
  setChanged,
  media,
  index,
  setOpenConfirmDelete,
  deleteMedia,
  error,
  isPublished,
}) => {
  const contents = useSelector(state => state.createContents.contents);
  const videoRef = useRef(null);
  const [block, setBlock] = useState(false);
  const [progress, setProgress] = useState(0);

  const [localVideo, setLocalVideo] = useState(item.link);
  const [videoTextoUploading, setVideoTextoUploading] = useState(item.titulo);
  const [videoDescriptionUploading, setVideoDescriptionUploading] = useState(
    item.descricao,
  );

  const onLoadVideo = async e => {
    const file = e.target.files[0];

    if (file?.size > 2 * 1024 * 1024 * 1024) {
      toast.warning(`Não é possível incluir arquivo maior que 2GB`);
      return;
    }
    setBlock(true);
    const blobURL = URL.createObjectURL(file);
    setLocalVideo(blobURL);
    await savedVideoFirebase(file);
  };

  const savedVideoFirebase = async data => {
    const file = data;
    const fileReference = firestorage
      .ref(`/capacitacao/media/video/${getId()}`)
      .child(file.name);
    const uploadTask = fileReference.put(data);
    uploadTask.on(
      'state_changed',
      snapShot => {
        const progress =
          (snapShot.bytesTransferred / snapShot.totalBytes) * 100;
        setProgress(progress);
      },
      err => {
        console.log('Err', err);
      },
      async () => {
        await fileReference.getDownloadURL().then(fireBaseUrl => {
          setLocalVideo(fireBaseUrl);
          setBlock(false);
        });
      },
    );
  };

  useEffect(() => {
    Object.assign(media[index], {
      link: localVideo,
      tipo: 'VÍDEO_UPLOADING',
      descricao: videoDescriptionUploading,
      titulo: videoTextoUploading,
    });
    setChanged(true);
  }, [localVideo, videoTextoUploading, videoDescriptionUploading]);

  const getId = () => {
    if (contents.find(a => a.id)) {
      return contents[0].id;
    }
    return new Date().getTime();
  };

  const hanldeDelete = () => {
    if (localVideo || videoTextoUploading || videoDescriptionUploading) {
      setOpenConfirmDelete(true);
    } else {
      setChanged(true);
      deleteMedia();
    }
  };

  return (
    <div style={{ width: '100%' }}>
      <div style={{ marginTop: 15, marginBottom: 20 }}>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton onClick={() => hanldeDelete()}>
            <DeleteOutlineOutlinedIcon htmlColor={colors.redDanger} />
          </IconButton>
        </div>
        <S.Container>
          <TextInput
            label="Titulo"
            placeholder="Titulo"
            value={videoTextoUploading}
            onChange={e =>
              setVideoTextoUploading(e.target.value.slice(0, 200), index)
            }
            error={error?.titulo && !videoTextoUploading}
            disabled={isPublished && !!item.id}
          />
          <TextInput
            label="Descrição"
            placeholder="Descrição"
            value={videoDescriptionUploading}
            onChange={e =>
              setVideoDescriptionUploading(e.target.value.slice(0, 200))
            }
            error={error?.descricao && !videoDescriptionUploading}
            disabled={isPublished && !!item.id}
          />
        </S.Container>

        <S.MediaArchive>
          {block && (
            <S.VideoCounter>
              <div className="video-progress">
                <label>FAZENDO UPLOAD</label>
                <div className="bar">
                  <div style={{ width: `${progress}%` }} className="progress" />
                </div>
              </div>
              <div style={{ height: 350, width: 'auto' }} />
            </S.VideoCounter>
          )}
          {localVideo !== '' && !block && (
            <S.VideoCounter>
              <ReactPlayer
                controls
                playing={false}
                url={localVideo}
                height="500px"
                width="100%"
              />
            </S.VideoCounter>
          )}
        </S.MediaArchive>
        <S.ContentButton>
          {!block && (!isPublished || !item.id) && (
            <>
              {!localVideo && <S.Label>Upload Vídeo</S.Label>}

              <S.VideoUploading isError={error?.link && !localVideo}>
                <input
                  ref={videoRef}
                  type="file"
                  onChange={onLoadVideo}
                  accept="video/mp4,video/x-m4v,video/*"
                  disabled={isPublished && !!item.id}
                />
                <BackupOutlinedIcon sx={{ color: colors.grey }} />
                <label>{localVideo ? 'Alterar Vídeo' : 'Upload Vídeo'}</label>
              </S.VideoUploading>
            </>
          )}
        </S.ContentButton>
      </div>
    </div>
  );
};
