import * as types from './types';

const INITIAL_STATE = {
  tipo: '',
  status: 'PENDENTE',
  responsavel: '',
  criticidade: '',
  carga: '',
};

export default function fiterDesvioDescarga(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.SET_FILTER: {
      return { ...state, ...action.payload };
    }
    case types.RESET_FILTER: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
}
