// React
import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

// Styles

// icons and styles
import CSVDOWN from 'images/icons/conteudo/alunos/csvicon.svg';
import CSVUP from 'images/icons/conteudo/alunos/csvuploading.svg';

// components
import ButtonDefault from 'components/Buttons/Default';
import ButtonGhost from 'components/Buttons/Ghost';
import * as SG from '../style';
import * as S from './styled';

const Upload = () => {
  const refFile = useRef(null);

  const handleFileChange = event => {
    // let m = refFile.currentTarget.files
    const file = Object(event.currentTarget.files)[0];
  };

  return (
    <div>
      <div>
        <h1>Adicionar em Massa - Alunos</h1>
      </div>
      <S.Container>
        <div className="left">
          <div>
            <img src={CSVDOWN} />
          </div>
          <div className="info">
            <div className="title">1. Baixar modelo</div>
            <div className="subtitle">
              Baixe em seu computador o arquivo modelo, preencha-o e faça o
              upload. Não modifique o tipo do arquivo, ele deve ser .CSV.
            </div>
            <div>
              <ButtonDefault>BAIXAR MODELO</ButtonDefault>
            </div>
          </div>
        </div>
        <div className="middle" />
        <div className="right">
          <div>
            <img src={CSVUP} />
          </div>
          <div className="info">
            <div className="title">2. Fazer upload</div>
            <input
              style={{ display: 'none' }}
              onChange={handleFileChange}
              ref={refFile}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              type="file"
            />
            <div className="subtitle">
              Procure em seu computador o arquivo modelo e faça o upload. Não
              modifique o tipo do arquivo, ele deve ser .CSV.
            </div>
            <div>
              <ButtonGhost onClick={() => refFile.current.click()}>
                FAZER UPLOAD
              </ButtonGhost>
            </div>
          </div>
        </div>
      </S.Container>
    </div>
  );
};

export default Upload;
