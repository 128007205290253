import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';

// Components
import { AddCircleOutline, SaveAlt } from '@mui/icons-material';
import ExcelModal from 'components/ExcelModal';

import { trackEvent } from 'utils/mixpanel';
import { formatNameDate } from 'utils/dates';
import ExportToExcel from 'utils/exportToCvs';
import { columns, fields, resetExcelFields } from './constants';
import { ReactComponent as file } from 'images/icons/sidebar/file.svg';

import {
  desactivateConfig,
  requestConfigurations,
  requestExcel,
} from './services';
import ConfirmModal from 'components/ConfirmModal';
import ListPage from 'pages/_templates/ListPage';

const TelemetriaConfiguracoes = () => {
  // Redux e hooks
  const navigate = useNavigate();
  const user = useSelector(state => state.auth?.user?.user);
  const isProvider = user?.provider;
  const filter = useSelector(state =>
    isProvider ? state.filterProvider : state.filter,
  );
  const filterDesviosConfiguracoes = useSelector(state =>
    isProvider
      ? state.filterDesviosConfiguracoesProvider
      : state.filterDesviosConfiguracoes,
  );

  const [query, setQuery] = useState(null);
  const [desativar, setDesativar] = useState(null);
  const [resetTable, setResetTable] = useState(false);
  const [loadingLines, setLoadingLines] = useState([]);

  // Excel Fields
  const [openExcelModal, setOpenExcelModal] = useState(false);
  const [excelFields, setExcelFields] = useState(fields);
  const [loadingExcel, setLoadingExcel] = useState(false);

  const {
    refetch: fetchData,
    isLoading,
    data: resData,
  } = useQuery(
    ['configuracoes-desvios', query],
    () => query && requestConfigurations(query),
    {
      refetchOnWindowFocus: false,
      onSuccess: () => {
        setLoadingLines([]);
        resetTable && setResetTable(false);
      },
    },
  );

  const initial = useRef(true);
  useEffect(() => {
    if (initial.current) {
      initial.current = false;
      return;
    }

    setResetTable(true);
  }, [filter, filterDesviosConfiguracoes]);

  const handleAtivar = async item => {
    if (!item.id_empresa && !isProvider)
      return toast.warning('Esse grupo foi criado pelo cliente');
    if (item.ativo) return toast.success('Esse grupo já está ativo');

    navigate(`/desvios/configuracoes/${item.id}`);
  };

  const handleDesativar = async item => {
    if (!item.id_empresa && !isProvider)
      return toast.warning('Esse grupo foi criado pelo cliente');
    if (!item.ativo) return toast.success('Esse grupo já está inativo');

    item?.titulo !== 'Desvios Onisys'
      ? setDesativar(item)
      : toast.warning('Esse grupo foi criado pelo cliente');
  };

  const confirmDesativar = async item => {
    const res = await desactivateConfig(item.id);
    if (res.success) {
      fetchData();
      toast.success(res.message);
    } else toast.error(res.message);
    setDesativar(null);
  };

  const handleRequestExcel = async () => {
    setLoadingExcel(true);

    const newFields = excelFields.filter(item => item.selected === true);
    const formatedDate = formatNameDate(new Date());

    const newQuery = {
      ...filter,
      ...filterDesviosConfiguracoes,
      excelFields: newFields,
    };

    const res = await requestExcel(newQuery);
    if (res.data && res.data?.data?.excel) {
      ExportToExcel({
        excel: res.data.data.excel,
        name: `desvios_${formatedDate}`,
      });
      toast.success(res.data.message);
    } else if (res.data.message) toast.error(res.data?.message);

    setExcelFields(resetExcelFields(fields));
    setLoadingExcel(false);
    setOpenExcelModal(false);
  };

  const actions = [
    {
      title: 'Ativar',
      function: (id, item) => handleAtivar(item),
    },
    {
      title: 'Desativar',
      function: (id, item) => handleDesativar(item),
    },
    {
      title: 'Editar',
      function: id => navigate(`/desvios/configuracoes/${id}`),
    },
    {
      title: 'Ver histórico',
      function: id => navigate(`/logs/desvios/configuracoes/${id}`),
    },
  ];

  const getColumns = cols =>
    cols
      .filter(col => !isProvider || col.id !== 'distribuidora.nome')
      .map(col => {
        if (col.id === 'ativo')
          return {
            ...col,
            switch: {
              value: (_, item) => item.ativo,
              onChange: (value, item) =>
                value ? handleAtivar(item) : handleDesativar(item),
            },
          };
        if (col.id === '')
          return {
            ...col,
            attention: (_, item) => {
              if (!item.id_empresa && !isProvider)
                return {
                  text: 'Desvio configurado pelo cliente',
                };
            },
          };
        return col;
      });

  return (
    <>
      <ListPage
        title="Configurações"
        headerActions={[
          {
            title: 'Exportar',
            variant: 'ghost',
            icon: <SaveAlt />,
            function: () => {
              trackEvent(user, 'EXPORTAR PLANILHA DESVIOS');
              setOpenExcelModal(true);
            },
          },
          {
            title: 'Criar grupo',
            variant: 'default',
            icon: <AddCircleOutline />,
            function: () => navigate(`/desvios/configuracoes/criar`),
          },
        ]}
        tableProps={{
          data:
            resData?.data?.data.map(item => ({ ...item, isProvider })) || [],
          columns: getColumns(columns),
          loading: isLoading,
          pageCount: resData?.data?.total || 0,
          local: false,
          actions,
          reset: resetTable,
          loadingSelection: loadingLines,
          setQuery: q =>
            setQuery({ ...q, ...filter, ...filterDesviosConfiguracoes }),
          searchEvent: search =>
            trackEvent(user, 'Busca Desvios de Direção', null, search),
          sortBy: { id: 'titulo', order: 'DESC' },
          placeholder: 'Buscar Desvio',
          empty: {
            title:
              'Ops! Você não tem nenhuma configuração de desvio disponível.',
            description: 'Verifique os filtros aplicados!',
          },
        }}
      />

      {openExcelModal && (
        <ExcelModal
          onClick={handleRequestExcel}
          open={openExcelModal}
          handleClose={() => setOpenExcelModal(false)}
          title="Selecionar os campos de Excel"
          titleIcon={file}
          subtitle="Selecionar abaixo:"
          setData={setExcelFields}
          data={excelFields}
          loading={loadingExcel}
        />
      )}

      {desativar && (
        <ConfirmModal
          handleClose={() => setDesativar(null)}
          open={!!desativar}
          title="Tem certeza que deseja desabilitar este grupo?"
          subtitle="Todas os desvios do grupo serão desabilitados"
          buttonText="Desabilitar"
          onClick={() => confirmDesativar(desativar)}
        />
      )}
    </>
  );
};

export default TelemetriaConfiguracoes;
