import React from 'react';
import * as S from './styled';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import { usePlans } from 'hooks/usePlans';

const Item = ({
  name,
  points,
  nome_empresa,
  deviations,
  primarios,
  moderados,
  graves,
  gravissimos,
  id,
}) => {
  const navigate = useNavigate();
  const { hasTorre, hasTorrePlus } = usePlans();
  const isTorreSafaty = hasTorre || hasTorrePlus;

  const navigateTo = id => {
    if (id) navigate(`/motoristas/${id}`);
  };

  const capitalizeFirstLetter = str => {
    return str
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  return (
    <S.ContainerItem>
      <div>
        <div
          className="container-content"
          style={{ marginBottom: 2, marginTop: 2 }}
        >
          {!isTorreSafaty ? (
            <div className="name">
              <Tooltip title={name}>
                {name?.length > 20 ? `${name?.substring(0, 20)}...` : name}
              </Tooltip>
              <span>{points}pts</span>
            </div>
          ) : (
            <div className="title-empresa">
              {capitalizeFirstLetter(nome_empresa)}
            </div>
          )}

          <div className="desvios" onClick={() => navigateTo(id)}>
            {deviations} desvios <KeyboardArrowRightIcon />
          </div>
        </div>
        {isTorreSafaty && (
          <div className="torre-driver-name">
            {' '}
            <Tooltip title={name}>
              {capitalizeFirstLetter(
                name?.length > 20 ? `${name?.substring(0, 20)}...` : name,
              )}
            </Tooltip>
            <div className="points-torre-driver">{points}pts</div>
          </div>
        )}
      </div>
      <div className="divider" />
      <div className="container-content">
        <div className="criticidade primary">
          Primário: <span>{primarios}</span>
        </div>
        <div className="criticidade moderado">
          Moderado: <span>{moderados}</span>
        </div>
        <div className="criticidade grave">
          Grave: <span>{graves}</span>
        </div>
        <div className="criticidade gravissimo">
          Gravíssimo: <span>{gravissimos}</span>
        </div>
      </div>
    </S.ContainerItem>
  );
};

export default Item;
