import React, { useEffect, useState } from 'react';
import { MenuItem, IconButton, Popover, List } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import FormControl from '@mui/material/FormControl';

import { useTheme } from 'styled-components';
import * as S from './styled';
import Tag from './Tag';

/*
data: lista de objetos com
    {
        value: valor do id correspondente,
        label: string do nome do objeto selecionado
    }

fieldName: nome da variável armazenada. Substitui a necessidade de:
    > handleChange={(e) => handleChange(e.target.value, 'name')}
    por:
        > handleChange={handleChange}
        > fieldName: 'name'

value: array com ids selecionados. Ex: '[1, 3]'

*/

const SelectTags = ({
  value,
  handleChange,
  data,
  fieldName,
  label,
  disabled,
  required,
  message,
  ...props
}) => {
  const theme = useTheme();
  const [anchor, setAnchor] = useState(null);
  const [backupItems, setBackupItems] = useState([]);
  const [search, setSearch] = useState('');
  const [ids, setIds] = useState([]);

  const handleSelect = value => {
    if (ids.includes(value)) {
      const updateSelect = ids.filter(id => id !== value);
      formtedItems(updateSelect);
    } else {
      const updateSelect = [...ids, value];
      formtedItems(updateSelect);
    }
    setAnchor(null);
  };

  useEffect(() => {
    setBackupItems(data);
  }, [data]);

  useEffect(() => {
    let items = [];
    if (value) {
      items = value;
    }

    for (const i in items) {
      if (Number(items[i])) {
        items[i] = Number(items[i]);
      }
    }

    const __IDS = [];

    for (const i in data) {
      if (items.includes(data[i].value)) {
        __IDS.push(data[i].value);
      }
    }

    setIds(__IDS);
  }, [value, data]);

  useEffect(() => {
    const searchedParams = [];
    for (const i in data) {
      const name = data[i].label.toLowerCase();
      if (name.includes(search)) {
        searchedParams.push(data[i]);
      }
    }
    if (searchedParams.length === 0) {
      setBackupItems(data);
    } else {
      setBackupItems(searchedParams);
    }
    setBackupItems(searchedParams);
  }, [search]);

  const formtedItems = items => {
    const __IDS = [];

    for (const i in data) {
      if (items.includes(data[i].value)) {
        __IDS.push(data[i].value);
      }
    }

    setIds(__IDS);
    handleChange(__IDS, fieldName);
  };

  return (
    <div>
      <FormControl sx={{ width: '100%' }}>
        {label && (
          <S.Label>
            {label}
            {required && <S.Required>*</S.Required>}
          </S.Label>
        )}
        <S.Container>
          <S.BoxTags>
            {data
              .filter(item => ids.includes(item.value))
              .map(i => (
                <Tag
                  key={i.value}
                  children={i.label}
                  active
                  onClick={() => handleSelect(i.value)}
                />
              ))}
            <IconButton
              onClick={e => setAnchor(e.currentTarget)}
              disabled={disabled}
            >
              <AddIcon />
            </IconButton>
          </S.BoxTags>
          <Popover
            open={!!anchor}
            anchorEl={anchor}
            onClose={() => setAnchor(null)}
          >
            <S.SearchBox>
              <SearchOutlinedIcon
                className="searchIcon"
                htmlColor={theme.palette.semantics.feedback.unknown.natural}
              />
              <S.SearchInput
                type="text"
                placeholder="Buscar..."
                onChange={e => setSearch(e.target.value.toLowerCase())}
              />
            </S.SearchBox>
            <List
              style={{
                height: '300px',
                overflowY: 'scroll',
                overflowX: 'hidden',
              }}
            >
              {backupItems.length > 0 &&
                backupItems.map(item => (
                  <MenuItem
                    className="menu-item"
                    key={item.value}
                    disabled={ids.includes(item.value)}
                    onClick={() => handleSelect(item.value)}
                  >
                    {item.label}
                  </MenuItem>
                ))}
              {backupItems.length === 0 && (
                <MenuItem style={{ width: '300px' }} disabled>
                  Nenhuma opção disponível
                </MenuItem>
              )}
            </List>
          </Popover>
        </S.Container>
        {message && (
          <span
            style={{
              color: theme.palette.semantics.feedback.attention.dark,
              margin: '10px 0',
            }}
          >
            {message}
          </span>
        )}
      </FormControl>
    </div>
  );
};

export default SelectTags;
