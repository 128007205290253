import { Modal } from '@mui/material';
import styled from 'styled-components';
import colors from 'styles/colors';

export const ModalMU = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  width: 500px;
  min-height: 340px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid ${colors.greyTiny};
  padding: 20px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .titleArea {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .title {
      margin-left: 15px;
      font-weight: 900;
      font-size: 20px;
      color: ${colors.greyTitle};
    }
  }
`;

export const LikeBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

export const FeedBox = styled.div`
  flex: 1;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0px;
  border: 2px solid ${colors.greyTiny};
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: ${colors.greyBackgroud};
  }

  &.like {
    border: 2px solid ${colors.greenMain};
  }

  &.dislike {
    border: 2px solid ${colors.redDanger};
  }
`;

export const CommentBox = styled.div`
  margin-top: 10px;
  position: relative;

  .counter {
    position: absolute;
    color: ${colors.greySubtitle};
    font-size: 12px;
    padding: 2px 5px;
    bottom: 0;
    right: 0;
    z-index: 1;
  }
`;

export const Buttons = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
`;
