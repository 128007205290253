// Styles
import { useTheme } from 'styled-components';

// React
import { Fragment, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import EmptyCard from 'components/Cards/EmptyDataCard';
import DefaultButton from 'components/Buttons/Default';

// Material UI
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// Utils
import { formatNewDate } from 'utils/dates';
import { changeCurrentWeek } from 'store/modules/myTasks/actions';
import { useDispatch } from 'react-redux';
import { formatTaskColor } from './constants';
import * as S from './styled';

export const Line = ({
  semanas,
  semana,
  setSemana,
  items,
  setItems,
  setCurrentWeek,
  currentDay,
  tasksContent,
  resetForced,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const iconGenerator = icon => {
    if (!icon) icon = 'edicao.svg';
    return require(`../../../images/icons/logs/${icon}`);
  };

  // Open week in current day by default
  useEffect(() => {
    if (semana === null) {
      if (resetForced) {
        const today = new Date(currentDay);
        for (const i in semanas) {
          if (
            today > new Date(semanas[i].inicio) &&
            today < new Date(semanas[i].final)
          ) {
            setSemana(i);
            setCurrentWeek(i);
            dispatch(
              changeCurrentWeek({
                currentWeek: Number(i),
              }),
            );
          }
        }
      } else {
        setCurrentWeek(tasksContent.currentWeek);
        setSemana(tasksContent.savedWeek);
      }
    }
  }, [semanas]);

  // Form button with navigate hook
  const formatStatusArea = task => {
    const disabled = task.disabled;
    switch (task.status) {
      case 'PENDENTE': {
        if (disabled) return <S.FinishButton>Agendado</S.FinishButton>;
        return <DefaultButton children="Ver Tarefa" />;
      }
      case 'VENCIDO': {
        return (
          <DefaultButton
            children="Ver Tarefa"
            style={{
              backgroundColor:
                theme.palette.semantics.feedback.attention.natural,
            }}
          />
        );
      }
      case 'CONCLUIDO': {
        return <S.FinishButton>Concluído</S.FinishButton>;
      }
    }
  };

  return (
    <S.Container>
      <S.WeekBox total={semanas.length} semana={semana}>
        {semanas &&
          semanas.map((data, idx) => (
            <S.TasksBox
              key={idx}
              semana={semana}
              total={semanas.length}
              className={semana == idx ? 'visible' : 'invisible'}
            >
              {data.tarefas.length > 0 &&
                data.tarefas.map((task, k) => (
                  <Fragment key={k}>
                    {k < items && (
                      <S.Slide key={k} total={semanas.length}>
                        <S.Task
                          key={k}
                          colorBorder={formatTaskColor(task.status, theme)}
                          finished={task.status}
                          onClick={() => {
                            if (task.params) {
                              if (!task.disabled)
                                navigate(`${task.route}`, {
                                  state: task.params,
                                });
                            } else
                              navigate(`${task.route}${task.id_da_tarefa}`);
                          }}
                        >
                          <div className="leftArea">
                            <span className="iconArea">
                              <img src={iconGenerator(task.icon)} />
                            </span>

                            <span className="textArea">
                              <div className="title">{`[${task.evento}] ${task.title} ${task.id_da_tarefa}`}</div>

                              <div className="date">
                                {formatNewDate(task.data)}
                              </div>
                            </span>
                          </div>

                          <div className="rightArea">
                            {formatStatusArea(task)}
                          </div>
                        </S.Task>
                      </S.Slide>
                    )}
                  </Fragment>
                ))}

              {data.tarefas.length <= 0 && (
                <S.EmptyBox>
                  <EmptyCard
                    image="frota.png"
                    title="Ops! Voce não tem nenhuma atividade para essa semana"
                    subtitle="Verifique outras datas ou altere os filtros"
                  />
                </S.EmptyBox>
              )}

              {data.tarefas.length > items && (
                <S.SeeMore onClick={() => setItems(items + 5)}>
                  <span className="textSeeMore">Ver Mais</span>
                  <div className="divIcon">
                    <KeyboardArrowDownIcon className="icon" />
                  </div>
                </S.SeeMore>
              )}
            </S.TasksBox>
          ))}
      </S.WeekBox>
    </S.Container>
  );
};
