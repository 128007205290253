export const columnsSmall = [
  {
    Header: 'Nome',
    id: 'nome',
    identifier: 'nome',
    accessor: d => String(d.nome),
    sortType: 'basic',
    show: true,
    disableSortBy: false,
  },
  {
    Header: 'Conclusão',
    id: 'concluido',
    identifier: 'concluido',
    accessor: d => Number(d.percentual),
    sortType: 'basic',
    show: true,
    disableSortBy: false,
    centered: true,
  },
  {
    Header: 'Filial',
    id: 'filial',
    identifier: 'filial',
    accessor: d => String(d.filial),
    sortType: 'basic',
    show: true,
  },
  {
    Header: 'Tipo',
    id: 'tipo',
    identifier: 'tipo',
    accessor: d => String(d.tipo),
    sortType: 'basic',
    show: true,
  },
  {
    Header: 'Tentativas',
    id: 'tentativas',
    identifier: 'tentativas',
    accessor: d => String(d.tentativas),
    sortType: 'basic',
    show: true,
  },
  {
    Header: 'Status',
    id: 'status',
    identifier: 'status',
    accessor: d => String(d.status),
    sortType: 'basic',
    show: true,
    centered: true,
  },
];
