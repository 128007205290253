import { useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { setTitle } from 'store/modules/formularios/actions';

import { Tooltip } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import * as S from './styled';

const TitleEdit = ({ type = 'normal' }) => {
  // Redux
  const dispatch = useDispatch();
  const { titulo } = useSelector(state => state.formularios);

  // General States
  const [inEdit, setInEdit] = useState(null);
  const [value, setValue] = useState(titulo);

  const setAvalableEdition = () => {
    setInEdit(true);
  };

  const confirmChanges = () => {
    if (!value) toast.error('Insira um título para o formulário');
    setInEdit(false);
    dispatch(setTitle(value));
  };

  return (
    <S.Container type={type}>
      {inEdit && (
        <>
          <input
            value={value}
            onChange={e => setValue(e.target.value.slice(0, 50))}
            maxLength={50}
            placeholder="Preencha um título para o formulário"
            autoFocus
            onBlur={confirmChanges}
          />

          <div className="counter">{`${value.length}/50`}</div>
        </>
      )}

      {!inEdit && titulo && (
        <span className="titleForm" onClick={() => setInEdit(true)}>
          {titulo}
        </span>
      )}

      {!inEdit && !titulo && (
        <span className="titleForm out" onClick={() => setInEdit(true)}>
          Sem título
        </span>
      )}

      {inEdit && (
        <Tooltip title="Concluir">
          <CheckOutlinedIcon
            onClick={confirmChanges}
            style={{ cursor: 'pointer' }}
          />
        </Tooltip>
      )}

      {!inEdit && (
        <Tooltip title="Editar">
          <EditOutlinedIcon
            onClick={setAvalableEdition}
            style={{ cursor: 'pointer' }}
          />
        </Tooltip>
      )}
    </S.Container>
  );
};

export default TitleEdit;
