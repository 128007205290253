/* eslint-disable import/order */

// Styleds
import { useTheme } from 'styled-components';
import * as S from './styled';
import colors from 'styles/colors';

// React
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

// Components
import Loading from 'components/Loading';
import { ModalAlunos } from './ModalAlunos';
import { ExportCompaniesPdf } from './Export';
import { DefaultTable } from 'components/_Table/templates/default';

// Components MUI
import { Box } from '@mui/system';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import { CircularProgress, Grid, Menu, MenuItem } from '@mui/material';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';

// Services
import * as services from '../services';

// Utils
import { fequencyModule } from '../actions';
import { trackEvent } from 'utils/mixpanel';

// Columns
import { columnsSmall } from './columns';

let timerRoute = null;

export const Users = ({
  select,
  currentSelect,
  setCurrentSelect,
  printRefPdf,
  dataPrint,
}) => {
  const user = useSelector(state => state.auth?.user?.user);

  const theme = useTheme();
  // Redux
  const { id, geral } = useSelector(state => state.conteudoProvider);

  // Data States
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [query, setQuery] = useState({});
  const [cards, setCards] = useState([]);

  // Controllers States
  const [openModal, setOpenModal] = useState(false);
  const [firstFetch, setFirstFetch] = useState(true);
  const [currentData, setCurrentData] = useState(null);
  const [anchorDistributor, setAnchorDistributor] = useState(false);

  // ---------------------------------------REQUESTS---------------------------------------//

  const { refetch, isFetching: loading } = useQuery(
    [`/content-reports-${id}`, 1],
    () => id && services.getCompanyInfos(id, query),
    {
      refetchOnWindowFocus: false,
      onSuccess: data => {
        setCount(data?.data?.count);
        setData(data?.data?.transportadoras);
        setCards(data?.data?.cards);
      },
      onError: data => toast.error(data?.message),
    },
  );

  useEffect(() => {
    !firstFetch ? refetch() : setFirstFetch(false);
  }, [query]);

  const updateQuery = qs => {
    setQuery({ ...qs });
  };

  const renderActionMenu = () => {
    let currentOptions = [];

    let tabsFuncs = {
      options: ['visualizar'],
    };

    currentOptions = tabsFuncs.options;

    return (
      <Menu
        open={!!anchorDistributor}
        anchorEl={anchorDistributor}
        onClose={() => setAnchorDistributor(null)}
        onClick={() => setAnchorDistributor(null)}
      >
        <Grid container style={{ display: 'flex', flexDirection: 'column' }}>
          {currentOptions.includes('visualizar') && (
            <MenuItem
              key="visualizar"
              onClick={() => getUserByCompany(anchorDistributor.id)}
            >
              <Grid item md={9}>
                <S.PTable>Ver alunos</S.PTable>
              </Grid>
            </MenuItem>
          )}
        </Grid>
      </Menu>
    );
  };

  // ------------------------------------------------ MODAL VIEWR CONTROLL----------------------------------------//
  const getUserByCompany = id => {
    const currentCompany = data.find(item => item.id === +id);
    setCurrentData(currentCompany);
    setOpenModal(true);
  };

  const actions = [
    {
      function: getUserByCompany,
      title: 'Ver alunos',
    },
  ];

  // ---------------------------------------------RENDER-------------------------------------------//
  return (
    <>
      <S.InfoLine>
        <div className="period">{fequencyModule(geral)}</div>
      </S.InfoLine>
      <S.Container>
        <S.BoxInfos>
          {!loading && (
            <>
              <S.CircleBar>
                <Box
                  sx={{
                    position: 'relative',
                    display: 'inline-flex',
                    transform: 'scale(2)',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <CircularProgress
                    variant="determinate"
                    value={100}
                    sx={{
                      color: colors.greyTiny,
                    }}
                  />
                  <Box
                    sx={{
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      position: 'absolute',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <CircularProgress
                      variant="determinate"
                      value={
                        cards?.percentual_conclusao &&
                        +cards?.percentual_conclusao > 100
                          ? '100'
                          : cards?.percentual_conclusao
                      }
                    />
                  </Box>
                  <Box
                    sx={{
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      position: 'absolute',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <FactCheckOutlinedIcon
                      htmlColor={
                        !cards?.percentual_conclusao
                          ? colors.greyTiny
                          : colors.greenMain
                      }
                      style={{ transform: 'scale(0.7)' }}
                    />
                  </Box>
                </Box>
                <div className="textFields">
                  <div className="text">Concluidos(%)</div>
                  <div className="value">
                    {cards?.percentual_conclusao &&
                    +cards?.percentual_conclusao > 100
                      ? '100'
                      : cards?.percentual_conclusao}
                    %
                  </div>
                </div>
              </S.CircleBar>
              <div className="bar" />
              <S.Card>
                <div className="text">Total de Concluidos</div>
                <div className="value">{cards?.n_concluidos || 0}</div>
              </S.Card>
              <div className="bar" />
              <S.Card>
                <div className="text">Em Andamento</div>
                <div className="value">{cards?.n_em_andamento || 0}</div>
              </S.Card>
            </>
          )}
          {loading && (
            <S.BoxLoading>
              <Loading />
            </S.BoxLoading>
          )}
        </S.BoxInfos>
        <S.BoxInfos style={{ marginTop: '-6px' }}>
          {!loading && (
            <>
              <S.Card>
                <div className="text">Reprovados</div>
                <div className="value">{cards?.n_reprovados || 0}</div>
              </S.Card>
              <div className="bar" />
              <S.Card>
                <div className="text">Não Inciados</div>
                <div className="value">{cards?.n_nao_iniciados || 0}</div>
              </S.Card>
              <div className="bar" />
              <S.Card>
                <div className="text">Número de Alunos</div>
                <div className="value">{cards?.n_alunos || 0}</div>
              </S.Card>
            </>
          )}
          {loading && (
            <S.BoxLoading>
              <Loading />
            </S.BoxLoading>
          )}
        </S.BoxInfos>
        <S.TableArea>
          <DefaultTable
            pageCount={count || 0}
            data={data || []}
            columns={columnsSmall}
            onClickRow={id => getUserByCompany(id)}
            searchKeys={['nome']}
            loading={loading}
            placeholder="Buscar por Todos"
            sortBy={{ id: 'nome', order: 'DESC' }}
            actions={actions}
            local={false}
            setQuery={qs => updateQuery(qs)}
            searchEvent={search =>
              trackEvent(user, 'Busca cliente report', null, search)
            }
            empty={{
              title: 'Buscar reports',
              description: `Seus clientes aparecerão aqui".`,
            }}
          />
        </S.TableArea>
        {renderActionMenu()}
      </S.Container>
      <ModalAlunos
        open={openModal}
        onClose={() => setOpenModal(false)}
        data={currentData}
      />

      <ExportCompaniesPdf
        cont={geral}
        data={dataPrint}
        printRef={printRefPdf}
      />
    </>
  );
};
