import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// Componentes
import LineGraph from 'components/Graphs/LineGraph';
import BarGraph from 'components/Graphs/BarGraph';
import Loading from 'components/Loading';
import Select from 'components/Inputs/Select';
import Tab from 'components/Tabs';

// Service
import { toast } from 'react-toastify/dist';
import { Grid } from '@mui/material';
import { trackEvent } from 'utils/mixpanel';
import { requestGraphData } from '../services';
import * as S from './styled';

const valuesSelect = [
  { value: 'ptsH', name: 'PONTOS/HORA' },
  { value: 'ptsF', name: 'PONTOS/HORA FILIAL' },
  { value: 'ptsC', name: 'PONTOS/HORA CLIENTE' },
  { value: 'ptsO', name: 'PONTOS/HORA OPERAÇÃO' },
];

const valuesOptions = [
  { value: 'status', label: 'STATUS' },
  { value: 'desvios', label: 'ZERO DESVIOS' },
  { value: 'atrasadas', label: 'ATRASADAS' },
];

const valuesAvaliados = [
  { value: 'drives', label: 'MOTORISTAS AVALIADOS' },
  { value: 'hours', label: 'HORAS AVALIADAS' },
];

const valuesTop10 = [
  { value: 'justificativas', label: 'JUSTIFICATIVAS' },
  { value: 'responsaveis', label: 'RESPONSÁVEIS' },
  { value: 'indisponibilidade', label: 'INDISPONIBILIDADE' },
];

const GraficosAvalicoes = () => {
  const filter = useSelector(state => {
    return state.filter;
  });
  const user = useSelector(state => {
    return state.auth?.user?.user;
  });

  const [ptsloading, setPtsLoading] = useState(true);
  const [hisloading, setHisLoading] = useState(true);
  const [avaloading, setAvaLoading] = useState(true);
  const [temploading, setTempLoading] = useState(true);
  const [toploading, setTopLoading] = useState(true);

  const [ptsHour, setPtsHour] = useState(null);
  const [ptsHourAxis, setPtsHourAxis] = useState(['month']);
  const [values, setValuesSelect] = useState('ptsH');

  const [history, setHistory] = useState(null);
  const [historyAxis, setHistoryAxis] = useState(['month']);
  const [historyTab, setHistoryTab] = useState('status');

  const [avaliados, setAvaliados] = useState(null);
  const [avaliadosAxis, setAvaliadosAxis] = useState(['month']);
  const [avaliadosTab, setAvaliadosTab] = useState('drives');

  const [tempMedio, setTempMedio] = useState(null);
  const [tempMedioAxis, setTempMedioAxis] = useState(['month']);

  const [top10, setTop10] = useState(null);
  const [top10Tab, setTop10Tab] = useState('justificativas');

  const fetchPtsHour = async query => {
    setPtsLoading(true);
    const res = await requestGraphData('1', query);
    if (res.data?.data) setPtsHour(res.data.data.per_pth);
    else if (res.data?.message) toast.error(res.data.message);
    if (res.data?.data.series) setPtsHourAxis(res.data.data.series);
    else setPtsHourAxis(['total']);
    setPtsLoading(false);
  };

  const fetchHistory = async query => {
    setHisLoading(true);
    const res = await requestGraphData('2', query);
    if (res.data?.data) {
      setHistory(res.data.data.historico);
      setHistoryAxis(res.data.data.series);
    } else if (res.data?.message) toast.error(res.data.message);
    setHisLoading(false);
  };

  const fetchAvaliadas = async query => {
    setAvaLoading(true);
    const res = await requestGraphData('3', query);
    if (res.data?.data) {
      setAvaliados(res.data.data.historico);
      setAvaliadosAxis(res.data.data.series);
    } else if (res.data?.message) toast.error(res.data.message);
    setAvaLoading(false);
  };

  const fetchTempMedio = async query => {
    setTempLoading(true);
    const res = await requestGraphData('4', query);
    if (res.data?.data) {
      setTempMedio(res.data.data.temp);
      setTempMedioAxis(res.data.data.series);
    } else if (res.data?.message) toast.error(res.data.message);
    setTempLoading(false);
  };

  const fetchTop10 = async query => {
    setTopLoading(true);
    const res = await requestGraphData('5', query);
    if (res.data?.data) setTop10(res.data.data);
    else if (res.data?.message) toast.error(res.data.message);
    setTopLoading(false);
  };

  useEffect(() => {
    const query = { ...filter, tip_select: values };
    fetchPtsHour(query);
  }, [filter, values]);

  useEffect(() => {
    const query = { ...filter, tip_options: historyTab };
    fetchHistory(query);
  }, [filter, historyTab]);

  useEffect(() => {
    const query = { ...filter, tip_avaliadas: avaliadosTab };
    fetchAvaliadas(query);
  }, [filter, avaliadosTab]);

  useEffect(() => {
    fetchTempMedio(filter);
  }, [filter]);

  useEffect(() => {
    const query = { ...filter, tip_top10: top10Tab };
    fetchTop10(query);
  }, [filter, top10Tab]);

  const YAxisMax = datamax => {
    console.log(datamax);
    return datamax > 100
      ? Math.ceil(datamax / 100) * 100
      : datamax > 10
      ? Math.ceil(datamax / 10) * 10
      : datamax > 2
      ? Math.ceil(datamax / 2) * 2
      : 2;
  };

  const renderPhGraph = () => (
    <div style={{ marginTop: '20px', position: 'relative' }}>
      <LineGraph
        data={ptsHour}
        colors="critical"
        title="Pontos por hora"
        series={ptsHourAxis}
        legend={ptsHourAxis}
        loading={ptsloading}
        tooltip
        newYAxisMax={YAxisMax}
      />
      <div style={{ position: 'absolute', top: '15px', right: '25px' }}>
        <Select
          data={valuesSelect}
          value={values}
          handleChange={e => {
            trackEvent(
              user,
              `GRÁFICO AVALIAÇÕES PONTOS POR HORA: ${e.target.value}`,
            );
            setValuesSelect(e.target.value);
          }}
        />
      </div>
    </div>
  );

  const renderHistoryGraph = () => (
    <div style={{ marginTop: '20px', position: 'relative' }}>
      <LineGraph
        data={history}
        colors="critical"
        title="Histórico"
        series={historyAxis}
        legend={historyAxis}
        loading={hisloading}
        tooltip
        newYAxisMax={YAxisMax}
      />
      <div style={{ position: 'absolute', top: '15px', right: '25px' }}>
        <Tab
          width="350px"
          value={historyTab}
          items={valuesOptions}
          onChange={(e, value) => {
            trackEvent(user, `GRÁFICO AVALIAÇÕES HISTÓRICO: ${value}`);
            setHistoryTab(value);
          }}
        />
      </div>
    </div>
  );

  const renderAvaliacaoGraph = () => (
    <div style={{ marginTop: '20px', position: 'relative' }}>
      <LineGraph
        data={avaliados}
        colors="critical"
        title="Histórico"
        series={avaliadosAxis}
        loading={avaloading}
        tooltip
        newYAxisMax={YAxisMax}
      />
      <div style={{ position: 'absolute', top: '15px', right: '25px' }}>
        <Tab
          width="350px"
          value={avaliadosTab}
          items={valuesAvaliados}
          onChange={(e, value) => {
            trackEvent(user, `GRÁFICO AVALIAÇÕES HISTÓRICO: ${value}`);
            setAvaliadosTab(value);
          }}
        />
      </div>
    </div>
  );

  const renderTempMedioGraph = () => (
    <div style={{ marginTop: '20px', position: 'relative' }}>
      <LineGraph
        data={tempMedio}
        colors="critical"
        title="Tempo Médio (dias)"
        series={tempMedioAxis}
        legend={tempMedioAxis}
        loading={temploading}
        tooltip
        newYAxisMax={YAxisMax}
      />
    </div>
  );

  const renderTop10 = () => (
    <div style={{ marginTop: '20px', position: 'relative' }}>
      <BarGraph
        data={top10}
        colors="default"
        title="Top 10"
        loading={toploading}
        tooltip
      />
      <div style={{ position: 'absolute', top: '15px', right: '25px' }}>
        <Tab
          width="350px"
          value={top10Tab}
          items={valuesTop10}
          onChange={(e, value) => {
            trackEvent(user, `GRÁFICO AVALIAÇÕES TOP 10: ${value}`);
            setTop10Tab(value);
          }}
        />
      </div>
    </div>
  );

  const renderMain = () => (
    <>
      <S.Container>
        <S.Header>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '15px',
            }}
          >
            <S.Title>Dashboard Avaliações</S.Title>
          </div>
        </S.Header>
        <S.Main>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} xl={6}>
              {renderPhGraph()}
            </Grid>

            <Grid item xs={15} md={6} xl={6}>
              {renderHistoryGraph()}
            </Grid>

            <Grid item xs={12} md={6} xl={6}>
              {renderAvaliacaoGraph()}
            </Grid>

            <Grid item xs={12} md={6} xl={6}>
              {renderTempMedioGraph()}
            </Grid>

            <Grid item xs={12} md={6} xl={6}>
              {renderTop10()}
            </Grid>
          </Grid>
        </S.Main>
      </S.Container>
    </>
  );

  return renderMain();
};

export default GraficosAvalicoes;
