import api from 'services/api';

// ---------------------------INDEX REQUESTS-----------------------------//

export const requestAvaliacoesList = async query => {
  const res = await api.get('/evaluations-descarga', { params: query });
  return res.data;
};

export const requestCards = async query => {
  const res = await api.get('/evaluations-cards-descarga', { params: query });
  return res;
};

export const requestResponsibles = async id => {
  const res = await api.get(`/select-responsibles-descarga/${id}`);
  return res;
};

export const archiveEvaluation = async data => {
  try {
    const res = await api.put(`/evaluations-descarga/${data.id}`, data);
    return res.data;
  } catch (error) {
    return false;
  }
};

export const signatureEvaluation = async data => {
  try {
    const res = await api.put(`/evaluations-descarga/${data.id}`, data);
    return res.data;
  } catch (error) {
    return false;
  }
};

export const selectWitnessEvaluation = async data => {
  try {
    const res = await api.put(`/select-witness-evaluations-descarga`, data);
    return res;
  } catch (error) {
    return false;
  }
};

export const viewEvaluation = async id => {
  try {
    const res = await api.put(`/view-evaluation-descarga/${id}`);
    return res;
  } catch (error) {
    return false;
  }
};

export const requestExcel = async query => {
  const res = await api.get('/excel/evaluationsDescarga', { params: query });
  return res;
};
