import React from 'react';
import { useTheme } from 'styled-components';
import {
  FormControlLabel,
  Radio,
  RadioGroup as MuiRadioGroup,
} from '@mui/material';

const RadioGroup = React.forwardRef(
  ({ options = [], disabled, ...props }, ref) => {
    const theme = useTheme();
    return (
      <MuiRadioGroup {...props} ref={ref}>
        {options.map(option => (
          <FormControlLabel
            key={option.id ?? option.value}
            sx={{
              color: theme.palette.words.subtitle.natural,
            }}
            value={option.value}
            control={
              <Radio
                color="secondary"
                sx={{
                  color: theme.palette.semantics.feedback.unknown.natural,
                }}
                disabled={disabled}
              />
            }
            label={option.label}
          />
        ))}
      </MuiRadioGroup>
    );
  },
);

export default RadioGroup;
