// Styled
import { useTheme } from 'styled-components';

// React
import { useState } from 'react';
import { toast } from 'react-toastify';

// Components
import GhostButton from 'components/Buttons/Ghost';
import DefaultButton from 'components/Buttons/Default';
import DropZoneCustom from 'components/Inputs/DropZoneCustom';

// Components MUI
import { Divider } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import FilePresentOutlinedIcon from '@mui/icons-material/FilePresentOutlined';
import * as S from './styled';

// Utils
import * as services from '../../services';

export const ModalEvidencia = ({
  id,
  open,
  onClose,
  setRecharge,
  responseQuestions,
  obj,
}) => {
  const theme = useTheme();
  const [evidence, setEvidence] = useState(null);

  const addImage = item => {
    setEvidence(item[0]);
  };

  const deleteImage = item => {
    setEvidence(null);
  };

  const editImage = item => {
    setEvidence(item[0]);
  };

  const saveEvidence = async () => {
    if (!evidence?.imagem_url) {
      toast.error('Insira uma evidência para responder a pergunta!');
      return;
    }

    const res = await services.updateEvidences(id, {
      event: 'updateEvidence',
      data: { addImages: [evidence] },
    });

    if (res.data.success) {
      toast.success(res.data.message);
      Promise.all([
        responseQuestions(obj.id, obj.answer, obj.oldAnswer),
        setRecharge(true),
      ]).then(() => {
        onClose();
      });
    } else {
      toast.error(res.data.message);
    }
  };

  return (
    <S.ModalMUI open={open} onClose={onClose}>
      <S.Container>
        <div className="header">
          <div className="textArea">
            <FilePresentOutlinedIcon
              htmlColor={theme.palette.brand.secondary.natural}
            />
            <span className="title">Adicionar Evidência</span>
          </div>
          <CloseOutlinedIcon onClick={onClose} />
        </div>

        <Divider />

        <div className="body">
          <DropZoneCustom
            data={evidence}
            multiple={false}
            fileDir="desvios/imagens"
            addFunction={addImage}
            deleteFunction={deleteImage}
            editFunction={editImage}
            automaticSave
            haveLegend
            label="Adicionar Imagens"
            titleButton="Adicionar Imagens"
            required
            unicSize="200px"
          />
        </div>

        <div className="footer">
          <GhostButton
            children="Cancelar"
            style={{ marginRight: '10px' }}
            onClick={onClose}
          />
          <DefaultButton
            children="Adicionar Evidência"
            onClick={() => saveEvidence()}
          />
        </div>
      </S.Container>
    </S.ModalMUI>
  );
};
