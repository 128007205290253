import React from 'react';
import { useNavigate } from 'react-router-dom';

import * as S from './styled';
import { Divider } from '@mui/material';
import { Info } from '../Info';
import { Link } from 'pages/Estatisticas/Components/SingleTable/Link';
import { ReactComponent as FuncaoIco } from 'images/icons/usuarios/funcao.svg';
import { ReactComponent as PerfilAcessoIco } from 'images/icons/usuarios/perfil-acesso.svg';
import { ReactComponent as TempoEmpresaIco } from 'images/icons/usuarios/tempo-empresa.svg';
import { ReactComponent as TempoTratativaIco } from 'images/icons/usuarios/tempo-tratativas.svg';
import { ChipSelect } from '../ChipSelect';
import { CardCriticidade } from '../CardCriticidade';
import { formatRelativeDate } from 'utils/dates';
import { usePlans } from 'hooks/usePlans';

export const UserCard = React.memo(({ data }) => {
  const { isProvider, hasTorrePlus } = usePlans();
  const navigate = useNavigate();
  const text = data?.nome || '';
  return (
    <S.Container>
      <div>
        <S.Avatar>
          <S.Circle>
            {data?.foto ? (
              <img src={data.foto} alt="Avatar" />
            ) : (
              <span>{text ? text.charAt(0) : ''}</span>
            )}
          </S.Circle>

          <S.StatusCircle active={data?.id === 1} />
        </S.Avatar>
        <div className="text-center no-wrap">
          {isProvider && !hasTorrePlus ? 'Última revisão' : 'Última tratativa'}
        </div>
        <div className="text-center">
          {data?.ultima_tratativa
            ? formatRelativeDate(new Date(data?.ultima_tratativa))
            : '-'}
        </div>
      </div>

      <S.Content>
        <S.Flex className="full-width mb-05 gap-1">
          <div className="w-50">
            <S.Title>{String(text).toLowerCase()}</S.Title>
            <S.Flex className="gap-2">
              <Link
                style={{ fontSize: '18px' }}
                component="button"
                onClick={() => navigate(`/equipe/${data?.id}`)}
              >
                Ver estatisticas
              </Link>
              <Link
                style={{ fontSize: '18px' }}
                component="button"
                onClick={() => navigate(`/configuracoes/usuarios/${data?.id}`)}
              >
                Ver perfil
              </Link>
            </S.Flex>
          </div>
          {(!isProvider || hasTorrePlus) && (
            <div className="w-50">
              <ChipSelect
                label="Filial:"
                options={data?.filiais.map(f => ({
                  value: f.id,
                  label: f.nome,
                }))}
                disabled
                justify="start"
              />
            </div>
          )}
          {isProvider && !hasTorrePlus && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                width: '50%',
              }}
            >
              <S.Flex className="gap-1">
                <div className="rv">
                  <S.Quantidade>{data?.revisao_desvios || '0'}</S.Quantidade>
                  <div>
                    <S.Title2>Desvios revisados</S.Title2>
                    <S.Subtitle>(Últimos 7 dias)</S.Subtitle>
                  </div>
                </div>
              </S.Flex>
              <S.Flex className="gap-1" style={{ marginLeft: 40 }}>
                <div className="rv">
                  <S.Quantidade>{data?.ultimas_operacoes || '0'}</S.Quantidade>
                  <div>
                    <S.Title2>Revisões de operações</S.Title2>
                    <S.Subtitle>(Últimos 7 dias)</S.Subtitle>
                  </div>
                </div>
              </S.Flex>
            </div>
          )}
        </S.Flex>
        <Divider />
        <S.Flex
          className="full-width mt-05 gap-1"
          style={
            isProvider && !hasTorrePlus
              ? { display: 'flex', justifyContent: 'space-between' }
              : {}
          }
        >
          <Info
            title="Usuário há"
            text={data?.tempo_cadastro ?? '-'}
            icon={<TempoEmpresaIco />}
          />
          <Info
            title="Perfil de acesso"
            text={data?.nivel ?? '-'}
            icon={<PerfilAcessoIco />}
          />
          <Info
            title="Função"
            text={data?.funcao ?? '-'}
            icon={<FuncaoIco />}
          />
          <Info
            title="Tempo medio de tratativa"
            text={data?.tempo_medio ? `${data?.tempo_medio} dias` : '-'}
            icon={<TempoTratativaIco />}
          />
        </S.Flex>
      </S.Content>
      {(!isProvider || hasTorrePlus) && (
        <CardCriticidade data={data?.desvios} />
      )}
    </S.Container>
  );
});
