import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme, ...props }) => css`
    border: 2px solid ${theme.palette.semantics.feedback.unknown.natural};
    background-color: ${theme.palette.system.overlay};
    width: 272px;
    height: 392px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    pointer-events: ${props.charge ? 'none' : 'all'};

    &:hover {
      .content {
        margin-bottom: ${props.noTags ? '-170px' : '0px'};
      }
    }
  `}
`;

export const Image = styled.div`
  ${({ theme, src, ...props }) => css`
    background: ${theme.palette.brand.primary.background};
    background-size: cover;
    background-position: center;
    background-image: url(${src});
    width: 100%;
    height: 50%;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    position: absolute;
    top: 0;
    z-index: 1;
    display: flex;
    justify-content: end;
    align-items: top;
    filter: ${props.finished ? 'grayscale(1)' : 'grayscale(0)'};
  `}
`;

export const Ribbon = styled.div`
  ${({ theme, ...props }) => css`
    background-color: ${props.colorBg};
    height: 40px;
    width: 220px;
    transform: rotate(30deg);
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    margin-right: -48px;
    margin-top: 17px;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    color: ${props.colorText};
    display: ${props.hasRibbon ? 'flex' : 'none'};
    box-shadow: 0px 1px 2px ${theme.palette.brand.primary.light};
  `}
`;

export const Content = styled.div`
  ${({ theme, ...props }) => css`
    width: 200%;
    height: 100%;
    position: absolute;
    bottom: 0;
    margin-bottom: ${props.noTags ? '-170px' : '-196px'};
    z-index: 2;
    transition: all ease-in-out 0.4s;
    background-color: ${theme.palette.system.overlay};
    display: flex;
    margin-left: ${props.screenView};
    overflow: hidden;

    .screenPrimary {
      width: 100%;
      padding: 15px;
    }

    .screenSecondary {
      width: 100%;
      padding: 3px;
    }

    .title {
      font-size: 16px;
      color: ${theme.palette.system.black};
      font-weight: 600;
    }

    .description {
      margin-top: 10px;
      font-size: 13px;
      font-weight: 500;
      color: ${theme.palette.words.title.natural};
      height: 40px;
    }

    .statusBox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 25px;

      .finished {
        font-size: 13px;
        font-weight: 600;
        color: ${theme.palette.words.text.light};
        text-align: end;
        width: 100%;
      }
    }
  `}
`;

export const TagsArea = styled.div`
  display: flex;
  justify-content: start;
  margin-bottom: 10px;
  gap: 7%;
  height: 24px;
`;

export const Tag = styled.span`
  ${({ theme }) => css`
    font-size: 14px;
    font-weight: 600;
    background-color: ${theme.palette.brand.primary.background};
    color: ${theme.palette.words.title.natural};
    padding: 2px 8px;
    border-radius: 20px;
    white-space: nowrap;
  `}
`;

export const Buttons = styled.div`
  ${({ theme, ...props }) => css`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: ${props.noTags ? '-120px' : '-50px'};

    span {
      font-size: 14px;
      color: ${theme.palette.words.text.light};
      margin-top: ${props.forMe ? '8px' : '15px'};
    }

    .formStatus {
      transform: scale(0.9);
      margin-bottom: 10px;
      align-self: end;
      padding: 0px;

      div {
        margin-right: 0px;
      }

      span {
        margin-top: 0px;
      }
    }

    .warnToast {
      width: 100%;
      margin-top: -10px;
    }

    .finished {
      font-size: 14px;
      font-weight: 600;
      color: ${theme.palette.words.subtitle.natural};
      text-align: end;
      width: 100%;
      margin-bottom: 20px;
    }
  `}
`;

export const ProgressBar = styled.span`
  width: 100%;
`;

export const LoadingBox = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.4);
  z-index: 3;
`;

// Secondary Screen
export const HeaderStory = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;

    .left {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .right {
      color: ${theme.palette.words.text.light};
      font-size: 14px;
      font-weight: 600;

      &:hover {
        color: ${theme.palette.words.title.natural};
      }
    }
  `}
`;

export const TitleStory = styled.div`
  ${({ theme }) => css`
    font-weight: 600;
    font-size: 18px;
    color: ${theme.palette.words.title.natural};
  `}
`;

export const LineArea = styled.div`
  ${({ theme }) => css`
    height: 100%;
    overflow-y: auto;
    padding-bottom: 65px;

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-track {
      background: ${theme.palette.brand.primary.background};
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${theme.palette.semantics.feedback.unknown.natural};
      border-radius: 20px;
      border: 3px solid ${theme.palette.semantics.feedback.unknown.natural};
    }
  `}
`;

export const HistoryItem = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${theme.palette.brand.primary.background};
    padding: 15px;
    height: 35px;

    &:hover {
      background-color: ${theme.palette.brand.primary.background};
    }
  `}
`;

export const HistoryText = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 60%;

    .text {
      font-weight: 500;
      color: ${theme.palette.words.subtitle.natural};
      font-size: 14px;
    }

    .date {
      font-weight: 600;
      color: ${theme.palette.words.title.natural};
      font-size: 15px;
    }
  `}
`;

export const HistoryStatus = styled.div`
  ${({ theme, concluido }) => css`
    display: flex;
    height: 20px;
    width: 80px;
    color: ${concluido > 0
      ? theme.palette.semantics.feedback.information.natural
      : theme.palette.semantics.feedback.attention.natural};
    background-color: ${concluido
      ? theme.palette.semantics.feedback.information.light
      : theme.palette.semantics.feedback.attention.light};
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    font-weight: 600;
    font-size: 12px;
    margin-right: 5px;
  `}
`;

export const LoadingBoxLine = styled.div`
  position: relative;
  transform: scale(0.3);
  justify-content: center;
  width: 100%;
`;
