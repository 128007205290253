export const uuidV4 =
  /^[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i;

export const filters = [
  {
    name: 'empresas',
    placeholder: 'Filtrar por Empresas',
    section: undefined,
    mode: 'multiple',
    key: 'empresas',
    provider: true,
    user: false,
  },
  {
    name: 'filial',
    placeholder: 'Filtrar por Filial',
    section: undefined,
    mode: 'multiple',
    key: 'filials',
    provider: true,
    user: true,
  },
  {
    name: 'filial_veiculo',
    placeholder: 'Filtrar por Filial do Veículo',
    section: undefined,
    mode: 'multiple',
    key: 'filials_veiculo',
    datakey: 'filials',
    provider: true,
    user: true,
  },
  {
    name: 'motoristas',
    placeholder: 'Filtrar por Motoristas',
    section: undefined,
    mode: 'single',
    key: 'motoristas',
    provider: true,
    user: false,
  },

  {
    name: 'operation',
    placeholder: 'Filtrar por Operação',
    section: 'Operação e Cliente',
    mode: 'single',
    key: 'operations',
    provider: true,
    user: true,
  },
  {
    name: 'client',
    placeholder: 'Filtrar por Cliente',
    section: 'Operação e Cliente',
    mode: 'multiple',
    key: 'clients',
    provider: false,
    user: true,
  },
];

export const filtersProvider = [
  {
    name: 'empresas',
    placeholder: 'Filtrar por Empresas',
    section: undefined,
    mode: 'multiple',
    key: 'empresas',
    provider: true,
    user: false,
  },
  {
    name: 'filial',
    placeholder: 'Filtrar por Filial',
    section: undefined,
    mode: 'multiple',
    key: 'filials',
    provider: true,
    user: true,
    dependency: [
      {
        type: 'provider',
        name: 'empresas',
        clear: true,
        visible: true,
        maxSelect: 1,
        data: (val, keyData) => {
          let newFiliais = [];
          for (const i in keyData) {
            if (keyData[i]?.id === Number(val)) {
              const fil = keyData[i]?.filiais;
              newFiliais =
                fil && fil.map(i => ({ value: i?.id, label: i?.nome }));
            }
          }
          return newFiliais;
        },
      },
    ],
  },
  {
    name: 'filial_veiculo',
    placeholder: 'Filtrar por Filial do Veículo',
    section: undefined,
    mode: 'multiple',
    key: 'filials_veiculo',
    datakey: 'filials',
    provider: true,
    user: true,
    dependency: [
      {
        type: 'provider',
        name: 'empresas',
        clear: true,
        visible: true,
        maxSelect: 1,
        data: (val, keyData) => {
          let newFiliais = [];
          for (const i in keyData) {
            if (keyData[i]?.id === Number(val)) {
              const fil = keyData[i]?.filiais;
              newFiliais =
                fil && fil.map(i => ({ value: i?.id, label: i?.nome }));
            }
          }
          return newFiliais;
        },
      },
    ],
  },
  {
    name: 'motoristas',
    placeholder: 'Filtrar por Motoristas',
    section: undefined,
    mode: 'single',
    key: 'motoristas',
    provider: true,
    user: false,
  },
  {
    name: 'operation',
    placeholder: 'Filtrar por Operação',
    section: 'Operação e Cliente',
    mode: 'single',
    key: 'operations',
    provider: true,
    user: true,
  },
];

export const motoristas = [
  { nome: 'Agregados', id: 'AGREGADOS' },
  { nome: 'Próprios', id: 'PROPRIOS' },
  { nome: 'Não Informado', id: -1 },
];
