export const generate = (type, value) => {
  switch (type) {
    case 'string':
      return String(value).toLocaleLowerCase();
    case 'number':
      return Number(value);
    case 'boolean':
      return Boolean(value);
    case 'date':
      return new Date(value || null);
    case 'date-hour':
      return new Date(value || null);
    default:
      return value;
  }
};
