import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { IconButton } from '@mui/material';
import {
  NavigateBeforeRounded,
  NavigateNextRounded,
} from '@mui/icons-material';
import Loading from 'components/Loading';

import * as S from './styled';
import { fetchSequence } from '../../services';

const NextPrev = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const index = query.get('index');
  const params = useParams();
  const navigate = useNavigate();

  const { user } = useSelector(state => state.auth?.user);
  const isProvider = user?.provider;
  const [isLoading, setIsloading] = useState(false);

  const _filter = useSelector(state => {
    return state.filterDesvios;
  });
  const { sortBy } = _filter;

  return isLoading ? (
    <S.BoxLoading>
      <Loading />
    </S.BoxLoading>
  ) : (
    <>
      <IconButton onClick={() => {}}>
        <NavigateBeforeRounded />
      </IconButton>

      <IconButton onClick={() => {}}>
        <NavigateNextRounded />
      </IconButton>
    </>
  );
};

export default NextPrev;
