import React from 'react';
import Grid from '@mui/material/Grid';
import { formatNewDate } from 'utils/dates';
import * as S from './styled';

const Desvios = ({ data }) => {
  return (
    <S.Card>
      <S.CardHeader>
        <h1>{`Código do desvio #${data.id}`}</h1>
      </S.CardHeader>
      <S.CardContent>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            {data.desvio_info?.imagem !== null ? (
              <img src={data.desvio_info.imagem} />
            ) : (
              ''
            )}
          </Grid>
          <Grid item xs={4}>
            <S.CardCol>
              <S.CardContentCol style={{ flex: 1 }}>
                <h4>Status</h4>
              </S.CardContentCol>
              <S.CardContentCol style={{ flex: 3 }}>
                <h5>{data.status}</h5>
              </S.CardContentCol>
            </S.CardCol>
            <S.CardCol>
              <S.CardContentCol style={{ flex: 1 }}>
                <h4>Tipo</h4>
              </S.CardContentCol>
              <S.CardContentCol style={{ flex: 3 }}>
                <h5>{data.desvio_tipo?.titulo || ''}</h5>
              </S.CardContentCol>
            </S.CardCol>
            <S.CardCol>
              <S.CardContentCol style={{ flex: 1 }}>
                <h4>Pontuação</h4>
              </S.CardContentCol>
              <S.CardContentCol style={{ flex: 3 }}>
                <h5>{`${data.pontos || '0'} pontos`}</h5>
              </S.CardContentCol>
            </S.CardCol>
            <S.CardCol>
              <S.CardContentCol style={{ flex: 1 }}>
                <h4>Data de Desvio</h4>
              </S.CardContentCol>
              <S.CardContentCol style={{ flex: 3 }}>
                <h5> {data.data_desvio && formatNewDate(data.data_desvio)}</h5>
              </S.CardContentCol>
            </S.CardCol>
            <S.CardCol>
              <S.CardContentCol style={{ flex: 1 }}>
                <h4>Disponibilizado em </h4>
              </S.CardContentCol>
              <S.CardContentCol style={{ flex: 3 }}>
                <h5>{formatNewDate(data.createdAt)}</h5>
              </S.CardContentCol>
            </S.CardCol>
          </Grid>
          <Grid item xs={4}>
            <S.CardCol>
              <S.CardContentCol style={{ flex: 1 }}>
                <h4>Objeto</h4>
              </S.CardContentCol>
              <S.CardContentCol style={{ flex: 3 }}>
                <h5>{data.objeto || ''}</h5>
              </S.CardContentCol>
            </S.CardCol>
            <S.CardCol>
              <S.CardContentCol style={{ flex: 1 }}>
                <h4>Criticidade</h4>
              </S.CardContentCol>
              <S.CardContentCol style={{ flex: 3 }}>
                <h5>{data.criticidade || ''}</h5>
              </S.CardContentCol>
            </S.CardCol>
            <S.CardCol>
              <S.CardContentCol style={{ flex: 1 }}>
                <h4>Responsável</h4>
              </S.CardContentCol>
              <S.CardContentCol style={{ flex: 3 }}>
                <h5>{data.responsavel?.nome || ''}</h5>
              </S.CardContentCol>
            </S.CardCol>
            <S.CardCol>
              <S.CardContentCol style={{ flex: 1 }}>
                <h4>Previsão de Conclusão</h4>
              </S.CardContentCol>
              <S.CardContentCol style={{ flex: 3 }}>
                <h5>{formatNewDate(data.data_previsao)}</h5>
              </S.CardContentCol>
            </S.CardCol>
          </Grid>
        </Grid>
      </S.CardContent>
    </S.Card>
  );
};

export default Desvios;
