import styled, { css } from 'styled-components';

export const Card = styled.div`
  height: 100%;
  max-height: 540px;
  border: 1px solid #95aac94d;
  border-radius: 4px;
  background: #fff;
  padding: 1rem;
`;

export const Title = styled.div`
  width: 100%;
  font-weight: 900;
  font-size: 24px;
  line-height: 30px;
  color: #4b5166;
`;

export const ListContainer = styled.div`
  height: 95%;
  overflow-y: auto;
`;

export const ListItem = styled.li`
  ${({ theme }) => css`
    font-size: 16px;
    line-height: 20px;
    font-weight: medium;
    color: ${theme.palette.words.title.natural};
    border-bottom: 1px solid #95aac9;
    padding: 0.5rem;

    span {
      font-weight: 900;
    }
  `}
`;
