import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { Divider, Tooltip } from '@mui/material';
import Avatar from '@mui/material/Avatar';

import { Icon } from 'components/IconsOnisys';
import Loading from 'components/Loading';
import GhostButton from 'components/Buttons/Ghost';
import DefaultButton from 'components/Buttons/Default';
import { CustomizedSelect } from 'components/SelectCustom';
import { getDeslocamento, tratarDesvios } from '../../services';
import Bar from '../../components/Deslocamento/components/bar';
import { formatDate } from '../../components/Deslocamento';
import { listOptionsSelect } from '../../components/Deslocamento/constants';

import { Main } from './styled';
import { criticidade } from 'utils/helpers';
import { toast } from 'react-toastify';

export const DetalheDeslocamento = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [saving, setSaving] = useState(false);
  const [fields, setFields] = useState({
    planoAcao: '',
    selectedAction: null,
  });

  const { data, isFetching, refetch } = useQuery(
    ['deslocamento', params.id],
    () => getDeslocamento(params.id, {}),
    {
      onError: error => toast.error(error),
      refetchOnWindowFocus: false,
    },
  );

  const item = data?.data ?? null;

  const handleChange = (name, value) => {
    setFields(state => ({ ...state, [name]: value }));
  };

  const handleSave = async () => {
    const data = {
      ids: item?.desvios.map(item => item.id),
      plano_acao: fields?.planoAcao,
      trativa: fields?.selectedAction,
      id_viagem: Number(params.id),
      risco: item?.risco,
    };
    setSaving(true);
    const res = await tratarDesvios(data);
    setSaving(false);
    if (res.success) {
      toast.success(res.message);
    } else toast.error(res.message);
  };

  const getCriticidade = criticidade => {
    switch (criticidade) {
      case 'ALTISSIMO':
      case 'ALTÍSSIMO':
      case 'GRAVÍSSIMA':
        return `#F64E60`;
      case 'GRAVE':
      case 'ALTO':
        return `#FFA801`;
      case 'MODERADA':
      case 'PERIGO':
        return `#0C12F2`;
      case 'BAIXO':
        return `#1BC5BD`;
      default:
        return `#95AAC9`;
    }
  };

  return isFetching ? (
    <Loading />
  ) : (
    <div>
      <div style={{ padding: '20px' }}>
        <h1>Detalhes do deslocamento</h1>
        <Divider sx={{ marginTop: '8px' }} />
      </div>
      <Main color={getCriticidade(item?.risco)}>
        <div className="sub-container">
          <div className="space">
            <div className="risk">Risco da viagem</div>
            <div className="criticidade">{item?.risco}</div>
          </div>
          <div className="space">
            <div className="driver-name">{item?.['motorista.nome'] || ''}</div>
            <div className="container-user">
              <div style={{ marginRight: 10 }}>
                <Avatar
                  alt={item?.['motorista.nome']}
                  src={item?.['motorista.foto']}
                />
              </div>
              {false ? (
                <div>
                  <div>Esse deslocamento está sendo visto por</div>
                  <div> Ricardo Assis do Nascimento</div>
                </div>
              ) : (
                <div className="points">
                  <div className="driver-descrition">
                    Nesse deslocamento, o motorista já somou{' '}
                    <span>{item?.pontos || 0} pontos</span>
                  </div>
                  <div className="driver-footer">
                    {item?.endereco_fim || 'Endereço não informado '}(
                    {item?.latitude_fim}, {item?.longitude_fim})
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="space">
            <div className="last-location">Última localização</div>
            <div style={{ color: '#4B5166', fontSize: 15 }}>
              {formatDate(item?.data_fim) || '-'}
            </div>
          </div>
          <div className="space">
            <div className="last-location">Ptos/Km</div>
            <div style={{ color: '#4B5166', fontSize: 15 }}>
              {item?.pontos_km ? item.pontos_km.toFixed(2) : '-'}
            </div>
          </div>
          <div className="space">
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 10,
              }}
            >
              <div className="total-desvio">
                <span>{item?.desvios?.length || '-'}</span> Desvios cometidos
              </div>
              <div style={{ marginTop: 10, marginLeft: 10 }}>
                <Bar
                  quantities={{
                    qtde_GRAVE: item?.qtde_GRAVE || 0,
                    qtde_GRAVISSIMA: item?.qtde_GRAVISSIMA || 0,
                    qtde_MODERADA: item?.qtde_MODERADA || 0,
                    qtde_PRIMARIA: item?.qtde_PRIMARIA || 0,
                  }}
                />
              </div>
            </div>
            <div>
              {item?.desvios?.map(desvio => (
                <Tooltip title={desvio?.desvio_tipo?.titulo}>
                  <span>
                    <Icon
                      name={desvio?.desvio_tipo?.desvio_tipo_padrao?.icone}
                      style={{ marginRight: 5 }}
                    />
                  </span>
                </Tooltip>
              ))}
            </div>
          </div>
        </div>
        <div className="details">
          <div style={{ width: '100%' }}>
            <textarea
              value={fields.planoAcao}
              onChange={e => handleChange('planoAcao', e.target.value)}
              className="input-text"
              placeholder="Escreva um plano de ação..."
              rows="3"
            />
          </div>
          <div
            style={{
              marginBottom: 25,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <CustomizedSelect
              items={listOptionsSelect}
              selected={fields.selectedAction}
              handelSelect={value => handleChange('selectedAction', value)}
              styles={{ marginRight: 1 }}
            />
            <GhostButton children="CANCELAR" onClick={() => navigate(-1)} />
            <DefaultButton
              children="SALVAR"
              style={{ marginLeft: '10px' }}
              onClick={() => handleSave()}
              loading={saving}
            />
          </div>
          <table cellSpacing={0}>
            <tr className="table-header">
              <td>ID</td>
              <td>DESVIO</td>
              <td>DATA DESVIO</td>
              <td>VENCIMENTO</td>
              <td style={{ textAlign: 'center' }}>CRITICIDADE</td>
            </tr>
            {item?.desvios?.map(item => (
              <tr
                style={{
                  background: 'white',
                  color:
                    item?.status === 'PENDENTE' || item?.status === 'FINALIZADO'
                      ? '#95AAC9'
                      : '#4B5166',
                }}
              >
                <td>{item?.id?.slice(0, 8) || ''}</td>
                <td>
                  <div>{item?.desvio_tipo?.titulo || ''}</div>
                  <div className="status-pendente">{item?.status || ''}</div>
                </td>
                <td>
                  {item?.status === 'FINALIZADO'
                    ? `Conclusão: ${formatDate(item?.data_encerramento)}`
                    : formatDate(item?.data_desvio)}
                </td>
                <td>{formatDate(item?.data_encerramento)}</td>
                <td>{criticidade(item?.criticidade)}</td>
              </tr>
            ))}
          </table>
        </div>
      </Main>
    </div>
  );
};
