import React from 'react';

import { ReactComponent as Three_stars } from 'images/icons/motoristas/perfil/selo/3_stars.svg';
import { ReactComponent as Two_stars } from 'images/icons/motoristas/perfil/selo/2_stars.svg';
import { ReactComponent as One_star } from 'images/icons/motoristas/perfil/selo/1_star.svg';

import * as S from './styled';
import { Star } from '@mui/icons-material';

export const Selo = ({ data, entidade = 'Morista' }) => {
  return (
    <S.Container>
      {data?.seal ? (
        <>
          <S.Icon>
            {data?.stars === 1 && <One_star />}
            {data?.stars === 2 && <Two_stars />}
            {data?.stars === 3 && <Three_stars />}
          </S.Icon>

          <S.Text>
            <S.Title>
              {entidade} seguro <Star fontSize="inherit" />
              <Star
                fontSize="inherit"
                sx={{ opacity: data?.stars >= 2 ? 1 : 0.4 }}
              />
              <Star
                fontSize="inherit"
                sx={{ opacity: data?.stars >= 3 ? 1 : 0.4 }}
              />
            </S.Title>
            <S.Subtitle>
              <span>{data?.sealText}</span> com pontuação abaixo da meta.
            </S.Subtitle>
          </S.Text>
        </>
      ) : (
        ''
      )}
    </S.Container>
  );
};
