import styled, { css } from 'styled-components';

export const Main = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SpacedRow = styled.div`
  display: flex;
  justify-content: end;
`;
