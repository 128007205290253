import styled, { css } from 'styled-components';

import { Modal } from '@mui/material';

export const ModalArea = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 540px;
    height: 250px;
    background-color: ${theme.palette.system.overlay};
    padding: 10px 30px;
    transition: all ease 0.5s;
    border-radius: 5px;
    box-shadow: 0px 0px 15px ${theme.palette.system.black};
  `}
`;

export const Header = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 70px;
    border-bottom: 1px solid ${theme.palette.system.border};

    div {
      display: flex;
      flex-direction: row;
      h2 {
        margin-left: 10px;
      }

      .isTemplate {
        margin-left: 20px;
        font-weight: 600;
        color: ${theme.palette.brand.secondary.natural};
        background-color: ${theme.palette.brand.secondary.background};
        border-radius: 10px;
        padding: 0px 15px;
      }
    }
  `}
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Box = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 20px;
`;

export const Counter = styled.span`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    justify-content: end;
    font-size: 11px;
    font-weight: 600;
    padding-right: 3px;
    color: ${theme.palette.words.title.natural};
  `}
`;
