// Styles
import { useTheme } from 'styled-components';

// Images
import logo from 'images/logo.svg';
import bg from 'images/loginbg.png';
import benefit from 'images/loginBenefit.svg';
import success from 'images/icons/toast/success.svg';

// React
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  signInRequest,
  signInMicrosoft,
  loadingReset,
} from 'store/modules/auth/action';

// Components
import Button from 'components/Buttons/Default';
import TextInput from 'components/Inputs/TextField';

// Material MUI
import { Grid } from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

// Services
import qs from 'qs';
import { signInWithPopup, OAuthProvider } from 'firebase/auth';
import { auth as firebaseAuth } from 'utils/firebase';
import { sendRecoverEmail } from './services';
import * as S from './styled';
import colors from '../../styles/colors';

const logo_microsoft = require('images/microsoft.png');

const LoginPage = () => {
  const theme = useTheme();
  // Hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Form States
  const [inputs, setInputs] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [emailSend, setEmailSend] = useState(false);
  const [visibleN, setVisibleN] = useState(false);
  const [errors, setErrors] = useState({
    email: false,
    emailHelper: '',
    password: false,
    passwordHelper: '',
  });

  // Redux
  const auth = useSelector(state => state.auth);

  // Resect login process
  useEffect(() => {
    dispatch(loadingReset());
  }, []);

  const handleMicrosoftLogin = () => {
    const provider = new OAuthProvider('microsoft.com');
    provider.setCustomParameters({
      prompt: 'select_account',
    });
    signInWithPopup(firebaseAuth, provider)
      .then(result => {
        const userResponse = result._tokenResponse;
        const email = userResponse.email;
        const uid = userResponse.localId;
        const credential = OAuthProvider.credentialFromResult(result);
        dispatch(signInMicrosoft({ uid, email, credential }));
      })
      .catch(error => {
        const err_message = error.message;
        if (err_message.includes('account-exists-with-different-credential')) {
          const message =
            'Uma conta já existe com o email utilizado. Faça o login com senha e vincule sua conta à microsoft em configurações/segurança.';
          toast.error(message, { autoClose: 10000 });
        }
      });
  };

  // ----------------------------------INPUT CHANGER---------------------------------//
  const handleChange = e => {
    const { value } = e.target;
    setInputs(values => ({ ...values, [e.target.name]: value }));
    setErrors({
      ...errors,
      [`${e.target.name}Helper`]: '',
      [e.target.name]: false,
    });
    document.getElementById(e.target.name).classList.remove('error');
  };

  // ---------------------------------LOGIN ACTION-----------------------------//
  const handleSubmit = async e => {
    e.preventDefault();
    if (currentPage === 0) {
      const emailRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
      const isValidEmail = emailRegex.test(inputs?.email);
      const emailInput = document.getElementById('email');
      const passwordInput = document.getElementById('password');

      // Valditation field (not necessary try login to see error)
      if (!isValidEmail && !inputs.password) {
        toast.error('Insira um email e senha validos');
        emailInput.classList.add('error');
        passwordInput.classList.add('error');
        setErrors({
          email: true,
          emailHelper: 'Insira um email valido',
          password: true,
          passwordHelper: 'Insira uma senha',
        });
        return;
      }

      if (!isValidEmail) {
        toast.error('Insira um email valido');
        emailInput.classList.add('error');
        setErrors({
          ...errors,
          email: true,
          emailHelper: 'Insira um email valido',
        });
        return;
      }

      if (!inputs.password) {
        toast.error('Insira uma senha');
        passwordInput.classList.add('error');
        setErrors({
          ...errors,
          password: true,
          passwordHelper: 'Insira uma senha',
        });
        return;
      }

      // Login Proccess (with dispatch)
      dispatch(signInRequest(inputs));
    } else {
      const data = { email: inputs.email_recover };
      const res = await sendRecoverEmail(data);
      if (res.data.success) {
        toast.success('Email enviado com sucesso!');
        setEmailSend(true);
      } else toast.error(res.data.message);
    }
  };

  useEffect(() => {
    if (auth?.signed) {
      navigate(`/?${qs.stringify({ auth: true })}`, { replace: true });
    }
  }, [auth]);

  // -----------------------------------RENDER-----------------------------------//

  // Login
  const renderCompanyForm = () => {
    const texts = {
      title: 'Entrar',
      subTitle: 'Digite suas credencias abaixo:',
    };
    return (
      <Grid
        style={{
          overflowY: 'initial',
          height: '100%',
          padding: '100px',
          marginTop: '10%',
        }}
        item
        sm={10}
      >
        <S.PageContainer onSubmit={handleSubmit}>
          <div>
            <h1 style={{ fontSize: '38px', margin: '12px 0' }}>
              {texts.title}
            </h1>
            <p style={{ margin: '12px 0 24px' }}>{texts.subTitle}</p>
          </div>
          <S.FormWrapper>
            <TextInput
              label="Email"
              placeholder="Email do usuário"
              value={inputs.email || ''}
              onChange={handleChange}
              name="email"
              id="email"
              error={errors.email}
            />
            <div
              className={
                errors.emailHelper && errors.email
                  ? 'errorMessage visible'
                  : 'errorMessage'
              }
            >
              {errors.emailHelper}
            </div>
          </S.FormWrapper>
          <S.FormWrapper>
            <S.Label>
              <TextInput
                label="Senha"
                placeholder="Senha"
                value={inputs.password || ''}
                onChange={handleChange}
                name="password"
                id="password"
                type={visibleN ? 'text' : 'password'}
                onBlur={() => setVisibleN(false)}
                error={errors.password}
              />
              <S.VisibleController onClick={() => setVisibleN(!visibleN)}>
                {visibleN && (
                  <VisibilityOffOutlinedIcon
                    htmlColor={theme.palette.brand.primary.light}
                  />
                )}
                {!visibleN && (
                  <VisibilityOutlinedIcon
                    htmlColor={theme.palette.brand.primary.light}
                  />
                )}
              </S.VisibleController>
            </S.Label>
            <div
              className={
                errors.passwordHelper && errors.password
                  ? 'errorMessage visible'
                  : 'errorMessage'
              }
            >
              {errors.passwordHelper}
            </div>
          </S.FormWrapper>
          <a
            onClick={() => setCurrentPage(1)}
            style={{
              textDecoration: 'none',
              color: theme.palette.words.subtitle.natural,
              display: 'flex',
              justifyContent: 'flex-end',
              cursor: 'pointer',
            }}
          >
            Recuperar Senha
          </a>
          <S.FormWrapper>
            <Button
              type="submit"
              variant="contained"
              loading={auth?.loading}
              disabled={auth?.loading}
              style={{
                marginTop: '15px',
                background: theme.palette.brand.secondary.natural,
                color: theme.palette.words.button.contrast,
                height: '50px',
                width: '100%',
                fontWeight: 900,
              }}
              children="LOGIN"
            />
          </S.FormWrapper>

          <S.FormWrapper>
            <Button
              variant="contained"
              loading={auth?.loading}
              disabled={auth?.loading}
              onClick={() => handleMicrosoftLogin()}
              style={{
                marginTop: '15px',
                background: theme.palette.brand.primary.background,
                color: colors.blueTitle,
                border: `1px solid ${colors.blueTitle}`,
                height: '50px',
                width: '100%',
                fontWeight: 900,
              }}
            >
              <img
                src={logo_microsoft}
                width="24px"
                height="24px"
                style={{ marginRight: '10px' }}
              />
              Fazer Login com conta Microsoft
            </Button>
          </S.FormWrapper>
        </S.PageContainer>
      </Grid>
    );
  };

  // Recovery
  const renderRecoverPassword = () => {
    const texts = {
      title: 'Recuperar senha',
      subTitle: !emailSend
        ? 'Coloque seu email e enviaremos um link para alterá-la:'
        : 'Senha enviada para o email!',
    };
    return (
      <Grid
        style={{
          overflowY: 'initial',
          height: '100%',
          padding: '100px',
          marginTop: '10%',
        }}
        item
        sm={10}
      >
        <S.PageContainer>
          <div>
            <h1 style={{ margin: '12px 0' }}>{texts.title}</h1>
            <p style={{ margin: '12px 0 24px' }}>{texts.subTitle}</p>
          </div>
          {!emailSend ? (
            <>
              <S.FormWrapper>
                <TextInput
                  label="Email"
                  placeholder="Email do usuário"
                  value={inputs.email_recover || ''}
                  onChange={handleChange}
                  name="email_recover"
                  id="email_recover"
                />
              </S.FormWrapper>
              <S.FormWrapper>
                <Button
                  type="submit"
                  onClick={handleSubmit}
                  variant="contained"
                  style={{
                    marginTop: '35px',
                    background: theme.palette.brand.secondary.natural,
                    color: theme.palette.words.button.contrast,
                    height: '50px',
                    width: '100%',
                    fontWeight: 900,
                  }}
                  children="ENVIAR"
                />
              </S.FormWrapper>
            </>
          ) : (
            <div
              style={{
                maxWidth: '500px',
                height: '50px',
                padding: '13px',
                border: `1px solid ${theme.palette.semantics.feedback.success.natural}`,
                backgroundColor: theme.palette.semantics.feedback.success.light,
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                color: theme.palette.words.subtitle.natural,
              }}
            >
              <img style={{ margin: '10px' }} src={success} />
              Caso você uma conta com este email, enviamos um link.
            </div>
          )}
          <a
            onClick={() => setCurrentPage(0)}
            style={{
              textDecoration: 'none',
              color: theme.palette.words.subtitle.natural,
              display: 'flex',
              justifyContent: 'center',
              cursor: 'pointer',
              marginTop: '10px',
            }}
          >
            Voltar
          </a>
        </S.PageContainer>
      </Grid>
    );
  };

  // Toggle Render
  return (
    <S.PageWrapper container>
      <Grid
        item
        sm={5}
        style={{
          display: 'flex',
          flexDirection: 'column',
          backgroundImage: `url(${bg})`,
          height: '100%',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          alignItems: 'center',
          overflow: 'hidden',
        }}
      >
        <Grid
          container
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            flex: 1,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <img src={logo} />
            <p
              style={{
                font: 'normal normal 24px/31px Texta',
                color: theme.palette.words.text.contrast,
                textAlign: 'center',
                marginTop: '30px',
              }}
            >
              Tudo que você precisa para gerenciar riscos.
            </p>
            <div
              style={{
                display: 'flex',
                width: '80%',
                justifyContent: 'space-evenly',
                margin: '35px 0',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <img style={{ width: '48px', height: '48px' }} src={benefit} />
                <p
                  style={{
                    font: 'normal normal 18px/25px Texta',
                    color: theme.palette.words.text.contrast,
                  }}
                >
                  Segurança
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <img style={{ width: '48px', height: '48px' }} src={benefit} />
                <p
                  style={{
                    font: 'normal normal 18px/25px Texta',
                    color: theme.palette.words.text.contrast,
                  }}
                >
                  Monitoramento
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <img style={{ width: '48px', height: '48px' }} src={benefit} />
                <p
                  style={{
                    font: 'normal normal 18px/25px Texta',
                    color: theme.palette.words.text.contrast,
                  }}
                >
                  Prevenção
                </p>
              </div>
            </div>
            <p
              style={{
                font: 'normal normal 18px/25px Texta',
                color: theme.palette.words.text.contrast,
                textAlign: 'center',
                maxWidth: '350px',
              }}
            >
              Maior e mais completa plataforma de prevenção de acidentes
              rodoviários do Brasil. Com a Onisys as transportadoras reduzem em
              média 22% dos atos inseguros de seus motoristas após o primeiro
              ano de uso da plataforma.
            </p>
            <Button
              children="SOLICITAR DEMONSTRAÇÃO"
              style={{
                borderRadius: '4px',
                width: '250px',
                height: '50px',
                background: 'transparent',
                border: `1px solid ${theme.palette.brand.secondary.natural}`,
                color: theme.palette.words.button.contrast,
                marginTop: '40px',
              }}
              onClick={() => {
                window.open(
                  'https://share.hsforms.com/1rP0tN_kVTHS4Ga1KHdkOWgc5ktn',
                );
              }}
            />
          </div>
        </Grid>
      </Grid>

      <Grid
        item
        sm={7}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        {currentPage === 0 ? renderCompanyForm() : renderRecoverPassword()}
      </Grid>
    </S.PageWrapper>
  );
};

export default LoginPage;
