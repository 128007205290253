import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Card,
  Collapse,
  Divider,
  FormControl,
  Grid,
  Menu,
  MenuItem,
  SvgIcon,
  Tab,
  TextField,
  Avatar,
} from '@mui/material';
import { SaveAlt } from '@mui/icons-material';
import { useReactToPrint } from 'react-to-print';
import Table from 'components/TableLocal';
import { toast } from 'react-toastify';

// Icons
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import OilBarrelOutlinedIcon from '@mui/icons-material/OilBarrelOutlined';
import ContentPasteSearchOutlinedIcon from '@mui/icons-material/ContentPasteSearchOutlined';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import NewReleasesOutlinedIcon from '@mui/icons-material/NewReleasesOutlined';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { ReactComponent as file } from 'images/icons/sidebar/file.svg';
import ExportToExcel from 'utils/exportToCvs';

// Componentes
import GhostButton from 'components/Buttons/Ghost';
import DefaultButton from 'components/Buttons/Default';
import TextInput from 'components/Inputs/TextField';
import Loading from 'components/Loading';
import CardIndicadores from 'components/Cards/Indicador';
import ExcelModal from 'components/ExcelModal';
import { formatNewDate, formatNameDate } from 'utils/dates';
import { useTheme } from 'styled-components';
import LineGraph from 'components/Graphs/LineGraph';
import BarGraph from 'components/Graphs/BarGraph';
import PieGraph from 'components/Graphs/PieGraph';
import RadarGraph from 'components/Graphs/RadarGraph';
import Tabs from 'components/Tabs';
import { ExportProfilePdf } from './Export';

// utils
import {
  requestDriver,
  requestDriverForms,
  requestDriverGraphs,
  requestDriverRanking,
  requestExcelEvaluation,
  requestExcelInfractions,
  requestExcelUnloadingEvaluation,
  requestExcelUnloadingInfraction,
  requestExcelForms,
} from '../services';
import {
  columns,
  resetFields,
  avaliacoesFields,
  desviosFields,
  avaliacoesDescargaFields,
  desviosDescargaFields,
  padFields,
  formFields,
} from './constants';
import * as S from './styled';
import SidebarPerfilMotorista from './Sidebar';
import { resetExcelFields } from '../constants';

const PerfilMotorista = () => {
  const theme = useTheme();
  // Navigate
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [loadingPrint, setLoadingPrint] = useState(false);
  const [openExport, setOpenExport] = useState(false);
  const [driver, setDriver] = useState(null);
  const [driverRanking, setDriverRanking] = useState(null);
  const [driverInfractions, setDriverInfractions] = useState(null);
  const [formData, setFormData] = useState(null);

  const [anchorActions, setAnchorActions] = useState(false);

  const query = useSelector(state => state.filterProvider);
  const [loading, setLoading] = useState(true);
  const { pathname } = useLocation();
  const currentPage = pathname.split('/');
  const id = currentPage[2];

  // GRÁFICOS
  // --- Pontos por Hora (direção)---
  const [graphPtsHr, setGraphPtsHr] = useState(null);
  const [graphPtsHrAxis, setGraphPtsHrAxis] = useState(['motorista']);

  // --- Radar de Desvios (direção) ---
  const [allDesvios, setAllDesvios] = useState(null);
  const [perStatusDirecao, setPerStatusDirecao] = useState(null);
  const [perTypeDirecao, setPerTypeDirecao] = useState(null);
  const [perGravityDirecao, setPerGravityDirecao] = useState(null);
  const [infractionTab, setInfractionTab] = useState('tipo');

  // --- Pontos por Descarga (descarga) ---
  const [graphPtsDescarga, setGraphPtsDescarga] = useState(null);
  const [graphPtsDescargaAxis, setGraphPtsDescargaAxis] = useState([
    'motorista',
  ]);

  // --- Radar de Desvios (descarga) ---
  const [allDesviosDescarga, setAllDesviosDescarga] = useState(null);
  const [perStatusDescarga, setPerStatusDescarga] = useState(null);
  const [perTypeDescarga, setPerTypeDescarga] = useState(null);
  const [perGravityDescarga, setPerGravityDescarga] = useState(null);
  const [infractionTabDescarga, setInfractionTabDescarga] = useState('tipo');

  // --- PAD ---
  const [padGraphData, setPadGraphData] = useState(null);
  const [padGraphDataAxis, setPadGraphDataAxis] = useState(['motorista']);
  const [perTypePad, setPerTypePad] = useState(null);
  const [tabPad, setTabPad] = useState('violacao');

  // --- EXCEL ---
  const [openExcelModal, setOpenExcelModal] = useState(false);
  const [loadingExcel, setLoadingExcel] = useState(false);
  const [excelFields, setExcelFields] = useState(null);

  const sortBy = [
    {
      id: 'data',
      desc: true,
    },
  ];

  const fetch = async () => {
    const res = await requestDriver(id, query);
    setDriver(res.data);

    const res2 = await requestDriverRanking(id, query);
    setDriverRanking(res2.data.driver);
    setDriverInfractions(res2.data.desvios);
  };

  const fetchForms = async () => {
    setLoading(true);
    const dados = { ...query };

    const res = await requestDriverForms(id, dados);
    setFormData(res.data.data);
    setLoading(false);
  };

  const fetchGraphs = async () => {
    const dados = { ...query, id };
    const res = await requestDriverGraphs(dados);

    if (res.data?.success) {
      // Gráfico de pontos por hora
      const prsH = res.data.pts_hora.map(i => {
        return {
          ...i,
          empresa: Number(i.empresa),
          motorista: Number(i.motorista),
        };
      });
      setGraphPtsHr(prsH);
      setGraphPtsHrAxis(['motorista', 'empresa']);

      // Radar de Desvios Direção
      setAllDesvios(res.data.all_desvios_direcao);
      setPerStatusDirecao(res.data.per_status_direcao);
      setPerTypeDirecao(res.data.per_type_direcao);
      setPerGravityDirecao(res.data.per_gravity_direcao);

      // Gráfico de pontos por descarga
      setGraphPtsDescarga(res.data.pts_descarga);
      setGraphPtsDescargaAxis(['motorista', 'empresa']);

      // Radar de Desvios Descarga
      setAllDesviosDescarga(res.data.all_desvios_descarga);
      setPerStatusDescarga(res.data.per_status_descarga);
      setPerTypeDescarga(res.data.per_type_descarga);
      setPerGravityDescarga(res.data.per_gravity_descarga);

      // PAD
      setPadGraphData(res.data.dataPad);
      setPadGraphDataAxis(['motorista']);

      // Radar ocorrências pad
      setPerTypePad(res.data.per_type_pad);
    }
  };

  const handleNavigateAvaliacoes = ev => {
    if (typeof ev === `string`) navigate(`/avaliacoes/${ev}`);
    else navigate(`/avaliacoes/${ev.id}`);
  };

  const handlePdfAvaliacoes = id => {
    navigate(`/avaliacoes/pdf/${id}`);
  };

  const handlePdfAvaliacoesDescarga = id => {
    navigate(`/avaliacoes-descarga/pdf/${id}`);
  };

  const handleNavigateDesvios = de => {
    if (typeof de === `string`) navigate(`/desvios/${de}`);
    else navigate(`/desvios/${de.id}`);
  };

  const handleNavigateAvaliacoesDescarga = ev => {
    if (typeof ev === `string`) navigate(`/avaliacoes-descarga/${ev}`);
    else navigate(`/avaliacoes-descarga/${ev.id}`);
  };

  const handleNavigateDesviosDescarga = de => {
    if (typeof de === `string`) navigate(`/desvios/${de}`);
    else navigate(`/desvios/${de.id}`);
  };

  const handleNavigateOcorrencia = oc => {
    if (typeof oc === `string`) navigate(`/ocorrencia/${oc}`);
    else navigate(`/ocorrencia/${oc.id}`);
  };

  const handleNavigateFormulario = oc => {
    navigate(`/formularios/configuracao/editar/${oc}`);
  };

  const handleOpenDistributorMenu = e => {
    setAnchorActions(e.currentTarget);
  };

  const handleRequestExcel = async () => {
    const newFields = excelFields.filter(item => item.selected === true);
    const formatedDate = formatNameDate(new Date());

    let { initialDate } = query;
    let { finalDate } = query;
    initialDate = Date.parse(initialDate);
    finalDate = Date.parse(finalDate);

    const newQuery = {
      ...query,
      excelFields: newFields,
      driver: driver.driver.motorista.id,
    };

    setLoadingExcel(true);
    let res = { data: { success: false } };
    if (page === 1) res = await requestExcelEvaluation(newQuery);
    if (page === 2) res = await requestExcelInfractions(newQuery);
    if (page === 3) res = await requestExcelUnloadingEvaluation(newQuery);
    if (page === 4) res = await requestExcelUnloadingInfraction(newQuery);
    if (page === 5) res = await requestExcelForms(newQuery);

    if (!res?.data?.success) {
      toast.error(res.data.message);
      setLoadingExcel(false);
      return;
    }
    let nome = '';
    if (page === 1) nome = 'avaliacoes_';
    if (page === 2) nome = 'desvios_';
    if (page === 3) nome = 'avaliacoes_descarga_';
    if (page === 4) nome = 'desvios_descarga_';
    if (page === 5) nome = 'formularios_';

    if (res.data && res.data.data.excel) toast.success(res.data.message);
    ExportToExcel({
      excel: res.data.data.excel,
      name: `${nome}${formatedDate}`,
    });
    if (page === 1) setExcelFields(resetFields(avaliacoesFields));
    if (page === 2) setExcelFields(resetFields(desviosFields));
    if (page === 3) setExcelFields(resetFields(avaliacoesDescargaFields));
    if (page === 4) setExcelFields(resetFields(desviosDescargaFields));
    if (page === 5) setExcelFields(resetFields(formFields));
    setLoadingExcel(false);
    setOpenExcelModal(false);
  };

  const handleExportData = page => {
    switch (page) {
      case 1:
        setExcelFields(avaliacoesFields);
        setOpenExcelModal(true);
        break;
      case 2:
        setExcelFields(desviosFields);
        setOpenExcelModal(true);
        break;
      case 3:
        setExcelFields(avaliacoesDescargaFields);
        setOpenExcelModal(true);
        break;
      case 4:
        setExcelFields(desviosDescargaFields);
        setOpenExcelModal(true);
        break;
      case 5:
        setExcelFields(formFields);
        setOpenExcelModal(true);
        break;
    }
  };

  const formatColumns = arr => {
    arr?.forEach(page => {
      page?.forEach(i => {
        if (i.identifier === 'acoes') {
          i.Cell = function ({ row }) {
            return (
              <S.IconContainer
                id={row.original.id}
                key={row.original.id}
                onClick={handleOpenDistributorMenu}
              >
                <MoreHorizIcon cursor="pointer" fontSize="large" />
              </S.IconContainer>
            );
          };
        }
      });
    });
  };

  useEffect(() => {
    formatColumns(columns);
    fetch();
  }, [query]);

  useEffect(() => {
    fetchForms();
  }, [query.initialDate, query.finalDate]);

  useEffect(() => {
    fetchGraphs();
  }, [query]);

  useEffect(() => {
    if (openExport) {
      setTimeout(() => {
        handlePrint();
      }, 500);
    }
  }, [openExport]);

  const infractionsData =
    driver &&
    driver.driverInfractions?.map(infraction =>
      infraction.status !== 'DELETADO' ? infraction : [],
    );
  const evaluationsData =
    driver && driver.driverEvaluations?.map(evaluation => evaluation);

  const unloadingInfractionsData =
    driver &&
    driver.driverUnloadingfractions?.map(infraction =>
      infraction.status !== 'DELETADO' ? infraction : [],
    );

  const unloadingEvaluationsData =
    driver && driver.driverUnloadingEvaluations?.map(evaluation => evaluation);

  const padData =
    driver && driver.padInfractions?.map(ocorrencia => ocorrencia);

  const infractionTabs = [
    { value: 'tipo', label: 'POR TIPO' },
    { value: 'criticidade', label: 'POR CRITICIDATE' },
    { value: 'status', label: 'POR STATUS' },
  ];

  const padTabs = [{ value: 'violacao', label: 'POR TIPO' }];

  const handleExport = () => {
    setOpenExport(true);
    // handlePrint()
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: () => setLoadingPrint(true),
    onAfterPrint: () => {
      setOpenExport(false);
    },
  });

  const handleInfractionTab = (event, newValue) => {
    setInfractionTab(newValue);
  };

  const handleInfractionTabDescarga = (event, newValue) => {
    setInfractionTabDescarga(newValue);
  };

  const handlePadTab = (event, newValue) => {
    setTabPad(newValue);
  };

  const calculateAge = dob => {
    const diff_ms = Date.now() - dob.getTime();
    const age_dt = new Date(diff_ms);

    return Math.abs(age_dt.getUTCFullYear() - 1970);
  };

  const renderInfractionsRadarGraph = () => {
    if (perTypeDirecao || perStatusDirecao || perGravityDirecao) {
      const tabComponent = (
        <Tabs
          width="350px"
          value={infractionTab}
          items={infractionTabs}
          onChange={handleInfractionTab}
        />
      );

      switch (infractionTab) {
        case 'tipo':
          return (
            <RadarGraph
              data={perTypeDirecao}
              colors="critical"
              title={`Radar de desvios (${
                allDesvios ? allDesvios.length : '-'
              })`}
              tooltip
              tabComponent={tabComponent}
            />
          );

        case 'criticidade':
          return (
            <PieGraph
              data={perGravityDirecao}
              colors="critical"
              title={`Radar de desvios (${
                allDesvios ? allDesvios.length : '-'
              })`}
              tooltip
              legend
              tabComponent={tabComponent}
            />
          );

        case 'status':
          return (
            <PieGraph
              data={perStatusDirecao}
              colors="status"
              title={`Radar de desvios (${
                allDesvios ? allDesvios.length : '-'
              })`}
              tooltip
              legend
              tabComponent={tabComponent}
            />
          );
      }
    }
  };

  const renderInfractionsRadarGraphDescarga = () => {
    if (perTypeDescarga || perStatusDescarga || perGravityDescarga) {
      const tabComponent = (
        <Tabs
          width="350px"
          value={infractionTabDescarga}
          items={infractionTabs}
          onChange={handleInfractionTabDescarga}
        />
      );

      switch (infractionTabDescarga) {
        case 'tipo':
          return (
            <BarGraph
              data={perTypeDescarga}
              colors="default"
              title={`Radar de desvios (${
                allDesviosDescarga ? allDesviosDescarga.length : '-'
              })`}
              tooltip
              tabComponent={tabComponent}
            />
          );

        case 'criticidade':
          return (
            <PieGraph
              data={perGravityDescarga}
              colors="critical"
              title={`Radar de desvios (${
                allDesviosDescarga ? allDesviosDescarga.length : '-'
              })`}
              tooltip
              legend
              tabComponent={tabComponent}
            />
          );

        case 'status':
          return (
            <PieGraph
              data={perStatusDescarga}
              colors="status"
              title={`Radar de desvios (${
                allDesviosDescarga ? allDesviosDescarga.length : '-'
              })`}
              tooltip
              legend
              tabComponent={tabComponent}
            />
          );
      }
    }
  };

  const renderPadInfractionsRadarGraph = () => {
    if (perTypePad) {
      const tabComponent = (
        <Tabs
          width="350px"
          value={tabPad}
          items={padTabs}
          onChange={handlePadTab}
        />
      );

      switch (tabPad) {
        case 'violacao':
          return (
            <BarGraph
              data={perTypePad}
              colors="default"
              title="Radar de ocorrências"
              tooltip
              tabComponent={tabComponent}
            />
          );
      }
    }
  };

  const renderHeader = () => {
    const titles = [
      'Informações Gerais',
      'Avaliações direção',
      'Desvios direção',
      'Avaliações descarga',
      'Desvios descarga',
      'Formulários',
      'Estatísticas',
    ];

    return (
      <>
        <S.TitleWrapper>
          <h1>{titles[page]}</h1>
          <div>
            {page === 0 && (
              <DefaultButton
                display-content="flex-end"
                size="medium"
                onClick={() => handleExport()}
                disabled={!(driver && driverRanking)}
              >
                IMPRMIR
              </DefaultButton>
            )}
            {page !== 6 && page !== 0 && (
              <GhostButton
                display-content="flex-end"
                startIcon={<SaveAlt />}
                size="medium"
                onClick={() => handleExportData(page)}
              >
                EXPORTAR
              </GhostButton>
            )}
          </div>
        </S.TitleWrapper>
      </>
    );
  };

  const renderBody = () => {
    const actionsEvaluations = [
      { name: 'ver', text: 'Ver avaliação', action: handleNavigateAvaliacoes },
      { name: 'baixar_pdf', text: 'Baixar PDF', action: handlePdfAvaliacoes },
    ];

    const actionsInfractions = [
      { name: 'ver', text: 'Ver desvio', action: handleNavigateDesvios },
    ];

    const actionsUnloadingEvaluations = [
      {
        name: 'ver',
        text: 'Ver avaliação',
        action: handleNavigateAvaliacoesDescarga,
      },
      {
        name: 'baixar_pdf',
        text: 'Baixar PDF',
        action: handlePdfAvaliacoesDescarga,
      },
    ];

    const actionsUnloadingInfractions = [
      {
        name: 'ver',
        text: 'Ver desvio',
        action: handleNavigateDesviosDescarga,
      },
    ];

    const actionsPad = [
      { name: 'ver', text: 'Ver ocorrência', action: handleNavigateOcorrencia },
    ];

    const actionsForms = [
      { name: 'ver', text: 'Ver formulário', action: handleNavigateFormulario },
    ];

    return (
      <>
        {!loading && (
          <div>
            {page === 1 && (
              <>
                <S.containerCard>
                  <div className="container-card">
                    <div className="icon-container">
                      <WorkspacePremiumIcon />
                    </div>
                    <div>
                      <div>
                        <h1>{driverRanking?.position}º colocado</h1>
                        <h3>Posição no ranking geral</h3>
                      </div>
                    </div>
                  </div>

                  <div className="container-card-divider" />

                  <div className="container-card">
                    <div className="icon-container">
                      <AccessTimeIcon />
                    </div>
                    <div>
                      <div>
                        <h1>
                          {driverRanking?.horas ? driverRanking?.horas : '0'}{' '}
                          Horas
                        </h1>
                        <h3>Horas avaliadas</h3>
                      </div>
                    </div>
                  </div>

                  <div className="container-card-divider" />

                  <div className="container-card">
                    <div className="icon-container">
                      <AlarmOnIcon />
                    </div>
                    <div>
                      <div>
                        <h1>
                          {driverRanking?.pontos_por_hora
                            ? driverRanking?.pontos_por_hora
                            : '0'}
                        </h1>
                        <h3>Pontos por hora</h3>
                      </div>
                    </div>
                  </div>

                  <div className="container-card-divider" />

                  <div className="container-card">
                    <div className="icon-container">
                      <WarningAmberIcon />
                    </div>
                    <div>
                      <div>
                        <h1>
                          {driverRanking?.pontos_totais
                            ? driverRanking?.pontos_totais
                            : '0'}
                        </h1>
                        <h3>Pontos totais</h3>
                      </div>
                    </div>
                  </div>
                </S.containerCard>

                <Table
                  columns={columns[page - 1]}
                  data={evaluationsData || []}
                  sortBy={sortBy}
                  onClickRow={handleNavigateAvaliacoes}
                  actions={actionsEvaluations}
                  permitIsSortedOccur
                />
              </>
            )}
            {page === 2 && (
              <>
                <S.containerCard>
                  <div className="container-card">
                    <div className="icon-container">
                      <ReportProblemOutlinedIcon />
                    </div>
                    <div>
                      <div>
                        <h1>
                          {driverInfractions?.total_desvios
                            ? driverInfractions?.total_desvios
                            : '0'}
                        </h1>
                        <h3>Total de desvios</h3>
                      </div>
                    </div>
                  </div>

                  <div className="container-card-divider" />

                  <div className="container-card">
                    <div className="icon-container">
                      <ErrorOutlineOutlinedIcon />
                    </div>
                    <div>
                      <div>
                        <h1>
                          {driverInfractions?.desvios_graves
                            ? driverInfractions?.desvios_graves
                            : '0'}
                        </h1>
                        <h3>Desvios graves</h3>
                      </div>
                    </div>
                  </div>

                  <div className="container-card-divider" />

                  <div className="container-card">
                    <div className="icon-container">
                      <NewReleasesOutlinedIcon />
                    </div>
                    <div>
                      <div>
                        <h1>
                          {driverInfractions?.desvios_gravissimos
                            ? driverInfractions?.desvios_gravissimos
                            : '0'}
                        </h1>
                        <h3>Desvios gravíssimos</h3>
                      </div>
                    </div>
                  </div>
                </S.containerCard>

                <Table
                  columns={columns[page - 1]}
                  data={infractionsData || []}
                  sortBy={sortBy}
                  onClickRow={handleNavigateDesvios}
                  actions={actionsInfractions}
                  permitIsSortedOccur
                />
              </>
            )}
            {page === 3 && (
              <>
                <S.containerCard>
                  <div className="container-card">
                    <div className="icon-container">
                      <OilBarrelOutlinedIcon />
                    </div>
                    <div>
                      <div>
                        <h1>
                          {driverRanking?.quantidade_descargas
                            ? driverRanking?.quantidade_descargas
                            : '0'}
                        </h1>
                        <h3>Descargas realizadas</h3>
                      </div>
                    </div>
                  </div>

                  <div className="container-card-divider" />

                  <div className="container-card">
                    <div className="icon-container">
                      <AlarmOnIcon />
                    </div>
                    <div>
                      <div>
                        <h1>
                          {driverRanking?.pontos_por_descarga
                            ? driverRanking?.pontos_por_descarga
                            : '0'}
                        </h1>
                        <h3>Pontos por descarga</h3>
                      </div>
                    </div>
                  </div>

                  <div className="container-card-divider" />

                  <div className="container-card">
                    <div className="icon-container">
                      <WarningAmberIcon />
                    </div>
                    <div>
                      <div>
                        <h1>
                          {driverRanking?.pontos_totais_descarga
                            ? driverRanking?.pontos_totais_descarga
                            : '0'}
                        </h1>
                        <h3>Total de pontos</h3>
                      </div>
                    </div>
                  </div>
                </S.containerCard>

                <Table
                  columns={columns[page - 1]}
                  data={unloadingEvaluationsData || []}
                  sortBy={sortBy}
                  onClickRow={handleNavigateAvaliacoesDescarga}
                  actions={actionsUnloadingEvaluations}
                  permitIsSortedOccur
                />
              </>
            )}
            {page === 4 && (
              <>
                <S.containerCard>
                  <div className="container-card">
                    <div className="icon-container">
                      <ReportProblemOutlinedIcon />
                    </div>
                    <div>
                      <div>
                        <h1>
                          {driverInfractions?.total_desvios_descarga
                            ? driverInfractions?.total_desvios_descarga
                            : '0'}
                        </h1>
                        <h3>Total de desvios</h3>
                      </div>
                    </div>
                  </div>

                  <div className="container-card-divider" />

                  <div className="container-card">
                    <div className="icon-container">
                      <ErrorOutlineOutlinedIcon />
                    </div>
                    <div>
                      <div>
                        <h1>
                          {driverInfractions?.desvios_descarga_graves
                            ? driverInfractions?.desvios_descarga_graves
                            : '0'}
                        </h1>
                        <h3>Desvios graves</h3>
                      </div>
                    </div>
                  </div>

                  <div className="container-card-divider" />

                  <div className="container-card">
                    <div className="icon-container">
                      <NewReleasesOutlinedIcon />
                    </div>
                    <div>
                      <div>
                        <h1>
                          {driverInfractions?.desvios_descarga_gravissimos
                            ? driverInfractions?.desvios_descarga_gravissimos
                            : '0'}
                        </h1>
                        <h3>Desvios gravíssimos</h3>
                      </div>
                    </div>
                  </div>
                </S.containerCard>

                <Table
                  columns={columns[page - 1]}
                  data={unloadingInfractionsData || []}
                  sortBy={sortBy}
                  onClickRow={handleNavigateDesviosDescarga}
                  actions={actionsUnloadingInfractions}
                  permitIsSortedOccur
                />
              </>
            )}

            {page === 5 && (
              <>
                <S.containerCard>
                  <div className="container-card">
                    <div className="icon-container">
                      <AlarmOnIcon />
                    </div>
                    <div>
                      <div>
                        <h1>
                          {formData?.cards[0]?.value
                            ? formData?.cards[0]?.value
                            : '0'}
                        </h1>
                        <h3>Aprovadas</h3>
                      </div>
                    </div>
                  </div>

                  <div className="container-card-divider" />

                  <div className="container-card">
                    <div className="icon-container">
                      <WarningAmberIcon />
                    </div>
                    <div>
                      <div>
                        <h1>
                          {formData?.cards[1].value
                            ? formData?.cards[1].value
                            : '0'}
                        </h1>
                        <h3>Aprovações pendentes</h3>
                      </div>
                    </div>
                  </div>

                  <div className="container-card-divider" />

                  <div className="container-card">
                    <div className="icon-container">
                      <ErrorOutlineOutlinedIcon />
                    </div>
                    <div>
                      <div>
                        <h1>
                          {formData?.cards[2].value
                            ? formData?.cards[2].value
                            : '0'}
                        </h1>
                        <h3>Iniciados</h3>
                      </div>
                    </div>
                  </div>
                </S.containerCard>

                <Table
                  columns={columns[page - 1]}
                  data={formData?.data || []}
                  sortBy={sortBy}
                  permitIsSortedOccur
                  actions={actionsForms}
                />
              </>
            )}
            {page === 6 && (
              <>
                {graphPtsHr && (
                  <>
                    <S.StyledTitle>Direção</S.StyledTitle>
                    <Divider />
                    <S.MainGraphs>
                      <S.FirstGraph>
                        <LineGraph
                          data={graphPtsHr}
                          colors="critical"
                          title="Histórico Pontos/h"
                          series={graphPtsHrAxis}
                          tooltip
                        />
                      </S.FirstGraph>
                      <S.SecondGraph>
                        {renderInfractionsRadarGraph()}
                      </S.SecondGraph>
                    </S.MainGraphs>
                  </>
                )}

                {graphPtsDescarga && (
                  <>
                    <S.StyledTitle>Descarga</S.StyledTitle>
                    <Divider />

                    <S.MainGraphs>
                      <S.FirstGraph>
                        <LineGraph
                          data={graphPtsDescarga}
                          colors="critical"
                          title="Histórico pontos por descarga"
                          series={graphPtsDescargaAxis}
                          tooltip
                        />
                      </S.FirstGraph>
                      <S.SecondGraph>
                        {renderInfractionsRadarGraphDescarga()}
                      </S.SecondGraph>
                    </S.MainGraphs>
                  </>
                )}
                {padGraphData && (
                  <>
                    <S.StyledTitle>PAD</S.StyledTitle>
                    <Divider />

                    <S.MainGraphs>
                      <S.FirstGraph>
                        <LineGraph
                          data={padGraphData}
                          colors="critical"
                          title="Histórico número de ocorrências"
                          series={padGraphDataAxis}
                          tooltip
                        />
                      </S.FirstGraph>
                      <S.SecondGraph>
                        {renderPadInfractionsRadarGraph()}
                      </S.SecondGraph>
                    </S.MainGraphs>
                  </>
                )}
              </>
            )}
            {[0].includes(page) && driver && driverRanking && (
              <>
                <S.BoxInformation>
                  {/* Cabeçalho */}
                  <Grid container display="flex" justifyContent="center">
                    <Grid
                      item
                      xs={12}
                      md={12}
                      display="flex"
                      justifyContent="center"
                    >
                      <div className="imgArea">
                        {/* //TODO Componente de foto para o motorista */}
                        {driver.driver.motorista.foto && (
                          <div className="avatar">
                            <Avatar
                              sx={{
                                width: 100,
                                height: 100,
                                fontSize: 36,
                                border: `2px solid ${theme.palette.system.highlight}`,
                              }}
                              alt={driver.driver.motorista.nome}
                              src="/static/images/avatar/1.jpg"
                            />
                          </div>
                        )}

                        {!driver.driver.motorista.foto && (
                          <div className="avatar">
                            <Avatar
                              sx={{
                                width: 100,
                                height: 100,
                                fontSize: 36,
                                border: `2px solid ${theme.palette.system.highlight}`,
                              }}
                              alt={driver.driver.motorista.nome}
                              src="/static/images/avatar/1.jpg"
                            />
                          </div>
                        )}
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      display="flex"
                      justifyContent="center"
                    >
                      <h1>{driver.driver.motorista.nome}</h1>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      display="flex"
                      justifyContent="center"
                      marginBottom="20px"
                    >
                      {driver.driver.motorista.filial && (
                        <h3>Filial: {driver.driver.motorista.filial.nome}</h3>
                      )}
                    </Grid>
                  </Grid>

                  {/* Cards */}
                  <S.containerCard>
                    <div className="container-card">
                      <div className="icon-container">
                        <WorkspacePremiumIcon />
                      </div>
                      <div>
                        <div>
                          <h1>{driverRanking?.position}º colocado</h1>
                          <h3>Posição no ranking geral</h3>
                        </div>
                      </div>
                    </div>

                    <div className="container-card-divider" />

                    <div className="container-card">
                      <div className="icon-container">
                        <AccessTimeIcon />
                      </div>
                      <div>
                        <div>
                          <h1>
                            {driverRanking?.horas ? driverRanking?.horas : '0'}{' '}
                            Horas
                          </h1>
                          <h3>Horas avaliadas</h3>
                        </div>
                      </div>
                    </div>

                    <div className="container-card-divider" />

                    <div className="container-card">
                      <div className="icon-container">
                        <AlarmOnIcon />
                      </div>
                      <div>
                        <div>
                          <h1>
                            {driverRanking?.pontos_por_hora
                              ? driverRanking?.pontos_por_hora
                              : '0'}
                          </h1>
                          <h3>Pontos por hora</h3>
                        </div>
                      </div>
                    </div>

                    <div className="container-card-divider" />

                    <div className="container-card">
                      <div className="icon-container">
                        <WarningAmberIcon />
                      </div>
                      <div>
                        <div>
                          <h1>
                            {driverRanking?.pontos_totais
                              ? driverRanking?.pontos_totais
                              : '0'}
                          </h1>
                          <h3>Pontos totais</h3>
                        </div>
                      </div>
                    </div>
                  </S.containerCard>

                  {/* Infos Gerais */}
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                    marginTop={1}
                  >
                    <Grid item xs={6} md={6}>
                      <label>
                        <TextInput
                          disabled
                          label="Função"
                          value={
                            driver.driver.motorista.funcao
                              ? driver.driver.motorista.funcao
                              : 'Não informada'
                          }
                        />
                      </label>
                    </Grid>

                    <Grid item xs={6} md={6}>
                      <label>
                        <TextInput
                          disabled
                          label="Gênero"
                          value={driver.driver.motorista.genero}
                        />
                      </label>
                    </Grid>

                    <Grid item xs={6} md={6}>
                      <label>
                        <TextInput
                          disabled
                          label="Idade"
                          value={
                            driver.driver.motorista.data_de_nascimento
                              ? calculateAge(
                                  new Date(
                                    driver.driver.motorista.data_de_nascimento,
                                  ),
                                )
                              : 'Não informada'
                          }
                        />
                      </label>
                    </Grid>

                    <Grid item xs={6} md={6}>
                      <label>
                        <TextInput
                          disabled
                          label="Data de admissão"
                          value={
                            driver.driver.motorista.data_de_admissao
                              ? formatNewDate(
                                  driver.driver.motorista.data_de_admissao,
                                )
                              : 'Não informada'
                          }
                        />
                      </label>
                    </Grid>
                  </Grid>
                </S.BoxInformation>
              </>
            )}
          </div>
        )}

        {loading && (
          <div>
            <Loading />
          </div>
        )}
      </>
    );
  };

  const renderTemplatePage = () => {
    return (
      <div>
        <div style={{ marginTop: '-27px', marginLeft: '-25px' }}>
          <SidebarPerfilMotorista page={page} setPage={setPage} />
        </div>

        <>
          <S.StyledMain
            item
            md={3}
            style={{ display: 'flex', justifyContent: 'flex-start' }}
          >
            {/* <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                          {renderHeader()}
                      </div> */}
            {renderHeader()}
          </S.StyledMain>
          <S.StyledMain>
            <div style={{ marginTop: '-20px', width: '100%' }}>
              {renderBody()}
            </div>
          </S.StyledMain>
        </>

        {openExcelModal && (
          <ExcelModal
            onClick={handleRequestExcel}
            open={openExcelModal}
            handleClose={() => setOpenExcelModal(false)}
            title="Selecionar os campos de Excel"
            titleIcon={file}
            subtitle="Selecionar abaixos:"
            setData={setExcelFields}
            data={excelFields}
            loading={loadingExcel}
          />
        )}

        {driver &&
          driverRanking &&
          graphPtsHr &&
          perTypeDirecao &&
          openExport && (
            <ExportProfilePdf
              driverRanking={driverRanking}
              driver={driver}
              graphsDirecao={{ ptsHr: graphPtsHr, radarTipos: perTypeDirecao }}
              graphsDescarga={{
                ptsDescarga: graphPtsDescarga,
                radarTipos: perTypeDescarga,
              }}
              graphsPad={{ dataPad: padGraphData, radarTipos: perTypePad }}
              printRef={componentRef}
              period={[query.initialDate, query.finalDate]}
            />
          )}
      </div>
    );
  };

  return <>{renderTemplatePage()}</>;
};

export default PerfilMotorista;
