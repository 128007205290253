import api from '../../services/api';

export const getContent = async data => {
  const res = await api.get(`/capacitacao/usuario-conteudo`, { params: data });
  return res?.data?.data || [];
};

export const getCards = async data => {
  const res = await api.get(`/capacitacao/usuario-conteudo/cards`, {
    params: data,
  });
  return res?.data?.data || [];
};

export const showDetails = async id => {
  const res = await api.get(`/capacitacao/usuario-conteudo/${id}`);
  return res?.data?.data || [];
};

export const updateDetails = async id => {
  const res = await api.put(`/capacitacao/usuario-conteudo/${id}`);
  return res?.data || [];
};

export const getConfig = async () => {
  const res = await api.get(`/capacitacao/configuracao-automacao`);
  return res?.data || [];
};

export const saveConfig = async (id, data) => {
  const res = await api.put(`/capacitacao/configuracao-automacao`, data);
  return res?.data || [];
};
