// React
import React, { useState, useEffect } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { setReportsFilters } from 'store/modules/filterFormulario/actions';

// Components
import GhostButton from 'components/Buttons/Ghost';
import DefaultButton from 'components/Buttons/Default';

// icons
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';

import {
  ListItem,
  Popover,
  List,
  ListItemText,
  ListItemButton,
  Checkbox,
  Collapse,
} from '@mui/material';
import * as S from './styled';

// Utils
import { items } from '../../../items';

const Filters = ({ id, open, onClose, anchorEl, totalFilters, isHistory }) => {
  const dispatch = useDispatch();
  const filters = useSelector(state => {
    return state.filterFormulario;
  });

  // opcoes filters
  const [checked, setChecked] = useState([]);
  const [itemsFilters, setItemsFilters] = useState([]);
  const defaultValues = [
    'Aprovador',
    'Aluno',
    'Filial do Aluno',
    'Filial do Aprovador',
  ];
  const defaultValuesHistory = ['Aluno', 'Filial do Aluno'];
  const [openCollapseField, setOpenCollapseField] = useState(false);
  const [openCollapseColumns, setOpenCollapseColumns] = useState(true);

  useEffect(() => {
    const itemsFilters = items[1].types.map(item => {
      return {
        id: item.id_tipo_campo,
        value: false,
        label: item.nome,
      };
    });
    setItemsFilters(itemsFilters);
    filters?.reportsFilters?.length === 0
      ? setChecked([])
      : setChecked(JSON.parse(JSON.stringify(filters?.reportsFilters)));
  }, []);

  const handleToggle = value => () => {
    const newChecked = [...checked];
    let currentIndex = -1;
    const currentValue = newChecked.filter((item, k) => {
      if (value.id === item.id) {
        currentIndex = k;
        return item;
      }
    });

    if (currentValue.length === 0) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const handleSave = () => {
    dispatch(setReportsFilters(checked));
    onClose();
  };

  const handleClear = () => {
    dispatch(setReportsFilters([]));
    setChecked([]);
  };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <S.Main>
        <S.Header>
          <h2>Pesquisar por</h2>
          <S.ClearButton onClick={handleClear}>Limpar filtros</S.ClearButton>
        </S.Header>

        <S.SelectsContainer>
          <h3>Colunas</h3>
          <ListItemButton
            onClick={() => {
              setOpenCollapseField(!openCollapseField);
            }}
          >
            <ListItemText primary="Filtros por colunas" />
            {openCollapseField ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openCollapseField} timeout="auto" unmountOnExit>
            <List>
              {(isHistory ? defaultValuesHistory : defaultValues).map(value => {
                const labelId = `checkbox-list-secondary-label-${value}`;
                return (
                  <ListItem
                    style={{ opacity: 0.5 }}
                    key={value}
                    secondaryAction={
                      <Checkbox
                        edge="end"
                        checked
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    }
                    disablePadding
                  >
                    <ListItemButton>
                      <ListItemText id={labelId} primary={value} />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Collapse>
          <h3>Campos</h3>
          <ListItemButton
            onClick={() => {
              setOpenCollapseColumns(!openCollapseColumns);
            }}
          >
            <ListItemText primary="Filtros por colunas" />
            {openCollapseColumns ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openCollapseColumns} timeout="auto" unmountOnExit>
            <List style={{ overflowY: 'scroll', height: 200, marginTop: 15 }}>
              {itemsFilters.map(value => {
                const labelId = `checkbox-list-secondary-label-${value}`;
                return (
                  <ListItem
                    key={value.id}
                    secondaryAction={
                      <Checkbox
                        edge="end"
                        onChange={handleToggle(value)}
                        checked={
                          checked.filter(item => item.id === value.id).length !=
                          0
                        }
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    }
                    disablePadding
                  >
                    <ListItemButton>
                      <ListItemText id={labelId} primary={value.label} />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Collapse>
        </S.SelectsContainer>

        <S.Footer>
          <GhostButton onClick={onClose} size="medium" id="cancelar">
            CANCELAR
          </GhostButton>

          <DefaultButton onClick={handleSave} size="medium" id="aplicar">
            APLICAR
          </DefaultButton>
        </S.Footer>
      </S.Main>
    </Popover>
  );
};

export default Filters;
