// React
import { useSelector } from 'react-redux';
import { useState, useCallback, useEffect } from 'react';

// Styles
import Table from 'components/Table';
import Loading from 'components/Loading';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { toast } from 'react-toastify';
import { formatNewHour } from 'utils/dates';
import * as S from './styled';
import * as C from '../styled';

// Components
import InfoModal from './InfoModal';
import WarningModal from './WarningModal';
import { DefaultTable } from 'components/_Table/templates/default';

// Components Material UI

// Utils
import { columns } from './columns';
import * as services from './services';
import { trackEvent } from 'utils/mixpanel';

export const Envios = () => {
  const user = useSelector(state => state.auth?.user?.user);

  // General States
  const [data, setData] = useState([]);
  const [uniqueData, setUniqueData] = useState([]);

  // Table States
  const [loading, setLoading] = useState(false);
  // Modal States
  const [openModalWarning, setOpenModalWarning] = useState(false);
  const [openModalInfo, setOpenModalInfo] = useState(false);

  // Global Filter
  const filter = useSelector(state => state.filter);

  // Click on table line for set details value
  const onClickRow = id => {
    for (const i in data) {
      if (data[i].id === id) {
        setUniqueData(data[i].detalhes);
      }
    }
  };

  // ------------------REQUEST----------------------------//
  const fetchData = useCallback(async query => {
    setLoading(true);
    const res = await services.requireEnvios(query);
    setData(res.data);
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const tableFormat = () => {
    const col = columns.map(item => {
      if (item.id === 'detalhes') {
        return {
          header: 'Detalhes',
          align: 'center',
          children: (value, item) => (
            <S.ColDriver>
              <S.Paragraph>
                {item.detalhes?.length == 0 && (
                  <VisibilityOffOutlinedIcon
                    style={{ opacity: '0.2' }}
                    onClick={() => {
                      toast.warning('Sem detalhes para o envio');
                    }}
                  />
                )}
                {item.detalhes?.length > 0 && (
                  <VisibilityOutlinedIcon
                    onClick={() => {
                      onClickRow(item.id);
                      setOpenModalWarning(true);
                    }}
                    style={{ cursor: 'pointer' }}
                  />
                )}
              </S.Paragraph>
            </S.ColDriver>
          ),
        };
      }
      return item;
    });
    return col;
  };

  return (
    <S.Container>
      <>
        <C.TitleArea className="flex">
          <span className="textArea">Envios</span>
        </C.TitleArea>

        <div>
          <DefaultTable
            data={data || []}
            columns={tableFormat(columns)}
            searchKeys={['filial.nome']}
            loading={loading}
            placeholder="Buscar por Filial"
            sortBy={{ id: 'ativo', order: 'ASC' }}
            searchEvent={search =>
              trackEvent(user, 'Buscar envios', null, search)
            }
            empty={{
              title: 'Ops! Você não tem nenhum envio',
              description: 'Verifique a data aplicada',
            }}
          />
        </div>
        <WarningModal
          open={openModalWarning}
          handleClose={setOpenModalWarning}
          openNext={setOpenModalInfo}
        />
        <InfoModal
          open={openModalInfo}
          handleClose={setOpenModalInfo}
          data={uniqueData}
        />
      </>
    </S.Container>
  );
};
