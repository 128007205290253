import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// Components
import TextInput from 'components/Inputs/TextField';
import SelectCliente from 'components/Inputs/SelectCliente';
import { useTheme } from 'styled-components';

// Material UI
import { Grid } from '@mui/material';
import { KeyboardDoubleArrowRight } from '@mui/icons-material';

// Servies
import { formatNewHour, formatNewHourClean } from 'utils/dates';
import { toast } from 'react-toastify';
import * as S from './styled';

export const InformacoesGerais = ({
  evaluation,
  setChanged,
  setData,
  disabled,
  newClient,
  setNewClient,
}) => {
  const theme = useTheme();
  // FORM STATES
  const { clients } = useSelector(state => {
    return state.selects;
  });

  const [placaCavalo, setPlacaCavalo] = useState('');
  const [placaReboque, setPlacaReboque] = useState('');
  const [local, setLocal] = useState('');
  const [transportadora, setTransportadora] = useState('');
  const [filial, setFilial] = useState('');
  const [inicial, setInicial] = useState('');
  const [final, setFinal] = useState('');
  const [disponilizado, setDisponibilizado] = useState('');
  const [distributor, setDistributor] = useState(null);
  const [hasDeleteReview, setHasDeleteReview] = useState(false);

  // Formatação do objeto que sera enviado para como Body
  // na request de update
  useEffect(() => {
    if (newClient) {
      setData({
        placa_reboque: placaReboque,
        local,
        id_da_distribuidora: distributor,
        shouldReview: true,
      });
    } else {
      setData({
        placa_reboque: placaReboque,
        local,
        id_da_distribuidora: distributor,
      });
    }
  }, [placaReboque, local, distributor]);

  useEffect(() => {
    if (evaluation) {
      setPlacaCavalo(evaluation.caminhao.placa);
      setPlacaReboque(evaluation.placa_reboque ? evaluation.placa_reboque : '');
      setLocal(evaluation.local ? evaluation.local : '');
      setTransportadora(evaluation.filial.empresa.nome);
      setFilial(evaluation.filial.nome);
      setInicial(formatNewHourClean(evaluation.hora_inicial));
      setFinal(formatNewHourClean(evaluation.hora_final));
      setDisponibilizado(
        evaluation.data_pendente
          ? formatNewHourClean(evaluation.data_pendente)
          : formatNewHour(evaluation.createdAt),
      );
      setDistributor(evaluation.id_da_distribuidora);

      // Bloquei de troca de cliente caso tenha algum desvio em revisao de exclusao
      const desvioDeleteReview = evaluation.desvios_motoristas.find(
        desvio =>
          desvio.aprovado_distribuidora === 0 ||
          desvio.aprovado_distribuidora == 3,
      );
      if (desvioDeleteReview) {
        setHasDeleteReview(true);
        toast.warning(
          'Avaliação possui desvio em revisão de exclusão. Não é possivel alterar o cliente.',
        );
      }
    }
  }, [evaluation]);

  const changeMonitoring = (value, func) => {
    if (value.length >= 1024) return;
    func(value);
    setChanged(true);
  };

  // Verificação se a opção é Raízen
  const distributorVerify = item => {
    if (evaluation.revisao_da_operacao === 'PENDENTE') {
      toast.error('Avalição em revisão, não é possivel alterar o cliente');
      return;
    }

    if (item === 1 && evaluation.tipo === 'Não anunciada extraordinária') {
      toast.error(
        'O cliente selecionado não permite avaliações extraordinárias',
      );
      return;
    }

    if (distributor === 1) setNewClient(item);
    changeMonitoring(item, setDistributor);
  };

  return (
    <>
      <S.Box>
        <h2>Informações do Motorista</h2>
        <div className="userArea">
          <div className="imageArea">
            {evaluation.motorista.foto && (
              <img src={evaluation.motorista.foto} />
            )}
            {!evaluation.motorista.foto && (
              <span className="letters">{evaluation.motorista.nome[0]}</span>
            )}
          </div>
          <div className="textArea">
            {}
            <span className="userName">
              {evaluation.motorista.nome.toLowerCase()}
            </span>
            <span className="filialName">Filial: {evaluation.filial.nome}</span>
          </div>
        </div>
        <Grid
          className="formArea"
          container
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          marginTop={1}
        >
          <Grid item xs={6} md={6}>
            <TextInput
              disabled
              label="Placa Cavalo"
              value={placaCavalo}
              onChange={() => setPlacaCavalo(placaCavalo)}
              props={{ readOnly: true }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={6} md={6}>
            <TextInput
              label="Placa Reboque"
              value={placaReboque}
              onChange={e => changeMonitoring(e.target.value, setPlacaReboque)}
              disabled={disabled}
              variant={disabled ? 'filled' : 'outlined'}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <TextInput
              label="Local/Trecho"
              placeholder="Insira sua descrição"
              value={local}
              onChange={e => changeMonitoring(e.target.value, setLocal)}
              InputProps={{ multiline: true }}
              multiline
              rows={6}
              disabled={disabled}
              variant={disabled ? 'filled' : 'outlined'}
            />
            <div className="counter">{`${local ? local.length : 0}/1023`}</div>
          </Grid>
        </Grid>
      </S.Box>
      <S.Box>
        <h2>Informações da Viagem</h2>
        <div className="userArea">
          <SelectCliente
            value={distributor}
            setValue={value => distributorVerify(value)}
            distributors={clients}
            disabled={
              disabled ||
              evaluation?.revisao_da_operacao === 'PENDENTE' ||
              hasDeleteReview
            }
          />
          {evaluation?.alteracao_distribuidora &&
            evaluation?.id_da_distribuidora !==
              evaluation?.alteracao_distribuidora && (
              <>
                <KeyboardDoubleArrowRight
                  sx={{
                    color: theme.palette.brand.secondary.natural,
                    opacity: 0.6,
                    margin: '20px 20px 0px 20px',
                  }}
                  fontSize="large"
                />

                <SelectCliente
                  value={evaluation?.alteracao_distribuidora}
                  distributors={clients}
                  disabled
                />
              </>
            )}
        </div>
        <Grid
          className="formArea"
          container
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          marginTop={1}
        >
          <Grid item xs={6} md={6}>
            <TextInput
              disabled
              label="Transportadora"
              value={transportadora}
              onChange={() => setTransportadora(transportadora)}
              props={{ readOnly: true }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={6} md={6}>
            <TextInput
              disabled
              label="Filial"
              value={filial}
              onChange={() => setFilial(filial)}
              props={{ readOnly: true }}
              variant="filled"
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <TextInput
              disabled
              label="Data Inicial"
              value={inicial}
              onChange={() => setInicial(inicial)}
              props={{ readOnly: true }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={6} md={6}>
            <TextInput
              disabled
              label="Data Final"
              value={final}
              onChange={() => setFinal(final)}
              props={{ readOnly: true }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <TextInput
              disabled
              label="Disponibilizado em:"
              value={disponilizado}
              onChange={() => setDisponibilizado(disponilizado)}
              props={{ readOnly: true }}
              variant="filled"
            />
          </Grid>
        </Grid>
      </S.Box>
    </>
  );
};
