import styled, { css } from 'styled-components';

export const QuestionBox = styled.div`
  ${({ theme, hasError }) => css`
    padding: 20px;
    background-color: ${hasError
      ? theme.palette.semantics.feedback.attention.light
      : theme.palette.system.highlight};
  `}
`;

export const ResponseArea = styled.div`
  ${({ theme, hasError }) => css`
    margin-top: 18px;
    display: flex;
    flex-direction: column;
    width: 100%;

    .counter {
      align-self: end;
      font-weight: 600;
      color: ${theme.palette.words.text.light};
      font-size: 12px;
      margin-top: 6px;
      margin-left: 5px;
    }

    input {
      width: 50%;
      max-width: 80px;
      font-size: 16px;
      border: 2px solid
        ${hasError
          ? theme.palette.semantics.feedback.attention.natural
          : theme.palette.brand.primary.light};
      padding: 15px 15px;
      border-radius: 5px;

      &:hover {
        border: 2px solid
          ${hasError
            ? theme.palette.semantics.feedback.attention.natural
            : theme.palette.system.black};
      }

      &:focus {
        border: 2px solid
          ${hasError
            ? theme.palette.semantics.feedback.attention.natural
            : theme.palette.brand.secondary.natural};
      }

      &:disabled {
        border: none;
        background-color: ${theme.palette.system.border};
        color: ${theme.palette.words.subtitle.natural};
      }
    }
  `}
`;
