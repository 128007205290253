export const columnsDesvios = [
  { header: 'Período', id: 'periodo' },
  {
    header: 'Desvio',
    id: 'titulo',
  },
  {
    header: 'Qtde Total',
    id: 'total',
    total: true,
    align: 'center',
  },
  {
    header: 'Qtde Abertos',
    id: 'total_abertos',
    total: true,
    align: 'center',
  },
  {
    header: 'Qtde Pendentes',
    id: 'total_pendentes',
    total: true,
    align: 'center',
  },
  {
    header: 'Qtde Finalizados',
    id: 'total_finalizados',
    total: true,
    align: 'center',
  },
  {
    header: 'Qtde Excluídos',
    id: 'total_deletados',
    total: true,
    align: 'center',
  },
];

export const columnsAcoes = [
  { header: 'Período', id: 'periodo' },
  {
    header: 'Status',
    id: 'status',
  },
  {
    header: 'Qtde Total',
    id: 'total',
    total: true,
    align: 'center',
  },
];

export const columnsFrota = [
  { header: 'Período', id: 'periodo' },
  {
    header: 'Status',
    id: 'status',
  },
  {
    header: 'Qtde Total',
    id: 'total',
    total: true,
    align: 'center',
  },
];
