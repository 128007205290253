import React from 'react';
import {
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from 'recharts';
import { IconButton, SvgIcon, Grid } from '@mui/material';
import CheckFiltersCard from 'components/Cards/CheckFiltersCard';
import { useTheme } from 'styled-components';
import * as S from './styled';
import { paletteColors } from '../../../styles/colors';
import { ReactComponent as settingsIcon } from '../../../images/icons/sidebar/inicio.svg';
import Loading from '../../Loading';

const PieGraph = ({
  data,
  colors,
  title,
  subtitle,
  legend,
  loading,
  tooltip,
  tabComponent,
}) => {
  const theme = useTheme();
  const chartColors = paletteColors[colors] || paletteColors.default;

  const total = data.reduce((accumulator, object) => {
    return accumulator + object.value;
  }, 0);

  // Renderiza a label = percentual de cada item na torta
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = outerRadius + 5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    if (percent)
      return (
        <text
          x={x}
          y={y}
          fill="#858585"
          textAnchor={x > cx ? 'start' : 'end'}
          dominantBaseline="central"
        >
          {`${(percent * 100).toFixed(0)}%`}
        </text>
      );
  };

  return (
    <S.StyledCard>
      <S.Header>
        <div>
          <h2>{title}</h2>
          {subtitle && <p>{subtitle}</p>}
        </div>
        {tabComponent}
      </S.Header>

      {data?.length > 0 ? (
        <>
          {legend && (
            <>
              <S.TotalContainer>
                <h2>{total}</h2>
              </S.TotalContainer>

              <S.LegendContainer>
                <Grid container flexDirection="column">
                  {data.map((element, index) => {
                    return (
                      <Grid item xs={4} md={4} xl={4} key={index}>
                        <S.LegendItem
                          color={chartColors[index % chartColors.length]}
                        >
                          <h2>{element.value}</h2>
                          <h3>{element.name}</h3>
                        </S.LegendItem>
                      </Grid>
                    );
                  })}
                </Grid>
              </S.LegendContainer>
            </>
          )}

          {loading ? (
            <S.BoxLoading>
              <Loading />
            </S.BoxLoading>
          ) : (
            <ResponsiveContainer width="100%" height="100%">
              <PieChart
                margin={{
                  top: 20,
                  right: 20,
                  left: 30,
                  bottom: 10,
                }}
              >
                {tooltip && <Tooltip />}

                <Pie
                  data={data}
                  cx={80}
                  cy={120}
                  innerRadius={68}
                  outerRadius={80}
                  paddingAngle={5}
                  dataKey="value"
                  startAngle={60} // default
                  endAngle={420} // default
                  labelLine={false}
                  label={renderCustomizedLabel}
                >
                  {data.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={chartColors[index % chartColors.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          )}
        </>
      ) : (
        <CheckFiltersCard height="200px" marginTop="45px" />
      )}
    </S.StyledCard>
  );
};

export default PieGraph;
