import styled, { css, keyframes } from 'styled-components';
import colors from 'styles/colors';

const placeholderShimmer = keyframes`
  0% {
    background-position: -40rem 0;
  }
  100% {
    background-position: 40rem 0;
  }
`;

const inLoading = css`
  .val {
    transition: all 0.8s ease-in-out;
    opacity: ${props => (props.isLoading ? 0 : 1)};
  }

  .loading {
    border-radius: 5px;
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    justify-content: flex-start;
    align-items: center;
    opacity: ${props => (props.isLoading ? 0.4 : 0)};
    transition: all 0.9s ease;
    background-image: linear-gradient(
      to right,
      #e2e2e8 0%,
      #edeef1 20%,
      #e2e2e8 40%,
      #e2e2e8 100%
    );
    background-size: 80rem 14rem;
    animation: ${placeholderShimmer} 3s linear infinite forwards;
  }
`;

export const Container = styled.div`
  padding-bottom: 30px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
`;

export const BoxInfos = styled.div`
  background-color: #fff;
  border: 2px solid ${colors.greyBorder};
  border-radius: 4px;
  padding: 30px;
  height: 145px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .bar {
    height: 80px;
    width: 1px;
    background-color: ${colors.greyTiny};
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;

  .text {
    font-weight: 500;
    font-size: 18px;
    color: ${colors.greyTitle};
    width: 230px;
    height: 22px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    ${inLoading}
  }

  .value {
    font-weight: 900;
    font-size: 16px;
    margin-top: 5px;
    width: 60px;
    height: 22px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    ${inLoading}
  }
`;

export const CircleBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  .textFields {
    margin-left: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .text {
      font-weight: 500;
      font-size: 18px;
      color: ${colors.greyTitle};

      width: 230px;
      height: 22px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      ${inLoading}
    }

    .value {
      font-weight: 900;
      font-size: 16px;
      margin-top: 5px;
      width: 60px;
      height: 22px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      ${inLoading}
    }
  }
`;

export const Paragraph = styled.p`
  padding-left: 5px;
  padding-right: 5px;
  font-size: ${props => (props.fontsize ? props.fontsize : '16px')};
  color: ${props => (props.color ? props.color : colors.grey)};
  font-weight: ${props => (props.fontweight ? props.fontweight : 400)};
  min-width: ${props => props.minwidth};

  &.status {
    font-weight: bold;
    color: ${props => props.color};
    background-color: ${props => props.bg};
    text-align: center;
    border-radius: 100px;
    width: 130px;
  }
`;

export const ColDriver = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-transform: capitalize;

  h1 {
    font-size: 14px;
    font-weight: 600;
    color: ${colors.greyTitle};
  }

  .container-img-name {
    display: flex;
    flex-direction: row;
  }
  img {
    width: 41px;
    height: 41px;
    border: 1px solid ${colors.greyBorder};
    border-radius: 100px;
  }
`;
