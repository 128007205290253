import * as S from './styled';
import { formatNewDate, formatTime } from '../../utils/dates';

const howToColor = (criticality, theme) => {
  switch (criticality) {
    case 'MODERADA':
      return theme?.palette?.semantics?.feedback?.warning?.natural;
    case 'GRAVE':
      return theme?.palette?.semantics?.feedback?.attention?.natural;
    case 'GRAVÍSSIMA':
      return theme?.palette?.semantics?.feedback?.attention?.natural;
    default:
      return theme?.palette?.semantics?.feedback?.information?.natural;
  }
};

const getStatusColor = (status, theme) => {
  switch (status) {
    case 'PENDENTE':
      return theme?.palette?.semantics?.feedback?.warning?.natural;
    case 'FINALIZADO':
      return theme?.palette?.semantics?.feedback?.success?.natural;
    case 'DELETADO':
      return theme?.palette?.semantics?.feedback?.attention?.natural;
  }
};

const infoMessage = 'Desvios de descarga...';

const statusTabs = [
  { value: 'PENDENTE', label: 'Pendentes' },
  { value: 'FINALIZADO', label: 'Finalizados' },
  { value: 'DELETADO', label: 'Deletados' },
];

const fields = [
  {
    label: 'ID',
    value: 'id',
    selected: true,
    default: true,
  },
  {
    label: 'Tipo do desvio',
    value: 'tipo.descricao',
    selected: true,
    default: true,
  },
  {
    label: 'Criticidade',
    value: 'tipo.criticidade',
    selected: true,
    default: true,
  },
  {
    label: 'Pontuação',
    value: 'tipo.peso',
    selected: true,
    default: true,
  },
  {
    label: 'Objeto',
    value: 'objeto',
    selected: true,
    default: true,
  },
  {
    label: 'Avaliação',
    value: 'id_da_avaliacao',
    selected: false,
    default: false,
  },
  {
    label: 'Data do desvio',
    value: 'data_desvio',
    selected: true,
    default: true,
  },
  {
    label: 'Motorista',
    value: 'motorista.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Filial',
    value: 'motorista.filial.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Veículo',
    value: 'caminhao.placa',
    selected: true,
    default: true,
  },
  {
    label: 'Cliente',
    value: 'distribuidora.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Link da Imagem',
    value: 'img_ocorrencia',
    selected: false,
    default: false,
  },
  {
    label: 'Status',
    value: 'status',
    selected: true,
    default: true,
  },
  {
    label: 'Data aprovação SSMA',
    value: 'data_pendente',
    selected: true,
    default: true,
  },
  {
    label: 'Previsão de conclusão',
    value: 'data_previsao',
    selected: false,
    default: false,
  },
  {
    label: 'Data conclusão',
    value: 'data_encerramento',
    selected: true,
    default: true,
  },
  {
    label: 'Data exclusão',
    value: 'data_exclusao',
    selected: false,
    default: false,
  },
  {
    label: 'Plano de ação',
    value: 'plano_de_acao',
    selected: true,
    default: true,
  },
  {
    label: 'Observação',
    value: 'observacao',
    selected: false,
    default: false,
  },
  {
    label: 'Evidência',
    value: 'arquivo_justificativa',
    selected: false,
    default: false,
  },
  {
    label: 'Responsável',
    value: 'responsavel.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Criador',
    value: 'criador.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Disponibilizado Em',
    value: 'createdAt',
    selected: true,
    default: true,
  },
  {
    label: 'Justificativa exclusão',
    value: 'motivo_exclusao',
    selected: false,
    default: false,
  },
  {
    label: 'Contestação motorista',
    value: 'comentario_contestacao',
    selected: false,
    default: false,
  },
];

const resetExcelFields = fields => {
  return fields.map(i => {
    if (!i.default) i.selected = false;
    return i;
  });
};

export {
  howToColor,
  getStatusColor,
  infoMessage,
  statusTabs,
  fields,
  resetExcelFields,
};

export const columns2 = [
  {
    header: 'Id',
    id: 'id',
    type: 'number',
    sort: true,
    width: 100,
  },
  {
    header: 'Motorista/Filial',
    id: 'motorista.nome',
    type: 'string',
    tooltip: true,
    sort: true,
    subRow: {
      prefix: 'FIlial:',
      value: (_, item) => item?.motorista?.filial?.nome,
    },
  },
  {
    header: 'Placa',
    id: 'caminhao.placa',
    type: 'string',
    sort: true,
    width: 100,
    style: {
      textTransform: 'uppercase',
    },
  },
  {
    header: 'Desvio',
    id: 'tipo.descricao',
    type: 'string',
    sort: true,
    tooltip: true,
  },
  {
    header: 'Data Desvio',
    id: 'data_desvio',
    type: 'string',
    sort: true,
    value: (_, item) => formatNewDate(item?.data_desvio),
    subRow: {
      prefix: 'Horário:',
      value: (_, item) => formatTime(item?.data_desvio, true),
    },
  },
  {
    header: 'Disponibilizado em',
    id: 'createdAt',
    type: 'date',
    sort: true,
    width: 200,
    subRow: {
      prefix: 'Vencimento:',
      value: (_, item) => formatNewDate(item?.createdAt),
    },
  },
  {
    header: 'Responsável',
    id: 'responsavel.nome',
    type: 'string',
    sort: true,
    tooltip: true,
  },
  {
    header: 'Criticidade',
    id: 'tipo.criticidade',
    type: 'string',
    sort: true,
    align: 'center',
    style: {
      textTransform: 'uppercase',
    },
    conditional: [
      {
        condition: value => true,
        style: theme => ({
          color: theme?.palette?.semantics?.feedback?.information?.natural,
          backgroundColor:
            theme?.palette?.semantics?.feedback?.information?.light,
          textAlign: 'center',
        }),
      },
      {
        condition: value => value === 'MODERADA',
        style: theme => ({
          color: theme?.palette?.semantics?.feedback?.warning?.natural,
          backgroundColor: theme?.palette?.semantics?.feedback?.warning?.light,
          textAlign: 'center',
        }),
      },
      {
        condition: value => value === 'GRAVÍSSIMA' || value === 'GRAVE',
        style: theme => ({
          color: theme?.palette?.semantics?.feedback?.attention?.natural,
          backgroundColor:
            theme?.palette?.semantics?.feedback?.attention?.light,
          textAlign: 'center',
        }),
      },
    ],
  },
];
