import colors from 'styles/colors';
import { differenceInDays, format } from 'date-fns';

export const formatStLine = (concluded, freedom = [], executed = [], index) => {
  let obj = {
    children: '',
    style: '',
  };

  if (concluded) {
    obj = {
      children: 'Concluído',
      style: { color: colors.greenMain, fontWeight: 900 },
    };
  } else {
    if (freedom?.includes(index) && executed?.includes(index)) {
      obj = {
        children: 'Concluído',
        style: { color: colors.greenMain, fontWeight: 900 },
      };
    } else if (freedom.includes(index)) {
      obj = {
        children: 'Liberado',
        style: { color: colors.greenSucces, fontWeight: 900 },
      };
    } else {
      obj = {
        children: 'Bloqueado - Realize os módulos anteriores',
        style: { color: colors.redDanger, fontWeight: 900 },
      };
    }
  }

  return obj;
};

export const formatStEval = (
  totalIndex,
  executed,
  avaliacao,
  is_quiz,
  coeficiente = 0,
) => {
  let obj = {
    children: '',
    style: '',
  };

  const allCompleted = totalIndex <= executed?.length;

  let total = avaliacao?.length;
  let totalResp = avaliacao?.filter(item => item?.resposta).length;

  if (!allCompleted) {
    obj = {
      children: 'Bloqueado - Realize os módulos anteriores',
      style: { color: colors.redDanger, fontWeight: 900 },
    };
  } else {
    if (totalResp === total) {
      if (is_quiz) {
        obj = {
          children: 'Aprovado',
          style: { color: colors.greenMain, fontWeight: 900 },
        };
      } else {
        let correct = avaliacao?.filter(item => item?.resposta?.correta).length;
        let correctCoef = Math.round((correct * 100) / total);
        if (correctCoef >= coeficiente) {
          obj = {
            children: `Aprovado - ${correctCoef}% de acerto - Mínimo ${coeficiente}%`,
            style: { color: colors.greenMain, fontWeight: 900 },
          };
        } else {
          obj = {
            children: `Reprovado - ${correctCoef}% de acerto - Mínimo ${coeficiente}%`,
            style: { color: colors.redDanger, fontWeight: 900 },
          };
        }
      }
    } else if (!totalResp) {
      obj = {
        children: 'Liberado',
        style: { color: colors.greenSucces, fontWeight: 900 },
      };
    } else if (totalResp < total) {
      obj = {
        children: 'Inciado',
        style: { color: colors.orangeAlert, fontWeight: 900 },
      };
    }
  }
  return obj;
};

export const formatIconColor = (
  concluded,
  freedom = [],
  executed = [],
  index,
) => {
  let htmlColor = '';

  if (concluded) {
    htmlColor = colors.greenMain;
  } else {
    if (freedom?.includes(index) && executed?.includes(index)) {
      htmlColor = colors.greenMain;
    } else if (freedom.includes(index)) {
      htmlColor = colors.greenSucces;
    } else {
      htmlColor = colors.redDanger;
    }
  }

  return htmlColor;
};

export const formatIconColorEval = (totalIndex, executed, avaliacao) => {
  let htmlColor = '';

  const allCompleted = totalIndex <= executed?.length;

  let total = avaliacao?.length;
  let totalResp = avaliacao?.filter(item => item?.resposta).length;

  if (!allCompleted) {
    htmlColor = colors.redDanger;
  } else {
    if (totalResp === total) {
      htmlColor = colors.greenMain;
    } else if (!totalResp) {
      htmlColor = colors.greenSucces;
    } else if (totalResp < total) {
      htmlColor = colors.orangeAlert;
    }
  }

  return htmlColor;
};

export const definePercentage = (
  midias,
  executed = [],
  avaliacao,
  totalIndex,
  // is_quiz,
  // coeficiente = 0,
) => {
  let total = +totalIndex + 1;

  let indexExecuted = [];

  midias.forEach((item, index) => {
    if (item.completedMidia) indexExecuted.push(index);
  });

  let newObject = new Set([...indexExecuted, ...executed]);

  let evalCompleted = avaliacao?.filter(item => item?.resposta).length;
  const totalEval = avaliacao?.length;
  if (totalEval && evalCompleted === totalEval) {
    // TODO! Se houver alteração no modo de porentagem, descomentar o if
    // if (is_quiz) {
    newObject.add(totalIndex);
    // } else {
    //   let correct = avaliacao?.filter(item => item?.resposta?.correta).length;
    //   let correctCoef = Math.round((correct * 100) / totalEval);
    //   if (correctCoef >= coeficiente) newObject.add(totalIndex);
    // }
  }

  let percentage = (newObject.size * 100) / total;
  return Math.ceil(percentage);
};

export const defineBarColor = (
  midias,
  executed = [],
  avaliacao,
  totalIndex,
) => {
  let total = +totalIndex + 1;

  let indexExecuted = [];

  midias.forEach((item, index) => {
    if (item.completedMidia) indexExecuted.push(index);
  });

  let newObject = new Set([...indexExecuted, ...executed]);

  let evalCompleted = avaliacao?.filter(item => item?.resposta).length;
  const totalEval = avaliacao?.length;
  if (evalCompleted === totalEval) newObject.add(totalIndex);

  let perc = (newObject.size * 100) / total;

  if (perc <= 25) return colors.redDanger;
  if (perc > 25 && perc <= 50) return colors.orangeAlert;
  if (perc > 50 && perc <= 75) return colors.greenSucces;
  if (perc > 75) return colors.greenMain;
};

export const formatFinalDate = date => {
  let obj = {
    children: '',
    style: {},
  };

  if (!date) return obj;
  let diffDays = differenceInDays(new Date(date), new Date());
  if (diffDays < 0) {
    obj = {
      children: `em ${format(new Date(date), 'dd/MM/yyyy')}`,
      style: { color: colors.greyTiny, marginLeft: '-100px' },
    };
  }
  if (diffDays >= 0) {
    obj = {
      children: `até ${format(new Date(date), 'dd/MM/yyyy')}`,
      style: { color: colors.greenMain, marginLeft: '-133px' },
    };
  }

  return obj;
};

export const formaterStatus = status => {
  let obj = {
    children: '',
    color: '',
  };

  switch (status?.toLowerCase()) {
    case 'aprovado':
      obj = {
        children: 'Aprovado',
        color: colors.greenMain,
      };
      break;
    case 'reprovado':
      obj = {
        children: 'Reprovado',
        color: colors.redDanger,
      };
      break;
    case 'finalizado':
      obj = {
        children: 'Finalizado',
        color: colors.grey,
      };
      break;
  }

  return obj;
};

export const defineConclusion = (
  midias,
  executed = [],
  avaliacao,
  totalIndex,
) => {
  let total = +totalIndex + 1;

  let indexExecuted = [];

  midias.forEach((item, index) => {
    if (item.completedMidia) indexExecuted.push(index);
  });

  let newObject = new Set([...indexExecuted, ...executed]);

  let evalCompleted = avaliacao?.filter(item => item?.resposta).length;
  const totalEval = avaliacao?.length;
  if (totalEval && evalCompleted === totalEval) newObject.add(totalIndex);

  let percentage = (newObject.size * 100) / total;
  return percentage === 100;
};
