import styled, { css, keyframes } from 'styled-components';

export const Container = styled.div`
  padding: 1rem 0;
  width: 100%;
  border: 3px solid ${({ borderColor }) => borderColor ?? '#95aac9'};
  border-radius: 8px;
  height: 100%;

  .title {
    padding: 5px;
    font: normal normal 900 32px/20px Texta;
    letter-spacing: 0px;
    color: ${({ titleColor }) => titleColor ?? '#4b5166'};
    text-align: center;
  }
  .footer {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    align-items: center;
    flex-direction: column;
    .text-footer {
      text-align: left;
      font: normal normal medium 16px/24px Texta;
      letter-spacing: 0px;
      color: #4b5166;
      opacity: 1;
    }
    .btn-ignore {
      text-align: center;
      text-decoration: underline;
      font: normal normal medium 16px/24px Texta;
      letter-spacing: 0px;
      color: #95aac9;
      margin-top: 10px;
      margin-bottom: 10px;
      cursor: pointer;
    }
  }
`;

export const Content = styled.div`
  padding-top: 20px;
  display: flex;
  width: 100%;
  align-items: center;
  background-color: ${({ bakground }) => bakground};
  height: 293px;
  margin-top: 15px;
`;

export const ContentPart = styled.div`
  .title {
    text-align: left;
    font: normal normal 900 32px/20px Texta;
    letter-spacing: 0px;
    color: #4b5166;
  }
  .desvio-sec {
    display: flex;
    flex-direction: 'row';
    color: #4b5166;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .desvio-sec > span {
    height: 24px;
    width: 24px;
    display: flex;
    background-color: #4b5166;
    color: 'white';
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    font-size: 15px;
    font-weight: 900;
    letter-spacing: 0px;
    color: #ffffff;
    margin-left: 5px;
    margin-right: 5px;
  }
  .driver-info {
    margin-left: 20px;
    padding: 20px;
    font-size: 14px;
    color: #4b5166;
    .title-driver {
      font-size: 20px;
      letter-spacing: 0px;
    }
    .title-driver > span {
      font-weight: 900;
    }
  }
  .single-desvio {
    margin-left: 10px;
    margin-bottom: 20px;
    color: #4b5166;

    span {
      font-size: 24px;
      font-weight: 900;
    }
  }
`;

export const DefaultContent = styled.div`
  width: 80%;
  min-width: 330px;
  margin: auto;
  font-size: 14px;
  color: #95aac9;
  .driver-info {
    font-size: 16px;
    color: #4b5166;

    .title-driver {
      font-size: 20px;
      font-weight: 900;
    }

    span {
      font-weight: 900;
    }
  }
  .flex {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .left {
    justify-content: left;
  }
  .gap05 {
    gap: 0.5rem;
  }
  .gap1 {
    gap: 1rem;
  }
  .justify-between {
    justify-content: space-between;
  }
  .risco {
    border: 1px solid ${({ riscoColor }) => riscoColor ?? '#4b5166'};
    color: ${({ riscoColor }) => riscoColor ?? '#4b5166'};
    font-size: 16px;
    font-weight: bold;
    border-radius: 4px;
    padding: 8px;
  }
  .dtviagem {
    color: #4b5166;
    font-size: 16px;
    font-weight: bold;
    padding: 8px 0;
  }
  .desvios {
    display: flex;
    gap: 8px;
    align-items: center;
    color: #4b5166;
    font-size: 16px;
    font-weight: bold;

    span {
      font-weight: 900;
      font-size: 32px;
    }
  }
`;
