import api from 'services/api';

export const getHistoricoTratativas = async query => {
  const res = await api.get('/equipe-historico-tratativas', { params: query });
  return res.data;
};

export const getEquipe = async query => {
  const res = await api.get('/equipe', { params: query });
  return res.data;
};

export const getJustified = async query => {
  const res = await api.get('/equipe-justifica-exclusao', { params: query });
  return res.data;
};

export const getMovimentacoes = async query => {
  const res = await api.get('/equipe-movimentacoes', { params: query });
  return res.data;
};

export const getDesviosTratados = async query => {
  const res = await api.get('/equipe-desvios-tratados-prazo', {
    params: query,
  });
  return res.data;
};

export const getDesviosTratadosForaPrazo = async query => {
  const res = await api.get('/equipe-desvios-fora-prazo', {
    params: query,
  });
  return res.data;
};
