import * as types from './types';

export const setConteudo = (data, step) => {
  const obj = {
    type: null,
    payload: { data },
  };

  switch (step) {
    case 'id': {
      obj.type = types.SET_DATA_ID;
      break;
    }
    case 'geral': {
      obj.type = types.SET_DATA_GERAL;
      break;
    }
    case 'midias': {
      obj.type = types.SET_DATA_MIDIAS;
      break;
    }
    case 'avaliacao': {
      obj.type = types.SET_DATA_AVALIACAO;
      break;
    }
    case 'publico': {
      obj.type = types.SET_DATA_PUBLICO;
      break;
    }
    case 'configuracoes': {
      obj.type = types.SET_DATA_CONFIGURACOES;
      break;
    }
    case 'publicar': {
      obj.type = types.SET_DATA_PUBLICAR;
      break;
    }
    default:
      obj.type = types.RESET_DATA;
  }

  return obj;
};

export const initializeData = data => {
  return {
    type: types.INITIALIZE_DATA,
    payload: data,
  };
};
