import { Fragment, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import * as S from './styled';

import Empty from '../Empty';
import AddCard from '../AddCard';
import ContentCard from '../ContentCards';

const Templates = ({
  query,
  templates,
  inSearch,
  setModalAdd,
  forMe,
  loadingHistoryLine,
  onClickNavigateEdit,
  onClickSketch,
  loadingScreen,
  onClickView,
  loadingCard,
  initialLoading = true,
  noneEvents,
  tryed,
}) => {
  const [items, setItems] = useState(7);

  return (
    <>
      {templates?.length > 0 && (
        <S.Global isLoading={loadingScreen}>
          <S.Wrapper>
            {!forMe && (
              <AddCard
                title="Criar Novo Conteúdo"
                onClick={() => setModalAdd(true)}
              />
            )}
            {templates?.map((temp, key) => (
              <Fragment key={key}>
                {key < items && (
                  <ContentCard
                    key={key}
                    template={temp}
                    loadingHistoryLine={loadingHistoryLine}
                    onClickNavigateEdit={onClickNavigateEdit}
                    onClickSketch={onClickSketch}
                    loadingScreen={loadingScreen}
                    onClickView={onClickView}
                    loadingCard={loadingCard}
                    forMe={forMe}
                    tryed={tryed}
                  />
                )}
              </Fragment>
            ))}
          </S.Wrapper>
          {templates.length > items && (
            <S.SeeMore onClick={() => setItems(items + 8)}>
              <span className="textSeeMore">Ver Mais</span>
              <div className="divIcon">
                <KeyboardArrowDownIcon className="icon" />
              </div>
            </S.SeeMore>
          )}
        </S.Global>
      )}
      {templates?.length <= 0 && (
        <Empty
          query={query}
          inSearch={inSearch}
          setModalAdd={setModalAdd}
          titleButton="Novo conteúdo"
          forMe={forMe}
          tryed={tryed}
        />
      )}
    </>
  );
};

export default Templates;
