// Styled
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import TextInput from 'components/Inputs/TextField';
import * as S from './styled';

// React

// Components
import GlobalModal from '..';
import Radio from '../Components/Radio';

export const Hora = ({
  open,
  onClose,
  data,
  closeModalItems,
  currentElement,
  section,
}) => {
  const [totalContent, setTotalContent] = useState(null);

  // Step 1 (States)
  const [pergunta, setPergunta] = useState('');
  const [descricao, setDescricao] = useState('');
  const [obrigatorio, setObrigatorio] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);

  // Verify Edit or New
  useEffect(() => {
    if (data) {
      data = JSON.parse(JSON.stringify(data));
      setPergunta(data.pergunta);
      setDescricao(data.descricao);
      setObrigatorio(data.obrigatorio);
    }
  }, [data]);

  const validateStepOne = () => {
    if (!pergunta) {
      toast.error('Preencha uma pergunta para o campo');
      return;
    }

    setTotalContent({
      index: data?.index,
      pergunta,
      descricao,
      obrigatorio,
    });

    closeModalItems();
    resetFields();
  };

  const resetFields = () => {
    // Reset All fileds
    setPergunta('');
    setDescricao('');
    setObrigatorio(false);
  };

  // -------------------------------------STEP ONE---------------------------------------//
  const stepOne = disabled => {
    const fullDisabled = disabled && data?.id !== undefined;

    return (
      <S.StepOneBox>
        <div className="boxText">
          <TextInput
            label="Pergunta"
            placeholder="Pergunta"
            value={pergunta}
            onChange={e => {
              setHasChanged(true);
              setPergunta(e.target.value.slice(0, 250));
            }}
            disabled={fullDisabled}
          />
          <span className="counter">{`${pergunta?.length}/250`}</span>
        </div>

        <div className="boxText">
          <TextInput
            label="Descrição"
            placeholder="Descrição (opicional)"
            value={descricao}
            onChange={e => {
              setHasChanged(true);
              setDescricao(e.target.value.slice(0, 150));
            }}
            disabled={fullDisabled}
          />
          <span className="counter">{`${descricao?.length}/150`}</span>
        </div>

        <S.SelectMoeda>
          <div className="title">Formato da hora</div>
          <div className="boxOptions">
            <span className="item">
              <Radio disabled value />
              <span>24hrs</span>
            </span>
          </div>
        </S.SelectMoeda>
      </S.StepOneBox>
    );
  };

  // -------------------------------------RENDER---------------------------------------//
  return (
    <GlobalModal
      stepOne={stepOne}
      validateStepOne={validateStepOne}
      open={open}
      onClose={onClose}
      resetFields={resetFields}
      totalContent={totalContent}
      hasData={!!data}
      obrigatorio={obrigatorio}
      setObrigatorio={setObrigatorio}
      small="500px"
      currentElement={currentElement}
      section={section}
      hasChanged={hasChanged}
      setHasChanged={setHasChanged}
    />
  );
};
