// Redux
import { useDispatch, useSelector } from 'react-redux';
import { setItems } from 'store/modules/formularios/actions';

// Components
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import InputEdit from '../InputEditable';
import * as S from './styled';

const LineSection = ({ item }) => {
  // Redux
  const dispatch = useDispatch();
  const formularios = useSelector(state => state.formularios);

  const generateNewSection = () => {
    let index = 0;
    const items = [...formularios.secoes];

    for (const i in items) {
      if (items[i].index > index) {
        index = items[i].index;
      }
    }

    items.push({
      index: items.length,
      titulo: `Seção ${items.length}`,
      descricao: '',
      campos: [],
    });

    dispatch(setItems(items));

    const content = document.querySelector('.contentControllScroll');
    setTimeout(() => {
      content.scrollTo({
        top: content.scrollHeight,
        behavior: 'smooth',
      });
    }, 200);
  };

  return (
    <S.Container>
      <div className="bar" />
      {item && <InputEdit type="small" item={item} />}
      {!item && (
        <div className="title" onClick={() => generateNewSection()}>
          <AddCircleOutlineOutlinedIcon />
          <span>Adicionar Seção</span>
        </div>
      )}
      <div className="bar" />
    </S.Container>
  );
};

export default LineSection;
