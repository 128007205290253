import React, { useCallback, useState } from 'react';
import { IconButton } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import {
  Control,
  Container,
  PartnerAvatar,
  PartnerInfo,
  PartnerLogo,
} from './styled';

const img = require(`images/empty/motorista.png`);

const FeedbackCard = ({ data = [], ...props }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const selected = data[selectedIndex] ?? {};

  const feedbackUp = useCallback(() => {
    setSelectedIndex(current => (current + 1 >= data.length ? 0 : current + 1));
  }, [data]);

  const feedbackDown = useCallback(() => {
    setSelectedIndex(current =>
      current - 1 < 0 ? data.length - 1 : current - 1,
    );
  }, [data]);

  return (
    <Container>
      <p>{`"${selected.comment ?? ''}"`}</p>
      <PartnerInfo>
        <PartnerAvatar src={selected.avatarSrc ?? img} />
        <div>
          <h2>{selected.name ?? ''}</h2>
          <p>{selected.title ?? ''}</p>
          {selected.logoSrc && <PartnerLogo src={selected.logoSrc} />}
        </div>
      </PartnerInfo>
      <Control>
        <IconButton onClick={feedbackDown}>
          <ArrowBack />
        </IconButton>
        <IconButton onClick={feedbackUp}>
          <ArrowForward />
        </IconButton>
      </Control>
    </Container>
  );
};

export default FeedbackCard;
