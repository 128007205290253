import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';

// Components
import FormEvaluation from 'components/Form';
import { ModalDesvio } from './ModalDesvio';

// Services
import { steps } from '../constants';
import * as services from '../services';

export const Formulario = ({
  evaluation,
  disabled,
  setCurrentStep,
  setRecharge,
  initialDate,
  finalDate,
}) => {
  const [modalDesvio, setModalDesvio] = useState(false);
  const [infosDesvio, setInfosDesvios] = useState(null);
  const [answers, setAnswers] = useState(null);
  const [sections, setSections] = useState(null);
  const [desvios, setDesvios] = useState(null);
  const [objNone, setObjNone] = useState(null);

  useEffect(() => {
    getAnswers();
  }, []);

  const getAnswers = async () => {
    if (evaluation?.id) {
      const res = await services.requestAnswersEvaluation(evaluation?.id);
      setAnswers(res?.data?.respostas || []);
      setDesvios(res?.data?.desvios || []);

      const types = await services.requestTypesQuestions();
      setSections(types.data);
    }
  };

  const responseQuestions = async (id, answer, oldAnswer) => {
    if (oldAnswer?.resposta === 'NAO') {
      toast.error('Não é possivel alterar perguntas com desvios');
    } else {
      const answerObject = {
        resposta: answer,
        imagem_uri: null,
        objeto: null,
        id_questao: id,
        id_avaliacao: evaluation.id,
      };

      const body = {
        event: 'answerOneQuestion',
        data: { id, answer: answerObject, oldAnswer },
      };

      const res = await services.answerOneQuestion(evaluation?.id, body);
      if (res && !res.data.success) {
        toast.error('Erro ao responder pergunta.');
      } else {
        return res.data;
      }
    }
  };

  const openModal = (id, answer, oldAnswer) => {
    setInfosDesvios(oldAnswer);
    setModalDesvio(true);
    setObjNone({ id, answer, oldAnswer });
  };

  const handlePage = () => {
    setCurrentStep(steps[2]);
  };

  return (
    <div style={{ marginTop: '30px' }}>
      {answers && sections && (
        <FormEvaluation
          sections={sections}
          answers={answers}
          desvios={desvios}
          setAnswers={setAnswers}
          onClickRow={responseQuestions}
          ifNo={openModal}
          clickOutlier={handlePage}
          disabled={disabled}
          forbiden="Avaliação assinada por motorista e/ou avaliador não pode ser modificada"
          isCreate
        />
      )}
      {modalDesvio && infosDesvio && (
        <ModalDesvio
          open={modalDesvio}
          onClose={() => {
            setModalDesvio(false);
            setInfosDesvios(null);
          }}
          infos={infosDesvio}
          evaluation={evaluation}
          setRecharge={setRecharge}
          responseQuestions={responseQuestions}
          obj={objNone}
          initialDate={initialDate}
          finalDate={finalDate}
        />
      )}
    </div>
  );
};
