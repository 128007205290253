// Styled
import { Divider } from '@mui/material';
import Select from 'components/Inputs/Select';
import { useEffect, useState } from 'react';
import * as S from './styled';

// Components
import { OutQuestion } from '..';

// Components MUI

import { changeId } from '../../actions';

export const SelectSimple = ({
  field,
  secoes,
  setSecoes,
  sectionId,
  errosObrigatorios,
  preview,
  previewWeb,
  selects,
}) => {
  const redirectFunction = (executable, idCampo, texto) => {
    setCleanField(true);
    const resp = selects[field.id_tipo_campo].find(item => item.id == texto);
    executable(texto, idCampo, setSecoes, secoes, sectionId, resp.nome);
  };

  const [label, setLabel] = useState('');
  useEffect(() => {
    if (field) {
      switch (field?.id_tipo_campo) {
        case 23:
          setLabel('Selecione uma Operação');
          break;
        case 24:
          setLabel('Selecione um Carregamento');
          break;
        case 27:
          setLabel('Selecione um Departamento');
          break;
        case 28:
          setLabel('Selecione um tipo de Cambio');
          break;
        case 29:
          setLabel('Selecione uma Propriedade');
          break;
        case 30:
          setLabel('Selecione uma Filial');
          break;
        default:
          setLabel('Selecione uma opção');
      }
    }
  }, [field]);

  // ------------------------------------ERROS-------------------------------------------//
  const [hasErrorObrigatorio, setHasErrorObrigatorio] = useState(false);
  const [cleanField, setCleanField] = useState(false);

  useEffect(() => {
    // Reset fields
    setHasErrorObrigatorio(false);
    setCleanField(false);

    if (errosObrigatorios) {
      for (const i in errosObrigatorios) {
        const erros = errosObrigatorios[i];
        if (sectionId === erros.idSecao && field.id === erros.idCampo) {
          setHasErrorObrigatorio(true);
        }
      }
    }
  }, [errosObrigatorios]);

  return (
    <>
      <S.QuestionBox
        hasError={hasErrorObrigatorio && !cleanField}
        className={`fieldClass-${field.id}`}
      >
        <OutQuestion
          field={field}
          secoes={secoes}
          setSecoes={setSecoes}
          sectionId={sectionId}
          hasError={hasErrorObrigatorio && !cleanField}
          preview={preview}
        />
        <S.ResponseArea preview={preview}>
          <Select
            value={field.id_resposta || ''}
            data={
              !preview && !previewWeb
                ? selects[field.id_tipo_campo]?.map(item => ({
                    value: item.id,
                    name: item.nome,
                  }))
                : []
            }
            handleChange={value =>
              redirectFunction(changeId, field.id, value.target.value)
            }
            label={label}
            required={field.obrigatorio}
            error={hasErrorObrigatorio && !cleanField}
          />
        </S.ResponseArea>
      </S.QuestionBox>
      <Divider />
    </>
  );
};
