import { useTheme } from 'styled-components';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import * as S from './styled';

const CardAdd = ({ title, onClick }) => {
  const theme = useTheme();
  return (
    <S.Wrapper onClick={onClick} className="addCard">
      <AddCircleOutlineIcon
        htmlColor={theme.palette.semantics.feedback.unknown.natural}
      />
      <span>{title}</span>
    </S.Wrapper>
  );
};

export default CardAdd;
