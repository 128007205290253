// Styled
import { useTheme } from 'styled-components';

// React
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setItems, setSection } from 'store/modules/formularios/actions';

// Components
import ConfirmModal from 'components/ConfirmModal';

// Material MUI
import { SvgIcon } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { DeleteOutline } from '@mui/icons-material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import * as S from './styled';

// Utils
import { items } from '../../items';

const BoxItem = ({ item, section, referenceDrag, pathname }) => {
  const theme = useTheme();
  // General States
  const [element, setEl] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [componentModal, setComponentModal] = useState(false);
  const [currentElement, setCurrentElement] = useState(null);

  // Redux
  const dispatch = useDispatch();
  const formularios = useSelector(state => state.formularios);

  // --------------------------------COMPONENT CONTROLLER-------------------------------//

  // Monitoring what correct modal component need be visible for user
  const changeComponent = () => {
    if (currentElement) {
      const { Component } = currentElement;

      return (
        <Component
          open={componentModal}
          onClose={() => setComponentModal(false)}
          data={item}
          closeModalItems={() => {}}
          currentElement={currentElement}
          section={section}
        />
      );
    }
  };

  // Get element informations for render modal
  useEffect(() => {
    for (const i in items) {
      const { types } = items[i];
      for (const j in types) {
        if (types[j].id_tipo_campo == item.id_tipo_campo) {
          setEl(types[j]);
        }
      }
    }
  }, []);

  const deleteItem = () => {
    const cloneItems = JSON.parse(JSON.stringify(formularios.secoes));
    for (const i in cloneItems) {
      if (cloneItems[i].index === section) {
        const componentes = cloneItems[i].campos;
        const newComponents = [];
        for (const j in componentes) {
          if (componentes[j].index !== item.index) {
            newComponents.push(componentes[j]);
          }
        }
        cloneItems[i].campos = newComponents;
      }
    }
    dispatch(setItems(cloneItems));
  };

  // Format Question text
  const textFormat = () => {
    if (item.pergunta) {
      return item.pergunta.length > 50
        ? `${item.pergunta.slice(0, 47)}...`
        : item.pergunta;
    }
    return element?.nome;
  };

  return (
    <>
      <S.Container>
        <div
          className="content"
          onClick={() => {
            setCurrentElement(element);
            setComponentModal(true);
          }}
        >
          <span {...referenceDrag}>
            <MenuIcon
              htmlColor={theme.palette.brand.primary.light}
              style={{ cursor: 'move' }}
            />
          </span>
          <SvgIcon
            component={element?.icone}
            htmlColor={theme.palette.brand.primary.light}
            className="mediumIcon"
          />
          <span className="question">{textFormat()}</span>
        </div>

        <div className="delete">
          <DeleteOutlinedIcon
            htmlColor={theme.palette.brand.primary.light}
            onClick={() => setDeleteModal(true)}
          />
        </div>
      </S.Container>
      {componentModal && changeComponent()}

      <ConfirmModal
        open={deleteModal}
        isNegative
        buttonText="Excluir"
        titleIcon={
          <DeleteOutline
            sx={{ color: theme.palette.semantics.feedback.attention.natural }}
            fontSize="medium"
          />
        }
        title="Tem certeza que deseja excluir essa campo?"
        subtitle={
          !pathname
            ? 'Não será possivel desfazer essa ação'
            : 'Todos os dados referentes a ele serão excluídos da plataforma Onisys.'
        }
        onClick={() => deleteItem()}
        handleClose={() => setDeleteModal(false)}
      />
    </>
  );
};

export default BoxItem;
