import styled from 'styled-components';

export const Container = styled.div`
  width: 80%;
  margin: auto;
`;

export const ErrorMessage = styled.span`
  font: normal normal medium 16px/20px Texta;
  color: #f64e60;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: right;
  gap: 1rem;
`;
