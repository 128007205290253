import React from 'react';
import * as S from './styled';
import { Item } from './Item';
import GraficoBarraHorizontal from './Grafico';
import { getHours } from 'date-fns';
import { Skeleton } from '@mui/material';

export const RodoviasPerigosas = ({
  data,
  selectedAddress,
  setSelectedAddress,
  isLoading = false,
}) => {
  const periodos = [
    { hora: 6, name: 'Manhã', value: 0 },
    { hora: 12, name: 'Tarde', value: 0 },
    { hora: 18, name: 'Noite', value: 0 },
    { hora: 0, name: 'Madrug.', value: 0 },
  ];

  const dataComEndereco = data.filter(item => item.endereco);

  const rodovias = dataComEndereco.reduce((acc, curr) => {
    const periodo = periodos.find(
      i => i.hora <= getHours(new Date(curr.data_desvio)),
    );
    if (periodo) periodo.value += 1;

    const item = acc.find(i => i.endereco === curr.endereco);
    if (item) item.value += 1;
    else acc.push({ endereco: curr.endereco, value: 1 });
    return acc;
  }, []);

  rodovias.sort((a, b) => b.value - a.value);

  return (
    <S.Container>
      <S.Title>Rodovias mais perigosas</S.Title>
      <S.GraphContainer>
        {isLoading ? (
          <Skeleton
            width="100%"
            height="60px"
            variant="rounded"
            animation="wave"
          />
        ) : rodovias[0] ? (
          <Item
            first
            index={1}
            disabled={
              selectedAddress && rodovias[0]?.endereco !== selectedAddress
            }
            selected={
              selectedAddress && rodovias[0]?.endereco === selectedAddress
            }
            text={rodovias[0]?.endereco || ''}
            infractions={rodovias[0]?.value}
            onClick={() =>
              setSelectedAddress(selected =>
                selected !== rodovias[0].endereco ? rodovias[0].endereco : null,
              )
            }
          />
        ) : (
          <span>Nenhum item encontrado.</span>
        )}
        <GraficoBarraHorizontal data={periodos} />
      </S.GraphContainer>
      <S.ContainerList>
        {isLoading
          ? [1, 2, 3, 4].map((item, index) => (
              <Skeleton key={item?.endereco} width="100%" animation="wave">
                <Item index={index + 2} text="" />
              </Skeleton>
            ))
          : rodovias
              .slice(1, 4)
              .map((item, index) => (
                <Item
                  disabled={
                    selectedAddress && item?.endereco !== selectedAddress
                  }
                  selected={
                    selectedAddress && item?.endereco === selectedAddress
                  }
                  index={index + 2}
                  key={item?.endereco}
                  text={item?.endereco || ''}
                  infractions={item?.value}
                  onClick={() =>
                    setSelectedAddress(selected =>
                      selected !== item.endereco ? item.endereco : null,
                    )
                  }
                />
              ))}
      </S.ContainerList>
    </S.Container>
  );
};
