import React, { useState, useCallback, useEffect } from 'react';
import {
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Sector,
} from 'recharts';

import Skeleton from '@mui/material/Skeleton';
import { simplifyNumber } from 'utils/helpers';

const PieGraph = ({ data, tooltip, loading = false }) => {
  const [outerRadius, setOuterRadius] = useState(0);
  const [innerRadius, setInnerRadius] = useState(0);
  const [dimensions, setDimensions] = useState({ cx: '50%', cy: 0 });

  const mockData = [
    {
      id: 'status_finalizado',
      name: 'Desvios finalizados',
      value: 333,
      color: '#ccc',
    },
    {
      id: 'status_pendente',
      name: 'Movidos para pendentes',
      value: 332,
      color: '#ccc',
    },
    {
      id: 'sol_exclusao',
      name: 'Solicitações de exclusão',
      value: 21233,
      color: '#ccc',
    },
    {
      id: 'status_pendente_pos',
      name: 'Movidos para pendentes após vencimento',
      value: 11,
      color: '#ccc',
    },
    {
      id: 'excluidos',
      name: 'Excluídos',
      value: 1992,
      color: '#ccc',
    },
  ];
  const total = data.reduce((acc, entry) => acc + entry.value, 0);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;

      if (screenWidth < 768) {
        setDimensions({ cx: '50%', cy: 80 });
        setOuterRadius(55);
        setInnerRadius(40);
      } else if (screenWidth >= 768 && screenWidth < 1280) {
        setDimensions({ cx: '50%', cy: 100 });
        setOuterRadius(75);
        setInnerRadius(60);
      } else {
        setDimensions({ cx: '50%', cy: 150 });
        setOuterRadius(100);
        setInnerRadius(80);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [activeIndex, setActiveIndex] = useState(0);
  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex],
  );

  const renderActiveShape = props => {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } =
      props;

    return (
      <g>
        <text
          x={cx}
          y={cy + 20}
          dy={8}
          textAnchor="middle"
          fill="#4B5166"
          fontSize={16}
        >
          Total
        </text>
        <text
          x={cx}
          y={cy}
          dy={8}
          textAnchor="middle"
          fill="#4B5166"
          fontSize={32}
          fontFamily="texta"
          fontWeight={900}
        >
          {simplifyNumber(total)}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
      </g>
    );
  };

  const renderLabel = ({ cx, cy, midAngle, outerRadius, value }) => {
    const RADIAN = Math.PI / 180;
    const radius = outerRadius + 20;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="black"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
        style={{ fontSize: window.innerWidth < 768 ? '12px' : '14px' }}
      >
        {simplifyNumber(value)}
      </text>
    );
  };

  useEffect(() => {
    if (data.length === 0) {
      data = mockData;
    }
  }, [data]);

  return (
    <ResponsiveContainer width={300} height={dimensions.cy * 2}>
      {!loading ? (
        <PieChart>
          {tooltip && <Tooltip />}

          <Pie
            data={data}
            cx={dimensions.cx}
            cy={dimensions.cy}
            activeIndex={activeIndex}
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            paddingAngle={4}
            activeShape={renderActiveShape}
            dataKey="value"
            startAngle={0}
            endAngle={360}
            label={renderLabel}
            labelLine
            onMouseEnter={onPieEnter}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
        </PieChart>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            marginTop: '40px',
          }}
        >
          <Skeleton variant="circular" width={160} height={160} />
        </div>
      )}
    </ResponsiveContainer>
  );
};

export default PieGraph;
