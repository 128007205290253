import styled, { css } from 'styled-components';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ListItemText from '@mui/material/ListItemText';

export const Main = styled.div`
  ${({ theme }) => css`
    position: absolute;
    width: 750px;
    min-height: 300px;
    background-color: ${theme.palette.system.overlay};
    border-radius: 4px;
    padding: 25px 35px;
    margin: 0 auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 550px;
    overflow-y: scroll;
  `}
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    font-size: 19px;
    color: ${theme.palette.brand.primary.dark};
    font-weight: 900;
    letter-spacing: 0px;
  `}
`;

export const CloseButton = styled(Button)`
  min-width: 15px !important;
  max-width: 15px !important;
  padding: 0px !important;
  border-radius: 50%;

  .MuiButton-startIcon {
    padding: 0px !important;
    margin: 0px !important;
  }
`;

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    margin-top: 30px;
    align: center;
    background-color: ${theme.palette.system.overlay};
  `}
`;

export const Item = styled.div`
  ${({ theme }) => css`
    height: 50px;
    width: 100%;
    background-color: ${theme.palette.system.divider};
    display: flex;
  `}
`;

export const LinkCae = styled.a``;

export const TextInput = styled.div`
  ${({ theme }) => css`
    text-align: left;
    font: normal normal 900 12px/16px Texta;
    letter-spacing: 0px;
    color: ${theme.palette.words.text.natural};
    text-transform: uppercase;
    opacity: 1;
    margin-top: 20px;
    display: flex;
    width: 100%;
    align-items: center;
  `}
`;

export const StyledList = styled(ListItemText)`
  span {
    font-size: 12px;
    font-weight: 400;
  }
`;

export const StyledHeaderWrapper = styled(Grid)`
  padding: 10px;
`;

export const StyledRowWrapper = styled(Grid)`
  ${({ theme }) => css`
    padding: 10px;
    cursor: pointer;

    &:hover {
      background-color: ${theme.palette.brand.primary.background};
    }
  `}
`;
