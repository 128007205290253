export const columnsSmall = [
  {
    Header: 'Empresa',
    id: 'empresa',
    identifier: 'empresa',
    accessor: d => String(d.nome),
    sortType: 'basic',
    show: true,
    disableSortBy: false,
  },
  {
    Header: 'Concluidos (%)',
    id: 'concluido',
    identifier: 'concluido',
    accessor: d => Number(d.percentagem_conclusao),
    sortType: 'basic',
    show: true,
    disableSortBy: false,
    centered: true,
  },
  {
    Header: 'Aprovados (%)',
    id: 'aprovados',
    identifier: 'aprovados',
    accessor: d => Number(d.percentagem_aprovados),
    sortType: 'basic',
    show: true,
    disableSortBy: false,
    centered: true,
  },
  {
    Header: 'Alunos',
    id: 'alunos',
    identifier: 'alunos',
    accessor: d => Number(d.alunos),
    sortType: 'basic',
    show: true,
    disableSortBy: true,
    centered: true,
  },
  {
    Header: 'Respostas',
    id: 'respostas',
    identifier: 'respostas',
    accessor: d => Number(d.respostas),
    sortType: 'basic',
    show: true,
    disableSortBy: true,
    centered: true,
  },
  {
    Header: 'Reprovados',
    id: 'reprovados',
    identifier: 'reprovados',
    accessor: d => Number(d.reprovados),
    sortType: 'basic',
    show: true,
    disableSortBy: false,
    centered: true,
  },
];
