import * as S from './styled';
import { Loading } from '../Loading';

export const Photo = ({ image, letter, loading }) => {
  return (
    <S.Container data-testid="picture">
      <Loading isLoading={loading} data-testid="loading" />
      <S.Circle>
        {image ? <img src={image} alt="" /> : <p>{letter}</p>}
      </S.Circle>
    </S.Container>
  );
};
