import api from 'services/api';

const requestInfractions = async query => {
  const res = await api.get('/provider/desvio-direcao', { params: query });
  return res;
};

const fetchInfraction = async id => {
  const res = await api.get(`/provider/desvio-direcao/${id}`);
  return res;
};

const requestHistory = async id => {
  const res = await api.get(`/provider/desvio-direcao-logs/${id}`);
  return res;
};

const requestCards = async query => {
  const res = await api.get('/provider/desvio-direcao-cards', {
    params: query,
  });
  return res;
};

const requestExcel = async query => {
  const res = await api.get('/excel-provider/infractions', { params: query });
  return res;
};

const toggleOperation = async data => {
  const res = await api.put('/provider/exclusao-update', data);
  return res;
};

export {
  requestInfractions,
  fetchInfraction,
  requestCards,
  requestExcel,
  requestHistory,
  toggleOperation,
};
