import React, { useEffect } from 'react';
import * as eva from 'eva-icons';
import { useTheme } from 'styled-components';
import * as S from './styled';
// import CustomerServiceIcon from 'images/customer_service.svg'
// import GuideBookIcon from 'images/guide-book.svg'
// import ChatQuestionIcon from 'images/chat-help-svgrepo-com.svg'
// import DriverLicenseIcon from 'images/driver-license.svg'
// import LikeIcon from 'images/like.svg'
// import DislikeIcon from 'images/unlike.svg'

const Icons = ({
  name,
  home,
  animation,
  hover,
  infinite,
  size,
  color,
  ...props
}) => {
  const theme = useTheme();
  useEffect(() => {
    eva.replace();
  }, []);

  switch (name) {
    // case 'driver-icon':
    //   return <S.DriverIconStyled
    //     color={color}
    //     width={size}
    //     height={size}
    //     name={name}
    //   />

    // case 'teach-icon':
    //   return <S.TeachStyled
    //     color={color}
    //     width={size}
    //     height={size}
    //     name={name}
    //   />

    // case 'customer-service':
    //   return (<img alt={CustomerServiceIcon} src={CustomerServiceIcon} width='25px' height='25px' />);

    // case 'guide-book':
    //   return (<img alt={GuideBookIcon} src={GuideBookIcon} width='25px' height='25px' />);

    // case 'question-chat':
    //   return (<img alt={ChatQuestionIcon} src={ChatQuestionIcon} width='25px' height='25px' />);

    // case 'driver-license':
    //   return (<img alt={DriverLicenseIcon} src={DriverLicenseIcon} width='25px' height='25px' />)

    // case 'like':
    //   return (<img alt='like' src={LikeIcon} width={size} height={size} />)

    // case 'dislike':
    //   return (<img alt='dislike' src={DislikeIcon} width={size} height={size} />)

    default:
      if (!home) {
        return (
          <i
            data-eva={name}
            data-eva-animation={animation}
            data-eva-hover={hover}
            data-eva-infinite={infinite}
            data-eva-height={size || 24}
            data-eva-width={size || 24}
            data-eva-fill={
              color || theme.palette.semantics.feedback.unknown.natural
            }
            {...props}
          />
        );
      }
      return (
        <span>
          <i
            data-eva={name}
            data-eva-animation={animation}
            data-eva-hover={hover}
            data-eva-infinite={infinite}
            data-eva-height={size || 24}
            data-eva-width={size || 24}
            data-eva-fill={
              color || theme.palette.semantics.feedback.unknown.natural
            }
            {...props}
          />
        </span>
      );
  }
};

export default Icons;
