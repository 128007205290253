/* eslint-disable import/order */

// Styles
import * as S from './styled';
import colors from 'styles/colors';

// Material UI
import { Icon, IconButton } from '@mui/material';
import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

const ModalAdd = ({
  open,
  onClose,
  title,
  description,
  textLeft,
  textRight,
  actionLeft,
  actionRight,
}) => {
  return (
    <S.ModalArea open={open} onClose={onClose} disableEscapeKeyDown={false}>
      <S.Container>
        <S.Header>
          <div style={{ alignItems: 'center' }}>
            <FormatListBulletedIcon htmlColor={colors.greenMain} />
            <h2>{title}</h2>
          </div>

          <IconButton size="small" onClick={onClose}>
            <Icon sx={{ color: colors.greySubtitle }}>close</Icon>
          </IconButton>
        </S.Header>

        <S.Description> {description}</S.Description>

        <S.Body>
          <S.Box
            onClick={() => {
              actionLeft();
              onClose();
            }}
          >
            <AddCircleOutlineOutlinedIcon htmlColor={colors.grey} />
            <span>{textLeft}</span>
          </S.Box>
          <S.Box
            onClick={() => {
              actionRight();
              onClose();
            }}
          >
            <BookOutlinedIcon htmlColor={colors.grey} />
            <span>{textRight}</span>
          </S.Box>
        </S.Body>
      </S.Container>
    </S.ModalArea>
  );
};

export default ModalAdd;
