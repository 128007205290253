// Styleds
import { useTheme } from 'styled-components';

// Utils
import { formatNewHour } from 'utils/dates';

// React
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// Components MUI
import { Box } from '@mui/system';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { CircularProgress, Grid, Menu, MenuItem } from '@mui/material';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';

// Components
import Table from 'components/Table';
import Loading from 'components/Loading';
import ModalSign from '../../components/ModalSign';
import Filters from '../../Config/Reports/Filters';

// Services
import * as services from './services';

// Columns
import { columnsTotal } from './columns';

import * as S from './styled';

let timerRoute = null;

const HistoryUser = ({ select, currentSelect, setCurrentSelect }) => {
  const theme = useTheme();
  // Redux
  const filters = useSelector(state => {
    return state.filterFormulario;
  });

  const { id } = useParams();

  // Data States
  const [cards, setCards] = useState([]);
  const [count, setCount] = useState(0);
  const [dataTable, setDataTable] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  // Controllers States
  const [query, setQuery] = useState({});
  const [firstFetch, setFirstFetch] = useState(true);
  const [selectedForms, setSelectedForms] = useState(null);
  const [anchorDistributor, setAnchorDistributor] = useState(false);
  const [formHist, setFormHist] = useState(null);
  const [modalView, setModalView] = useState(false);
  const [loadingLine, setLoadingLine] = useState(null);
  const [currentHist, setCurrentHist] = useState(null);

  const openFilter = Boolean(anchorEl);

  const idFilter = openFilter ? 'simple-popover' : undefined;

  // ---------------------------------------REQUESTS---------------------------------------//

  // -------------- Infos Tbale
  const { refetch, isFetching: loading } = useQuery(
    [`/form-history-table-${id}`, 1],
    () =>
      services.getInfosByPeriod(id, {
        periodo: currentSelect,
        id,
        ...query,
      }),
    {
      refetchOnWindowFocus: false,
      onSuccess: data => updateInfos(data),
      onError: data => toast.error(data.message),
    },
  );

  const updateInfos = data => {
    setCards(data.cards);
    setDataTable(data.data);
    setCount(data.count);
    setFirstFetch(false);
  };

  useEffect(() => {
    if (!firstFetch) {
      refetch();
    }
  }, [currentSelect, query]);

  const updateQuery = qs => {
    setQuery({
      ...query,
      ...qs,
      reportsFilters: filters.reportsFilters.map(item => {
        return item.id;
      }),
    });
  };

  // -------------------------------------------------FORMAT TABLE--------------------------------------------------//
  const formatTable = arr => {
    arr.forEach(i => {
      if (i.identifier === 'nome') {
        i.Cell = function ({ row }) {
          return (
            <S.ColDriver>
              <S.Paragraph className="normal name">
                {row.original.aluno.nome.toLowerCase()}
              </S.Paragraph>
              <S.Paragraph className="small">
                Filial: {row.original.aluno.filial.nome}
              </S.Paragraph>
            </S.ColDriver>
          );
        };
      } else if (i.identifier === 'status') {
        i.Cell = function ({ row }) {
          return (
            <S.ColDriver>
              {row.original.concluido && (
                <>
                  <S.Paragraph className="green">Concluído</S.Paragraph>
                  <S.Paragraph className="small" center>
                    Concluído: {formatNewHour(row.original.data_concluido)}
                  </S.Paragraph>
                </>
              )}
              {!row.original.concluido && (
                <>
                  <S.Paragraph className="red">Não Concluído</S.Paragraph>
                  <S.Paragraph className="small" center>
                    {`Iniciado: ${formatNewHour(row.original.createdAt)}`}
                  </S.Paragraph>
                </>
              )}
            </S.ColDriver>
          );
        };
      } else if (i.identifier === 'ocorrencias') {
        i.Cell = function ({ row }) {
          return (
            <S.ColDriver>
              <S.Paragraph className="normal" center>
                {row.original.ocorrencias.total}
              </S.Paragraph>
            </S.ColDriver>
          );
        };
      } else if (i.identifier === 'contador') {
        i.Cell = function ({ row }) {
          return (
            <S.ColDriver>
              <S.Paragraph className="normal" center>
                {row.original.contador}
              </S.Paragraph>
            </S.ColDriver>
          );
        };
      } else if (i.identifier === 'aprovado') {
        i.Cell = function ({ row }) {
          return (
            <S.ColDriver>
              <S.Paragraph
                className={row.original.data_assinatura ? 'green' : 'orange'}
              >
                {formatNewHour(row.original.data_assinatura) || 'Não aprovado'}
              </S.Paragraph>
            </S.ColDriver>
          );
        };
      } else if (i.identifier === 'acoes') {
        i.Cell = function ({ row }) {
          return (
            <S.IconContainer
              id={row.original.id}
              key={row.original.id}
              onClick={handleOpenDistributorMenu}
            >
              <MoreHorizIcon cursor="pointer" fontSize="large" />
            </S.IconContainer>
          );
        };
      }
    });
    return arr;
  };

  const formatTypeColumns = () => {
    return columnsTotal;
  };

  const handleOpenDistributorMenu = e => {
    setAnchorDistributor(e.currentTarget);
  };

  const renderActionMenu = () => {
    let currentOptions = [];

    let tabsFuncs = {
      options: ['visualizar', 'assinar'],
      selected: ['assinar'],
    };

    if (selectedForms?.length > 0) {
      currentOptions = tabsFuncs.selected;
    } else {
      currentOptions = tabsFuncs.options;
    }

    return (
      <Menu
        open={!!anchorDistributor}
        anchorEl={anchorDistributor}
        onClose={() => setAnchorDistributor(null)}
        onClick={() => setAnchorDistributor(null)}
      >
        <Grid container style={{ display: 'flex', flexDirection: 'column' }}>
          {currentOptions.includes('visualizar') && (
            <MenuItem
              key="visualizar"
              onClick={() => getCurrentResponses(anchorDistributor.id)}
            >
              <Grid item md={9}>
                <S.PTable>Ver respostas</S.PTable>
              </Grid>
            </MenuItem>
          )}
        </Grid>
      </Menu>
    );
  };

  // --------------------------------------------------------------------------------------------------------------//

  // Controll back arrow in window when modal is opened in same tab (BETA VERSION)
  useEffect(() => {
    if (!modalView) {
      timerRoute = setTimeout(() => {
        window.onpopstate = event => {
          event.preventDefault();
        };
      }, 100);
    }

    return function cleanup() {
      clearTimeout(timerRoute);
    };
  }, [modalView]);

  // ------------------------------------------------ MODAL VIEWR CONTROLL----------------------------------------//
  const getCurrentResponses = async idHist => {
    let currentItem = dataTable.find(item => +item.id === +idHist);
    let alunoId = currentItem.aluno.id;
    let alunoTipo = currentItem.motorista ? 'MOTORISTA' : 'GESTOR';

    if (!idHist) {
      toast.error(`Sem respostas disponíveis para esse aluno`);
      return null;
    }

    setLoadingLine(true);
    setCurrentHist(+idHist);
    let res = await services.getFormResponse(id, idHist, alunoId, alunoTipo);
    if (res.success) {
      setFormHist(res);
      setModalView(true);
    } else {
      toast.error(res.message);
    }
    setLoadingLine(false);
  };

  const calcPercentageCircle = cds => {
    let total = cds[0]?.value + cds[2]?.value || 0;
    let percent = (cds[0]?.value * 100) / total || 0;
    return Math.round(percent);
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  // ---------------------------------------------RENDER-------------------------------------------//
  return (
    <>
      <S.Container>
        <S.BoxInfos>
          {!loading && (
            <>
              <S.CircleBar>
                <Box
                  sx={{
                    position: 'relative',
                    display: 'inline-flex',
                    transform: 'scale(2)',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <CircularProgress
                    variant="determinate"
                    value={calcPercentageCircle(cards)}
                  />
                  <Box
                    sx={{
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      position: 'absolute',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <FactCheckOutlinedIcon
                      htmlColor={theme.palette.brand.secondary.natural}
                      style={{ transform: 'scale(0.7)' }}
                    />
                  </Box>
                </Box>
                <div className="textFields">
                  <div className="text">{cards[0]?.text}</div>
                  <div className="value">
                    {cards[0]?.value}/
                    {cards[0]?.value + cards[1]?.value + cards[2]?.value}
                  </div>
                </div>
              </S.CircleBar>
              <div className="bar" />
              <S.Card>
                <div className="text">{cards[1]?.text}</div>
                <div className="value">{cards[1]?.value}</div>
              </S.Card>

              <>
                <div className="bar" />
                <S.Card>
                  <div className="text">{cards[2]?.text}</div>
                  <div className="value">{cards[2]?.value}</div>
                </S.Card>
              </>
            </>
          )}
          {loading && (
            <S.BoxLoading>
              <Loading />
            </S.BoxLoading>
          )}
        </S.BoxInfos>
        <S.TableArea>
          <div className="visualButton">
            <span style={{ marginRight: 15 }}>
              <IconButton
                color="error"
                aria-describedby={idFilter}
                onClick={handleClick}
              >
                <Badge
                  color="error"
                  badgeContent={filters.reportsFilters.length}
                >
                  <FilterAltOutlinedIcon color="disabled" />
                </Badge>
              </IconButton>
              <label
                style={{
                  color: theme.palette.semantics.feedback.unknown.natural,
                }}
                onClick={handleClick}
              >
                Filtros
              </label>
            </span>

            <Filters
              id={idFilter}
              open={openFilter}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              isHistory
            />
          </div>

          <Table
            inputLabel="Buscar por Todos"
            data={dataTable}
            columns={formatTable(formatTypeColumns())}
            setSelectedData={setSelectedForms}
            pageCount={count}
            loading={loading || loadingLine}
            fetchData={qs => updateQuery(qs)}
            onClickRow={id => getCurrentResponses(id)}
            updated={false}
            query={{}}
            initialSortBy={[{ id: 'id', desc: false }]}
            permitIsSortedOccur
            invisibleSelect
            tableType
            empty={{
              title: 'Ops! Você não tem nenhuma resposta para esse formulário',
              subtitle: 'Verifique se o formulário já foi respondido',
            }}
            bulkOptions={[]}
          />
        </S.TableArea>
        {renderActionMenu()}
      </S.Container>
      {formHist && modalView && (
        <ModalSign
          open={modalView}
          onClose={() => {
            setModalView(false);
            setFormHist(false);
            setCurrentHist(null);
          }}
          form={formHist}
          histId={currentHist}
          refetch={getCurrentResponses}
          loading={loadingLine}
          isHistory
          backPath={`/fomularios/historicos/${id}`}
          histories={dataTable}
        />
      )}
    </>
  );
};

export default HistoryUser;
