import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { useTheme } from 'styled-components';
import { useNavigate } from 'react-router-dom';

import {
  Close,
  FilterAltOutlined,
  AddCircleOutline,
  SaveAlt,
  InfoOutlined as Info,
} from '@mui/icons-material';
import Button from 'components/Buttons/Default';
import ButtonGhost from 'components/Buttons/Ghost';
import EmptyCard from 'components/Cards/EmptyDataCard';
import Loading from 'components/Loading';
import ExportToExcel from 'utils/exportToCvs';
import ExcelModal from 'components/ExcelModal';
import {
  MapInfoButton,
  MapInfoContent,
  MapInfoWrapper,
} from 'components/GoogleMap/styled';
import { GoogleMap } from 'components/GoogleMap';
import PlacesAutocomplete from 'components/PlacesAutocomplete';

import { ReactComponent as file } from 'images/icons/sidebar/file.svg';
import { toast } from 'react-toastify';
import { formatNameDate } from 'utils/dates';
import * as services from '../services';
import * as S from './styled';
import { fields, resetExcelFields } from './constants';
import DetalheCercaModal from './DetalheCercaModal';
import TableFilters from '../components/TableFilters';
import { FilterTable } from 'components/_Table/templates/filter';

let lineSymbol = {
  path: 'M 0,-1 0,1',
  strokeOpacity: 1,
  scale: 4,
};

const sortBy = [
  {
    id: 'status',
    desc: true,
  },
];

const ConfigCercaEletronica = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  // EXCEL STATES
  const [excelFields, setExcelFields] = useState(fields);
  const [loadingExcel, setLoadingExcel] = useState(false);
  const [openExcelModal, setOpenExcelModal] = useState(false);

  // MAP STATES
  const [map, setMap] = useState(null);
  const [filters, setFilters] = useState({
    cliente: '',
    tipoVeiculo: '',
  });
  const [anchorEl, setAnchorEl] = useState(null);

  const [showMap, setShowMap] = useState(false);
  const [markerRefs, setMarkerRefs] = useState([]);
  const [polyRefs, setPolyRefs] = useState([]);
  const [circleRefs, setCircleRefs] = useState([]);
  const [mapInfo, setMapInfo] = useState({
    open: true,
    content:
      'Busque a localização da cerca ou navegue no mapa aplicando o zoom',
  });
  const [cercaModal, setCercaModal] = useState({
    open: false,
    data: null,
  });

  const selects = useSelector(state => state.selects);
  const { user } = useSelector(state => state.auth);
  const isProvider = user?.user?.provider;

  // FILTER OPTIONS
  const { data: _tiposVeiculos = [] } = useQuery(
    ['tipos-veiculos-telemetria'],
    () => services.getTiposVeiculos(),
    { staleTime: Infinity },
  );

  const tiposVeiculos = _tiposVeiculos.map(tipo => ({
    label: tipo.tipo,
    value: tipo.id,
  }));

  // FEFTCH DATA

  const {
    data = [],
    isLoading,
    refetch,
  } = useQuery(
    ['cercas-eletronicas'],
    () => services.getDesvios({ categoria: ['CERCA'] }),
    {
      refetchOnWindowFocus: false,
    },
  );

  // Adiciona os marcadores no mapa
  useEffect(() => {
    if (!map || !data) return;

    markerRefs.forEach(marker => {
      window.google.maps.event.removeListener(marker.clickListener);
      marker.ref.setMap(null);
    });
    circleRefs.forEach(ref => {
      ref.setMap(null);
    });
    polyRefs.forEach(ref => {
      ref.setMap(null);
    });

    applyFilters(data)
      .filter(item => item.ativo)
      .map(item => {
        const rotas = item.cerca?.rotas
          ?.filter(i => !i.is_marcador)
          .sort((a, b) => a.pos - b.pos);

        const markers = item.cerca?.raio
          ? [
              {
                lat: parseFloat(item.cerca.latitude),
                lng: parseFloat(item.cerca.longitude),
              },
            ]
          : [
              {
                lat: parseFloat(rotas[0]?.latitude),
                lng: parseFloat(rotas[0]?.longitude),
              },
              {
                lat: parseFloat(rotas[rotas.length - 1]?.latitude),
                lng: parseFloat(rotas[rotas.length - 1]?.longitude),
              },
            ];
        const path = rotas.map(item => ({
          lat: parseFloat(item.latitude),
          lng: parseFloat(item.longitude),
        }));

        markers?.forEach(position => {
          // Add marker
          const markerRef = new window.google.maps.Marker({
            map,
            position,
          });

          // Add marker click Listner
          const markerClickListener = google.maps.event.addListener(
            markerRef,
            'click',
            () => setCercaModal({ open: true, data: item }),
            { once: true },
          );

          setMarkerRefs(_markerRefs => [
            ..._markerRefs,
            {
              ref: markerRef,
              clickListener: markerClickListener,
            },
          ]);
        });

        // Add radius overlay
        if (item.cerca?.raio) {
          const circleRef = new window.google.maps.Circle({
            map,
            strokeColor: theme.palette.brand.primary.dark,
            strokeOpacity: 0.8,
            strokeWeight: 1,
            fillColor: theme.palette.brand.primary.natural,
            fillOpacity: 0.35,
            center: markers[0],
            radius: item.cerca.raio,
          });
          setCircleRefs(_circleRefs => [..._circleRefs, circleRef]);
        }

        // Add polyline between markers
        if (markers?.length === 2) {
          const polyRef = new window.google.maps.Polyline({
            map,
            path,
            geodesic: true,
            strokeColor: theme.palette.brand.primary.dark,
            strokeOpacity: 0,
            icons: [
              {
                icon: lineSymbol,
                offset: '0',
                repeat: '20px',
              },
            ],
          });
          setPolyRefs(_polyRefs => [..._polyRefs, polyRef]);
        }

        return () => {
          markerRefs.forEach(marker => {
            window.google.maps.event.removeListener(marker.clickListener);
            marker.ref.setMap(null);
          });
          circleRefs.forEach(ref => {
            ref.setMap(null);
          });
          polyRefs.forEach(ref => {
            ref.setMap(null);
          });
        };
      });
  }, [map, data, filters]);

  const applyFilters = data => {
    let res = [...data];
    if (filters.cliente)
      res = res.filter(item => item.id_distribuidora == filters.cliente);
    if (filters.tipoVeiculo)
      res = res.filter(item => item.id_tipo_veiculo == filters.tipoVeiculo);

    return res;
  };

  // Excel Fields
  const handleRequestExcel = async () => {
    setLoadingExcel(true);

    const newFields = excelFields.filter(
      item =>
        item.selected === true && (!isProvider || item.label !== 'Cliente'),
    );
    const formatedDate = formatNameDate(new Date());
    const { filters } = JSON.parse(
      localStorage.getItem('table-telemetria-cerca'),
    );

    const newQuery = {
      id_distribuidora:
        filters.find(filter => filter.key === 'cliente')?.value ?? null,
      id_tipo_veiculo:
        filters.find(filter => filter.key === 'veiculo')?.value ?? null,
      excelFields: newFields,
      categoria: 'CERCA',
    };

    const res = await services.requestExcel(newQuery, isProvider);
    if (res.data && res.data?.data?.excel)
      ExportToExcel({
        excel: res.data.data.excel,
        name: `telemetria_cercas_eletronicas${formatedDate}`,
      });
    else if (res.data.message) toast.error(res.data?.message);

    setExcelFields(resetExcelFields(fields));
    setLoadingExcel(false);
    setOpenExcelModal(false);
  };

  // HANDLERS
  const handleAtivar = async (id, row) => {
    let cerca = null;
    if (row) {
      cerca = row;
    } else {
      cerca = data.find(item => String(item.id) === String(id));
    }
    if (!cerca.id_empresa && !isProvider)
      return toast.warning('Essa cerca foi criada pelo cliente');

    if (cerca.ativo) return toast.success('Essa cerca já está ativa');

    const clientesAtivos = selects.clients
      .filter(i => i.status == 'ATIVO')
      .map(i => i.id);
    if (clientesAtivos.includes(cerca.id_distribuidora))
      return toast.warning(
        'Este cliente está ATIVO! Sua parametrização deve ser definida por ele.',
      );

    cerca.ativo = true;
    const res = await services.activateDesvio(cerca.id);

    if (res.success) {
      refetch();
      toast.success(res.message);
    } else toast.error(res.message);
  };

  const handleDesativar = async (id, row) => {
    let cerca = null;
    if (row) {
      cerca = row;
    } else {
      cerca = data.find(item => String(item.id) === String(id));
    }

    if (!cerca.id_empresa && !isProvider)
      return toast.warning('Essa cerca foi criada pelo cliente');

    if (!cerca.ativo) return toast.success('Essa cerca já está inativa');

    cerca.ativo = false;

    const res = await services.desactivateDesvio(cerca.id);
    if (res.success) {
      refetch();
      toast.success(res.message);
    } else toast.error(res.message);
  };

  const handleEditar = id => {
    navigate(`/telemetria/configuracoes/cerca-eletronica/${id}`);
  };

  // TABLE CONFIGS
  const actions = [
    {
      title: 'Ativar',
      function: handleAtivar,
    },
    {
      title: 'Desativar',
      function: handleDesativar,
    },
    {
      title: 'Editar',
      function: handleEditar,
    },
    {
      title: 'Ver Histórico',
      function: id =>
        navigate(`/logs/telemetria/configuracoes/cerca-eletronica/${id}`),
    },
  ];

  const columns = [
    {
      header: 'Status',
      id: 'ativo',
      type: 'boolean',
      sort: true,
      switch: {
        value: (_, item) => item.ativo,
        onChange: (value, item) =>
          value ? handleAtivar(null, item) : handleDesativar(null, item),
      },
      width: 105,
    },
    {
      header: 'Titulo',
      id: 'titulo',
      type: 'string',
      sort: true,
    },
    {
      header: 'Cliente',
      id: 'cliente',
      type: 'string',
      sort: true,
      attention: (_, item) => {
        if (!item.id_empresa && !isProvider)
          return {
            text: 'Desvio configurado pelo cliente',
          };
      },
      width: 150,
    },
    {
      header: 'Tipo de veículo',
      id: 'tipoVeiculo',
      type: 'string',
      sort: true,
    },
    {
      header: 'Condições',
      id: 'condicoes',
      type: 'string',
      value: (_, item) => {
        if (
          !item.carregado &&
          item.carregado !== false &&
          !item.pista_molhada &&
          item.pista_molhada !== false
        )
          return 'Todos';
        let complemento = '';
        if (item.carregado === true) complemento += 'Veículo Carregado\n';
        if (item.carregado === false) complemento += 'Veículo Vazio\n';
        if (item.pista_molhada === true) complemento += 'Pista Molhada\n';
        if (item.pista_molhada === false) complemento += 'Pista Seca\n';
        return complemento;
      },
    },
    {
      header: 'Total Desvios',
      id: 'violacoes.length',
      type: 'number',
      sort: true,
      align: 'center',
    },
  ];

  let tableFilters = [
    {
      label: 'Filtro por Veículo',
      placeholder: 'Selecione o tipo de veículo',
      data: tiposVeiculos,
      mode: 'single',
      key: 'veiculo',
      keysToSearch: ['id_tipo_veiculo'],
      value: filters.tipoVeiculo,
    },
  ];
  if (!isProvider)
    tableFilters.unshift({
      label: 'Filtro por Cliente',
      placeholder: 'Selecione o cliente',
      data: selects.clients.map(i => ({ label: i.nome, value: i.id })),
      mode: 'single',
      key: 'cliente',
      keysToSearch: ['id_distribuidora'],
      value: filters.tipoVeiculo,
    });

  useEffect(() => {
    function getTableFilter(e) {
      const tableFilter = localStorage.getItem('table-telemetria-cerca');
      if (tableFilter) {
        const local = JSON.parse(tableFilter);
        if (local.filters.some(item => item.key === 'veiculo')) {
          const value =
            local.filters.find(item => item.key === 'veiculo').value ?? '';
          setFilters(state => ({
            ...state,
            tipoVeiculo: value,
          }));
        }
        if (local.filters.some(item => item.key === 'cliente')) {
          const value =
            local.filters.find(item => item.key === 'cliente').value ?? '';
          setFilters(state => ({
            ...state,
            cliente: value,
          }));
        }
      }
    }

    window.addEventListener('storage', getTableFilter);

    return () => {
      window.removeEventListener('storage', getTableFilter);
    };
  }, []);

  const headerActions = (
    <div style={{ display: 'flex', gap: 10 }}>
      <ButtonGhost
        startIcon={<SaveAlt />}
        onClick={() => setOpenExcelModal(true)}
      >
        EXPORTAR
      </ButtonGhost>
      <Button
        startIcon={<AddCircleOutline />}
        onClick={() =>
          navigate('/telemetria/configuracoes/cerca-eletronica/criar')
        }
      >
        ADICIONAR CERCA
      </Button>
    </div>
  );

  return (
    <div>
      {isLoading && <Loading />}
      {!isLoading && !data.length && (
        <EmptyCard
          image="padconfig.png"
          title="Comece a configurar as cercas eletrônicas por aqui."
          subtitle=" "
          handleDefaultButton={() =>
            navigate('/telemetria/configuracoes/cerca-eletronica/criar')
          }
          defaultButtonContent={
            <>
              <AddCircleOutline /> Adicionar Cerca Eletrônica
            </>
          }
          margintop="0"
        />
      )}
      {!isLoading && !!data.length && (
        <>
          <S.ControlsSession>
            <S.Controls>
              <S.ControlItem
                selected={!showMap}
                onClick={() => {
                  setShowMap(false);
                }}
              >
                Tabela
              </S.ControlItem>
              <S.ControlItem
                selected={showMap}
                onClick={() => setShowMap(true)}
              >
                Mapa
              </S.ControlItem>
            </S.Controls>
          </S.ControlsSession>
          <S.TableContainer show={!showMap}>
            <FilterTable
              local
              data={data.map(item => ({
                ...item,
                cliente: item.distribuidora?.nome ?? 'Todos',
                tipoVeiculo: item.tipo_veiculo?.tipo ?? 'Todos',
              }))}
              columns={columns}
              filters={tableFilters}
              persist="telemetria-cerca"
              searchKeys={['titulo', 'distribuidora.nome', 'tipo_veiculo.tipo']}
              placeholder="Buscar Cerca Eletrônica"
              permitIsSortedOccur
              resetPaginationOnFilter
              actions={actions}
              empty={{
                title: '',
                description: '',
                image: 'motorista.png',
              }}
              headerActions={headerActions}
            />
          </S.TableContainer>
          <S.MapContainer show={showMap}>
            <S.MapHeader>
              <S.MapFilters>
                {map && (
                  <PlacesAutocomplete
                    sx={{
                      width: '300px',
                      '.MuiOutlinedInput-root': { padding: '3px' },
                    }}
                    onChange={position => {
                      map?.setCenter(position);
                      map?.setZoom(13);
                      setMapInfo(state => ({
                        ...state,
                        content: 'Clique no mapa para definir o ponto',
                      }));
                    }}
                  />
                )}
                <ButtonGhost
                  customcolor={theme.palette.semantics.feedback.unknown.natural}
                  startIcon={<FilterAltOutlined color="disabled" />}
                  onClick={event => setAnchorEl(event.currentTarget)}
                >
                  Filtros
                </ButtonGhost>
              </S.MapFilters>
              <S.MapActions>{headerActions}</S.MapActions>
            </S.MapHeader>
            <GoogleMap
              mapContainerClassName="map"
              options={{
                center: {
                  lat: -13.923025,
                  lng: -56.8509357,
                },
                zoom: 4,
                zoomControl: true,
                scrollwheel: false,
              }}
              getMapInstance={mapInstance => setMap(mapInstance)}
            >
              {mapInfo?.open ? (
                <MapInfoWrapper>
                  <MapInfoContent>{mapInfo.content}</MapInfoContent>
                  <MapInfoButton
                    onClick={e =>
                      setMapInfo(state => ({ ...state, open: false }))
                    }
                  >
                    <Close fontSize="8px" />
                  </MapInfoButton>
                </MapInfoWrapper>
              ) : (
                ''
              )}
            </GoogleMap>
          </S.MapContainer>
        </>
      )}
      <DetalheCercaModal
        open={cercaModal.open}
        registro={cercaModal.data}
        handleClose={() => setCercaModal({ open: false, data: null })}
        refetch={refetch}
        handleEditar={handleEditar}
      />
      <TableFilters
        id="simple-popover"
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        filters={filters}
        setFilters={setFilters}
      />
      {openExcelModal && (
        <ExcelModal
          onClick={handleRequestExcel}
          open={openExcelModal}
          handleClose={() => setOpenExcelModal(false)}
          title="Selecionar os campos de Excel"
          titleIcon={file}
          subtitle="Selecionar abaixo:"
          setData={setExcelFields}
          data={excelFields.filter(
            item => !isProvider || item.label !== 'Cliente',
          )}
          loading={loadingExcel}
        />
      )}
    </div>
  );
};

export default ConfigCercaEletronica;
