import styled, { css } from 'styled-components';

export const Root = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;

  .medium-icon {
    cursor: pointer;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
`;

export const Search = styled.input`
  ${({ theme, disabled }) => css`
    height: 40px;
    width: 264px;
    border-radius: 5px;
    border: 2px solid ${theme.palette.system.border};
    background-color: ${theme.palette.brand.primary.background};
    padding-left: 35px;
    font-size: 18px;
    font-weight: 500;
    color: ${theme.palette.words.text.natural};
    transition: all 0.2s ease-in-out;

    &::placeholder {
      font-size: 18px;
      color: ${theme.palette.words.text.light};
    }

    &:hover {
      border: 2px solid ${theme.palette.words.text.natural};
    }

    &:focus {
      border: 2px solid ${theme.palette.brand.secondary.natural};
    }

    &:disabled {
      opacity: 0.7;
      border: 2px solid ${theme.palette.system.border};
    }
  `}
`;

export const IconContainer = styled.div`
  ${({ theme }) => css`
    position: absolute;
    padding: 5px;
    pointer-events: none;

    .icon {
      color: ${theme.palette.words.text.light};
      margin-bottom: -5px;
    }
  `}
`;
