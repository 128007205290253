import React from 'react';
import { Controller } from 'react-hook-form';
import Select from 'components/Inputs/SelectOne';

const SelectOne = ({ control, name, rules, errors, data, ...props }) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { value, onChange } }) => (
        <Select
          data={data}
          value={value}
          handleChange={value => onChange(value)}
          error={errors?.[name]?.message || null}
          {...props}
        />
      )}
    />
  );
};

export default SelectOne;
