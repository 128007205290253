import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    border: 2px dashed ${theme.palette.semantics.feedback.unknown.natural};
    background-color: #fff;
    width: 272px;
    height: 392px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;

    span {
      margin-top: 15px;
      color: ${theme.palette.words.subtitle.natural};
      font-weight: 500;
    }

    &:hover {
      background-color: ${theme.palette.brand.primary.background};
    }
  `}
`;
