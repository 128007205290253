// Styles
import { useTheme } from 'styled-components';

// React
import { toast } from 'react-toastify';
import React, { useState, useEffect } from 'react';

// Components
import { Switch } from 'components/Inputs/Switch';
import SelectInput from 'components/Inputs/Select';
import GhostButton from 'components/Buttons/Ghost';
import ConfirmModal from 'components/ConfirmModal';
import DefaultButton from 'components/Buttons/Default';

// Components Material UI
import { EditOutlined } from '@mui/icons-material';
import { Icon, IconButton, Modal, Grid } from '@mui/material';
import * as S from './styled';

// Services
import * as services from '../services';

const ModalAddFilial = ({ open, handleClose, filter, fetchData }) => {
  const theme = useTheme();
  // General States
  const [nome, setNome] = useState('');
  const [status, setStatus] = useState(true);
  const [loading, setLoading] = useState(false);
  const [margin, setMargin] = useState('-550px');
  const [confirmAdd, setConfirmAdd] = useState(false);

  // -------------------MODAL CONTROLLER---------------//
  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setMargin('0px');
      }, 10);
    }
  }, [open]);

  const behaviorCloseModal = () => {
    setMargin('-550px');
    setTimeout(() => {
      handleClose();
    }, 380);
  };

  // --------------------REQUEST--------------------------//
  const verifyFields = () => {
    if (!nome) {
      toast.error('Selecione uma Base');
      return;
    }
    setConfirmAdd(true);
  };

  const confirmCreate = async () => {
    const data = {
      base_id: nome,
      status,
    };

    setLoading(true);
    const res = await services.createBase(data);
    if (res.success) {
      behaviorCloseModal();
      setTimeout(() => {
        fetchData();
        toast.success(res.data.message);
      }, 600);
    } else {
      toast.error(res.message);
    }

    setConfirmAdd(false);
    setLoading(false);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={behaviorCloseModal}
        disableEscapeKeyDown={false}
      >
        <S.Container margin={margin}>
          <S.Header>
            <div style={{ alignItems: 'center' }}>
              <Icon
                sx={{ color: theme.palette.brand.secondary.natural }}
                fontSize="medium"
              >
                add_circle_outline
              </Icon>
              <h2>Adicionar Nova Base</h2>
            </div>

            <IconButton size="small" onClick={behaviorCloseModal}>
              <Icon sx={{ color: theme.palette.words.subtitle.natural }}>
                close
              </Icon>
            </IconButton>
          </S.Header>

          <S.Main>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <S.Section>INFORMAÇÕES DAS BASES</S.Section>
              </Grid>

              <Grid item xs={12} sm={12}>
                <SelectInput
                  required
                  handleChange={e => setNome(e.target.value)}
                  name="bases"
                  label="Bases"
                  data={filter}
                  value={nome}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <Switch
                  check={status}
                  setCheckbox={setStatus}
                  textOn="Base Ativa"
                  textOff="Base Inativa"
                />
              </Grid>
            </Grid>
          </S.Main>

          <S.Footer>
            <div>
              <GhostButton onClick={behaviorCloseModal} size="medium">
                CANCELAR
              </GhostButton>

              <DefaultButton
                onClick={verifyFields}
                size="medium"
                sx={{ marginLeft: '20px' }}
                loading={loading}
              >
                Salvar
              </DefaultButton>
            </div>
          </S.Footer>
        </S.Container>
      </Modal>

      {/* Confirmação de adição */}
      <ConfirmModal
        open={confirmAdd}
        handleClose={() => setConfirmAdd(false)}
        title="Deseja adicionar nova base?"
        titleIcon={
          <EditOutlined
            sx={{ color: theme.palette.brand.secondary.natural }}
            fontSize="medium"
          />
        }
        subtitle="Atenção! Bases adicionadas aparecem na tabela."
        buttonText="Confirmar"
        onClick={() => confirmCreate()}
        loading={loading}
      />
    </>
  );
};

export default ModalAddFilial;
