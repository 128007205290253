import styled from 'styled-components';
import colors from 'styles/colors';

export const Container = styled.div`
  flex-wrap: wrap;
  flex-direction: column;
  border-radius: 5px;
`;

export const Square = styled.div`
  display: flex;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid ${colors.greyTiny};
  margin-top: 20px;
`;

export const Counter = styled.div`
  display: flex;
  width: 22px;
  height: 22px;
  background: ${props => (props.counter > 0 ? colors.greenMain : colors.grey)};
  margin: 8px;
  border-radius: 100px;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
`;

export const LoadingBox = styled.div`
  position: relative;
  min-height: 500px;
`;
