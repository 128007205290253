import api from 'services/api';
import qs from 'qs';

export const getGraphs = async (id, query) => {
  const res = await api.get(
    `/provider/training/reports/${id}?${qs.stringify(query)}`,
  );
  return res.data;
};

export const getCompanyInfos = async (id, query) => {
  const res = await api.get(
    `/provider/training/reports-empresas/${id}?${qs.stringify(query)}`,
  );
  return res.data;
};

export const getCertificate = async (id, query) => {
  const res = await api.get(
    `/training/request-acertificate/${id}?${qs.stringify(query)}`,
  );
  return res.data;
};

export const exportExcel = async id => {
  const res = await api.get(
    `/provider/training/reports-empresas/${id}?${qs.stringify({
      exports: true,
      excel: true,
    })}`,
  );
  return res.data;
};

export const exportPDF = async id => {
  const res = await api.get(
    `/provider/training/reports-empresas/${id}?${qs.stringify({
      exports: true,
    })}`,
  );
  return res.data;
};

export const selectEmpresas = async id => {
  const res = await api.get('/provider/training/select-empresas');
  return res.data;
};
