import styled, { css } from 'styled-components';

const ColumnWrapperModifier = {
  selection: () => css`
    width: 70px;
  `,
  normal: ({ lines }) => css`
    width: calc((100% - ${lines.acc}px) / ${lines.columns});
    min-width: 150px;
  `,
};

export const Container = styled.div`
  ${({ theme, transparent }) => css`
    width: 100%;
    display: flex;
    overflow: hidden;
    background-color: ${!transparent
      ? 'transparent'
      : theme.palette.system.white};
    border-bottom: ${!transparent
      ? 'none'
      : `1px solid ${theme.palette.system.border}`};
  `}
`;

export const Column = styled.table`
  ${({ selection, lines }) => css`
    display: flex;
    flex-direction: column;
    ${selection
      ? ColumnWrapperModifier.selection()
      : ColumnWrapperModifier.normal({ lines })}
  `}
`;

export const Scrollable = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;

    ::-webkit-scrollbar {
      height: 8px;
      width: 8px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${theme.palette.system.scrollbar};
    }
  `}
`;
