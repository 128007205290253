import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  min-width: 100%;
  padding-left: 50px;
  flex-direction: column;
  .desvios-padroes {
    text-align: left;
    letter-spacing: 0px;
    color: #95aac9;
  }
  .desvios-padroes > span {
    font-weight: bold;
  }
  .select-header {
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    .title-header {
      text-align: center;
      font: normal normal bold 16px/20px Texta;
      letter-spacing: 0px;
      color: #4b5166;
      padding-right: 10px;
      width: 200px;
    }
  }
  .label-hilight {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 20px;
    -ms-user-select: none;
    user-select: none;
    text-align: left;
    font: normal normal medium 14px/20px Texta;
    letter-spacing: 0px;
    color: #ffffff;
    background: #2e3240;
    border-radius: 12px;
    margin-left: 5px;
  }
  .label-hilight > span {
    margin-left: 10px;
    margin-right: 10px;
    font-weight: bold;
  }
  .label-hilight > span:hover {
    cursor: pointer;
    opacity: 0.2;
    border-radius: 4px;
  }
  .input-title {
    border: none;
    background: transparent;
    font-size: 32px;
    color: #4b5166;
    width: 70%;
    opacity: 0.4;
  }
  .container-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .new-list {
    display: flex;
    gap: 1rem;
    align-items: center;
    flex-direction: row;

    span {
      white-space: nowrap;
      color: ${({ theme }) =>
        theme.palette.semantics.feedback.attention.natural};
    }
  }
  .new-list-title {
    text-align: left;
    font: normal normal 900 32px/46px Texta;
    letter-spacing: 0px;
    color: #4b5166;
    opacity: 0.4;
    margin-right: 10px;
  }
  .container-step {
    display: flex;
    align-items: start;
    padding: 0 20px;
    margin-top: 20px;
  }
  .label-step {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 254px;
    height: 50px;
    padding-left: 30px;
    padding-right: 30px;
    background-color: #fff;
    padding-bottom: 10px;
    padding-top: 10px;
    border: 1px solid #95aac94d;
    border-radius: 4px;
    text-align: center;
    font: normal normal medium 16px/24px Texta;
    letter-spacing: 0px;
    color: #4b5166;
    margin-right: 5px;
  }
  .btn-config-push {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #4b5166;
    border-radius: 4px;
    gap: 8px;
    width: 141px;
    height: 50px;
    margin-left: 5px;
    font: normal normal normal 16px/24px Texta;
    letter-spacing: 0px;
    text-align: center;
    color: #ffffff;
    -ms-user-select: none;
    user-select: none;
    opacity: 0.5;
    cursor: pointer;
  }
  .btn-config-push:hover {
    opacity: 0.2;
    transition: opacity ease-in 0.5s;
  }
  .active {
    opacity: 1;
  }
  .select-time {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;

    span {
      margin-left: 1rem;
      white-space: nowrap;
      color: ${({ theme }) =>
        theme.palette.semantics.feedback.attention.natural};
    }
  }
  .container-btn-time {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .btn-time {
    width: 50px;
    margin: 2px;
    height: 23px;
    background: #fff;
    border: 1px solid #95aac94d;
    border-radius: 4px;
    color: #4b5166;
  }

  .btn-time:hover {
  }

  .btn-time.disabled {
    opacity: 0.3;
  }
  .display-time {
    width: 197px;
    height: 50px;
    border: 1px solid #95aac94d;
    border-radius: 4px;
    text-align: center;
    text-align: center;
    font-size: 32px;
    letter-spacing: 0px;
    color: #4b5166;
    opacity: 0.4;
  }
  .error {
    opacity: 0.8;
    border: 2px solid
      ${({ theme }) => theme.palette.semantics.feedback.attention.natural};
  }
  .container-actions {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    margin-top: 40px;
    .icon {
      background: #edf2f9;
      border-radius: 50%;
      width: 33px;
      height: 33px;
    }
    .btn-add {
      background: #edf2f9;
      border: 1px solid #95aac94d;
      border-radius: 4px;
      text-align: center;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 5px;
      padding-bottom: 5px;
      margin-left: 20px;
      color: #4b5166;
      font-size: 16px;
      cursor: pointer;
      user-select: none;
    }
    .btn-add:hover {
      opacity: 0.8;
    }
  }
  .risco-title {
    text-align: left;
    font: normal normal 900 32px/46px Texta;
    letter-spacing: 0px;
    color: #4b5166;
  }
  .altissimo {
    color: #f64e60;
  }

  .alto {
    color: #ffa801;
  }
`;
