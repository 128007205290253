import api from 'services/api';

export const requestDrivers = async () => {
  const res = await api.get('/capacitacao/configuracoes/motoristas');
  return res.data;
};

export const changeDriverStatus = async id => {
  const res = await api.put(`/driver-change-status/${id}`);
  return res;
};

export const createDriver = async data => {
  const res = await api.post(`/driver`, data);
  return res;
};

export const updateDriver = async data => {
  const res = await api.put(`/driver/${data.id}`, data);
  return res;
};
