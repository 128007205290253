import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme, ...props }) => css`
    .headerArea {
      display: flex;
      justify-content: space-between;
      flex-direction: ${props.preview ? 'column-reverse' : 'row'};

      .boxQuestion {
        display: flex;

        .question {
          font-weight: 700;
          font-size: ${props.preview ? '14px' : '17px'};
          color: ${props.hasError
            ? theme.palette.semantics.feedback.attention.natural
            : theme.palette.system.black};
          word-break: break-all;
        }

        span {
          color: ${theme.palette.semantics.feedback.attention.natural};
          margin-left: 5px;
          font-weight: 900;
        }
      }
    }

    .description {
      margin-top: 8px;
      margin-bottom: 15px;
      font-weight: 500;
      color: ${props.hasError
        ? theme.palette.semantics.feedback.attention.natural
        : theme.palette.words.subtitle.natural};
      font-size: ${props.preview ? '12px' : '14px'};
    }
  `}
`;

export const IconsArea = styled.div`
  ${({ theme, ...props }) => css`
    display: flex;
    align-items: center;
    transform: ${props.preview ? 'scale(0.8)' : 'scale(1)'};
    margin-right: ${props.preview ? '-17px' : '0px'};
    align-self: end;

    .icons {
      margin-left: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .boxComment {
      position: relative;
      margin-bottom: -3px;

      .hasCommnet {
        background-color: ${props.hasComment
          ? theme.palette.semantics.feedback.success.natural
          : theme.palette.semantics.feedback.attention.natural};
        color: ${theme.palette.words.text.contrast};
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        width: 17px;
        height: 17px;
        border-radius: 20px;
        font-size: 12px;
        position: absolute;
        top: 0;
        right: 0%;
        margin-right: -4px;
        margin-top: -4px;
      }
    }
  `}
`;
