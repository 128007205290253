import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setFilter } from 'store/modules/filterDesvioDescarga/actions';
import { useLocation, useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';
import { SaveAlt, Check } from '@mui/icons-material';
import { Divider, Grid } from '@mui/material';

import { formatNameDate, formatNewDate } from 'utils/dates';
import ExportToExcel from 'utils/exportToCvs';
import { useTheme } from 'styled-components';

import Tabs from 'components/Tabs';
import InfoCard from 'components/Cards/InfoCard';
import GhostButton from 'components/Buttons/Ghost';
import Card from 'components/Cards/Indicador';
import ExcelModal from 'components/ExcelModal';
import ConfirmModal from 'components/ConfirmModal';
import { ReactComponent as file } from 'images/icons/sidebar/file.svg';
import DeleteModal from './DeleteModal';

import {
  infoMessage,
  statusTabs,
  columns2,
  fields,
  resetExcelFields,
} from './constants';

import {
  requestInfractions,
  requestCards,
  requestExcel,
  deleteInfractions,
  finishInfractions,
  sendPadInfractions,
} from './services';

import * as S from './styled';
import { trackEvent } from '../../utils/mixpanel';
import { getRouterQueryParams } from '../../utils/router-query-params';
import { DefaultTable } from 'components/_Table/templates/default';
import { useQuery } from 'react-query';

const DesviosDescarga = () => {
  const theme = useTheme();
  // Redux e hooks
  const filter = useSelector(state => state.filter);
  const filterDesvio = useSelector(state => state.filterDesvioDescarga);
  const user = useSelector(state => state.auth?.user?.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userLevel = user.nivel;

  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedInfractions, setSelectedInfractions] = useState([]);

  const [query, setQuery] = useState(null);
  const [loadingTab, setLoadingTab] = useState(false);
  const [resetTable, setResetTable] = useState(false);
  const [loadingLines, setLoadingLines] = useState([]);

  const [idsFinishModal, setIdsFinishModal] = useState(null);
  const [idsDeleteModal, setIdsDeleteModal] = useState(false);
  const [idsPadModal, setIdsPadModal] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);

  // Excel Fields
  const [openExcelModal, setOpenExcelModal] = useState(false);
  const [excelFields, setExcelFields] = useState(fields);
  const [loadingExcel, setLoadingExcel] = useState(false);

  const getExpirationDate = row => {
    const parameters = resData?.data?.parametros;
    if (parameters) {
      let newDate = new Date(row.createdAt);
      if (row.id_da_distribuidora) {
        if (parameters?.clientes.length) {
          const newValue = parameters.clientes.find(
            item => item.id_distribuidora === row.id_da_distribuidora,
          );
          if (newValue) newDate.setDate(newDate.getDate() + +newValue.valor);
          else newDate.setDate(newDate.getDate() + +parameters.valor);
        }
      } else newDate.setDate(newDate.getDate() + +parameters.valor);
      return formatNewDate(newDate);
    }

    return '-';
  };

  // ********************  Funções da página *****************************

  const {
    refetch: fetchData,
    isFetching,
    isLoading: loadingInfractions,
    data: resData,
  } = useQuery(
    ['infractions-descarga-index', query],
    () => query && requestInfractions(query),
    {
      refetchOnWindowFocus: false,
      onSuccess: () => {
        setLoadingLines([]);
        setLoadingTab(false);
        resetTable && setResetTable(false);
      },
    },
  );

  // Altera coluna de posicao 5 com o STATUS
  const handleColumns = () => {
    const col = [...columns2].map(item => {
      if (item.id === 'createdAt') {
        return {
          ...item,
          subRow: {
            prefix: 'Vencimento:',
            value: (_, item) => getExpirationDate(item),
          },
        };
      }
      return item;
    });

    if (filterDesvio.status === 'FINALIZADO')
      col[5] = {
        header: 'Data Conclusão',
        id: 'data_encerramento',
        type: 'date',
        sort: true,
      };
    else if (filterDesvio.status === 'DELETADO')
      col[5] = {
        header: 'Data Exclusão',
        id: 'data_exclusao',
        type: 'date',
        sort: true,
      };

    return col;
  };

  // Atualiza cards de acordo com os filtros selecionados
  // Atualiza tabela após cads
  const {
    refetch: fetchCards,
    isLoading: loadingCards,
    data: resCards,
  } = useQuery(
    ['infractions-descarga-cards', { ...filter, ...filterDesvio }],
    () =>
      requestCards({
        ...filter,
        ...filterDesvio,
      }),
    {
      refetchOnWindowFocus: false,
    },
  );

  const mode = getRouterQueryParams({ location: useLocation }).get('mode');

  useEffect(() => {
    mode &&
      dispatch(
        setFilter({
          status: mode,
        }),
      );
  }, [mode]);

  const initial = useRef(true);
  useEffect(() => {
    if (initial.current) {
      initial.current = false;
      return;
    }

    setResetTable(true);
  }, [filter, filterDesvio, selectedCard]);

  // Troca do tab (filterDesvio.status)
  const handleStatusTab = async (event, newValue) => {
    setSelectedCard(null);
    setLoadingTab(true);
    dispatch(
      setFilter({
        status: newValue,
      }),
    );
  };

  // Só habilita clique no card quando nao há nada carregando
  // Verifica se card clicado há valor valido: nao nulo e maior que 0.
  // Se sim: seleciona ou desseleciona ele
  const handleClickCard = type => {
    if (!loadingCards && !loadingInfractions) {
      const cards = resCards?.data?.data || [];
      const card = cards.find(item => item.type === type);
      if (card.value) {
        if (type === selectedCard) setSelectedCard(null);
        else setSelectedCard(type);
      }
    }
  };

  const handleRequestExcel = async () => {
    setLoadingExcel(true);

    const newFields = excelFields.filter(item => item.selected === true);
    const formatedDate = formatNameDate(new Date());

    const newQuery = { ...filter, ...filterDesvio, excelFields: newFields };

    const res = await requestExcel(newQuery);
    if (res.data && res.data?.data?.excel) {
      ExportToExcel({
        excel: res.data.data.excel,
        name: `desvios-descarga_${formatedDate}`,
      });
      toast.success(res.data.message);
    } else if (res.data.message) toast.error(res.data?.message);

    setExcelFields(resetExcelFields(fields));
    setLoadingExcel(false);
    setOpenExcelModal(false);
  };

  // ********************  ACTIONS / BULKACTIONS *****************************
  const handleFinalizar = async () => {
    setLoadingModal(true);
    setLoadingLines(idsFinishModal || []);

    const data = { ids: idsFinishModal.map(id => parseInt(id)) };
    const res = await finishInfractions(data);
    if (res.data?.success) {
      fetchCards();
      fetchData();
      toast.success(res.data?.message);
    } else {
      setLoadingLines([]);
      if (res.data?.message) toast.error(res.data.message);
      if (res.data?.bulkResponse) {
        // TODO: resposta individual
      }
    }
    setLoadingModal(false);
    setIdsFinishModal(null);
    trackEvent(user, 'Finalizar Desvios');
  };

  const handleDeletar = async data => {
    setLoadingModal(true);
    setLoadingLines(data?.ids || []);
    const res = await deleteInfractions(data);
    if (res.data?.success) {
      fetchCards();
      fetchData();
      toast.success(res.data?.message);
    } else {
      setLoadingLines([]);
      if (res.data?.message) toast.error(res.data.message);
      if (res.data?.bulkResponse) {
        // TODO: resposta individual
      }
    }
    setLoadingModal(false);
    setIdsDeleteModal(null);
    trackEvent(user, 'Excluir Desvios');
  };

  const handleSendPad = async () => {
    setLoadingModal(true);

    const data = { ids: idsPadModal.map(id => parseInt(id)) };
    setLoadingLines(idsPadModal);
    const res = await sendPadInfractions(data);
    if (res.data?.success) {
      fetchCards();
      fetchData();
      toast.success(res.data?.message);
    } else {
      setLoadingLines([]);
      if (res.data?.message) toast.error(res.data.message);
      if (res.data?.bulkResponse) {
        // TODO: resposta individual
      }
    }
    setLoadingModal(false);
    setIdsPadModal(null);
  };

  const handleOpenNewTab = id => {
    return window.open(`/desvio-descarga/${id}`);
  };

  const handleOpenDetails = id => {
    navigate(`/desvio-descarga/${id}`);
  };

  // Atualiza bulkActions de acordo com o status selecionado
  // Os itens selecionados podem alterar o a lista de ações
  const getBulkActions = () => {
    let actions = [];

    switch (filterDesvio.status) {
      case 'PENDENTE':
        actions = [
          {
            title: 'Finalizar',
            function: ids => setIdsFinishModal(ids),
          },
          userLevel < 4 && {
            title: 'Excluir',
            function: ids => setIdsDeleteModal(ids),
          },
        ];
        break;

      case 'FINALIZADO':
        actions = [
          {
            title: 'Enviar para PAD',
            function: ids => setIdsPadModal(ids),
          },
          trackEvent(user, 'Enviar Desvio para o Pad'),
        ];
        break;

      default:
        break;
    }
    return actions.filter(Boolean);
  };

  // Atualiza ações de acordo com o status selecionado
  const getActions = () => {
    let actions = [{ name: 'null', text: 'Sem ações' }];

    switch (filterDesvio.status) {
      case 'PENDENTE':
        actions = [
          {
            title: 'Abrir em nova guia',
            function: handleOpenNewTab,
          },
          {
            title: 'Finalizar',
            function: id => setIdsFinishModal([id]),
          },
          userLevel < 4 && {
            title: 'Excluir',
            function: id => setIdsDeleteModal([id]),
          },
        ];
        break;

      case 'FINALIZADO':
        actions = [
          {
            title: 'Abrir em nova guia',
            function: handleOpenNewTab,
          },
          {
            title: 'Enviar para o PAD',
            function: id => setIdsPadModal([id]),
          },
        ];
        break;

      case 'DELETADO':
        actions = [
          {
            title: 'Abrir em nova guia',
            function: handleOpenNewTab,
          },
        ];
        break;

      default:
        break;
    }
    return actions.filter(Boolean);
  };

  // Renderiza cards por status: ABERTO, PENDENTE, FINALIZADO, DELETADO
  const renderCards = () => {
    const cards = resCards?.data?.data || [{}, {}, {}];
    return (
      <Grid container spacing={2} marginBottom="25px">
        {cards &&
          cards.map(card => {
            return (
              <Grid item key={card.type} xs={12} md={4} xl={4}>
                <Card
                  value={card.value}
                  icon={card.icon}
                  text={card.text}
                  handleClick={() => handleClickCard(card.type)}
                  selected={selectedCard === card.type}
                  disabled={isFetching}
                  loading={loadingCards}
                />
              </Grid>
            );
          })}
      </Grid>
    );
  };

  return (
    <>
      <S.Main>
        <Grid
          marginBottom={1}
          item
          md={12}
          display="flex"
          justifyContent="space-between"
        >
          <h1>Desvios de Descarga</h1>

          <GhostButton
            startIcon={<SaveAlt />}
            size="medium"
            onClick={() => {
              setOpenExcelModal(true);
              trackEvent(user, 'Exportar Planinha Desvio');
            }}
            style={{ marginLeft: '10px' }}
          >
            EXPORTAR
          </GhostButton>
        </Grid>

        <Grid marginBottom={0} item md={12} display="flex">
          <Tabs
            value={filterDesvio.status}
            items={statusTabs}
            onChange={handleStatusTab}
            disabled={loadingCards || loadingInfractions || isFetching}
            last
          />
        </Grid>

        <Divider />

        <div style={{ padding: '15px 0px' }}>
          <InfoCard message={infoMessage} key="info" />
        </div>

        {renderCards()}

        <DefaultTable
          data={resData?.data?.rows || []}
          columns={handleColumns()}
          setSelectedRows={setSelectedInfractions}
          loading={loadingInfractions || loadingTab}
          pageCount={resData?.data?.total || 0}
          visualizedKey="visto"
          local={false}
          bulk={getBulkActions()}
          actions={getActions()}
          reset={resetTable}
          onClickRow={handleOpenDetails}
          loadingSelection={loadingLines}
          setQuery={q =>
            setQuery({ ...q, ...filter, ...filterDesvio, card: selectedCard })
          }
          searchEvent={search =>
            trackEvent(user, 'Busca Desvios de Descarga', null, search)
          }
          sortBy={{ id: 'data_desvio', order: 'DESC' }}
          placeholder="Buscar por ID, Placa ou Motorista"
          empty={{
            title: 'Ops! Você não tem nenhum desvio disponível.',
            description: 'Verifique os filtros aplicados!',
          }}
        />
      </S.Main>

      {openExcelModal && (
        <ExcelModal
          onClick={handleRequestExcel}
          open={openExcelModal}
          handleClose={() => setOpenExcelModal(false)}
          title="Selecionar os campos de Excel"
          titleIcon={file}
          subtitle="Selecionar abaixo:"
          setData={setExcelFields}
          data={excelFields}
          loading={loadingExcel}
        />
      )}

      {idsFinishModal && (
        <ConfirmModal
          handleClose={() => setIdsFinishModal(null)}
          open={Boolean(idsFinishModal)}
          title={
            idsFinishModal.length > 1
              ? `Deseja finalizar os desvios selecionados? (${idsFinishModal.length})`
              : 'Deseja finalizar o desvio selecionados?'
          }
          titleIcon={
            <Check
              sx={{ color: theme.palette.brand.secondary.natural }}
              fontSize="medium"
            />
          }
          subtitle={
            idsFinishModal.length > 1
              ? 'Os desvios serão movidos para Finalizados. As alterações serão aplicadas em TODOS os desvios selecionados.'
              : 'O desvio será movido para aba Finalizados.'
          }
          buttonText="Confirmar"
          onClick={handleFinalizar}
          loading={loadingModal}
        />
      )}

      {idsDeleteModal && (
        <DeleteModal
          handleConfirm={handleDeletar}
          ids={idsDeleteModal}
          handleClose={() => setIdsDeleteModal(null)}
          loading={loadingModal}
        />
      )}

      {idsPadModal && (
        <ConfirmModal
          handleClose={() => setIdsPadModal(null)}
          open={Boolean(idsPadModal)}
          title={
            idsPadModal.length > 1
              ? `Deseja enviar desvios ao PAD? (${idsPadModal.length})`
              : 'Deseja enviar desvio ao PAD?'
          }
          subtitle="Os desvios serão enviados como ocorrências para o PAD"
          titleIcon={
            <Check
              sx={{ color: theme.palette.brand.secondary.natural }}
              fontSize="medium"
            />
          }
          buttonText="Confirmar"
          onClick={handleSendPad}
          loading={loadingModal}
        />
      )}
    </>
  );
};

export default DesviosDescarga;
