// Styled
import { useTheme } from 'styled-components';

// React
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  setId,
  setItems,
  setStatus,
  setTitle,
} from 'store/modules/formularios/actions';

// Components
import TextInput from 'components/Inputs/TextField';
import DefaultButton from 'components/Buttons/Default';

// Components Material
import { Icon, IconButton } from '@mui/material';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { toast } from 'react-toastify';
import * as S from './styled';

// Services
import * as services from '../../services';

const ModalTitle = ({
  open,
  onClose,
  setFullModal,
  title,
  template,
  closeModalTemplates,
}) => {
  const theme = useTheme();
  const disptach = useDispatch();

  const [value, setValue] = useState(title || '');
  const [loading, setLoading] = useState(false);

  const generateNewForm = async () => {
    if (value) {
      // TODO: Unificar dispatchs
      setLoading(true);
      const res = await services.CreateTitleForm(value);
      if (res.data.success) {
        disptach(setTitle(res.data.data.titulo));
        disptach(setStatus(res.data.data.status));
        disptach(setId(res.data.data.id));

        if (template) {
          const saveSections = await services.SaveFields({
            id: res.data.data.id,
            pagina: 'secoes',
            titulo: res.data.data.titulo,
            secoes: template,
          });

          if (saveSections.success) {
            disptach(setItems(template));
            closeModalTemplates();
          } else {
            toast.error(saveSections.message);
            return;
          }
        }

        setFullModal(true);
        setValue('');
        onClose();
      } else {
        toast.error(res.data.message);
      }
      setLoading(false);
    } else {
      toast.error('Preencha um título para o formulário');
    }
  };

  return (
    <S.ModalArea
      open={open}
      onClose={() => {
        onClose();
        setValue('');
      }}
      disableEscapeKeyDown={false}
    >
      <S.Container>
        <S.Header>
          <div style={{ alignItems: 'center' }}>
            <FormatListBulletedIcon
              htmlColor={theme.palette.brand.secondary.natural}
            />
            <h2>Novo Formulário</h2>
            {template && (
              <span className="isTemplate">Template selecionado</span>
            )}
          </div>

          <IconButton
            size="small"
            onClick={() => {
              onClose();
              setValue('');
            }}
          >
            <Icon sx={{ color: theme.palette.words.subtitle.natural }}>
              close
            </Icon>
          </IconButton>
        </S.Header>

        <S.Body>
          <TextInput
            placeholder="Insira o título do formalário"
            label="Título"
            value={value}
            onChange={e => setValue(e.target.value.slice(0, 50))}
          />
          <S.Counter>{`${value?.length}/50`}</S.Counter>
          <S.Box>
            <DefaultButton
              children="Criar"
              onClick={() => generateNewForm()}
              loading={loading}
            />
          </S.Box>
        </S.Body>
      </S.Container>
    </S.ModalArea>
  );
};

export default ModalTitle;
