import styled, { css } from 'styled-components';

const containerModifier = {
  normal: () => css`
    textarea {
      font-size: 14px;
      min-width: 500px;
    }

    span {
      font-size: 14px;
    }
  `,
};

export const Container = styled.div`
  ${({ theme, type }) => css`
    display: flex;
    justify-content: start;
    align-items: center;
    color: ${theme.palette.words.subtitle.natural};
    margin-top: 10px;

    textarea {
      border: none;
      border: 1px solid ${theme.palette.brand.primary.light};
      border-radius: 4px;
      font-weight: 500;
      margin-left: 10px;
      margin-right: 10px;
      padding: 0px 3px;
      resize: none;

      &::placeholder {
        font-size: 14px;
        color: ${theme.palette.words.text.light};
      }
    }

    .counter {
      font-size: 10px;
      margin-right: 13px;
    }

    span {
      font-weight: 600;
      margin-left: 10px;
      margin-right: 10px;
      cursor: pointer;
    }

    ${!!type && containerModifier[type]}
  `}
`;
