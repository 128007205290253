import styled from 'styled-components';
import colors from 'styles/colors';

export const Container = styled.div`
  flex: 1;
  background-color: #fff;
  border: 2px solid
    ${props => (props.isSelected ? colors.greenMain : colors.grey)};
  height: 273px;
  border-radius: 5px;
  margin: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: all ease 0.3s;
  padding: 20px 0px;

  &:hover {
    border-color: ${colors.greenMain};
    background-color: rgba(245, 245, 245, 10);
    transform: scale(1.05);
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;

  .titleArea {
    font-weight: 600;
    color: ${props =>
      props.isSelected ? colors.greenMain : colors.greySubtitle};
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  width: 70%;

  .boxItem {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border: 1px solid
      ${props => (props.isSelected ? colors.greenMain : colors.greyTiny)};
    border-radius: 5px;
    height: 50px;

    .option {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      border-right: 1px solid
        ${props => (props.isSelected ? colors.greenMain : colors.greyTiny)};

      label {
        margin-left: 5px;
        color: ${props =>
          props.isSelected ? colors.greenMain : colors.greySubtitle};
        font-weight: 600;
        font-size: 15px;
      }
    }

    .option:last-child {
      border: none;
    }
  }
`;

export const Footer = styled.div``;
