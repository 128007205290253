// Styled

// React
import { useQuery } from 'react-query';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

// Components
import Loading from 'components/Loading';
import Stepper from 'components/Stepper';
import GhostButton from 'components/Buttons/Ghost';
import DefaultButton from 'components/Buttons/Default';
import * as S from './styled';

// Constants
import { steps } from './constants';

// Services
import * as services from './services';

import { Sidebar } from './Sidebar';

const TemplateConfigVagas = () => {
  // Navigate
  const navigate = useNavigate();
  const location = useLocation();

  // Params
  const params = useParams();

  // Controller Page (states)
  const [isEdit, setIsEdit] = useState(null);
  const [trySave, setTrySave] = useState(false);
  const [changed, setChanged] = useState(false);
  const [vagaId, setVagaId] = useState(null);
  const [currentStep, setCurrentStep] = useState(steps[0]);
  const [data, setData] = useState();

  // Selects
  const [beneficios, setBeneficios] = useState(null);
  const [bnf, setBnf] = useState(null);
  const [cargas, setCargas] = useState(null);
  const [segmentos, setSegmentos] = useState(null);

  // ------------------------CHARGE CURRENT FORM------------------------------------//
  const {
    data: res,
    isError,
    isLoading,
  } = useQuery(
    [`vacancy${params.id}`],
    () => services.getOneVacancy(params.id),
    { refetchOnWindowFocus: false },
  );

  useEffect(() => {
    if (isLoading) {
      return null;
    }
    if (!isError) {
      const vaga = res.data;
      setVagaId(res?.data?.id || null);
      setData({
        // Infos Gerais
        titulo: vaga.titulo,
        descricao: vaga.descricao,
        id_usuario: vaga.id_usuario,
        imagem_vaga: vaga.imagem_vaga,

        // Requisitos
        id_carga: vaga.id_carga,
        id_segmento: vaga.id_segmento,
        tipo_de_carteira: vaga.tipo_de_carteira,
        categoria: vaga.categoria,
        requisitos:
          vaga?.requisitos?.map(item => ({ text: item.requisito })) || [],
        beneficios: [],

        // Detalhamento
        uf: vaga.estado,
        cidade: vaga.cidade,
        quantidade: vaga.posicoes_abertas,
        tipo_contratacao: vaga.tipo_contratacao,
        data_inicio: vaga.data_inicio,
        data_fim: vaga.expira_em,
        tempo_experiencia: vaga.tempo_de_experiencia,
        salario: vaga.salario,
        ocultar: !!vaga.empresa_oculta,
      });
    }

    const pathname = location.pathname.slice(19);
    if (pathname.includes('editar') && res?.data?.expira_em) {
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }

    if (pathname.includes('editar2')) {
      setCurrentStep(steps[3]);
    }

    // Inicializa o processo de preencher os campos
    const getSelects = async () => {
      const res = await services.selectsVagas();
      if (res.success) {
        setBnf(res.beneficios);
        setSegmentos(res.segmentos);
        setCargas(res.cargas);
      }
    };

    getSelects();
  }, [isLoading]);

  useEffect(() => {
    if (bnf && res?.data) {
      const currentBenefits = res.data.beneficios;
      const checkBnf = currentBenefits?.map(item => ({
        id: item.VagaBeneficio.id_beneficio,
        value: item.VagaBeneficio.valor,
      }));

      const copyBenefits = [...bnf];
      copyBenefits?.map(beneficio => {
        for (const i in checkBnf) {
          if (checkBnf[i].id === beneficio.id) {
            beneficio.checked = true;
            beneficio.value = checkBnf[i].value;
          }
        }
      });

      setBeneficios(copyBenefits);
    }
  }, [res, bnf]);

  // --------------------CONTROLLER PAGE (Functions)--------------------------------//
  const handleBackPage = () => {
    if (currentStep.step !== 1) {
      setCurrentStep(steps[currentStep.value - 1]);
      setChanged(false);
    } else {
      navigate(-1);
    }
  };

  const handleNext = () => {
    if (currentStep.step < steps.length) {
      setCurrentStep(steps[currentStep.value + 1]);
      setChanged(false);
    }
  };

  const changePage = () => {
    const { Component } = currentStep;
    return (
      <Component
        trySave={trySave}
        setTrySave={setTrySave}
        changed={changed}
        setChanged={setChanged}
        beneficios={beneficios}
        cargas={cargas}
        segmentos={segmentos}
        setVagaId={setVagaId}
        vagaId={vagaId}
        setCurrentStep={setCurrentStep}
        setBeneficios={setBeneficios}
        candidatos={res.data.candidatos || []}
        isEdit={isEdit}
        data={data}
        setData={setData}
      />
    );
  };

  const handleSave = status => {
    // This state is used for start a request in sub components
    // They have a useEffect to verify true or false and dispatch request
    // setFinalMode(status)
    setTrySave(true);
  };

  // -------------------------CONTROLLER FOOTER BUTTONS----------------------------//
  const saveButton = () => {
    let valid = false;
    if ((changed && isEdit) || !isEdit) {
      valid = true;
    }

    if (valid) {
      return (
        <DefaultButton onClick={() => handleSave()}>
          {currentStep.step === 3
            ? isEdit
              ? 'Salvar'
              : 'Salvar e Publicar'
            : 'Salvar e Avançar'}
        </DefaultButton>
      );
    }
  };

  const nextButton = () => {
    let valid = false;

    if (!changed && isEdit && currentStep.step !== 3) {
      valid = true;
    }

    if (valid) {
      return (
        <DefaultButton onClick={() => handleNext()}>Avançar</DefaultButton>
      );
    }
  };

  return (
    <S.Container>
      {!isLoading && isEdit !== null && (
        <>
          {isEdit && (
            <Sidebar
              page={currentStep.step - 1 || 0}
              setPage={setCurrentStep}
              setChanged={setChanged}
            />
          )}
          <S.TemplatePage className={isEdit ? 'isEditTemplate' : ''}>
            {!isEdit && (
              <Stepper
                steps={steps.slice(0, 3)}
                currentStep={currentStep.step}
              />
            )}

            <S.HeaderPage>
              <div className="titlePage">
                {!isEdit ? currentStep.pageTitle : currentStep.page}
              </div>
            </S.HeaderPage>

            <div style={{ marginBottom: '60px' }}>
              {!isLoading && data && changePage()}
            </div>

            {currentStep.step !== 4 && (
              <S.ControllerArea>
                <div className="statusArea">
                  {changed && 'Alterações não salvas'}
                  {!changed && 'Informações Atualizadas'}
                </div>

                <div className="buttonsArea">
                  <GhostButton
                    className="backArea"
                    onClick={() => handleBackPage()}
                  >
                    Voltar
                  </GhostButton>

                  {saveButton()}
                  {nextButton()}
                </div>
              </S.ControllerArea>
            )}
          </S.TemplatePage>
        </>
      )}

      {isLoading && (
        <S.LoadingBox>
          <Loading />
        </S.LoadingBox>
      )}
    </S.Container>
  );
};

export default TemplateConfigVagas;
