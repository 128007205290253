import * as types from './types';

export function setFilterProvider(query) {
  return {
    type: types.SET_FILTER_EXCLUSAO_DESVIO,
    payload: { query },
  };
}

export function changeTabExclusaoDesvio({ tab }) {
  return {
    type: types.CHANGE_TAB_EXCLUSAO_DESVIO,
    payload: { tab },
  };
}

export function changeCardExclusaoDesvio({ card }) {
  return {
    type: types.CHANGE_CARD_EXCLUSAO_DESVIO,
    payload: { card },
  };
}
