const filtersGraficoParams = [
  {
    name: 'filiais',
    placeholder: 'Filtrar por Filial',
    data: [],
  },

  {
    name: 'cliente',
    placeholder: 'Filtrar por Cliente',
    data: [],
  },
  {
    name: 'tipos_desvio',
    placeholder: 'Filtra por tipo de desvio',
    data: [],
  },
];

const filtersGraficoParamsProvider = [
  {
    name: 'empresas',
    placeholder: 'Filtrar por Empresa',
    data: [],
  },
  {
    name: 'tipos_desvio',
    placeholder: 'Filtra por tipo de desvio',
    data: [],
  },
];

const data = [
  {
    name: 'Velocidade da via até',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Interação com celular',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Frenagem em curva',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Objeto solto na cabine',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Page A',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Velocidade da via até',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Interação com celular',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Frenagem em curva',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Objeto solto na cabine',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Page A',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
];

const tabsItemsPth = [
  { value: 'Desvios', label: 'DESVIOS' },
  { value: 'Clientes', label: 'CLIENTES' },
  { value: 'Filiais', label: 'FILIAIS' },
  { value: 'Operações', label: 'OPERAÇÕES' },
  { value: 'Tipos de motoristas', label: 'TIPOS DE MOTORISTAS' },
];

const tabsItemsPtkm = [
  { value: 'Desvios', label: 'DESVIOS' },
  { value: 'Clientes', label: 'CLIENTES' },
  { value: 'Filiais', label: 'FILIAIS' },
  { value: 'Tipos de veiculos', label: 'TIPOS DE VEÍCULOS' },
  { value: 'Tipos de motoristas', label: 'TIPOS DE MOTORISTAS' },
];

const tabsItemsPthsProvider = [
  { value: 'Empresas', label: 'EMPRESAS' },
  { value: 'Desvios', label: 'DESVIOS' },
  { value: 'Tipos de veículos', label: 'TIPOS DE VEÍCULOS' },
  { value: 'Tipos de motoristas', label: 'TIPOS DE MOTORISTAS' },
];

const tabsItemsPtkmProvider = [
  { value: 'Empresas', label: 'EMPRESAS' },
  { value: 'Desvios', label: 'DESVIOS' },
  { value: 'Operações', label: 'OPERAÇÕES' },
  { value: 'Tipos de motoristas', label: 'TIPOS DE MOTORISTAS' },
];

const colorsCriticidade = {
  PRIMÁRIA: '#1BC5BD',
  GRAVE: '#FFA801',
  GRAVÍSSIMA: '#F64E60',
  MODERADA: '#0C12F2',
};
export {
  filtersGraficoParams,
  data,
  tabsItemsPth,
  tabsItemsPtkm,
  colorsCriticidade,
  tabsItemsPthsProvider,
  tabsItemsPtkmProvider,
  filtersGraficoParamsProvider,
};
