import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { usePlans } from 'hooks/usePlans';

import CustomDateRangePicker from 'components/Header/DateRangePicker';
import { Grid } from '@mui/material';
import { Header } from '../components/Header';
import { TempoMedioRevisao } from '../components/TempoMedioRevisao';
import { RevisoesExclusao } from '../components/RevisoesExclusao';

import * as S from '../styled';
import * as API from '../services';

// services
import { getUltimaRevisao } from '../services';
import { Atividades } from '../components/Atividades';
import { downloadFromLink } from 'utils/helpers';

export const EstatisticasTorre = () => {
  const params = useParams();
  const { isProvider } = usePlans();
  const [groupBy, setGroupBy] = useState('CRITICIDADE');
  const [loadingExport, setLoadingExports] = useState(false);

  const filter = useSelector(state => {
    return isProvider ? state.filterProvider : state.filter;
  });

  const { isLoading, data = {} } = useQuery(
    ['equipe-ultima-revisao', params.id],
    () => getUltimaRevisao(params.id),
    {
      refetchOnWindowFocus: false,
    },
  );

  const handleExport = async () => {
    setLoadingExports(true);
    const res = await API.exportDepenhoDoUsuarioTorre({
      ...filter,
      groupBy,
      user_id: params.id,
    });
    downloadFromLink(res?.data?.link);
    setLoadingExports(false);
  };

  return (
    <S.Container>
      <Header
        foto={data?.foto}
        title={data?.nome}
        isLoading={isLoading}
        ultima_revisao={data?.ultima_revisao ?? null}
        loading={loadingExport}
        handleExport={handleExport}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={7}>
          <S.Flex>
            <S.Title>Revisões de exclusão</S.Title>
            <CustomDateRangePicker height="45px" />
          </S.Flex>
          <br />
          <S.Flex className="gap-05">
            <RevisoesExclusao
              id={params.id}
              filter={filter}
              groupBy={groupBy}
              setGroupBy={setGroupBy}
            />
          </S.Flex>
          <br />
          <TempoMedioRevisao id={params.id} />
        </Grid>
        <Grid item xs={12} md={5}>
          <Atividades id={params.id} filter={filter} />
        </Grid>
      </Grid>
    </S.Container>
  );
};
