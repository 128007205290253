/* eslint-disable import/order */
// Styles
import * as S from './styled';

// React
import { useEffect, useRef, useState } from 'react';

// Components
import { Card } from '../Card';

// Components MUI
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';

// Slick
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Constants
import { SLIDE_CONFIG } from './constants';

export const Section = ({ item, open, setOpen, isLoading }) => {
  const sliderRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  // -----------------------------------------SLIDE-----------------------------------------
  const next = () => {
    !isLoading && sliderRef.current.slickNext();
  };

  const previous = () => {
    !isLoading && sliderRef.current.slickPrev();
  };

  const handleSlideChange = newSlide => {
    setCurrentSlide(newSlide);
  };

  const renderDots = slideCount => {
    const currentConfig = SLIDE_CONFIG.slidesToShow;

    const dotsCount = Math.ceil(slideCount / currentConfig);
    const activeDot = Math.floor(currentSlide / currentConfig);

    return Array.from({ length: dotsCount }).map((_, index) => (
      <button
        key={index}
        className={index === activeDot ? 'active' : ''}
        onClick={() => sliderRef.current.slickGoTo(index * currentConfig)}
        aria-label={`Slide ${index + 1}`}
      />
    ));
  };

  useEffect(() => {
    if (sliderRef.current) {
      const { currentSlide } = sliderRef.current.innerSlider.state;
      setCurrentSlide(currentSlide);
    }
  }, [sliderRef]);

  return (
    <S.Section key={item?.id}>
      <S.SectionHeader>
        <h2>
          <S.Loading isLoading={isLoading} />
          {item?.titulo}
        </h2>
        <div className="infos">
          <S.Loading isLoading={isLoading} />
          <div className="pointer">{renderDots(item?.passos?.length || 0)}</div>
          <div className="arrows">
            <div className="box" onClick={previous}>
              <ArrowBackIosRoundedIcon />
            </div>
            <div className="box" onClick={next}>
              <ArrowForwardIosRoundedIcon />
            </div>
          </div>
        </div>
      </S.SectionHeader>

      <Slider
        {...SLIDE_CONFIG}
        ref={sliderRef}
        afterChange={newSlide => handleSlideChange(newSlide)}
        className="slider"
      >
        {item?.passos?.map((step, index) => (
          <Card
            key={index}
            item={step}
            active={open}
            onClick={card => setOpen(card)}
            isLoading={isLoading}
          />
        ))}
      </Slider>
    </S.Section>
  );
};
