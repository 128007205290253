export const localFilter = (data, state) => {
  const { filters } = state;
  let updatedData = data;

  filters?.forEach(filter => {
    const searchKeys = filter.keysToSearch;
    const value = filter?.value;

    const isArray = Array.isArray(value);

    if (isArray ? value?.length > 0 : value) {
      searchKeys.forEach(key => {
        updatedData = updatedData.filter(item => {
          const searchable = item;

          // Format keys intro object
          if (key?.includes('.')) {
            const keys = key.split('.');
            let value = searchable;
            keys?.forEach(k => {
              value = value?.[k];
            });
            searchable[key] = value;
          }

          const pureSearch = searchable?.[key]?.toString()?.toLowerCase();

          if (!isArray) {
            const pureValue = value?.toString()?.toLowerCase();
            if (searchable?.[key] && pureValue?.includes(pureSearch)) {
              return true;
            }
          } else {
            const pureValues = value?.map(i => i?.toString()?.toLowerCase());
            if (searchable?.[key] && pureValues?.includes(pureSearch)) {
              return true;
            }
          }
        });
      });
    }
  });

  return updatedData;
};
