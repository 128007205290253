import styled, { css } from 'styled-components';

export const Loading = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease-in-out;
  opacity: ${props => (props.isLoading ? 1 : 0)};
  z-index: ${props => (props.isLoading ? 2 : -1)};
  border-radius: 100vh;
  background-image: linear-gradient(
    to right,
    #e2e2e8 0%,
    #edeef1 20%,
    #e2e2e8 40%,
    #e2e2e8 100%
  );
  background-size: 80rem 14rem;
  animation: placeholderShimmer 2s linear infinite forwards;

  @keyframes placeholderShimmer {
    0% {
      background-position: -40rem 0;
    }
    100% {
      background-position: 40rem 0;
    }
  }
`;
