import styled, { css } from 'styled-components';

export const Container = styled.div`
  padding: 30px;
  .title {
    font-size: 40px;
    font-weight: 900;
    font-style: normal;
    letter-spacing: 0px;
    color: #4b5166;
    span {
      color: #f64e60;
    }
  }

  .tabs {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #edf2f9;
    cursor: default;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    .indicator {
      border-radius: 4px 4px 0px 0px;
      height: 4px;
      width: auto;
    }
    .active {
      background: #bdcc30;
    }
    .container-indicator {
      margin-right: 15px;
      display: flex;
      flex-direction: column;
    }
  }

  .item-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #4b5166;
    border-bottom: 2px solid #edf2f9;
    padding-bottom: 10px;
    margin-left: 20px;
    margin-top: 10px;
    span {
      font-style: normal;
      font-weight: 900;
    }
    .item-link {
      text-align: left;
      text-decoration: underline;
      letter-spacing: 0px;
      color: #bdcc30;
      font-style: normal;
      font-weight: medium;
      font-size: 16px;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      text-decoration: none;
      cursor: default;
      &:hover {
        opacity: 0.7;
      }
    }
    .vencimento {
      font-size: 14px;
      font-weight: 900;
      font-style: normal;
      letter-spacing: 0px;
      color: #f64e60;
    }
  }
  .title-sub {
    text-align: left;
    font-weight: medium;
    font-style: normal;
    font-size: 20px;
    letter-spacing: 0px;
    color: #95aac9;
    margin-top: 20px;
  }
`;
