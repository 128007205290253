import api from 'services/api';

export const getInfoCards = async params => {
  const res = await api.get(`home/fatores-risco/cards`, { params });
  return res.data;
};

export const getFatoresRiscoMonth = async params => {
  const res = await api.get(`home/fatores-risco/aumento-mes`, { params });
  return res.data;
};

export const getFatoresRisco = async params => {
  const res = await api.get(`/home/fatores-risco/principais`, { params });
  return res.data;
};

export const getFatoresRiscoGraves = async params => {
  const res = await api.get(`/home/fatores-risco/graves`, { params });
  return res.data;
};

export const getFatoresDiasdaSemana = async params => {
  const res = await api.get(`/home/fatores-risco/desvios-dia-semana`, {
    params,
  });
  return res.data;
};

export const getFatoresRiscoPeriodoDia = async params => {
  const res = await api.get(`/home/fatores-risco/periodo-dia`, { params });
  return res.data;
};

export const getFiliais = async params => {
  const res = await api.get(`/home/fatores-risco/filiais`, {
    params,
  });
  return res.data;
};

export const getClients = async params => {
  const res = await api.get(`/home/fatores-risco/clientes`, {
    params,
  });
  return res.data;
};

export const getMotoristaMaisOfensores = async params => {
  const res = await api.get(`/home/fatores-risco/ofensores`, {
    params,
  });
  return res.data;
};

export const getRadarDeRisco = async params => {
  const res = await api.get(`/home/fatores-risco/radar`, {
    params,
  });
  return res.data;
};

export const getVencimentosProvider = async params => {
  const res = await api.get(`/home/fatores-risco/vencimentos`, {
    params,
  });
  return res.data;
};

export const getCriticidadeProvider = async params => {
  const res = await api.get(`/home/fatores-risco/criticidade`, {
    params,
  });
  return res.data;
};
