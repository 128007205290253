export const columnsSmall = [
  {
    header: 'Empresa',
    id: 'nome',
    type: 'string',
    sort: true,
    align: 'center',
    picture: 'logo_url',
  },
  {
    header: 'Concluidos (%)',
    id: 'percentagem_conclusao',
    type: 'number',
    sort: true,
    align: 'center',
    value: val => `${val}%`,
    conditional: [
      {
        condition: () => true,
        style: theme => ({
          color: theme.palette.semantics.feedback.success.natural,
          backgroundColor: theme.palette.semantics.feedback.success.light,
          textAlign: 'center',
        }),
      },
    ],
  },
  {
    header: 'Aprovados (%)',
    id: 'percentagem_aprovados',
    type: 'number',
    sort: true,
    align: 'center',
    value: val => `${val}%`,
    conditional: [
      {
        condition: () => true,
        style: theme => ({
          color: theme.palette.semantics.feedback.success.natural,
          backgroundColor: theme.palette.semantics.feedback.success.light,
          textAlign: 'center',
        }),
      },
    ],
  },
  {
    header: 'Alunos',
    id: 'alunos',
    type: 'number',
    sort: false,
    align: 'center',
    value: val => val.length,
  },
  {
    header: 'Respostas',
    id: 'respostas',
    type: 'number',
    sort: true,
    align: 'center',
  },
  {
    header: 'Reprovados',
    id: 'reprovados',
    type: 'number',
    sort: true,
    align: 'center',
  },
];
