import api from 'services/api';

export const getStats = async (id, params) => {
  const res = await api.get(`/equipe-tempo-medio/${id}`, { params });
  if (res.status === 200) {
    return res.data?.data;
  }
  throw res.data;
};

export const getUltimaRevisao = async (id, params) => {
  const res = await api.get(`/equipe-ultima-revisao/${id}`, { params });
  if (res.status === 200) {
    return res.data?.data;
  }
  throw res.data;
};

export const getMovimentacoes = async (id, params) => {
  const res = await api.get(`/equipe-movimentacoes/${id}`, { params });
  if (res.status === 200) {
    return res.data?.data;
  }
};

export const getRevisoes = async (id, params) => {
  const res = await api.get(`/equipe-revisoes-exclusao/${id}`, { params });
  if (res.status === 200) {
    return res.data?.data;
  }
};

export const getAtividades = async (id, params) => {
  const res = await api.get(`/equipe-atividades/${id}`, { params });
  if (res.status === 200) {
    return res.data?.data;
  }
  throw res.data;
};

export const getHistorico = async (id, params) => {
  const res = await api.get(`/equipe-historico-tempo-medio/${id}`, { params });
  if (res.status === 200) {
    return res.data?.data;
  }
};

export const getHistoricoTempoRevisao = async (id, params) => {
  const res = await api.get(`/equipe-historico-tempo-medio-tipo/${id}`, {
    params,
  });
  if (res.status === 200) {
    return res.data?.data;
  }
};

export const getJustificativas = async (id, params) => {
  const res = await api.get(`/equipe-justifica-exclusao`, { params });
  if (res.status === 200) {
    return res.data?.data;
  }
  throw res.data;
};

export const exportDepenhoDoUsuarioTorrePlus = async params => {
  const res = await api.get(`/excel/desempenho_do_usuario`, { params });
  if (res.status === 200) {
    return res.data;
  }
  throw res.data?.data;
};

export const exportDepenhoDoUsuarioTorre = async params => {
  const res = await api.get(`/excel/desempenho_do_usuario_torre`, { params });
  if (res.status === 200) {
    return res.data;
  }
  throw res.data?.data;
};
