import styled from 'styled-components';
import colors from 'styles/colors';

export const Container = styled.div`
  padding: 10px 20px;
  border-bottom: ${props =>
    props.noBorder ? 'none' : `1px solid ${colors.greyTiny}`};
`;

export const Question = styled.div`
  text-transform: lowercase;
  font-weight: 600;
  color: ${colors.greyTitle};
  font-size: 18px;
  margin-top: 5px;
  word-break: break-all;

  &::first-letter {
    text-transform: uppercase;
  }
`;

export const ResponsesBox = styled.div`
  margin-top: 5px;
  padding-bottom: 10px;
`;

export const Response = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  font-weight: 500;
  color: ${colors.greyTitle};
  word-break: break-all;
  margin-top: 10px;
`;
