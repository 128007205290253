import api from 'services/api';

export const requireInfos = async () => {
  const res = await api.get('/configuracoes/clientes');
  return res.data;
};

export const selectClients = async () => {
  const res = await api.get('/configuracoes/distribuidorasAll');
  return res.data;
};

export const updateClientes = async data => {
  const res = await api.put('/configuracoes/relacaoDistribuidora', data);
  return res.data;
};

export const createClientes = async data => {
  const res = await api.post('/configuracoes/verificacaoDistribuidora', data);
  return res.data;
};

export const addNewCliente = async data => {
  const res = await api.post('/client-add', data);
  return res.data;
};

export const reenviarConvite = async id => {
  const res = await api.post(`/client-add/${id}/resend/`);
  return res.data;
};

export const deleteConvite = async id => {
  const res = await api.delete(`/client-remove/${id}`);
  return res.data;
};
