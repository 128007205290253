import styled, { css } from 'styled-components';

export const PageWrapper = styled.div`
  width: 100%;
  display: flex;
`;

export const FormWrapper = styled.div`
  margin: 5px 0;
`;

export const PageContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    height: 100%;
    flex-direction: column;
    div > &:first-child {
      display: flex;
      flex-direction: column;
      h1 {
        font-size: 38px;
        color: ${theme.palette.words.title.natural};
        font-weight: 600;
      }
      p {
        color: ${theme.palette.brand.primary.natural};
        font-weight: 400;
        font-size: 1.2em;
      }
    }
  `}
`;
