import { AddCircleOutline } from '@mui/icons-material';
import DefaultButton from 'components/Buttons/Default';
import * as S from './styled';

import Loading from '../Loading';

const Empty = ({
  titleButton,
  query,
  setModalAdd,
  inSearch,
  width = '400px',
}) => {
  const img = require(`images/empty/desvio.png`);

  const formatEmptyText = () => {
    if (query.search && query.tag !== 'Todos') {
      return (
        <h3>{`Ops! Nenhum conteúdo "${query.search}" em: "${query.tag}".`}</h3>
      );
    }
    if (query.search) {
      return <h3>{`Ops! Nenhum conteúdo "${query.search}".`}</h3>;
    }
    if (query.tag) {
      return <h3>{`Ops! Nenhum conteúdo em: "${query.tag}".`}</h3>;
    }
    return <h3>Ops! você não tem nenhum conteúdo disponível.</h3>;
  };

  return (
    <S.Container width={width}>
      {!inSearch && (
        <>
          <S.ImageArea>
            <img src={img} alt="Conteúdo vazio" />
          </S.ImageArea>
          <S.ContentArea>
            <span className="textArea">
              {formatEmptyText()}
              <h5>
                Verifique o filtro aplicado ou clique em "Novo conteúdo" e
                comece agora mesmo!
              </h5>
            </span>
            {setModalAdd && (
              <span className="buttonArea">
                <DefaultButton
                  children={titleButton}
                  onClick={() => setModalAdd(true)}
                  startIcon={<AddCircleOutline />}
                />
              </span>
            )}
          </S.ContentArea>
        </>
      )}
      {inSearch && <Loading />}
    </S.Container>
  );
};

export default Empty;
