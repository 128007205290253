import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { SaveAlt } from '@mui/icons-material';
import { Divider } from '@mui/material';
import Tabs from 'components/Tabs';
import { trackEvent } from 'utils/mixpanel';
import { downloadFromLink } from 'utils/helpers';

import { usePlans } from 'hooks/usePlans';
import Header from 'pages/_templates/ListPage/components/Header';

import { requestExcel } from './ListAcoes/services';
import { fields, fieldsProvider } from './ListAcoes/constants';

import { setFilterProvider } from 'store/modules/provider/filterAcoesSuspensoesProvider/actions';

import Empresas from './Empresas';
import { fieldsEmpresas } from './Empresas/constants';
import { requestExcelEmpresas } from './Empresas/services';
import ListAcoes from './ListAcoes';

const AcoesSuspensoes = ({ viewEmpresas }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id: idEmpresa } = useParams();
  const { isProvider, isOpLogistico } = usePlans();
  const user = useSelector(state => state.auth?.user?.user);
  const filter = useSelector(state =>
    isProvider ? state.filterProvider : state.filter,
  );

  const empresas = useSelector(state => state.selects.empresas);

  const [title, setTitle] = useState('Ações e Suspensões');
  const [query, setQuery] = useState(null);

  const [resetTable, setResetTable] = useState(false);
  const [loadingExcel, setLoadingExcel] = useState(false);

  // Tabs de operador logístico: controla o componente exibido
  const headerTabsView = {
    value: viewEmpresas ? 'TERCEIROS' : 'PROPRIOS',
    items: [
      {
        value: 'PROPRIOS',
        label: 'Minhas frota',
      },
      {
        value: 'TERCEIROS',
        label: 'Frota terceira',
      },
    ],
    onChange: (_, newValue) =>
      navigate(
        newValue === 'TERCEIROS'
          ? '/acoes-suspensoes/empresas'
          : '/acoes-suspensoes',
      ),
  };

  // Set filter by queryparams
  useEffect(() => {
    if (isProvider) {
      const newfilter = {};
      const empresa = idEmpresa ? empresas.find(e => e.id == idEmpresa) : null;

      if (idEmpresa && empresa) {
        newfilter.empresa = idEmpresa;
        setTitle(empresa.nome);
        dispatch(setFilterProvider(newfilter));
        if (!viewEmpresas) setResetTable(true);
      } else if (!idEmpresa && isOpLogistico) {
        setTitle('Ações e Suspensões');
        newfilter.empresa = '';
        dispatch(setFilterProvider(newfilter));
        if (!viewEmpresas) setResetTable(true);
      } else if (!isOpLogistico) {
        setTitle('Ações e Suspensões');
        navigate('/acoes-suspensoes/empresas');
      }
    }
  }, [idEmpresa, isProvider, isOpLogistico]);

  // request de exportação
  const handleRequestExcel = useCallback(async () => {
    setLoadingExcel(true);
    const newQuery = {
      ...query,
      excelFields: isProvider ? fieldsProvider : fields,
    };
    const res = await requestExcel(newQuery);
    if (res.link) {
      downloadFromLink(res.link);
      toast.success(res.message);
    } else if (res.message) toast.error(res.message);

    setLoadingExcel(false);
  }, [query]);

  // request de exportação empresas
  const handleRequestExcelEmpresas = async () => {
    setLoadingExcel(true);
    const newQuery = {
      ...filter,
      excelFields: fieldsEmpresas,
    };

    const res = await requestExcelEmpresas(newQuery);
    if (res.link) {
      downloadFromLink(res.link);
      toast.success(res.message);
    } else if (res.message) toast.error(res.message);

    setLoadingExcel(false);
  };

  // açoes do header
  const headerActions = useMemo(
    () => [
      {
        title: 'Exportar',
        variant: 'ghost',
        icon: <SaveAlt />,
        function: () => {
          trackEvent(user, 'EXPORTAR PLANILHA');
          viewEmpresas ? handleRequestExcelEmpresas() : handleRequestExcel();
        },
        loading: loadingExcel,
      },
    ],
    [
      user,
      loadingExcel,
      viewEmpresas,
      handleRequestExcel,
      handleRequestExcelEmpresas,
    ],
  );

  return (
    <>
      <Header
        title={title}
        subtitle={isProvider && idEmpresa ? 'Filial: Todas' : ''}
        actions={headerActions}
      />

      {isProvider && isOpLogistico && !idEmpresa && (
        <>
          <Tabs {...headerTabsView} />
          <Divider />
        </>
      )}

      {viewEmpresas ? (
        <Empresas />
      ) : (
        <ListAcoes
          query={query}
          setQuery={setQuery}
          resetTable={resetTable}
          setResetTable={setResetTable}
        />
      )}
    </>
  );
};

export default AcoesSuspensoes;
