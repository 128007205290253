import * as types from './types';

export function setQueue(newQueue) {
  return {
    type: types.SET_QUEUE,
    payload: { queue: newQueue },
  };
}

export function setConcluded(concluded) {
  return {
    type: types.SET_CONCLUDED,
    payload: { concluded },
  };
}
