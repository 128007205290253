import styled, { css } from 'styled-components';

export const Box = styled.div`
  ${({ theme, hasError }) => css`
    width: 100%;
    background-color: ${theme.palette.system.overlay};
    border: 2px solid ${theme.palette.system.border};
    border-radius: 4px;
    padding: 30px;
    margin-top: 20px;

    .title {
      font-size: 17px;
      font-weight: 600;
      color: ${theme.palette.words.subtitle.natural};
    }

    .container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
      margin-top: 20px;

      .boxPayment {
        display: flex;
        flex-direction: column;
        justify-content: end;

        span {
          color: ${theme.palette.words.subtitle.natural};
        }

        .InputTextLibary {
          margin-top: 10px;
          border: 2px solid
            ${hasError
              ? theme.palette.semantics.feedback.attention.natural
              : theme.palette.brand.primary.light};
          padding: 15px 15px;
          border-radius: 5px;

          &:hover {
            border: 2px solid
              ${hasError
                ? theme.palette.semantics.feedback.attention.natural
                : theme.palette.system.black};
          }

          &:focus {
            border: 2px solid
              ${hasError
                ? theme.palette.semantics.feedback.attention.natural
                : theme.palette.brand.secondary.natural};
          }
        }
      }
    }

    .unicSelect {
      display: flex;
      width: 100%;
      gap: 10px;

      .little {
        flex: 1;
      }

      .bigger {
        flex: 6;
      }
    }

    .request {
      margin-top: 30px;

      .idxRequest {
        font-weight: 600;
        color: ${theme.palette.words.subtitle.natural};
        margin-right: 20px;
      }

      .boxRequest {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
      }

      .buttonBox {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .empty {
          font-weight: 500;
          color: ${theme.palette.words.text.light};
          font-size: 16px;
          opacity: 0.8;
        }
      }
    }
  `}
`;

export const LoadingBox = styled.div`
  position: relative;
  min-height: 500px;
`;
