import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

// style
import { useTheme } from 'styled-components';

// components
import Avatar from '@mui/material/Avatar';

import * as S from './style';

const HeaderSidebarInter = ({ nome, filial, foto }) => {
  const theme = useTheme();
  return (
    <div>
      <S.containerBanner>
        <div className="avatar">
          <Avatar
            sx={{
              width: 68,
              height: 68,
              fontSize: 26,
              border: `2px solid ${theme.palette.system.overlay}`,
            }}
            alt={nome}
            src={foto}
          />
          <h2>{nome}</h2>
          <label>{filial}</label>
        </div>
      </S.containerBanner>
    </div>
  );
};

export default HeaderSidebarInter;
