import React, { useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';

import { FileDownloadOutlined } from '@mui/icons-material';
import Loading from 'components/Loading';
import GhostButton from 'components/Buttons/Ghost';
import { ReactComponent as HistoricoIcon } from 'images/icons/sidebar/historico.svg';
import { ExportToPdf } from './Export';
import Config from './Config';
import Info from './Info';
import { Container, HeaderActions, SpacedRow } from './styled';
import { getAcidente } from '../services';
import { Grid } from '@mui/material';
import { NextPrev } from './NextPrev';

export const DetalheAcidente = () => {
  const params = useParams();
  const navigate = useNavigate();
  const printRefPdf = useRef();
  const [loadingPdf, setLoadingPdf] = useState(false);

  const {
    data: acidente,
    isFetching,
    refetch,
  } = useQuery(['acidente', params.id], () => getAcidente(params.id), {
    onError: error => toast.error(error),
    refetchOnWindowFocus: false,
  });

  const handlePrint = useReactToPrint({
    content: () => printRefPdf.current,
    onBeforeGetContent: () => setLoadingPdf(true),
    onAfterPrint: () => {
      setLoadingPdf(false);
    },
    copyStyles: true,
    documentTitle: `Acidente_${acidente?.id}`,
  });

  return (
    <>
      {isFetching ? (
        <Loading />
      ) : (
        <>
          <Container>
            <SpacedRow>
              <h1>Detalhes do acidente</h1>
              <HeaderActions>
                <GhostButton
                  startIcon={<FileDownloadOutlined />}
                  size="medium"
                  onClick={handlePrint}
                  loading={loadingPdf}
                >
                  IMPRIMIR
                </GhostButton>
                <GhostButton
                  startIcon={<HistoricoIcon />}
                  size="medium"
                  onClick={() => navigate(`/logs/acidentes/${params.id}`)}
                >
                  HISTÓRICO DE ALTERAÇÕES
                </GhostButton>
                <NextPrev />
              </HeaderActions>
            </SpacedRow>

            <Grid container spacing="20px" marginTop="10px">
              <Grid item xs={12} sm={12} md={6}>
                <Info acidente={acidente} />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <Config acidente={acidente} />
              </Grid>
            </Grid>
          </Container>
          <ExportToPdf acidente={acidente} printRef={printRefPdf} />
        </>
      )}
    </>
  );
};
