/* eslint-disable import/order */

// Styles
import * as S from './styled';

// React
import { useSelector } from 'react-redux';
import { useEffect, useState, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// Libs
import qs from 'qs';

// Components
import Loading from 'components/Loading';
import DefaultButton from 'components/Buttons/Default';

// Constants
import { homes } from './constants';
import { useConstants } from 'constants/use';

const Redirect = ({ virtualDom }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [notFound, setNotFound] = useState(false);

  const img = require(`images/alert_not_found.png`);

  const user = useSelector(state => state.auth.user?.user);
  const { pageMap } = useSelector(state => state.pageMap);
  const { _PRIVATE } = useMemo(
    () => useConstants(pageMap, user),
    [pageMap, user],
  );

  const checkRoute = (page, key) => {
    const excludes = ['?', ':'];

    const pure = route => {
      return route
        .split('/')
        .map(part =>
          excludes.some(item => part.includes(item)) || +part ? '' : part,
        )
        .join('/');
    };

    const pagePure = pure(page);
    const keyPure = pure(key);
    return pagePure === keyPure;
  };

  useEffect(() => {
    if (!user) navigate('/login', { replace: true });
    if (!virtualDom) return;

    const path = location.pathname;
    const auth = location.search?.includes('auth');
    const homePath = homes.find(home => path === home.url);

    if (homePath && auth) {
      const home = homes.map(home => home.url);
      const redirect = Object.keys(_PRIVATE).find(key => home.includes(key));
      navigate(redirect, { replace: true });
      return;
    }

    pageMap?.forEach(env => {
      if (!env?.active) {
        env?.paginas?.forEach(pagina => {
          if (checkRoute(path, pagina.pagina) && pagina.pagina.length > 3) {
            navigate(
              `/upgradePlano?${qs.stringify({ titulo: env?.descricao })}`,
              { replace: true },
            );
          }
        });
      }
    });

    setNotFound(true);
  }, [pageMap, virtualDom]);

  // ------------------------------------RENDER-----------------------------------------//
  if (!pageMap) return <Loading />;
  if (!notFound) return null;
  return (
    <S.Main>
      <S.Card>
        <S.Alert src={img} />
        <h1>Página não encontrada</h1>

        <h2>A página que você está buscando foi movida ou não existe mais.</h2>

        <DefaultButton size="large" onClick={() => navigate('/')} width="100%">
          VOLTAR À HOME
        </DefaultButton>
      </S.Card>
    </S.Main>
  );
};

export { Redirect };
