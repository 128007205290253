import React from 'react';
import * as S from './styled';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import { Close } from '@mui/icons-material';

export const Item = ({
  text,
  first,
  index,
  infractions,
  disabled = false,
  selected = false,
  onClick,
}) => {
  return (
    <S.Container
      first={first}
      disabled={disabled}
      onClick={() => !!infractions && onClick()}
    >
      <div className="text">
        <div className="name" title={text}>
          #{index} {text}
        </div>
        <div className="desvios">{infractions} desvios</div>
      </div>
      {selected ? <Close /> : <KeyboardArrowRightIcon />}
    </S.Container>
  );
};
