import React from 'react';

import * as S from './styled';

export const Nivel = ({ nivel, onClick, selected = false }) => {
  const img = require(`images/usuario/${nivel.icone ?? 'analista.svg'}`);
  return (
    <S.Container onClick={onClick} selected={selected}>
      <S.Radio selected={selected} />
      <S.Title>{nivel.titulo}</S.Title>
      <S.Image>
        <img src={img} alt="logo" />
      </S.Image>
      <S.Description>{nivel.descricao}</S.Description>
    </S.Container>
  );
};
