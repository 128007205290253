import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const InfoContainer = styled.div`
  padding-left: 0.5rem;
`;

export const Title = styled.div`
  font: normal normal medium 11px/16px Texta;
  color: #4b5166;
`;

export const Text = styled.div`
  font: normal normal 900 16px/16px Texta;
  color: #4b5166;
`;
