import {
  getExecutores,
  getBases,
  getTamanhos,
  getStates,
  getTypeClientIp,
} from 'constants/_SERVICES/provider';

// Pages
import CaeDetail from 'pages/LayoutDescarga/Cae/Detalhe';
import LayoutPdf from 'pages/LayoutDescarga/Config/ModelPdf';
import LayoutDescargaProvider from 'pages/Provider/LayoutDescarga';
import ConfigLayoutProvider from 'pages/Provider/LayoutDescarga/Config';

export default {
  '/layout': {
    defaults: ['empresas'],
    pageFilterName: 'filterLayoutProvider',
    filters: [
      {
        name: 'tipo_cliente',
        placeholder: 'Filtrar por Tipo de Cliente',
        mode: 'single',
        data: async () => getTypeClientIp(),
        visibility: {
          provider: [4],
        },
      },
      {
        name: 'estado',
        placeholder: 'Filtrar por Estado',
        mode: 'single',
        data: async () => getStates(),
      },
      {
        name: 'executor',
        placeholder: 'Filtrar por Executor',
        mode: 'multiple',
        data: async () => getExecutores(),
      },
      {
        name: 'base',
        placeholder: 'Filtrar por Base',
        mode: 'multiple',
        data: async () => getBases(),
      },
      {
        name: 'tmc',
        placeholder: 'Filtrar por Tam Max',
        mode: 'single',
        data: async () => getTamanhos(),
      },
    ],
    page: <LayoutDescargaProvider />,
  },

  '/layout/:id': {
    back: -1,
    title: 'Layout',
    id: true,
    status: true,
    defaults: [],
    page: <ConfigLayoutProvider />,
  },

  '/layout/pdf/:id': {
    back: -1,
    title: 'Layout',
    id: true,
    defaults: [],
    page: <LayoutPdf />,
  },

  '/cae/:id': {
    back: -1,
    title: 'Cae',
    id: true,
    defaults: [],
    page: <CaeDetail />,
  },
};
