import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// Componentes
import BarGraph from 'components/Graphs/BarGraph';
import LineGraph from 'components/Graphs/LineGraph';
import Tab from 'components/Tabs';

// Service
import { toast } from 'react-toastify/dist';
import { Grid } from '@mui/material';
import { requestLayoutProvider } from '../services';
import * as S from './styled';

const historicoTabs = [
  { value: 'geral', label: 'GERAL' },
  { value: 'base', label: 'POR BASE' },
];

const GraficosLayout = () => {
  const filter = useSelector(state => {
    return state.filter;
  });

  const [historico, setHistorico] = useState(null);
  const [historicoTab, setHistoricoTab] = useState('geral');
  const [historicoAxis, setHistoricoAxis] = useState(['month']);
  const [historicoLoading, setHistoricoLoading] = useState(true);

  const [tempMedio, setTempMedio] = useState(null);
  const [tempMedioAxis, setTempMedioAxis] = useState(['month']);
  const [temploading, setTempLoading] = useState(true);

  const [tipo, setTipo] = useState(null);
  const [tipoLoading, setTipoLoading] = useState(true);

  const [status, setStatus] = useState(null);
  const [statusLoading, setStatusLoading] = useState(true);

  const [posto, setPosto] = useState(null);
  const [postoLoading, setPostoLoading] = useState(true);

  const fetchHistorico = async query => {
    setHistoricoLoading(true);
    const res = await requestLayoutProvider('1', query);
    if (res.data?.data) {
      setHistorico(res.data.data.caes);
      setHistoricoAxis(res.data.data.series);
    } else if (res.data?.message) toast.error(res.data.message);
    setHistoricoLoading(false);
  };

  const fetchTempMedio = async query => {
    setTempLoading(true);
    const res = await requestLayoutProvider('2', query);
    if (res.data?.data) {
      setTempMedio(res.data.data.data);
      setTempMedioAxis(res.data.data.series);
    } else if (res.data?.message) toast.error(res.data.message);
    setTempLoading(false);
  };

  const fetchTipo = async query => {
    setTipoLoading(true);
    const res = await requestLayoutProvider('3', query);
    if (res.data?.data) setTipo(res.data.data);
    else if (res.data?.message) toast.error(res.data.message);
    setTipoLoading(false);
  };

  const fetchStatus = async query => {
    setStatusLoading(true);
    const res = await requestLayoutProvider('4', query);
    if (res.data?.data) setStatus(res.data.data);
    else if (res.data?.message) toast.error(res.data.message);
    setStatusLoading(false);
  };

  const fetchPosto = async query => {
    setPostoLoading(true);
    const res = await requestLayoutProvider('5', query);
    if (res.data?.data) setPosto(res.data.data);
    else if (res.data?.message) toast.error(res.data.message);
    setPostoLoading(false);
  };

  useEffect(() => {
    const query = { ...filter, tip_tab: historicoTab };
    fetchHistorico(query);
  }, [filter.filial, historicoTab]);

  useEffect(() => {
    fetchTempMedio(filter);
    fetchTipo(filter);
    fetchStatus(filter);
    fetchPosto(filter);
  }, [filter.filial]);

  const renderHistoryGraph = () => (
    <div style={{ marginTop: '20px', position: 'relative' }}>
      <LineGraph
        data={historico}
        colors="critical"
        title="Histórico de CAEs abertas"
        series={historicoAxis}
        legend={historicoAxis}
        loading={historicoLoading}
        tooltip
      />
      <div style={{ position: 'absolute', top: '15px', right: '25px' }}>
        <Tab
          width="220px"
          value={historicoTab}
          items={historicoTabs}
          onChange={(e, value) => setHistoricoTab(value)}
        />
      </div>
    </div>
  );

  const renderTempMedioGraph = () => (
    <div style={{ marginTop: '20px', position: 'relative' }}>
      <LineGraph
        data={tempMedio}
        colors="critical"
        title="Histórico tempo médio conclusão"
        series={tempMedioAxis}
        loading={temploading}
        tooltip
      />
    </div>
  );

  const renderTipoGraph = () => (
    <div style={{ marginTop: '20px' }}>
      <BarGraph
        data={tipo}
        colors="default"
        title="CAEs por tipo"
        loading={tipoLoading}
        tooltip
      />
    </div>
  );

  const renderStatusGraph = () => (
    <div style={{ marginTop: '20px' }}>
      <BarGraph
        data={status}
        colors="default"
        title="CAEs por status"
        loading={statusLoading}
        tooltip
      />
    </div>
  );

  const renderPostoGraph = () => (
    <div style={{ marginTop: '20px' }}>
      <BarGraph
        data={posto}
        colors="default"
        title="Status por posto"
        loading={postoLoading}
        tooltip
      />
    </div>
  );

  const renderMain = () => (
    <>
      <S.Container>
        <S.Header>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '15px',
            }}
          >
            <S.Title>Dashboard Layout de Descarga</S.Title>
          </div>
        </S.Header>
        <S.Main>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} xl={6}>
              {renderHistoryGraph()}
            </Grid>

            <Grid item xs={12} md={6} xl={6}>
              {renderTempMedioGraph()}
            </Grid>

            <Grid item xs={12} md={6} xl={6}>
              {renderTipoGraph()}
            </Grid>

            <Grid item xs={12} md={6} xl={6}>
              {renderStatusGraph()}
            </Grid>

            <Grid item xs={12} md={6} xl={6}>
              {renderPostoGraph()}
            </Grid>
          </Grid>
        </S.Main>
      </S.Container>
    </>
  );

  return renderMain();
};

export default GraficosLayout;
