const columnsList = [
  {
    Header: 'Id',
    id: 'id',
    accessor: d => Number(d.id),
    sortType: 'basic',
    show: true,
    disableSortBy: false,
    centered: true,
  },
  {
    Header: 'Empresa',
    id: 'empresa',
    accessor: d => String(d.empresa),
    sortType: 'basic',
    show: true,
    disableSortBy: false,
    centered: false,
  },
  {
    Header: 'Status',
    id: 'status',
    accessor: d => String(d.status),
    show: true,
    disableSortBy: false,
    centered: true,
  },
  {
    Header: 'Usuários',
    id: 'usuarios',
    accessor: d => Number(d.usuarios),
    show: true,
    disableSortBy: false,
    centered: true,
  },
  {
    Header: 'Filiais',
    id: 'filiais',
    accessor: d => Number(d.filiais),
    show: true,
    disableSortBy: false,
    centered: true,
  },
];

const columnsTurmas = [
  {
    Header: 'Id',
    id: 'id',
    accessor: d => Number(d.id),
    sortType: 'basic',
    show: true,
    disableSortBy: false,
    centered: true,
  },
  {
    Header: 'Nome',
    id: 'nome',
    accessor: d => String(d.nome),
    sortType: 'basic',
    show: true,
    disableSortBy: false,
    centered: false,
  },
  {
    Header: 'Tipo',
    id: 'tipo',
    accessor: d => String(d.tipoUser),
    show: true,
    disableSortBy: false,
    centered: true,
  },
  {
    Header: 'Status',
    id: 'ativa',
    accessor: d => String(d.ativa),
    show: true,
    disableSortBy: false,
    centered: true,
  },
  {
    Header: 'Alunos',
    id: 'n_alunos',
    accessor: d => Number(d.n_alunos),
    show: true,
    disableSortBy: false,
    centered: true,
  },
  {
    Header: 'Ações',
    id: 'acoes',
    accessor: 'acoes',
    show: true,
    disableSortBy: true,
  },
];

export const columnsView = [
  {
    Header: 'Id',
    id: 'id',
    accessor: d => Number(d.id),
    sortType: 'basic',
    show: true,
    disableSortBy: false,
  },
  {
    Header: 'Nome',
    id: 'nome',
    accessor: d => String(d.nome),
    sortType: 'basic',
    show: true,
    disableSortBy: false,
  },
  {
    Header: 'Tipo',
    id: 'tipo',
    accessor: d => String(d.tipo),
    show: true,
    disableSortBy: false,
    centered: true,
  },
  {
    Header: 'Função',
    id: 'funcao',
    accessor: d => d.funcao,
    show: true,
    disableSortBy: false,
  },
  {
    Header: 'Filial',
    id: 'filial',
    accessor: d => String(d.filial.nome),
    show: true,
    disableSortBy: false,
  },
  {
    Header: 'Ações',
    id: 'acoes',
    accessor: 'acoes',
    show: true,
    disableSortBy: true,
  },
];

export { columnsTurmas, columnsList };
