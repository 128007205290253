import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { startOfMonth, subHours, subMonths } from 'date-fns';

import Tabs from 'components/Tabs';
import { PeriodPicker } from 'pages/Estatisticas/Components/PeriodPicker';
import { LineGraph } from './LineGraph';

import * as S from './styled';
import { getHistoricoTempoRevisao } from '../../services';

const tabs = [
  {
    value: 'EXCLUSAO',
    label: 'Exclusão de desvio',
  },
  {
    value: 'OPERACAO',
    label: 'Troca de operação',
  },
];

export const TempoMedioRevisao = ({ id }) => {
  const [filter, setFilter] = useState({
    finalDate: new Date(),
    initialDate: startOfMonth(subMonths(new Date(), 11)),
    userId: id,
    tipo: 'EXCLUSAO',
  });

  const { isLoading: isLoadingHistorico, data: historico = {} } = useQuery(
    ['equipe-historico-revisao', id, filter],
    () => getHistoricoTempoRevisao(id, filter),
    {
      refetchOnWindowFocus: false,
    },
  );

  return (
    <S.Card>
      <S.Container flex={2}>
        <S.Flex className="justify-between mb-05">
          <S.Title>Tempo médio de revisões</S.Title>
          <PeriodPicker
            finalDate={filter.finalDate}
            initialDate={filter.initialDate}
            period="lastYear"
            periodAdd="month"
            onChange={date => {
              setFilter(state => ({
                ...state,
                initialDate: date.initialDate,
                finalDate: subHours(date.finalDate, 3),
              }));
            }}
          />
        </S.Flex>
        <Tabs
          value={filter.tipo}
          items={tabs}
          onChange={(_, value) =>
            setFilter(state => ({
              ...state,
              tipo: value,
            }))
          }
        />
        <LineGraph
          height={240}
          data={
            historico?.values?.map(value => ({
              ...value,
              meta: historico?.meta ?? 0,
            })) ?? []
          }
          loading={isLoadingHistorico}
        />
      </S.Container>
    </S.Card>
  );
};
