import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import Tabs from 'components/Tabs';
import { Divider } from '@mui/material';
import { SaveAlt } from '@mui/icons-material';

import Header from 'pages/_templates/ListPage/components/Header';
import ListaFaixas from '../Components/ListaFaixas';
import { getFaixas, requestExcelFaixas } from '../services';
import { trackEvent } from 'utils/mixpanel';
import { downloadFromLink } from 'utils/helpers';
import { fields } from '../constants';

const ConfigAcoesSuspensoesClientes = () => {
  const user = useSelector(state => state.auth?.user?.user);
  const clients = useSelector(state => state.selects.clients);

  const [tab, setTab] = useState(clients[0]?.id || 0);
  const [queryFaixas, setQueryFaixas] = useState({ client: clients[0]?.id });
  const [loadingExcel, setLoadingExcel] = useState(false);

  const [faixaModal, setFaixaModal] = useState({
    open: false,
    data: null,
  });

  const {
    data: resFaixas,
    isFetching: fetchingFaixas,
    refetch: refetchFaixas,
  } = useQuery(
    ['acoes-suspensoes-faxas', queryFaixas],
    () => getFaixas(queryFaixas),
    {
      refetchOnWindowFocus: false,
    },
  );

  const headerTabs = {
    value: tab,
    items: clients.map(client => ({ value: client.id, label: client.nome })),
    onChange: (_, newValue) => setTab(newValue),
    disabled: fetchingFaixas,
  };

  useEffect(() => {
    setQueryFaixas({ client: tab });
  }, [tab]);

  // request de exportação
  const handleRequestExcel = async () => {
    setLoadingExcel(true);
    const res = await requestExcelFaixas({
      client: tab,
      excelFields: fields,
    });
    if (res.link) {
      downloadFromLink(res.link);
      toast.success(res.message);
    } else if (res.message) toast.error(res.message);

    setLoadingExcel(false);
  };

  const headerActions = [
    {
      title: 'Exportar',
      variant: 'ghost',
      icon: <SaveAlt />,
      function: () => {
        trackEvent(user, 'EXPORTAR PLANILHA');
        handleRequestExcel();
      },
    },
  ];

  return (
    <>
      <Header title="Configurações do Cliente" />

      <Tabs {...headerTabs} />
      <Divider />

      <ListaFaixas
        data={resFaixas?.data?.data || []}
        pageCount={resFaixas?.data?.total || 0}
        loading={fetchingFaixas}
        formModal={faixaModal}
        headerActions={headerActions}
        setFormModal={setFaixaModal}
        refetch={refetchFaixas}
        readOnly
        client
      />
    </>
  );
};

export default ConfigAcoesSuspensoesClientes;
