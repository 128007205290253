export const columns = [
  {
    header: 'Motorista',
    id: 'motorista.nome',
    type: 'string',
    sort: true,
    tooltip: true,
    width: 200,
  },
  {
    header: 'Semana',
    id: 'semana',
    type: 'string',
    sort: true,
  },
  {
    header: 'Placa',
    id: 'placas',
    type: 'string',
    sort: true,
  },
  {
    header: 'Enviado com sucesso',
    id: 'enviado_com_sucesso',
    type: 'string',
    sort: true,
    align: 'center',
    width: 200,
  },
  {
    header: 'Avaliação',
    id: 'id_da_avaliacao',
    type: 'number',
    sort: true,
  },
];
