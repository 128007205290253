import mixpanel from 'mixpanel-browser';
import { object } from 'underscore';

// getting mixpanel token
const token =
  process.env.REACT_APP_ENV === 'production'
    ? `${process.env.REACT_APP_MIXPANEL_PROD}`
    : `${process.env.REACT_APP_MIXPANEL_DEV}`;

// set the token to where to send the data
mixpanel.init(token);

// Formata dados de entrada da API
// Padrao de atributos que devem ser passados ao mixpanel
const formatData = userInfo => {
  let data = {
    nome: userInfo.nome,
    email: userInfo.email,
    usuario_id: userInfo.id,
    filial: userInfo.filial?.nome,
    filial_id: userInfo.filial?.id,
    empresa: userInfo.filial?.empresa?.nome,
    empresa_id: userInfo.filial?.empresa.id,
  };
  if (userInfo.provider) {
    data = {
      nome: userInfo.nome,
      email: userInfo.email,
      usuario_id: userInfo.id,
      distribuidora: userInfo.distribuidora?.nome,
      distribuidora_id: userInfo.distribuidora?.id,
    };
  }
  return data;
};

// Função principal de registro de eventos
// Os dados de userInfo são obrigatórios para o registro
const trackEvent = (userInfo, event, time = null, search = null) => {
  if (userInfo) {
    const data = { ...formatData(userInfo), permanencia: time, busca: search };
    mixpanel.track(event, data);
  }
};

const resetId = () => {
  mixpanel.reset();
};

export { resetId, trackEvent };
