/* eslint-disable import/order */

// React
import { useSelector } from 'react-redux';
import { Routes, Route } from 'react-router-dom';
import { memo, useEffect, useState } from 'react';

// Hooks
import { useConstants } from 'constants/use';

// Components
import { Redirect } from './Redirect';

// Firebase
import { auth } from 'utils/firebase';

// Redux - Actions
import { useDispatch } from 'react-redux';
import { updatePageMap } from 'store/modules/pageMap/actions';

const MainRoutes = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state?.auth?.user?.user);
  const map = useSelector(state => state.pageMap.pageMap);
  const uid = user?.firebase_id;

  const [_PUBLICS, _SET_PUBLICS] = useState({});
  const [_PRIVATES, _SET_PRIVATES] = useState({});
  const _PAGES = { ..._PUBLICS, ..._PRIVATES };

  useEffect(() => {
    const { _PRIVATE, _PUBLIC } = useConstants(map, user);
    _SET_PRIVATES(_PRIVATE);
    _SET_PUBLICS(_PUBLIC);
  }, [map, user]);

  useEffect(() => {
    auth.onAuthStateChanged(user => {
      user &&
        user?.uid === uid &&
        dispatch(updatePageMap(new Date().toISOString()));
    });
  }, [uid]);

  if (!Object.keys(_PAGES)?.length) return <></>;

  return (
    <Routes>
      {Object.keys(_PAGES).map(key => (
        <Route key={key} path={key} element={_PAGES[key]?.page} />
      ))}
      <Route
        path="*"
        element={<Redirect virtualDom={Object.keys(_PRIVATES).length} />}
      />
    </Routes>
  );
};

export default memo(MainRoutes);
