import styled, { css } from 'styled-components';
import { Button } from '@mui/material';

export const Container = styled.div`
  visibility: ${({ hidden }) => (hidden ? 'hidden' : 'visible')};
  height: ${({ hidden }) => (hidden ? '0' : '')};
  overflow: ${({ hidden }) => (hidden ? 'hidden' : 'unset')};
  display: grid;

  margin-top: 20px;
  .grid {
    display: grid;
    grid-template-columns: 0.45fr 3fr;
    height: 100%;
    gap: 20px;
  }

  .item-grid {
    display: grid;
    grid-template-rows: 1fr 1fr;
    gap: 20px;
  }

  .sub-item {
  }

  .sub-item-grid {
    display: grid;
    grid-template-columns: 2fr 2fr;
    gap: 20px;
  }

  .inner-item-grid {
    display: grid;
    //grid-template-rows: 1fr 1fr;
    gap: 20px;
  }

  .line-item-grid {
    display: grid;
    grid-template-columns: 1.6fr 1fr;
    gap: 20px;
  }

  .column-item {
    padding: 5px;
  }

  .style-container {
    border: 1px solid #95aac94d;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: white;
  }
  .container-bar-grafico {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 30px;
  }
  .container-centered-column {
    display: flex;
    flex-direction: column;
    //align-items: center;
    padding-left: 30px;
    .title {
      font-size: 24px;
      font-weight: 900;
      letter-spacing: 0px;
      color: #4b5166;
      margin-top: 20px;
    }
    .sub-title {
      text-align: left;
      font-size: 20px;
      font-weight: medium;
      letter-spacing: 0px;
      color: #4b5166;
    }
    .sub-title > span {
    }
    .sub-container {
      height: 106px;
      border-left: 3px solid #95aac94d;
      border-radius: 4px;
      padding: 8px;
      margin-top: 16px;
      .text1 {
        text-align: left;
        font-size: 16px;
        font-weight: medium;
        letter-spacing: 0px;
        color: #4b5166;
      }
      .text2 {
        text-align: left;
        font-size: 20px;
        font-weight: 900;
        letter-spacing: 0px;
        color: #4b5166;
      }
    }
  }
`;

export const ContainerProvider = styled.div`
  visibility: ${({ hidden }) => (hidden ? 'hidden' : 'visible')};
  height: ${({ hidden }) => (hidden ? '0' : '')};
  overflow: ${({ hidden }) => (hidden ? 'hidden' : 'unset')};
  margin-top: 20px;

  .card {
    margin-bottom: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: white;
  }

  .flex {
    display: flex;
    gap: 1rem;
    width: 100%;
  }

  .flex-1 {
    flex: 1;
  }
`;

export const StyledButton = styled(Button)`
  && {
    text-transform: none;
    color: ${props => props.textcolor};
    background: ${props => props.backgroundcolor};
    border: 2px solid ${props => props.theme.palette.brand.primary.light};
    font: normal normal 400 16px/24px Texta;
    height: ${props => props.height || '38px'};
    padding: 5px 15px;
    transition: opacity 300ms;
    box-shadow: none;

    :hover {
      background: ${props => props.backgroundcolor};
      border-color: black;
      opacity: 0.7;
    }

    :disabled {
      background: ${props => props.theme.palette.system.disabled};
      opacity: 0.5;
    }
  }
`;

export const Count = styled.p`
  ${({ theme, ...props }) => css`
    font: normal normal 900 12px/16px Texta;
    color: ${theme.palette.words.text.contrast};
    display: ${props.count ? 'block' : 'none'};
    background: ${theme.palette.brand.primary.natural};
    width: 16px;
    border-radius: 8px;
    position: absolute;
    top: -3px;
    right: -3px;
  `}
`;
