import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const Card = styled.div`
  ${({ theme }) => css`
    width: 100%;
    min-height: 230px;
    background-color: white;
    border: 1px solid ${theme.palette.system.border};
    border-radius: 5px;
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 30px;
  `}
`;

export const HeaderCard = styled.div`
  ${({ theme }) => css`
    color: ${theme.palette.words.title.natural};

    span {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .title {
        font-size: 20px;
        font-weight: 600;
        margin-left: 10px;

        p {
          margin-right: 10px;
        }
      }
    }

    .preDivider {
      margin-bottom: 10px;
    }
  `}
`;

export const Description = styled.div`
  ${({ theme }) => css`
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 500;
    color: ${theme.palette.words.subtitle.natural};
    font-size: 14px;
  `}
`;

export const ComponentsArea = styled.div``;
