import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// Componentes
import BarGraph from 'components/Graphs/BarGraph';
import LineGraph from 'components/Graphs/LineGraph';
import Tab from 'components/Tabs';
import { trackEvent } from 'utils/mixpanel';

// Service
import { toast } from 'react-toastify/dist';
import { Grid } from '@mui/material';
import { requestPad } from '../services';
import * as S from './styled';

const radarTabs = [
  { value: 'tipo', label: 'POR TIPO' },
  { value: 'status', label: 'POR STATUS' },
];

const GraficosPad = () => {
  const filter = useSelector(state => {
    return state.filter;
  });

  const user = useSelector(state => {
    return state.auth?.user?.user;
  });

  const [radar, setRadar] = useState(null);
  const [radarTab, setRadarTab] = useState('tipo');
  const [radarLoading, setRadarLoading] = useState(true);

  const [responsaveis, setResponsaveis] = useState(null);
  const [responsaveisLoading, setResponsaveisLoading] = useState(true);

  const [nivel, setNivel] = useState(null);
  const [nivelLoading, setNivelLoading] = useState(true);

  const [criadas, setCriadas] = useState(null);
  const [criadasAxis, setCriadasAxis] = useState(['month']);
  const [criadasLoading, setCriadasLoading] = useState(true);

  const [conclusao, setConclusao] = useState(null);
  const [conclusaoAxis, setConclusaoAxis] = useState(['month']);
  const [conclusaoLoading, setConclusaoLoading] = useState(true);

  const fetchRadar = async query => {
    setRadarLoading(true);
    const res = await requestPad('1', query);
    if (res.data?.data) setRadar(res.data.data);
    else if (res.data?.message) toast.error(res.data.message);
    setRadarLoading(false);
  };

  const fetchResponsaveis = async query => {
    setResponsaveisLoading(true);
    const res = await requestPad('2', query);
    if (res.data?.data) setResponsaveis(res.data.data);
    else if (res.data?.message) toast.error(res.data.message);
    setResponsaveisLoading(false);
  };

  const fetchNivel = async query => {
    setNivelLoading(true);
    const res = await requestPad('3', query);
    if (res.data?.data) setNivel(res.data.data);
    else if (res.data?.message) toast.error(res.data.message);
    setNivelLoading(false);
  };

  const fetchCriadas = async query => {
    setCriadasLoading(true);
    const res = await requestPad('4', query);
    if (res.data?.data) {
      setCriadas(res.data.data.criadas);
      setCriadasAxis(res.data.data.series);
    } else if (res.data?.message) toast.error(res.data.message);
    setCriadasLoading(false);
  };

  const fetchConclusao = async query => {
    setConclusaoLoading(true);
    const res = await requestPad('5', query);
    if (res.data?.data) {
      setConclusao(res.data.data.conclusao);
      setConclusaoAxis(res.data.data.series);
    } else if (res.data?.message) toast.error(res.data.message);
    setConclusaoLoading(false);
  };

  useEffect(() => {
    fetchResponsaveis(filter);
    fetchNivel(filter);
    fetchCriadas(filter);
    fetchConclusao(filter);
  }, [filter]);

  useEffect(() => {
    const query = { ...filter, tip_tab: radarTab };
    fetchRadar(query);
  }, [filter, radarTab]);

  const renderRadar = () => (
    <div style={{ marginTop: '20px', position: 'relative' }}>
      <BarGraph
        data={radar}
        colors="default"
        title="Radar de Ocorrências"
        loading={radarLoading}
        tooltip
      />
      <div style={{ position: 'absolute', top: '15px', right: '25px' }}>
        <Tab
          width="220px"
          value={radarTab}
          items={radarTabs}
          onChange={(e, value) => {
            trackEvent(user, `GRÁFICO RADAR OCORRÊNCIAS: ${value}`);
            setRadarTab(value);
          }}
        />
      </div>
    </div>
  );

  const renderTopResponsabilidadesGraph = () => (
    <div style={{ marginTop: '20px' }}>
      <BarGraph
        data={responsaveis}
        colors="default"
        title="Top 10 Responsáveis"
        loading={responsaveisLoading}
        tooltip
      />
    </div>
  );

  const renderMotoristasNivelGraph = () => (
    <div style={{ marginTop: '20px' }}>
      <BarGraph
        data={nivel}
        colors="default"
        title="Motoristas por Nível"
        loading={nivelLoading}
        tooltip
      />
    </div>
  );

  const renderOcorrenciasCriadas = () => (
    <div style={{ marginTop: '20px' }}>
      <LineGraph
        data={criadas}
        colors="critical"
        title="Histórico Ocorrências Criadas"
        series={criadasAxis}
        loading={criadasLoading}
        tooltip
      />
    </div>
  );

  const renderConclusaoGraph = () => (
    <div style={{ marginTop: '20px' }}>
      <LineGraph
        data={conclusao}
        colors="critical"
        title="Tempo de Conclusão"
        series={conclusaoAxis}
        loading={conclusaoLoading}
        tooltip
      />
    </div>
  );

  const renderMain = () => (
    <>
      <S.Container>
        <S.Header>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '15px',
            }}
          >
            <S.Title>Dashboard Pad</S.Title>
          </div>
        </S.Header>
        <S.Main>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} xl={6}>
              {renderRadar()}
            </Grid>

            <Grid item xs={12} md={6} xl={6}>
              {renderTopResponsabilidadesGraph()}
            </Grid>

            <Grid item xs={12} md={6} xl={6}>
              {renderMotoristasNivelGraph()}
            </Grid>

            <Grid item xs={12} md={6} xl={6}>
              {renderOcorrenciasCriadas()}
            </Grid>

            <Grid item xs={12} md={6} xl={6}>
              {renderConclusaoGraph()}
            </Grid>
          </Grid>
        </S.Main>
      </S.Container>
    </>
  );

  return renderMain();
};

export default GraficosPad;
