import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme, transparent }) => css`
    display: flex;
    flex-direction: row;
    height: 70px;
    align-items: center;
    justify-content: space-between;
    padding: ${transparent ? '0px' : '0px 15px'};
  `}
`;
