import api from 'services/api';

export const requestAvaliacoesList = async (id, query) => {
  const res = await api.get(`/drivers/perfil/av-direcao/${id}`, {
    params: query,
  });
  return res.data;
};

export const requestCards = async (id, query) => {
  const res = await api.get(`/drivers/perfil/av-direcao/cards/${id}`, {
    params: query,
  });
  return res;
};
