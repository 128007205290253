import { Card } from '@mui/material';
import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`;

export const Title = styled.h2`
  ${({ theme }) => css`
    font-size: 20px;
    color: ${theme.palette.words.title.natural};
    font-weight: 600;
  `}
`;

export const BoxLoading = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const List = styled.ul`
  li {
    margin-bottom: 0.5rem;
  }
`;

export const StyledCard = styled(Card)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0.5rem;
  gap: 0.25rem;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.palette.words.title.natural};

  p {
    font: normal normal bold 14px/24px Texta;
  }
  .title {
    font: normal normal 900 16px/24px Texta;
  }
`;

export const Criticity = styled.div`
  display: flex;
  gap: 0.25rem;
  align-items: center;
`;

export const Circle = styled.div`
  width: 12px;
  height: 12px;
  background-color: ${({ color }) => color};
  border-radius: 50%;
`;
