export const columnsAluno = [
  {
    header: 'Id',
    id: 'id',
    type: 'string',
    sort: true,
    align: 'center',
  },
  {
    header: 'Nome',
    id: 'nome',
    type: 'string',
    sort: true,
    picture: 'foto',
  },
  {
    header: 'Filial',
    id: 'filial_name',
    type: 'string',
    sort: true,
    align: 'center',
  },
  {
    header: 'Status',
    id: 'status',
    type: 'string',
    sort: true,
    align: 'center',
    value: value => (value ? 'Ativa' : 'Inativa'),
    conditional: [
      {
        condition: value => value,
        style: theme => ({
          color: theme.palette.semantics.feedback.success.natural,
          backgroundColor: theme.palette.semantics.feedback.success.light,
          textAlign: 'center',
        }),
      },
      {
        condition: value => !value,
        style: theme => ({
          color: theme.palette.semantics.feedback.warning.natural,
          backgroundColor: theme.palette.semantics.feedback.warning.light,
          textAlign: 'center',
        }),
      },
    ],
  },
  {
    header: 'Turmas',
    id: 'turmas',
    type: 'string',
    sort: true,
    align: 'center',
  },
  {
    header: 'Atividades',
    id: 'conteudos',
    type: 'string',
    sort: true,
    align: 'center',
  },
  {
    header: 'Concluídos',
    id: 'concluidos',
    type: 'string',
    sort: true,
    align: 'center',
    description:
      'São considerados Concluídos, os conteúdos onde houve aprovação ou reprovação considerando configuração de repetição vinculada.',
  },
];
