import { toast } from 'react-toastify';

export const validation = (f, setTrySave, setErrors, tags) => {
  let erros = {
    titulo: false,
    descricao: false,
    data_inicio: false,
    data_fim: false,
  };

  let hasError = false;
  if (!f?.titulo) {
    erros.titulo = true;
    hasError = true;
  }

  if (!f?.descricao) {
    erros.descricao = true;
    hasError = true;
  }

  if (!f?.data_inicio) {
    erros.data_inicio = true;
    hasError = true;
  }

  if (f?.final && !f?.data_fim) {
    toast.error('Defina a data encerramento ou marque como "Nunca encerrar"');
    erros.data_fim = true;
    hasError = true;
  }

  if (f?.final && new Date(f?.data_fim) < new Date(f?.data_inicio)) {
    toast.error('Defina uma data incial menor que a data final');
    erros.data_fim = true;
    erros.data_inicio = true;
    hasError = true;
  }

  if (tags.length === 0) {
    toast.error('Selecione pelo menos uma categoria do conteúdo');
    erros.tags = true;
    hasError = true;
  }

  if (tags.length > 3) {
    toast.error('Selecione no maximo 3 categorias do conteúdo');
    erros.tags = true;
    hasError = true;
  }

  if (hasError) {
    toast.error('Verifique o preenchimento dos campos');
    setErrors(erros);
    setTrySave(false);
    return false;
  }

  return true;
};
