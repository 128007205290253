export const columns = [
  {
    header: 'Id',
    id: 'id',
    type: 'number',
    sort: false,
  },
  {
    header: 'Nome',
    id: 'nome',
    type: 'string',
    sort: false,
  },
  {
    header: 'Sigla',
    id: 'sigla',
    type: 'string',
    sort: false,
  },
  {
    header: 'Status',
    id: 'status',
    type: 'string',
    sort: false,
    centered: true,
  },
];
