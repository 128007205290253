export const validatePhone = value => {
  // Verifica se o valor é um número de telefone válido
  const phoneRegex = /^(?:\()?\d{2}(?:\))?(?: )?(?:\d{4,5})-(?:\d{4})$/;
  const phoneWithNineDigitsSpaceRegex =
    /^(?:\()?\d{2}(?:\))?(?: )?(?:\d{1}) \d{4}-\d{4}$/;

  if (!value) {
    return 'Telefone obrigatório';
  }
  if (!phoneRegex.test(value) && !phoneWithNineDigitsSpaceRegex.test(value)) {
    return 'Telefone inválido';
  }

  return true;
};
