// Styleds
import { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';

// React
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { trackEvent } from 'utils/mixpanel';

// Material UI
import { Grid } from '@mui/material';
import {
  AddCircleOutline,
  EditOutlined,
  DeleteOutline,
} from '@mui/icons-material';

// Components
import { DefaultTable } from 'components/_Table/templates/default';

import ConfirmModal from 'components/ConfirmModal';
import DefaultButton from 'components/Buttons/Default';
import * as C from '../styled';
import * as S from './styled';

// Utils
import { columns } from './columns';
import * as service from './service';
import ModalFilial from './ModaFilial';

export const Filiais = () => {
  const theme = useTheme();
  // GENERAL STATES
  const [data, setData] = useState(null);
  const [selectedData, setSelectedData] = useState([]);
  const [dataEdit, setDataEdit] = useState([]);
  const [loading, setLoading] = useState(true);

  const user = useSelector(state => state.auth?.user?.user);

  // ---------------------------REQUEST-------------------------//
  const fetchData = async () => {
    setLoading(true);
    const res = await service.requestFiliais();
    if (res.data.success) {
      let data = res.data.data;
      data = data.map(item => {
        return {
          ...item,
          motorista: item.motorista.length,
          frota: item.caminhao.length,
        };
      });
      console.log(data);
      setData(data);
    } else {
      toast.error(res.data.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  // --------------COLUNA DE AÇÕES & BULK ACTIONS-------------//

  // Actions column
  const [idsAtivar, setIdsAtivar] = useState([]);
  const [idsDesativar, setIdsDesativar] = useState([]);
  const [modalText, setModalText] = useState('');
  const [companiesList, setCompaniesList] = useState([]);

  const handleEditar = id => {
    const filialEdit = data.find(filial => String(filial.id) === String(id));
    if (filialEdit) {
      setDataEdit(filialEdit);
      setOpenModal(true);
    }
  };

  const handleSetDesativar = id => {
    const desativar = [];

    if (selectedData.length > 0) {
      for (const i in data) {
        const item = data[i].id;
        if (selectedData.includes(item)) {
          desativar.push(data[i]);
        }
      }
      setIdsDesativar(desativar);
    } else {
      for (const i in data) {
        if (data[i].id === Number(id)) {
          desativar.push(data[i]);
          setIdsDesativar(desativar);
        }
      }
    }

    setModalText(`Tem certeza que deseja desativar?`);
    setCompaniesList(formatText('desativadas', desativar));
  };

  const handleSetAtivar = id => {
    const ativar = [];

    if (selectedData.length > 0) {
      for (const i in data) {
        const item = data[i].id;
        if (selectedData.includes(item)) {
          ativar.push(data[i]);
        }
      }
      setIdsAtivar(ativar);
    } else {
      for (const i in data) {
        if (data[i].id === Number(id)) {
          ativar.push(data[i]);
          setIdsAtivar(ativar);
        }
      }
    }
    setModalText(`Tem em certeza que deseja ativar?`);
    setCompaniesList(formatText('ativadas', ativar));
  };

  const formatText = (text, filials) => {
    return (
      <>
        <span>
          As seguintes filias serão
          <span style={{ fontWeight: '900' }}> {text.toUpperCase()}</span>:
        </span>
        <ul style={{ listStyle: 'disc', marginLeft: '15px' }}>
          {filials.map((i, k) => (
            <li key={k}>{i.nome}</li>
          ))}
        </ul>
      </>
    );
  };

  const confirmChange = async (items, status) => {
    const ids = [];
    for (const i in items) {
      ids.push(items[i].id);
    }
    const res = await service.changeStatus(ids, status);
    if (res.data.success) {
      toast.success(res.data.message);
      setIdsAtivar([]);
      setIdsDesativar([]);
      fetchData();
    } else {
      toast.error(res.data.message);
    }
  };

  const handleAtivarInativar = id => {
    const status = data.some(item => item.id === id);
    if (!status) {
      handleSetDesativar(id);
    } else {
      handleSetAtivar(id);
    }
  };

  // BulkMenu
  const bulkActions = [
    {
      title: 'Desativar',
      function: () => handleSetDesativar(),
    },
    {
      title: 'Ativar',
      function: () => handleSetAtivar(),
    },
  ];

  // açoes
  const actions = [
    {
      title: 'Ativar/Desativar',
      function: handleAtivarInativar,
    },
    {
      title: 'Editar',
      function: handleEditar,
    },
  ];

  // --------------MODAL ADD & EDIT-------------//
  const [openModal, setOpenModal] = useState(false);
  const renderModal = () => {
    return (
      openModal && (
        <ModalFilial
          open={openModal}
          handleClose={() => {
            setOpenModal(false);
            setDataEdit(0);
          }}
          dataEdit={dataEdit}
          request={fetchData}
        />
      )
    );
  };

  return (
    <S.Container>
      <>
        <C.TitleArea className="space">
          <span className="textArea">Filiais</span>

          <DefaultButton
            startIcon={<AddCircleOutline />}
            size="medium"
            sx={{ marginLeft: '10px' }}
            onClick={() => setOpenModal(true)}
          >
            ADICIONAR FILIAL
          </DefaultButton>
        </C.TitleArea>
        <Grid item md={12} paddingTop={1}>
          <DefaultTable
            data={data || []}
            columns={columns}
            setSelectedRows={setSelectedData}
            actions={actions}
            bulk={bulkActions}
            searchKeys={['nome']}
            loading={loading}
            placeholder="Buscar Filiais"
            sortBy={{ id: 'ativo', order: 'ASC' }}
            searchEvent={search =>
              trackEvent(user, 'Buscar Filiais', null, search)
            }
            empty={{
              title: 'Nenhum cliente encontrado',
              description: 'Você ainda não cadastrou nenhum cliente.',
              image: 'frota.png',
            }}
          />
        </Grid>

        {
          /* Confirmação de ativacao */
          idsAtivar && (
            <ConfirmModal
              open={idsAtivar.length > 0}
              handleClose={() => setIdsAtivar(null)}
              title={modalText}
              titleIcon={
                <EditOutlined
                  sx={{ color: theme.palette.brand.secondary.natural }}
                  fontSize="medium"
                />
              }
              subtitle={companiesList}
              buttonText="Confirmar"
              onClick={() => confirmChange(idsAtivar, true)}
              loading={false}
            />
          )
        }

        {
          /* Confirmação de desativacao */
          idsDesativar && (
            <ConfirmModal
              open={idsDesativar.length > 0}
              handleClose={() => setIdsDesativar(null)}
              title={modalText}
              titleIcon={
                <DeleteOutline
                  sx={{
                    color: theme.palette.semantics.feedback.attention.natural,
                  }}
                  fontSize="medium"
                />
              }
              subtitle={companiesList}
              buttonText="Confirmar"
              onClick={() => confirmChange(idsDesativar, false)}
              loading={false}
            />
          )
        }
        {renderModal()}
      </>
    </S.Container>
  );
};
