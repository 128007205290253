import api from 'services/api';

export const requestBasic = async id => {
  const res = await api.get(`/drivers/perfil/geral/basic-data/${id}`);
  return res?.data?.data?.[0] ?? null;
};

export const requestSelo = async id => {
  const res = await api.get(`/drivers/perfil/geral/selo/${id}`);
  return res?.data?.data ?? null;
};

export const requestPosRanking = async id => {
  const res = await api.get(`/drivers/perfil/geral/ranking-position/${id}`);
  return res?.data?.data ?? null;
};
