import React, { useMemo } from 'react';
import { SingleTable } from '../../Components/SingleTable';
import { columns } from './constants';
import { useQuery } from 'react-query';
import { requestDriversRanking } from 'pages/RankingMotoristas/services';
import { addDays, startOfDay } from 'date-fns';

const today = new Date();
const last30 = startOfDay(addDays(new Date(), -30));

const query = {
  initialDate: last30,
  finalDate: today,
  tipo: 'geral',
  pageIndex: 0,
  pageSize: 5,
  sortBy: { id: 'posicao', order: 'ASC', desc: false },
};

export const MaisSeguros = () => {
  const { isFetching, data: resData } = useQuery(
    ['mais-seguros', query],
    () => query && requestDriversRanking(query),
    {
      refetchOnWindowFocus: false,
    },
  );

  const data = useMemo(() => {
    const _data = resData?.data?.data ?? [];
    return _data.slice(0, 5);
  }, [resData]);

  return (
    <SingleTable
      title="Motoristas mais seguros"
      subtitle="Últimos 30 dias"
      data={data}
      columns={columns}
      link={{
        to: '/motoristas/rankings',
        children: 'Ver todos',
        justify: 'right',
      }}
      loading={isFetching}
    />
  );
};
