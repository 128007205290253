import React, { useState } from 'react';

import { Divider, Grid } from '@mui/material';
import Tabs from 'components/Tabs';

import * as S from './styled';
import { MenosSeguros } from './MenosSeguros';
import { ListaEmpresas } from './ListaEmpresas';
import { Historico } from './Historico';

import { usePlans } from 'hooks/usePlans';
import { MotoristasEstatisticas } from '../Motoristas';

const Estatisticas = () => {
  return (
    <S.Container>
      <S.Main>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <MenosSeguros />
          </Grid>
          <Grid item xs={12}>
            <Historico />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <ListaEmpresas />
          </Grid>
        </Grid>
      </S.Main>
    </S.Container>
  );
};

export const statusTabs = [
  { value: 'MOTORISTAS', label: 'Minha empresa' },
  { value: 'EMPRESAS', label: 'Todas as empresas' },
];

export const EmpresasEstatisticas = () => {
  const { isOpLogistico } = usePlans();
  const [tab, setTab] = useState('MOTORISTAS');

  if (isOpLogistico)
    return (
      <>
        <S.Container>
          <Tabs
            value={tab}
            items={statusTabs}
            onChange={(_, value) => setTab(value)}
          />
          <Divider />
          <br />
          <br />
          {tab === 'MOTORISTAS' && <MotoristasEstatisticas />}
          {tab === 'EMPRESAS' && <Estatisticas />}
        </S.Container>
      </>
    );
  return <Estatisticas />;
};
