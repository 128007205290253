import * as S from './styled';

const img = require(`images/empty/desvio.png`);

export const EmptyContent = () => {
  return (
    <S.EmptyArea>
      <>
        <S.ImageArea>
          <img src={img} alt="Conteúdo vazio" />
        </S.ImageArea>
        <S.ContentArea>
          <span className="textArea">
            <h3>Nenhuma avaliação foi adicionada para o conteúdo</h3>
            <h5>Selecione o tipo de avaliação desejada ou pule essa etapa</h5>
          </span>
        </S.ContentArea>
      </>
    </S.EmptyArea>
  );
};
