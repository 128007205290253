import * as S from '../styled';

const columns = [
  {
    Header: 'Rodovia',
    id: 'rodovia',
    accessor: d => String(d.rodovia ?? ''),
    sortType: 'basic',
    show: true,
    disableSortBy: false,
    centered: false,
    Cell: ({ row }) => <S.TableName>{row.original.rodovia}</S.TableName>,
  },
  {
    Header: 'Quantidade de Desvios',
    id: 'total',
    accessor: d => Number(d.total ?? 0),
    sortType: 'basic',
    show: true,
    disableSortBy: false,
    centered: true,
    Cell: ({ row }) => <S.TableName center>{row.original.total}</S.TableName>,
  },
  {
    Header: 'Percentual do Todo',
    id: 'percentual',
    accessor: d => Number(d.percentual ?? 0),
    sortType: 'basic',
    show: true,
    disableSortBy: false,
    centered: true,
    Cell: ({ row }) => (
      <S.TableName center>
        {Number(row.original.percentual).toFixed(2)}%
      </S.TableName>
    ),
  },
];

export { columns };
