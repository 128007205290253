import { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import Icon from 'components/Icons';

import { Grid, IconButton } from '@mui/material';
import GhostButton from 'components/Buttons/Ghost';
import DefaultButton from 'components/Buttons/Default';
import TextInput from 'components/Inputs/TextField';
import { useTheme } from 'styled-components';
import { RoomOutlined } from '@mui/icons-material';
import * as S from './styled';

const DetalheCercaModal = ({ registro, handleClose, handleEditar, open }) => {
  const theme = useTheme();
  const [origem, setOrigem] = useState(null);
  const [destino, setDestino] = useState(null);

  useEffect(async () => {
    const getGeocodeLocation = async location => {
      if (!window.google) return '';
      const geocoder = new window.google.maps.Geocoder();
      if (!geocoder) return '';
      const response = await geocoder.geocode({ location });

      if (response.results[0]) return response.results[0].formatted_address;
      return `${location.lat}, ${location.lng}`;
    };

    if (registro) {
      if (registro.cerca?.raio) {
        const location = {
          lat: parseFloat(registro.cerca?.latitude),
          lng: parseFloat(registro.cerca?.longitude),
        };
        const origem = await getGeocodeLocation(location);
        setOrigem(origem);
      } else if (registro.cerca?.rotas?.length) {
        const lastIndex = registro.cerca.rotas.length - 1;
        const locationOrigem = {
          lat: parseFloat(registro.cerca.rotas[0].latitude),
          lng: parseFloat(registro.cerca.rotas[0].longitude),
        };
        const locationDestino = registro.cerca?.rotas[lastIndex]
          ? {
              lat: parseFloat(registro.cerca.rotas[lastIndex].latitude),
              lng: parseFloat(registro.cerca.rotas[lastIndex].longitude),
            }
          : null;
        if (locationOrigem && locationDestino) {
          const origem = await getGeocodeLocation(locationOrigem);
          setOrigem(origem);
          const destino = await getGeocodeLocation(locationDestino);
          setDestino(destino);
        }
      }
    }
  }, [registro]);

  const body = (
    <S.Container>
      <S.Header>
        <div style={{ alignItems: 'center' }}>
          <div>
            <RoomOutlined
              sx={{ color: theme.palette.brand.secondary.natural }}
              fontSize="medium"
            />
            <h2>Detalhes Cerca Eletrônica</h2>
          </div>
        </div>

        <IconButton size="small" onClick={handleClose}>
          <Icon
            sx={{ color: theme.palette.words.subtitle.natural }}
            name="close"
          />
        </IconButton>
      </S.Header>
      <S.Main>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <TextInput
              disabled
              name="titulo"
              label="Título"
              placeholder="Insira um título"
              value={registro?.titulo ?? ''}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextInput
              disabled
              name="ponto_inicial"
              label="Ponto inicial"
              value={origem}
            />
          </Grid>

          {!registro?.cerca?.raio && (
            <Grid item xs={12} sm={12}>
              <TextInput
                disabled
                name="ponto_final"
                label="Ponto final"
                value={destino}
              />
            </Grid>
          )}

          <Grid item xs={6} sm={6}>
            <TextInput
              disabled
              name="velocidade"
              type="number"
              label="Velocidade"
              placeholder="Insira a velocidade"
              value={registro?.violacoes?.[0]?.limiar ?? ''}
            />
          </Grid>

          <Grid item xs={6} sm={6}>
            <TextInput
              disabled
              name="pontos"
              type="number"
              label="Pontuação"
              placeholder="Insira a pontuação"
              value={registro?.violacoes?.[0]?.pontos ?? ''}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <S.Section>INFORMAÇÕES COMPLEMENTARES</S.Section>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextInput
              disabled
              name="tipo_veiculo"
              label="Tipo de veículo"
              value={registro?.tipo_veiculo?.tipo ?? 'Todos'}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              disabled
              name="distribuidora"
              label="Cliente"
              value={registro?.distribuidora?.nome ?? 'Todos'}
            />
          </Grid>
        </Grid>
      </S.Main>

      <S.Footer>
        <GhostButton
          onClick={() => {
            handleClose();
          }}
          size="medium"
        >
          Cancelar
        </GhostButton>

        <DefaultButton
          onClick={() => handleEditar(registro?.id)}
          size="medium"
          sx={{ marginLeft: '20px' }}
        >
          EDITAR
        </DefaultButton>
      </S.Footer>
    </S.Container>
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {body}
    </Modal>
  );
};

export default DetalheCercaModal;
