export const fields = [
  {
    label: 'ID',
    value: 'id',
    selected: true,
    default: true,
  },
  {
    label: 'Empresa',
    value: 'empresa',
    selected: true,
    default: true,
  },
  {
    label: 'Titulo',
    value: 'titulo',
    selected: true,
    default: true,
  },
  {
    label: 'Descrição',
    value: 'descricao',
    selected: true,
    default: true,
  },
  {
    label: 'Status',
    value: 'status',
    selected: true,
    default: true,
  },
  {
    label: 'Criador',
    value: 'criador',
    selected: false,
    default: false,
  },
  {
    label: 'Necessita Aprovação',
    value: 'aprovar',
    selected: false,
    default: false,
  },
  {
    label: 'Responsável aprovação',
    value: 'responsavel_aprovador',
    selected: false,
    default: false,
  },
  {
    label: 'Prazo aprovação(dias)',
    value: 'prazo_aprovacao',
    selected: false,
    default: false,
  },
  {
    label: 'Gera Ocorrências',
    value: 'ocorrencias',
    selected: false,
    default: false,
  },
  {
    label: 'Responsável ocorrência',
    value: 'responsavel_ocorrencia',
    selected: false,
    default: false,
  },
  {
    label: 'Prazo ocorrências(dias)',
    value: 'prazo_ocorrencia',
    selected: false,
    default: false,
  },
  {
    label: 'Frequência',
    value: 'frequencia',
    selected: true,
    default: true,
  },
  {
    label: 'Período',
    value: 'periodo',
    selected: true,
    default: true,
  },
  {
    label: 'Múltiplo',
    value: 'multiplo',
    selected: true,
    default: true,
  },
  {
    label: 'Data Início',
    value: 'data_inicio',
    selected: true,
    default: true,
  },
  {
    label: 'Data Encerramento',
    value: 'data_fim',
    selected: true,
    default: true,
  },
  {
    label: 'Assinatura',
    value: 'assinatura',
    selected: false,
    default: false,
  },
  {
    label: 'Obrigatório',
    value: 'obrigatorio',
    selected: false,
    default: false,
  },
  {
    label: 'Criado em',
    value: 'createdAt',
    selected: true,
    default: true,
  },
  {
    label: 'Última edição',
    value: 'updatedAt',
    selected: true,
    default: true,
  },
];

export const resetFields = fields => {
  return fields.map(i => {
    if (!i.default) i.selected = false;
    return i;
  });
};

export const initializeForm = [
  {
    index: 0,
    titulo: 'Cabeçalho',
    descricao: '',
    campos: [],
  },
];

export const tagsLoading = ['', '', '', '', '', '', '', '', '', '', ''];
