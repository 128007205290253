import React from 'react';
import * as S from './styled';

const Footer = ({ actions, title }) => {
  return (
    <S.Footer>
      <S.FooterContainer>
        <h2>{title}</h2>
        <S.FooterActions>{actions}</S.FooterActions>
      </S.FooterContainer>
    </S.Footer>
  );
};

export default Footer;
