import * as S from './styled';

export const TableToPrint = ({ columns = [], data = [], total = null }) => {
  const hasTotal = columns.findIndex(column => column.total);

  const totais = data.reduce((acc, row) => {
    columns.map(column => {
      if (column.total) {
        if (acc[column.id]) {
          acc[column.id] += +(row[column.id] ?? 0);
        } else {
          acc[column.id] = +(row[column.id] ?? 0);
        }
      }
    });
    return acc;
  }, {});

  return (
    <S.TableContainer>
      <S.Table>
        <thead>
          <tr>
            {columns.map((column, index) => (
              <th key={index} style={{ textAlign: column.align ?? 'left' }}>
                {column.header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              {columns.map(column => (
                <td
                  key={column.id}
                  style={{ textAlign: column.align ?? 'left' }}
                >
                  {column.value instanceof Function
                    ? column.value(row[column.id] ?? '-', row)
                    : row[column.id] ?? '-'}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
        {hasTotal >= 0 && (
          <tr>
            {columns.map((column, index) =>
              index === 0 ? (
                <td
                  key={column.id}
                  colSpan={hasTotal}
                  style={{ textAlign: 'right' }}
                >
                  {total ?? 'Total:'}
                </td>
              ) : index >= hasTotal ? (
                <td
                  key={column.id}
                  style={{ textAlign: column.align ?? 'left' }}
                >
                  {totais[column.id] ?? '-'}
                </td>
              ) : null,
            )}
          </tr>
        )}
      </S.Table>
    </S.TableContainer>
  );
};
