import { formatNewDate } from 'utils/dates';
import * as S from '../styled';

const columns = [
  {
    Header: 'Desvios',
    id: 'titulo',
    accessor: d => String(d.name ?? ''),
    sortType: 'basic',
    show: true,
    disableSortBy: false,
    centered: false,
  },
  {
    Header: 'Alcançado',
    id: 'alcancado',
    accessor: d => Number(d.value ?? 0),
    sortType: 'basic',
    show: true,
    disableSortBy: false,
    centered: true,
    Cell: ({ row }) => (
      <S.TableName center>
        {row.original.value.toFixed(5)} pontos/km
      </S.TableName>
    ),
  },
  {
    Header: 'Meta',
    id: 'meta',
    accessor: d => Number(d.meta ?? 0),
    show: true,
    disableSortBy: false,
    centered: true,
    Cell: ({ row }) => (
      <S.TableName center>{row.original.meta ?? 0} pontos/km</S.TableName>
    ),
  },
  {
    Header: 'Período',
    id: 'periodo',
    accessor: d => String(d.periodo ?? ''),
    show: true,
    disableSortBy: false,
    centered: true,
    Cell: ({ row }) => (
      <S.TableName center>
        {formatNewDate(row.original.initialDate)}-
        {formatNewDate(row.original.finalDate)}
      </S.TableName>
    ),
  },
  {
    Header: 'Variação',
    id: 'variacao',
    accessor: d => String(d.variacao ?? ''),
    show: true,
    disableSortBy: true,
    centered: true,
    Cell: ({ row }) => {
      const variacao = ((row.original.meta ?? 0) - row.original.value).toFixed(
        5,
      );
      return (
        <S.TableName center>
          <S.Points red={variacao < 0}>
            {variacao > 0 ? `+${variacao}` : variacao < 0 ? variacao : 0}{' '}
            pontos/km
          </S.Points>
        </S.TableName>
      );
    },
  },
];

export { columns };
