// React
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// Components
import GhostButton from 'components/Buttons/Ghost';
import DefaultButton from 'components/Buttons/Default';
import { Select } from 'components/Header/Filters/Select';

// Components MUI
import List from '@mui/material/List';
import { Popover } from '@mui/material';

import {
  getResponsaveis,
  getCriticidade,
  getDesviosTiposPadrao,
  getTecnologias,
  getCargas,
} from 'constants/_SERVICES/user';
import { getResponsaveis as getResponsaveisProvider } from 'constants/_SERVICES/provider';

import * as S from './styled';
import { usePlans } from 'hooks/usePlans';

// Utils

const Filters = ({
  id,
  open,
  onClose,
  anchorEl,
  filter,
  setFilter,
  defaultFilters,
}) => {
  const [prevFilter, setPrevFilter] = useState({ ...filter });
  const [data, setData] = useState({});
  const { isProvider } = usePlans();

  const selects = useSelector(state => state.selects);

  useEffect(() => {
    const fetchData = async () => {
      if (Object.getOwnPropertyNames(filter).includes('tecnologia')) {
        const tecnologias = await getTecnologias();
        setData(state => ({
          ...state,
          tecnologias: tecnologias.map(i => ({
            label: i.label,
            value: i.value,
            tipos: i.tipos?.map(i => ({
              label: i.titulo,
              value: i.id,
            })),
          })),
        }));
      }
      if (Object.getOwnPropertyNames(filter).includes('criticidade')) {
        const criticidades = await getCriticidade();
        setData(state => ({
          ...state,
          criticidades,
        }));
      }
      if (Object.getOwnPropertyNames(filter).includes('desvio')) {
        const desvios = await getDesviosTiposPadrao();
        setData(state => ({
          ...state,
          desvios,
        }));
      }
      if (Object.getOwnPropertyNames(filter).includes('responsavel')) {
        const responsaveis = isProvider
          ? await getResponsaveisProvider()
          : await getResponsaveis();
        setData(state => ({
          ...state,
          responsaveis,
        }));
      }
      if (Object.getOwnPropertyNames(filter).includes('carga')) {
        const cargas = await getCargas();
        setData(state => ({
          ...state,
          cargas,
        }));
      }
    };
    fetchData();
    setPrevFilter({ ...filter });
  }, [filter]);

  const clientes =
    selects.clients?.map(i => {
      return { label: i.nome, value: i.id };
    }) || [];

  const filials =
    selects.filials?.map(i => {
      return { label: i.nome, value: i.id };
    }) || [];

  const operations =
    selects.operations
      ?.filter(i => i.id > 0)
      .map(i => {
        return { label: i.operacao, value: i.id };
      }) || [];

  const handleChangeMultiple = (value, key) => {
    setPrevFilter(_prevFilter => {
      return { ..._prevFilter, [key]: value };
    });
    if (key === 'tecnologia')
      setPrevFilter(_prevFilter => {
        return { ..._prevFilter, desvio: '' };
      });
  };

  const aplyFilter = () => {
    const _prevFilter = { ...prevFilter };
    setFilter({ ..._prevFilter });
    onClose();
  };

  const reset = () => {
    setPrevFilter({ ...filter });
  };

  // Limpa TODOS os valores do estado e foca no botao de aplicar
  const handleClear = () => {
    setPrevFilter(state => ({
      ...defaultFilters,
      status: state.status,
      sortBy: state.sortBy,
    }));
    const buttonApply = document.getElementById('aplicar');
    buttonApply?.focus();
  };

  const getDesvios = useCallback(() => {
    if (prevFilter.tecnologia) {
      const tecnologias = prevFilter.tecnologia.split(',');
      const desvios = data.tecnologias.reduce((acc, curr) => {
        if (tecnologias.includes(`${curr.id}`)) acc = [...acc, ...curr.tipos];
        return acc;
      }, []);
      return desvios;
    }
    return data.desvios ?? [];
  }, [data, prevFilter.tecnologia]);

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <S.Main>
        <S.Header>
          <h2>Filtros</h2>
          <S.ClearButton onClick={handleClear}>Limpar filtros</S.ClearButton>
        </S.Header>

        <S.SelectsContainer>
          <List>
            <S.CollapseContent>
              {Object.getOwnPropertyNames(filter).includes('client') && (
                <Select
                  value={prevFilter?.client}
                  setValue={value => handleChangeMultiple(value, 'client')}
                  placeholder="Filtrar por Cliente"
                  data={clientes}
                />
              )}
              {Object.getOwnPropertyNames(filter).includes('filial') && (
                <Select
                  value={prevFilter?.filial}
                  setValue={value => handleChangeMultiple(value, 'filial')}
                  placeholder="Filtrar por Filial"
                  data={filials}
                />
              )}
              {Object.getOwnPropertyNames(filter).includes('operation') && (
                <Select
                  value={prevFilter?.operation}
                  setValue={value => handleChangeMultiple(value, 'operation')}
                  placeholder="Filtrar por Operação"
                  data={operations}
                />
              )}
              {Object.getOwnPropertyNames(filter).includes('carga') && (
                <Select
                  mode="multiple"
                  value={prevFilter?.carga}
                  setValue={value => handleChangeMultiple(value, 'carga')}
                  placeholder="Filtrar por Carga"
                  data={data.cargas}
                />
              )}
              {Object.getOwnPropertyNames(filter).includes('tecnologia') && (
                <Select
                  mode="multiple"
                  value={prevFilter?.tecnologia}
                  setValue={value => handleChangeMultiple(value, 'tecnologia')}
                  placeholder="Filtrar por Tecnologia"
                  data={data.tecnologias}
                />
              )}
              {Object.getOwnPropertyNames(filter).includes('desvio') && (
                <Select
                  mode="multiple"
                  value={prevFilter?.desvio}
                  setValue={value => handleChangeMultiple(value, 'desvio')}
                  placeholder="Filtrar por Desvio"
                  data={getDesvios()}
                />
              )}
              {Object.getOwnPropertyNames(filter).includes('criticidade') && (
                <Select
                  mode="multiple"
                  value={prevFilter?.criticidade}
                  setValue={value => handleChangeMultiple(value, 'criticidade')}
                  placeholder="Filtrar por Criticidade"
                  data={data.criticidades}
                />
              )}
              {Object.getOwnPropertyNames(filter).includes('responsavel') && (
                <Select
                  mode="multiple"
                  value={prevFilter?.responsavel}
                  setValue={value => {
                    handleChangeMultiple(value, 'responsavel');
                  }}
                  placeholder="Filtrar por Responsavel"
                  data={data.responsaveis}
                />
              )}
            </S.CollapseContent>
          </List>
        </S.SelectsContainer>
      </S.Main>
      <S.Footer>
        <GhostButton
          onClick={() => {
            reset();
            onClose();
          }}
          size="medium"
        >
          Cancelar
        </GhostButton>

        <DefaultButton
          onClick={aplyFilter}
          size="medium"
          sx={{ marginLeft: '20px' }}
          id="aplicar"
        >
          APLICAR
        </DefaultButton>
      </S.Footer>
    </Popover>
  );
};

export default Filters;
