export const period = [
  { value: 'DIA', name: 'Dia' },
  { value: 'SEMANA', name: 'Semana' },
  { value: 'MES', name: 'Mes' },
];

export const week = [
  { day: 'S', tooltip: 'Segunda' },
  { day: 'T', tooltip: 'Terca' },
  { day: 'Q', tooltip: 'Quarta' },
  { day: 'Q', tooltip: 'Quinta' },
  { day: 'S', tooltip: 'Sexta' },
  { day: 'S', tooltip: 'Sabado' },
  { day: 'D', tooltip: 'Domingo' },
];
