import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  @media (min-width: 900px) {
    max-width: 20rem;
  }
`;

export const Header = styled.div`
  font: normal normal medium 20px Texta;
  color: #4b5166;
  margin-bottom: 1rem;
`;

export const Title = styled.div`
  display: flex;
  gap: 1rem;
  font: normal normal 900 48px Texta;
  color: #4b5166;
`;

export const IconContainer = styled.div`
  width: 3rem;
  display: flex;
  align-items: center;
`;

export const ListContainer = styled.div`
  width: 100%;
  max-height: 36rem;
  overflow-y: auto;
`;

export const ListItem = styled.div`
  margin-top: 0.5rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #95aac94d;
  border-radius: 4px;
  opacity: ${({ active }) => (active ? 1 : 0.5)};
  padding: 0.5rem;
  padding-left: 0;
  font: normal normal medium 11px/16px Texta;
  color: #4b5166;

  .icon {
    text-align: center;
    align-content: center;
    opacity: ${({ active }) => (active ? 1 : 0)};
  }

  .flex {
    display: flex;
  }

  .flexcenter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font: normal normal medium 16px/16px Texta;
  }

  .title {
    max-width: 11rem;
    overflow: hidden;
    text-overflow: ellipsis;
    font: normal normal 900 16px/16px Texta;
  }
  .textnowrap {
    white-space: nowrap;
  }
`;
