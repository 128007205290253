export const columnsTotal = [
  {
    Header: 'Nome',
    id: 'aluno', // alinhar com back
    identifier: 'nome',
    accessor: d => String(d?.aluno?.nome),
    sortType: 'basic',
    show: true,
    disableSortBy: false,
  },
  {
    Header: 'Status',
    id: 'concluido', // alinhar com back
    identifier: 'status',
    accessor: d => Number(d.concluido),
    sortType: 'basic',
    show: true,
    disableSortBy: false,
    centered: true,
  },

  {
    Header: 'Ocorrências',
    id: 'ocorrencias', // alinhar com back
    identifier: 'ocorrencias',
    accessor: d => Number(d?.ocorrencias?.total || 0),
    sortType: 'basic',
    show: true,
    disableSortBy: false,
    centered: true,
  },
  {
    Header: 'Repetiçoes Preenchidas',
    id: 'contador', // alinhar com back
    identifier: 'contador',
    accessor: d => Number(d.contador),
    sortType: 'basic',
    show: true,
    disableSortBy: false,
    centered: true,
  },

  {
    Header: 'Aprovado em',
    id: 'data_assinatura', // alinhar com back
    identifier: 'aprovado',
    accessor: d => Date(d.data_assinatura),
    sortType: 'basic',
    show: true,
    disableSortBy: false,
    centered: true,
  },
  {
    Header: 'Ações',
    id: 'acoes', // alinhar com back
    identifier: 'acoes',
    accessor: d => null, // alinhar acesso
    sortType: 'basic',
    show: true,
    disableSortBy: true,
  },
];

export const columnsApprove = [
  {
    Header: 'Nome',
    id: 'aluno', // alinhar com back
    identifier: 'nome',
    accessor: d => String(d?.aluno?.nome),
    sortType: 'basic',
    show: true,
    disableSortBy: false,
  },
  {
    Header: 'Status',
    id: 'concluido', // alinhar com back
    identifier: 'status',
    accessor: d => Number(d.concluido),
    sortType: 'basic',
    show: true,
    disableSortBy: false,
    centered: true,
  },

  {
    Header: 'Repetiçoes Preenchidas',
    id: 'contador', // alinhar com back
    identifier: 'contador',
    accessor: d => Number(d.contador),
    sortType: 'basic',
    show: true,
    disableSortBy: false,
    centered: true,
  },

  {
    Header: 'Aprovado em',
    id: 'data_assinatura', // alinhar com back
    identifier: 'aprovado',
    accessor: d => Date(d.data_assinatura),
    sortType: 'basic',
    show: true,
    disableSortBy: false,
    centered: true,
  },
];

export const columnsOcurrence = [
  {
    Header: 'Nome',
    id: 'aluno', // alinhar com back
    identifier: 'nome',
    accessor: d => String(d?.aluno?.nome),
    sortType: 'basic',
    show: true,
    disableSortBy: false,
  },
  {
    Header: 'Status',
    id: 'concluido', // alinhar com back
    identifier: 'status',
    accessor: d => Number(d.concluido),
    sortType: 'basic',
    show: true,
    disableSortBy: false,
    centered: true,
  },

  {
    Header: 'Ocorrências',
    id: 'ocorrencias', // alinhar com back
    identifier: 'ocorrencias',
    accessor: d => Number(d?.ocorrencias?.total || 0),
    sortType: 'basic',
    show: true,
    disableSortBy: false,
    centered: true,
  },
  {
    Header: 'Repetiçoes Preenchidas',
    id: 'contador', // alinhar com back
    identifier: 'contador',
    accessor: d => Number(d.contador),
    sortType: 'basic',
    show: true,
    disableSortBy: false,
    centered: true,
  },
];

export const columnsSmall = [
  {
    Header: 'Nome',
    id: 'aluno', // alinhar com back
    identifier: 'nome',
    accessor: d => String(d.aluno.nome),
    sortType: 'basic',
    show: true,
    disableSortBy: false,
  },
  {
    Header: 'Status',
    id: 'concluido', // alinhar com back
    identifier: 'status',
    accessor: d => Number(d.concluido),
    sortType: 'basic',
    show: true,
    disableSortBy: false,
    centered: true,
  },
  {
    Header: 'Repetiçoes Preenchidas',
    id: 'contador', // alinhar com back
    identifier: 'contador',
    accessor: d => Number(d.contador),
    sortType: 'basic',
    show: true,
    disableSortBy: false,
    centered: true,
  },
];
