import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useTheme } from 'styled-components';
import { usePlans } from 'hooks/usePlans';
import { subDays } from 'date-fns';
import Tab from 'components/Tabs';
import Graph from 'components/Graphs/ComposedChart';
import { getRadarDesvios } from '../../services';
import DateRangePicker from 'components/DateRangePicker';
import { StyledButton } from '../../styled';
import DrawerFilters from '../DrawerFilters';

// components @mui
import { SvgIcon } from '@mui/material';

// icons svg
import { ReactComponent as filterIco } from 'images/icons/filter.svg';

// ------------------------------- Constantes
const valuesRadar = [
  { value: 'porPontos', label: 'PONTOS' },
  { value: 'porQuantidade', label: 'QUANTIDADE' },
];

const filtersGraficoParams = [
  {
    name: 'filiais',
    placeholder: 'Filtrar por Filial',
    data: [],
  },
  {
    name: 'clientes',
    placeholder: 'Filtrar por Cliente',
    data: [],
  },
  {
    name: 'criticidade',
    placeholder: 'Filtra por Criticidade',
    data: [
      { id: 'PRIMÁRIA', value: 'PRIMÁRIA' },
      { id: 'MODERADA', value: 'MODERADA' },
      { id: 'GRAVE', value: 'GRAVE' },
      { id: 'GRAVÍSSIMA', value: 'GRAVÍSSIMA' },
    ],
  },
];

const filtersGraficoParamsProvider = [
  {
    name: 'empresas',
    placeholder: 'Filtrar por Empresa',
    data: [],
  },
  {
    name: 'criticidade',
    placeholder: 'Filtra por Criticidade',
    data: [
      { id: 'PRIMÁRIA', value: 'PRIMÁRIA' },
      { id: 'MODERADA', value: 'MODERADA' },
      { id: 'GRAVE', value: 'GRAVE' },
      { id: 'GRAVÍSSIMA', value: 'GRAVÍSSIMA' },
    ],
  },
];

const RadarDesvios = ({ empresas = [], clientes = [], filiais = [] }) => {
  const theme = useTheme();
  const { isProvider, hasMonitoramento, hasTelemetria } = usePlans();
  const [tab, setTab] = useState('porPontos');

  const [filterIntervalRadar, setFilterIntervalRadar] = useState({
    initialDate: subDays(new Date(), 30),
    finalDate: new Date(),
  });
  const [openFilterRadar, setOpenFilterRadar] = useState(null);
  const [filtersPageRadar, setFiltersPageRadar] = useState(null);

  filtersGraficoParams[0].data = filiais;
  filtersGraficoParams[1].data = clientes;
  filtersGraficoParamsProvider[0].data = empresas;

  const filter = { ...filterIntervalRadar, ...filtersPageRadar };

  const { data: resData, isFetching } = useQuery(
    ['radar-desvios', filter],
    () => getRadarDesvios(filter),
    {
      onError: error => toast.error(error),
      refetchOnWindowFocus: false,
    },
  );

  const rightYAxisMax = datamax => {
    return datamax > 100
      ? Math.ceil(datamax / 100) * 100
      : datamax > 10
      ? Math.ceil(datamax / 10) * 10
      : 10;
  };

  const tabComponent = (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'end',
        }}
      >
        <Tab
          width="230px"
          value={tab}
          items={valuesRadar}
          onChange={(e, value) => {
            setTab(value);
          }}
        />
      </div>
    </div>
  );

  const data =
    tab == 'porPontos'
      ? resData?.data?.porPontos
      : resData?.data?.porQuantidade;

  const maxDataPercentage = data?.[0]?.percentage;
  const maxData = data?.[0]?.percentage;

  const renderRadarDesviosGraph = () => (
    <Graph
      data={data}
      barSeries={['value']}
      barLabel={tab == 'porPontos' ? ['Pontos'] : ['Quantidade']}
      barColors="default"
      rightYAxisMax={rightYAxisMax}
      lineYAxis
      leftYAxisMax={Math.ceil(parseFloat(100))}
      lineSeries={['percentage']}
      lineLabel={['Percentual do todo']}
      renderLineLabel={false}
      lineColors="status"
      linePostFix="%"
      title="Radar de Desvios"
      subtitle={
        tab == 'porPontos' ? 'Total de pontos' : 'Quantidade de desvios'
      }
      loading={isFetching}
      tooltip
      tabComponent={tabComponent}
      wrapped
      yWidth={90}
    />
  );

  return (
    <>
      <div
        style={{ display: 'flex', flexDirection: 'row', alignSelf: 'flex-end' }}
      >
        <DateRangePicker
          onDate={setFilterIntervalRadar}
          dInt={filterIntervalRadar.initialDate}
          dfim={filterIntervalRadar.finalDate}
        />

        <div style={{ marginLeft: '1rem' }}>
          <StyledButton
            textcolor={theme.palette.words.text.natural}
            backgroundcolor="transparent"
            startIcon={<SvgIcon component={filterIco} />}
            onClick={event => setOpenFilterRadar(event.currentTarget)}
            disabled={isFetching}
          >
            Filtros
          </StyledButton>
        </div>
      </div>

      {renderRadarDesviosGraph()}

      <DrawerFilters
        open={openFilterRadar}
        handleClose={() => setOpenFilterRadar(null)}
        onSelected={setFiltersPageRadar}
        dataSelect={
          isProvider ? filtersGraficoParamsProvider : filtersGraficoParams
        }
        onClean={() => setFiltersPageRadar(null)}
      />
    </>
  );
};

export default RadarDesvios;
