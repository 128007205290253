import styled, { css } from 'styled-components';

export const StepOneBox = styled.div`
  ${({ theme }) => css`
    .boxText {
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      width: 100%;

      .typeDesc {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 600;
        color: ${theme.palette.words.title.natural};
        font-size: 16px;
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }

    .counter {
      align-self: flex-end;
      font-size: 12px;
      color: ${theme.palette.words.text.light};
    }
  `}
`;
