import styled, { css } from 'styled-components';

export const Main = styled.div`
  width: 100%;
  height: 100%;
  padding: 8px 16px;
  overflow-y: scroll;
  margin-bottom: 8px;
`;

export const Header = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 70px;
    border-bottom: 1px solid ${theme.palette.brand.primary.light}4d;

    div {
      display: flex;
      flex-direction: row;
      h2 {
        margin-left: 10px;
      }
    }
  `}
`;

export const Action = styled.div`
  width: 100%;
  padding: 20px;
`;

export const ClearButton = styled.button`
  border: none;
  background: transparent;
  font: normal 600 14px Texta;
  text-decoration: underline;
  color: ${({ theme }) => theme.palette.words.text.natural};
  opacity: 1;
  :hover {
    opacity: 0.6;
  }
`;

export const Footer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 80px;
    border-top: 1px solid ${theme.palette.brand.primary.light}4d;
  `}
`;

export const Container = styled.div`
  ${({ theme }) => css`
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    top: 0px;
    right: 0px;
    width: 550px;
    height: 100%;
    background-color: ${theme.palette.system.overlay};
    padding: 0px 30px;
  `}
`;

export const Section = styled.h3`
  ${({ theme }) => css`
    font: normal normal 900 13px/32px Texta;
    justify-content: center;
    color: ${theme.palette.words.subtitle.natural};
  `}
`;
