import styled from 'styled-components';
import colors from 'styles/colors';

export const Container = styled.div`
  width: 100%;
  padding: 30px;
  border: 1px solid ${colors.grey}4D;
  border-radius: 4px;
  background-color: ${colors.white};
`;

export const Total = styled.p`
  font: normal normal 900 16px/20px Texta;
  letter-spacing: 0px;
  color: ${colors.blueTitle};
  opacity: 1;
  margin-bottom: 30px;
`;

export const Comentario = styled.div`
  div {
    display: flex;
    align-items: center;

    .content {
      width: 100%;
      justify-content: space-between;

      .nome {
        margin-left: 10px;
        text-align: left;
        font: normal normal bold 16px/40px Texta;
        letter-spacing: 0px;
        color: #4b5166;
        opacity: 1;
      }

      .data {
        font: normal normal normal 11px/29px Texta;
        letter-spacing: 0px;
        color: #95aac9;
        opacity: 1;
      }
    }
  }

  .comentario {
    margin-top: 8px;
    margin-bottom: 20px;
    text-align: left;
    font: normal normal normal 14px/20px Texta;
    letter-spacing: 0px;
    color: ${colors.greyTitle};
    opacity: 1;
    font-weight: 500;
    font-size: 14px;
  }
`;

export const Curtida = styled.div`
  div {
    display: flex;
    align-items: center;

    .content {
      width: 100%;
      justify-content: space-between;

      div {
        .nome {
          margin-left: 10px;
          text-align: left;
          font: normal normal bold 16px/40px Texta;
          letter-spacing: 0px;
          color: #4b5166;
          opacity: 1;
        }

        .curtida {
          margin-left: 3px;
          font: normal normal medium 16px/40px Texta;
          letter-spacing: 0px;
          color: #4b5166;
        }
      }

      .data {
        font: normal normal normal 11px/29px Texta;
        letter-spacing: 0px;
        color: #95aac9;
        opacity: 1;
      }
    }
  }
`;

export const EmptyView = styled.div`
  display: flex;
  align-items: center;

  .text {
    font-size: 20px;
    font-weight: bold;
    color: ${colors.greySubtitle};
    margin-left: 10px;
  }

  .imageArea {
    width: 100px;
    height: auto;

    img {
      width: 100%;
      height: 100%;
    }
  }
`;
