import styled, { css } from 'styled-components';

export const Container = styled.main``;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TabsArea = styled.div``;

export const Cards = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  margin: 25px 0px;

  .cardItem {
    margin: 10px;
  }
`;

export const SearchArea = styled.div`
  ${({ theme }) => css`
    margin-top: 23px;

    p {
      margin-left: 5px;
      margin-top: 10px;
      font-weight: 600;
      font-size: 18px;
      color: ${theme.palette.words.subtitle.natural};
    }
  `}
`;

export const Body = styled.section`
  margin-top: 20px;
  height: 100%;
  opacity: ${props => (props.inSearch ? 0.5 : 1)};
  pointer-events: ${props => (props.inSearch ? 'none' : 'all')};
`;

export const StatusArea = styled.div`
  display: flex;
  margin-right: 40px;

  span {
    margin-left: 10px;
    font-weight: 600;
  }
`;
