import { useLayoutEffect } from 'react';

// Components
import Loading from 'components/Loading';

// Components Material UI
import { SvgIcon } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useTheme } from 'styled-components';
import { ReactComponent as crown } from '../../images/crown.svg';
import * as S from './styled';

// Propriedades da Props data = {
//     nameOne ==> Prioridade de exibição
//     nameTwo ==> Na ausencia do One, exibe o Two (usado para exibir a filial no filtro da distribuidora)
//     position
//     oldPosition ==> Indica as setas se a posição subiu ou caiu em relação a data X
//     logo
//     hours
//     points
// }

// Atenção ==> É IMPORTANTE PASSAR LOADING PARA MANTER A SINCRONIA DAS ANIMAÇÕES DO COMPONENTE

const Podium = ({ data, loading }) => {
  const theme = useTheme();
  const img = require(`images/Onisys_Simbolo.png`);

  if (data && data.length) {
    for (const pod of data) {
      if (pod.logo === null || pod.logo === undefined) pod.logo = img;
    }
  }
  // FUNCIONAMENTO DAS ANIMAÇÕES DO COMPONENTE
  useLayoutEffect(() => {
    if (!loading) {
      const pos1 = document.querySelector('.pos1');
      const pos2 = document.querySelector('.pos2');
      const pos3 = document.querySelector('.pos3');
      const title = document.querySelector('.titlePodium');

      if ((title, pos3, pos2, pos1)) {
        setTimeout(() => {
          title.classList.add('moveTitle');
        }, 500);

        setTimeout(() => {
          title.classList.add('noDisplay');
        }, 1000);

        setTimeout(() => {
          pos3?.classList.add('animeted');
        }, 1000);

        setTimeout(() => {
          pos2?.classList.add('animeted');
        }, 1500);

        setTimeout(() => {
          pos1?.classList.add('animeted');
        }, 2100);
      }
    }
  }, [loading]);

  const calcArrow = position => {
    switch (position) {
      case 'UP':
        return (
          <ArrowDropUpIcon
            className="svg-up"
            htmlColor={theme.palette.semantics.feedback.success.natural}
          />
        );
      case 'DOWN':
        return (
          <ArrowDropDownIcon
            className="svg-up"
            htmlColor={theme.palette.semantics.feedback.attention.natural}
          />
        );
      case 'EQUAL':
        return (
          <CircleIcon
            className="svg"
            htmlColor={theme.palette.semantics.feedback.information.natural}
          />
        );
    }
  };

  return (
    <S.Container>
      {loading ? (
        <Loading />
      ) : (
        <>
          <span className="titlePodium">RANKING</span>
          {data.length > 0 && (
            <S.BoxPosition>
              {data[1] && (
                <div className="userInfo pos2">
                  <div className="diferrencePos">
                    {calcArrow(data[1].oldPosition)}2
                  </div>
                  <span className="ring pos2" key={data[1].position}>
                    <span className="overRing">
                      <div className="imageArea">
                        <img src={data[1].logo} />
                      </div>
                    </span>
                  </span>
                  <div className="footerInfo">
                    <span className="nameBox">
                      {`${
                        data[1].nameOne ? data[1].nameOne : data[1].nameTwo
                      }`.toLowerCase()}
                    </span>
                    <span className="contentBox">
                      {data[1].value || data[1].pontos_km ? (
                        <span className="points">
                          {data[1].value || `${data[1].pontos_km} pontos/km`}
                        </span>
                      ) : (
                        <>
                          <span className="points">
                            {data[1].points} pontos
                          </span>
                          {!!data[1].hours && (
                            <span className="bar">/{data[1].hours} horas</span>
                          )}
                          {!!data[1].km && (
                            <span className="bar">/{data[1].km} Km</span>
                          )}
                        </>
                      )}
                    </span>
                  </div>
                </div>
              )}
              <div className="userInfo pos1">
                <div className="diferrencePos">
                  <SvgIcon component={crown} />
                </div>
                <span className="ring pos1" key={data[0].position}>
                  <span className="overRing">
                    <div className="imageArea">
                      <img src={data[0].logo} />
                    </div>
                  </span>
                </span>
                <div className="footerInfo">
                  <span className="nameBox">
                    {`${
                      data[0].nameOne ? data[0].nameOne : data[0].nameTwo
                    }`.toLowerCase()}
                  </span>
                  <span className="contentBox">
                    {data[0].value || data[0].pontos_km ? (
                      <span className="points">
                        {data[0].value || `${data[0].pontos_km} pontos/km`}
                      </span>
                    ) : (
                      <>
                        <span className="points">{data[0].points} pontos</span>
                        {!!data[0].hours && (
                          <span className="bar">/{data[0].hours} horas</span>
                        )}
                        {!!data[0].km && (
                          <span className="bar">/{data[0].km} Km</span>
                        )}
                      </>
                    )}
                  </span>
                </div>
              </div>
              {data[2] && (
                <div className="userInfo pos3">
                  <div className="diferrencePos">
                    {calcArrow(data[2].oldPosition)}3
                  </div>
                  <span className="ring pos3" key={data[2].position}>
                    <span className="overRing">
                      <div className="imageArea">
                        <img src={data[2].logo} />
                      </div>
                    </span>
                  </span>
                  <div className="footerInfo">
                    <span className="nameBox">
                      {`${
                        data[2].nameOne ? data[2].nameOne : data[2].nameTwo
                      }`.toLowerCase()}
                    </span>
                    <span className="contentBox">
                      {data[2].value || data[2].pontos_km ? (
                        <span className="points">
                          {data[2].value || `${data[2].pontos_km} pontos/km`}
                        </span>
                      ) : (
                        <>
                          <span className="points">
                            {data[2].points} pontos
                          </span>
                          {!!data[2].hours && (
                            <span className="bar">/{data[2].hours} horas</span>
                          )}
                          {!!data[2].km && (
                            <span className="bar">/{data[2].km} Km</span>
                          )}
                        </>
                      )}
                    </span>
                  </div>
                </div>
              )}
            </S.BoxPosition>
          )}
          {data.length <= 0 && (
            <div className="emptyBox">
              <span className="text">
                Nenhum item encontrado nesse período{' '}
              </span>
              <span className="sub">Verifique os filtros selecionados</span>
            </div>
          )}
        </>
      )}
    </S.Container>
  );
};

export default Podium;
