import { formatNewDate } from 'utils/dates';

export const columns = [
  {
    Header: 'Desvio',
    id: 'violacao.tipo_violacao.tipo_violacao_padrao.tipo',
    accessor: d =>
      String(d.violacao?.tipo_violacao?.tipo_violacao_padrao?.tipo ?? '-'),
    sortType: 'basic',
    show: true,
    disableSortBy: false,
  },
  {
    Header: 'Data Desvio',
    id: 'violacao.data_violacao',
    accessor: d =>
      d.violacao?.data_violacao && formatNewDate(d.violacao.data_violacao),
    show: true,
    disableSortBy: false,
    centered: true,
  },
  {
    Header: 'Pontuação',
    id: 'violacao.pontos', // alinhar com back
    accessor: d => d.violacao?.pontos && Number(d.violacao.pontos),
    show: true,
    centered: true,
  },
  {
    Header: 'Ações',
    id: 'acoes',
    accessor: 'acoes',
    show: true,
    disableSortBy: true,
  },
];
