import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import styled, { css } from 'styled-components';
import colors from 'styles/colors';
import { styled as stMuiUI } from '@mui/material/styles';

export const Wrapper = styled.div`
  border: 2px solid ${colors.greyTiny};
  background-color: #fff;
  width: 272px;
  height: 392px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  pointer-events: ${props =>
    props.charge || props.loadingScreen ? 'none' : 'all'};

  .content {
    margin-bottom: ${props => (props.inHover ? '0px' : '-390px')};
  }

  .statusBox {
    opacity: ${props => (props.inHover ? 0 : 1)};
    margin-top: ${props => (props.inHover ? '-300px' : '25px')};
  }
`;

export const Image = styled.div`
  ${({ src, loadingScreen }) => css`
    background: #f6f7f8;
    background-size: cover;
    background-position: center;
    background-image: url(${src});
    width: 100%;
    height: 50%;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    position: absolute;
    top: 0;
    z-index: 1;
    display: flex;
    justify-content: end;
    align-items: top;
    filter: ${props => (props.finished ? 'grayscale(1)' : 'grayscale(0)')};

    .preLoad {
      opacity: ${loadingScreen ? 1 : 0};
      visibility: ${loadingScreen ? 'visible' : 'hidden'};
      width: 100%;
      height: 100%;
      position: absolute;
      transition: all 0.3s ease-in-out;
      background-image: linear-gradient(
        to right,
        #e2e2e8 0%,
        #edeef1 20%,
        #e2e2e8 40%,
        #e2e2e8 100%
      );
      background-size: 80rem 14rem;
      animation: placeholderShimmer 3s linear infinite forwards;

      @keyframes placeholderShimmer {
        0% {
          background-position: -40rem 0;
        }
        100% {
          background-position: 40rem 0;
        }
      }
    }
  `}
`;

export const Ribbon = styled.div`
  background-color: ${props => props.colorBg};
  height: 40px;
  width: 220px;
  transform: rotate(30deg);
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  margin-right: -48px;
  margin-top: 17px;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  color: ${props => props.colorText};
  display: ${props => (props.hasRibbon ? 'flex' : 'none')};
  box-shadow: 0px 1px 2px ${colors.grey};
  opacity: ${props => (props.isLoading ? 0 : 1)};
  transition: all ease-in-out 0.3s;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  bottom: 0;
  z-index: 2;
  transition: all ease-in-out 0.4s;
  background-color: #fff;
  display: flex;
  overflow: hidden;

  .preLoad {
    opacity: ${props => (props.loadingScreen ? 1 : 0)};
    visibility: ${props => (props.loadingScreen ? 'visible' : 'hidden')};
    transition: all 0.3s ease-in-out;
    width: 100%;
    height: 100%;
    position: absolute;
    background-image: linear-gradient(
      to right,
      #f6f7f8 0%,
      #edeef1 20%,
      #f6f7f8 40%,
      #f6f7f8 100%
    );
    background-size: 80rem 14rem;
    animation: placeholderShimmer 3s linear infinite forwards;

    @keyframes placeholderShimmer {
      0% {
        background-position: -40rem 0;
      }
      100% {
        background-position: 40rem 0;
      }
    }
  }

  .screenPrimary {
    width: 100%;
    padding: 15px;
  }

  .screenSecondary {
    width: 100%;
    padding: 3px;
  }

  .title {
    font-size: 16px;
    color: #000;
    font-weight: 600;
  }

  .description {
    margin-top: 10px;
    font-size: 13px;
    font-weight: 500;
    color: ${colors.greyTitle};
    height: 40px;
    height: ${props => (props.inHover ? '140px' : '40px')};
    transition: all ease-in-out 0.4s;
    overflow: hidden;
    word-wrap: break-word;
  }

  .statusBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all ease-in-out 0.4s;

    z-index: 1;

    .finished {
      font-size: 13px;
      font-weight: 600;
      color: ${colors.grey};
      text-align: end;
      width: 100%;
    }
  }
`;

export const TagsArea = styled.div`
  display: flex;
  justify-content: start;
  margin-bottom: 10px;
  gap: 7%;
  height: 24px;
`;

export const Tag = styled.span`
  font-size: 14px;
  font-weight: 600;
  background-color: ${colors.greyBackgroud};
  color: ${colors.greyTitle};
  padding: 2px 8px;
  border-radius: 20px;
  white-space: nowrap;
`;

export const MediaContainer = styled.div`
  margin-top: ${props => (props.inHover ? '280px' : '20px')};
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: all ease-in-out 0.4s;
  z-index: 2;

  .finished {
    font-size: 13px;
    font-weight: 600;
    color: ${colors.grey};
    text-align: end;
    width: 100%;
  }
`;

export const StyledTooltip = stMuiUI(({ className, ...props }) => (
  <Tooltip
    placement="bottom"
    arrow
    {...props}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
    '&::before': {
      backgroundColor: theme.palette.common.white,
      boxShadow: theme.shadows[1],
    },
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 11,
    border: '1px solid #dadde9',
    textTransform: 'capitalize',
    fontWeight: 600,
    color: colors.greySubtitle,
    padding: '0px 15px',
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export const Buttons = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: -50px;
  visibility: ${props => (props.inHover ? 'visible' : 'hidden')};
  opacity: ${props => (props.inHover ? 1 : 0)};
  transition: all ease-in-out 0.4s;

  span {
    font-size: 14px;
    color: ${colors.grey};
    margin-top: ${props => (props.forMe ? '8px' : '15px')};
  }

  .formStatus {
    transform: scale(0.9);
    margin-bottom: 10px;
    align-self: end;
    padding: 0px;

    div {
      margin-right: 0px;
    }

    span {
      margin-top: 0px;
    }
  }

  .warnToast {
    width: 100%;
    margin-top: -10px;
  }

  .finished {
    font-size: 14px;
    font-weight: 600;
    color: ${colors.greySubtitle};
    text-align: end;
    width: 100%;
    margin-bottom: 20px;
  }
`;

export const ProgressBar = styled.span`
  width: 100%;
`;

export const LoadingBox = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.4);
  z-index: 3;
`;

export const TryedArea = styled.div`
  display: flex;
  justify-content: flex-start;

  .txt {
    font-size: 14px;
    font-weight: 600;
    color: ${colors.greyTitle};
    margin-right: 5px;
    margin-top: -1px;
  }

  .MuiSvgIcon-root {
    font-size: 15px;
    margin-right: 2px;
    margin-bottom: -3px;
  }

  .unlimited {
    font-size: 14px;
    font-weight: 600;
    color: ${colors.greySubtitle};
  }
`;
