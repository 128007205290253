import { ReactComponent as user } from 'images/icons/motoristas/perfil/sidebar/user2.svg';
import { ReactComponent as turmas } from 'images/icons/sidebar/drivers.svg';
import { ReactComponent as aprendizado } from 'images/icons/perfil_aluno/book-open-blank-variant.svg';
import { ReactComponent as conquistas } from 'images/icons/perfil_aluno/trophy-variant-outline.svg';
import Info from './info';
import Turmas from './turmas';
import Aprendizado from './aprendizado';
import Certificados from './certificados';

export const pages = [
  {
    value: 0,
    page: 'Informações gerais',
    id: 'gerais',
    linkTo: '/capacitacao/perfil-do-aluno/info-aluno',
    icon: user,
    element: <Info />,
  },

  {
    value: 1,
    page: 'Turmas',
    id: 'page 02',
    linkTo: '/capacitacao/perfil-do-aluno/turmas-aluno',
    icon: turmas,
    element: <Turmas />,
  },
  {
    value: 2,
    page: 'Aprendizado',
    id: 'perfil',
    linkTo: '/capacitacao/perfil-do-aluno/aprendizado-aluno',
    icon: aprendizado,
    element: <Aprendizado />,
  },
  {
    value: 3,
    page: 'Certificados',
    id: 'page 02',
    linkTo: '/capacitacao/perfil-do-aluno/certificados-aluno',
    icon: conquistas,
    element: <Certificados />,
  },
];
