import { Modal } from '@mui/material';
import styled from 'styled-components';
import colors from 'styles/colors';

export const ModalMUI = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
`;

export const Content = styled.div`
  width: 70%;
  max-width: 850px;
  height: 600px;
  background-color: #fff;
  border-radius: 5px;
  overflow-y: scroll;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;

  .right {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .left {
    display: flex;
    justify-content: center;
    align-items: center;

    .title {
      font-weight: 900;
      color: ${colors.greyTitle};
      margin-left: 20px;
      font-size: 25px;
    }

    .imageBox {
      width: 50px;
      height: 50px;
      border-radius: 50px;
      border: 2px solid ${colors.greenMain};
      overflow: hidden;
      padding: 5px;

      img {
        width: 100%;
        object-fit: cover;
      }
    }
  }
`;

export const Body = styled.div``;

export const Table = styled.div``;

export const SearchArea = styled.div`
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderTable = styled.div`
  height: 50px;
  width: 100%;
  background-color: ${colors.greyBackgroud};
  display: flex;
  align-items: center;
  font-weight: bold;
  color: ${colors.grey};
  font-size: 16px;

  .columnHeader {
    display: flex;
    padding: 15px 20px;

    &.flex1 {
      flex: 1;
    }

    &.flex3 {
      flex: 2;
    }
  }

  .center {
    justify-content: center;
  }
`;

export const BodyTable = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  height: 470px;
  display: flex;

  .column {
    padding: 0px 10px;

    &.flex1 {
      flex: 1;
    }

    &.flex3 {
      flex: 2;
    }
  }

  .lineItem {
    border-bottom: 1px solid ${colors.greyBorder};
    padding: 15px 0px;
    height: 70px;
    font-size: 17px;
    font-weight: 500;
    color: ${colors.greyTitle};
    display: flex;
    justify-content: start;
    align-items: center;
    text-transform: capitalize;
  }

  .center {
    justify-content: center;
  }
`;
export const TableArea = styled.div`
  position: relative;

  .coverInput {
    width: 100%;
    height: 100px;
    background-color: #fff;
    position: absolute;
    top: 0;
    z-index: 3;
  }

  .coverInputBottom {
    width: 100%;
    height: 100px;
    background-color: #fff;
    position: absolute;
    bottom: 0;
    z-index: 3;
  }

  .buttonArea {
    display: flex;
    justify-content: flex-end;
    margin-right: 25px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
`;

export const Paragraph = styled.p`
  font-weight: bold;
  color: ${colors.blueTitle};
  font-size: 14px;
  text-align: ${props => (props.center ? 'center' : 'start')};

  &.normal {
    font-size: 16px;
    border-radius: 50px;
    color: ${props => `${props.color}`};
    background-color: ${props => `${props.color}33`};
  }

  &.name {
    text-transform: capitalize;
  }

  &.small {
    font-weight: 500;
    font-size: 12px;
  }

  &.green {
    color: ${colors.greenSucces};
    background-color: ${colors.greenBackground};
    border-radius: 20px;
    text-align: center;
  }

  &.orange {
    color: ${colors.orangeAlert};
    background-color: ${colors.orangeBackground};
    border-radius: 20px;
    text-align: center;
  }

  &.red {
    color: ${colors.redDanger};
    background-color: ${colors.redBackground};
    border-radius: 20px;
    text-align: center;
  }
`;

export const ColDriver = styled.div`
  display: flex;
  flex-direction: column;
  text-transform: capitalize;

  h1 {
    font-size: 14px;
    font-weight: 600;
    color: ${colors.blueTitle};
  }

  &.flex {
    flex-direction: row;
    align-items: center;
  }
`;

export const TableSubname = styled.h3`
  color: ${colors.greySubtitle};
  font-weight: 600;
  font-size: 13px;
  text-align: center;
`;

export const EmptyArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: -80px;

  img {
    width: 200px;
  }

  .emeptyText {
    margin-left: 20px;
    font-weight: 600;
    color: ${colors.greyTitle};
    font-size: 25px;
  }
`;

export const Popup = styled.div`
  display: flex;
  flex-direction: column;
  width: 130px;

  button {
    width: 100%;
    border: none;
    background-color: #fff;
    border-radius: none;
    text-align: start;
    padding: 8px 10px;
    font-weight: 600;
    color: ${colors.greyTitle};
    font-size: 15px;
    transition: all ease 0.2s;

    &:hover {
      background-color: ${colors.greyBorder};
    }
  }
`;
