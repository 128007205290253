/* eslint-disable import/order */

// Styles
import * as S from './styled';

// React
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector, useDispatch } from 'react-redux';
import { setConcluded } from 'store/modules/guia/actions';

// Components
import Tabs from 'components/Tabs';
import { Section } from './Section';
import { ModalPlayer } from './ModalPlayer';
import SelectList from 'components/Inputs/SelectList';
import EmptyDataCard from 'components/Cards/EmptyDataCard';

// Components MUI
import { Divider } from '@mui/material';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';

// Services
import * as services from './services';

import { initialize, initializeTab } from './constants';

const Catalogo = () => {
  // Redux
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth.user);

  // Data States
  const [tabs, setTabs] = useState(initializeTab);
  const [data, setData] = useState(initialize);
  const [percentage, setPercentage] = useState(null);

  // Control States
  const [tab, setTab] = useState('monitoramento');
  const [sections, setSections] = useState([]);
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState(false);
  const [filter, setFilter] = useState({
    categories: '',
    search: '',
  });

  // -----------------------------------------REQUESTS-----------------------------------------
  const { isFetching: isLoading } = useQuery(
    ['catalogo-contents', 3],
    () => services.getContent(user.provider),
    {
      refetchOnWindowFocus: false,
      onSuccess: data => formatInitialData(data.data),
    },
  );

  const formatInitialData = res => {
    let resTabs = [];
    let resData = {};
    let nameSections = [];
    let percents = [];
    let copyRes = JSON.parse(JSON.stringify(res));
    copyRes.forEach(item => {
      let totalItems = 0;
      let totalViewed = 0;
      resTabs.push({
        id: item?.id,
        value: item?.titulo,
        label: item?.descricao,
      });
      resData[item?.titulo] = item?.secao?.filter(section => {
        const steps = section.passos.filter(step => {
          if (
            step.ativa &&
            step.niveis.split(',').includes(user.nivel.toString()) &&
            step.mostrar_catalogo
          ) {
            totalItems++;
            step.respondida && totalViewed++;
            return true;
          }
        });

        if (steps.length > 0) {
          nameSections.push({
            id: section?.id,
            value: section?.titulo,
            env: item.titulo,
          });
          section.passos = steps;
          return true;
        }
      });

      percents.push({
        total: totalItems,
        viewed: totalViewed,
        env: item.titulo,
      });
    });

    const availableTabs = resTabs.filter(t => resData[t.value]?.length > 0);

    setData(resData);
    setTabs(availableTabs);
    setTab(availableTabs[0]?.value);
    setPercentage(percents);
    setSections(nameSections);
  };

  // -----------------------------------------FUNCTIONS-----------------------------------------
  const handleTab = (e, newTab) => {
    setTab(newTab);
    setFilter({ categories: '', search: '' });
  };

  const handleFilter = (value, type) => {
    setFilter({ ...filter, [type]: value });
  };

  const getFilter = () => {
    let res = JSON.parse(JSON.stringify(data?.[tab] || []));
    if (filter.categories) {
      const filterCategories = res.filter(item =>
        filter.categories.split(',').includes(item.id.toString()),
      );
      res = filterCategories;
    }

    if (filter.search) {
      const filterSearch = res.map(item => {
        const steps = item.passos.filter(step =>
          step.titulo.toLowerCase().includes(filter.search.toLowerCase()),
        );
        item.passos = steps;
        return item;
      });
      res = filterSearch;
    }

    res = res.filter(item => item.passos.length > 0);

    return res;
  };

  // -----------------------------------------EXECUTE-----------------------------------------
  const execute = step => {
    let totalItems = 0;
    let totalViewed = 0;
    const newTab = data[tab].map(section => {
      const newSteps = section.passos.map(item => {
        if (item.id === step?.id && !item.respondida) {
          services
            .responseGuia(user.provider, {
              id: item.id,
              guia: item.id_guia_conteudo,
            })
            .then(res => {
              res?.conclusao_total_guias &&
                dispatch(setConcluded(res?.conclusao_total_guias));
            });
          item.respondida = true;
        }
        totalItems++;
        item.respondida && totalViewed++;
        return item;
      });
      section.passos = newSteps;
      return section;
    });

    setPercentage(
      percentage.map(item => {
        if (item.env === tab) {
          item.total = totalItems;
          item.viewed = totalViewed;
        }
        return item;
      }),
    );

    setData({ ...data, [tab]: newTab });
  };

  // -----------------------------------------RENDER-----------------------------------------
  return (
    <S.Container>
      <S.Header>
        <S.Infos>
          <h1>Vídeos Tutoriais</h1>
          <p>Um panorama completo para você começar com o Onisys</p>
        </S.Infos>
        <S.Percents
          percent={Math.round(
            (percentage?.find(item => item.env === tab)?.viewed /
              percentage?.find(item => item.env === tab)?.total) *
              100,
          )}
        >
          <S.Loading isLoading={isLoading} />
          <div className="bar">
            <div className="fill" />
          </div>
          <div className="infos">
            {percentage?.find(item => item.env === tab)?.viewed || 0}/
            {percentage?.find(item => item.env === tab)?.total || 0} completos
          </div>
        </S.Percents>
      </S.Header>
      <S.TabArea>
        <S.Loading isLoading={isLoading} />
        <Tabs
          items={tabs}
          value={tab}
          onChange={handleTab}
          disabled={isLoading}
        />
        <Divider />
      </S.TabArea>
      <S.SearchArea>
        <div className="grid left">
          <SelectList
            label="Categorias"
            data={sections.filter(item => item.env === tab)}
            value={filter?.categories}
            fieldName="categoria"
            handleChange={value => handleFilter(value, 'categories')}
            selectAll
            disabled={isLoading}
          />
        </div>
        <div className="grid right">
          <SearchRoundedIcon />
          <input
            className="search"
            placeholder="O que você quer aprender?"
            onChange={e => handleFilter(e.target.value, 'search')}
            value={filter?.search}
            disabled={isLoading}
          />
        </div>
      </S.SearchArea>
      <S.ContentArea>
        {getFilter()?.map(item => (
          <Section
            key={item.id}
            item={item}
            open={open}
            setOpen={setOpen}
            isLoading={isLoading}
          />
        ))}
        {!isLoading && getFilter()?.length === 0 && (
          <EmptyDataCard
            image="frota.png"
            title="Ops! Não encontramos nenhum vídeo."
            subtitle="Estamos sempre atualizando nosso conteúdo, volte mais tarde!"
          />
        )}
      </S.ContentArea>
      <ModalPlayer
        mode={!mode}
        open={!!open}
        item={open}
        execute={execute}
        handleClose={() => {
          setMode(!mode);
          setOpen(null);
        }}
      />
    </S.Container>
  );
};

export default Catalogo;
