import React, { useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Tooltip, SvgIcon } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useTheme } from 'styled-components';
import * as S from './styled';

/*
data: lista de objetos com {value: valor do id correspondente,
                            name: string do nome do objeto selecionado},
value: data[].value
label: Descrição acima do select (não obrigatório)
hasClear:
*/

const SelectInput = React.forwardRef(
  (
    {
      value = '',
      handleChange,
      data,
      placeholder,
      clearLabel,
      label,
      required,
      markselected,
      tooltip,
      disabled,
      color = null,
      colorDisabled = null,
      ...props
    },
    ref,
  ) => {
    const theme = useTheme();
    return (
      <S.Container>
        {label && (
          <S.Label color={color}>
            {label}
            <S.Required active={required}>*</S.Required>
            {tooltip && (
              <Tooltip title={tooltip} placement="top">
                <SvgIcon fontSize="small" component={InfoOutlinedIcon} />
              </Tooltip>
            )}
          </S.Label>
        )}
        <Select
          ref={ref}
          color="secondary"
          disabled={disabled}
          value={value}
          onChange={handleChange}
          displayEmpty
          fullWidth
          sx={{
            background:
              markselected && Boolean(value)
                ? `${theme.palette.system.divider}AA`
                : 'transparent',
            '.MuiOutlinedInput-notchedOutline': {
              border: disabled
                ? 'none'
                : `2px solid ${theme.palette.brand.primary.light}`,
            },
            '.MuiOutlinedInput-notchedOutline.error': {
              border: `2px solid ${theme.palette.semantics.feedback.attention.natural}`,
            },
            '.Mui-disabled': {
              backgroundColor:
                colorDisabled || theme.palette.semantics.feedback.unknown.light,
              borderRadius: '5px',
            },
          }}
          {...props}
        >
          {clearLabel && (
            <MenuItem
              value=""
              key="undefined"
              children={value ? clearLabel : placeholder}
            />
          )}
          {data.map(item => (
            <MenuItem
              value={item.value}
              key={item.value}
              style={{ display: 'flex', alignItems: 'center', border: 'none' }}
              disabled={!!item.disabled}
            >
              {item.image && (
                <img
                  style={{
                    width: '38px',
                    height: '38px',
                    borderRadius: '38px',
                    marginRight: '10px',
                    border: `2px solid ${theme.palette.brand.secondary.natural}`,
                  }}
                  src={item.image}
                />
              )}
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </S.Container>
    );
  },
);

export default SelectInput;
