import * as React from 'react';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

const Drawer = ({ open, onClose, onOpen, children }) => {
  return (
    <div>
      <SwipeableDrawer
        anchor="right"
        open={open}
        disableSwipeToOpen={false}
        onClose={() => onClose()}
        onOpen={() => onOpen()}
      >
        {children}
      </SwipeableDrawer>
    </div>
  );
};

export default Drawer;
