// Styles
import { useTheme } from 'styled-components';

// React
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Coponents
import Tabs from 'components/Tabs';
import ConfirmModal from 'components/ConfirmModal';
import DefaultButton from 'components/Buttons/Default';
import { DefaultTable } from 'components/_Table/templates/default';

// Components Material UI
import { AddCircleOutline } from '@mui/icons-material';
import { EditOutlined, DeleteOutline } from '@mui/icons-material';
import { Divider, Grid } from '@mui/material';
import * as C from '../styled';
import * as S from './styled';

// Utils
import { columnsTransportador, columnsEmbarcador } from './columns';
import { tabs } from './constants';
import * as services from './services';
import { trackEvent } from 'utils/mixpanel';
import { useSelector } from 'react-redux';

export const Usuarios = () => {
  const user = useSelector(state => state.auth?.user?.user);
  const isProvider = user.provider;
  const navigate = useNavigate();
  const theme = useTheme();
  // GENERAL STATES
  const [ativos, setAtivos] = useState([]);
  const [inativos, setInativos] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);

  // ---------------------------TABLE FORMATED--------------------------------//
  const handlePageTab = (event, newValue) => {
    setPageTab(newValue);
  };
  const columns = isProvider ? columnsEmbarcador : columnsTransportador;

  // ---------------------------ACTIONS COLUNM--------------------------------//
  const [idAtivar, setIdAtivar] = useState(null);
  const [idDesativar, setIdDesativar] = useState(null);
  const [modalText, setModalText] = useState('');
  const [companiesList, setCompaniesList] = useState([]);
  const [pageTab, setPageTab] = useState(0);

  const handleEditar = id => {
    navigate(`/configuracoes/usuarios/${id}`);
  };

  const handleSetDesativar = id => {
    setLoadingTable(true);
    let ativo = null;
    for (const i in ativos) {
      if (ativos[i].id === Number(id)) {
        ativo = ativos[i];
        setIdDesativar(ativos[i]);
      }
    }

    setModalText(`Tem certeza que deseja desativar?`);
    setCompaniesList(formatText('desativado', ativo));
    setLoadingTable(false);
  };

  const handleSetAtivar = id => {
    setLoadingTable(true);
    let inativo = null;
    for (const i in inativos) {
      if (inativos[i].id === Number(id)) {
        inativo = inativos[i];
        setIdAtivar(inativos[i]);
      }
    }

    setModalText(`Tem em certeza que deseja ativar?`);
    setCompaniesList(formatText('ativado', inativo));
    setLoadingTable(false);
  };

  const formatText = (text, ativar) => {
    return (
      <>
        <span>
          O seguinte cliente sera
          <span style={{ fontWeight: '900' }}> {text.toUpperCase()}</span>:
        </span>
        <ul style={{ listStyle: 'disc', marginLeft: '15px' }}>
          <li>{ativar?.nome || ''}</li>
        </ul>
      </>
    );
  };

  // ações por linha
  const handleAtivarInativar = id => {
    if (pageTab == 0) {
      handleSetDesativar(id);
    } else {
      handleSetAtivar(id);
    }
  };

  const actions = [
    {
      title: 'Ativar/Desativar',
      function: handleAtivarInativar,
    },
    {
      title: 'Editar',
      function: handleEditar,
    },
    {
      title: 'Historico',
      function: id => navigate(`/logs/usuario/${id}`),
    },
  ];
  // -------------------------REQUESTS--------------------------//
  const fetchData = async () => {
    setLoadingTable(true);
    const res = await services.requestUsers();
    if (res.success) {
      const { data } = res;
      const actives = [];
      const disabled = [];
      for (const i in data) {
        if (data[i].status === 'ATIVO') {
          actives.push(data[i]);
        } else if (data[i].status === 'INATIVO') {
          disabled.push(data[i]);
        }
      }

      setAtivos(actives);
      setInativos(disabled);
    } else {
      toast.error(res.message);
    }
    setLoadingTable(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const confirmActive = async () => {
    setLoadingTable(true);
    const dataItem = {
      ...idAtivar,
      status: 'ATIVO',
    };

    const res = await services.updateUser(dataItem);

    if (res.success) {
      toast.success(res.message);
      setIdAtivar(null);
      fetchData();
    } else {
      toast.error(res.message);
    }
    setLoadingTable(false);
  };

  const confirmDisable = async () => {
    setLoadingTable(true);
    const dataItem = {
      ...idDesativar,
      status: 'INATIVO',
    };

    const res = await services.updateUser(dataItem);

    if (res.success) {
      toast.success(res.message);
      setIdDesativar(null);
      fetchData();
    } else {
      toast.error(res.message);
      setIdDesativar(null);
    }
    setLoadingTable(false);
  };

  return (
    <S.Container>
      <>
        <C.TitleArea className="space">
          <span className="textArea">Usuários</span>
          <DefaultButton
            startIcon={<AddCircleOutline />}
            size="medium"
            sx={{ marginLeft: '10px' }}
            onClick={() => navigate('/configuracoes/usuarios/criar')}
          >
            ADICIONAR USUÁRIO
          </DefaultButton>
        </C.TitleArea>
        <Grid marginBottom={1} item md={12}>
          <Tabs value={pageTab} items={tabs} onChange={handlePageTab} />
          <Divider />
        </Grid>
        <Grid item md={12}>
          <DefaultTable
            data={pageTab === 0 ? ativos : inativos}
            columns={columns}
            actions={actions}
            searchKeys={['nome']}
            loading={loadingTable}
            placeholder="Buscar por nome"
            sortBy={{ id: 'ativo', order: 'ASC' }}
            searchEvent={search =>
              trackEvent(user, 'Busca Gestão de Usuarios', null, search)
            }
            empty={{
              title: 'Nenhum usuário encontrado',
              description: 'Você ainda não cadastrou nenhum Usuário.',
              image: 'frota.png',
            }}
          />
        </Grid>

        {
          /* Confirmação de ativacao */
          idAtivar && (
            <ConfirmModal
              open={!!idAtivar}
              handleClose={() => setIdAtivar(null)}
              title={modalText}
              titleIcon={
                <EditOutlined
                  sx={{ color: theme.palette.brand.secondary.natural }}
                  fontSize="medium"
                />
              }
              subtitle={companiesList}
              buttonText="Confirmar"
              onClick={() => confirmActive()}
              loading={false}
            />
          )
        }

        {
          /* Confirmação de desativacao */
          idDesativar && (
            <ConfirmModal
              open={!!idDesativar}
              handleClose={() => setIdDesativar(null)}
              title={modalText}
              titleIcon={
                <DeleteOutline
                  sx={{
                    color: theme.palette.semantics.feedback.attention.natural,
                  }}
                  fontSize="medium"
                />
              }
              subtitle={companiesList}
              buttonText="Confirmar"
              onClick={() => confirmDisable()}
              loading={false}
            />
          )
        }
      </>
    </S.Container>
  );
};
