import React from 'react';
import { useQuery } from 'react-query';
import { requestEmpresasPerfil } from '../../services';

import * as S from './styled';
import { RadarGraph } from '../../../../Components/RadarGraph';

export const Radar = ({ id, filters }) => {
  const { isFetching, data } = useQuery(
    ['empresas-perfil', id, filters],
    () => filters && requestEmpresasPerfil(id, filters),
    {
      refetchOnWindowFocus: false,
      enabled: !!id,
    },
  );

  return (
    <S.Container>
      <S.Header>
        <S.Title>Maiores fatores de risco</S.Title>
      </S.Header>
      <RadarGraph
        loading={isFetching}
        series={['previousValue', 'value']}
        labels={['Período anterior', 'Período atual']}
        data={data?.data?.data ?? []}
        margin={{
          top: 10,
          right: 20,
          left: 20,
          bottom: 10,
        }}
        axisMax={1}
        colors="radar"
      />
    </S.Container>
  );
};
