import api from 'services/api';

// #TODO Verificar se todas essas funções são usadas ao final da refatoração

// ---------------------------INDEX REQUESTS-----------------------------//

export const requestAvaliacoesList = async query => {
  const res = await api.get('/evaluations', { params: query });
  return res.data;
};

export const requestCards = async query => {
  const res = await api.get('/evaluations-cards', { params: query });
  return res;
};

export const requestResponsibles = async id => {
  const res = await api.get(`/select-responsibles/${id}`);
  return res;
};

export const archiveEvaluation = async data => {
  try {
    const res = await api.put(`/evaluations/${data.id}`, data);
    return res.data;
  } catch (error) {
    return false;
  }
};

export const signatureEvaluation = async data => {
  try {
    const res = await api.put(`/evaluations/${data.id}`, data);
    return res.data;
  } catch (error) {
    return false;
  }
};

export const selectWitnessEvaluation = async data => {
  try {
    const res = await api.put(`/select-witness-evaluations`, data);
    return res;
  } catch (error) {
    return false;
  }
};

export const viewEvaluation = async id => {
  try {
    const res = await api.put(`/view-evaluation/${id}`);
    return res;
  } catch (error) {
    return false;
  }
};

export const requestExcel = async query => {
  const res = await api.get('/excel/evaluations', { params: query });
  return res;
};
// ---------------------------------------------------------------------//
