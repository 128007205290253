export const columns = [
  {
    header: 'Id',
    id: 'id',
    sort: false,
    type: 'number',
    width: 70,
    align: 'center',
  },

  {
    header: 'Nome',
    id: 'nome',
    type: 'string',
    picture: 'logo',
    sort: false,
  },
  {
    header: 'Status',
    id: 'ativo',
    type: 'string',
    sort: true,
    width: 120,
    align: 'center',
  },
];
