import produce from 'immer';
import * as types from './types';

const INITIAL_STATE = {
  responsavel: '',
  revisaoTab: 0,
};

export default function filterRevisaoOperacao(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case types.SET_FILTER_REVISAO_OPERACAO: {
        draft.responsavel = action.payload.query.responsavel;
        break;
      }
      case '@filterRevisaoOperacao/CHANGE_TAB_REVISAO_OPERACAO': {
        draft.revisaoTab = action.payload.tab;
        break;
      }
    }
  });
}
