import { InformacoesGerais } from './InfoGerais';
import { Requisitos } from './Requisitos';
import { Detalhes } from './Detalhes';
import { Curriculos } from './Curriculos';

export const steps = [
  {
    title: 'Informações Gerais',
    pageTitle: 'Informações Gerais',
    page: 'Informações Gerais',
    step: 1,
    value: 0,
    Component: changed => InformacoesGerais(changed),
  },
  {
    title: 'Requisitos',
    pageTitle: 'Requisitos e Benefícios',
    page: 'Requisitos e Benefícios',
    step: 2,
    value: 1,
    Component: changed => Requisitos(changed),
  },
  {
    title: 'Detalhes',
    pageTitle: 'Detalhes da Vaga',
    page: 'Detalhes da Vaga',
    step: 3,
    value: 2,
    Component: changed => Detalhes(changed),
  },
  {
    title: 'Currículos',
    pageTitle: 'Currículos',
    page: 'Currículos',
    step: 4,
    value: 3,
    Component: changed => Curriculos(changed),
  },
];
