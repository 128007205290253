import React from 'react';

import * as S from './styled';

export const RoundedTabs = ({ value, onChange, items = [] }) => {
  return (
    <S.Container>
      {items.map(item => (
        <S.Tab
          key={item.value}
          onClick={() => {
            if (onChange instanceof Function) onChange(item.value);
          }}
          active={item.value === value}
        >
          {item.label ?? ''}
        </S.Tab>
      ))}
    </S.Container>
  );
};
