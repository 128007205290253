import styled, { css } from 'styled-components';

export const Label = styled.label`
  ${({ theme, ...props }) => css`
    display: flex;
    align-items: center;

    input {
      display: none;
    }

    .background {
      height: 40px;
      width: 70px;
      border-radius: 100px;
      background-color: ${props.check
        ? theme.palette.semantics.feedback.success.natural
        : theme.palette.semantics.feedback.unknown.natural};
      position: relative;
      display: flex;
      align-items: center;
      padding: 3px;
      cursor: pointer;

      .circle {
        height: 35px;
        width: 35px;
        border-radius: 50px;
        background-color: ${theme.palette.system.highlight};
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all ease 0.5s;
        margin-left: ${props.check ? '30px' : '0px'};
      }
    }

    .textSwitch {
      margin-left: 10px;
      font-weight: 700;
      color: ${theme.palette.semantics.feedback.unknown.dark};
    }
  `}
`;
