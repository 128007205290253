import api from '../../../services/api';

export const viewAlert = async id => {
  const res = await api.post(`/alertas/${id}/view`, { tipo: 'RESOLUCAO' });
  return res;
};

export const ignoreAlert = async id => {
  const res = await api.post(`/alertas/${id}/view`, { tipo: 'IGNORAR' });
  return res;
};
