/* eslint-disable import/no-mutable-exports */

let firebaseConfig = {};

switch (process.env.REACT_APP_ENV) {
  case 'production':
  case 'piloto':
    firebaseConfig = {
      apiKey: 'AIzaSyBL1ov5_CzRqW12Uj-yBcGg_7RXaRq6d94',
      authDomain: 'onisys.firebaseapp.com',
      databaseURL: 'https://onisys.firebaseio.com/',
      projectId: 'onisys',
      storageBucket: 'onisys.appspot.com',
      storageBucketVideos: 'onisys_transporte',
      messagingSenderId: '663602712783',
      appId: '1:663602712783:web:9e4ba3dc0902721f7367ba',
      measurementId: 'G-5WLHTEYLCE',
    };
    break;
  case 'development':
    firebaseConfig = {
      apiKey: 'AIzaSyBst7BhcgfFH19C0fDbIwdGFxevuy4DJPc',
      authDomain: 'onisysweb.firebaseapp.com',
      projectId: 'onisysweb',
      storageBucket: 'onisysweb.appspot.com',
      messagingSenderId: '633547516129',
      appId: '1:633547516129:web:5c926b5267278aab87afa0',
      // storageBucketVideos: "testes_videos",
      storageBucketVideos: 'videos_avaliacao',
      // databaseURL: "https://onisyswebhom.firebaseio.com",
      // storageBucket: "onisyswebhom.appspot.com",
      // messagingSenderId: "801771076759",
      // measurementId: "G-00RBB6CY0Z"
    };
}

export { firebaseConfig };

// conexão remote config firebase (whitelabel)
const whitelabelConfig = {
  apiKey: 'AIzaSyBI5jeTAJYJQ3pxTSYQXwrxT4Qk1afeBzU',
  authDomain: 'whitelabels-95f22.firebaseapp.com',
  projectId: 'whitelabels-95f22',
  storageBucket: 'whitelabels-95f22.appspot.com',
  messagingSenderId: '320655345868',
  appId: '1:320655345868:web:7982233f08b64ab5bc1a69',
  measurementId: 'G-EHVJQ2TMMD',
};
export { whitelabelConfig };
