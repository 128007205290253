import { Controller } from 'react-hook-form';

import { Tag, TagContainer, TagLabel } from 'components/Tags';

const Condicoes = ({ path, control, disable }) => {
  return (
    <>
      <TagLabel>Condições</TagLabel>
      <TagContainer>
        <Controller
          control={control}
          name={`${path}.pista_molhada`}
          render={({ field: { onChange, value } }) => (
            <>
              <Tag
                selected={value === false}
                onClick={() =>
                  !disable && onChange(value === false ? null : false)
                }
              >
                Pista Seca
              </Tag>
              <Tag
                selected={value === true}
                onClick={() =>
                  !disable && onChange(value === true ? null : true)
                }
              >
                Pista Molhada
              </Tag>
            </>
          )}
        />
        <Controller
          control={control}
          name={`${path}.carregado`}
          render={({ field: { onChange, value } }) => (
            <>
              <Tag
                selected={value === false}
                onClick={() =>
                  !disable && onChange(value === false ? null : false)
                }
              >
                Veículo Vazio
              </Tag>
              <Tag
                selected={value === true}
                onClick={() =>
                  !disable && onChange(value === true ? null : true)
                }
              >
                Veículo Carregado
              </Tag>
            </>
          )}
        />
      </TagContainer>
    </>
  );
};

export default Condicoes;
