import produce from 'immer';
import * as types from './types';

const INITIAL_STATE = {
  status: '',
  responsavel: '',
  cidade: '',
};

export default function filterVagas(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case types.SET_FILTER_VAGAS: {
        draft.status = action.payload.query.status;
        draft.responsavel = action.payload.query.responsavel;
        draft.cidade = action.payload.query.cidade;
        break;
      }
    }
  });
}
