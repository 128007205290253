import api from 'services/api';

// ------------------------------------ENDPOINTS DE LAYOUT-----------------------------------------//

export const requestLayouts = async query => {
  const res = await api.get(`provider/layout`, { params: query });
  return res;
};

export const requestCards = async query => {
  const res = await api.get('/provider/layout-cards', { params: query });
  return res;
};

export const requestExcel = async query => {
  const res = await api.get('/excel-provider/layout', { params: query });
  return res;
};

export const requestCaeExcel = async query => {
  const res = await api.get('/excel-provider/cae', { params: query });
  return res;
};

export const seguirPosto = async id => {
  const res = await api.put(`/layout/seguir/${id}`);
  return res;
};

export const desseguirPosto = async id => {
  const res = await api.put(`/layout/desseguir/${id}`);
  return res;
};

export const getSeguidores = async id => {
  const res = await api.get(`/layout/seguidores/${id}`);
  return res;
};

export const updateEmails = async (id_posto, emails) => {
  const body = { emails };
  const res = await api.put(`/layout/emails/${id_posto}`, body);
  return res;
};
