import { Grid } from '@mui/material';
import { formatNewDate, formatNewHourClean } from 'utils/dates';
import { firestorage } from 'utils/firebase';
import { useCallback } from 'react';
import MapDesvio from 'pages/TelemetriaDesvios/MapDesvio';

// Columns

import * as S from './styled';

export const ExportToPdf = ({ infraction, printRef }) => {
  const logo = require('images/onisys.png');

  const getFileName = useCallback(fileUrl => {
    try {
      let newName = '-';
      if (fileUrl) newName = firestorage.refFromURL(fileUrl)?.name;
      return newName;
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <S.ModalMUI onClose={() => {}} disableEscapeKeyDown>
      <S.Container ref={printRef}>
        <S.Infos>
          <div className="formIdent">
            <img src={logo} />
          </div>

          <div className="formIdent">
            <div className="titleForm">
              <span>
                Empresa: {infraction?.viagem?.motorista?.filial?.empresa?.nome}
              </span>
            </div>
            <div className="titleForm">
              <span>Desvio: {infraction?.id}</span>
            </div>
            {infraction?.distribuidora?.logo_url && (
              <div className="logoArea">
                <img src={infraction?.distribuidora?.logo_url} />
              </div>
            )}
          </div>
        </S.Infos>
        <S.CardHeader>
          <h1>Informações do desvio</h1>
        </S.CardHeader>
        <S.Header>
          <div className="topInfos">
            <div className="coverArea">
              {infraction && (
                <MapDesvio
                  position={{
                    lat: parseFloat(infraction.latitude),
                    lng: parseFloat(infraction.longitude),
                  }}
                />
              )}
            </div>
            <div className="textsArea">
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <div className="normalText">Empresa:</div>
                  <S.InputToPDF>
                    {infraction?.viagem?.motorista?.filial?.empresa?.nome}
                  </S.InputToPDF>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className="normalText">Filial:</div>
                  <S.InputToPDF>
                    {infraction?.viagem?.motorista?.filial?.nome}
                  </S.InputToPDF>
                </Grid>
                <Grid item xs={12} md={12}>
                  <div className="normalText">Desvio:</div>
                  <S.InputToPDF>
                    {infraction?.tipo_violacao?.tipo_violacao_padrao?.tipo}
                  </S.InputToPDF>
                </Grid>
                <Grid item xs={12} md={4}>
                  <div className="normalText">Data do desvio:</div>
                  <S.InputToPDF>
                    {(infraction?.data_violacao &&
                      formatNewHourClean(infraction?.data_violacao, true)) ||
                      '-'}
                  </S.InputToPDF>
                </Grid>
                <Grid item xs={12} md={4}>
                  <div className="normalText">Pontuação:</div>
                  <S.InputToPDF>
                    {` ${infraction?.pontos || '-'}`}
                    {'  Pontos'}
                  </S.InputToPDF>
                </Grid>
                <Grid item xs={12} md={4}>
                  <div className="normalText">Criticidade:</div>
                  <S.InputToPDF>
                    {` ${infraction?.criticidade || '-'}`}
                  </S.InputToPDF>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className="normalText">Tipo de Veículo:</div>
                  <S.InputToPDF>
                    {infraction?.viagem?.caminhao?.tipo_veiculo?.tipo || '-'}
                  </S.InputToPDF>
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className="normalText">Placa:</div>
                  <S.InputToPDF>
                    {infraction?.viagem?.caminhao?.placa || '-'}
                  </S.InputToPDF>
                </Grid>

                <Grid item xs={12} md={12}>
                  <div className="normalText">Localização:</div>
                  <S.InputToPDF>
                    {infraction?.endereco
                      ? infraction.endereco
                      : infraction?.latitude
                      ? `lat: ${infraction.latitude}, lng: ${infraction.longitude}`
                      : 'Não informado'}
                  </S.InputToPDF>
                </Grid>
                <Grid item xs={12} md={12}>
                  <div className="normalText">Condições:</div>
                  <S.InputToPDF>
                    {`${
                      infraction?.carregado
                        ? 'Veículo carregado; '
                        : infraction?.carregado === false
                        ? 'Veículo vazio; '
                        : ''
                    }${
                      infraction?.pista_molhada
                        ? 'Pista molhada;'
                        : infraction?.pista_molhada === false
                        ? 'Pista seca;'
                        : ''
                    }`}
                  </S.InputToPDF>
                </Grid>
              </Grid>
            </div>
          </div>
        </S.Header>
        <S.CardHeader>
          <h1>Informações Complementares</h1>
        </S.CardHeader>
        <S.Header>
          <div className="bottomInfos">
            <Grid container spacing={1}>
              <Grid item xs={12} md={8}>
                <div className="normalText">Responsável:</div>
                <S.InputToPDF>
                  {infraction?.responsavel?.nome || '-'}
                </S.InputToPDF>
              </Grid>
              <Grid item xs={12} md={4}>
                <div className="normalText">Previsão de conclusão:</div>
                <S.InputToPDF>
                  {(infraction?.data_previsao &&
                    formatNewDate(infraction?.data_previsao)) ||
                    '-'}
                </S.InputToPDF>
              </Grid>
              <Grid item xs={12} md={12}>
                <div className="normalText">Plano de ação:</div>
                <S.InputToPDF lines={4}>
                  {infraction?.plano_de_acao || '-'}
                </S.InputToPDF>
              </Grid>
              <Grid item xs={12} md={12}>
                <div className="normalText">Arquivo:</div>
                <S.InputToPDF>
                  {getFileName(infraction?.arquivo_observacao)}
                </S.InputToPDF>
              </Grid>
              <Grid item xs={12} md={12}>
                <div className="normalText">Fator de Risco:</div>
                <S.InputToPDF>{infraction?.fator_de_risco || '-'}</S.InputToPDF>
              </Grid>
              {infraction?.observacao_exclusao && (
                <Grid item xs={12} md={12}>
                  <div className="normalText">Observações:</div>
                  <S.InputToPDF>
                    {infraction?.observacao_exclusao || '-'}
                  </S.InputToPDF>
                </Grid>
              )}
            </Grid>
          </div>
        </S.Header>
      </S.Container>
    </S.ModalMUI>
  );
};
