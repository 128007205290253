import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { auth } from 'utils/firebase';
import firebase from 'firebase/compat/app';
import { updatePageMap } from 'store/modules/pageMap/actions';

import localStorage from 'redux-persist/es/storage';
import { signInSuccess, signFailure, signUpSuccess } from './action';
import api from '../../../services/api';
import { trackEvent } from '../../../utils/mixpanel';

export function* signIn({ payload }) {
  try {
    const { email, password } = payload;
    yield auth
      .setPersistence(firebase.default.auth.Auth.Persistence.LOCAL)
      .then(() => {
        return auth.signInWithEmailAndPassword(email, password);
      })
      .catch(error => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(error, 'errors');
      });
    const data = yield auth.currentUser;

    const token = yield auth.currentUser.getIdToken();

    const user = {
      id: data.uid,
    };
    api.defaults.headers.Authorization = `Bearer ${token}`;

    const res = yield call(api.get, `/users/${data.uid}`);

    // Segurança para tratar de erros intenos do banco (**ausência de dados do usuario)
    if (res.data.success) {
      trackEvent(res.data.userInfo.user, 'Login');
      yield put(signInSuccess(token, user, res.data.userInfo));
      yield put(updatePageMap());
    } else {
      toast.error(res.data.message);
      yield put(signFailure());
      auth.signOut();
    }
  } catch (err) {
    console.log(err);
    toast.error('Falha na autenticação, verifique seus dados');
    yield put(signFailure());
  }
}

export function* signInMicrosoft({ payload }) {
  try {
    const { uid, email, credential } = payload;

    const idToken = credential.idToken;
    const user = {
      id: uid,
    };

    api.defaults.headers.Authorization = `Bearer ${idToken}`;

    const verificaFirebase = yield call(api.post, `/user/loginAd`, {
      uid,
      email,
      credential,
    });

    if (!verificaFirebase.data.success) {
      toast.error(res.data.message);
      yield put(signFailure());
      auth.signOut();
      return;
    }

    const res = yield call(api.get, `/users/${uid}`);

    // Segurança para tratar de erros intenos do banco (**ausência de dados do usuario)
    if (res.data.success) {
      trackEvent(res.data.userInfo.user, 'LoginMicrosoft');
      yield put(signInSuccess(idToken, user, res.data.userInfo));
    } else {
      toast.error(res.data.message);
      yield put(signFailure());
      auth.signOut();
    }
  } catch (err) {
    console.log(err);
    toast.error('Falha na autenticação, verifique seus dados');
    yield put(signFailure());
  }
}

export function* signUp({ payload }) {
  try {
    const { firstName, lastName, email, password, confirmPassword } =
      payload.data;
    const { indicated } = payload;
    yield call(api.post, `api/users?indicated=${indicated}`, {
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
    });
    toast.success('Pronto! Você foi cadastrado com sucesso.');
    yield put(signUpSuccess());
    // history.push("/");   TRATAR NO COMPONENTE  history.push em DESUSO
  } catch (err) {
    toast.error('Falha no cadastro, verifique seus dados');
    // history.push("/cadastrar");  TRATAR NO COMPONENTE  history.push em DESUSO
    yield put(signFailure());
  }
}

export function signOut() {
  window.localStorage.clear();
  localStorage.removeItem(process.env.REACT_APP_REDUX_KEY);
  const signOutMethod = Promise.resolve(auth.signOut());
}

export default all([
  // takeLatest("persist/REHYDRATE", setToken),
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('@auth/SIGN_IN_MICROSOFT', signInMicrosoft),
  takeLatest('@auth/SIGN_OUT', signOut),
  takeLatest('@auth/SIGN_UP_REQUEST', signUp),
  // takeLatest("@auth/REFRESH_REQUEST", refreshToken),
]);
