import { order } from './order';
import { offSet } from './offSet';
import { filter } from './filter';
import { limitText } from './limit';
import { valueType } from './valueType';
import { localFilter } from './localFilter';

import { Switch } from '../components/Body/Switch';

export const generatorColumns = ({
  sort,
  state,
  theme,
  local,
  loading,
  emptyFill,
  data = [],
  columns = [],
  hideLines = [],
  searchKeys = [],
}) => {
  if (loading) {
    data = local
      ? Array(state.pageSize * (state.pageIndex + 1) || 10).fill({})
      : Array(state.pageSize).fill({});
  }

  data = data?.filter(item => !hideLines?.includes(item.id));

  const tableData = [];
  let total = 0;

  columns?.forEach(column => {
    const key = column.id;
    const clData = [];
    let finalData = data;
    total = finalData?.length;

    if (local) {
      const columnType = columns.find(c => c.key === sort.id)?.type;
      finalData = order(finalData, sort.id, sort.order, columnType);
      finalData = filter(finalData, state, searchKeys);
      finalData = localFilter(finalData, state);
      total = finalData?.length;
      finalData = offSet(finalData, state);
    }

    finalData?.forEach((line, index) => {
      // Format keys intro object
      const item = { ...line };

      if (key?.includes('.')) {
        const keys = key.split('.');
        let value = item;
        keys?.forEach(k => {
          value = value?.[k];
        });
        item[key] = value;
      }

      // Default object
      const object = {
        id: index,
        original: item.id,
        value: String(
          valueType(column?.type, item?.[key])?.toString() || emptyFill,
        )?.toLowerCase(),
        item,
      };

      // Custom value
      if (column?.value) {
        if (loading) {
          object.value = '-';
        } else {
          object.value = String(
            valueType(
              column?.type,
              column.value(item?.[key], item),
            ).toString() || emptyFill,
          )?.toLowerCase();
        }
      }

      // SubRow
      if (column.subRow) {
        if (loading) {
          object.subRow = '-';
        } else {
          object.subRow = `${column.subRow.prefix || ''} ${limitText(
            (column?.subRow?.value &&
              valueType(
                column?.subRow?.type,
                column?.subRow?.value(item?.[key], item),
              )) ||
              valueType(column?.subRow?.type, item[column.subRow.key]) ||
              emptyFill,
          )}`?.toLowerCase();
        }
      }

      // Picture
      if (column?.picture) {
        object.picture = {
          show: true,
          src: item[column.picture],
          fill: String(item?.[key]?.[0] || emptyFill),
        };
      }

      // SuFix
      if (column?.sufix) {
        object.value = `${object.value} ${column.sufix}`;
      }

      // PreFix
      if (column?.prefix) {
        object.value = `${column.prefix} ${object.value}`;
      }

      // Conditionals
      if (column?.conditional) {
        column.conditional.forEach(cond => {
          if (cond.condition(item?.[key], item)) {
            object.value = cond.value || object.value;
            object.style = {
              borderRadius: '50px',
              ...cond.style(theme, item),
            };
          }
        });
      }

      // Style
      if (column?.style) {
        object.style = {
          ...object.style,
          ...column.style,
        };
      }

      // Attention tooltip
      if (column?.attention) {
        if (loading) {
          object.attention = {
            disabled: true,
          };
        } else {
          object.attention = column.attention(item?.[key], item) || {
            disabled: true,
          };
        }
      }

      // Separate click
      if (column?.onClick) {
        object.onClick = () => column.onClick(item?.[key], item);
      }

      // Switch
      if (column?.switch) {
        object.value = (
          <Switch
            checked={column?.switch?.value(item?.[key], item)}
            onChange={value => column?.switch?.onChange(value, item)}
          />
        );
      }

      clData.push(object);
    });

    tableData.push({
      header: {
        name: column.header,
        tooltip: column.tooltip,
        sort: column.sort,
        id: column.id,
        align: column?.align,
        children: column?.children,
        width: column?.width,
        description: column?.description,
      },
      rows: clData,
    });
  });

  return {
    total,
    generate: tableData,
  };
};
