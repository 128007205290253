/* eslint-disable import/order */
import * as S from './styled';

import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

export const Switch = ({ checked, onChange }) => {
  const handleChange = () => {
    onChange && onChange(!checked);
  };

  return (
    <S.Switch onClick={handleChange} checked={checked} data-testid="switch">
      <S.Circle checked={checked}>
        {checked ? (
          <CheckRoundedIcon data-testid="active" />
        ) : (
          <CloseRoundedIcon data-testid="inactive" />
        )}
      </S.Circle>
    </S.Switch>
  );
};
