import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useTheme } from 'styled-components';
import { trackEvent } from 'utils/mixpanel';
import { useSelector } from 'react-redux';
import * as S from './styled';

const CardAdd = ({ onClick }) => {
  const theme = useTheme();
  const user = useSelector(state => {
    return state.auth?.user?.user;
  });

  return (
    <S.Wrapper
      onClick={() => {
        trackEvent(user, 'FORMULÁRIOS: CRIAÇÃO');
        onClick();
      }}
      className="addCard"
    >
      <AddCircleOutlineIcon
        htmlColor={theme.palette.semantics.feedback.unknown.natural}
      />
      <span>Criar Novo Formulário</span>
    </S.Wrapper>
  );
};

export default CardAdd;
