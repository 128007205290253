// React
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// styles
import * as S from './styled';

const Item = ({ item, vencido = false }) => {
  const navigate = useNavigate();

  return (
    <div className="item-container">
      <div>
        {item?.tipo === 'avaliacao' ? (
          <>
            Tratar avaliação <span>{item?.titulo}</span>
          </>
        ) : (
          <>
            Avaliar desvio <span>{item?.titulo}</span>
          </>
        )}
        {vencido && (item?.data_prazo || item?.data_vencimento) && (
          <div className="vencimento">
            Venceu dia{' '}
            {new Date(
              item?.data_prazo ?? item?.data_vencimento,
            ).toLocaleDateString()}
          </div>
        )}
      </div>
      <div
        className="item-link"
        onClick={() =>
          navigate(
            item?.tipo === 'avaliacao'
              ? '/revisao-cliente'
              : `/desvios/${item?.id}`,
          )
        }
      >
        Resolver
      </div>
    </div>
  );
};

const MinhasTarefas = ({ data, handleCount = null }) => {
  const [activeTab, setActiveTab] = useState(0);
  const count =
    activeTab === 0
      ? (data?.hoje?.length ?? 0) + (data?.proximos?.length ?? 0)
      : data?.vencidos?.length ?? 0;

  useEffect(() => {
    if (data && handleCount instanceof Function)
      handleCount(
        (data?.hoje?.length ?? 0) +
          (data?.proximos?.length ?? 0) +
          (data?.vencidos?.length ?? 0),
      );
  }, [data]);

  return (
    <S.Container>
      <div className="title">
        Você tem {count} tratativas{' '}
        <span>{activeTab === 0 ? 'a vencer' : 'vencidas'}</span>
      </div>
      <div className="tabs">
        <div className="container-indicator" onClick={() => setActiveTab(0)}>
          <span>Próximos do vencimento</span>
          <div className={`indicator ${activeTab === 0 && 'active'}`} />
        </div>
        <div className="container-indicator" onClick={() => setActiveTab(1)}>
          <span>Vencidos</span>
          <div className={`indicator ${activeTab === 1 && 'active'}`} />
        </div>
      </div>
      {activeTab === 0 && (
        <div style={{ overflowY: 'scroll', maxHeight: 200 }}>
          <div className="title-sub">Vence hoje:</div>
          {data?.hoje?.map(item => (
            <Item item={item} key={item.id} />
          ))}
          <div className="title-sub">Próximos do vencimento:</div>
          {data?.proximos?.map(item => (
            <Item item={item} key={item.id} />
          ))}
        </div>
      )}
      {activeTab === 1 && (
        <div style={{ overflowY: 'scroll', maxHeight: 300 }}>
          {data?.vencidos?.map(item => (
            <Item item={item} key={item.id} vencido />
          ))}
        </div>
      )}
    </S.Container>
  );
};

export default MinhasTarefas;
