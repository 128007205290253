import styled from 'styled-components';

export const Container = styled.div`
  padding: 1rem;
`;

export const ContainerList = styled.div`
  overflow-y: scroll;
  height: 500px;
  padding-right: 15px;
  .deslocamentos-link {
    width: 100%;
    text-align: right;
    text-decoration: underline;
    font: normal normal medium 16px/20px Texta;
    letter-spacing: 0px;
    color: #bdcc30;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
`;

export const Title = styled.h1`
  font-size: 24px;
  font-weight: 900;
  letter-spacing: 0px;
  color: #4b5166;
  .point {
    width: 8px;
    height: 8px;
    background: #ffc107;
    border-radius: 50%;
    transform: translateY(20px);
  }
`;

export const Subtitle = styled.p`
  font-size: 16px;
  letter-spacing: 0px;
  color: #4b5166;
`;

export const GraphContainer = styled.div`
  display: flex;
`;

export const GraphTitle = styled.div`
  padding: 0 0.5rem;
  text-align: center;
  font-weight: 900;
  font-size: ${({ children }) => {
    return `${children}`.length > 2 ? '96px' : '160px';
  }};
  font-family: Texta;
  line-height: 192px;
  letter-spacing: 0px;
  color: #4b5166;
`;
