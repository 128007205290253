import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import OnisysLogo from 'images/Onisys_Simbolo.png';
import Frota from 'images/empty/padconfig.png';
import DefaultButton from 'components/Buttons/Default';
import * as S from './styled';

export const InitialModal = ({ open, onClose }) => {
  return (
    <S.ModalMUI open={open}>
      <S.Container>
        <S.Header>
          <div className="left">
            <img src={OnisysLogo} alt="Onisys" />
            <h1>Bem vindo à Onisys</h1>
          </div>

          <div className="right">
            <CloseRoundedIcon style={{ cursor: 'pointer' }} onClick={onClose} />
          </div>
        </S.Header>
        <S.Body>
          <p className="description">
            Comece agora a sua jornada rumo à prevenção de acidentes! Preencha
            as informações essenciais para começar a utilizar a plataforma.
          </p>
          <img src={Frota} alt="Onisys" />
        </S.Body>
        <S.Footer>
          <DefaultButton
            children="Começar Agora"
            width="100%"
            onClick={onClose}
          />
        </S.Footer>
      </S.Container>
    </S.ModalMUI>
  );
};
