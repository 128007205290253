import styled from 'styled-components';
import colors from 'styles/colors';

export const Container = styled.div`
  margin-top: 10px;
`;

export const BoxLine = styled.div`
  width: 100%;
  height: 70px;
  border-bottom: 1px solid ${colors.grey};
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  opacity: ${props => (props.active ? 1 : 0.5)};
  transition: opacity 0.3s ease-in-out;

  .actions {
    position: absolute;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 30px;

    .edit {
      font-size: 25px;
      margin-left: 20px;

      cursor: pointer;
    }
  }

  .page {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 30px;

    .title {
      font-size: 20px;
      font-weight: bold;
      color: ${colors.greyTitle};
      width: 350px;
    }

    .infos {
      margin-left: 30px;
      .in {
        span {
          font-weight: bold;
        }
      }
    }

    .MuiSvgIcon-root {
      font-size: 40px;
      margin-right: 20px;
      transition: all 0.3s ease-in-out;
    }
  }
`;

export const OrderLine = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 35px;
  gap: 20px;

  button {
    border: none;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    color: ${colors.greyTitle};
    transition: all 0.3s ease-in-out;

    &:hover {
      opacity: 0.8;
    }

    .MuiSvgIcon-root {
      color: ${colors.greenMain};
      font-size: 18px;
      margin-right: 5px;

      &.prev {
        color: ${colors.greyTitle};
      }
    }
  }
`;
