import React, { useState, useMemo } from 'react';
import { useTheme } from 'styled-components';

import { Modal, IconButton, Icon, Grid } from '@mui/material';

import InfoCard from 'components/Cards/InfoCard';
import GhostButton from 'components/Buttons/Ghost';
import DefaultButton from 'components/Buttons/Default';
import Select from 'components/Inputs/Select';
import Calendar from 'components/Inputs/Calendar';
import * as S from './styled';

const ReviewModal = ({
  ids,
  handleClose,
  handleConfirm,
  titleIconName = 'warning',
  responsaveis,
  loading,
}) => {
  const theme = useTheme();
  const titleIcon = (
    <Icon
      sx={{ color: theme.palette.semantics.feedback.warning.natural }}
      fontSize="medium"
    >
      {titleIconName}
    </Icon>
  );

  const title = 'Deseja alterar o responsável pela ocorrência?';

  const subtitle =
    'Esta ocorrência terá novo responsável e nova data de vencimento.';

  const [data, setData] = useState({
    id_responsavel: '',
    data_vencimento: '',
  });

  const handleChange = (name, value) => {
    setData(prev => {
      return { ...prev, [name]: value };
    });
  };

  const checkConfirmButton = () => {
    let ready = true;
    if (!data.id_responsavel) ready = false;
    else if (!data.data_vencimento) ready = false;
    return ready;
  };

  const handleClickConfirm = () => {
    const req = {
      ...data,
      ids,
    };
    handleConfirm(req);
  };

  const open = Boolean(ids);

  return (
    <Modal open={open}>
      <S.Paper>
        <S.Header>
          <S.Title>
            {titleIcon}
            <h1>{title}</h1>
          </S.Title>

          <IconButton size="small" onClick={handleClose}>
            <Icon sx={{ color: theme.palette.words.subtitle.natural }}>
              close
            </Icon>
          </IconButton>
        </S.Header>

        <InfoCard message={subtitle} />

        <Grid container rowSpacing={0} columnSpacing={2} marginY={3}>
          <Grid item xs={8} sm={8}>
            <Select
              required
              data={responsaveis}
              value={data.id_responsavel}
              onChange={e => handleChange('id_responsavel', e.target.value)}
              name="id_responsavel"
              label="Responsável"
            />
          </Grid>

          <Grid item xs={4} sm={4}>
            <Calendar
              value={data.data_vencimento}
              onChange={newDate => handleChange('data_vencimento', newDate)}
              futureDate
              pastDate={false}
              label="Novo vencimento"
              required
            />
          </Grid>
        </Grid>

        <S.Footer>
          <GhostButton size="medium" onClick={handleClose}>
            Cancelar
          </GhostButton>

          <DefaultButton
            onClick={handleClickConfirm}
            size="medium"
            style={{ marginLeft: '10px' }}
            disabled={!checkConfirmButton()}
            loading={loading}
          >
            Confirmar
          </DefaultButton>
        </S.Footer>
      </S.Paper>
    </Modal>
  );
};

export default ReviewModal;
