import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { changeHeaderStatus } from 'store/modules/header/actions';
import { trackEvent } from 'utils/mixpanel';
import { Divider, Grid, SvgIcon } from '@mui/material';
import {
  SaveAlt,
  FindInPageOutlined,
  Check,
  RouteOutlined,
  CalendarTodayOutlined,
  WarningAmber,
  KeyboardDoubleArrowRight,
} from '@mui/icons-material';
import { toast } from 'react-toastify';

import { useReactToPrint } from 'react-to-print';
import SelectCliente from 'components/Inputs/SelectCliente';
import TextInput from 'components/Inputs/TextField';
import SelectInput from 'components/Inputs/Select';
import Calendar from 'components/Inputs/Calendar';
import GhostButton from 'components/Buttons/Ghost';
import DefaultButton from 'components/Buttons/Default';
import ConfirmModal from 'components/ConfirmModal';
import InputAttachedFile from 'components/Inputs/InputAttachedFile2';
import Loading from 'components/Loading';

import { formatNewDate, formatNewHour, formatNewHourClean } from 'utils/dates';
import { useTheme } from 'styled-components';
import { ReactComponent as HistoricoIcon } from 'images/icons/sidebar/historico.svg';
import DeleteModal from '../DeleteModal';
import ImageDesvio from '../ImageDesvio';

import { getStatusColor, howToColor, objetos } from '../constants';
import { ReactComponent as navigateIcon } from '../../../images/icons/desvios/Grupo 269.svg';
import { ReactComponent as disabledNavigateIcon } from '../../../images/icons/desvios/Grupo 11400.svg';
import * as S from './styled';
import { ExportDesviosPdf } from './Export';

import * as request from '../services';

const InfractionDetail = () => {
  const theme = useTheme();
  // Parametro da página desvio/:id
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Dados do redux
  const user = useSelector(state => {
    return state.auth?.user?.user;
  });
  const userLevel = user.nivel;
  const userFilials = user.usuario_filiais;
  const { users, clients } = useSelector(state => {
    return state.selects;
  });

  // Desvio que pode ser manipulado e desvio original (que é lido)
  const [infraction, setInfraction] = useState(null);
  const [originalInfraction, setOriginalInfraction] = useState(null);
  const [desvioDeleteModal, setDesvioDeleteModal] = useState(null);
  const [loading, setLoading] = useState(false);

  // Controla modal de confirmação das ações:
  // APROVAR, SALVAR, FINALIZAR E ENVIAR PARA PAD
  const [openModal, setOpenModal] = useState(null);
  const [loadingModal, setLoadingModal] = useState(false);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [printMode, setPrintMode] = useState(false);

  const componentRef = useRef();

  // Lista de responsáveis
  const responsibles = useMemo(
    () =>
      users
        .filter(i => {
          if (userLevel === 1) return true;
          if (userFilials.includes(i.id_da_filial)) return true;
          return false;
        })
        .map(usr => ({ name: usr.nome, value: usr.id })),
    [users, userLevel],
  );

  const fetchInfraction = async (dataId = null) => {
    const infractionId = dataId || id;

    const res = await request.fetchInfraction(infractionId);
    if (res.data?.data) {
      const desv = res.data.data;

      if (desv.objeto && !objetos.includes(desv.objeto)) {
        desv.outro = desv.objeto;
        desv.objeto = 'OUTRO';
      }

      setInfraction(desv);
      setOriginalInfraction(desv);

      // STATUS em header
      let rev = '';
      if (desv.aprovado_distribuidora === 0) rev = ' (Revisão de exclusão)';
      else if (desv.aprovado_distribuidora === 3)
        rev = ' (Segunda revisão de exclusão)';
      else if (desv.aprovado_distribuidora === 1)
        rev = ' (Revisão de exclusão APROVADA)';
      else if (desv.aprovado_distribuidora === 2)
        rev = ' (Revisão de exclusão RECUSADA)';

      if (desv.avaliacao.revisao_da_operacao === 'PENDENTE')
        rev += ' (Revisão de cliente)';
      else if (desv.avaliacao.revisao_da_operacao === 'APROVADA')
        rev += ' (Revisão de cliente APROVADA)';
      else if (desv.avaliacao.revisao_da_operacao === 'RECUSADA')
        rev += ' (Revisão de cliente RECUSADA)';

      dispatch(
        changeHeaderStatus({
          status: desv.status + rev,
          color: getStatusColor(desv.status, theme),
          background: `${getStatusColor(desv.status, theme)}30`,
        }),
      );
    } else if (res.data?.message) toast.error(res.data.message);

    setLoading(false);
  };

  useEffect(() => {
    fetchInfraction();
  }, []);

  useEffect(() => {
    if (printMode) handlePrint();
  }, [printMode]);

  useEffect(() => {
    if (originalInfraction) {
      if (
        infraction.aprovado_distribuidora === 0 ||
        infraction.aprovado_distribuidora === 3
      ) {
        toast.warning(
          'Desvio está em revisão de exclusão. Não é possivel alterar o cliente.',
        );
      }

      if (infraction.aprovado_distribuidora === 3) {
        toast.warning(
          'A embarcadora solicitou outra evidência para a exclusão do desvio.',
          { autoClose: 15000 },
        );
      }

      if (originalInfraction.avaliacao.desvios_delete_review?.length > 0) {
        toast.warning(
          'Avaliação deste desvio possui outro desvio que está em revisão exclusão. Não é possivel alterar o cliente.',
        );
      }

      if (originalInfraction.avaliacao.revisao_da_operacao === 'PENDENTE') {
        toast.warning(
          'Avaliação deste desvio está em revisão de troca de operação. Não é possivel excluir.',
        );
      }
    }
  }, [originalInfraction]);

  const handleChange = (key, value) => {
    setInfraction(prev => {
      return { ...prev, [key]: value };
    });
  };

  // Chamada ao trocar distribuidora
  // Abre modal de confirmação de troca
  // value passa o novo id_da_distribuidora
  // block diz se a avaliação está bloqueada
  const handleChangeDistribuidora = value => {
    const reviewRaizen =
      infraction.id_da_distribuidora === 1 &&
      value !== infraction.id_da_distribuidora &&
      infraction.tipo.relate.questoes?.id_da_secao !== 6;

    const disabled = infraction?.avaliacao.status !== 'PENDENTE';

    const objOpen = {
      value,
      block: false,
      event: 'ALTERAR_DISTRIBUIDORA',
      titleIcon: (
        <Check
          sx={{ color: theme.palette.brand.secondary.natural }}
          fontSize="medium"
        />
      ),
      buttonText: 'CONFIRMAR',
      title: 'Tem certeza que deseja alterar o cliente do desvio?',
      subtitle: 'A alteração será aplicada após SALVAR.',
    };

    if (reviewRaizen) {
      objOpen.subtitle =
        'A solicitação de alteração de cliente ficará pendente e será enviada à Raízen para análise. \
      \nA alteração será aplicada após SALVAR.';
    }
    if (disabled) {
      objOpen.block = true;
      objOpen.title = 'Avaliação bloqueada';
      objOpen.subtitle =
        'A alteração de cliente deve ser feita pela avaliação correspondente. deseja continuar?';
    }
    setOpenModal(objOpen);
  };

  // Controla modal de confirmação das ações:
  // APROVAR, SALVAR, FINALIZAR E ENVIAR PARA PAD
  const handleOpenModal = event => {
    let objOpen = {
      event,
      titleIcon: (
        <Check
          sx={{ color: theme.palette.brand.secondary.natural }}
          fontSize="medium"
        />
      ),
      buttonText: 'CONFIRMAR',
    };

    switch (event) {
      case 'SALVAR':
        let hasChange = false;
        for (const key in infraction) {
          // Um dos 2 nao é nulo ou "" e são diferentes
          if (
            (infraction[key] || originalInfraction[key]) &&
            infraction[key] !== originalInfraction[key]
          ) {
            if (
              (typeof infraction[key] === 'string' && infraction[key].trim()) ||
              typeof infraction[key] !== 'string'
            )
              hasChange = true;
          }
        }
        if (!hasChange) {
          toast.warning('Nenhuma alteração foi aplicada!');
          return;
        }
        objOpen = {
          ...objOpen,
          title: 'Tem certeza que deseja salvar desvio?',
          subtitle: 'Revise todos os campos alterados!',
        };
        break;

      case 'APROVAR':
        if (
          infraction?.id_da_distribuidora !==
          originalInfraction?.id_da_distribuidora
        ) {
          toast.warning('Utilize o botão SALVAR para alterar o cliente!');
          const element = document.getElementById('btnSalvar');
          element?.focus();
          return;
        }
        if (!infraction.id_do_usuario) {
          toast.warning('O campo Responsável é obrigatório!');
          const element = document.getElementById('responsavel');
          element?.focus();
          return;
        }
        if (
          !infraction.plano_de_acao?.trim() &&
          infraction.tipo.criticidade === 'GRAVÍSSIMA'
        ) {
          toast.warning('O campo Plano de Ação é obrigatório!');
          const element = document.getElementById('plano_de_acao');
          element?.focus();
          return;
        }
        if (!infraction.data_previsao) {
          toast.warning('O campo Previsão de Conclusão é obrigatório!');
          const element = document.getElementById('data_previsao');
          element?.click();
          return;
        }

        objOpen = {
          ...objOpen,
          title: 'Tem certeza que deseja aprovar desvio?',
          subtitle: 'O desvio será movido para PENDENTE',
        };
        break;

      case 'FINALIZAR':
        if (
          infraction?.tipo.criticidade === 'GRAVÍSSIMA' &&
          !infraction.arquivo_justificativa
        ) {
          toast.warning(
            'O campo Evidência é obrigatório para desvios com criticidade GRAVÍSSIMA!',
          );
          const element = document.getElementById('arquivo_justificativa');
          element?.focus();
          return;
        }
        if (
          infraction?.id_da_distribuidora !==
          originalInfraction?.id_da_distribuidora
        ) {
          toast.warning('Utilize o botão SALVAR para alterar o cliente!');
          const element = document.getElementById('btnSalvar');
          element?.focus();
          return;
        }

        objOpen = {
          ...objOpen,
          title: 'Tem certeza que deseja finalizar desvio?',
          subtitle: 'O desvio será movido para FINALIZADO',
        };
        break;

      case 'PAD':
        for (const key in infraction) {
          // Um dos 2 nao é nulo ou "" e são diferentes
          if (
            (infraction[key] || originalInfraction[key]) &&
            infraction[key] !== originalInfraction[key]
          ) {
            toast.warning('Salve as alterações antes de enviar para o PAD!');
            return;
          }
        }
        objOpen = {
          ...objOpen,
          title: 'Tem certeza que deseja enviar desvio para o PAD?',
          subtitle: 'O desvio será enviado como ocorrência para o PAD',
        };
        break;
    }
    if (objOpen.title) setOpenModal(objOpen);
  };

  // Ações da confirmação do modal:
  // Mesmas das de cima + ALTERAR_DISTRIBUIDORA
  const handleConfirmModal = () => {
    const { event } = openModal;
    switch (event) {
      case 'SALVAR':
        handleSalvar();
        break;

      case 'APROVAR':
        handleAprovar();
        break;

      case 'FINALIZAR':
        handleFinalizar();
        break;

      case 'PAD':
        handleSendPad();
        break;

      case 'ALTERAR_DISTRIBUIDORA':
        // Direciona para avaliação caso esteja bloqueada
        // Se não, troca o id_da_distribuidora
        if (openModal.block) navigate(`/avaliacoes/${infraction.id_avaliacao}`);
        else handleChange('id_da_distribuidora', openModal.value);
        setOpenModal(null);
        break;
    }
  };

  // ---------------  AÇÕES --------------------------------
  const handleSalvar = async () => {
    setLoadingModal(true);
    // Monta obj com parametros editaveis apenas
    const data = {
      ids: [infraction.id],
      id_do_usuario: infraction.id_do_usuario,
      plano_de_acao: infraction.plano_de_acao,
      data_previsao: infraction.data_previsao,
      arquivo_justificativa: infraction.arquivo_justificativa,
      observacao: infraction.observacao,
      objeto:
        infraction.objeto === 'OUTRO' && infraction.outro
          ? infraction.outro
          : infraction.objeto,
      id_da_distribuidora: infraction.id_da_distribuidora,
    };
    const res = await request.saveInfractions(data);
    if (res.data?.success) {
      toast.success(res.data?.message);
      fetchInfraction();
    } else if (res.data?.message) toast.error(res.data.message);
    setLoadingModal(false);
    setOpenModal(null);
  };

  const handleAprovar = async () => {
    setLoadingModal(true);
    // Monta obj com parametros editaveis apenas
    const data = {
      ids: [infraction.id],
      id_do_usuario: infraction.id_do_usuario,
      plano_de_acao: infraction.plano_de_acao,
      data_previsao: infraction.data_previsao,
      arquivo_justificativa: infraction.arquivo_justificativa,
      observacao: infraction.observacao,
      objeto:
        infraction.objeto === 'OUTRO' && infraction.outro
          ? infraction.outro
          : infraction.objeto,
    };
    const res = await request.approveInfractions(data);
    if (res.data?.success) {
      toast.success(res.data?.message);
      fetchInfraction();
    } else if (res.data?.message) toast.error(res.data.message);
    setLoadingModal(false);
    setOpenModal(null);
  };

  const handleFinalizar = async () => {
    setLoadingModal(true);
    // Monta obj com parametros editaveis apenas
    const data = {
      ids: [infraction.id],
      id_do_usuario: infraction.id_do_usuario,
      plano_de_acao: infraction.plano_de_acao,
      data_previsao: infraction.data_previsao,
      arquivo_justificativa: infraction.arquivo_justificativa,
      observacao: infraction.observacao,
      objeto:
        infraction.objeto === 'OUTRO' && infraction.outro
          ? infraction.outro
          : infraction.objeto,
    };
    const res = await request.finishInfractions(data);
    if (res.data?.success) {
      toast.success(res.data?.message);
      fetchInfraction();
    } else if (res.data?.message) toast.error(res.data.message);
    setLoadingModal(false);
    setOpenModal(null);
  };

  const handleDeletar = async data => {
    setLoadingModal(true);
    const res = await request.deleteInfractions(data);
    if (res.data?.success) {
      toast.success(res.data?.message);
      fetchInfraction();
    } else if (res.data?.message) toast.error(res.data.message);
    setLoadingModal(false);
    setDesvioDeleteModal(null);
  };

  const handleSendPad = async () => {
    setLoadingModal(true);
    const data = {
      ids: [infraction.id],
    };
    const res = await request.sendPadInfractions(data);
    if (res.data?.success) {
      toast.success(res.data?.message);
      fetchInfraction();
    } else if (res.data?.message) toast.error(res.data.message);
    setLoadingModal(false);
    setOpenModal(null);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: () => setLoadingPdf(true),
    onAfterPrint: () => {
      setPrintMode(false);
      setLoadingPdf(false);
    },
    documentTitle: `desvio${infraction?.id}`,
    pageStyle: `@page {
        size: auto;
        margin: 0mm;
      }

      @media print {
        body {
          -webkit-print-color-adjust: exact;
          padding: 20px !important;
          background: white !important;
        }

        .page-break {
          margin-top: 1rem;
          display: block;
          page-break-after: always;
        }
      }`,
  });

  const handleNavigateInfraction = data => {
    setLoading(true);

    let infractionId = infraction.next;
    if (data === 'PREVIOUS') infractionId = infraction.previous;
    navigate(`/desvio/${infractionId}`, { replace: true });

    fetchInfraction(infractionId);
  };

  //  ------------------   RENDERIZACAO --------------------------------
  const renderHeader = () => {
    return (
      <S.SpacedRow>
        <h1>Detalhe do desvio</h1>

        <S.SpacedRow>
          <GhostButton
            startIcon={<HistoricoIcon />}
            size="medium"
            onClick={() => navigate(`/logs/desvio/${id}`)}
            sx={{ marginLeft: '20px' }}
          >
            HISTÓRICO DE ALTERAÇÕES
          </GhostButton>

          <GhostButton
            startIcon={<FindInPageOutlined />}
            size="medium"
            onClick={() => navigate(`/avaliacoes/${infraction.id_avaliacao}`)}
            sx={{ marginLeft: '20px' }}
          >
            VER AVALIAÇÃO
          </GhostButton>

          <GhostButton
            startIcon={<SaveAlt />}
            size="medium"
            onClick={e => setPrintMode(true)}
            style={{ marginLeft: '10px', marginRight: '32px' }}
            loading={loadingPdf}
          >
            IMPRIMIR
          </GhostButton>

          <S.NavigateButton
            onClick={() => handleNavigateInfraction('PREVIOUS')}
            style={{ transform: infraction.previous && 'rotate(180deg)' }}
            disabled={!infraction.previous}
          >
            <SvgIcon
              component={
                infraction.previous ? navigateIcon : disabledNavigateIcon
              }
              fontSize="medium"
            />
          </S.NavigateButton>

          <S.NavigateButton
            style={{ transform: !infraction.next && 'rotate(180deg)' }}
            onClick={() => handleNavigateInfraction('NEXT')}
            disabled={!infraction.next}
          >
            <SvgIcon
              component={infraction.next ? navigateIcon : disabledNavigateIcon}
              fontSize="medium"
            />
          </S.NavigateButton>
        </S.SpacedRow>
      </S.SpacedRow>
    );
  };

  // Lado Esquerdo do detalhe do desvio
  // Não editável
  const renderTravelInfo = () => {
    const hasImage = infraction.motorista.foto
      ? infraction.motorista.foto
      : false;
    return (
      <S.ColumnWrapper>
        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={12} display="flex" justifyContent="center">
            <ImageDesvio infraction={infraction} onlyImage={printMode} />
          </Grid>

          <Grid item xs={12} sm={12} display="flex" flexDirection="row">
            <S.StyledLogoWrapper>
              <S.StyledLogo backgroundImage={hasImage}>
                {!hasImage && <h2>{infraction.motorista.nome[0]}</h2>}
              </S.StyledLogo>
            </S.StyledLogoWrapper>

            <S.StyledTextWrapper>
              <h1>{infraction.motorista.nome.toLowerCase()}</h1>
              <p>Filial: {infraction.motorista.filial?.nome}</p>
            </S.StyledTextWrapper>
          </Grid>

          <Divider style={{ margin: '10px 0px 10px 10px', width: '100%' }} />

          <Grid item xs={8} sm={8}>
            <TextInput
              id="Desvio"
              label="Desvio"
              value={infraction.tipo.descricao}
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={4} sm={4}>
            <TextInput
              id="criticidade"
              label="Criticidade"
              value={infraction.tipo.criticidade}
              InputProps={{ readOnly: true }}
              variant="filled"
              startIcon={
                <WarningAmber
                  sx={{ color: howToColor(infraction.tipo.criticidade, theme) }}
                />
              }
              fillcolor={howToColor(infraction.tipo.criticidade, theme)}
            />
          </Grid>

          {infraction.avaliacao.local && (
            <Grid item xs={12} sm={12}>
              <TextInput
                id="trejeto"
                label="Trajeto"
                value={infraction.avaliacao.local}
                inputProps={{ readOnly: true }}
                startIcon={
                  <RouteOutlined
                    sx={{
                      rotate: '90deg',
                      color: theme.palette.brand.primary.light,
                    }}
                  />
                }
                variant="filled"
              />
            </Grid>
          )}

          <Grid item xs={6} sm={6}>
            <TextInput
              id="reportadoem"
              label="Reportado em"
              value={formatNewDate(infraction.createdAt)}
              inputProps={{ readOnly: true }}
              startIcon={
                <CalendarTodayOutlined
                  sx={{ color: theme.palette.brand.primary.light }}
                />
              }
              variant="filled"
            />
          </Grid>

          <Grid item xs={6} sm={6}>
            <TextInput
              id="datadesvio"
              label="Data do Desvio"
              value={formatNewHourClean(infraction.data_desvio)}
              startIcon={
                <CalendarTodayOutlined
                  sx={{ color: theme.palette.brand.primary.light }}
                />
              }
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>

          {(infraction.status === 'DELETADO' ||
            infraction.aprovado_distribuidora !== null) && (
            <Grid item xs={12} sm={12}>
              <TextInput
                id="motivoexclusao"
                label="Motivo da exclusão"
                value={infraction.motivo_exclusao}
                inputProps={{ readOnly: true }}
                variant="filled"
              />
            </Grid>
          )}
        </Grid>
      </S.ColumnWrapper>
    );
  };

  const renderFooter = () => {
    return (
      !printMode && (
        <Grid item xs={12} sm={12} display="flex" justifyContent="flex-end">
          {infraction.status === 'ABERTO' &&
            userLevel < 3 &&
            infraction.aprovado_distribuidora !== 0 &&
            !(
              infraction.alteracao_distribuidora &&
              infraction.id_da_distribuidora !==
                infraction.alteracao_distribuidora
            ) && (
              <GhostButton
                // disabled={infraction.alteracao_distribuidora &&
                // infraction.id_da_distribuidora !== infraction.alteracao_distribuidora}
                size="medium"
                customcolor={theme.palette.semantics.feedback.attention.natural}
                onClick={() => {
                  trackEvent(user, 'Excluir DESVIOS');
                  setDesvioDeleteModal({ ...infraction });
                }}
                sx={{ marginLeft: '20px' }}
              >
                {infraction.aprovado_distribuidora === 3
                  ? 'Alterar evidência de exclusão'
                  : 'EXCLUIR'}
              </GhostButton>
            )}

          {(infraction.status === 'ABERTO' ||
            infraction.status === 'PENDENTE' ||
            infraction.status === 'FINALIZADO') && (
            <GhostButton
              id="btnSalvar"
              size="medium"
              onClick={() => handleOpenModal('SALVAR')}
              style={{ marginLeft: '10px' }}
              // disabled={ O usuário deve salvar alterações em revisão (arquivo evidencia, etc...)
              // infraction.aprovado_distribuidora === 0 || infraction.aprovado_distribuidora === 3
              // }
            >
              SALVAR
            </GhostButton>
          )}
          {infraction.status === 'ABERTO' && (
            <DefaultButton
              size="medium"
              onClick={() => {
                trackEvent(user, 'APROVAR DESVIOS');
                handleOpenModal('APROVAR');
              }}
              style={{ marginLeft: '10px' }}
              disabled={
                infraction?.aprovado_distribuidora === 0 ||
                infraction?.aprovado_distribuidora === 3 ||
                infraction?.id_da_distribuidora !==
                  originalInfraction?.id_da_distribuidora
              }
            >
              APROVAR
            </DefaultButton>
          )}

          {infraction.status === 'PENDENTE' && (
            <DefaultButton
              size="medium"
              onClick={() => {
                trackEvent(user, 'FINALIZAR DESVIO');
                handleOpenModal('FINALIZAR');
              }}
              style={{ marginLeft: '10px' }}
              disabled={
                infraction?.id_da_distribuidora !==
                originalInfraction?.id_da_distribuidora
              }
            >
              FINALIZAR
            </DefaultButton>
          )}

          {infraction.status === 'FINALIZADO' && (
            <DefaultButton
              size="medium"
              onClick={() => {
                trackEvent(user, 'ENVIAR DESVIO PARA PAD');
                handleOpenModal('PAD');
              }}
              style={{ marginLeft: '10px' }}
            >
              ENVIAR PARA PAD
            </DefaultButton>
          )}
        </Grid>
      )
    );
  };

  // Lado direito detalhe do desvio
  // Dados editáveis
  const renderInfractionInfo = () => {
    return (
      <S.ColumnWrapper>
        <Grid container columnSpacing={2} rowSpacing={1}>
          <Grid
            item
            xs={12}
            sm={12}
            display="flex"
            flexDirection="row"
            alignItems="center"
          >
            <SelectCliente
              value={infraction?.id_da_distribuidora}
              setValue={value => handleChangeDistribuidora(value)}
              distributors={clients}
              disabled={
                infraction.avaliacao.revisao_da_operacao === 'PENDENTE' ||
                infraction.aprovado_distribuidora === 0 ||
                infraction.aprovado_distribuidora === 3 ||
                infraction.avaliacao.desvios_delete_review?.length > 0
              }
            />
            {infraction.alteracao_distribuidora &&
              infraction.id_da_distribuidora !==
                infraction.alteracao_distribuidora && (
                <>
                  <KeyboardDoubleArrowRight
                    sx={{
                      color: theme.palette.brand.secondary.natural,
                      opacity: 0.6,
                      margin: '20px 20px 0px 20px',
                    }}
                    fontSize="large"
                  />

                  <SelectCliente
                    value={infraction?.alteracao_distribuidora}
                    // setValue={(value) => handleChangeDistribuidora(value)}
                    distributors={clients}
                    disabled
                  />
                </>
              )}
          </Grid>

          <Grid item xs={7} sm={7}>
            <SelectInput
              id="responsavel"
              label="Atribuir Responsável"
              value={infraction.id_do_usuario}
              onChange={e => handleChange('id_do_usuario', e.target.value)}
              placeholder="Atribuir por Responsável"
              data={responsibles}
              required
            />
          </Grid>

          <Grid item xs={5} sm={5}>
            <Calendar
              id="data_previsao"
              value={infraction.data_previsao}
              onChange={newDate => handleChange('data_previsao', newDate)}
              futureDate
              pastDate={false}
              label="Previsão de Conclusão"
              required
            />
          </Grid>

          {[6, 11].includes(infraction.tipo?.id) && (
            <>
              <Grid item xs={6} sm={6}>
                <SelectInput
                  id="objeto"
                  label="Objeto"
                  value={infraction.objeto}
                  onChange={e => handleChange('objeto', e.target.value)}
                  placeholder="Selecionar objeto"
                  data={objetos}
                />
              </Grid>

              {infraction.objeto === 'OUTRO' && (
                <Grid item xs={6} sm={6}>
                  <TextInput
                    id="outro"
                    placeholder="Outro"
                    label="Outro objeto"
                    onChange={e => handleChange('outro', e.target.value)}
                    value={infraction.outro}
                  />
                </Grid>
              )}
            </>
          )}

          <Grid item xs={12} sm={12}>
            <TextInput
              id="plano_de_acao"
              placeholder="Definir Plano de Ação"
              label="Plano de Ação"
              onChange={e => handleChange('plano_de_acao', e.target.value)}
              value={infraction.plano_de_acao}
              multiline
              required={infraction?.tipo.criticidade === 'GRAVÍSSIMA'}
              rows={2}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <InputAttachedFile
              idInput="input_file_detalhe"
              required={infraction.tipo.criticidade === 'GRAVÍSSIMA'}
              label="Arquivo Justificativa"
              inputLabel="Anexar Evidência"
              fileUrl={infraction.arquivo_justificativa}
              setFileUrl={value => handleChange('arquivo_justificativa', value)}
              fileDir={`/desvios/${id}`}
              // deleteFromStorage nao apagar arquivo antigo
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextInput
              label="Observações"
              placeholder="Inserir observações"
              onChange={e => handleChange('observacao', e.target.value)}
              multiline
              rows={4}
              value={infraction.observacao}
            />
          </Grid>

          {(infraction.status === 'DELETADO' ||
            infraction.aprovado_distribuidora !== null) &&
            infraction.observacao_exclusao && (
              <Grid item xs={12} sm={12}>
                <TextInput
                  id="observacaoexclusao"
                  label="Observação da exclusão (transportadora)"
                  value={infraction.observacao_exclusao}
                  inputProps={{ readOnly: true }}
                  variant="filled"
                  multiline
                />
              </Grid>
            )}

          {(infraction.status === 'DELETADO' ||
            infraction.aprovado_distribuidora !== null) &&
            infraction.observacoes_distribuidora && (
              <Grid item xs={12} sm={12}>
                <TextInput
                  id="observacaorevisaodistribuidora"
                  label="Observação da embarcadora"
                  value={infraction.observacoes_distribuidora}
                  inputProps={{ readOnly: true }}
                  variant="filled"
                  multiline
                />
              </Grid>
            )}

          {renderFooter()}
        </Grid>
      </S.ColumnWrapper>
    );
  };

  return infraction && originalInfraction && !loading ? (
    <>
      <S.Main>
        {renderHeader()}
        <Grid container spacing="20px" marginTop="10px">
          <Grid item xs={12} sm={12} md={printMode ? 12 : 6}>
            {renderTravelInfo()}
          </Grid>

          {printMode && <Grid item xs={12} height="350px" />}

          <Grid item xs={12} sm={12} md={printMode ? 12 : 6}>
            {renderInfractionInfo()}
          </Grid>
        </Grid>
        <ExportDesviosPdf printRef={componentRef} data={infraction} />
      </S.Main>

      {
        // Este modal controla todas as confirmações
        // openModal.event define a acao em handleConfirmModal
        openModal && (
          <ConfirmModal
            handleClose={() => setOpenModal(null)}
            open={Boolean(openModal)}
            title={openModal.title}
            subtitle={openModal.subtitle}
            titleIcon={openModal.titleIcon}
            buttonText={openModal.buttonText}
            onClick={handleConfirmModal}
            loading={loadingModal}
          />
        )
      }

      {desvioDeleteModal && (
        <DeleteModal
          handleConfirm={handleDeletar}
          desvio={desvioDeleteModal}
          handleClose={() => setDesvioDeleteModal(null)}
          loading={loadingModal}
        />
      )}
    </>
  ) : (
    <Loading />
  );
};

export default InfractionDetail;
