// Pages
import { NovaLista } from 'pages/Configuracoes/EscalationList/NovaLista';

export default {
  '/configuracoes/escalation-list/nova-list': {
    back: -1,
    title: 'Nova Lista',
    subTitle: 'Escalation List',
    date: false,
    page: <NovaLista />,
  },
};
