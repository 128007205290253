// Styles

// React
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { reset } from 'store/modules/formularios/actions';
import { ReactComponent as file } from 'images/icons/sidebar/file.svg';

// Page Components
import ExcelModal from 'components/ExcelModal';
import GhostButton from 'components/Buttons/Ghost';
import DefaultButton from 'components/Buttons/Default';
import { Divider } from '@mui/material';
import { SaveAlt, AddCircleOutline } from '@mui/icons-material';
import ExportToExcel from 'utils/exportToCvs';
import { formatNameDate, formatNewDate } from 'utils/dates';
import { trackEvent } from 'utils/mixpanel';
import { useDebounce } from 'use-debounce';
import Tag from './components/Tag';
import Title from './components/Title';
import Search from './components/Search';
import Loading from './components/Loading';
import ModalAdd from './components/ModalAdd';
import FullModal from './components/FullModal';
import ModalTitle from './components/ModalTitle';
import ModalResponse from './components/ModalResponse';
import ModalTemplates from './components/ModalTemplates';

// Geral Components
import Templates from './components/Templates';
import SliderTag from './components/SliderTag';

// Material Components

// Utils
import * as services from './services';
import { fields, resetFields } from './constants';
import * as S from './styled';

let timer = null;
let timerRoute = null;

const Formularios = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const pathname = location.pathname.slice(13);
  const formularios = useSelector(state => state.formularios);
  const filterForm = useSelector(state => state.filterFormulario);
  const user = useSelector(state => {
    return state.auth?.user?.user;
  });
  let isConvidado = false;
  if (+user.nivel === 4) isConvidado = true;

  // General States
  const [firstCharge, setFirstCharge] = useState(true);
  const [templates, setTemplates] = useState(null);
  const [filteredTemplates, setFilteredTemplates] = useState(null);
  const [modalAdd, setModalAdd] = useState(false);
  const [tags, setTags] = useState([]);
  const [modalTitle, setModalTitle] = useState(false);
  const [modalTemplate, setModalTemplate] = useState(false);
  const [modalResponse, setModalResponse] = useState(false);
  const [formResponse, setFormResponse] = useState(null);
  const [fullModal, setFullModal] = useState(false);
  const [inSearch, setInSearch] = useState(false);
  const [query, setQuery] = useState({
    card: null,
    tag: 'Todos',
    search: '',
  });
  const [searchTrackEvent] = useDebounce(query.search, 1000);

  // -------------------------------------PAGE CONTROLLER---------------------------------------//

  useEffect(() => {
    if (formularios.id && pathname === 'criar') {
      setFullModal(true);
    } else if (pathname === 'novo') {
      setModalAdd(true);
      window.history.replaceState({}, {}, '/formularios');
    }
  }, []);

  const {
    data: res,
    isError,
    isLoading: loading,
    refetch,
  } = useQuery(['forms', 1], () => services.GetForms(), {
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (loading) return null;

    if (!isError) {
      setTemplates(res.data.forms);
      setTags(res.data.tags);
      if (pathname !== 'criar') dispatch(reset());
    } else {
      toast.error(res.message);
    }
  }, [res]);

  // Controll back arrow in window when modal is opened in same tab (BETA VERSION)
  useEffect(() => {
    timerRoute = setTimeout(() => {
      if (!fullModal && !firstCharge && !modalResponse) {
        window.onpopstate = event => {
          event.preventDefault();
        };
      }
    }, 100);

    return function cleanup() {
      clearTimeout(timerRoute);
    };
  }, [fullModal, firstCharge, modalResponse]);

  // ------------------------------------FILTERS CONTROLLER-------------------------------------//
  const handleQuery = object => {
    setQuery({ ...query, ...object });
  };

  useEffect(() => {
    if (filterForm) setQuery({ ...query, ...filterForm });
  }, [filterForm]);

  useEffect(() => {
    let hasFilter = [];
    const temps = templates;
    let status = null;

    if (!temps || firstCharge) {
      setFilteredTemplates(null);
      setFirstCharge(false);
      return;
    }

    // Filtro Status
    status = query?.status ? query.status.split(',') : false;
    if (status.length) {
      hasFilter = temps.filter(temp => status.includes(temp?.status));
    } else {
      hasFilter = temps;
    }

    // Filtro Publico
    const turma = query?.turma;
    if (turma === 'MOTORISTAS') {
      const hasMotoristas = [];
      for (const i in hasFilter) {
        const { turmas } = hasFilter[i];
        let hasPublic = false;
        for (const j in turmas) {
          const turma = turmas[j];
          if (turma.para_motoristas) {
            hasPublic = true;
          }
        }
        if (hasPublic) {
          hasMotoristas.push(hasFilter[i]);
        }
      }
      hasFilter = hasMotoristas;
    } else if (turma === 'GESTORES') {
      const hasGestor = [];
      for (const i in hasFilter) {
        const { turmas } = hasFilter[i];
        let hasPublic = false;
        for (const j in turmas) {
          const turma = turmas[j];
          if (turma.para_usuarios) {
            hasPublic = true;
          }
        }
        if (hasPublic) {
          hasGestor.push(hasFilter[i]);
        }
      }
      hasFilter = hasGestor;
    }

    if (query.search) {
      hasFilter = hasFilter.filter(temp => {
        const turmasName = temp.turmas
          .map(item => item.nome.toLowerCase())
          .join('*');
        if (
          temp.titulo
            .toLowerCase()
            .includes(query.search.toLocaleLowerCase()) ||
          temp.tags
            .toString()
            .toLocaleLowerCase()
            .includes(query.search.toLocaleLowerCase()) ||
          turmasName.includes(query.search.toLocaleLowerCase())
        ) {
          return temp;
        }
      });
    }

    if (query.tag !== 'Todos') {
      hasFilter = hasFilter.filter(temp => temp.tags.includes(query.tag));
    }

    setInSearch(true);
    clearTimeout(timer);

    timer = setTimeout(() => {
      if (!query.search && query.tag === 'Todos' && !status && !turma) {
        setFilteredTemplates(null);
      } else {
        setFilteredTemplates(hasFilter);
      }
      setInSearch(false);
    }, 400);
  }, [query, templates]);

  useEffect(() => {
    // Valida busca com pelo menos 3 caracteres para o MixPanel
    if (searchTrackEvent.length >= 3) {
      trackEvent(user, 'Busca Formulários', null, searchTrackEvent);
    }
  }, [searchTrackEvent]);

  // -------------------------- EXCEL ------------------------------------------//

  const [openExcelModal, setOpenExcelModal] = useState(false);
  const [excelFields, setExcelFields] = useState(fields);
  const [loadingExcel, setLoadingExcel] = useState(false);

  const handleRequestExcel = async () => {
    const newFields = excelFields.filter(item => item.selected === true);
    const formatedDate = formatNameDate(new Date());

    const excelArr = [];
    const data = filteredTemplates || templates;
    data.forEach(template => {
      let customizedFields = {};
      const multiplo = template.multiplo;

      newFields.forEach(item => {
        const { label } = item;
        let value =
          template[item.value] || template[item.value] === 0
            ? template[item.value]
            : '';

        // Formatação de value para datas
        if (
          label === 'Criado em' ||
          label === 'Data Encerramento' ||
          label === 'Última edição' ||
          label === 'Data Início'
        )
          value = formatNewDate(value);

        if (
          label === 'Gera Ocorrências' ||
          label === 'Obrigatório' ||
          label === 'Necessita Aprovação' ||
          label === 'Múltiplo'
        )
          value = value ? 'Sim' : 'Não';

        if (
          label === 'Responsável aprovação' ||
          label === 'Empresa' ||
          label === 'Criador' ||
          label === 'Responsável ocorrência'
        )
          value = value.nome;

        // Se for múltiplo ignora periodo e frequencia para não confundir
        if (multiplo && (label === 'Frequência' || label === 'Período'))
          value = '--';

        customizedFields = { ...customizedFields, [label]: value };
      });
      excelArr.push(customizedFields);
    });

    setLoadingExcel(true);
    ExportToExcel({
      excel: excelArr,
      name: `formularios_${formatedDate}`,
    });
    setExcelFields(resetFields(fields));
    setLoadingExcel(false);
    setOpenExcelModal(false);
  };

  // ------------------------------------- RENDER ------------------------------------------//

  return (
    <S.Container>
      <S.Header>
        <Title>Formulários</Title>
        <span className="buttonArea">
          <GhostButton
            startIcon={<SaveAlt />}
            size="medium"
            onClick={() => {
              trackEvent(user, 'FORMULÁRIOS: EXPORTAR');
              setOpenExcelModal(true);
            }}
            children="Exportar"
            style={{ marginRight: '10px' }}
            disabled={isConvidado}
          />

          <DefaultButton
            children="Novo Formulário"
            onClick={() => {
              trackEvent(user, 'FORMULÁRIOS: CRIAÇÃO');
              setModalAdd(true);
            }}
            startIcon={<AddCircleOutline />}
            disabled={isConvidado}
          />
        </span>
      </S.Header>

      <Divider style={{ marginTop: '10px' }} />

      <SliderTag width="72vw">
        {!loading &&
          tags.map((item, key) => (
            <Tag
              children={item}
              key={key}
              onClick={() => handleQuery({ tag: item })}
              active={item === query.tag}
              disabled={item === query.tag}
            />
          ))}
        {loading && <Tag loading />}
      </SliderTag>

      <S.SearchArea>
        <Search
          value={query.search}
          setValue={value => handleQuery({ search: value })}
        />
        <p>{query.tag}</p>
      </S.SearchArea>
      <S.Body inSearch={inSearch}>
        {!loading && templates && !fullModal && !modalResponse && (
          <Templates
            templates={filteredTemplates || templates}
            conteudos={null}
            setModalAdd={setModalAdd}
            query={query}
            inSearch={inSearch}
            setFullModal={setFullModal}
            setFormResponse={setFormResponse}
            setModalResponse={setModalResponse}
          />
        )}
        {loading && <Loading />}
      </S.Body>

      {/* Modal Area */}
      <ModalAdd
        open={modalAdd}
        onClose={() => setModalAdd(false)}
        setModalTitle={setModalTitle}
        setModalTemplate={setModalTemplate}
      />

      <ModalTitle
        open={modalTitle}
        onClose={() => {
          setModalTitle(false);
        }}
        setFullModal={setFullModal}
      />

      {fullModal && (
        <FullModal
          open={fullModal}
          onClose={() => {
            setFullModal(false);
            dispatch(reset());
            refetch();
          }}
          backPath="/formularios"
        />
      )}

      {modalResponse && formResponse && (
        <ModalResponse
          open={modalResponse}
          onClose={() => setModalResponse(false)}
          form={formResponse}
          previewWeb
          backPath="/formularios"
        />
      )}

      {openExcelModal && (
        <ExcelModal
          onClick={handleRequestExcel}
          open={openExcelModal}
          handleClose={() => setOpenExcelModal(false)}
          title="Selecionar os campos de Excel"
          titleIcon={file}
          subtitle="Selecione abaixo:"
          setData={setExcelFields}
          data={excelFields}
          loading={loadingExcel}
        />
      )}

      <ModalTemplates
        tags={tags}
        templates={templates}
        open={modalTemplate}
        onClose={() => setModalTemplate(false)}
        setModalTitle={setModalTitle}
        setFullModal={setFullModal}
        setModalResponse={setModalResponse}
        setFormResponse={setFormResponse}
      />
    </S.Container>
  );
};

export default Formularios;
