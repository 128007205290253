import styled from 'styled-components';
import colors from 'styles/colors';

export const Container = styled.div`
  width: 100%;
  background-color: #fff;
  min-height: ${props => props.width};
  max-height: 100%;
  border: 2px solid ${colors.greyBorder};
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ImageArea = styled.div``;

export const ContentArea = styled.div`
  margin-left: 20px;

  .textArea {
    h3 {
      font-size: 28px;
      color: ${colors.greyTitle};
      font-weight: bold;
    }

    h5 {
      font-size: 18px;
      margin-top: 13px;
      font-weight: 500;
      color: ${colors.greySubtitle};
      margin-bottom: 13px;
    }
  }
`;
