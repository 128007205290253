import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    background-color: ${theme.palette.system.overlay};
    border: 2px solid ${theme.palette.system.border};
    border-radius: 4px;
    padding: 30px;
    margin-top: 20px;
  `}
`;

export const Title = styled.h2`
  margin-bottom: 20px;
`;

export const signedBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: start;
    margin-top: 20px;
    margin-bottom: 30px;

    .outWitness {
      font-weight: 600;
      color: ${theme.palette.semantics.feedback.unknown.natural};
    }

    .imageArea {
      width: 50px;
      height: 50px;
      border: 2px solid ${theme.palette.brand.secondary.natural};
      border-radius: 50px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
        border-radius: 200px;
        padding: 1px;
      }

      .letters {
        width: 42px;
        height: 42px;
        border-radius: 42px;
        background-color: ${theme.palette.semantics.feedback.unknown.light};
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 900;
        color: ${theme.palette.words.title.natural};
        font-size: 19px;
      }
    }

    .textArea {
      display: flex;
      flex-direction: column;
      margin-left: 20px;
      text-transform: capitalize;

      .userName {
        color: ${theme.palette.words.title.natural};
        font-weight: 700;
        font-size: 18px;
      }
      .status {
        color: ${theme.palette.words.subtitle.natural};
        font-size: 14px;
      }
    }
  `}
`;
