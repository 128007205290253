// Pages
import { Acidentes } from 'pages/Acidentes';
import { CriarAcidente } from 'pages/Acidentes/Criar';
import { DetalheAcidente } from 'pages/Acidentes/Detalhe';
import AddAcidentes from 'pages/Acidentes/AddAcidentes';
import Historico from 'pages/Historico';

export default {
  '/acidentes': {
    back: -1,
    title: 'Acidentes',
    page: <Acidentes />,
  },
  '/acidentes/:id': {
    title: 'Acidente',
    subTitle: 'Acidentes',
    id: true,
    back: '/acidentes',
    page: <DetalheAcidente />,
  },
  '/acidentes/criar': {
    title: 'Novo registro',
    subTitle: 'Acidentes',
    back: -1,
    page: <CriarAcidente />,
  },
  '/acidentes/editar/:id': {
    title: 'Acidente',
    subTitle: 'Acidentes',
    id: true,
    back: -1,
    page: <CriarAcidente edit />,
  },
  '/acidentes/add-acidentes': {
    back: '/acidentes',
    title: 'Adicionar em Massa - Acidentes',
    defaults: [],
    page: <AddAcidentes />,
  },
  '/logs/acidentes/:id': {
    back: -1,
    title: 'Histórico do Acidente',
    id: true,
    defaults: [],
    page: (
      <Historico
        back={-1}
        url="/acidentes/:id/logs"
        formatReturn={data =>
          data.map(log => ({
            ...log,
            data: log.data ?? log.createdAt,
            evento: log.evento ?? '',
          }))
        }
      />
    ),
  },
};
