import api from 'services/api';

export const getRoutes = async () => {
  const res = await api.get('/guide-panel-controller-routes');
  return res.data;
};

export const getIndex = async () => {
  const res = await api.get('/guide-panel-controller');
  return res.data;
};

export const createNewSection = async data => {
  const res = await api.post('/section-panel-controller', data);
  return res.data;
};

export const updateSection = async data => {
  const res = await api.put(`/section-panel-controller/${data.id}`, data);
  return res.data;
};

export const saveContent = async data => {
  const res = await api.post('/guide-panel-controller', data);
  return res.data;
};

export const updateContent = async data => {
  const res = await api.put(`/guide-panel-controller/${data.id}`, data);
  return res.data;
};

export const orderSections = async data => {
  const res = await api.put('/sections-order', data);
  return res.data;
};

export const orderGuide = async data => {
  const res = await api.put('/guides-order', data);
  return res.data;
};

export const orderFirstSteps = async data => {
  const res = await api.put('/steps-order', data);
  return res.data;
};

export const updateFirstStatus = async id => {
  const res = await api.put(`/step-change-status/${id}`);
  return res.data;
};

export const updateGuidaStatus = async id => {
  const res = await api.put(`/guide-change-status/${id}`);
  return res.data;
};
