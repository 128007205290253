import { CheckCircleOutline, HighlightOff } from '@mui/icons-material';

export const iconsCards = {
  ativos: 'card_ativas.svg',
  pos_camera: 'card_camera_inadequada.svg',
  dvr: 'card_incorreto.svg',
};

export const columns = [
  {
    header: 'Placa',
    id: 'placa',
    type: 'number',
    sort: true,
    width: 100,
  },
  {
    header: 'Id Frota',
    id: 'id',
    type: 'number',
    sort: true,
  },
  {
    header: 'Modelo',
    id: 'modelo',
    type: 'string',
    sort: true,
  },
  {
    header: 'Filial',
    id: 'nome_filial',
    type: 'string',
    sort: true,
  },
  {
    header: 'Pos. Câmera',
    id: 'pos_camera',
    sort: true,
    type: 'string',
    align: 'center',
    children: (_, item) => {
      return item.pos_camera === 'SIM' ? (
        <CheckCircleOutline htmlColor="#1BC5BD" />
      ) : (
        <HighlightOff htmlColor="#F64E60" />
      );
    },
  },
  {
    header: 'Canais DVR',
    id: 'dvr',
    sort: true,
    type: 'string',
    children: (_, item) => {
      return item.dvr === 'SIM' ? (
        <CheckCircleOutline htmlColor="#1BC5BD" />
      ) : (
        <HighlightOff htmlColor="#F64E60" />
      );
    },
  },
  {
    header: 'Status',
    id: 'status',
    type: 'string',
    sort: true,
    width: 200,
    align: 'center',
    conditional: [
      {
        condition: value => ['ATIVO'].includes(value),
        style: theme => ({
          color: theme.palette.semantics.feedback.success.natural,
          backgroundColor: theme.palette.semantics.feedback.success.light,
          textAlign: 'center',
        }),
      },
      {
        condition: value => ['INATIVO'].includes(value),
        style: theme => ({
          color: theme.palette.semantics.feedback.attention.dark,
          backgroundColor: theme.palette.semantics.feedback.attention.light,
          textAlign: 'center',
        }),
      },
    ],
  },
];
