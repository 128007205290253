import styled from 'styled-components';

export const Warnning = styled.div`
  position: fixed;
  z-index: 999;
  width: 250px;
  background-color: ${props => props.backgorund};
  bottom: 0;
  right: 0;
  margin-bottom: 20px;
  margin-right: 20px;
  border-radius: 10px;
  padding: 10px;
  font-weight: 600;
  flex-direction: column;
  font-style: oblique;
  display: ${props => (props.view ? 'flex' : 'none')};

  .warning {
    color: ${({ theme }) => theme.palette.system.whitez};
  }

  .close {
    align-self: end;
    font-weight: 600;
    color: ${props => props.color};
    margin: 10px;
    cursor: pointer;
  }
`;
