import React, { useState, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import { SvgIcon, IconButton } from '@mui/material';
import ConfirmModal from 'components/ConfirmModal';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useTheme } from 'styled-components';
import { ReactComponent as turmas } from 'images/icons/sidebar/drivers.svg';
import Loading from 'components/Loading';
import Header from '../components';
import { getTurmas, handleDeleteTurma } from '../services';
import * as S from './styled';
import AdicionarTurma from './AdicionarTurma/AdicionarTurma';

const Turmas = () => {
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const [listTurmas, setListTurmas] = useState([]);
  const [optionsTurmas, setOptionsTurmas] = useState([]);
  const [info, setInfo] = useState(null);

  const [confirmExclusion, setConfirmExclusion] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [hasContent, setHasContent] = useState(false);
  const [contents, setContents] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const id = searchParams.get('id');
  const tipo = searchParams.get('tipo');

  const fetchTurmas = () => {
    setLoading(true);
    getTurmas(id, tipo).then(res => {
      setListTurmas(res.turmas);
      setOptionsTurmas(res.outras);
      setInfo(res.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchTurmas();
  }, []);

  const handleDelete = async () => {
    const obj = {
      tipo,
      alunoId: Number(id),
      turmaId: Number(currentId),
    };
    await handleDeleteTurma(obj);
    setOpen(false);
    setConfirmExclusion(false);
    fetchTurmas();
  };

  if (loading) {
    return (
      <div style={{ position: 'absolute', left: '55%' }}>
        <Loading />
      </div>
    );
  }

  return (
    <>
      <h1>Turmas</h1>
      <S.Container>
        <Header nome={info?.nome} foto={info?.foto} filial="" />
        <div style={{ marginTop: 40 }} />
        {listTurmas.map(item => (
          <div key={item?.id}>
            <S.ListItems>
              <div className="item-right">
                <div className="container-avatar">
                  {!item?.foto ? (
                    <SvgIcon
                      component={turmas}
                      style={{
                        color: theme.palette.brand.primary.light,
                        height: 30,
                        width: 30,
                      }}
                    />
                  ) : (
                    <img
                      style={{ borderRadius: '100%' }}
                      width={30}
                      height={30}
                      alt="foto perfil"
                    />
                  )}
                </div>
                <div className="title">{item?.nome}</div>
              </div>
              <div>
                <div className={`status ${item?.ativa ? 'active' : 'inative'}`}>
                  {item?.ativa ? 'Ativa' : 'Inativa'}
                </div>
              </div>

              <div className="items-end">
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="label"
                  onClick={() => {
                    setConfirmExclusion(true);
                    setCurrentId(item.id);
                    setHasContent(item.temConteudoAtivo);
                    const content = item.turma_conteudo.map(item => {
                      return <div> - {item.conteudo.titulo}</div>;
                    });
                    setContents(content);
                  }}
                >
                  <DeleteIcon
                    style={{
                      color: theme.palette.semantics.feedback.attention.natural,
                      opacity: 0.8,
                    }}
                  />
                </IconButton>
              </div>
            </S.ListItems>
            {confirmExclusion && (
              <ConfirmModal
                handleClose={() => setConfirmExclusion(false)}
                open={confirmExclusion}
                title="Tem certeza que deseja exluir a Turma?"
                subtitle={
                  <div style={{ overflowY: 'scroll', height: 200 }}>
                    {hasContent && (
                      <div>
                        <div
                          style={{
                            fontWeight: 'bold',
                            color: theme.palette.words.title.natural,
                            fontSize: 18,
                          }}
                        >
                          Essa turma possui conteúdos associados:
                        </div>
                        {contents}
                      </div>
                    )}
                  </div>
                }
                buttonText="Confirmar"
                onClick={handleDelete}
              />
            )}
          </div>
        ))}
        {open && (
          <AdicionarTurma
            handleClose={() => setOpen(false)}
            open={open}
            fetchData={() => {
              setOpen(false);
              fetchTurmas();
            }}
            data={optionsTurmas}
          />
        )}
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="label"
          style={{ width: '100%' }}
          onClick={() => setOpen(true)}
        >
          <div className="button-costum">
            <AddCircleOutlineIcon />
            <div className="title-button">ADICIONAR À NOVA TURMA</div>
          </div>
        </IconButton>
      </S.Container>
    </>
  );
};

export default Turmas;
