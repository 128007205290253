import axios from 'axios';
import api from 'services/api';

export const selectsVagas = async () => {
  const res = await api.get('/select-vagas');
  return res.data;
};

export const getOneVacancy = async id => {
  if (id !== undefined) {
    const res = await api.get(`/vaga-show/${id}`);
    return res.data;
  }
  return { data: {} };
};

// Infos Gerais
export const createNewVacancy = async data => {
  const res = await api.post('/vaga-criar', data);
  return res.data;
};

// Used for all steps
export const updateVacancy = async (data, id) => {
  const res = await api.put(`/vaga-update/${id}`, data);
  return res.data;
};

// Detalhamento
export const cityComplet = async uf => {
  if (uf) {
    const fetch = await axios.get(
      `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${uf}/distritos/?orderBy=nome`,
    );
    return fetch?.data;
  }
  const fetch = await axios.get(
    `https://servicodados.ibge.gov.br/api/v1/localidades/distritos/?orderBy=nome`,
  );
  return fetch?.data;
};

// Curriculo
export const requestCurriculo = async id => {
  const res = await api.get(`/candidato/profile/${id}`);
  return res;
};
