import styled, { css } from 'styled-components';

export const QuestionBox = styled.div`
  padding: 20px;
`;

export const ResponseArea = styled.div`
  ${({ theme }) => css`
    margin-top: 18px;
    display: flex;
    flex-direction: column;
    width: 100%;

    .counter {
      align-self: end;
      font-weight: 600;
      color: ${theme.palette.words.text.light};
      font-size: 12px;
      margin-top: 6px;
    }
  `}
`;
