import styled, { css } from 'styled-components';

export const Main = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    display: flex;
    background-color: ${theme.palette.brand.primary.background};
    justify-content: center;
    align-items: center;
  `}
`;

export const Card = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 400px;
    height: 300px;
    padding: 40px;
    background-color: ${theme.palette.system.overlay};
    border: 1px solid ${theme.palette.words.subtitle.natural}4d;
    border-radius: 4;
    justify-content: center;
    align-items: center;

    h1 {
      font-size: 24px;
      color: ${theme.palette.words.title.natural};
      font-weight: 900;
      margin-bottom: 15px;
      text-align: center;
    }

    h2 {
      font-size: 16px;
      color: ${theme.palette.words.subtitle.natural};
      font-weight: 600;
      margin-bottom: 20px;
      text-align: center;
    }
  `}
`;

export const Alert = styled.img`
  width: 60px;
  height: 60px;
`;
