import { Modal } from '@mui/material';
import colors from 'styles/colors';
import styled from 'styled-components';

export const ModalMUI = styled.div`
  position: relative;
  display: none;
  overflow: hidden;
`;

export const Container = styled.div`
  width: 100%;
  background-color: #fff;
  padding: 20px 40px;
  overflow: scroll;
`;

export const Infos = styled.div`
  display: flex;
  justify-content: space-between;

  .formIdent {
    font-weight: bold;
    color: ${colors.greyTitle};
    display: flex;
    flex-direction: column;
  }
`;

export const Header = styled.div`
  background-color: #f5f5f5;
  border: 2px solid ${colors.greyTiny};
  border-radius: 5px;
  margin-top: 20px;
  padding: 20px;

  .topInfos {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;

    .coverArea {
      flex: 2;
      width: 100%;
      height: 200px;
      border-radius: 5px;

      .background {
        background-image: url(${props => props.bg});
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 100%;
        border-radius: 5px;
      }
    }

    .textsArea {
      flex: 3;
      margin-left: 20px;

      .titleForm {
        font-weight: 800;
        font-size: 25px;
        color: ${colors.greyTitle};
      }

      .descriptionForm {
      }

      .initial {
        margin-top: 20px;
        font-weight: 500;

        span {
          font-weight: bold;
          margin-right: 5px;
        }
      }

      .final {
        font-weight: 500;

        span {
          font-weight: bold;
          margin-right: 5px;
        }
      }
    }
  }

  .bottomInfos {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;

    .card {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      .logoCompany {
        margin-right: 30px;

        img {
          max-height: 80px;
        }
      }

      .cardInfos {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .title {
          font-size: 16px;
          font-weight: 500;
          color: ${colors.greySubtitle};
          text-align: center;
        }

        .numbers {
          font-weight: 600;
          font-size: 18px;
          color: ${colors.greyTitle};
        }
      }
    }

    .bar {
      height: 100%;
      min-height: 100px;
      width: 1px;
      background-color: ${colors.greyTiny};
    }
  }
`;

export const TableArea = styled.div`
  position: relative;

  .coverInput {
    width: 100%;
    height: 100px;
    background-color: #fff;
    position: absolute;
    top: 0;
    z-index: 3;
  }

  .coverInputBottom {
    width: 100%;
    height: 100px;
    background-color: #fff;
    position: absolute;
    bottom: 0;
    z-index: 3;
  }
`;

export const Paragraph = styled.p`
  font-weight: bold;
  color: ${colors.blueTitle};
  font-size: 14px;
  text-align: ${props => (props.center ? 'center' : 'start')};

  &.normal {
    font-size: 16px;
  }

  &.name {
    text-transform: capitalize;
  }

  &.small {
    font-weight: 500;
    font-size: 12px;
  }

  &.green {
    color: ${colors.greenSucces};
    background-color: ${colors.greenBackground};
    border-radius: 20px;
    text-align: center;
  }

  &.orange {
    color: ${colors.orangeAlert};
    background-color: ${colors.orangeBackground};
    border-radius: 20px;
    text-align: center;
  }

  &.red {
    color: ${colors.redDanger};
    background-color: ${colors.redBackground};
    border-radius: 20px;
    text-align: center;
  }
`;

export const ColDriver = styled.div`
  display: flex;
  flex-direction: column;
  text-transform: capitalize;

  h1 {
    font-size: 14px;
    font-weight: 600;
    color: ${colors.blueTitle};
  }

  &.flex {
    flex-direction: row;
    align-items: center;
  }
`;

export const Photo = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 40px;
  overflow: hidden;
  padding: 5px;
  border: 1px solid ${colors.greenMain};
  margin-right: 10px;

  img {
    width: 100%;
    object-fit: cover;
  }
`;
