import styled, { css, keyframes } from 'styled-components';

const jump = keyframes`
    0% {
        padding-top: 0px;
    }
    50% {
        padding-top: 4px;
    }
    100% {
        padding-top: 0px;
    }
`;

export const Wrapper = styled.div`
  gap: 20px;
  display: grid;
  width: 100%;
  grid-template-columns: repeat(8, 1fr);

  @media (max-width: 2560px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 1440px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 1136px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 787px) {
    grid-template-columns: repeat(1, 1fr);
  }

  .cardContent {
    width: 100%;
  }

  .addCard {
    width: 100%;
  }
`;

export const Global = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SeeMore = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 20px;
    height: 50px;

    .textSeeMore {
      font-size: 18px;
      font-weight: 600;
      color: ${theme.palette.words.title.natural};
      cursor: pointer;
    }

    .divIcon {
      background-color: red;
      animation: ${jump} 1s ease infinite;
      position: relative;
      display: flex;
      justify-content: center;

      .icon {
        cursor: pointer;
        position: absolute;
      }
    }
  `}
`;
