import { ReactComponent as user } from 'images/icons/motoristas/perfil/sidebar/user.svg';
import { ReactComponent as desvios } from 'images/icons/motoristas/perfil/sidebar/desvios.svg';
import { ReactComponent as avaliacao } from 'images/icons/motoristas/perfil/sidebar/avaliacao.svg';
import { ReactComponent as checklist } from 'images/icons/motoristas/perfil/sidebar/checklist.svg';
import { ReactComponent as pad } from 'images/icons/motoristas/perfil/sidebar/pad.svg';
import { ReactComponent as estatisticas } from 'images/icons/motoristas/perfil/sidebar/estatisticas.svg';

export const itemsConfigTransportadora = [
  {
    name: 'informacoes',
    icon: user,
    text: 'Informações Gerais',
  },
  {
    name: 'avaliacoes_direcao',
    icon: avaliacao,
    text: 'Avaliações direção',
  },
  {
    name: 'desvios_direcao',
    icon: desvios,
    text: 'Desvios direção',
  },
  {
    name: 'avaliacoes_descarga',
    icon: avaliacao,
    text: 'Avaliações descarga',
  },
  {
    name: 'desvios_descarga',
    icon: desvios,
    text: 'Desvios descarga',
  },
  {
    name: 'formulario',
    icon: checklist,
    text: 'Formulário',
  },
  {
    name: 'estatisticas',
    icon: estatisticas,
    text: 'Estatísticas',
  },
].filter(Boolean);
