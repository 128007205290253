import React, { useState, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

// services

// utils
import { padWithLeadingZeros } from 'utils/helpers';

// components
import { IconButton, CardMedia } from '@mui/material';
import Button from 'components/Buttons/Default';
import ButtonOutlined from '@mui/material/Button';
import Input from 'components/Inputs/TextField';
import { toast } from 'react-toastify';
import Loading from 'components/Loading';

// styles
import { useTheme } from 'styled-components';

// icons
import SearchIcon from '@mui/icons-material/Search';
import PhotoOutlinedIcon from '@mui/icons-material/PhotoOutlined';
import SlideshowOutlinedIcon from '@mui/icons-material/SlideshowOutlined';
import BoltOutlined from '@mui/icons-material/BoltOutlined';
import DisabledByDefaultOutlinedIcon from '@mui/icons-material/DisabledByDefaultOutlined';
import HeartBrokenIcon from '@mui/icons-material/HeartBroken';
import PlayLessonOutlinedIcon from '@mui/icons-material/PlayLessonOutlined';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

// img
import ImageNotFound from 'images/empty/imageempty.png';
import * as S from './style.js';
import ModalReviewFull from '../../../TakeTest';

// services
import { getContentsReproved } from '../../services';

const Reprovacoes = ({ setInfo }) => {
  const theme = useTheme();
  const [searchParams] = useSearchParams();

  const id = searchParams.get('id');
  const tipo = searchParams.get('tipo');
  const [cards, setCards] = useState([]);

  const [openPreviewFull, setOpenPreviewFull] = useState(false);
  const [curentContent, setCurentContent] = useState([]);

  const refScrollTab = useRef(null);

  const [tabSelected, setTabSelected] = useState(0);

  const [mouseDown, setMouseDown] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [isInitScroll, setInitScroll] = useState(false);

  const [arrayConteudo, setArrayConteudo] = useState([]);
  const [arrayConteudoBK, setArrayConteudoBK] = useState([]);

  const [arrayTags, setArrayarrayTags] = useState([]);

  const [search, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);

  const updateData = () => {
    setLoading(true);
    getContentsReproved(id, tipo).then(res => {
      if (res.success) {
        const contents = res.data;
        const tags = res.tags;
        setArrayConteudo(contents);
        setArrayConteudoBK(res.data);
        setArrayarrayTags(tags);
        setCards(res.cards);
        setLoading(false);
        setInfo(res.aluno);
      }
    });
  };
  useEffect(() => {
    updateData();
  }, []);

  const getMedia = (key, value, index) => {
    const tmp = key.toUpperCase();
    switch (tmp) {
      case 'VÍDEO':
        return (
          <S.StyledTooltip key={index} title={`${key} (${value})`} arrow>
            <SlideshowOutlinedIcon
              sx={{ color: theme.palette.brand.primary.light, marginLeft: 1 }}
            />
          </S.StyledTooltip>
        );
      case 'TEXTO':
        return (
          <S.StyledTooltip key={index} title={`${key} (${value})`} arrow>
            <BoltOutlined
              sx={{ color: theme.palette.brand.primary.light, marginLeft: 1 }}
            />
          </S.StyledTooltip>
        );
      case 'IMAGEM':
        return (
          <S.StyledTooltip key={index} title={`${key} (${value})`} arrow>
            <PhotoOutlinedIcon
              sx={{ color: theme.palette.brand.primary.light, marginLeft: 1 }}
            />
          </S.StyledTooltip>
        );
      case 'PDF':
        return (
          <S.StyledTooltip key={index} title={`${key} (${value})`} arrow>
            <PlayLessonOutlinedIcon
              sx={{ color: theme.palette.brand.primary.light, marginLeft: 1 }}
            />
          </S.StyledTooltip>
        );
    }
  };

  const formatDate = date => {
    return `${`Até ${new Date(date).toLocaleDateString()}`}`;
  };

  const handleSearch = text => {
    const filters = arrayConteudoBK.filter(
      value =>
        value.titulo.toLowerCase().includes(text.toLowerCase()) ||
        value.descricao.toLowerCase().includes(text.toLowerCase()) ||
        value.tags.toString().toLowerCase().includes(text.toLowerCase()),
    );
    setSearchValue(text);
    setArrayConteudo(filters);
  };

  const imageExists = url => {
    return url;
  };

  const handleClickTag = (tab, tabIndex) => {
    filterByTags(tab);
    setTabSelected(tabIndex);
  };

  const stopDragging = () => {
    setMouseDown(false);
  };

  const scrollMove = value => {
    refScrollTab.current.scrollLeft = value;
    if (refScrollTab.current.scrollLeft < 50) {
      setInitScroll(false);
    } else {
      setInitScroll(true);
    }
  };

  const onMouseMove = function (e) {
    if (!mouseDown) {
      return;
    }
    const x = e.pageX - refScrollTab.current.offsetLeft;
    const scroll = x - startX;
    refScrollTab.current.scrollLeft = scrollLeft - scroll;

    if (refScrollTab.current.scrollLeft < 50) {
      setInitScroll(false);
    } else {
      setInitScroll(true);
    }
  };
  const filterByTags = tag => {
    switch (tag) {
      case 'Todos':
        setArrayConteudo(arrayConteudoBK);
        break;
      case 'Ativos':
        const ativos = arrayConteudoBK.filter(el => el.status === 'Ativo');
        setArrayConteudo(ativos);
        break;
      case 'Rascunhos':
        const rascunhos = arrayConteudoBK.filter(
          el => el.status === 'Rascunho',
        );
        setArrayConteudo(rascunhos);
        break;
      case 'Agendado':
        const agendados = arrayConteudoBK.filter(
          el => el.status === 'Agendado',
        );
        setArrayConteudo(agendados);
        break;
      case 'Agendado':
        const Finalizado = arrayConteudoBK.filter(
          el => el.status === 'Finalizados',
        );
        setArrayConteudo(Finalizado);
        break;

      default:
        const all = arrayConteudoBK.filter(el => el.tags.some(d => d === tag));
        setArrayConteudo(all);
    }
  };
  const startDragging = function (e) {
    setMouseDown(true);
    setStartX(e.pageX - refScrollTab.current.offsetLeft);
    setScrollLeft(refScrollTab.current.scrollLeft);
  };

  const capitalizeFirstLetter = str => {
    str = str.toLowerCase();
    return str;
  };

  const getTentativas = (i, tentativas) => {
    const complete = Number(
      i.respostas.filter(p => p.status === 'REPROVADO')?.length,
    );
    const total = complete >= 10 ? 0 : Number(tentativas - complete);

    return (
      <div>
        {Array.from(Array(complete), (_, x) => x)?.map(() => {
          return (
            <HeartBrokenIcon
              sx={{
                color: theme.palette.brand.secondary.natural,
                height: 20,
                width: 20,
              }}
            />
          );
        })}

        {Array.from(Array(Number(total)), (_, x) => x)?.map(() => {
          return (
            <HeartBrokenIcon
              sx={{
                color: theme.palette.semantics.feedback.unknown.light,
                height: 20,
                width: 20,
              }}
            />
          );
        })}
      </div>
    );
  };

  if (loading) {
    return (
      <div style={{ position: 'absolute', top: '80%', left: '57%' }}>
        <Loading />
      </div>
    );
  }

  return (
    <S.Container>
      {openPreviewFull && (
        <ModalReviewFull
          retakeTest
          open={openPreviewFull}
          handleClose={() => {
            setOpenPreviewFull(false);
            setArrayConteudo([]);
            setTimeout(() => {
              updateData();
            }, 100);
          }}
          isPreview
          data={curentContent}
        />
      )}

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            color: theme.palette.brand.primary.natural,
            fontSize: 20,
            fontWeight: 900,
          }}
        >
          Minhas Reprovações
        </div>
        <S.SearchContainer>
          <Input
            style={{ width: 300 }}
            value={search}
            startIcon={
              <SearchIcon sx={{ color: theme.palette.brand.primary.light }} />
            }
            placeholder="Buscar Conteúdo"
            onChange={e => handleSearch(e.target.value)}
          />
        </S.SearchContainer>
      </div>
      <S.containerCard>
        {cards.map((item, k) => (
          <div className="container-card">
            <div className="icon-container">
              {k === 0 && <DisabledByDefaultOutlinedIcon />}
              {k === 1 && <HeartBrokenIcon />}
              {k === 2 && <SlideshowOutlinedIcon />}
            </div>
            <div>
              <div>
                <h1>{padWithLeadingZeros(item.value, 2)}</h1>
                <h3>{item.text}</h3>
              </div>
            </div>
          </div>
        ))}
      </S.containerCard>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          marginBottom: 20,
          marginTop: 20,
        }}
      >
        <IconButton
          disabled={!isInitScroll}
          onClick={() => scrollMove(refScrollTab.current.scrollLeft - 50)}
        >
          <KeyboardArrowLeftIcon
            sx={{
              color: !isInitScroll
                ? theme.palette.system.overlay
                : 'rgba(0, 0, 0, 0.54)',
            }}
          />
        </IconButton>
        <S.TagContent
          onMouseLeave={stopDragging}
          onMouseUp={stopDragging}
          onMouseDown={startDragging}
          ref={refScrollTab}
          onMouseMove={onMouseMove}
        >
          {arrayTags?.map((i, k) => (
            <IconButton
              key={k}
              disableRipple
              onClick={() => handleClickTag(i, k)}
            >
              <S.Tag tabSelected={tabSelected === k}>{i}</S.Tag>
            </IconButton>
          ))}
        </S.TagContent>
        <IconButton
          onClick={() => scrollMove(refScrollTab.current.scrollLeft + 50)}
        >
          <KeyboardArrowRightIcon />
        </IconButton>
      </div>
      <div
        style={{
          color: theme.palette.brand.primary.natural,
          fontSize: 20,
          fontWeight: 900,
        }}
      >
        Todos
      </div>
      <S.Space h={20} />
      <S.CardContainer>
        {arrayConteudo.map((i, k) => (
          <S.Card2 key={k}>
            <S.CardMedia>
              <CardMedia
                component="img"
                height="196"
                image={
                  imageExists(i.imagem_capa) ? i.imagem_capa : ImageNotFound
                }
                alt=""
              />
            </S.CardMedia>
            <S.CardDescription>
              <S.TagsContainer>
                <>
                  {i.tags.slice(0, 5)?.map((i, k) => (
                    <S.Tag key={k}>{capitalizeFirstLetter(i)}</S.Tag>
                  ))}
                  <S.containerDate>
                    {i.data_fim ? formatDate(i.data_fim) : ''}
                  </S.containerDate>
                </>
              </S.TagsContainer>

              <S.containerTitle>{i.titulo}</S.containerTitle>
              <S.containerSubTitle>{i.descricao}</S.containerSubTitle>
              <S.hartContainer>
                <h1>Tentativas:</h1>
                {getTentativas(i, i.tentativas)}
              </S.hartContainer>
              <>
                <S.ButtonConainer>
                  <S.Space h={10} />
                  <Button
                    style={{ width: 300 }}
                    onClick={() => {
                      setCurentContent(i);
                      setOpenPreviewFull(true);
                    }}
                  >
                    VISUALIZAR
                  </Button>
                </S.ButtonConainer>
              </>
            </S.CardDescription>
          </S.Card2>
        ))}
      </S.CardContainer>
      {arrayConteudo?.length === 0 && (
        <S.ContainerEmptyState>
          <div>
            <HeartBrokenIcon
              sx={{
                color: theme.palette.semantics.feedback.unknown.light,
                height: 100,
                width: 100,
              }}
            />
          </div>
          <S.EmptyStateTitle>
            <h1 style={{ fontSize: 28 }}>
              Parabéns! Você não possui reprovações.
            </h1>
            {/* <h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h3> */}
            <S.Space />
          </S.EmptyStateTitle>
        </S.ContainerEmptyState>
      )}
    </S.Container>
  );
};

export default Reprovacoes;
