import api from 'services/api';
import qs from 'qs';

export const getInfosByPeriod = async (id, query) => {
  const res = await api.get(`/my-form/history/${id}?${qs.stringify(query)}`);
  return res.data;
};

export const getFormResponse = async (id, histId, alunoId, alunoTipo) => {
  const res = await api.get(
    `/my-form/${id}?${qs.stringify({
      selects: true,
      histId,
      alunoId,
      alunoTipo,
    })}`,
  );
  return res.data;
};
