export const tabs = [
  {
    value: 'ATIVO',
    label: 'Ativos',
  },
  {
    value: 'INATIVO',
    label: 'Inativos',
  },
];

export const order = [
  {
    value: 'ultima_tratativa',
    label: 'Última tratativa',
  },
  {
    value: 'desvios.TOTAL',
    label: 'Desvios tratados',
  },
  {
    value: 'tempo_medio',
    label: 'Tempo médio de tratativa',
  },
  {
    value: 'nivel',
    label: 'Perfil de acesso',
  },
];
