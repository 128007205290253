// Styles
import colors from 'styles/colors';

// React
import { toast } from 'react-toastify';
import React, { useState, useEffect, useRef } from 'react';

// Components
import Input from 'components/Inputs/TextField';
import { Switch } from 'components/Inputs/Switch';
import SelectInput from 'components/Inputs/Select';
import ConfirmModal from 'components/ConfirmModal';
import GhostButton from 'components/Buttons/Ghost';
import DefaultButton from 'components/Buttons/Default';

// Components Material UI
import { EditOutlined } from '@mui/icons-material';
import { Icon, IconButton, Modal, Grid } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

// Utils
import { celMask } from 'utils/masks';
import { firestorage } from 'utils/firebase';
import * as services from '../../services';
import * as S from './styled';

const ModalUsuario = ({
  open,
  dataEdit,
  handleClose,
  setDataEdit,
  setNewData,
  activedFilials,
}) => {
  // General States
  const [loading, setLoading] = useState(false);

  // Modal Controll and States
  const [confirmEdit, setConfirmEdit] = useState(false);
  const [confirmAdd, setConfirmAdd] = useState(false);
  const [margin, setMargin] = useState('-550px');

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setMargin('0px');
      }, 10);
    }
  }, [open]);

  const behaviorCloseModal = () => {
    setDataEdit(null);
    setMargin('-550px');
    setTimeout(() => {
      handleClose();
    }, 380);
  };

  // User fields
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [funcao, setFuncao] = useState('');
  const [filial, setFilial] = useState('');
  const [telefone, setTelefone] = useState('');
  const [matricula, setMatricula] = useState('');
  const [nivel, setNivel] = useState('');
  const [status, setStatus] = useState(true);
  const [foto, setFoto] = useState(null);

  // Input file reference (for picture)
  const inputFile = useRef(null);

  // Level options  (mocked)
  const levels = [
    {
      value: 1,
      name: 'Administrador',
    },
    {
      value: 2,
      name: 'Gerente',
    },
    {
      value: 3,
      name: 'Membro',
    },
  ];

  // Fill fields if action are edit
  useEffect(() => {
    if (!dataEdit) {
      return null;
    }
    setNome(dataEdit?.nome || '');
    setEmail(dataEdit?.email || '');
    setFuncao(dataEdit?.funcao || '');
    setFilial(dataEdit?.id_da_filial || '');
    setTelefone(dataEdit?.telefone_de_contato || '');
    setMatricula(dataEdit?.matricula || '');
    setNivel(dataEdit?.nivel || '');
    setFoto(dataEdit?.foto || '');
    switch (dataEdit?.status || '') {
      case 'ATIVO':
        setStatus(true);
        break;
      case undefined:
        setStatus(true);
        break;
      default:
        setStatus(false);
    }
  }, [dataEdit]);

  // CONFIRM ACTION (FIELDS AND REQUESTS)
  const verifyFields = () => {
    if (!nome) {
      toast.error('Preencha um nome para o usuário');
      return;
    }

    if (!email) {
      toast.error('Preencha um email para o usuário');
      return;
    }

    const regexEmail =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/gi;

    if (!regexEmail.test(email)) {
      toast.error('Preencha um email válido');
      return;
    }

    if (!funcao) {
      toast.error('Preencha uma função para o usuário');
      return;
    }

    if (!filial) {
      toast.error('Selecione a filial do usuário');
      return;
    }

    if (!telefone) {
      toast.error('Preencha um telefone para o usuário');
      return;
    }

    if (!matricula) {
      toast.error('Preencha a matricula do usuário');
      return;
    }

    if (!nivel) {
      toast.error('Selecione um nivel para o usuário');
      return;
    }

    dataEdit?.id_local ? setConfirmEdit(true) : setConfirmAdd(true);
  };

  const confirmEditUser = async () => {
    const data = {
      id: dataEdit?.id,
      id_local: dataEdit.id_local,
      nome,
      email,
      telefone_de_contato: telefone,
      status: status ? 'ATIVO' : 'INATIVO',
      funcao,
      id_da_filial: filial,
      filial: activedFilials.find(item => item.id === filial).nome,
      matricula,
      nivel,
      foto,
    };

    setLoading(true);
    const res = await services.updateUsuario(data);
    if (res.data.success) {
      setNewData(res.data.data);
      behaviorCloseModal();
      toast.success(res.data.message);
    } else {
      toast.error(res.data.message || 'Erro ao editar usuário');
    }
    setConfirmEdit(false);
    setLoading(false);
  };

  const confirmCreateUser = async () => {
    const data = {
      nome,
      email,
      telefone_de_contato: telefone,
      status: status ? 'ATIVO' : 'INATIVO',
      funcao,
      id_da_filial: filial,
      filial: activedFilials.find(f => f.id === filial).nome,
      matricula,
      nivel,
      foto,
    };
    setLoading(true);
    const res = await services.createUsuario(data);
    if (res.data.success) {
      setNewData(res.data.data);
      behaviorCloseModal();
      toast.success(res.data.message);
    } else {
      toast.error(res.data.message || 'Erro ao criar usuário');
    }
    setConfirmAdd(false);
    setLoading(false);
  };

  // Change Photo
  // Atenção o upload da foto nao garante a finalização do usuario
  // Isso pode gerar upload desnecessario de imagens no firebase
  const updateUserImage = async () => {
    const file = inputFile.current.files[0];
    if (file) {
      try {
        setLoading(true);
        const dh = new Date().toDateString();
        if (!file) return toast.error('Não foi possível enviar sua imagem');

        const uploadTask = firestorage
          .ref(`/usuarios/${dh}_${file.name}`)
          .put(file);
        uploadTask.on(
          'state_changed',
          snapShot => {},
          err => {
            console.log('Err', err);
          },
          () => {
            firestorage
              .ref(`/usuarios/`)
              .child(`${dh}_${file.name}`)
              .getDownloadURL()
              .then(fireBaseUrl => {
                setFoto(fireBaseUrl);
              });
          },
        );
      } catch (error) {
        toast.error('Não foi possível enviar sua imagem');
      }
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={behaviorCloseModal}
        disableEscapeKeyDown={false}
      >
        <S.Container margin={margin}>
          <S.Header>
            <div style={{ alignItems: 'center' }}>
              {dataEdit ? (
                <>
                  <EditOutlined
                    sx={{ color: colors.greenMain }}
                    fontSize="medium"
                  />
                  <h2>Editar Usuário</h2>
                </>
              ) : (
                <>
                  <Icon sx={{ color: colors.greenMain }} fontSize="medium">
                    add_circle_outline
                  </Icon>
                  <h2>Adicionar Usuário</h2>
                </>
              )}
            </div>

            <IconButton size="small" onClick={behaviorCloseModal}>
              <Icon sx={{ color: colors.greySubtitle }}>close</Icon>
            </IconButton>
          </S.Header>

          <S.Main>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <S.Section>INFORMAÇÕES DE PERFIL</S.Section>
              </Grid>
              <Grid item xs={12} sm={12}>
                <label>
                  <input
                    type="file"
                    multiple={false}
                    ref={inputFile}
                    onChange={updateUserImage}
                    disabled={loading}
                  />
                  <div className="imgArea">
                    {foto && (
                      <span className="borderImage">
                        <img src={foto} />
                      </span>
                    )}

                    {!foto && <span className="empty">{nome[0]}</span>}
                  </div>
                  <div className="textArea">
                    <EditOutlinedIcon htmlColor="#656E8C" />
                    <span>Alterar Foto</span>
                  </div>
                </label>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Input
                  required
                  label="Nome Completo"
                  placeholder=""
                  value={nome}
                  onChange={e => setNome(e.target.value)}
                  name="nome"
                  id="nome"
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Input
                  required
                  label="Email"
                  placeholder=""
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  name="email"
                  id="email"
                />
              </Grid>

              <Grid container item xs={12} sm={12} display="flex" spacing={2}>
                <Grid item xs={6} sm={6}>
                  <Input
                    required
                    label="Função"
                    placeholder=""
                    value={funcao}
                    onChange={e => setFuncao(e.target.value)}
                    name="funcao"
                    id="funcao"
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <SelectInput
                    required
                    handleChange={e => setFilial(e.target.value)}
                    name="filial"
                    label="Filial"
                    data={[
                      { name: 'Selecione uma filial', value: '' },
                      ...activedFilials.map(f => ({
                        name: f.nome,
                        value: f.id,
                      })),
                    ]}
                    value={filial}
                  />
                </Grid>
              </Grid>

              <Grid container item xs={12} sm={12} display="flex" spacing={2}>
                <Grid item xs={6} sm={6}>
                  <Input
                    required
                    type="text"
                    label="Telefone"
                    placeholder=""
                    value={celMask(telefone)}
                    onChange={e => setTelefone(e.target.value)}
                    name="telefone"
                    id="telefone"
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Input
                    required
                    label="Matricula"
                    placeholder=""
                    value={matricula}
                    onChange={e => setMatricula(e.target.value)}
                    name="matricula"
                    id="matricula"
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2} item xs={12} sm={12}>
                <Grid item xs={6} sm={6}>
                  <SelectInput
                    required
                    handleChange={e => setNivel(e.target.value)}
                    name="Nível"
                    label="Nível"
                    data={[
                      { name: 'Selecione um nível', value: '' },
                      ...levels,
                    ]}
                    value={nivel}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  display="flex"
                  alignContent="center"
                  marginTop="25px"
                >
                  <Switch
                    check={status}
                    setCheckbox={setStatus}
                    textOn="Usuário Ativo"
                    textOff="Usuário Inativo"
                  />
                </Grid>
              </Grid>
            </Grid>
          </S.Main>

          <S.Footer>
            <div>
              <GhostButton onClick={behaviorCloseModal} size="medium">
                CANCELAR
              </GhostButton>

              <DefaultButton
                onClick={verifyFields}
                size="medium"
                sx={{ marginLeft: '20px' }}
              >
                {dataEdit ? 'Salvar Alterações' : 'Salvar'}
              </DefaultButton>
            </div>
          </S.Footer>
        </S.Container>
      </Modal>

      {/* Confirmação de edição */}
      <ConfirmModal
        open={confirmEdit}
        handleClose={() => setConfirmEdit(false)}
        title="Deseja editar cliente?"
        titleIcon={
          <Icon sx={{ color: colors.greenMain }} fontSize="medium">
            add_circle_outline
          </Icon>
        }
        subtitle="Atenção! Verifique todos os campos antes de confirmar a operação."
        buttonText="Confirmar"
        onClick={() => confirmEditUser()}
        loading={loading}
      />

      {/* Confirmação de adição */}
      <ConfirmModal
        open={confirmAdd}
        handleClose={() => setConfirmAdd(false)}
        title="Deseja confirmar criação do cliente?"
        titleIcon={
          <EditOutlined sx={{ color: colors.greenMain }} fontSize="medium" />
        }
        subtitle="Atenção! Verifique todos os campos antes de confirmar a operação."
        buttonText="Confirmar"
        onClick={() => confirmCreateUser()}
        loading={loading}
      />
    </>
  );
};

export default ModalUsuario;
