/* eslint-disable import/order */

// Styles
import * as S from './styled';
import colors from 'styles/colors';
import background from 'images/banner_perfil_aluno.png';

// React
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useLayoutEffect, useState } from 'react';

// Constants
import { tabs } from './constants';

const MeuPerfil = () => {
  // Navigate
  const navigate = useNavigate();

  // Redux
  const { user } = useSelector(state => state.auth.user);

  const [tab, setTab] = useState(0);
  const [small, setSmall] = useState(false);

  // Reset scroll page on open
  useEffect(() => {
    let doc = document.getElementById('bodyGlobalWrapper');
    doc.scroll(0, 0);
  }, []);

  // Define Current Route
  const location = useLocation();
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const page = searchParams.get('page');
    const tab = tabs.find(tab => tab.search === page);
    setTab(tab?.index || 0);
    if (!tab) navigate(tabs[0].link);
  }, [location]);

  // Scroll
  useLayoutEffect(() => {
    let doc = document.getElementById('bodyGlobalWrapper');

    doc.addEventListener('scroll', () => {
      const position = doc?.scrollTop;
      if (position > 442) {
        setSmall(true);
      } else {
        setSmall(false);
      }
    });
  }, []);

  const blockedScrollMomment = () => {
    let doc = document.getElementById('bodyGlobalWrapper');
    if (small) {
      doc.scrollTo({
        behavior: 'smooth',
        top: 415,
        left: 0,
      });
    }
  };

  // Componenet Formated
  const formatedComponent = () => {
    const { Component } = tabs[tab];
    return <Component />;
  };

  const handleTab = index => {
    setTab(index);
    let tab = tabs[index];
    navigate(tab.link, { replace: true });
    blockedScrollMomment();
  };

  // --------------------------------------------RENDER--------------------------------------------//
  return (
    <>
      <S.Container>
        <S.Background src={background} />
        <S.Content small={small}>
          <S.LineHeader>
            <div className="imgArea">
              {user?.foto && <img src={user?.foto} />}
              {!user?.foto && <div className="letter">{user?.nome[0]}</div>}
            </div>
            <div className="infoArea">
              <div className="name">{user?.nome}</div>
              <div className="filial">{user?.filial?.nome}</div>
            </div>
          </S.LineHeader>
        </S.Content>
      </S.Container>
      <S.AbsBox>
        <S.LineTabs tab={tab}>
          <>
            {tabs.map((item, index) => {
              const { title, Icon } = item;
              return (
                <S.Tab
                  onClick={() => handleTab(index)}
                  active={index === tab}
                  key={index}
                >
                  <Icon
                    htmlColor={index === tab ? colors.greenMain : colors.grey}
                    size="large"
                  />
                  <div className="title">{title}</div>
                </S.Tab>
              );
            })}
            <div className="bar" />
          </>
        </S.LineTabs>
      </S.AbsBox>
      <S.AbsBoxFixed small={small}>
        <S.LineTabs tab={tab}>
          <>
            {tabs.map((item, index) => {
              const { title, Icon } = item;
              return (
                <S.Tab
                  onClick={() => handleTab(index)}
                  active={index === tab}
                  key={index}
                >
                  <Icon
                    htmlColor={index === tab ? colors.greenMain : colors.grey}
                    size="large"
                  />
                  <div className="title">{title}</div>
                </S.Tab>
              );
            })}
            <div className="bar" />
          </>
        </S.LineTabs>
      </S.AbsBoxFixed>
      <S.PageContainer id="containerPage">
        {formatedComponent()}
      </S.PageContainer>
    </>
  );
};

export default MeuPerfil;
