import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 1rem;
`;
export const Content = styled.div`
  width: 100%;
  display: flex;
  gap: 0.5rem;
`;

export const GraphContainer = styled.div`
  width: 50%;
`;

export const ContainerList = styled.div`
  width: 100%;
`;

export const Title = styled.h1`
  font-size: 24px;
  font-weight: 900;
  letter-spacing: 0px;
  color: #4b5166;
`;
