import React, { useEffect, useState, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { startOfMonth, subDays, subMonths } from 'date-fns';
import { useSelector } from 'react-redux';

// components @mui
import { SvgIcon } from '@mui/material';

// components custom
import Tabs from './components/Tabs';
import { Graph } from './components/Graph';
import { BarraPercentual } from './components/BarPercent';
import DrawerFilters from './components/DrawerFilters';
import DateRangePicker from 'components/DateRangePicker';
import { CustomizedSelect } from 'components/SectionPeriod';
import { DefaultTable } from 'components/_Table/templates/default';
import GhostButton from 'components/Buttons/Ghost';
import RadarDesvios from './components/RadarDesvios';

// styled
import {
  Container,
  Divider,
  StyledButton,
  HeaderDesvios,
  ContainerExport,
} from './styled';

// icons svg
import { ReactComponent as filterIco } from 'images/icons/filter.svg';
import { SaveAlt } from '@mui/icons-material';

// constants
import {
  filtersGraficoParams,
  tabsItemsPth,
  tabsItemsPtkm,
  colorsCriticidade,
  tabsItemsPthsProvider,
  tabsItemsPtkmProvider,
  filtersGraficoParamsProvider,
} from './constants';
import { columns } from './columns';

// services
import {
  getDesempenhoPtsH,
  getDesviosTypes,
  getClientes,
  getDesvioCriticidade,
  getDesvioCriticidadeTipo,
  getPadroesCriticidade,
  getDesempenhoPtsKm,
  getExportDesempenhoPtsKm,
  getExportDesempenhoPtsH,
  getHistorico,
} from './services';
import { usePlans } from 'hooks/usePlans';
import { toast } from 'react-toastify';
import { HistoryPoints } from './components/HistoryPoints';
import { useQuery } from 'react-query';

const DesempenhoGeral = () => {
  const { isProvider, hasMonitoramento, hasTelemetria } = usePlans();

  const selects = useSelector(state => state.selects);
  const empresas =
    selects?.empresas?.map(i => {
      return { value: i.nome, id: i.id };
    }) || [];

  const filiais =
    selects.filials?.map(i => {
      return { value: i.nome, id: i.id };
    }) || [];

  const [clientes, setClientes] = useState([]);

  const finalDate = new Date();

  const [anchorEl, setAnchorEl] = useState(null);
  const [openFilterCriticidadeDesvio, setOpenFilterCriticidadeDesvio] =
    useState(null);

  // tabs ptskm e ptsH
  const [currentTab, setCurrentTab] = useState(0);

  // states ptskm
  const [pontoskm, setPontoskm] = useState([]);

  // states ptsH
  const [PontosH, setPontosH] = useState([]);

  // states compartilhados
  const [currentSectionGraph, setCurrentSectionGraaph] = useState('Desvios');
  const [openIntervalDesvios, setOpenIntervalDesvios] = useState({
    initialDate: subDays(new Date(), 30),
    finalDate: new Date(),
  });
  const [loading, setLoading] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [initialDate, setInitialDate] = useState(subDays(finalDate, 30));
  const [meta, setMeta] = useState(0);
  const [filtersPage, setFiltersPage] = useState(null);
  const [filtersPageCriticidade, setFiltersPageCriticidade] = useState(null);
  const [desvioCriticidade, setDesvioCriticidade] = useState([]);
  const [optionsDesvio, setOptionsDesvio] = useState([]);
  const [currentOptionSelect, setCurrentOptionsSelect] = useState(null);
  const [desvioCriticidadeList, setDesvioCriticidadeList] = useState([]);
  const [historyData, setHistoryData] = useState([]);
  const [historicoTab, setHistoricoTab] = useState(
    hasMonitoramento ? 'ptsHr' : 'ptsKm',
  );
  const [historyDates, setHistoryDates] = useState({
    finalDate: new Date(),
    initialDate: subMonths(new Date(), 11),
  });

  const theme = useTheme();

  const handlePageTab = (event, newValue) => {
    setCurrentSectionGraaph('Desvios');
    setCurrentTab(newValue);
  };

  const fetchDesvioCriticidade = async () => {
    setCurrentOptionsSelect(null);
    const params = {
      ...openIntervalDesvios,
      filial: filtersPageCriticidade?.filiais || null,
      client: filtersPageCriticidade?.cliente,
      empresas: filtersPageCriticidade?.empresas || null,
    };
    const res = await getDesvioCriticidade(params);
    setDesvioCriticidade(res.data);
    if (res.fator_risco?.length)
      setCurrentOptionsSelect(res.fator_risco[0]?.id);
  };

  const fetchClientes = async () => {
    const resClientes = await getClientes();
    setClientes(resClientes);
    const index = filtersGraficoParams.findIndex(
      filter => filter.name === 'cliente',
    );
    if (index !== -1) {
      filtersGraficoParams[index].data = resClientes;
    }
  };

  const fetchDesempenhoPtsH = async () => {
    const params = {
      filial: filtersPage?.filiais || null,
      client: filtersPage?.cliente || null,
      empresas: filtersPage?.empresas || null,
      desvio: filtersPage?.tipos_desvio || null,
      initialDate,
      finalDate,
      tab: currentSectionGraph,
    };
    const response = await getDesempenhoPtsH(params);

    setPontosH(response.data || []);
    setMeta(response.meta || 0);
    setLoading(false);
  };

  const fetchDesempenhoPtsKm = async () => {
    const params = {
      filial: filtersPage?.filiais || null,
      empresas: filtersPage?.empresas || null,
      client: filtersPage?.cliente || null,
      desvio: filtersPage?.tipos_desvio || null,
      initialDate,
      finalDate,
      tab: currentSectionGraph,
    };

    const res = await getDesempenhoPtsKm(params);
    setPontoskm(res?.data || []);
    setMeta(res?.meta || 0);
    setLoading(false);
  };

  const fetchDataExport = async () => {
    const params = {
      filial: filtersPage?.filiais || null,
      client: filtersPage?.cliente || null,
      empresas: filtersPage?.empresas || null,
      desvio: filtersPage?.tipos_desvio || null,
      initialDate,
      finalDate,
      tab: currentSectionGraph,
    };
    const res =
      currentTab === 0
        ? await getExportDesempenhoPtsKm(params)
        : await getExportDesempenhoPtsH(params);

    return res;
  };

  const downloadFile = async () => {
    const data = await fetchDataExport();
    if (data?.data?.link) {
      const anchor = document.createElement('a');
      anchor.href = data?.data?.link;
      anchor.download = 'download';
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    } else {
      toast.error(data.message);
    }
  };

  const fetchPadroesCriticidade = async () => {
    const params = {
      ...openIntervalDesvios,
    };

    const response = await getPadroesCriticidade(params);
    const result = response?.data?.map(item => {
      return {
        value: item?.id,
        label: item?.nome,
        criticality: item?.criticidade,
        color: colorsCriticidade[item?.criticidade],
      };
    });
    setOptionsDesvio(result || []);
  };

  const fetchDesvioCriticidadeTipo = async () => {
    setLoadingTable(true);
    const criticidade = optionsDesvio.filter(
      item => item.value === currentOptionSelect,
    );
    const params = {
      idDesvioTipo: currentOptionSelect,
      criticidade: criticidade[0]?.criticality,
      ...openIntervalDesvios,
      filial: filtersPageCriticidade?.filiais || null,
      client: filtersPageCriticidade?.cliente,
      empresas: filtersPageCriticidade?.empresas || null,
    };
    const response = await getDesvioCriticidadeTipo(params);
    setDesvioCriticidadeList(response || []);
    setLoadingTable(false);
  };

  const fetchTypesDesvios = async () => {
    const res = await getDesviosTypes();

    const desviosTipos = res?.data?.map(item => ({
      id: item.id,
      value: item.nome,
    }));

    const index = filtersGraficoParams.findIndex(
      filter => filter.name === 'tipos_desvio',
    );
    const indexPrivider = filtersGraficoParamsProvider.findIndex(
      filter => filter.name === 'tipos_desvio',
    );

    if (index !== -1) {
      filtersGraficoParams[index].data = desviosTipos;
    }

    if (indexPrivider !== -1) {
      filtersGraficoParamsProvider[indexPrivider].data = desviosTipos;
    }
  };

  const settingFiliais = () => {
    const index = filtersGraficoParams.findIndex(
      filter => filter.name === 'filiais',
    );
    if (index !== -1) {
      filtersGraficoParams[index].data = filiais;
    }
  };

  const settingEmpresas = () => {
    const index = filtersGraficoParamsProvider.findIndex(
      filter => filter.name === 'empresas',
    );
    if (index !== -1) {
      filtersGraficoParamsProvider[index].data = empresas;
    }
  };

  const tabs = useMemo(() => {
    let filteredTabs = [];

    if (hasMonitoramento) {
      filteredTabs.push({ value: 0, label: 'Pontos/hora' });
    }
    if (hasTelemetria) {
      filteredTabs.push({ value: 1, label: 'Pontos/km' });
    }

    return filteredTabs;
  }, [hasMonitoramento, hasTelemetria]);

  const historyTabs = useMemo(() => {
    let filteredTabs = [];
    if (hasMonitoramento) {
      filteredTabs.push({ value: 'ptsHr', label: 'PONTOS/HORA' });
    }
    if (hasTelemetria) {
      filteredTabs.push({ value: 'ptsKm', label: 'PONTOS/KM' });
    }
    return filteredTabs;
  }, [hasMonitoramento, hasTelemetria]);

  // Gráfico de histórico de pontos da empresa
  const { data: _historyData, isLoading: isLoadingHistory } = useQuery(
    ['empresa-historico', historyDates],
    () => getHistorico({ ...historyDates }),
    {
      refetchOnWindowFocus: false,
    },
  );

  const fetchAll = () => {
    !isProvider && fetchClientes();
    settingFiliais();
    settingEmpresas();
    fetchTypesDesvios();
    fetchPadroesCriticidade();
  };

  useEffect(() => {
    if (!hasMonitoramento && hasTelemetria) {
      setCurrentTab(1);
    }

    setLoading(true);
    fetchAll();
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  useEffect(() => {
    setLoading(true);
    currentTab === 0 && fetchDesempenhoPtsH();
    currentTab === 1 && fetchDesempenhoPtsKm();
  }, [initialDate, currentSectionGraph, filtersPage, currentTab]);

  useEffect(() => {
    fetchDesvioCriticidade();
  }, [openIntervalDesvios, filtersPageCriticidade]);

  useEffect(() => {
    if (currentOptionSelect) fetchDesvioCriticidadeTipo();
  }, [openIntervalDesvios, currentOptionSelect]);

  useEffect(() => {
    if (_historyData?.success) {
      const _data =
        _historyData?.[isProvider ? 'global' : 'empresa']?.reduce(
          (acc, item) => {
            acc.push({
              name: item.period,
              x: historicoTab === 'ptsHr' ? item.pontos_horas : item.pontos_kms,
              meta:
                historicoTab === 'ptsHr'
                  ? _historyData.meta_pontos_hora ?? 5
                  : _historyData.meta_pontos_km ?? 10,
            });
            return acc;
          },
          [],
        ) ?? [];
      setHistoryData(_data);
    } else {
      setHistoryData([]);
    }
  }, [_historyData, historicoTab]);

  return (
    <Container>
      <div className="filter-container">
        <Tabs value={currentTab} items={tabs} onChange={handlePageTab} />
        <div className="filter-container">
          <div>
            <CustomizedSelect
              today={finalDate}
              selectedDate={initialDate}
              handleChangeDate={value => setInitialDate(value)}
            />
          </div>
          <StyledButton
            textcolor={theme.palette.words.text.natural}
            backgroundcolor="transparent"
            startIcon={<SvgIcon component={filterIco} />}
            height="45px"
            style={{ marginTop: '3px' }}
            onClick={event => setAnchorEl(event.currentTarget)}
            disabled={loading}
          >
            Filtros
          </StyledButton>
        </div>
      </div>
      <Graph
        currentTab={currentTab}
        data={
          currentTab === 0
            ? PontosH.sort((a, b) => b.qtde_atual - a.qtde_atual)
            : pontoskm.sort((a, b) => b.qtde_atual - a.qtde_atual)
        }
        metaValue={meta}
        tabsItems={
          currentTab === 0
            ? isProvider
              ? tabsItemsPtkmProvider
              : tabsItemsPtkm
            : isProvider
            ? tabsItemsPthsProvider
            : tabsItemsPth
        }
        isLoading={loading}
        currentSelection={currentSectionGraph}
        handleTabClick={(e, newValue) => setCurrentSectionGraaph(newValue)}
      />
      <ContainerExport>
        <GhostButton
          startIcon={<SaveAlt />}
          size="medium"
          width="8rem"
          onClick={() => {
            downloadFile();
          }}
        >
          EXPORTAR
        </GhostButton>
      </ContainerExport>

      <Divider />

      <div className="container-graph">
        <HistoryPoints
          data={historyData || []}
          tab={historicoTab}
          tabs={historyTabs}
          dates={historyDates}
          handleChangeTab={value => setHistoricoTab(value)}
          handleDate={dates => setHistoryDates(dates)}
        />
      </div>

      <Divider />

      <RadarDesvios filiais={filiais} empresas={empresas} clientes={clientes} />

      <Divider />

      <HeaderDesvios>
        <div className="container">
          <div className="headerText">
            <span className="main-header">Desvios por criticidade</span>
            <span className="sub-header">Total de pontos</span>
          </div>
          <div className="subContainer">
            <div>
              <DateRangePicker
                onDate={setOpenIntervalDesvios}
                dInt={openIntervalDesvios.initialDate}
                dfim={openIntervalDesvios.finalDate}
              />
            </div>
            <div style={{ marginLeft: '1rem' }}>
              <StyledButton
                textcolor={theme.palette.words.text.natural}
                backgroundcolor="transparent"
                startIcon={<SvgIcon component={filterIco} />}
                onClick={event =>
                  setOpenFilterCriticidadeDesvio(event.currentTarget)
                }
                disabled={loading}
              >
                Filtros
              </StyledButton>
            </div>
          </div>
        </div>
      </HeaderDesvios>
      <BarraPercentual
        value={currentOptionSelect}
        data={desvioCriticidade}
        selectOptions={optionsDesvio}
        handleSelect={val => setCurrentOptionsSelect(val)}
      />
      <DefaultTable
        data={desvioCriticidadeList}
        columns={columns(isProvider)}
        searchKeys={['nome']}
        loading={loadingTable}
        placeholder="Buscar"
        sortBy={{ id: 'status', order: 'ASC' }}
        searchEvent={search =>
          trackEvent(user, 'Busca desvio por criticidade', null, search)
        }
        empty={{
          title: 'Nenhum desvio encontrado',
          description: '',
          image: 'frota.png',
        }}
      />
      {/* filtro primeiro grafico */}
      <DrawerFilters
        open={anchorEl}
        handleClose={() => setAnchorEl(null)}
        onSelected={setFiltersPage}
        dataSelect={
          isProvider ? filtersGraficoParamsProvider : filtersGraficoParams
        }
        onClean={() => setFiltersPageCriticidade(null)}
      />

      {/* filtro Desvios por criticidade */}
      <DrawerFilters
        open={openFilterCriticidadeDesvio}
        handleClose={() => setOpenFilterCriticidadeDesvio(null)}
        onSelected={setFiltersPageCriticidade}
        dataSelect={
          isProvider
            ? filtersGraficoParamsProvider.filter(
                item => item.name !== 'tipos_desvio',
              )
            : filtersGraficoParams.filter(item => item.name !== 'tipos_desvio')
        }
        onClean={() => setFiltersPageCriticidade(null)}
      />
    </Container>
  );
};

export default DesempenhoGeral;
