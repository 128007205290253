/* eslint-disable import/order */

// Styles
import * as S from './styled';
import { useTheme } from 'styled-components';

// React
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';

// Components
import { CaePrint } from './ModelPdf';
import { ModalEvidencia } from './Evidence';
import EmptyCard from 'components/Cards/EmptyDataCard';

// MATERIAL UI
import { MoreHoriz } from '@mui/icons-material';
import { MenuItem, Popover } from '@mui/material';
import NoPhotographyOutlinedIcon from '@mui/icons-material/NoPhotographyOutlined';

// UTILS
import { formatNewDate } from 'utils/dates';

export const Cae = ({ posto, cliente, setPosto }) => {
  const theme = useTheme();
  const printRef = useRef();

  const cae = posto?.cae;
  const navigate = useNavigate();

  const [currentCae, setCurrentCae] = useState(null);

  const questions = posto?.respostas?.map(item => ({
    ...item?.questao,
  }));

  // Modal Control
  const [approveModal, setApproveModal] = useState(false);

  const [desvio, setDesvio] = useState(null);

  const setStatusColor = status => {
    switch (status) {
      case 'ABERTO':
        return theme.palette.semantics.feedback.attention.natural;
      case 'VENCIDA':
        return theme.palette.semantics.feedback.unknown.natural;
      case 'FINALIZADO':
        return theme.palette.semantics.feedback.success.natural;
    }
  };

  const setTypeColor = type => {
    switch (type) {
      case '4':
        return {
          color: theme.palette.semantics.feedback.information.natural,
          background: theme.palette.semantics.feedback.information.light,
        };
      case '3':
        return {
          color: theme.palette.semantics.feedback.warning.natural,
          background: theme.palette.semantics.feedback.warning.light,
        };
      case '2':
        return {
          color: theme.palette.semantics.feedback.attention.natural,
          background: theme.palette.semantics.feedback.attention.light,
        };
      case '1':
        return {
          color: theme.palette.semantics.feedback.attention.natural,
          background: theme.palette.semantics.feedback.attention.light,
        };
      default:
        return {};
    }
  };

  // ********************  ACTIONS *****************************
  // Modais
  const handleModalAprovar = infrac => {
    setApproveModal(true);
    setCurrentCae(infrac);
  };

  // Funções

  const handleOpenNewTab = infrac => {
    return window.open(`/cae/${infrac.id}`);
  };

  const handlePrint = infrac => {
    setCurrentCae(infrac);
    setTimeout(() => print(), 300);
  };

  const print = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `cae_${cae?.id}`,
    onAfterPrint: () => setCurrentCae(null),
  });

  // CONTROLE DE EXIBIÇÃO DAS AÇÕES
  const [anchor, setAnchor] = useState(null);
  const [actions, setActions] = useState([]);
  const allActions = [
    {
      name: 'abrir',
      action: handleOpenNewTab,
      text: 'Abrir em nova guia',
    },
    // {
    //   name: 'aprovar',
    //   action: handleModalAprovar,
    //   text: 'Aprovar',
    // },
    {
      name: 'imprimir',
      action: handlePrint,
      text: 'Imprimir',
    },
  ];

  const setActionsPopover = (e, caeEl) => {
    setAnchor(e.currentTarget);
    setDesvio(caeEl);
    let currentActions = [];
    switch (caeEl.status) {
      case 'ABERTO':
        currentActions = ['abrir', 'imprimir'];
        break;
      case 'VENCIDO':
        currentActions = ['abrir', 'imprimir'];
        break;
      case 'FINALIZADO':
        currentActions = ['abrir', 'imprimir'];
        break;
    }

    const ac = [];
    for (const i in allActions) {
      if (currentActions.includes(allActions[i].name)) ac.push(allActions[i]);
    }

    setActions(ac);
  };

  return (
    <div>
      {cae &&
        cae.map((c, idx) => (
          <S.DesvioBox key={idx} color={setStatusColor(c?.status)}>
            <div className="imgArea">
              {c?.imagem_cae && <img src={c?.imagem_cae} />}
              {!c?.imagem_cae && (
                <div className="default">
                  <NoPhotographyOutlinedIcon />
                  <p>Sem Imagem</p>
                </div>
              )}
            </div>
            <div className="contentArea">
              <div className="top">
                <span className="texts">
                  <span className="date">
                    {formatNewDate(c?.data_ocorrencia)}
                  </span>
                  <span className="id">#{c?.id}</span>
                </span>
                <span className="actionsSide">
                  <span onClick={() => navigate(`/cae/${c?.id}`)}>
                    Visualizar CAE
                  </span>
                  <MoreHoriz
                    fontSize="large"
                    onClick={e => setActionsPopover(e, c)}
                  />
                  <Popover
                    id={anchor && c?.id === desvio?.id ? 'popover' : undefined}
                    open={Boolean(anchor && c?.id === desvio?.id)}
                    anchorEl={anchor && c?.id === desvio?.id ? anchor : null}
                    onClose={() => setAnchor(null)}
                    onClick={() => setAnchor(null)}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                  >
                    {actions.map(action => {
                      return (
                        <MenuItem
                          key={action.name}
                          onClick={() => action.action && action.action(c)}
                        >
                          <S.ItemPopover>{action.text}</S.ItemPopover>
                        </MenuItem>
                      );
                    })}
                  </Popover>
                </span>
              </div>
              <div className="medium">
                <span className="dsTitle">{`Descrição: ${
                  questions?.find(q => c?.questao?.id === q?.id)?.abreviacao
                }`}</span>
                <span className="name">{posto?.nome?.toLowerCase()}</span>
              </div>
              <div className="bottom">
                <span className="status">
                  <span className="ball" />
                  <span className="text">{c?.status?.toLowerCase()}</span>
                </span>
                <span className="type">
                  Severidade{' '}
                  <span style={setTypeColor(c?.questao?.severidade)}>
                    {c?.questao?.severidade}
                  </span>
                </span>
              </div>
            </div>
          </S.DesvioBox>
        ))}
      {cae?.length <= 0 && (
        <EmptyCard
          image="frota.png"
          title="Nenhuma CAE para o layout"
          subtitle="Nada para exibir"
        />
      )}

      {/* Modal Area */}
      <ModalEvidencia
        open={approveModal}
        handleClose={() => {
          setCurrentCae(null);
          setApproveModal(false);
        }}
        cae={currentCae}
        cliente={cliente}
        posto={posto}
        setPosto={setPosto}
      />

      <CaePrint cae={currentCae} posto={posto} printRef={printRef} />
    </div>
  );
};
