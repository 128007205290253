import { SvgIcon } from '@mui/material';
import { useEffect, useState } from 'react';
import * as S from './styled';
import { ReactComponent as openCae } from '../../images/icons/tooltip/Grupo11754.svg';

// INSTANCIAR TOOLTIP DENTRO DO ELEMENTO QUE DESEJA PASSAR O MOUSE PARA ATIVAR

let timer;

export const TooltipCustom = ({
  text,
  svgName,
  svgColor = '#000',
  parentEl,
  left = '0',
  right = '0',
  top = '40',
  bottom = '0',
  arrow = 'top',
}) => {
  // Para incluir um novo SVG, importe e adicione ao array
  const availableSvg = [{ name: 'openCae', component: openCae }];

  const [visible, setVisible] = useState(false);
  const [currentSvg, setCurrentSvg] = useState(null);
  const [opacity, setOpacity] = useState(0);
  const [pos, setPos] = useState('top');

  // Add MouseEvent in parent element
  useEffect(() => {
    const parentNode = document.getElementById(parentEl);
    parentNode.addEventListener('mouseover', () => {
      clearTimeout(timer);
      setVisible(true);
      timer = setTimeout(() => {
        setOpacity(1);
      }, 50);
    });
    parentNode.addEventListener('mouseout', () => {
      clearTimeout(timer);
      setOpacity(0);
      timer = setTimeout(() => {
        setVisible(false);
      }, 50);
    });
    parentNode.display = 'flex';
  }, []);

  // Select SVG
  useEffect(() => {
    for (const i in availableSvg) {
      if (availableSvg[i].name === svgName) {
        setCurrentSvg(availableSvg[i].component);
      }
    }
    setPos(arrow);
  }, [text, svgName, svgColor, parentEl, left, right, top, bottom, arrow]);

  return (
    <S.Container
      className="tooltip--reserved--class"
      visible={visible}
      opacity={opacity}
      left={left}
      right={right}
      bottom={bottom}
      top={top}
      pos={pos}
    >
      <div className="box--controller">
        {svgName && (
          <SvgIcon
            component={currentSvg}
            htmlColor={svgColor}
            className="svg"
          />
        )}
        <span>{text}</span>
      </div>
    </S.Container>
  );
};
