import styled, { css } from 'styled-components';
import Button from '@mui/material/Button';

export const StyledButton = styled(Button)`
  ${({ theme, ...props }) => css`
    &.MuiButton-root {
      min-width: 120px;
      width: ${props.width || ''};
      cursor: ${props.loading === 'true' ? 'default' : 'pointer'};

      background: ${props.loading === 'true'
        ? theme.palette.brand.primary.background
        : theme.palette.system.transparent};
      border: 1px solid;
      border-color: ${props.customcolor || theme.palette.brand.primary.natural};
      border-radius: 4px;
      text-transform: uppercase;
      color: ${props.customcolor || theme.palette.brand.primary.natural};

      &:hover {
        background: ${props.customcolor
          ? `${props.customcolor}10`
          : theme.palette.brand.secondary.background};
        border: 1px solid;
        border-color: ${props.customcolor ||
        theme.palette.brand.secondary.light};
      }

      &:active {
        background: ${`${props.customcolor}1A` ||
        `${theme.palette.brand.secondary.natural}1A`};
        box-shadow: 0px 3px 15px
          ${props.customcolor || theme.palette.brand.secondary.natural};
      }
    }

    &.MuiButton-sizeSmall {
      height: 30px;
      font: normal normal 900 12px/24px Texta;
    }

    &.MuiButton-sizeMedium {
      height: 40px;
      font: normal normal 900 14px/18px Texta;
    }

    &.MuiButton-sizeLarge {
      height: 50px;
      font: normal normal 900 16px/24px Texta;
    }

    &&.Mui-disabled {
      background: ${theme.palette.system.disabled};
      color: ${theme.palette.words.disabled.natural};
    }
  `}
`;
