import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// Componentes
import LineGraph from 'components/Graphs/LineGraph';
import BarGraph from 'components/Graphs/BarGraph';
import Tab from 'components/Tabs';

// Service
import { toast } from 'react-toastify/dist';
import { Grid } from '@mui/material';
import { requestGraphDesvio } from '../services';
import * as S from './styled';

const radarTabs = [
  { value: 'criticidade', label: 'POR CRITICIDATE' },
  { value: 'status', label: 'POR STATUS' },
];

const tempoTabs = [
  { value: 'conclusao', label: 'TEMPO DE CONCLUSÃO' },
  { value: 'tratamento', label: 'TEMPO DE TRATAMENTO' },
];

const camerasTabs = [
  { value: 'posicao', label: 'POSICIONAMENTO CÂMERAS' },
  { value: 'dvr', label: 'CANAL DVR' },
];

const GraficosDesvioDirecao = () => {
  const filter = useSelector(state => {
    return state.filterProvider;
  });
  const filterDesvio = useSelector(state => {
    return state.filterEstatisticasDesviosProvider;
  });

  const [radar, setRadar] = useState(null);
  const [radarAxis, setRadarAxis] = useState(null);
  const [radarTab, setRadarTab] = useState('criticidade');
  const [radarLoading, setRadarLoading] = useState(true);

  const [infracoes, setInfracoes] = useState(null);
  const [infracoesAxis, setInfracoeseAxis] = useState(['month']);
  const [infracoesLoading, setInfracoesLoading] = useState(true);

  const [tempo, setTempo] = useState(null);
  const [tempoAxis, setTempoAxis] = useState(null);
  const [tempoTab, setTempoTab] = useState('conclusao');
  const [tempoLoading, setTempoLoading] = useState(true);

  const [revisao, setRevisao] = useState(null);
  const [revisaoAxis, setRevisaoAxis] = useState(['month']);
  const [revisaoLoading, setRevisaoLoading] = useState(true);

  const [response, setResponse] = useState(null);
  const [responseLoading, setResponseLoading] = useState(true);

  const [objetos, setObjetos] = useState(null);
  const [objetoLoading, setObjetosLoading] = useState(true);

  const [justificativas, setJustificativas] = useState(null);
  const [justificativasLoading, setJustificativasLoading] = useState(true);

  const [cameras, setCameras] = useState(null);
  const [camerasTab, setCamerasTab] = useState('posicao');
  const [camerasLoading, setCamerasLoading] = useState(true);

  const fetchRadar = async query => {
    setRadarLoading(true);
    const res = await requestGraphDesvio('1', query);
    if (res.data?.data) {
      setRadar(res.data.data.data);
      setRadarAxis(res.data.data.series);
    } else if (res.data?.message) toast.error(res.data.message);
    setRadarLoading(false);
  };

  const fetchInfracoes = async query => {
    setInfracoesLoading(true);
    const res = await requestGraphDesvio('2', query);
    if (res.data?.data) {
      setInfracoes(res.data.data.data);
      setInfracoeseAxis(res.data.data.series);
    } else if (res.data?.message) toast.error(res.data.message);
    setInfracoesLoading(false);
  };

  const fetchTempo = async query => {
    setTempoLoading(true);
    const res = await requestGraphDesvio('3', query);
    if (res.data?.data) {
      setTempo(res.data.data.data);
      setTempoAxis(res.data.data.series);
    } else if (res.data?.message) toast.error(res.data.message);
    setTempoLoading(false);
  };

  const fetchRevisao = async query => {
    setRevisaoLoading(true);
    const res = await requestGraphDesvio('4', query);
    if (res.data?.data) {
      setRevisao(res.data.data.data);
      setRevisaoAxis(res.data.data.series);
    } else if (res.data?.message) toast.error(res.data.message);
    setRevisaoLoading(false);
  };

  const fetchObjeto = async query => {
    setObjetosLoading(true);
    const res = await requestGraphDesvio('6', query);
    if (res.data?.data) setObjetos(res.data.data);
    else if (res.data?.message) toast.error(res.data.message);
    setObjetosLoading(false);
  };

  const fetchJustificativas = async query => {
    setJustificativasLoading(true);
    const res = await requestGraphDesvio('7', query);
    if (res.data?.data) setJustificativas(res.data.data);
    else if (res.data?.message) toast.error(res.data.message);
    setJustificativasLoading(false);
  };

  const fetchResponse = async query => {
    setResponseLoading(true);
    const res = await requestGraphDesvio('5', query);
    if (res.data?.data) setResponse(res.data.data);
    else if (res.data?.message) toast.error(res.data.message);
    setResponseLoading(false);
  };

  const fetchCameras = async query => {
    setCamerasLoading(true);
    const res = await requestGraphDesvio('8', query);
    if (res.data?.data) setCameras(res.data.data);
    else if (res.data?.message) toast.error(res.data.message);
    setCamerasLoading(false);
  };

  useEffect(() => {
    const query = { ...filter, tip_tab: radarTab, ...filterDesvio };
    fetchRadar(query);
  }, [filter, filterDesvio, radarTab]);

  useEffect(() => {
    fetchInfracoes({ ...filter, ...filterDesvio });
    fetchResponse({ ...filter, ...filterDesvio });
    fetchObjeto({ ...filter, ...filterDesvio });
    fetchJustificativas({ ...filter, ...filterDesvio });
    fetchRevisao({ ...filter, ...filterDesvio });
  }, [filter, filterDesvio]);

  useEffect(() => {
    const query = { ...filter, ...filterDesvio, tip_tab: tempoTab };
    fetchTempo(query);
  }, [filter, filterDesvio, tempoTab]);

  useEffect(() => {
    const query = { ...filter, ...filterDesvio, tip_tab: camerasTab };
    fetchCameras(query);
  }, [filter, filterDesvio, camerasTab]);

  const renderRadarGraph = () => (
    <div style={{ marginTop: '20px', position: 'relative' }}>
      <LineGraph
        data={radar}
        colors="critical"
        title="Histórico de desvios"
        series={radarAxis}
        legend={radarAxis}
        loading={radarLoading}
        tooltip
      />
      <div style={{ position: 'absolute', top: '15px', right: '25px' }}>
        <Tab
          width="290px"
          value={radarTab}
          items={radarTabs}
          onChange={(e, value) => setRadarTab(value)}
        />
      </div>
    </div>
  );

  const renderObjetosGraph = () => (
    <div style={{ marginTop: '20px' }}>
      <BarGraph
        data={objetos}
        colors="default"
        title="Radar Objetos"
        loading={objetoLoading}
        tooltip
      />
    </div>
  );

  const renderJustificativasGraph = () => (
    <div style={{ marginTop: '20px' }}>
      <BarGraph
        data={justificativas}
        colors="default"
        title="Resumo das Justificativas"
        loading={justificativasLoading}
        tooltip
      />
    </div>
  );

  const renderTopResponsabilidadesGraph = () => (
    <div style={{ marginTop: '20px' }}>
      <BarGraph
        data={response}
        colors="default"
        title="Top 10 Responsáveis"
        loading={responseLoading}
        tooltip
      />
    </div>
  );

  const renderInfracoesGraph = () => (
    <div style={{ marginTop: '20px' }}>
      <LineGraph
        data={infracoes}
        colors="critical"
        title="Histórico Zero Infrações"
        series={infracoesAxis}
        loading={infracoesLoading}
        tooltip
      />
    </div>
  );

  const renderTempoGraph = () => (
    <div style={{ marginTop: '20px', position: 'relative' }}>
      <LineGraph
        data={tempo}
        colors="critical"
        title="Histórico tempo médio (dias)"
        series={tempoAxis}
        loading={tempoLoading}
        tooltip
      />
      <div style={{ position: 'absolute', top: '15px', right: '25px' }}>
        <Tab
          width="330px"
          value={tempoTab}
          items={tempoTabs}
          onChange={(e, value) => setTempoTab(value)}
        />
      </div>
    </div>
  );

  const renderRevisaoGraph = () => (
    <div style={{ marginTop: '20px' }}>
      <LineGraph
        data={revisao}
        colors="critical"
        title="Histórico Revisões"
        series={revisaoAxis}
        legend={revisaoAxis}
        loading={revisaoLoading}
        tooltip
      />
    </div>
  );

  const renderCamerasGraph = () => (
    <div style={{ marginTop: '20px', position: 'relative' }}>
      <BarGraph
        data={cameras}
        colors="default"
        title="Controle das Câmeras"
        loading={camerasLoading}
        tooltip
      />
      <div style={{ position: 'absolute', top: '15px', right: '25px' }}>
        <Tab
          width="350px"
          value={camerasTab}
          items={camerasTabs}
          onChange={(e, value) => setCamerasTab(value)}
        />
      </div>
    </div>
  );

  const renderMain = () => (
    <>
      <S.Container>
        <S.Header>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '15px',
            }}
          >
            <S.Title>Dashboard Desvios</S.Title>
          </div>
        </S.Header>
        <S.Main>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} xl={6}>
              {renderRadarGraph()}
            </Grid>

            <Grid item xs={12} md={6} xl={6}>
              {renderInfracoesGraph()}
            </Grid>

            <Grid item xs={15} md={6} xl={6}>
              {renderTempoGraph()}
            </Grid>

            <Grid item xs={15} md={6} xl={6}>
              {renderRevisaoGraph()}
            </Grid>

            <Grid item xs={12} md={6} xl={6}>
              {renderTopResponsabilidadesGraph()}
            </Grid>

            <Grid item xs={15} md={6} xl={6}>
              {renderObjetosGraph()}
            </Grid>

            <Grid item xs={12} md={6} xl={6}>
              {renderJustificativasGraph()}
            </Grid>

            <Grid item xs={12} md={6} xl={6}>
              {renderCamerasGraph()}
            </Grid>
          </Grid>
        </S.Main>
      </S.Container>
    </>
  );

  return renderMain();
};

export default GraficosDesvioDirecao;
