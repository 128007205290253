/* eslint-disable import/order */

import * as S from './styled';
import colors from 'styles/colors';

import { Radio } from '@mui/material';

import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

export const EvaluationView = ({ item, noBorder, viewStyle, number }) => {
  return (
    <S.Container noBorder={noBorder}>
      <S.Question>
        {number ? `${number})` : ''} {item?.pergunta}
      </S.Question>
      <S.ResponsesBox>
        {item?.alternativas?.map((alt, index) => (
          <S.Response
            key={index}
            className={viewStyle ? 'view' : null}
            correct={alt?.correta}
            isChecked={item?.resposta?.id_alternativa === alt?.id}
            isWrong={item.resposta?.id_alternativa === alt?.id && !alt?.correta}
          >
            <Radio
              size="small"
              disabled={item?.resposta?.id_alternativa !== alt?.id}
              checked={item?.resposta?.id_alternativa === alt?.id}
            />
            <div className="alt">{alt?.alternativa}</div>
            {item?.resposta?.id_alternativa === alt?.id && (
              <S.ModeBox>
                <div
                  className={alt?.correta ? 'iconCircle correct' : 'iconCircle'}
                >
                  {alt?.correta && (
                    <CheckOutlinedIcon
                      className="icon"
                      htmlColor={colors.greenSucces}
                    />
                  )}
                  {!alt?.correta && (
                    <ClearOutlinedIcon
                      className="icon"
                      htmlColor={colors.redDanger}
                    />
                  )}
                </div>
                <div className="definedText">
                  {alt?.correta && (
                    <div className="text correct">Resposta Correta</div>
                  )}
                  {!alt?.correta && (
                    <div className="text">Resposta Incorreta</div>
                  )}
                </div>
              </S.ModeBox>
            )}
          </S.Response>
        ))}
      </S.ResponsesBox>
    </S.Container>
  );
};
