import styled, { css } from 'styled-components';

export const Main = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  h1 {
    margin-left: 30px;
  }
`;

export const ColumnWrapper = styled.div`
  ${({ theme }) => css`
    background: ${theme.palette.system.overlay};
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 25px;
    margin-top: 25px;
  `}
`;

export const ImageContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;

    .content {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      p {
        font-size: 18px;
        color: ${theme.palette.words.subtitle.natural};
        font-weight: 600;
      }
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 5px;
      object-fit: contain;
    }
  `}
`;
