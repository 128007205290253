import styled, { css } from 'styled-components';

export const QuestionBox = styled.div`
  ${({ theme, hasError }) => css`
    padding: 20px;
    background-color: ${hasError
      ? theme.palette.semantics.feedback.attention.light
      : theme.palette.system.highlight};
  `}
`;

export const ResponseArea = styled.div`
  ${({ theme, ...props }) => css`
    margin-top: 18px;
    display: flex;
    align-items: center;
    width: 100%;
    background-color: ${props.hasError
      ? theme.palette.semantics.feedback.attention.light
      : theme.palette.system.transparent};

    .checkarea {
      flex: 2;
      width: 100%;
    }

    .blockArea {
      flex: 5;

      display: ${props.preview ? 'none' : 'block'}; //somente mobile
    }

    .iconsBox {
      margin-left: 50px;
      opacity: ${props.isChecked ? 1 : 0};
      transition: all ease-in 0.3s;
      pointer-events: ${props.isChecked ? 'all' : 'none'};
    }
  `}
`;
