import React, { useRef, useEffect } from 'react';
import { useTheme } from 'styled-components';
import { formatNewDate } from 'utils/dates';

import { Grid } from '@mui/material';

import TextInput from 'components/Inputs/TextField';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import { usePlans } from 'hooks/usePlans';
import * as S from '../styled';
import { getStatusColor } from '../constants';

import empryImage from 'images/empty/imageempty.png';

const Info = ({ data }) => {
  const scrollRef = useRef(null);
  const { hasTorre, hasTorrePlus } = usePlans();
  const theme = useTheme();
  const hasImage = null;

  const scrollToEnd = () => {
    scrollRef.current?.scrollTo({
      left: scrollRef.current.scrollWidth,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    scrollToEnd();
  }, []);

  return (
    <S.ColumnWrapper>
      <Grid container columnSpacing={2} rowSpacing={1}>
        <Grid item xs={12} sm={12}>
          <S.DriverContainer>
            <div className="info">
              <S.StyledLogoWrapper>
                <S.StyledLogo backgroundImage={hasImage}>
                  {data?.motorista?.nome.substring(0, 1).toUpperCase() || ''}
                </S.StyledLogo>
              </S.StyledLogoWrapper>

              <S.StyledTextWrapper>
                <h1>{data?.motorista?.nome || ''}</h1>
                <p>{data?.motorista?.filial?.nome || '-'}</p>
              </S.StyledTextWrapper>
            </div>
          </S.DriverContainer>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextInput
            id="data_desvio"
            value={formatNewDate(data?.createdAt) || '-'}
            label="Data do desvio"
            inputProps={{ readOnly: true }}
            variant="filled"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInput
            id="obrigatorio"
            label="Obrigatório"
            value={data?.configuracao_automacao?.obrigatorio ? 'Sim' : 'Não'}
            inputProps={{ readOnly: true }}
            variant="filled"
          />
        </Grid>
        <>
          <Grid item xs={12} sm={6} md={8}>
            <TextInput
              id="capacitacao"
              label="Capacitação"
              value={data?.conteudo?.titulo || '-'}
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextInput
              id="status"
              label="Status"
              inputProps={{ readOnly: true }}
              variant="filled"
              value={data?.status || '-'}
              fillcolor={getStatusColor(data?.status, theme)}
            />
          </Grid>
        </>

        <Grid item xs={12} sm={12} md={12}>
          <TextInput
            id="certificado"
            label="Certificado"
            value={
              (data?.capacitacao_certificados &&
                data?.capacitacao_certificados[0]?.link) ||
              '-'
            }
            inputProps={{ readOnly: true }}
            variant="filled"
          />
        </Grid>

        {(hasTorre || hasTorrePlus) && (
          <Grid item xs={12} sm={12}>
            <TextInput
              id="configurado"
              label="Configurado por"
              value={data?.configuracao_automacao?.empresa?.nome || '-'}
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>
        )}

        <Grid item xs={12} sm={8}>
          <TextInput
            id="desvio_cometido"
            label="Desvio cometido"
            inputProps={{ readOnly: true }}
            variant="filled"
            value={data?.desvio_tipo_padrao?.titulo || '-'}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextInput
            id="recorrencia"
            label="Recorrência"
            value={data?.desvio_tipo_padrao?.conteudos_usuarios.length || '0'}
            inputProps={{ readOnly: true }}
            variant="filled"
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <div
            ref={scrollRef}
            style={{
              display: 'flex',
              flexDirection: 'row',
              overflowX: 'scroll',
            }}
          >
            {data?.desvio_tipo_padrao?.conteudos_usuarios?.map((item, k) => (
              <S.Card>
                <div
                  key={k}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-end',
                  }}
                >
                  <S.ImageContainer>
                    <S.Image
                      src={item?.conteudo?.imagem_capa || empryImage}
                      alt=""
                      style={{ opacity: 0.4 }}
                    />
                    <S.TextOverlay>{item?.status}</S.TextOverlay>
                  </S.ImageContainer>
                  <KeyboardArrowRightIcon
                    sx={{ color: '#4B5166' }}
                    fontSize="large"
                  />
                </div>
                {item.conteudo.tags.map((item, k) => (
                  <div className="tags" key={k}>
                    {item.tag}
                  </div>
                ))}
                <div className="title">{item?.conteudo?.titulo}</div>
              </S.Card>
            ))}

            <S.Card>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-end',
                }}
              >
                <S.ImageContainer>
                  <S.Image
                    src={data?.conteudo?.imagem_capa || empryImage}
                    alt=""
                  />
                </S.ImageContainer>
                <KeyboardArrowRightIcon
                  sx={{ color: '#4B5166' }}
                  fontSize="large"
                />
              </div>
              {data?.conteudo.tags &&
                data?.conteudo.tags.slice(0, 2).map((item, k) => (
                  <div className="tags" key={k}>
                    {item.tag}
                  </div>
                ))}
              <div className="title">{data?.conteudo?.titulo}</div>
            </S.Card>
          </div>
        </Grid>
      </Grid>
    </S.ColumnWrapper>
  );
};

export default Info;
