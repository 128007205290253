import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme, width }) => css`
    width: 100%;
    background-color: #fff;
    min-height: ${width};
    max-height: 100%;
    border: 2px solid ${theme.palette.system.border};
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;

export const ImageArea = styled.div``;

export const ContentArea = styled.div`
  ${({ theme }) => css`
    margin-left: 20px;

    .textArea {
      h3 {
        font-size: 28px;
        color: ${theme.palette.words.title.natural};
        font-weight: bold;
      }

      h5 {
        font-size: 18px;
        margin-top: 13px;
        font-weight: 500;
        color: ${theme.palette.words.subtitle.natural};
        margin-bottom: 13px;
      }
    }
  `}
`;
