import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import ReactPlayer from 'react-player';

import { Divider, Grid } from '@mui/material';
import {
  SaveAlt,
  FindInPageOutlined,
  Check,
  RouteOutlined,
  CalendarTodayOutlined,
  WarningAmber,
  PlayCircle,
  Close,
  OpenInFull,
} from '@mui/icons-material';
import { toast } from 'react-toastify';

import { useReactToPrint } from 'react-to-print';
import SelectCliente from 'components/Inputs/SelectCliente';
import TextInput from 'components/Inputs/TextField';
import SelectInput from 'components/Inputs/Select';
import Calendar from 'components/Inputs/Calendar';
import GhostButton from 'components/Buttons/Ghost';
import DefaultButton from 'components/Buttons/Default';
import ConfirmModal from 'components/ConfirmModal';
import InputAttachedFile from 'components/Inputs/InputAttachedFile2';
import Loading from 'components/Loading';
import ImageModal from 'components/ImageModal';
import { formatNewDate, formatNewHour } from 'utils/dates';
import { useTheme } from 'styled-components';
import { ReactComponent as HistoricoIcon } from 'images/icons/sidebar/historico.svg';
import { trackEvent } from 'utils/mixpanel';
import ImageViolacao from '../ImageViolacao';

import DeleteModal from '../DeleteModal';

import { getStatusColor, howToColor } from '../constants';
import * as S from './styled';

import * as request from '../services';

const DetalheViolacao = () => {
  const theme = useTheme();
  // Parametro da página violacao/:id
  const { id } = useParams();
  const navigate = useNavigate();

  // Dados do redux
  const user = useSelector(state => {
    return state.auth?.user?.user;
  });
  const userLevel = user.nivel;
  const userFilials = user.usuario_filiais;
  const { users, clients } = useSelector(state => {
    return state.selects;
  });

  // Violação que pode ser manipulado e violacao original (que é lido)
  const [violation, setViolation] = useState(null);
  const [originalViolation, setOriginalViolation] = useState(null);
  const [violacaoDeleteModal, setViolacaoDeleteModal] = useState(null);

  // Controla modal de confirmação das ações:
  // SALVAR, FINALIZAR E ENVIAR PARA PAD
  const [openModal, setOpenModal] = useState(null);
  const [loadingModal, setLoadingModal] = useState(false);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [printMode, setPrintMode] = useState(false);

  const componentRef = useRef();

  // Lista de responsáveis
  const responsibles = useMemo(
    () =>
      users
        .filter(i => {
          if (userLevel === 1) return true;
          if (userFilials.includes(i.id_da_filial)) return true;
          return false;
        })
        .map(usr => ({ name: usr.nome, value: usr.id })),
    [users, userLevel],
  );

  const fetchViolation = async () => {
    const res = await request.fetchViolation(id);
    if (res.data?.data) {
      const desv = res.data.data;
      setViolation(desv);
      setOriginalViolation(desv);
    } else if (res.data?.message) toast.error(res.data.message);
  };

  useEffect(() => {
    fetchViolation();
  }, []);

  useEffect(() => {
    if (printMode) handlePrint();
  }, [printMode]);

  const handleChange = (key, value) => {
    setViolation(prev => {
      return { ...prev, [key]: value };
    });
  };

  // Chamada ao trocar distribuidora
  // Abre modal de confirmação de troca
  // value passa o novo id_da_distribuidora
  const handleChangeDistribuidora = value => {
    const objOpen = {
      value,
      event: 'ALTERAR_DISTRIBUIDORA',
      titleIcon: (
        <Check
          sx={{ color: theme.palette.brand.secondary.natural }}
          fontSize="medium"
        />
      ),
      buttonText: 'CONFIRMAR',
      title: 'Tem certeza que deseja alterar o cliente da violação?',
      subtitle: 'A alteração será aplicada após SALVAR.',
    };
    setOpenModal(objOpen);
  };

  // Controla modal de confirmação das ações:
  // SALVAR, FINALIZAR E ENVIAR PARA PAD
  const handleOpenModal = event => {
    let objOpen = {
      event,
      titleIcon: (
        <Check
          sx={{ color: theme.palette.brand.secondary.natural }}
          fontSize="medium"
        />
      ),
      buttonText: 'CONFIRMAR',
    };

    switch (event) {
      case 'SALVAR':
        let hasChange = false;
        for (const key in violation) {
          // Um dos 2 nao é nulo ou "" e são diferentes
          if (
            (violation[key] || originalViolation[key]) &&
            violation[key] !== originalViolation[key]
          ) {
            if (
              (typeof violation[key] === 'string' && violation[key].trim()) ||
              typeof violation[key] !== 'string'
            )
              hasChange = true;
          }
        }
        if (!hasChange) {
          toast.warning('Nenhuma alteração foi aplicada!');
          return;
        }
        objOpen = {
          ...objOpen,
          title: 'Tem certeza que deseja salvar violação?',
          subtitle: 'Revise todos os campos alterados!',
        };
        break;

      case 'FINALIZAR':
        if (
          violation?.motorista?.id_da_distribuidora !==
          originalViolation?.motorista?.id_da_distribuidora
        ) {
          toast.warning('Utilize o botão SALVAR para alterar o cliente!');
          const element = document.getElementById('btnSalvar');
          element?.focus();
          return;
        }

        objOpen = {
          ...objOpen,
          title: 'Tem certeza que deseja finalizar violação?',
          subtitle: 'A violação será movida para FINALIZADO',
        };
        break;

      case 'PAD':
        for (const key in violation) {
          // Um dos 2 nao é nulo ou "" e são diferentes
          if (
            (violation[key] || originalViolation[key]) &&
            violation[key] !== originalViolation[key]
          ) {
            toast.warning('Salve as alterações antes de enviar para o PAD!');
            return;
          }
        }
        objOpen = {
          ...objOpen,
          title: 'Tem certeza que deseja enviar violação para o PAD?',
          subtitle: 'A violação será enviada como ocorrência para o PAD',
        };
        break;
    }
    if (objOpen.title) setOpenModal(objOpen);
  };

  // Ações da confirmação do modal:
  // Mesmas das de cima + ALTERAR_DISTRIBUIDORA
  const handleConfirmModal = () => {
    const { event } = openModal;
    switch (event) {
      case 'SALVAR':
        handleSalvar();
        break;

      case 'FINALIZAR':
        handleFinalizar();
        break;

      case 'PAD':
        handleSendPad();
        break;

      case 'ALTERAR_DISTRIBUIDORA':
        handleChange('id_da_distribuidora', openModal.value);
        setOpenModal(null);
        break;
    }
  };

  // ---------------  AÇÕES --------------------------------
  const handleSalvar = async () => {
    setLoadingModal(true);
    // Monta obj com parametros editaveis apenas
    const data = {
      ids: [violation.id],
      id_do_usuario: violation.id_do_usuario,
      plano_de_acao: violation.plano_de_acao,
      data_previsao: violation.data_previsao,
      arquivo_justificativa: violation.arquivo_justificativa,
      observacao: violation.observacao,
      // objeto: violation.objeto,
      id_da_distribuidora: violation.id_da_distribuidora,
    };
    const res = await request.saveViolations(data);
    if (res.data?.success) {
      toast.success(res.data?.message);
      fetchViolation();
    } else if (res.data?.message) toast.error(res.data.message);
    setLoadingModal(false);
    setOpenModal(null);
  };

  const handleFinalizar = async () => {
    setLoadingModal(true);
    // Monta obj com parametros editaveis apenas
    const data = {
      ids: [violation.id],
      id_do_usuario: violation.id_do_usuario,
      plano_de_acao: violation.plano_de_acao,
      data_previsao: violation.data_previsao,
      arquivo_justificativa: violation.arquivo_justificativa,
      observacao: violation.observacao,
      // objeto: violation.objeto,
    };
    const res = await request.finishViolations(data);
    if (res.data?.success) {
      toast.success(res.data?.message);
      fetchViolation();
    } else if (res.data?.message) toast.error(res.data.message);
    setLoadingModal(false);
    setOpenModal(null);
  };

  const handleDeletar = async data => {
    setLoadingModal(true);
    const res = await request.deleteViolations(data);
    if (res.data?.success) {
      toast.success(res.data?.message);
      fetchViolation();
    } else if (res.data?.message) toast.error(res.data.message);
    setLoadingModal(false);
    setViolacaoDeleteModal(null);
  };

  const handleSendPad = async () => {
    setLoadingModal(true);
    const data = {
      ids: [violation.id],
    };
    const res = await request.sendPadViolations(data);
    if (res.data?.success) {
      toast.success(res.data?.message);
      fetchViolation();
    } else if (res.data?.message) toast.error(res.data.message);
    setLoadingModal(false);
    setOpenModal(null);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: () => setLoadingPdf(true),
    onAfterPrint: () => {
      setPrintMode(false);
      setLoadingPdf(false);
    },
    documentTitle: `violacao${violation?.id}`,
    pageStyle: `
    @page {
      size: auto;
      margin: 15mm;
    }
  `,
  });

  //  ------------------   RENDERIZACAO --------------------------------
  const renderHeader = () => {
    return (
      <S.SpacedRow>
        <S.Status color={getStatusColor(violation.status, theme)}>
          <p>{violation.status.toLowerCase()}</p>
        </S.Status>

        <S.SpacedRow>
          <GhostButton
            startIcon={<HistoricoIcon />}
            size="medium"
            onClick={() => navigate(`/logs/violacao/${id}`)}
            sx={{ marginLeft: '20px' }}
          >
            HISTÓRICO DE ALTERAÇÕES
          </GhostButton>

          <GhostButton
            startIcon={<SaveAlt />}
            size="medium"
            onClick={e => setPrintMode(true)}
            style={{ marginLeft: '10px' }}
            loading={loadingPdf}
          >
            IMPRIMIR
          </GhostButton>
        </S.SpacedRow>
      </S.SpacedRow>
    );
  };

  // Lado Esquerdo do detalhe da violação
  // Não editável
  const renderTravelInfo = () => {
    const hasImage = violation.motorista.foto
      ? violation.motorista.foto
      : false;
    return (
      <S.ColumnWrapper>
        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={12} display="flex" justifyContent="center">
            <ImageViolacao infraction={violation} onlyImage={printMode} />
          </Grid>

          <Grid item xs={12} sm={12} display="flex" flexDirection="row">
            <S.StyledLogoWrapper>
              <S.StyledLogo backgroundImage={hasImage}>
                {!hasImage && <h2>{violation.motorista.nome[0]}</h2>}
              </S.StyledLogo>
            </S.StyledLogoWrapper>

            <S.StyledTextWrapper>
              <h1>{violation.motorista.nome.toLowerCase()}</h1>
              <p>Filial: {violation.motorista.filial?.nome}</p>
            </S.StyledTextWrapper>
          </Grid>

          <Divider style={{ margin: '10px 0px 10px 10px', width: '100%' }} />

          <Grid item xs={8} sm={8}>
            <TextInput
              id="Violação"
              label="Violação"
              value={violation.tipo.descricao}
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={4} sm={4}>
            <TextInput
              id="criticidade"
              label="Criticidade"
              value={violation.tipo.criticidade}
              InputProps={{ readOnly: true }}
              variant="filled"
              startIcon={
                <WarningAmber
                  sx={{ color: howToColor(violation.tipo.criticidade, theme) }}
                />
              }
              fillcolor={howToColor(violation.tipo.criticidade, theme)}
            />
          </Grid>

          <Grid item xs={6} sm={6}>
            <TextInput
              id="reportadoem"
              label="Reportado em"
              value={formatNewDate(violation.createdAt)}
              inputProps={{ readOnly: true }}
              startIcon={
                <CalendarTodayOutlined
                  sx={{ color: theme.palette.brand.primary.light }}
                />
              }
              variant="filled"
            />
          </Grid>

          <Grid item xs={6} sm={6}>
            <TextInput
              id="dataviolacao"
              label="Data da Violação"
              value={formatNewHour(violation.data_violacao)}
              startIcon={
                <CalendarTodayOutlined
                  sx={{ color: theme.palette.brand.primary.light }}
                />
              }
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>

          {violation.status === 'DELETADO' && (
            <Grid item xs={12} sm={12}>
              <TextInput
                id="motivoexclusao"
                label="Motivo da exclusão"
                value={violation.motivo_exclusao}
                inputProps={{ readOnly: true }}
                variant="filled"
              />
            </Grid>
          )}

          {violation.status === 'DELETADO' && violation.observacao_exclusao && (
            <Grid item xs={12} sm={12}>
              <TextInput
                id="observacaoexclusao"
                label="Observação da exclusão"
                value={violation.observacao_exclusao}
                inputProps={{ readOnly: true }}
                variant="filled"
              />
            </Grid>
          )}
        </Grid>
      </S.ColumnWrapper>
    );
  };

  // Lado direito detalhe do violacao
  // Dados editáveis
  const renderViolationInfo = () => {
    return (
      <S.ColumnWrapper>
        <Grid container columnSpacing={2} rowSpacing={1}>
          <Grid item xs={12} sm={12}>
            <SelectCliente
              value={violation?.motorista?.id_da_distribuidora}
              setValue={value => handleChangeDistribuidora(value)}
              distributors={clients}
            />
          </Grid>

          <Grid item xs={7} sm={7}>
            <SelectInput
              id="responsavel"
              label="Atribuir Responsável"
              value={violation.id_do_usuario}
              onChange={e => handleChange('id_do_usuario', e.target.value)}
              placeholder="Atribuir por Responsável"
              data={responsibles}
              required
            />
          </Grid>

          <Grid item xs={5} sm={5}>
            <Calendar
              id="data_previsao"
              value={violation.data_previsao}
              onChange={newDate => handleChange('data_previsao', newDate)}
              futureDate
              pastDate={false}
              label="Previsão de Conclusão"
              required
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextInput
              id="plano_de_acao"
              placeholder="Definir Plano de Ação"
              label="Plano de Ação"
              onChange={e => handleChange('plano_de_acao', e.target.value)}
              value={violation.plano_de_acao}
              multiline
              rows={2}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <InputAttachedFile
              idInput="input_file_detalhe_violacao"
              required
              label="Arquivo Justificativa"
              inputLabel="Anexar Evidência"
              fileUrl={violation.arquivo_justificativa}
              setFileUrl={value => handleChange('arquivo_justificativa', value)}
              fileDir={`/violacoes/${id}`}
              // deleteFromStorage nao apagar arquivo antigo
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextInput
              label="Observações"
              placeholder="Inserir observações"
              onChange={e => handleChange('observacao', e.target.value)}
              multiline
              rows={4}
              value={violation.observacao}
            />
          </Grid>

          {!printMode && (
            <Grid item xs={12} sm={12} display="flex" justifyContent="flex-end">
              {violation.status === 'PENDENTE' && userLevel < 3 && (
                <GhostButton
                  size="medium"
                  customcolor={
                    theme.palette.semantics.feedback.attention.natural
                  }
                  onClick={() => {
                    trackEvent(user, 'EXCLUIR VIOLAÇÃO');
                    setViolacaoDeleteModal([id]);
                  }}
                  sx={{ marginLeft: '20px' }}
                >
                  EXCLUIR
                </GhostButton>
              )}

              {(violation.status === 'PENDENTE' ||
                violation.status === 'FINALIZADO') && (
                <GhostButton
                  id="btnSalvar"
                  size="medium"
                  onClick={() => handleOpenModal('SALVAR')}
                  style={{ marginLeft: '10px' }}
                >
                  SALVAR
                </GhostButton>
              )}

              {violation.status === 'PENDENTE' && (
                <DefaultButton
                  size="medium"
                  onClick={() => {
                    trackEvent(user, 'FINALIZAR VIOLAÇÃO');
                    handleOpenModal('FINALIZAR');
                  }}
                  style={{ marginLeft: '10px' }}
                >
                  FINALIZAR
                </DefaultButton>
              )}

              {violation.status === 'FINALIZADO' && (
                <DefaultButton
                  size="medium"
                  onClick={() => {
                    trackEvent(user, 'ENVIAR PARA PAD');
                    handleOpenModal('PAD');
                  }}
                  style={{ marginLeft: '10px' }}
                  disabled={!user.filial?.empresa?.pad}
                >
                  ENVIAR PARA PAD
                </DefaultButton>
              )}
            </Grid>
          )}
        </Grid>
      </S.ColumnWrapper>
    );
  };

  return violation && originalViolation ? (
    <>
      <S.Main ref={componentRef}>
        {renderHeader()}
        <Grid container spacing="20px" marginTop="10px">
          <Grid item xs={12} sm={12} md={printMode ? 12 : 6}>
            {renderTravelInfo()}
          </Grid>

          <Grid item xs={12} sm={12} md={printMode ? 12 : 6}>
            {renderViolationInfo()}
          </Grid>
        </Grid>
      </S.Main>

      {
        // Este modal controla todas as confirmações
        // openModal.event define a acao em handleConfirmModal
        openModal && (
          <ConfirmModal
            handleClose={() => setOpenModal(null)}
            open={Boolean(openModal)}
            title={openModal.title}
            subtitle={openModal.subtitle}
            titleIcon={openModal.titleIcon}
            buttonText={openModal.buttonText}
            onClick={handleConfirmModal}
            loading={loadingModal}
          />
        )
      }

      {violacaoDeleteModal && (
        <DeleteModal
          handleConfirm={handleDeletar}
          ids={violacaoDeleteModal}
          handleClose={() => setViolacaoDeleteModal(null)}
          loading={loadingModal}
        />
      )}
    </>
  ) : (
    <Loading />
  );
};

export default DetalheViolacao;
