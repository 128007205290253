import {
  getResponsaveis,
  getCargas,
  getCriticidade,
  getTiposDesvio,
  getDesviosTiposPadrao,
  getVeiculos,
  getTecnologias,
} from 'constants/_SERVICES/user';

// Pages
import Desvios from 'pages/Desvio';
import Desvio from 'pages/Desvio/Detalhe';
import DesempenhoGeral from 'pages/Desvios/Estatisticas/DesempenhoGeral';
import LogDesvio from 'pages/Desvio/Historico';

import Historico from 'pages/Historico';

import DesviosUnificados from 'pages/Desvios';
import DetalheDesvio from 'pages/Desvios/Detalhe';

import DesviosConfiguracoes from 'pages/DesviosConfiguracoes';
import EditConfig from 'pages/DesviosConfiguracoes/Detalhe';
import TratativasDesvios from 'pages/Desvios/Estatisticas/TratativasDesvios';
import Deslocamentos from 'pages/Desvios/Deslocamentos';
import { DetalheDeslocamento } from 'pages/Desvios/Deslocamentos/Detalhe';

export default {
  '/desvio': {
    date: true,
    defaults: ['filials', 'operations', 'clients'],
    pageFilterName: 'filterDesvio',
    filters: [
      {
        name: 'tipo',
        placeholder: 'Filtrar por Tipo',
        section: 'Desvio',
        mode: 'multiple',
        data: async () => getTiposDesvio(),
      },
      {
        name: 'responsavel',
        placeholder: 'Filtrar por Responsável',
        section: 'Desvio',
        mode: 'multiple',
        data: async () => getResponsaveis(),
      },
      {
        name: 'criticidade',
        placeholder: 'Filtrar por Criticidade',
        section: 'Desvio',
        mode: 'multiple',
        data: async () => getCriticidade(),
      },
      {
        name: 'carga',
        placeholder: 'Filtrar por Carga',
        section: 'Desvio',
        mode: 'multiple',
        data: async () => getCargas(),
      },
    ],
    page: <Desvios />,
  },

  '/desvio/:id': {
    back: -1,
    title: 'Desvio',
    id: true,
    status: true,
    defaults: [],
    page: <Desvio />,
  },

  '/logs/desvio/:id': {
    back: -1,
    title: 'Histórico do Desvio',
    id: true,
    defaults: [],
    page: <LogDesvio />,
  },

  '/desvios/configuracoes': {
    back: -1,
    defaults: ['clients'],
    pageFilterName: 'filterDesviosConfiguracoes',
    filters: [
      {
        name: 'desvio',
        placeholder: 'Filtrar por Desvio',
        mode: 'single',
        data: async () => getDesviosTiposPadrao(),
      },
      {
        name: 'veiculo',
        placeholder: 'Filtrar por Tipo de Veículo',
        mode: 'single',
        data: async () => getVeiculos(true),
      },
    ],
    page: <DesviosConfiguracoes />,
  },

  '/logs/desvios/configuracoes/:id': {
    back: -1,
    title: 'Histórico da Configuração de Desvio',
    id: true,
    defaults: [],
    page: (
      <Historico
        back={-1}
        url="/desvios/configuracoes/logs/:id"
        formatReturn={data =>
          data.map(log => ({
            ...log,
            data: log.data ?? log.createdAt,
            evento: log.evento?.evento ?? '',
          }))
        }
      />
    ),
  },

  '/desvios/configuracoes/criar': {
    back: -1,
    title: 'Adicionar Grupo',
    defaults: [],
    page: <EditConfig novo />,
  },
  '/desvios/configuracoes/:id': {
    back: -1,
    title: 'Editar Grupo',
    defaults: [],
    page: <EditConfig />,
  },

  '/desvios/desempenho-geral': {
    back: -1,
    title: 'Desempenho Geral',
    defaults: [],
    page: <DesempenhoGeral />,
  },

  '/desvios/deslocamentos': {
    back: -1,
    date: false,
    title: 'Deslocamentos',
    defaults: [],
    page: <Deslocamentos />,
  },

  '/desvios/deslocamentos/:id': {
    back: -1,
    title: 'Deslocamento',
    subTitle: 'Deslocamentos',
    id: true,
    page: <DetalheDeslocamento />,
  },

  '/desvios': {
    date: true,
    defaults: ['clients', 'filials', 'filials_veiculo'],
    pageFilterName: 'filterDesvios',
    filters: [
      {
        name: 'tecnologia',
        key: 'tecnologia',
        placeholder: 'Filtrar por Tecnologia',
        mode: 'multiple',
        data: async () => getTecnologias(),
      },
      {
        name: 'desvio',
        placeholder: 'Filtrar por Desvio',
        mode: 'multiple',
        key: 'padroes',
        data: async () => getDesviosTiposPadrao(),
        dependency: [
          {
            type: 'user',
            name: 'tecnologia',
            clear: true,
            visible: false,
            data: (val, keyData, originalData) => {
              const selectedIds = keyData
                .filter(item => val.includes(`${item.value}`))
                .reduce((acc, item) => [...acc, ...item.ids_tipos], []);
              return originalData.filter(item =>
                selectedIds.includes(item.value),
              );
            },
          },
        ],
      },
      {
        name: 'criticidade',
        placeholder: 'Filtrar por Criticidade',
        mode: 'multiple',
        data: async () => getCriticidade(),
      },
      {
        name: 'responsavel',
        placeholder: 'Filtrar por Responsável',
        mode: 'multiple',
        data: async () => getResponsaveis(),
      },
    ],
    page: <DesviosUnificados />,
  },

  '/desvios/:id': {
    back: -1,
    title: 'Desvio',
    id: true,
    status: true,
    defaults: [],
    page: <DetalheDesvio />,
  },

  '/logs/desvios/:id': {
    back: -1,
    title: 'Histórico do Desvio',
    id: true,
    defaults: [],
    page: (
      <Historico
        back="/desvios/:id"
        url="/desvios/:id/logs"
        formatReturn={data =>
          data.map(log => ({
            ...log,
            data: log.data ?? log.createdAt,
            evento: log.evento?.evento ?? '',
          }))
        }
      />
    ),
  },

  '/desvios/tratativa-desvios': {
    back: -1,
    title: 'Tratativa de desvios',
    defaults: [],
    page: <TratativasDesvios />,
  },
};
