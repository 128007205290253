import { InfoOutlined } from '@mui/icons-material';
import { TooltipCustom } from 'components/Tooltip';
import * as S from './styled';

const infoMessage = {
  direcao:
    'Acompanhe a posição dos seus motoristas. Você pode alterar os filtros para mudar a exibição.',
  descarga:
    'Acompanhe a posição dos seus motoristas. Você pode alterar os filtros para mudar a exibição.',
};

const cardsMotoristas = [
  {
    name: 'limite',
    value: '0/0',
    text: 'Nº de Ativos/Faixa contratada',
    icon: 'lupa_verde.svg',
    selected: false,
  },
  {
    name: 'desvios',
    value: 0,
    text: 'Motoristas sem desvios registrados',
    icon: 'lupa_verde.svg',
    selected: false,
  },
];

const columnsMotoristas = [
  {
    Header: 'Nome',
    id: 'nome',
    accessor: d => (d.nome ? String(d.nome) : '-'),
    sortType: 'basic',
    show: true,
    Cell: ({ row }) => (
      <h3 style={{ color: '#2E3240', fontWeight: '600' }}>
        {row.original.nome}
      </h3>
    ),
  },
  {
    Header: 'Função',
    id: 'funcao',
    accessor: d => (d.funcao ? String(d.funcao) : '-'),
    sortType: 'basic',
    show: true,
    Cell: ({ row }) => (
      <h3>{row.original.funcao ? row.original.funcao : '-'}</h3>
    ),
  },
  {
    Header: 'Filial',
    id: 'filial',
    accessor: d => (d.filial ? String(d.filial) : '-'),
    sortType: 'basic',
    show: true,
    Cell: ({ row }) => <h3>{row.original.filial.nome}</h3>,
  },
  {
    Header: 'Cliente',
    id: 'cliente',
    accessor: d => (d.distribuidora ? String(d.distribuidora) : '-'),
    sortType: 'basic',
    show: true,
    Cell: ({ row }) => (
      <h3>
        {row.original.distribuidora ? row.original.distribuidora.nome : '-'}
      </h3>
    ),
  },
  {
    Header: 'Status',
    id: 'status',
    accessor: d => (d.status ? String(d.status) : '-'),
    sortType: 'basic',
    show: true,
    Cell: ({ row }) => {
      const msg = 'Termo de confidencialidade pendente.';
      return (
        <div style={{ display: 'flex' }}>
          <S.Status2>{row.original.status?.toLowerCase()}</S.Status2>
          {row.original.assinatura_adesao && (
            <>
              <InfoOutlined
                id={`motorista${row.original.id}`}
                key={`motorista${row.original.id}`}
              />
              <TooltipCustom
                text={msg}
                svgName="openCae"
                parentEl={`motorista${row.original.id}`}
                left="-15"
              />
            </>
          )}
        </div>
      );
    },
  },
  {
    Header: 'Ações',
    id: 'acoes',
    accessor: d => (d.acoes ? String(d.acoes) : '-'),
    show: true,
  },
];

const columnsCreateMany = [
  {
    Header: 'Matricula',
    id: 'matricula',
    accessor: d => (d.matricula ? String(d.matricula) : '-'),
    sortType: 'basic',
    show: true,
    Cell: ({ row }) => (
      <h3 style={{ color: '#2E3240', fontWeight: '600' }}>
        {row.original.matricula}
      </h3>
    ),
  },
  {
    Header: 'Nome',
    id: 'nome',
    accessor: d => (d.nome ? String(d.nome) : '-'),
    sortType: 'basic',
    show: true,
    Cell: ({ row }) => <h3>{row.original.nome}</h3>,
  },
  {
    Header: 'CPF',
    id: 'cpf',
    accessor: d => (d.cpf ? String(d.cpf) : '-'),
    sortType: 'basic',
    Cell: ({ row }) => <h3>{row.original.cpf}</h3>,
  },
  {
    Header: 'Celular',
    id: 'celular',
    accessor: d => (d.celular ? String(d.celular) : '-'),
    sortType: 'basic',
    Cell: ({ row }) => <h3>{row.original.celular}</h3>,
  },
  {
    Header: 'Função',
    id: 'funcao',
    accessor: d => (d.funcao ? String(d.funcao) : '-'),
    sortType: 'basic',
    show: true,
    Cell: ({ row }) => <h3>{row.original.funcao}</h3>,
  },
  {
    Header: 'Gênero',
    id: 'genero',
    accessor: d => (d.genero ? String(d.genero) : '-'),
    sortType: 'basic',
    show: true,
    Cell: ({ row }) => <h3>{row.original.genero}</h3>,
  },
  {
    Header: 'Data de Nascimento',
    id: 'data_de_nascimento',
    accessor: d => (d.date_de_nascimento ? String(d.date_de_nascimento) : '-'),
    sortType: 'basic',
    show: true,
    Cell: ({ row }) => <h3>{row.original.date_de_nascimento}</h3>,
  },
  {
    Header: 'Data de Admissão',
    id: 'data_de_admissao',
    accessor: d => (d.date_de_admissao ? String(d.date_de_admissao) : '-'),
    sortType: 'basic',
    show: true,
    Cell: ({ row }) => <h3>{row.original.date_de_admissao}</h3>,
  },
  {
    Header: 'Empresa Terceira',
    id: 'empresa_terceira',
    accessor: d => (d.empresa_terceira ? String(d.empresa_terceira) : '-'),
    sortType: 'basic',
    show: true,
    Cell: ({ row }) => <h3>{row.original.empresa_terceira}</h3>,
  },
  {
    Header: 'Filial',
    id: 'filial',
    accessor: d => (d.filial ? String(d.filial) : '-'),
    sortType: 'basic',
    show: true,
    Cell: ({ row }) => <h3>{row.original.filial}</h3>,
  },
  {
    Header: 'CNH',
    id: 'cnh',
    accessor: d => (d.cnh ? String(d.cnh) : '-'),
    sortType: 'basic',
    show: true,
    Cell: ({ row }) => <h3>{row.original.cnh}</h3>,
  },
  {
    Header: 'Primeira CNH',
    id: 'primeira_cnh',
    accessor: d => (d.primeiraa_cnh ? String(d.primeiraa_cnh) : '-'),
    sortType: 'basic',
    show: true,
    Cell: ({ row }) => <h3>{row.original.primeiraa_cnh}</h3>,
  },
  {
    Header: 'Validade CNH',
    id: 'validade_cnh',
    accessor: d => (d.validadee_cnh ? String(d.validadee_cnh) : '-'),
    sortType: 'basic',
    show: true,
    Cell: ({ row }) => <h3>{row.original.validadee_cnh}</h3>,
  },
  {
    Header: 'Estado Civíl',
    id: 'estado_civil',
    accessor: d => (d.estado_civil ? String(d.estado_civil) : '-'),
    sortType: 'basic',
    show: true,
    Cell: ({ row }) => <h3>{row.original.estado_civil}</h3>,
  },
  {
    Header: 'Fumante',
    id: 'fuma',
    accessor: d => (d.fuma ? String(d.fuma) : '-'),
    sortType: 'basic',
    show: true,
    Cell: ({ row }) => <h3>{row.original.fuma}</h3>,
  },
];

const fields = [
  {
    label: 'ID',
    value: 'id',
    selected: true,
    default: true,
  },
  {
    label: 'Motorista',
    value: 'nome',
    selected: true,
    default: true,
  },
  {
    label: 'Filial',
    value: 'motorista.filial.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Pontos/H',
    value: 'pontos_por_hora',
    selected: true,
    default: true,
  },
  {
    label: 'Horas',
    value: 'horas',
    selected: true,
    default: true,
  },
  {
    label: 'Pontos',
    value: 'pontos_totais',
    selected: true,
    default: true,
  },
  {
    label: 'Avaliações s/ desvios',
    value: 'zero_infracoes',
    selected: true,
    default: true,
  },
];

const resetExcelFields = fields => {
  return fields.map(i => {
    if (!i.default) i.selected = false;
    return i;
  });
};

export {
  infoMessage,
  cardsMotoristas,
  columnsMotoristas,
  columnsCreateMany,
  fields,
  resetExcelFields,
};

export const columns = [
  {
    header: 'Posição',
    id: 'position',
    sort: true,
    type: 'number',
    width: 100,
    align: 'center',
  },
  {
    header: 'Nome',
    id: 'motorista.nome',
    sort: true,
    tooltip: true,
    type: 'string',
    picture: 'motorista.foto',
  },
  {
    header: 'Filial',
    id: 'motorista.filial.nome',
    sort: true,
    type: 'string',
  },
  {
    header: 'Pontos/H',
    id: 'pontos_por_hora',
    sort: true,
    type: 'number',
    align: 'center',
    value: value => Math.round(value),
  },
  {
    header: 'Horas',
    id: 'horas',
    sort: true,
    type: 'number',
    align: 'center',
  },
  {
    header: 'Pontos',
    id: 'pontos_totais',
    sort: true,
    type: 'number',
    align: 'center',
  },
  {
    header: 'Av Sem Desvios',
    id: 'zero_infracoes',
    sort: true,
    type: 'number',
    align: 'center',
    description: 'Avaliações sem desvio registrado',
  },
];
