/* eslint-disable import/order */
import colors from 'styles/colors';
import { Box, CircularProgress } from '@mui/material';

export const Progress = ({
  children,
  value = 0,
  thickness = 3.6,
  scale = 1,
}) => {
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        transform: `scale(${scale})`,
      }}
    >
      <CircularProgress
        variant="determinate"
        value={100}
        sx={{
          color: colors.greyTiny,
        }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress
          variant="determinate"
          value={value}
          thickness={thickness}
          sx={{
            color: colors.greenSucces,
          }}
        />
      </Box>
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
