import { AddCircleOutline } from '@mui/icons-material';
import DefaultButton from 'components/Buttons/Default';
import HeartBrokenIcon from '@mui/icons-material/HeartBroken';
import colors from 'styles/colors';
import * as S from './styled';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import Loading from '../Loading';

const Empty = ({
  titleButton,
  query,
  setModalAdd,
  inSearch,
  width = '400px',
  forMe,
  tryed,
}) => {
  const img = require(`images/empty/desvio.png`);

  const { user } = useSelector(state => {
    return state.auth.user;
  });
  let isConvidado = false;
  if (+user.nivel === 4) isConvidado = true;

  const handleConvidado = () => {
    toast.error('Você não tem permissão para executar essa ação!');
  };

  const formatEmptyText = () => {
    if (query.search && query.tag !== 'Todos') {
      return (
        <h3>{`Ops! Nenhum conteúdo "${query.search}" em: "${query.tag}".`}</h3>
      );
    }
    if (query.search) {
      return <h3>{`Ops! Nenhum conteúdo "${query.search}".`}</h3>;
    }
    if (query.tag) {
      return <h3>{`Ops! Nenhum conteúdo em: "${query.tag}".`}</h3>;
    }

    if (tryed) {
      return <h3>Você não possui reprovações.</h3>;
    }

    if (!tryed) {
      return <h3>Ops! você não tem nenhum conteúdo disponível.</h3>;
    }
  };

  return (
    <S.Container width={width}>
      {!inSearch && (
        <>
          <S.ImageArea>
            {!tryed && <img src={img} alt="Conteúdo vazio" />}
            {tryed && (
              <HeartBrokenIcon
                htmlColor={colors.grey}
                style={{ fontSize: 100 }}
              />
            )}
          </S.ImageArea>
          <S.ContentArea>
            <span className="textArea">
              {formatEmptyText()}
              {!forMe && (
                <h5>
                  Verifique o filtro aplicado ou clique em "Novo conteúdo" e
                  comece agora mesmo!
                </h5>
              )}
              {forMe && (
                <h5>Verifique os filtros aplicados e faça uma nova busca!</h5>
              )}
            </span>
            {setModalAdd && (
              <span className="buttonArea">
                <DefaultButton
                  children={titleButton}
                  onClick={() =>
                    !isConvidado ? setModalAdd(true) : handleConvidado()
                  }
                  startIcon={<AddCircleOutline />}
                />
              </span>
            )}
          </S.ContentArea>
        </>
      )}
      {inSearch && <Loading />}
    </S.Container>
  );
};

export default Empty;
