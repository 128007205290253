import EmptyCard from 'components/Cards/EmptyDataCard';

export const NotFoundConfig = () => {
  return (
    <EmptyCard
      image="motorista.png"
      title="Ops! Página não encontrada!"
      subtitle="Selecione uma das opções disponíveis."
    />
  );
};
