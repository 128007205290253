import styled, { css } from 'styled-components';

export const ModalMUI = styled.div`
  position: relative;
  display: none;
  overflow: hidden;
`;

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    background-color: ${theme.palette.system.overlay};
    padding: 20px 40px;
    overflow-y: scroll;
    overflow-x: hidden;

    .box {
      overflow: hidden;
      position: relative;
      width: 100%;
    }

    .lineGraph {
      position: relative;
      background-color: red;
      overflow: hidden;
    }
  `}
`;

export const Infos = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;

    .formIdent {
      font-weight: bold;
      color: ${theme.palette.words.title.natural};
      display: flex;
      flex-direction: column;
    }
  `}
`;

export const Header = styled.div`
  ${({ theme, ...props }) => css`
    background-color: #f5f5f5;
    border: 2px solid ${theme.palette.words.text.light};
    border-radius: 5px;
    margin-top: 20px;
    padding: 20px;

    .topInfos {
      display: flex;
      justify-content: space-between;
      margin-bottom: 25px;

      .coverArea {
        flex: 2;
        width: 100%;
        height: 200px;
        border-radius: 5px;

        .background {
          background-image: url(${props.bg});
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          width: 100%;
          height: 100%;
          border-radius: 5px;
        }
      }

      .textsArea {
        flex: 3;
        margin-left: 20px;

        .titleForm {
          font-weight: 800;
          font-size: 25px;
          color: ${theme.palette.words.title.natural};
        }

        .descriptionForm {
        }
      }
    }

    .bottomInfos {
      display: flex;
      justify-content: space-between;
      margin-top: 25px;

      .card {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        .logoCompany {
          margin-right: 30px;

          img {
            max-height: 80px;
          }
        }

        .cardInfos {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .title {
            font-size: 16px;
            font-weight: 500;
            color: ${theme.palette.words.subtitle.natural};
            text-align: center;
          }

          .numbers {
            font-weight: 600;
            font-size: 18px;
            color: ${theme.palette.words.title.natural};
          }
        }
      }

      .bar {
        height: 100%;
        min-height: 100px;
        width: 1px;
        background-color: ${theme.palette.words.text.light};
      }
    }
  `}
`;

export const Header2 = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TableArea = styled.div`
  ${({ theme }) => css`
    position: relative;

    .coverInput {
      width: 100%;
      height: 100px;
      background-color: ${theme.palette.system.overlay};
      position: absolute;
      top: 0;
      z-index: 3;
    }

    .coverInputBottom {
      width: 100%;
      height: 100px;
      background-color: ${theme.palette.system.overlay};
      position: absolute;
      bottom: 0;
      z-index: 3;
    }
  `}
`;

export const Paragraph = styled.p`
  ${({ theme, center }) => css`
    font-weight: bold;
    color: ${theme.palette.brand.primary.natural};
    font-size: 14px;
    text-align: ${center ? 'center' : 'start'};

    &.normal {
      font-size: 16px;
    }

    &.name {
      text-transform: capitalize;
    }

    &.small {
      font-weight: 500;
      font-size: 12px;
    }

    &.green {
      color: ${theme.palette.semantics.feedback.success.natural};
      background-color: ${theme.palette.semantics.feedback.success.light};
      border-radius: 20px;
      text-align: center;
    }

    &.orange {
      color: ${theme.palette.semantics.feedback.warning.natural};
      background-color: ${theme.palette.semantics.feedback.warning.light};
      border-radius: 20px;
      text-align: center;
    }

    &.red {
      color: ${theme.palette.semantics.feedback.attention.natural};
      background-color: ${theme.palette.semantics.feedback.attention.light};
      border-radius: 20px;
      text-align: center;
    }
  `}
`;

export const ColDriver = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    text-transform: capitalize;

    h1 {
      font-size: 14px;
      font-weight: 600;
      color: ${theme.palette.brand.primary.natural};
    }
  `}
`;

export const CardHeader = styled.div`
  ${({ theme }) => css`
    background: ${theme.palette.brand.primary.dark};
    padding: 10px;
    margin-top: 20px;
    border-radius: 5px;

    h1 {
      color: ${theme.palette.words.title.contrast};
      font-size: 22px;
      font-weight: 900;
    }
  `}
`;
