import styled, { css } from 'styled-components';

export const TagItem = styled.span`
  ${({ theme }) => css`
    background-color: ${theme.palette.brand.primary.background};
    color: ${theme.palette.words.title.natural};
    font-weight: bold;
    text-align: center;
    padding: 0px 20px;
    margin-right: 20px;
    border-radius: 50px;
    border: 1px solid ${theme.palette.brand.primary.background};
    transition: all ease 0.2s;
    cursor: pointer;
    height: 25px;
    white-space: nowrap;

    &.active {
      border: 1px solid ${theme.palette.words.title.natural};
    }

    &:hover {
      background-color: ${theme.palette.system.border};
    }
  `}
`;

export const TagItemLoading = styled.span`
  ${({ theme }) => css`
    padding: 0px 20px;
    margin-right: 20px;
    border-radius: 50px;
    border: 1px solid ${theme.palette.brand.primary.background};
    transition: all ease 0.2s;
    height: 25px;
    width: 100px;
    background-image: linear-gradient(
      to right,
      #f6f7f8 0%,
      #edeef1 20%,
      #f6f7f8 40%,
      #f6f7f8 100%
    );
    background-size: 80rem 14rem;
    animation: placeholderShimmer 3s linear infinite forwards;

    @keyframes placeholderShimmer {
      0% {
        background-position: -40rem 0;
      }
      100% {
        background-position: 40rem 0;
      }
    }
  `}
`;
