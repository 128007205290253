import { Gerais } from './Gerais';
import { Midias } from './Midias';
import { Avaliacao } from './Avaliacao';
import { Publico } from './Publico';
import { Configuracoes } from './Configuracoes';
import { Publicar } from './Publicar';
import { Reports } from './Reports';
import { Reviews } from './Reviews';

export const stepsDefault = [
  {
    title: 'Informações Gerais',
    pageTitle: 'Informações Gerais',
    page: 'Informações Gerais',
    step: 1,
    value: 0,
    Component: changed => Gerais(changed),
  },
  {
    title: 'Mídias',
    pageTitle: 'Selecione o tipo de conteúdo',
    page: 'Mídias',
    step: 2,
    value: 1,
    Component: changed => Midias(changed),
  },
  {
    title: 'Avaliação',
    pageTitle: 'Selecione o tipo de avaliação',
    page: 'Avaliação',
    step: 3,
    value: 2,
    Component: changed => Avaliacao(changed),
  },
  {
    title: 'Público',
    pageTitle: 'Definir Público',
    page: 'Público',
    step: 4,
    value: 3,
    Component: changed => Publico(changed),
  },
  {
    title: 'Configurações',
    pageTitle: 'Configurações',
    page: 'Configurações',
    step: 5,
    value: 4,
    Component: changed => Configuracoes(changed),
  },
  {
    title: 'Publicar',
    pageTitle: 'Revisar e Publicar',
    page: 'Prévia do conteúdo',
    step: 6,
    value: 5,
    Component: changed => Publicar(changed),
  },
  {
    title: 'Reports',
    pageTitle: 'Reports',
    page: 'Reports',
    step: 7,
    value: 6,
    Component: changed => Reports(changed),
  },
  {
    title: 'Curtidas e Comentários',
    pageTitle: 'Curtidas e Comentários',
    page: 'Curtidas e Comentários',
    step: 8,
    value: 7,
    Component: changed => Reviews(changed),
  },
];

export const stepsEmbarcadora = [
  {
    title: 'Público',
    pageTitle: 'Definir Público',
    page: 'Público',
    step: 1,
    value: 0,
    Component: changed => Publico(changed),
  },
  {
    title: 'Publicar',
    pageTitle: 'Revisar e Publicar',
    page: 'Prévia do conteúdo',
    step: 2,
    value: 1,
    Component: changed => Publicar(changed),
  },
  {
    title: 'Reports',
    pageTitle: 'Reports',
    page: 'Reports',
    step: 3,
    value: 2,
    Component: changed => Reports(changed),
  },
  {
    title: 'Curtidas e Comentários',
    pageTitle: 'Curtidas e Comentários',
    page: 'Curtidas e Comentários',
    step: 4,
    value: 3,
    Component: changed => Reviews(changed),
  },
];
