import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 50px;
    border: 1px solid ${theme.palette.brand.primary.light};
    border-left: 5px solid ${theme.palette.brand.primary.light};
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3px 15px;
    margin-top: 20px;
    background-color: ${theme.palette.system.overlay};
    cursor: pointer;

    div {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .mediumIcon {
      margin-left: 15px;
      margin-right: 15px;
    }

    span {
      font-size: 16px;
      font-weight: 500;
      color: ${theme.palette.words.subtitle.natural};
    }

    .question::first-letter {
      text-transform: uppercase;
    }

    .content {
      width: 100%;
      display: flex;
      justify-content: start;
      align-items: center;
    }
  `}
`;
