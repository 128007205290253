import React, { useState, useCallback, useEffect } from 'react';
import {
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Sector,
} from 'recharts';

import Skeleton from '@mui/material/Skeleton';

const PieGraph = ({ data, tooltip, loading = false }) => {
  const [outerRadius, setOuterRadius] = useState(0);
  const [currentWidth, setCurrentWidth] = useState('30%');
  const [innerRadius, setInnerRadius] = useState(0);

  const total = data.reduce((acc, entry) => acc + entry.absoluteValue, 0);
  const startAngle = 0;
  const endAngle = 360;

  const cx = 80;
  const cy = 120;

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;

      if (screenWidth < 768) {
        setCurrentWidth('small');
      } else if (screenWidth >= 768 && screenWidth < 1280) {
        setCurrentWidth('50%');
        setOuterRadius(55);
        setInnerRadius(38);
      } else if (screenWidth >= 1280 && screenWidth < 1440) {
        setCurrentWidth('35%');
        setOuterRadius(65);
        setInnerRadius(48);
      } else {
        setCurrentWidth('20%');
        setOuterRadius(75);
        setInnerRadius(58);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [activeIndex, setActiveIndex] = useState(0);
  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex],
  );

  const renderActiveShape = props => {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } =
      props;

    return (
      <g>
        <text
          x={cx}
          y={cy + 20}
          dy={8}
          textAnchor="middle"
          fill="#4B5166"
          fontSize={16}
        >
          Total
        </text>
        <text
          x={cx}
          y={cy}
          dy={8}
          textAnchor="middle"
          fill="#4B5166"
          fontSize={32}
          fontFamily="texta"
          fontWeight={900}
        >
          {total}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
      </g>
    );
  };

  return (
    <ResponsiveContainer width={300} height="100%">
      {!loading ? (
        <PieChart
          margin={{
            left: 30,
          }}
        >
          {tooltip && <Tooltip />}

          <Pie
            data={data}
            cx={cx}
            cy={cy}
            activeIndex={activeIndex}
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            paddingAngle={8}
            activeShape={renderActiveShape}
            dataKey="value"
            startAngle={startAngle}
            endAngle={endAngle}
            label
            onMouseEnter={onPieEnter}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color || '#000'} />
            ))}
          </Pie>
        </PieChart>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <Skeleton variant="circular" width={160} height={160} />
        </div>
      )}
    </ResponsiveContainer>
  );
};

export default PieGraph;
