export const SET_DATA_ID = '@conteudo_provider/SET_DATA_ID';
export const SET_DATA_GERAL = '@conteudo_provider/SET_DATA_GERAL';
export const SET_DATA_MIDIAS = '@conteudo_provider/SET_DATA_MIDIAS';
export const SET_DATA_AVALIACAO = '@conteudo_provider/SET_DATA_AVALIACAO';
export const SET_DATA_PUBLICO = '@conteudo_provider/SET_DATA_PUBLICO';
export const SET_DATA_CONFIGURACOES =
  '@conteudo_provider/SET_DATA_CONFIGURACOES';
export const SET_DATA_PUBLICAR = '@conteudo_provider/SET_DATA_PUBLICAR';
export const RESET_DATA = '@conteudo_provider/RESET_DATA';
export const INITIALIZE_DATA = '@conteudo_provider/INITIALIZE_DATA';
