import React, { useMemo, useState } from 'react';
import { Menu, MenuItem, SvgIcon, IconButton } from '@mui/material';
import { ReactComponent as arrow_down } from 'images/icons/arrows/down.svg';
import * as S from './styled';

const SelectClient = ({
  value,
  setValue,
  empresas,
  disabled,
  label,
  required,
  hasError,
}) => {
  const [anchorEmpresa, setAnchorEmpresa] = useState();
  const empresa = useMemo(
    () => empresas.find(item => item.id === value),
    [empresas, value],
  );

  const handleSelect = newValue => {
    setValue(newValue);
    setAnchorEmpresa(null);
  };

  return (
    <>
      {label && (
        <S.Label hasError={hasError}>
          <p>{label}</p>
          {required && <span>*</span>}
        </S.Label>
      )}
      <S.Container>
        <S.ImageArea>
          {empresa?.logo_url ? (
            <img src={empresa?.logo_url} />
          ) : (
            <span className="letters">{empresa?.nome[0]}</span>
          )}
        </S.ImageArea>

        <S.TextArea>
          <span className="filialName">Transportadora</span>
          <span className="userName">{empresa?.nome}</span>
        </S.TextArea>

        {!disabled && (
          <IconButton
            size="small"
            children={<SvgIcon fontSize="large" component={arrow_down} />}
            onClick={e => setAnchorEmpresa(e.currentTarget)}
          />
        )}

        <Menu
          open={!!anchorEmpresa}
          anchorEl={anchorEmpresa}
          onClose={() => setAnchorEmpresa(null)}
        >
          {empresas &&
            empresas.map((item, index) => (
              <MenuItem
                style={{ width: '300px' }}
                key={item.nome}
                selected={item.id === empresa?.id}
                onClick={() => handleSelect(item.id)}
              >
                <S.ImageArea>
                  {item.logo_url ? (
                    <img src={item.logo_url} />
                  ) : (
                    <span className="letters">{item.nome[0]}</span>
                  )}
                </S.ImageArea>

                <S.TextEmpresa>{item.nome}</S.TextEmpresa>
              </MenuItem>
            ))}
        </Menu>
      </S.Container>
    </>
  );
};

export default SelectClient;
