import api from 'services/api';
import { toast } from 'react-toastify';
import qs from 'qs';

export const getInfo = async (id, tipo) => {
  const res = await api.get(`/training/perfil-aluno/${id}`, {
    params: { tipo },
  });
  if (res?.data?.success) {
    return res.data;
  }
  toast.error(res.data.message);
  return [];
};

export const getTurmas = async (id, tipo) => {
  const res = await api.get(`/training/turmas-aluno/${id}`, {
    params: { tipo },
  });
  if (res.data.success) {
    return res.data;
  }
  toast.error(res.data.message);
  return [];
};

export const handleDeleteTurma = async data => {
  const res = await api.post(`/training/remove-turmas-aluno/`, {
    data,
  });
  if (res.data.success) {
    toast.success(res.data.message);
    return res.data;
  }
  toast.error(res.data.message);
  return [];
};
export const handleAddTurma = async data => {
  const res = await api.post(`/training/add-turmas-aluno/`, {
    data,
  });
  if (res.data.success) {
    toast.success(res.data.message);
    return res.data;
  }
  toast.error(res.data.message);
  return [];
};

export const getContents = async (id, tipo) => {
  const res = await api.get(`/training/aluno-content/${id}`, {
    params: { tipo },
  });
  if (res.data.success) {
    return res.data;
  }
  toast.error(res.data.message);
  return [];
};

export const getContentsApproved = async (id, tipo) => {
  const res = await api.get(`/training/aluno-approved-content/${id}`, {
    params: { tipo },
  });
  if (res.data.success) {
    return res.data;
  }
  toast.error(res.data.message);
  return [];
};

export const getContentsReproved = async (id, tipo) => {
  const res = await api.get(`/training/aluno-reproved-content/${id}`, {
    params: { tipo },
  });
  if (res.data.success) {
    return res.data;
  }
  toast.error(res.data.message);
  return [];
};

export const getCertificates = async (id, tipo) => {
  const res = await api.get(`/training/aluno-certificados/${id}`, {
    params: { tipo },
  });
  if (res.data.success) {
    return res.data;
  }
  toast.error(res.data.message);
  return [];
};

export const showContent = async id => {
  const res = await api.get(`/training/content/${id}`);
  if (res.data.success) {
    return res.data;
  }
  toast.error(res.data.message);
  return [];
};

export const requestCertificate = async (id, query) => {
  const res = await api.get(
    `/training/request-acertificate/${id}?${qs.stringify(query)}`,
  );
  return res;
};
