import { Modal } from '@mui/material';
import colors from 'styles/colors';
import styled from 'styled-components';

export const ModalMUI = styled(Modal)`
  position: fixed;
  visibility: hidden;
  overflow: hidden;
`;

export const Container = styled.div`
  width: 100%;
  background-color: #fff;
  padding: 20px 40px;
  overflow: scroll;
`;

export const Infos = styled.div`
  display: flex;
  justify-content: space-between;

  .formIdent {
    font-weight: bold;
    color: ${colors.greyTitle};
    display: flex;
    flex-direction: column;
  }
`;

export const Header = styled.div`
  background-color: #f5f5f5;
  border: 2px solid ${colors.greyTiny};
  border-radius: 5px;
  margin-top: 20px;
  padding: 20px;

  .topInfos {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;

    .coverArea {
      flex: 2;
      width: 100%;
      height: 200px;
      border-radius: 5px;

      .background {
        background-image: url(${props => props.bg});
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 100%;
        border-radius: 5px;
      }
    }

    .textsArea {
      flex: 3;
      margin-left: 20px;

      .titleForm {
        font-weight: 800;
        font-size: 25px;
        color: ${colors.greyTitle};
      }

      .descriptionForm {
      }

      .initial {
        margin-top: 20px;
        font-weight: 500;

        span {
          font-weight: bold;
          margin-right: 5px;
        }
      }

      .final {
        font-weight: 500;

        span {
          font-weight: bold;
          margin-right: 5px;
        }
      }
    }
  }

  .bottomInfos {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;

    .card {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      .logoCompany {
        margin-right: 30px;

        img {
          max-height: 80px;
        }
      }

      .cardInfos {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .title {
          font-size: 16px;
          font-weight: 500;
          color: ${colors.greySubtitle};
          text-align: center;
        }

        .numbers {
          font-weight: 600;
          font-size: 18px;
          color: ${colors.greyTitle};
        }
      }
    }

    .bar {
      height: 100%;
      min-height: 100px;
      width: 1px;
      background-color: ${colors.greyTiny};
    }
  }
`;

export const MediumBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 40px;
  position: relative;

  .bigGap {
    margin-top: 320px;
  }

  @media (max-width: 1327px) {
    flex-direction: column;
  }
`;
