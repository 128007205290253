import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  text-align: left;
  height: 100%;
  max-height: 430px;
  background: ${({ theme }) => theme.palette.system.white};
  padding: 1rem;
  border: 1px solid ${({ theme }) => `${theme.palette.brand.primary.light}4d`};
  border-radius: 4px;
  cursor: pointer;
`;

export const Title = styled.div`
  font: normal normal 900 20px/24px Texta;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.palette.words.subtitle.natural};
  opacity: 1;
  margin-bottom: 1rem;

  span {
    font: normal normal 600 16px/24px Texta;
    color: rgb(246, 78, 96);
  }
`;

export const ListContainer = styled.div`
  height: 100%;
  overflow-y: auto;
`;

export const ListItem = styled.li`
  ${({ theme }) => css`
    height: 2rem;
    font: normal normal medium 16px/30px Texta;
    color: ${theme.palette.words.title.natural};
  `}
`;

export const StyledButton = styled.button`
  ${({ theme }) => css`
    width: 100%;
    padding: 0.25rem;
    text-align: center;
    color: ${theme.palette.words.title.natural};
    font-weight: 600;
    background-color: ${theme.palette.system.border};
    border: none;
    border-radius: 0.25rem;
    margin: 0.5rem 0;

    :hover {
      background-color: ${theme.palette.system.divider};
    }
  `}
`;

export const SearchBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .searchIcon {
    position: absolute;
    top: 5px;
    left: 8px;
  }
`;

export const SearchInput = styled.input`
  ${({ theme }) => css`
    width: 100%;
    padding: 5px 8px 5px 38px;
    border: none;
    border: 1px solid ${theme.palette.system.divider};
    border-radius: 5px;
    font-size: 16px;

    &::placeholder {
      font-size: 16px;
    }

    &:focus {
      border: 1px solid ${theme.palette.brand.secondary.natural};
    }
  `}
`;
