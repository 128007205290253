// Styled
import { useTheme } from 'styled-components';

// React
import { Fragment, useEffect, useState } from 'react';

// Components

// Compoenets Maaterial
import { Divider } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';
import { Modal, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import Search from '../Search';
import Title from '../Title';
import * as S from './styled';

// Utils
import { items } from '../../items';

const ModalItems = ({ reference, expanded, setExpanded }) => {
  const theme = useTheme();
  // General States
  const [value, setValue] = useState('');
  const [controllScroll, setControllScroll] = useState(false);
  const [componentModal, setComponentModal] = useState(false);
  const [mapSections, setMapSections] = useState(items);
  const [currentElement, setCurrentElement] = useState(null);

  // -----------------------------------MODAL CONTROLLER----------------------------//
  const behaviorModal = () => {
    if (expanded) {
      setTimeout(() => {
        setControllScroll(true);
      }, 300);
    }
  };

  useEffect(() => {
    behaviorModal();
  }, [expanded]);

  const closeModal = () => {
    setControllScroll(false);
    setTimeout(() => {
      setExpanded(false);
      setValue('');
    }, 300);
  };

  // --------------------------------------COMPONENT CONTROLLER-----------------------------------//
  const changeComponent = () => {
    if (currentElement) {
      const { Component } = currentElement;

      return (
        <Component
          open={componentModal}
          onClose={() => setComponentModal(false)}
          data={null}
          closeModalItems={() => closeModal()}
          currentElement={currentElement}
        />
      );
    }
  };

  // FILTER ITENS BY INPUT
  useEffect(() => {
    if (value) {
      const forFilter = [];
      for (const i in items) {
        const { types } = items[i];
        const newTypes = [];
        for (const j in types) {
          if (types[j].nome.toLowerCase().includes(value.toLowerCase())) {
            newTypes.push(types[j]);
          }
        }
        forFilter.push({
          section: items[i].section,
          types: newTypes,
        });
      }
      setMapSections(forFilter);
    } else {
      setMapSections(items);
    }
  }, [value]);

  return (
    <>
      <Modal
        open={expanded}
        disableEscapeKeyDown={false}
        onClose={() => closeModal()}
      >
        <S.Container
          expanded={controllScroll}
          ref={reference}
          className="ModalItemsController"
        >
          <S.Header>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Title children="Adicionar Campos" />
              <CloseIcon onClick={() => closeModal()} />
            </div>
            <Search value={value} setValue={setValue} />
            <Divider />
          </S.Header>
          <S.SectionArea>
            {mapSections?.map((box, key) => (
              <Fragment key={key}>
                <S.TitleSections>
                  <span>{box.section}</span>
                  <Tooltip title={box.description}>
                    <HelpOutlineOutlinedIcon
                      fontSize="small"
                      htmlColor={theme.palette.brand.primary.light}
                    />
                  </Tooltip>
                </S.TitleSections>
                <S.IconsArea>
                  {box.types.length > 0 &&
                    box.types.map((item, idx) => (
                      <S.ItemBox
                        key={idx}
                        onClick={() => {
                          setCurrentElement(item);
                          setComponentModal(true);
                        }}
                      >
                        <SvgIcon
                          component={item.icone}
                          htmlColor={theme.palette.brand.primary.light}
                        />
                        <div className="itemName">{item.nome}</div>
                      </S.ItemBox>
                    ))}

                  {box.types.length <= 0 && (
                    <S.NotFoundItems>Nenhum item encontrado</S.NotFoundItems>
                  )}
                </S.IconsArea>
              </Fragment>
            ))}
          </S.SectionArea>
        </S.Container>
      </Modal>
      {changeComponent()}
    </>
  );
};

export default ModalItems;
