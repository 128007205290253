import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Divider, Grid, SvgIcon } from '@mui/material';
import {
  SaveAlt,
  FindInPageOutlined,
  Check,
  RouteOutlined,
  CalendarTodayOutlined,
  WarningAmber,
} from '@mui/icons-material';
import { toast } from 'react-toastify';

import { useReactToPrint } from 'react-to-print';
import SelectCliente from 'components/Inputs/SelectCliente';
import TextInput from 'components/Inputs/TextField';
import SelectInput from 'components/Inputs/Select';
import Calendar from 'components/Inputs/Calendar';
import GhostButton from 'components/Buttons/Ghost';
import DefaultButton from 'components/Buttons/Default';
import ConfirmModal from 'components/ConfirmModal';
import InputAttachedFile from 'components/Inputs/InputAttachedFile2';
import Loading from 'components/Loading';
import ImageDesvio from 'pages/Desvio/ImageDesvio';

import { formatNewDate, formatNewHour, formatNewHourClean } from 'utils/dates';
import { useTheme } from 'styled-components';
import { ReactComponent as HistoricoIcon } from 'images/icons/sidebar/historico.svg';
import { getStatusColor, howToColor } from '../constants';
import DeleteModal from '../DeleteModal';
import { ReactComponent as navigateIcon } from '../../../images/icons/desvios/Grupo 269.svg';
import { ReactComponent as disabledNavigateIcon } from '../../../images/icons/desvios/Grupo 11400.svg';
import * as S from './styled';

import * as request from '../services';

const InfractionDetail = () => {
  const theme = useTheme();
  // Parametro da página desvio/:id
  const { id } = useParams();
  const navigate = useNavigate();

  // Dados do redux
  const user = useSelector(state => {
    return state.auth?.user?.user;
  });
  const userLevel = user.nivel;
  const userFilials = user.usuario_filiais;
  const { users, clients } = useSelector(state => {
    return state.selects;
  });

  // Desvio que pode ser manipulado e desvio original (que é lido)
  const [infraction, setInfraction] = useState(null);
  const [originalInfraction, setOriginalInfraction] = useState(null);
  const [desvioDeleteModal, setDesvioDeleteModal] = useState(null);
  const [loading, setLoading] = useState(false);

  // Controla modal de confirmação das ações:
  // SALVAR, FINALIZAR E ENVIAR PARA PAD
  const [openModal, setOpenModal] = useState(null);
  const [loadingModal, setLoadingModal] = useState(false);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [printMode, setPrintMode] = useState(false);

  const componentRef = useRef();

  // Lista de responsáveis
  const responsibles = useMemo(
    () =>
      users
        .filter(i => {
          if (userLevel === 1) return true;
          if (userFilials.includes(i.id_da_filial)) return true;
          return false;
        })
        .map(usr => ({ name: usr.nome, value: usr.id })),
    [users, userLevel],
  );

  const fetchInfraction = async (dataId = null) => {
    const infractionId = dataId || id;
    const res = await request.fetchInfraction(infractionId);
    if (res.data?.data) {
      const desv = res.data.data;
      setInfraction(desv);
      setOriginalInfraction(desv);
    } else if (res.data?.message) toast.error(res.data.message);

    setLoading(false);
  };

  useEffect(() => {
    fetchInfraction();
  }, []);

  useEffect(() => {
    if (printMode) handlePrint();
  }, [printMode]);

  const handleChange = (key, value) => {
    setInfraction(prev => {
      return { ...prev, [key]: value };
    });
  };

  // Chamada ao trocar distribuidora
  // Abre modal de confirmação de troca
  // value passa o novo id_da_distribuidora
  // block diz se a avaliação está bloqueada
  const handleChangeDistribuidora = value => {
    const blocked = infraction?.avaliacao.status !== 'PENDENTE';

    const objOpen = {
      value,
      block: false,
      event: 'ALTERAR_DISTRIBUIDORA',
      titleIcon: (
        <Check
          sx={{ color: theme.palette.brand.secondary.natural }}
          fontSize="medium"
        />
      ),
      buttonText: 'CONFIRMAR',
      title: 'Tem certeza que deseja alterar o cliente do desvio?',
      subtitle: 'A alteração será aplicada após SALVAR.',
    };

    if (blocked) {
      objOpen.block = true;
      objOpen.title = 'Avaliação bloqueada';
      objOpen.subtitle =
        'A alteração de cliente deve ser feita pela avaliação correspondente. deseja continuar?';
    }
    setOpenModal(objOpen);
  };

  // Controla modal de confirmação das ações:
  // SALVAR, FINALIZAR E ENVIAR PARA PAD
  const handleOpenModal = event => {
    let objOpen = {
      event,
      titleIcon: (
        <Check
          sx={{ color: theme.palette.brand.secondary.natural }}
          fontSize="medium"
        />
      ),
      buttonText: 'CONFIRMAR',
    };

    switch (event) {
      case 'SALVAR':
        let hasChange = false;
        for (const key in infraction) {
          // Um dos 2 nao é nulo ou "" e são diferentes
          if (
            (infraction[key] || originalInfraction[key]) &&
            infraction[key] !== originalInfraction[key]
          ) {
            if (
              (typeof infraction[key] === 'string' && infraction[key].trim()) ||
              typeof infraction[key] !== 'string'
            )
              hasChange = true;
          }
        }
        if (!hasChange) {
          toast.warning('Nenhuma alteração foi aplicada!');
          return;
        }
        objOpen = {
          ...objOpen,
          title: 'Tem certeza que deseja salvar desvio?',
          subtitle: 'Revise todos os campos alterados!',
        };
        break;

      case 'FINALIZAR':
        if (
          infraction?.tipo.criticidade === 'GRAVÍSSIMA' &&
          !infraction.arquivo_justificativa
        ) {
          toast.warning(
            'O campo Evidência é obrigatório para desvios com criticidade GRAVÍSSIMA!',
          );
          const element = document.getElementById('arquivo_justificativa');
          element?.focus();
          return;
        }
        if (
          infraction?.id_da_distribuidora !==
          originalInfraction?.id_da_distribuidora
        ) {
          toast.warning('Utilize o botão SALVAR para alterar o cliente!');
          const element = document.getElementById('btnSalvar');
          element?.focus();
          return;
        }

        objOpen = {
          ...objOpen,
          title: 'Tem certeza que deseja finalizar desvio?',
          subtitle: 'O desvio será movido para FINALIZADO',
        };
        break;

      case 'PAD':
        for (const key in infraction) {
          // Um dos 2 nao é nulo ou "" e são diferentes
          if (
            (infraction[key] || originalInfraction[key]) &&
            infraction[key] !== originalInfraction[key]
          ) {
            toast.warning('Salve as alterações antes de enviar para o PAD!');
            return;
          }
        }
        objOpen = {
          ...objOpen,
          title: 'Tem certeza que deseja enviar desvio para o PAD?',
          subtitle: 'O desvio será enviado como ocorrência para o PAD',
        };
        break;
    }
    if (objOpen.title) setOpenModal(objOpen);
  };

  // Ações da confirmação do modal:
  // Mesmas das de cima + ALTERAR_DISTRIBUIDORA
  const handleConfirmModal = () => {
    const { event } = openModal;
    switch (event) {
      case 'SALVAR':
        handleSalvar();
        break;

      case 'FINALIZAR':
        handleFinalizar();
        break;

      case 'PAD':
        handleSendPad();
        break;

      case 'ALTERAR_DISTRIBUIDORA':
        // Direciona para avaliação caso esteja bloqueada
        // Se não, troca o id_da_distribuidora
        if (openModal.block)
          navigate(`/avaliacoes-descarga/${infraction.id_da_avaliacao}`);
        else handleChange('id_da_distribuidora', openModal.value);
        setOpenModal(null);
        break;
    }
  };

  // ---------------  AÇÕES --------------------------------
  const handleSalvar = async () => {
    setLoadingModal(true);
    // Monta obj com parametros editaveis apenas
    const data = {
      ids: [infraction.id],
      id_do_usuario: infraction.id_do_usuario,
      plano_de_acao: infraction.plano_de_acao,
      data_previsao: infraction.data_previsao,
      arquivo_justificativa: infraction.arquivo_justificativa,
      observacao: infraction.observacao,
      objeto: infraction.objeto,
      id_da_distribuidora: infraction.id_da_distribuidora,
    };
    const res = await request.saveInfractions(data);
    if (res.data?.success) {
      toast.success(res.data?.message);
      fetchInfraction();
    } else if (res.data?.message) toast.error(res.data.message);
    setLoadingModal(false);
    setOpenModal(null);
  };

  const handleFinalizar = async () => {
    setLoadingModal(true);
    // Monta obj com parametros editaveis apenas
    const data = {
      ids: [infraction.id],
      id_do_usuario: infraction.id_do_usuario,
      plano_de_acao: infraction.plano_de_acao,
      data_previsao: infraction.data_previsao,
      arquivo_justificativa: infraction.arquivo_justificativa,
      observacao: infraction.observacao,
      objeto: infraction.objeto,
    };
    const res = await request.finishInfractions(data);
    if (res.data?.success) {
      toast.success(res.data?.message);
      fetchInfraction();
    } else if (res.data?.message) toast.error(res.data.message);
    setLoadingModal(false);
    setOpenModal(null);
  };

  const handleDeletar = async data => {
    setLoadingModal(true);
    const res = await request.deleteInfractions(data);
    if (res.data?.success) {
      toast.success(res.data?.message);
      fetchInfraction();
    } else if (res.data?.message) toast.error(res.data.message);
    setLoadingModal(false);
    setDesvioDeleteModal(null);
  };

  const handleSendPad = async () => {
    setLoadingModal(true);
    const data = {
      ids: [infraction.id],
    };
    const res = await request.sendPadInfractions(data);
    if (res.data?.success) {
      toast.success(res.data?.message);
      fetchInfraction();
    } else if (res.data?.message) toast.error(res.data.message);
    setLoadingModal(false);
    setOpenModal(null);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: () => setLoadingPdf(true),
    onAfterPrint: () => {
      setPrintMode(false);
      setLoadingPdf(false);
    },
    documentTitle: `desvio-descarga${infraction?.id}`,
    pageStyle: `
    @page {
      size: auto;
      margin: 15mm;
    }
  `,
  });

  const handleNavigateInfraction = data => {
    setLoading(true);

    let infractionId = infraction.next;
    if (data === 'PREVIOUS') infractionId = infraction.previous;
    navigate(`/desvio-descarga/${infractionId}`, { replace: true });

    fetchInfraction(infractionId);
  };

  //  ------------------   RENDERIZACAO --------------------------------
  const renderHeader = () => {
    return (
      <S.SpacedRow>
        <S.Status color={getStatusColor(infraction.status, theme)}>
          <p>{infraction.status.toLowerCase()}</p>
        </S.Status>

        <S.SpacedRow>
          <GhostButton
            startIcon={<HistoricoIcon />}
            size="medium"
            onClick={() => navigate(`/logs/desvio-descarga/${id}`)}
            sx={{ marginLeft: '20px' }}
          >
            HISTÓRICO DE ALTERAÇÕES
          </GhostButton>

          <GhostButton
            startIcon={<FindInPageOutlined />}
            size="medium"
            onClick={() =>
              navigate(`/avaliacoes-descarga/${infraction.id_da_avaliacao}`)
            }
            sx={{ marginLeft: '20px' }}
          >
            VER AVALIAÇÃO
          </GhostButton>

          <GhostButton
            startIcon={<SaveAlt />}
            size="medium"
            onClick={() => setPrintMode(true)}
            style={{ marginLeft: '10px', marginRight: '32px' }}
            loading={loadingPdf}
          >
            IMPRIMIR
          </GhostButton>

          <S.NavigateButton
            onClick={() => handleNavigateInfraction('PREVIOUS')}
            style={{ transform: infraction.previous && 'rotate(180deg)' }}
            disabled={!infraction.previous}
          >
            <SvgIcon
              component={
                infraction.previous ? navigateIcon : disabledNavigateIcon
              }
              fontSize="medium"
            />
          </S.NavigateButton>

          <S.NavigateButton
            style={{ transform: !infraction.next && 'rotate(180deg)' }}
            onClick={() => handleNavigateInfraction('NEXT')}
            disabled={!infraction.next}
          >
            <SvgIcon
              component={infraction.next ? navigateIcon : disabledNavigateIcon}
              fontSize="medium"
            />
          </S.NavigateButton>
        </S.SpacedRow>
      </S.SpacedRow>
    );
  };

  // Lado Esquerdo do detalhe do desvio
  // Não editável
  const renderTravelInfo = () => {
    const hasImage = infraction.motorista.foto
      ? infraction.motorista.foto
      : false;
    return (
      <S.ColumnWrapper>
        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={12} display="flex" justifyContent="center">
            <ImageDesvio infraction={infraction} onlyImage={printMode} />
          </Grid>

          <Grid item xs={12} sm={12} display="flex" flexDirection="row">
            <S.StyledLogoWrapper>
              <S.StyledLogo backgroundImage={hasImage}>
                {!hasImage && <h2>{infraction.motorista.nome[0]}</h2>}
              </S.StyledLogo>
            </S.StyledLogoWrapper>

            <S.StyledTextWrapper>
              <h1>{infraction.motorista.nome.toLowerCase()}</h1>
              <p>Filial: {infraction.motorista.filial?.nome}</p>
            </S.StyledTextWrapper>
          </Grid>

          <Divider style={{ margin: '10px 0px 10px 10px', width: '100%' }} />

          <Grid item xs={8} sm={8}>
            <TextInput
              id="Desvio"
              label="Desvio"
              value={infraction.tipo.descricao}
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={4} sm={4}>
            <TextInput
              id="criticidade"
              label="Criticidade"
              value={infraction.tipo.criticidade}
              InputProps={{ readOnly: true }}
              variant="filled"
              startIcon={
                <WarningAmber
                  sx={{ color: howToColor(infraction.tipo.criticidade, theme) }}
                />
              }
              fillcolor={howToColor(infraction.tipo.criticidade, theme)}
            />
          </Grid>

          {infraction.avaliacao.local && (
            <Grid item xs={12} sm={12}>
              <TextInput
                id="trejeto"
                label="Trajeto"
                value={infraction.avaliacao.local}
                inputProps={{ readOnly: true }}
                startIcon={
                  <RouteOutlined
                    sx={{
                      rotate: '90deg',
                      color: theme.palette.brand.primary.light,
                    }}
                  />
                }
                variant="filled"
              />
            </Grid>
          )}

          <Grid item xs={6} sm={6}>
            <TextInput
              id="reportadoem"
              label="Reportado em"
              value={formatNewDate(infraction.createdAt)}
              inputProps={{ readOnly: true }}
              startIcon={
                <CalendarTodayOutlined
                  sx={{ color: theme.palette.brand.primary.light }}
                />
              }
              variant="filled"
            />
          </Grid>

          <Grid item xs={6} sm={6}>
            <TextInput
              id="datadesvio"
              label="Data do Desvio"
              value={formatNewHourClean(infraction.data_desvio)}
              startIcon={
                <CalendarTodayOutlined
                  sx={{ color: theme.palette.brand.primary.light }}
                />
              }
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>

          {infraction.status === 'DELETADO' && (
            <Grid item xs={12} sm={12}>
              <TextInput
                id="motivoexclusao"
                label="Motivo da exclusão"
                value={infraction.motivo_exclusao}
                inputProps={{ readOnly: true }}
                variant="filled"
              />
            </Grid>
          )}

          {infraction.status === 'DELETADO' &&
            infraction.observacao_exclusao && (
              <Grid item xs={12} sm={12}>
                <TextInput
                  id="observacaoexclusao"
                  label="Observação da exclusão"
                  value={infraction.observacao_exclusao}
                  inputProps={{ readOnly: true }}
                  variant="filled"
                />
              </Grid>
            )}
        </Grid>
      </S.ColumnWrapper>
    );
  };
  const renderFooter = () => {
    return (
      !printMode && (
        <Grid item xs={12} sm={12} display="flex" justifyContent="flex-end">
          {infraction.status === 'PENDENTE' && userLevel < 4 && (
            <GhostButton
              size="medium"
              customcolor={theme.palette.semantics.feedback.attention.natural}
              onClick={() => setDesvioDeleteModal([id])}
              sx={{ marginLeft: '20px' }}
            >
              EXCLUIR
            </GhostButton>
          )}

          {(infraction.status === 'PENDENTE' ||
            infraction.status === 'FINALIZADO') && (
            <GhostButton
              id="btnSalvar"
              size="medium"
              onClick={() => handleOpenModal('SALVAR')}
              style={{ marginLeft: '10px' }}
            >
              SALVAR
            </GhostButton>
          )}

          {infraction.status === 'PENDENTE' && (
            <DefaultButton
              size="medium"
              onClick={() => handleOpenModal('FINALIZAR')}
              style={{ marginLeft: '10px' }}
              disabled={
                infraction?.id_da_distribuidora !==
                originalInfraction?.id_da_distribuidora
              }
            >
              FINALIZAR
            </DefaultButton>
          )}

          {infraction.status === 'FINALIZADO' && (
            <DefaultButton
              size="medium"
              onClick={() => handleOpenModal('PAD')}
              style={{ marginLeft: '10px' }}
              disabled={!user.filial?.empresa?.pad}
            >
              ENVIAR PARA PAD
            </DefaultButton>
          )}
        </Grid>
      )
    );
  };

  // Lado direito detalhe do desvio
  // Dados editáveis
  const renderInfractionInfo = () => {
    return (
      <S.ColumnWrapper>
        <Grid container columnSpacing={2} rowSpacing={1}>
          <Grid item xs={12} sm={12}>
            <SelectCliente
              value={infraction?.id_da_distribuidora}
              setValue={value => handleChangeDistribuidora(value)}
              distributors={clients}
              // disabled={infraction?.avaliacao.status !== "PENDENTE"}
            />
          </Grid>

          <Grid item xs={7} sm={7}>
            <SelectInput
              id="responsavel"
              label="Atribuir Responsável"
              value={infraction.id_do_usuario}
              onChange={e => handleChange('id_do_usuario', e.target.value)}
              placeholder="Atribuir por Responsável"
              data={responsibles}
              required
            />
          </Grid>

          <Grid item xs={5} sm={5}>
            <Calendar
              id="data_previsao"
              value={infraction.data_previsao}
              onChange={newDate => handleChange('data_previsao', newDate)}
              futureDate
              pastDate={false}
              label="Previsão de Conclusão"
              required
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextInput
              id="plano_de_acao"
              placeholder="Definir Plano de Ação"
              label="Plano de Ação"
              onChange={e => handleChange('plano_de_acao', e.target.value)}
              value={infraction.plano_de_acao}
              multiline
              rows={2}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <InputAttachedFile
              idInput="input_file_desvio_descarga"
              required
              label="Arquivo Justificativa"
              inputLabel="Anexar Evidência"
              fileUrl={infraction.arquivo_justificativa}
              setFileUrl={value => handleChange('arquivo_justificativa', value)}
              fileDir={`/desvios/${id}`}
              // deleteFromStorage nao apagar arquivo antigo
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextInput
              label="Observações"
              placeholder="Inserir observações"
              onChange={e => handleChange('observacao', e.target.value)}
              multiline
              rows={4}
              value={infraction.observacao}
            />
          </Grid>

          {renderFooter()}
        </Grid>
      </S.ColumnWrapper>
    );
  };

  return infraction && originalInfraction && !loading ? (
    <>
      <S.Main>
        {renderHeader()}
        <Grid container spacing="20px" marginTop="10px" ref={componentRef}>
          <Grid item xs={12} sm={12} md={printMode ? 12 : 6}>
            {renderTravelInfo()}
          </Grid>

          {printMode && <Grid item xs={12} height="350px" />}

          <Grid item xs={12} sm={12} md={printMode ? 12 : 6}>
            {renderInfractionInfo()}
          </Grid>
        </Grid>
      </S.Main>

      {
        // Este modal controla todas as confirmações
        // openModal.event define a acao em handleConfirmModal
        openModal && (
          <ConfirmModal
            handleClose={() => setOpenModal(null)}
            open={Boolean(openModal)}
            title={openModal.title}
            subtitle={openModal.subtitle}
            titleIcon={openModal.titleIcon}
            buttonText={openModal.buttonText}
            onClick={handleConfirmModal}
            loading={loadingModal}
          />
        )
      }

      {desvioDeleteModal && (
        <DeleteModal
          handleConfirm={handleDeletar}
          ids={desvioDeleteModal}
          handleClose={() => setDesvioDeleteModal(null)}
          loading={loadingModal}
        />
      )}
    </>
  ) : (
    <Loading />
  );
};

export default InfractionDetail;
