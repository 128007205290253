import api from 'services/api';

export const showInfo = async id => {};

export const saveInfos = async data => {
  const res = await api.post('/create-layout', data);
  return res.data;
};

export const saveUpdate = async (data, id) => {
  const res = await api.put(`/layout/${id}`, data);
  return res.data;
};

export const answerOne = async data => {
  const res = await api.put(`/layout-answer/${data.id}`, data);
  return res.data;
};

export const getAprovadores = async () => {
  const res = await api.get('/select-aprovadores');
  return res.data;
};

export const getBases = async id => {
  const res = await api.get(`/select-user-bases?id_da_distribuidora=${id}`);
  return res.data;
};

export const getProdutos = async id => {
  const res = await api.get(`/produtos?id_da_distribuidora=${id}`);
  return res.data;
};

export const getTamanhos = async () => {
  const res = await api.get('/select-tamanhos');
  return res.data;
};

export const getLogs = async id => {
  const res = await api.get(`/logs-layout/${id}}`);
  return res.data;
};

export const showLayout = async id => {
  const res = await api.get(`/layout/${id}`);
  return res.data;
};

export const createCae = async data => {
  const res = await api.post('/create-cae', data);
  return res.data;
};

export const signExecutor = async id => {
  const res = await api.put(`/layout-executor/${id}`);
  return res.data;
};

export const signAprovador = async id => {
  const res = await api.put(`/layout-aprovador`, { ids: [id] });
  return res.data;
};

export const approveCae = async data => {
  const res = await api.put(`/cae/${data.id}`, data);
  return res.data;
};

export const verifyRascunho = async id => {
  const res = await api.post(`/create-layout`, {
    etapa: 0,
    id_da_distribuidora: id,
  });
  return res.data;
};

export const removeRascunho = async id => {
  const res = await api.delete(`/layout-delete-rascunho/${id}`);
  return res.data;
};

export const checkCNPJ = async cnpj => {
  const res = await api.get(`https://publica.cnpj.ws/cnpj/${cnpj}`, {
    timeout: 30000,
  });
  return res?.data;
};
