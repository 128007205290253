import api from 'services/api';
import { toast } from 'react-toastify';
import qs from 'qs';

// ------------------------------------ENDPOINTS DE LAYOUT-----------------------------------------//

// OK
export const requestOcorrencias = async query => {
  const res = await api.get(`/form-ocurrences`, { params: query });
  return res;
};

export const requestOcorrencia = async id => {
  const res = await api.get(`/form-ocurrence/${id}`);
  return res.data;
};

export const updateOcorrencia = async data => {
  const res = await api.put('/form-ocurrences', data);
  return res;
};

// OK
export const requestCards2 = async query => {
  const res = await api.get('/form-ocurrences-cards', { params: query });
  return res;
};

export const requestExcel = async query => {
  const res = await api.get('/excel/formOcurrences', { params: query });
  return res;
};
// OK
export const finishOcorrencia = async data => {
  const res = await api.put('/form-ocurrences/finish', { ids: data });
  return res.data;
};
// OK
export const deleteOcorrencia = async data => {
  const res = await api.put('/form-ocurrences/delete', { ids: data });
  return res.data;
};
// OK
export const selectResponsibles = async () => {
  const res = await api.get('/select-responsibles-ocorrencias');
  return res.data;
};

export const selectClients = async () => {
  const res = await api.get('/select-clients');
  return res.data;
};

export const requestOperations = async () => {
  const res = await api.get('/select-operations');
  return res.data;
};
// OK
export const reviewOcorrencia = async data => {
  const res = await api.put('/form-ocurrences', data);
  return res.data;
};

export const requestFormResponse = async (id, histId, alunoId, alunoTipo) => {
  const res = await api.get(
    `/my-form/${id}?${qs.stringify({
      selects: true,
      histId,
      alunoId,
      alunoTipo,
    })}`,
  );
  return res.data;
};
