import styled, { css } from 'styled-components';

export const NewItem = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-top: 10px;

    span {
      color: ${theme.palette.words.text.light};
      font-weight: 600;
      margin-left: 30px;
    }
  `}
`;
