import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// Componentes
import LineGraph from 'components/Graphs/LineGraph';
import BarGraph from 'components/Graphs/BarGraph';
import PieGraph from 'components/Graphs/PieGraph';
import Tab from 'components/Tabs';
import { trackEvent } from 'utils/mixpanel';

// Service
import { toast } from 'react-toastify/dist';
import { Grid } from '@mui/material';
import { requestViolacoes } from '../services';
import * as S from './styled';

const radarTabs = [
  { value: 'tipo', label: 'POR TIPO' },
  { value: 'criticidade', label: 'POR CRITICIDATE' },
  { value: 'status', label: 'POR STATUS' },
];

const GraficosViolacoesTelemetria = () => {
  const filter = useSelector(state => {
    return state.filter;
  });

  const user = useSelector(state => {
    return state.auth?.user?.user;
  });

  const [radar, setRadar] = useState(null);
  const [radarTab, setRadarTab] = useState('tipo');
  const [radarLoading, setRadarLoading] = useState(true);

  const [response, setResponse] = useState(null);
  const [responseLoading, setResponseLoading] = useState(true);

  const [criticidade, setCriticidade] = useState(null);
  const [criticidadeAxis, setCriticidadeAxis] = useState(['month']);
  const [criticidadeLoading, setCriticidadeLoading] = useState(true);

  const [status, setStatus] = useState(null);
  const [statusAxis, setStatusAxis] = useState(['month']);
  const [statusLoading, setStatusLoading] = useState(true);

  const [conclusao, setConclusao] = useState(null);
  const [conclusaoAxis, setConclusaoAxis] = useState(['month']);
  const [conclusaoLoading, setConclusaoLoading] = useState(true);

  const [tratamento, setTratamento] = useState(null);
  const [tratamentoAxis, setTratamentoAxis] = useState(['month']);
  const [tratamentoLoading, setTratamentoLoading] = useState(true);

  const fetchRadar = async query => {
    setRadarLoading(true);
    const res = await requestViolacoes('1', query);
    if (res.data?.data) setRadar(res.data.data);
    else if (res.data?.message) toast.error(res.data.message);
    setRadarLoading(false);
  };

  const fetchResponse = async query => {
    setResponseLoading(true);
    const res = await requestViolacoes('2', query);
    if (res.data?.data) setResponse(res.data.data);
    else if (res.data?.message) toast.error(res.data.message);
    setResponseLoading(false);
  };

  const fetchCriticidade = async query => {
    setCriticidadeLoading(true);
    const res = await requestViolacoes('3', query);
    if (res.data?.data) {
      setCriticidade(res.data.data.criticidade);
      setCriticidadeAxis(res.data.data.series);
    } else if (res.data?.message) toast.error(res.data.message);
    setCriticidadeLoading(false);
  };

  const fetchStatus = async query => {
    setStatusLoading(true);
    const res = await requestViolacoes('4', query);
    if (res.data?.data) {
      setStatus(res.data.data.status);
      setStatusAxis(res.data.data.series);
    } else if (res.data?.message) toast.error(res.data.message);
    setStatusLoading(false);
  };

  const fetchConclusao = async query => {
    setConclusaoLoading(true);
    const res = await requestViolacoes('5', query);
    if (res.data?.data) {
      setConclusao(res.data.data.conclusao);
      setConclusaoAxis(res.data.data.series);
    } else if (res.data?.message) toast.error(res.data.message);
    setConclusaoLoading(false);
  };

  const fetchTratamento = async query => {
    setTratamentoLoading(true);
    const res = await requestViolacoes('6', query);
    if (res.data?.data) {
      setTratamento(res.data.data.tempo);
      setTratamentoAxis(res.data.data.series);
    } else if (res.data?.message) toast.error(res.data.message);
    setTratamentoLoading(false);
  };

  useEffect(() => {
    const query = { ...filter, tip_options: radarTab };
    fetchRadar(query);
  }, [filter, radarTab]);

  useEffect(() => {
    fetchResponse(filter);
    fetchCriticidade(filter);
    fetchStatus(filter);
    fetchConclusao(filter);
    fetchTratamento(filter);
  }, [filter]);

  const renderRadarGraph = () => {
    const tabComponent = (
      <Tab
        width="350px"
        value={radarTab}
        items={radarTabs}
        onChange={(e, value) => {
          trackEvent(user, `GRÁFICO VIOLAÇÕES DE TELEMETRIA: ${value}`);
          setRadarTab(value);
        }}
      />
    );

    switch (radarTab) {
      case 'tipo':
        return (
          <div style={{ marginTop: '20px' }}>
            <BarGraph
              data={radar}
              colors="default"
              title="Radar de Desvios"
              loading={radarLoading}
              tooltip
              tabComponent={tabComponent}
            />
          </div>
        );

      case 'criticidade':
        return (
          <div style={{ marginTop: '20px' }}>
            <PieGraph
              data={radar}
              colors="critical"
              title={`Radar de desvios `}
              loading={radarLoading}
              tooltip
              legend
              tabComponent={tabComponent}
            />
          </div>
        );

      case 'status':
        return (
          <div style={{ marginTop: '20px' }}>
            <PieGraph
              data={radar}
              colors="critical"
              title={`Radar de desvios `}
              loading={radarLoading}
              tooltip
              legend
              tabComponent={tabComponent}
            />
          </div>
        );
    }
  };

  const renderTopResponsabilidadesGraph = () => (
    <div style={{ marginTop: '20px' }}>
      <BarGraph
        data={response}
        colors="default"
        title="Top 10 Responsáveis"
        loading={responseLoading}
        tooltip
      />
    </div>
  );

  const renderCriticidadeGraph = () => (
    <div style={{ marginTop: '20px' }}>
      <LineGraph
        data={criticidade}
        colors="critical"
        title="Histórico Criticidade/Mês"
        series={criticidadeAxis}
        legend={criticidadeAxis}
        loading={criticidadeLoading}
        tooltip
      />
    </div>
  );

  const renderStatusGraph = () => (
    <div style={{ marginTop: '20px' }}>
      <LineGraph
        data={status}
        colors="critical"
        title="Histórico Status/Mês"
        series={statusAxis}
        legend={statusAxis}
        loading={statusLoading}
        tooltip
      />
    </div>
  );

  const renderConclusaoGraph = () => (
    <div style={{ marginTop: '20px' }}>
      <LineGraph
        data={conclusao}
        colors="critical"
        title="Histórico Conclusão/Mês"
        series={conclusaoAxis}
        loading={conclusaoLoading}
        tooltip
      />
    </div>
  );

  const renderTratamentoGraph = () => (
    <div style={{ marginTop: '20px' }}>
      <LineGraph
        data={tratamento}
        colors="critical"
        title="Histórico Tempo Tratamento"
        series={tratamentoAxis}
        loading={tratamentoLoading}
        tooltip
      />
    </div>
  );

  const renderMain = () => (
    <>
      <S.Container>
        <S.Header>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '15px',
            }}
          >
            <S.Title>Dashboard Violações Telemetria</S.Title>
          </div>
        </S.Header>
        <S.Main>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} xl={6}>
              {renderRadarGraph()}
            </Grid>

            <Grid item xs={12} md={6} xl={6}>
              {renderTopResponsabilidadesGraph()}
            </Grid>

            <Grid item xs={12} md={6} xl={6}>
              {renderCriticidadeGraph()}
            </Grid>

            <Grid item xs={12} md={6} xl={6}>
              {renderStatusGraph()}
            </Grid>

            <Grid item xs={12} md={6} xl={6}>
              {renderConclusaoGraph()}
            </Grid>

            <Grid item xs={12} md={6} xl={6}>
              {renderTratamentoGraph()}
            </Grid>
          </Grid>
        </S.Main>
      </S.Container>
    </>
  );

  return renderMain();
};

export default GraficosViolacoesTelemetria;
