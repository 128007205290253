// React
import { useLocation } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Redux
import { signOut } from 'store/modules/auth/saga';
import { setFilter } from 'store/modules/filter/actions';
import { updateVersion } from 'store/modules/version/actions';
import { setCurrentPlan } from 'store/modules/pageMap/actions';

// Utils
import { auth } from 'utils/firebase';
import { trackEvent } from 'utils/mixpanel';
import { formatarTempo } from 'utils/helpers';

export const useTemplate = () => {
  // Refs
  const startTimeRef = useRef(null);

  // Hooks
  const dispatch = useDispatch();
  const path = useLocation().pathname;

  // Selectors
  const filter = useSelector(state => state.filter);
  const version = useSelector(state => state.version);
  const user = useSelector(state => state.auth.user?.user);
  const { currentPlan, pageMap } = useSelector(state => state.pageMap);

  // States
  const [currentUser, setCurrentUser] = useState(null);

  const checkRoute = (page, key) => {
    const excludes = ['?', ':'];

    const pure = route => {
      return route
        .split('/')
        .map(part =>
          excludes.some(item => part.includes(item)) || +part ? '' : part,
        )
        .join('/');
    };

    const pagePure = pure(page);
    const keyPure = pure(key);
    return pagePure === keyPure;
  };

  useEffect(() => {
    dispatch(updateVersion());
    let ordered = [];

    // Adiciona o plano atual em primeiro
    if (pageMap) {
      pageMap.forEach(env => {
        if (env.active && currentPlan === env.id_do_ambiente) {
          ordered.push(env);
        }
      });

      // Adiciona os planos de mesmo ambiente
      pageMap.forEach(env => {
        if (env.active && env.id_do_ambiente === currentPlan) {
          ordered.push(env);
        }
      });

      // Adiciona os planos ativos de outros ambientes
      const restPages = pageMap
        .filter(env => env.active && currentPlan !== env.id_do_ambiente)
        .sort((a, b) => {
          return a.id_do_ambiente - b.id_do_ambiente;
        });

      ordered = [...ordered, ...restPages];
    }

    ordered?.forEach(env => {
      if (env.active) {
        env.paginas.map(pagina => {
          const check = checkRoute(path, pagina.pagina);
          check &&
            currentPlan !== env.id_do_ambiente &&
            env.id_do_ambiente &&
            dispatch(setCurrentPlan(env.id_do_ambiente));
        });
      }
    });

    if (!currentUser)
      auth.onAuthStateChanged(userFirebase => {
        if (!userFirebase && user) {
          dispatch({ type: '@DESTROY_SESSION' });
          signOut();
        }
        setCurrentUser(userFirebase);
      });

    startTimeRef.current = new Date();
    return () => {
      const endTime = new Date();
      const duration = endTime - startTimeRef.current;
      const time = formatarTempo(duration);
      trackEvent(user, `Página: ${location.pathname}`, time);
    };
  }, [path, pageMap]);

  useEffect(() => {
    if (
      version.version?.id &&
      version.old_version?.id &&
      version.version.id !== version.old_version?.id &&
      version.version.need_reset
    ) {
      dispatch({ type: '@DESTROY_SESSION' });
      signOut();
    }
  }, [version]);

  return {
    currentUser,
    user,
    contract: auth?.provider
      ? auth?.distribuidora?.contrato
      : auth?.filial?.empresa?.contrato,
  };
};
