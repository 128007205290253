import api from 'services/api';

export const requireInfos = async () => {
  const res = await api.get('/configuracoes/empresa');
  return res.data;
};

export const updateInfos = async data => {
  const res = await api.put('/configuracoes/update_empresa', data);
  return res.data;
};

export const requireProvider = async id => {
  const { data } = await api.get(`/distribuidora/${id}`);
  return data.data;
};

export const updateProvider = async (id, data) => {
  const payload = {
    distribuidora: {
      nome: data.nome,
      logo_url: data.logo_url,
      razao_social: data.razao_social,
      telefone: data.telefone_do_contato,
      inscricao_municipal: data.inscricao_municipal,
      inscricao_estadual: data.inscricao_estadual,
    },
    usuario: {
      nome: data.nome_do_contato,
      email: data.email_do_contato,
    },
    endereco: { ...data.endereco, id: data.id_do_endereco },
  };

  const res = await api.put(`/distribuidora/${id}`, payload);
  return res.data;
};
