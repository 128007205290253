import styled, { css } from 'styled-components';
import Select from '@mui/material/Select';

const Container = styled.div`
  position: relative;
  transition: all ease 0.9s;
`;

const Label = styled.p`
  ${({ theme, ...props }) => css`
    color: ${props.visible
      ? theme.palette.words.subtitle.natural
      : theme.palette.semantics.feedback.unknown.dark};
    font: normal medium 14px/20px Texta;
    margin-bottom: 5px;
    position: ${props.visible ? 'static' : 'absolute'};
    margin-top: ${props.visible ? '0px' : '15px'};
    margin-left: ${props.visible ? '10px' : '10px'};

    transition: all ease 1s;
  `}
`;

const Selecteds = styled.div`
  color: ${({ theme }) => theme.palette.semantics.feedback.unknown.dark};
  font: normal medium 14px/20px Texta;
  margin-bottom: 5px;
  position: absolute;
  margin-top: 15px;
  margin-left: 10px;
  opacity: ${props => props.opacity};
  transition: all ease 0.5s;
`;

const Required = styled.span`
  font-size: 20px;
  font-weight: 500;
  color: red;
`;

const SearchBox = styled.div`
  width: 100%;
  padding: 3px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  position: relative;

  .searchIcon {
    margin-left: -30px;
  }
`;

export const LineBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  position: relative;
`;

const SearchInput = styled.input`
  ${({ theme }) => css`
    width: 80%;
    padding: 5px 8px;
    border: none;
    border: 1px solid ${theme.palette.semantics.feedback.unknown.natural};
    border-radius: 5px;
    font-size: 16px;

    &::placeholder {
      font-size: 16px;
    }

    &:focus {
      border: 1px solid ${theme.palette.brand.secondary.natural};
    }
  `}
`;
const ClearSelection = styled.div`
  ${({ theme }) => css`
    padding: 5px;
    text-align: center;
    color: ${theme.palette.semantics.feedback.unknown.dark};
    font-weight: 600;
    width: 100%;
    background-color: ${theme.palette.brand.secondary.background};
    cursor: pointer;
  `}
`;

const OptionsArea = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
  transform: translate(10px, 10px);
`;

const LabelSearch = styled.label`
  display: flex;
  justify-content: start;
  align-items: center;

  span {
    margin-left: 15px;
    font-weight: 700;
    color: ${({ theme }) => theme.palette.semantics.feedback.unknown.dark};
  }
`;

const EmptyOptions = styled.span`
  text-align: center;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  color: ${({ theme }) => theme.palette.words.placeholder.light};
`;

export {
  Label,
  Required,
  SearchBox,
  SearchInput,
  LabelSearch,
  OptionsArea,
  ClearSelection,
  Selecteds,
  Container,
  EmptyOptions,
};
