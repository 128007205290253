export const tabs = [
  {
    value: 0,
    label: 'Geral',
  },
  {
    value: 1,
    label: 'Usuários',
  },
];

export const justifications = [
  { value: 'Férias', label: 'Férias' },
  { value: 'Atestado', label: 'Atestado' },
  { value: 'Afastamento', label: 'Afastamento' },
  { value: 'OUTRO', label: 'Outro motivo' },
];
