import { useTheme } from 'styled-components';
import { toast } from 'react-toastify';
import React, { useState, useEffect } from 'react';

// Components
import { Switch } from 'components/Inputs/Switch';
import SelectInput from './Select';
import GhostButton from 'components/Buttons/Ghost';
import ConfirmModal from 'components/ConfirmModal';
import DefaultButton from 'components/Buttons/Default';

// Material UI
import { EditOutlined } from '@mui/icons-material';
import { Icon, IconButton, Modal, Grid } from '@mui/material';
import * as S from './styled';

// Services
import * as services from '../services';

const ModalAddFilial = ({ open, handleClose, updateData, data }) => {
  const theme = useTheme();
  const [nome, setNome] = useState('');
  const [status, setStatus] = useState(true);
  const [confirmAdd, setConfirmAdd] = useState(false);
  const [margin, setMargin] = useState('-550px');
  const [select, setSelect] = useState([]);
  const [loading, setLoading] = useState(false);

  // Modal Controll
  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setMargin('0px');
      }, 10);
    }
  }, [open, data]);

  const behaviorCloseModal = () => {
    setMargin('-550px');
    setTimeout(() => {
      handleClose();
    }, 380);
  };

  // Add Area
  const verifyFields = () => {
    if (!nome) {
      toast.error('Selecione um cliente');
      return;
    }

    setConfirmAdd(true);
  };

  const confirm = async () => {
    setLoading(true);
    const res = await services.createClientes({
      distribuidora_id: nome,
      status,
    });

    if (res.success) {
      toast.success(res.message);
      setConfirmAdd(false);
      updateData();
      handleClose();
    } else {
      toast.error(res.message);
      setConfirmAdd(false);
    }
    setLoading(false);
  };

  // ------------------------------REQUESTS-----------------------------------//
  useEffect(() => {
    fetchClientes();
  }, []);

  const fetchClientes = async () => {
    setLoading(true);
    const res = await services.selectClients();
    const formatedRes = [];
    for (const i in res) {
      formatedRes.push({
        name: res[i].nome,
        value: res[i].id,
        image: res[i].logo_url,
      });
    }
    setSelect(formatedRes);
    setLoading(false);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={behaviorCloseModal}
        disableEscapeKeyDown={false}
      >
        <S.Container margin={margin}>
          <S.Header>
            <div style={{ alignItems: 'center' }}>
              <Icon
                sx={{ color: theme.palette.brand.secondary.natural }}
                fontSize="medium"
              >
                add_circle_outline
              </Icon>
              <h2>Adicionar Novo Cliente</h2>
            </div>

            <IconButton size="small" onClick={behaviorCloseModal}>
              <Icon sx={{ color: theme.palette.words.subtitle.natural }}>
                close
              </Icon>
            </IconButton>
          </S.Header>

          <S.Main>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <S.Section>INFORMAÇÕES DE PERFIL</S.Section>
              </Grid>

              <Grid item xs={12} sm={12}>
                <SelectInput
                  required
                  refetch={fetchClientes}
                  handleChange={e => setNome(e.target.value)}
                  name="clientes"
                  label="Clientes"
                  data={select}
                  value={nome}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <Switch
                  check={status}
                  setCheckbox={setStatus}
                  textOn="Cliente Ativo"
                  textOff="Cliente Inativo"
                />
              </Grid>
            </Grid>
          </S.Main>

          <S.Footer>
            <div>
              <GhostButton onClick={behaviorCloseModal} size="medium">
                CANCELAR
              </GhostButton>

              <DefaultButton
                onClick={verifyFields}
                size="medium"
                sx={{ marginLeft: '20px' }}
              >
                Salvar
              </DefaultButton>
            </div>
          </S.Footer>
        </S.Container>
      </Modal>

      {/* Confirmação de adição */}
      <ConfirmModal
        open={confirmAdd}
        handleClose={() => setConfirmAdd(false)}
        title="Deseja confirmar criação do cliente?"
        titleIcon={
          <EditOutlined
            sx={{ color: theme.palette.brand.secondary.natural }}
            fontSize="medium"
          />
        }
        subtitle="Atenção! Verifique todos os campos antes de confirmar a operação."
        buttonText="Confirmar"
        onClick={() => confirm()}
        loading={loading}
      />
    </>
  );
};

export default ModalAddFilial;
