import styled, { css } from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;

  .menu-item {
    width: 300px;
    border: 1px SOLID #95aac94d;
    border-radius: 4px;
    padding: 4px;
    margin: 4px;
    overflow: hidden;
    color: #4b5166;
  }
`;

export const BoxTags = styled.div`
  margin-top: 10px;
  align-items: center;
  justify-content: start;
  display: flex;
  flex-wrap: wrap;
  gap: 1;
`;

const Label = styled.p`
  ${({ theme }) => theme.palette.words.label.natural};
  font: normal medium 14px/20px Texta;
  margin-bottom: 5px;
`;

const Required = styled.span`
  font-size: 20px;
  font-weight: 500;
  color: red;
`;

const SearchBox = styled.div`
  width: 100%;
  background: #edf2f9;
  padding: 0 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  position: relative;
`;

const SearchInput = styled.input`
  ${({ theme }) => css`
    width: 100%;
    padding: 5px 8px;
    border: none;
    background-color: inherit;
    font-size: 16px;

    &::placeholder {
      font-size: 16px;
    }
  `}
`;

export { Label, Required, SearchBox, SearchInput, Container };
