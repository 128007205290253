import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const BoxArea = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${theme.palette.system.overlay};
    border: 1px solid ${theme.palette.system.divider};
    border-radius: 5px;
    width: 100%;
    padding: 2rem 4rem;
    margin-top: 1.5rem;
  `}
`;

export const Actions = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
`;

export const Monitor = styled.div`
  position: relative;
  margin: 2rem 0 3rem 0;
  width: 100%;
  max-width: 48rem;
  height: 27rem;
  border: 1rem solid #cccccc;
  border-radius: 1rem;

  ::after {
    position: absolute;
    content: '';
    bottom: -2rem;
    left: 50%;
    translate: -50%;
    width: 10%;
    border-bottom: 1.5rem solid #cccccc;
    border-left: 4rem solid transparent;
    border-right: 4rem solid transparent;
    border-radius: 1rem;
  }

  iframe {
    width: 100%;
    height: 100%;
    zoom: 0.5;
    -moz-transform: scale(0.5);
    -moz-transform-origin: 0 0;
  }
`;
