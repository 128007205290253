// Styles
import { useTheme } from 'styled-components';

// React
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setFilter } from 'store/modules/filterPad/actions';

// Components
import Tabs from 'components/Tabs';
import ReviewModal from './ReviewModal';
import ExcelModal from 'components/ExcelModal';
import ConfirmModal from 'components/ConfirmModal';
import GhostButton from 'components/Buttons/Ghost';
import CardIndicadores from 'components/Cards/Indicador';
import { DefaultTable } from 'components/_Table/templates/default';
import { ReactComponent as file } from 'images/icons/sidebar/file.svg';

// Components MUI
import Grid from '@mui/material/Grid';
import { Divider } from '@mui/material';
import { SaveAlt } from '@mui/icons-material';

// Utils
import { trackEvent } from 'utils/mixpanel';
import { formatNameDate } from 'utils/dates';
import ExportToExcel from 'utils/exportToCvs';

// Constants
import { columns } from './columns';
import { fields, resetExcelFields } from './constants';

// Services
import * as request from './services';

// Styles
import * as S from './styled';
import { useQuery } from 'react-query';

const initialize = [{}, {}];

// ---------------------------------------OCCURRENCE PAGE--------------------------------------------//

const Ocorrencias = () => {
  const theme = useTheme();
  // GENERAL STATES
  const [query, setQuery] = useState(null);
  const [selectedOcorrencias, setSelectedOcorrencias] = useState(null);

  // MODAL STATES
  const [openConfirmFinishModal, setOpenConfirmFinishModal] = useState(false);
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);

  // EXCEL STATES
  const [excelFields, setExcelFields] = useState(fields);
  const [loadingExcel, setLoadingExcel] = useState(false);
  const [openExcelModal, setOpenExcelModal] = useState(false);

  // NAVIGATE
  const navigate = useNavigate();

  // REDUX
  const dispatch = useDispatch();
  const filter = useSelector(state => {
    return state.filter;
  });
  const filterPad = useSelector(state => {
    return state.filterPad;
  });
  const user = useSelector(state => {
    return state.auth?.user?.user;
  });
  let isConvidado = false;
  if (+user.nivel === 4) isConvidado = true;

  const handleConvidado = () => {
    toast.error('Você não tem permissão para executar essa ação!');
  };
  // --------------------TOP PAGE-----------------------------//
  const renderTop = () => (
    <>
      <S.TitleWrapper>
        <h1>Ocorrências</h1>
        <div>
          <GhostButton
            startIcon={<SaveAlt />}
            size="medium"
            onClick={() =>
              !isConvidado ? setOpenExcelModal(true) : handleConvidado()
            }
          >
            EXPORTAR
          </GhostButton>
        </div>
      </S.TitleWrapper>
    </>
  );

  // ------------------- TABS -------------------------------//
  const handleTabChange = (_, newValue) => {
    trackEvent(user, `OCORRÊNCIAS: TABS ${tabs[newValue].status}`);
    dispatch(
      setFilter({
        ...filterPad,
        tab: newValue,
      }),
    );
  };

  // ---------------------CARDS -----------------------------//

  const [selectedCard, setSelectedCard] = useState(null);
  const [cardIds, setCardIds] = useState(null);

  const handleClickCard = (type, ids) => {
    if (type === selectedCard) {
      setSelectedCard(null);
      setCardIds(null);
    } else {
      setSelectedCard(type);
      setCardIds(ids);
    }
  };

  const tabs = [
    {
      value: 0,
      label: 'Pendente',
      status: 'PENDENTE',
    },
    {
      value: 1,
      label: 'Finalizada',
      status: 'FINALIZADO',
    },
    {
      value: 2,
      label: 'Deletada',
      status: 'DELETADO',
    },
  ];

  // ---------------------TABLE-----------------------------//

  const [selectedIds, setSelectedIds] = useState([]);

  useEffect(() => {
    setSelectedCard(null);
    setCardIds(null);
  }, [filterPad.tab]);

  // Excel Fields
  const handleRequestExcel = async () => {
    setLoadingExcel(true);

    const newFields = excelFields.filter(item => item.selected === true);
    const formatedDate = formatNameDate(new Date());

    const newQuery = { ...filter, ...filterPad, excelFields: newFields };

    const res = await request.requestExcel(newQuery);
    if (res.data && res.data?.data?.excel)
      ExportToExcel({
        excel: res.data.data.excel,
        name: `ocorrencias_formularios${formatedDate}`,
      });
    else if (res.data.message) toast.error(res.data?.message);

    setExcelFields(resetExcelFields(fields));
    setLoadingExcel(false);
    setOpenExcelModal(false);
  };

  // Navegação para novas páginas
  const handleNavigate = ids => {
    navigate(`/formularios/ocorrencias/${ids}`);
  };

  const openNewTab = id => {
    window.open(`/formularios/ocorrencias/${id}`);
  };

  // Finalização de ocorrências
  const handleFinishOcorrencies = async id => {
    setSelectedIds(id);
    setOpenConfirmFinishModal(true);
  };

  const finishOcorrencies = async () => {
    const res = await request.finishOcorrencia(selectedIds);
    if (res.success) {
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }
    fetchData();
    fetchCards();
    setOpenConfirmFinishModal(false);
    setSelectedIds([]);
  };

  // Deletar ocorrências
  const handleDeleteOcorrencies = async id => {
    setSelectedIds(id);
    setOpenConfirmDeleteModal(true);
  };

  const deleteOcorrencies = async () => {
    const res = await request.deleteOcorrencia(selectedIds);

    if (res.success) {
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }
    fetchData();
    fetchCards();
    setOpenConfirmDeleteModal(false);
    setSelectedIds([]);
  };

  // Revisar(atualizar) ocorrências
  const [reviewId, setReviewId] = useState(null);
  const [loadingReview, setLoadingReview] = useState(false);

  const handleReviewOcorrencies = async id => {
    setReviewId(id);
  };

  const reviewOcorrencies = async data => {
    setLoadingReview(true);

    const res = await request.reviewOcorrencia(data);
    if (res.success) {
      toast.success(res.message);
    } else if (res.message) toast.error(res.message);

    fetchData();
    fetchCards();
    setReviewId(null);
    setLoadingReview(false);
  };

  // Actions
  const actions = [
    {
      title: 'Finalizar',
      function: id => handleFinishOcorrencies([id]),
      visible: item => item.status === 'PENDENTE',
    },
    {
      title: 'Alterar responsável',
      function: id => {
        trackEvent(user, 'OCORRêNCIAS:ALTERAR RESPONSÁVEL');
        handleReviewOcorrencies([id]);
      },
      visible: item => item.status === 'PENDENTE',
    },
    {
      title: 'Deletar',
      function: id => handleDeleteOcorrencies([id]),
      visible: item => item.status !== 'DELETADO',
    },
    {
      title: 'Ver detalhe',
      function: id => {
        trackEvent(user, 'OCORRÊNCIAS: VER DETALHES');
        handleNavigate(id);
      },
    },
    {
      title: 'Abrir em nova guia',
      function: id => openNewTab(id),
    },
  ];

  // Bulk
  const bulk = [
    {
      title: 'Finalizar',
      function: () => handleFinishOcorrencies(selectedOcorrencias),
      visible: () => filterPad.tab === 0,
    },
    {
      title: 'Deletar',
      function: () => handleDeleteOcorrencies(selectedOcorrencias),
      visible: () => filterPad.tab < 2,
    },
    {
      title: 'Alterar responsável',
      function: () => {
        trackEvent(user, 'OCORRêNCIAS:ALTERAR RESPONSÁVEL');
        handleReviewOcorrencies(selectedOcorrencias);
      },
      visible: () => filterPad.tab === 0,
    },
  ];

  // ---------------------GENERAL REQUEST-------------------//

  const {
    refetch: fetchData,
    isLoading: loadingOcorrencias,
    isFetching,
    data: resData,
  } = useQuery(
    ['ocorrências-index', query],
    () => query && request.requestOcorrencias(query),
    {
      refetchOnWindowFocus: false,
      onSuccess: () => resetTable && setResetTable(false),
    },
  );

  const {
    refetch: fetchCards,
    isLoading: loadingCards,
    data: resCards,
  } = useQuery(
    ['ocorrências-cards', query],
    () => query && request.requestCards2(query),
    {
      refetchOnWindowFocus: false,
    },
  );

  const { data: resResponsible } = useQuery(
    ['responsible-cards'],
    () => request.selectResponsibles(),
    {
      refetchOnWindowFocus: false,
    },
  );

  // INDICATORS
  const initial = useRef(true);
  const [resetTable, setResetTable] = useState(false);
  useEffect(() => {
    if (initial.current) {
      initial.current = false;
      return;
    }

    setResetTable(true);
  }, [filter, filterPad, selectedCard]);

  return (
    <Grid container>
      <Grid marginBottom={3} item md={12}>
        {renderTop()}
        <Tabs
          value={filterPad.tab}
          items={tabs}
          onChange={handleTabChange}
          last
          disabled={loadingOcorrencias}
        />
        <Divider />
      </Grid>
      <>
        <Grid marginBottom={3} item md={12}>
          <Grid item>
            <Grid container spacing={2}>
              {(resCards?.data?.data || initialize)?.map((item, k) => {
                let md = 4;

                if (12 / resCards?.data?.data?.length < 4)
                  md = 12 / resCards?.data?.data?.length;
                return (
                  <Grid
                    item
                    md={md}
                    key={k}
                    style={{ fontSize: '13px', fontWeight: 600 }}
                  >
                    <CardIndicadores
                      value={item.value}
                      icon={item.icon}
                      text={item?.text}
                      border={`1px solid ${theme.palette.brand.primary.light}4D`}
                      handleClick={() => handleClickCard(item?.type, item?.ids)}
                      selected={selectedCard === item.type}
                      disabled={isFetching}
                      loading={loadingCards}
                      smalltype={
                        resCards?.data?.data.length > 3 ? 'true' : undefined
                      }
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12}>
          <DefaultTable
            data={resData?.data?.rows || []}
            columns={columns}
            setSelectedRows={setSelectedOcorrencias}
            loading={loadingOcorrencias}
            pageCount={resData?.data?.count}
            local={false}
            bulk={bulk}
            actions={actions}
            reset={resetTable}
            onClickRow={handleNavigate}
            searchEvent={search =>
              trackEvent(user, 'Busca Formulários Ocorrências', null, search)
            }
            setQuery={q =>
              setQuery({
                ...q,
                ...filter,
                ...filterPad,
                cardIds,
                card: selectedCard,
                status: tabs?.[filterPad?.tab]?.status || undefined,
              })
            }
            sortBy={{ id: 'id', order: 'ASC' }}
            placeholder="Buscar por Nome"
            empty={{
              title: 'Você não tem nenhuma ocorrência.',
              description: 'Verifique os filtros selecionados.',
            }}
          />
        </Grid>
      </>
      <ConfirmModal
        handleClose={() => setOpenConfirmFinishModal(false)}
        open={!!openConfirmFinishModal}
        title="Deseja finalizar esta(s) ocorrência(s)?"
        subtitle="O status desta(s) ocorrência(s) será atualizado para Finalizado."
        buttonText="Confirmar"
        onClick={() => finishOcorrencies()}
      />
      <ConfirmModal
        handleClose={() => setOpenConfirmDeleteModal(false)}
        open={!!openConfirmDeleteModal}
        title="Deseja excluir ocorrência(s)?"
        subtitle="Seus pontos deixarão de contar para os respectivos motoristas."
        buttonText="Confirmar"
        onClick={() => deleteOcorrencies()}
      />
      {reviewId && (
        <ReviewModal
          handleConfirm={reviewOcorrencies}
          ids={reviewId}
          handleClose={() => setReviewId(null)}
          responsaveis={resResponsible?.data?.map(item => ({
            value: item.id,
            name: item.nome,
          }))}
          loading={loadingReview}
        />
      )}

      {openExcelModal && (
        <ExcelModal
          onClick={handleRequestExcel}
          open={openExcelModal}
          handleClose={() => setOpenExcelModal(false)}
          title="Selecionar os campos de Excel"
          titleIcon={file}
          subtitle="Selecionar abaixo:"
          setData={setExcelFields}
          data={excelFields}
          loading={loadingExcel}
        />
      )}
    </Grid>
  );
};

export default Ocorrencias;
