import React from 'react';
import { useQuery } from 'react-query';

import * as S from './styled';

import { getAtividades } from '../../services';
import { Skeleton } from '@mui/material';
import { useTheme } from 'styled-components';

export const Atividades = ({ id, filter }) => {
  const theme = useTheme();
  const { isLoading, data = [] } = useQuery(
    ['equipe-atividades', id, filter],
    () => getAtividades(id, filter),
    {
      refetchOnWindowFocus: false,
    },
  );

  return (
    <S.Card>
      <S.Title>Registro de atividades</S.Title>
      <S.ListContainer>
        <ul>
          {isLoading ? (
            <S.ListItem>
              <Skeleton height={50} animation="wave" variant="rounded" />
            </S.ListItem>
          ) : (
            data.map((item, index) => {
              const regex = /"(https?:\/\/[^\s]+\.com[^\s]*)"/;
              const match = item.descricao.match(regex);
              if (match) {
                const url = match[1];
                const textBefore = item.descricao
                  .split(url)[0]
                  .replace(/"/g, '');
                const textAfter = item.descricao
                  .split(url)[1]
                  .replace(/"/g, '');
                return (
                  <S.ListItem key={index}>
                    <div>
                      {item.evento}{' '}
                      <span>
                        {textBefore}{' '}
                        <a
                          style={{
                            textDecoration: 'none',
                            color: theme.palette.brand.secondary.natural,
                          }}
                          href={url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Ver arquivo
                        </a>{' '}
                        {textAfter}
                      </span>
                    </div>
                    <div>{new Date(item.data).toLocaleString()} </div>
                  </S.ListItem>
                );
              }
              return (
                <S.ListItem key={index}>
                  <div>
                    {item.evento} <span>{item.descricao}</span>
                  </div>
                  <div>{new Date(item.data).toLocaleString()} </div>
                </S.ListItem>
              );
            })
          )}
        </ul>
      </S.ListContainer>
    </S.Card>
  );
};
