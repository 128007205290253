import styled, { css } from 'styled-components';
import { Collapse } from '@mui/material';

export const Box = styled.div`
  ${({ theme, disabled }) => css`
    pointer-events: ${disabled ? 'none' : 'auto'};
    width: 100%;
    background-color: ${theme.palette.system.overlay};
    border: 2px solid ${theme.palette.system.border};
    border-radius: 4px;
    padding: 30px;
    margin-top: 20px;
  `}
`;

export const ContentLimit = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    border: 1px solid ${theme.palette.brand.primary.light};
    border-radius: 5px;
  `}
`;

export const BoxConfigure = styled.div`
  ${({ theme, repeat }) => css`
    min-height: 47px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    border-bottom: 1px solid ${theme.palette.brand.primary.light};

    &.repetable {
      opacity: ${repeat ? 1 : 0.5};
    }

    .textArea {
      font-size: 17px;
      font-weight: 600;
      color: ${theme.palette.words.title.natural};
      display: flex;
      align-items: center;

      .tooltip {
        margin-left: 10px;
        cursor: pointer;
      }
    }
  `}
`;

export const BoxCollapse = styled.div`
  ${({ theme, hasOcurrence }) => css`
    padding: 15px 20px;
    min-height: 50px;
    cursor: pointer;
    border-bottom: 1px solid ${theme.palette.brand.primary.light};

    &.lastCollapse {
      border-bottom: none;
    }

    .clickColl {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      opacity: ${hasOcurrence ? 1 : 0.5};

      .leftSide {
        display: flex;
        align-items: center;
        justify-content: start;

        .textArea {
          font-size: 17px;
          font-weight: 600;
          color: ${theme.palette.words.title.natural};
        }
      }

      .rigthSide {
        display: flex;
        align-items: center;

        .textArea {
          margin-right: 13px;
          font-weight: 500;
          text-transform: capitalize;
        }

        .number {
          text-transform: lowercase;
        }
      }
    }
  `}
`;

export const CollapseMu = styled(Collapse)`
  margin-top: 15px;

  .totalContentScroll {
    overflow-y: scroll;
    max-height: 400px;
  }

  .boxTime {
    display: flex;
    width: 100%;
    align-items: end;
    justify-content: start;

    span {
      margin-bottom: 20px;
      margin-left: -5px;
    }
  }
`;

export const CollapseItem = styled.div`
  ${({ theme, checked }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    background-color: ${checked
      ? theme.palette.brand.primary.background
      : theme.palette.system.transparent};
    border-radius: 4px;
    transition: all ease 0.3s;

    .infoCheck {
      flex: 3;
      display: flex;
      align-items: center;
      justify-content: start;

      .boxUser {
        display: flex;
        align-items: center;
        justify-content: start;
        margin-left: 25px;

        .nameElement {
          margin-left: 20px;
          font-size: 17px;
          font-weight: 600;
          color: ${theme.palette.words.title.natural};
          text-transform: capitalize;
        }
      }

      .imageArea {
        width: 38px;
        height: 38px;
        border-radius: 38px;
        border: 2px solid ${theme.palette.brand.secondary.natural};
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          width: 100%;
          height: 100%;
          background-color: ${theme.palette.brand.primary.background};
          border-radius: 38px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-transform: uppercase;
          font-weight: 700;
          color: ${theme.palette.words.title.natural};
        }
      }
    }

    .locale {
      flex: 1;
      font-weight: 600;
      color: ${theme.palette.words.title.natural};
    }

    .workArea {
      flex: 1;
      font-weight: 600;
      color: ${theme.palette.words.title.natural};
    }
  `}
`;
