import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`;

export const Title = styled.h2`
  ${({ theme }) => css`
    font-size: 20px;
    color: ${theme.palette.words.title.natural};
    font-weight: 600;
  `}
`;
