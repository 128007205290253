import styled, { css } from 'styled-components';

export const Box = styled.div`
  ${({ theme }) => css`
    width: 100%;
    background-color: ${theme.palette.system.overlay};
    border: 2px solid ${theme.palette.system.border};
    border-radius: 4px;
    padding: 30px;
    margin-top: 20px;
  `}
`;

export const Log = styled.div`
  ${({ theme }) => css`
    display: flex;

    .icons {
      display: flex;
      flex-direction: column;
      align-items: center;

      .circleIcon {
        width: 42px;
        height: 42px;
        border: 1px solid ${theme.palette.semantics.feedback.unknown.light};
        border-radius: 42px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .bar {
        height: 37px;
        width: 2px;
        background-color: ${theme.palette.semantics.feedback.unknown.light};
        margin: 8px 0px;
      }
    }

    .content {
      display: flex;
      align-items: start;
      justify-content: start;

      .information {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        color: ${theme.palette.words.subtitle.natural};

        .topArea {
        }

        .bottomArea {
          font-size: 14px;
        }
      }
    }
  `}
`;

export const LoadingBox = styled.div`
  position: relative;
  min-height: 500px;
`;
