import { getResponsaveis, getCargas } from 'constants/_SERVICES/user';

// Pages
import AvaliacoesDescarga from 'pages/AvaliacoesDescarga';
import CriacaoAvaliacaoDescarga from 'pages/AvaliacoesDescarga/Criacao';
import TemplateDescargaEvaluation from 'pages/AvaliacoesDescarga/Detalhe';
import AvaliacoesDescargaPdf from 'pages/AvaliacoesDescarga/Detalhe/ModelPdf';

export default {
  '/avaliacoes-descarga': {
    date: true,
    defaults: ['filials', 'clients'],
    pageFilterName: 'filterAvaliacoesDescarga',
    filters: [
      {
        name: 'responsavel',
        placeholder: 'Filtrar por Responsável',
        section: 'Avaliação',
        mode: 'multiple',
        data: async () => getResponsaveis(),
      },
      {
        name: 'carga',
        placeholder: 'Filtrar por Carga',
        section: 'Avaliação',
        mode: 'multiple',
        data: async () => getCargas(),
      },
    ],
    page: <AvaliacoesDescarga />,
  },

  '/avaliacoes-descarga/:id': {
    back: -1,
    id: true,
    status: true,
    defaults: [],
    title: 'Avaliação Descarga',
    page: <TemplateDescargaEvaluation />,
  },

  '/avaliacoes-descarga/criar': {
    back: -1,
    defaults: [],
    title: 'Criar Avaliação Descarga',
    page: <CriacaoAvaliacaoDescarga />,
  },

  '/avaliacoes-descarga/pdf/:id': {
    back: -1,
    title: 'Avaliação Descarga',
    id: true,
    defaults: [],
    page: <AvaliacoesDescargaPdf />,
  },
};
