import { Divider } from '@mui/material';
import { useSelector } from 'react-redux';

// Columns
import Table from 'components/Table';
import { formatNewHour } from 'utils/dates';
import * as S from './styled';
import { columnsSmall } from './columns';

export const ExportCompaniesPdf = ({ cont, data, printRef }) => {
  let dataTable = data?.alunos || [];
  const cards = data?.cards || null;

  const logo = require('images/onisys.png');
  const capa =
    'https://firebasestorage.googleapis.com/v0/b/onisysweb.appspot.com/o/capacitacao%2Finfo%2Fcapas%2F1673467756358%2F2023-01-11_17-09-19_Capa_Conteudo_Capacitacao.jpg?alt=media&token=3ca2cd3b-f1c8-438a-a277-88580f7cbdbd';
  const { id } = useSelector(state => state.conteudoProvider);

  // -------------------------------------------------FORMAT TABLE--------------------------------------------------//
  const formatTable = arr => {
    arr.forEach(i => {
      if (i.identifier === 'nome') {
        i.Cell = function ({ row }) {
          return (
            <S.ColDriver className="flex">
              <S.Paragraph className="normal name">
                {row.original.nome.slice(0, 20)}
              </S.Paragraph>
            </S.ColDriver>
          );
        };
      } else if (i.identifier === 'concluido') {
        i.Cell = function ({ row }) {
          return (
            <S.ColDriver>
              <S.Paragraph className="green">
                {row.original?.percentual || 0}%
              </S.Paragraph>
            </S.ColDriver>
          );
        };
      } else if (i.identifier === 'filial') {
        i.Cell = function ({ row }) {
          return (
            <S.ColDriver>
              <S.Paragraph className="normal">
                {row?.original?.filial}
              </S.Paragraph>
            </S.ColDriver>
          );
        };
      } else if (i.identifier === 'tipo') {
        i.Cell = function ({ row }) {
          return (
            <S.ColDriver>
              <S.Paragraph className="normal">
                {row?.original?.tipo}
              </S.Paragraph>
            </S.ColDriver>
          );
        };
      } else if (i.identifier === 'tentativas') {
        i.Cell = function ({ row }) {
          return (
            <S.ColDriver>
              <S.Paragraph className="normal" center>
                {row?.original?.tentativas}
              </S.Paragraph>
            </S.ColDriver>
          );
        };
      } else if (i.identifier === 'status') {
        i.Cell = function ({ row }) {
          return (
            <S.ColDriver>
              <S.Paragraph className="normal" center>
                {row?.original?.status}
              </S.Paragraph>
            </S.ColDriver>
          );
        };
      }
    });
    return arr;
  };

  const formatTypeColumns = () => {
    return columnsSmall;
  };

  return (
    <S.ModalMUI open={false} onClose={() => {}} disableEscapeKeyDown>
      <S.Container ref={printRef}>
        <S.Infos>
          <div className="logoArea">
            <img src={logo} />
          </div>
          <div className="formIdent">
            <span>
              Contéudo - ID {id} {formatNewHour(new Date())}
            </span>
          </div>
        </S.Infos>
        <S.Header bg={cont?.capa || capa}>
          <div className="topInfos">
            <div className="coverArea">
              <div className="background" />
            </div>
            <div className="textsArea">
              <div className="titleForm">{cont?.titulo}</div>
              <div className="descriptionForm">{cont?.descricao}</div>
              <div className="initial">
                <span>Data Início:</span>
                {formatNewHour(cont?.data_inicio)}
              </div>
              <div className="final">
                <span>Data Fim:</span>
                {cont?.data_fim
                  ? formatNewHour(cont?.data_fim)
                  : 'Nunca encerrar'}
              </div>
            </div>
          </div>
          <Divider />

          <div className="bottomInfos">
            <div className="card">
              <div className="cardInfos">
                <div className="title">Concluidos(%)</div>
                <div className="numbers">
                  {cards?.percentual_conclusao || 0}%
                </div>
              </div>
            </div>
            <div className="bar" />
            <div className="card">
              <div className="cardInfos">
                <div className="title">Total de Concluidos</div>
                <div className="numbers">{cards?.n_finalizados || 0}</div>
              </div>
            </div>
            <div className="bar" />
            <div className="card">
              <div className="cardInfos">
                <div className="title">Em Andamento</div>
                <div className="numbers">{cards?.n_em_andamento || 0}</div>
              </div>
            </div>
          </div>

          <div className="bottomInfos">
            <div className="card">
              <div className="cardInfos">
                <div className="title">Reprovados</div>
                <div className="numbers">{cards?.n_reprovados || 0}</div>
              </div>
            </div>
            <div className="bar" />
            <div className="card">
              <div className="cardInfos">
                <div className="title">Não Inciados</div>
                <div className="numbers">{cards?.n_nao_iniciados || 0}</div>
              </div>
            </div>
            <div className="bar" />
            <div className="card">
              <div className="cardInfos">
                <div className="title">Número de Alunos</div>
                <div className="numbers">{cards?.n_alunos || 0}</div>
              </div>
            </div>
          </div>
        </S.Header>

        <S.TableArea>
          <div className="coverInput" />
          <Table
            inputLabel=""
            data={dataTable || []}
            columns={formatTable(formatTypeColumns())}
            pageCount={data?.count || 0}
            fetchData={() => {}}
            updated={false}
            query={{}}
            initialSortBy={[{ id: 'nome', desc: false }]}
            permitIsSortedOccur
            invisibleSelect
            tableType
            empty={{
              title: 'Ops! Você não tem nenhuma resposta para esse conteúdo',
              subtitle: 'Aguarde até que os alunos realizem o conteúdo',
            }}
          />
          <div className="coverInputBottom" />
        </S.TableArea>
      </S.Container>
    </S.ModalMUI>
  );
};
