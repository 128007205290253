import styled, { css } from 'styled-components';

export const Paper = styled.div`
  ${({ theme }) => css`
    position: absolute;
    width: 540px;
    background-color: ${theme.palette.system.overlay};
    border: 0px 5px 20px ${theme.palette.system.divider};
    border-radius: 8px;
    padding: 25px 25px;
    margin: 0 auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `}
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
`;

export const Title = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    h1 {
      color: ${theme.palette.words.title.natural};
      font: normal 900 20px Texta;
      margin-left: 10px;
    }
  `}
`;

export const Main = styled.div`
  display: flex;
  width: 100%;
  min-height: 40px;
  flex-grow: 1;
  align-items: center;
  flex-direction: column;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 25px;
`;

export const StyledToast = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    width: 100%;
    border: 1px solid ${theme.palette.semantics.feedback.warning.natural};
    border-radius: 4px;
    background: ${theme.palette.semantics.feedback.warning.light} 0% 0%
      no-repeat padding-box;
    min-height: 50px;
    padding: 3px 10px;
    margin: 15px 0 10px;
    h1 {
      width: 100%;
      color: ${theme.palette.brand.primary.natural};
      font-size: 14px;
      margin-left: 15px;
      font-weight: 500;
    }
  `}
`;
