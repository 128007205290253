import { Modal } from '@mui/material';
import { useTheme } from 'styled-components';

import DefaultButton from 'components/Buttons/Default';
import { useState } from 'react';
import * as S from './styled';

const ModalCriacao = ({ open, onClose, onClick }) => {
  const theme = useTheme();
  const [ok, setOk] = useState(false);

  return (
    <S.ModalMui
      open={open}
      disableEscapeKeyDown={false}
      onClose={() => onClose(false)}
    >
      <S.Container>
        <div className="header">
          <span className="title">AVISO IMPORTANTE</span>
          <span className="subtitle">
            Leia com atenção as condições para criação de uma avaliação
          </span>
        </div>

        <div className="body">
          <span className="text">
            Prezado(a),
            <br />
            ao optar pela criação de avaliações desta forma é necessário
            atentar-se aos principais pontos abaixo:
          </span>

          <span className="topic">1. Tempo de Avaliação</span>

          <span className="text">
            É muito importante que seja avaliado PELO MENOS 1 (uma) hora de
            vídeo deste motorista! Exigimos isso para que seja respeitada a
            equidade de tratamento dado a todos os motoristas na plataforma.
          </span>

          <span className="topic">2. Envio posterior</span>

          <span className="text">
            Após a criação desta avaliação através deste método não será
            possível fazer o envio pelo Upload Manual e nem serão feitas mais
            buscas pelo Upload Automático para a avaliação desta semana deste
            motorista. Mesmo que os vídeos sejam disponibilizados posteriormente
            isso não será possível.
          </span>

          <span className="topic">3. Sobre Embarcadoras</span>

          <span className="text">
            Algumas embarcadoras não aceitam este tipo de avaliação digital,
            portanto o nome delas não aparecerá na lista suspensa do formulário.
            <br />
            Embarcadoras que não aceitam: Raízen.
          </span>

          <span className="topic">4. Sobre os Dados</span>

          <span className="text">
            Todos os dados preenchidos posterior a criação serão utilizados da
            mesma forma. Enriquecendo os indicadores disponibilizados na
            plataforma.
          </span>
        </div>
        <S.Footer>
          <div className="term">
            <label>
              <input type="checkbox" value={ok} onChange={() => setOk(!ok)} />
              EU ESTOU CIENTE DAS INFORMAÇÕES ACIMA
            </label>
          </div>
          <div>
            <DefaultButton
              onClick={() => onClose(false)}
              children="Cancelar"
              style={{
                backgroundColor:
                  theme.palette.semantics.feedback.attention.natural,
                marginRight: '10px',
              }}
            />
            <DefaultButton
              children="Avançar"
              disabled={!ok}
              onClick={onClick}
            />
          </div>
        </S.Footer>
      </S.Container>
    </S.ModalMui>
  );
};

export default ModalCriacao;
