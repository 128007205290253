import api from 'services/api';

const requestInfractions = async query => {
  const res = await api.get('/unloading-infractions', { params: query });
  return res;
};

const fetchInfraction = async id => {
  const res = await api.get(`/unloading-infraction/${id}`);
  return res;
};

const requestHistory = async id => {
  const res = await api.get(`/unloading-infraction-logs/${id}`);
  return res;
};

const requestCards = async query => {
  const res = await api.get('/unloading-infraction-cards', { params: query });
  return res;
};

const requestExcel = async query => {
  const res = await api.get('/excel/unloadingInfractions', { params: query });
  return res;
};

const saveInfractions = async data => {
  const res = await api.put(`/unloading-infractions-update`, data);
  return res;
};

const deleteInfractions = async data => {
  const res = await api.put(`/unloading-infractions-delete`, data);
  return res;
};

const viewInfractions = async data => {
  const res = await api.put(`/unloading-infractions-view`, data);
  return res;
};

const finishInfractions = async data => {
  const res = await api.put(`/unloading-infractions-finish`, data);
  return res;
};

const sendPadInfractions = async data => {
  const res = await api.post(`/unloading-infractions-send-pad`, data);
  return res;
};

export {
  requestInfractions,
  fetchInfraction,
  requestCards,
  requestExcel,
  saveInfractions,
  deleteInfractions,
  finishInfractions,
  viewInfractions,
  sendPadInfractions,
  requestHistory,
};
