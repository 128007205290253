import { formatNewHour } from 'utils/dates';

export const columns = [
  {
    header: 'Filial',
    id: 'filial.nome',
    type: 'string',
    sort: false,
  },
  {
    header: 'Inicio',
    id: 'execucao',
    type: 'string',
    disableSortBy: false,
    sort: true,
    value: item => formatNewHour(item),
    align: 'center',
  },
  {
    header: 'Fim',
    id: 'fim_do_upload',
    type: 'string',
    show: true,
    sort: true,
    value: item => formatNewHour(item),
    align: 'center',
  },
  {
    header: 'Enviados',
    id: 'enviados_com_sucesso',
    type: 'number',
    sort: true,
    align: 'center',
    width: 80,
  },
  {
    header: 'Não Enviados',
    id: 'nao_enviados',
    type: 'number',
    sort: true,
    align: 'center',
    width: 90,
  },
  {
    header: 'Versão',
    id: 'versao_software',
    type: 'number',
    sort: true,
    align: 'center',
    width: 80,
  },
  {
    header: 'Detalhes',
    id: 'detalhes',
    sort: true,
    align: 'center',
  },
];
