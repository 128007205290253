// Styles
import { useTheme } from 'styled-components';

// React
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';

// Components
import Input from 'components/Inputs/TextField';
import { Switch } from 'components/Inputs/Switch';
import ConfirmModal from 'components/ConfirmModal';
import GhostButton from 'components/Buttons/Ghost';
import DefaultButton from 'components/Buttons/Default';

// Components Material UI
import { EditOutlined } from '@mui/icons-material';
import { Icon, IconButton, Modal, Grid } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

// Utils
import { firestorage } from 'utils/firebase';
import { createTurma } from '../services';
import * as S from './styled';

const ModalAddTurma = ({ open, handleClose, fetchData }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  // General States
  const [loading, setLoading] = useState(false);

  // Modal Controll and States
  const [confirmAdd, setConfirmAdd] = useState(false);
  const [margin, setMargin] = useState('-550px');

  const [motoristaActive, setMotoristaActive] = useState(false);
  const [gestorActive, setGestorActive] = useState(false);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setMargin('0px');
      }, 10);
    }
  }, [open]);

  const behaviorCloseModal = () => {
    setMargin('-550px');
    setTimeout(() => {
      handleClose();
    }, 380);
  };

  // User fields
  const [nome, setNome] = useState('');
  const [status, setStatus] = useState(true);
  const [foto, setFoto] = useState(null);

  // Input file reference (for picture)
  const inputFile = useRef(null);

  // Filials redux
  const { filials } = useSelector(state => {
    return state.selects;
  });

  useEffect(() => {
    if (filials) {
      const formated = [];
      for (const i in filials) {
        formated.push({
          name: filials[i].nome,
          value: filials[i].id,
        });
      }
    }
  }, [filials]);

  // CONFIRM ACTION (FIELDS AND REQUESTS)
  const verifyFields = () => {
    if (!nome) {
      toast.error('Preencha um nome para o usuário');
      return;
    }
    setConfirmAdd(true);
  };

  const navigateTO = id => {
    navigate(`/fiscalizacao/viewTurmas/${id}`, { id });
  };

  const confirmCreateTurma = async () => {
    setLoading(true);
    const res = await createTurma({
      nome,
      ativa: Number(status),
      foto,
      para_motoristas:
        motoristaActive === true || gestorActive === true
          ? motoristaActive
          : true,
      para_usuarios:
        motoristaActive === true || gestorActive === true ? gestorActive : true,
    });

    if (res.data.success) {
      behaviorCloseModal();
      setTimeout(() => {
        fetchData();
        toast.success(res.data.data.message);
        setNome('');
        setFoto(null);
        navigateTO(res.data.data.id);
      }, 600);
    } else {
      toast.error(res.data.message);
    }

    setConfirmAdd(false);
    setLoading(false);
  };

  // Change Photo
  // Atenção o upload da foto nao garante a finalização do usuario
  // Isso pode gerar upload desnecessario de imagens no firebase
  const updateUserImage = async () => {
    const file = inputFile.current.files[0];
    if (file) {
      try {
        setLoading(true);
        const dh = new Date().toDateString();
        if (!file) return toast.error('Não foi possível enviar sua imagem');

        const uploadTask = firestorage
          .ref(`/turmas/${dh}_${file.name}`)
          .put(file);
        uploadTask.on(
          'state_changed',
          snapShot => {},
          err => {
            console.log('Err', err);
          },
          () => {
            firestorage
              .ref(`/turmas/`)
              .child(`${dh}_${file.name}`)
              .getDownloadURL()
              .then(fireBaseUrl => {
                setFoto(fireBaseUrl);
              });
          },
        );
      } catch (error) {
        toast.error('Não foi possível enviar sua imagem');
      }
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={behaviorCloseModal}
        disableEscapeKeyDown={false}
      >
        <S.Container margin={margin}>
          <S.Header>
            <div style={{ alignItems: 'center' }}>
              <>
                <SettingsOutlinedIcon
                  sx={{ color: theme.palette.brand.secondary.natural }}
                  fontSize="medium"
                />
                <h2>Criar Turma</h2>
              </>
            </div>

            <IconButton size="small" onClick={behaviorCloseModal}>
              <Icon sx={{ color: theme.palette.words.subtitle.natural }}>
                close
              </Icon>
            </IconButton>
          </S.Header>

          <S.Main>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <S.Section>INFORMAÇÕES GERAIS</S.Section>
              </Grid>
              <Grid item xs={12} sm={12}>
                <label>
                  <input
                    type="file"
                    multiple={false}
                    ref={inputFile}
                    onChange={updateUserImage}
                    disabled={loading}
                  />
                  <div className="imgArea">
                    {foto && (
                      <span className="borderImage">
                        <img src={foto} />
                      </span>
                    )}

                    {!foto && (
                      <span className="empty">
                        {nome.length > 0 && nome[0]}
                      </span>
                    )}
                  </div>
                  <div className="textArea">
                    <EditOutlinedIcon
                      htmlColor={theme.palette.words.subtitle.natural}
                    />
                    <span>Alterar Foto</span>
                  </div>
                </label>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Input
                  required
                  label="Nome do Turma"
                  value={nome}
                  placeholder="Insira o nome da turma"
                  onChange={e => setNome(e.target.value)}
                  name="nome"
                  id="nome"
                />
              </Grid>
              <Grid container spacing={2} item xs={12} sm={12}>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  display="flex"
                  alignContent="center"
                  marginTop="25px"
                >
                  <Switch
                    check={status}
                    setCheckbox={setStatus}
                    textOn="Ativa"
                    textOff="Inativa"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} item xs={12} sm={12}>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  display="flex"
                  alignContent="center"
                  marginTop="25px"
                >
                  <Switch
                    check={motoristaActive}
                    setCheckbox={setMotoristaActive}
                    textOn="Para Motorista"
                    textOff="Para Motorista"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} item xs={12} sm={12}>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  display="flex"
                  alignContent="center"
                  marginTop="25px"
                >
                  <Switch
                    check={gestorActive}
                    setCheckbox={setGestorActive}
                    textOn="Para Gestor"
                    textOff="Para Gestor"
                  />
                </Grid>
              </Grid>
            </Grid>
          </S.Main>

          <S.Footer>
            <div />
            <div>
              <GhostButton onClick={behaviorCloseModal} size="medium">
                CANCELAR
              </GhostButton>

              <DefaultButton
                onClick={verifyFields}
                size="medium"
                sx={{ marginLeft: '20px' }}
              >
                Salvar
              </DefaultButton>
            </div>
          </S.Footer>
        </S.Container>
      </Modal>

      {/* Confirmação de adição */}
      <ConfirmModal
        open={confirmAdd}
        handleClose={() => setConfirmAdd(false)}
        title="Deseja confirmar a criação da turma?"
        titleIcon={
          <EditOutlined
            sx={{ color: theme.palette.brand.secondary.natural }}
            fontSize="medium"
          />
        }
        subtitle="Atenção! Verifique todos os campos antes de confirmar a operação."
        buttonText="Confirmar"
        onClick={() => confirmCreateTurma()}
        loading={loading}
      />
    </>
  );
};

export default ModalAddTurma;
