import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { usePlans } from 'hooks/usePlans';

// Components
import { DefaultTable } from 'components/_Table/templates/default';

import { trackEvent } from 'utils/mixpanel';
import { columns, columnsTorrePlus } from './constants';

import { getAcoesEmpresa, getAcoesSuspensoes } from './services';

const AcoesSuspensoesEmpresas = () => {
  // Redux e hooks
  const navigate = useNavigate();
  const user = useSelector(state => state.auth?.user?.user);
  const filter = useSelector(state => state.filterProvider);
  const { hasTorrePlus } = usePlans();

  const [query, setQuery] = useState(null);
  const [resetTable, setResetTable] = useState(false);
  const [loadingLines, setLoadingLines] = useState([]);

  const initial = useRef(true);
  const { isLoading, data: resData } = useQuery(
    ['acoes-suspensoes-empresas', query],
    () => query && getAcoesEmpresa(query),
    {
      refetchOnWindowFocus: false,
      onSuccess: () => {
        setLoadingLines([]);
        resetTable && setResetTable(false);
      },
      enabled: !initial.current,
    },
  );

  const { isLoadingTorrePlus, data: resDataTorrePlus } = useQuery(
    ['acoes-suspensoes', query],
    () => query && getAcoesSuspensoes(query),
    {
      refetchOnWindowFocus: false,
      onSuccess: () => {
        setLoadingLines([]);
        resetTable && setResetTable(false);
      },
      enabled: !initial.current,
    },
  );

  useEffect(() => {
    if (initial.current) {
      initial.current = false;
      return;
    }

    setResetTable(true);
  }, [filter]);

  const actions = [
    {
      title: 'Ver detalhe',
      function: id =>
        hasTorrePlus
          ? navigate(`/acoes-suspensoes/${id}`)
          : navigate(`/acoes-suspensoes/empresas/${id}`),
    },
  ];
  return (
    <>
      <br />
      <DefaultTable
        data={
          hasTorrePlus
            ? resDataTorrePlus?.data?.data
            : resData?.data?.data || []
        }
        columns={hasTorrePlus ? columnsTorrePlus : columns}
        loading={isLoading || isLoadingTorrePlus}
        pageCount={
          hasTorrePlus
            ? resDataTorrePlus?.data?.total
            : resData?.data?.total || 0
        }
        local={false}
        actions={actions}
        reset={resetTable}
        loadingSelection={loadingLines}
        onClickRow={id =>
          hasTorrePlus
            ? navigate(`/acoes-suspensoes/${id}`)
            : navigate(`/acoes-suspensoes/empresas/${id}`)
        }
        setQuery={q => setQuery({ ...q, ...filter })}
        searchEvent={search =>
          trackEvent(user, 'Busca Ações e Suspensões de empresa', null, search)
        }
        sortBy={{ id: hasTorrePlus ? 'pontos' : 'titulo', order: 'DESC' }}
        placeholder="Buscar Empresa"
        empty={{
          title: 'Ops! Você não tem nenhuma ação ou suspensão disponível.',
          description: 'Verifique os filtros aplicados!',
        }}
      />
    </>
  );
};

export default AcoesSuspensoesEmpresas;
