// React
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

// import { NotFoundConfig } from "./NotFound"

// Material UI
import { SvgIcon } from '@mui/material';
import { useTheme } from 'styled-components';
import * as S from './styled';

export const SidebarInter = ({ pages = [] }) => {
  const theme = useTheme();
  const location = useLocation();
  const page = location.pathname.split('/')[3];

  const navigate = useNavigate();
  const [Component, setComponent] = useState(<></>);

  useEffect(() => {
    renderComponent();
  }, [page]);

  const renderComponent = () => {
    let currentPage = null;
    for (const i in pages) {
      if (pages[i].linkTo.includes(page)) {
        currentPage = pages[i].element;
        setComponent(currentPage);
        return;
      }
    }

    if (!page) {
      currentPage = pages[0].element;
      setComponent(currentPage);
      return;
    }

    setComponent(<div>pagina outra não encontrada</div>);
  };

  return (
    <S.Container>
      <S.NavBar>
        {pages.map((i, k) => (
          <span
            onClick={() =>
              navigate(`${i.linkTo}${location.search}`, { replace: true })
            }
            className={
              i.linkTo.includes(page) ? 'navBarItem active' : 'navBarItem'
            }
            key={k}
          >
            <SvgIcon
              component={i.icon}
              style={{
                color: i.linkTo.includes(page)
                  ? theme.palette.brand.secondary.natural
                  : theme.palette.words.button.natural,
              }}
            />
            <span>{i.page}</span>
          </span>
        ))}
      </S.NavBar>
      <S.TemplatePage>
        <div className="alignContent">{Component}</div>
      </S.TemplatePage>
    </S.Container>
  );
};
