import React from 'react';
import { useSelector } from 'react-redux';

import { Divider } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import Tabs from 'components/Tabs';

import * as S from './styled';
import { useTheme } from 'styled-components';
import { Select } from '../Select';
import { ChipSelect } from '../ChipSelect';
import { order, tabs } from './constants';

export const Filters = ({ filters, setFilters, sortBy, setSortBy }) => {
  const selects = useSelector(state => state.selects);

  const filiais =
    selects.filials?.map(i => {
      return { value: i.id, label: i.nome };
    }) || [];

  const theme = useTheme();
  return (
    <S.Filters>
      <div className="flex gap-1">
        <div className="flex-1">
          <Tabs
            value={filters.status}
            items={tabs}
            onChange={(_, value) =>
              setFilters(state => ({ ...state, status: value }))
            }
          />
          <Divider />
        </div>
        <Select
          label="Ordenar por"
          options={order}
          value={sortBy}
          onChange={value => setSortBy(value)}
        />
      </div>
      <div className="flex justify-between my-1 gap-1">
        <S.SearchBox>
          <SearchOutlinedIcon
            className="searchIcon"
            htmlColor={theme.palette.semantics.feedback.unknown.natural}
          />
          <S.SearchInput
            type="text"
            placeholder="Buscar..."
            value={filters.search}
            onChange={e =>
              setFilters(state => ({ ...state, search: e.target.value }))
            }
          />
        </S.SearchBox>

        <ChipSelect
          label="Filtrar por filial:"
          options={[{ value: '', label: 'Todas' }, ...filiais]}
          value={filters.filial}
          onChange={value => setFilters(state => ({ ...state, filial: value }))}
        />
      </div>
    </S.Filters>
  );
};
