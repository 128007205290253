import styled from 'styled-components';
import colors from 'styles/colors';

export const TagItem = styled.div`
  background-color: ${colors.greyBackgroud};
  color: ${colors.greyTitle};
  font-weight: bold;
  text-align: center;
  padding: 0px 20px;
  margin-right: 20px;
  border-radius: 50px;
  border: 1px solid ${colors.greyBackgroud};
  transition: all ease 0.2s;
  height: 25px;
  white-space: nowrap;
  display: flex;
  gap: 4px;

  &.active {
    border: 1px solid ${colors.greyTitle};
  }

  &:hover {
    background-color: ${colors.greyBorder};
  }
`;

export const TagItemButton = styled.div`
  background-color: ${colors.greyBackgroud};
  color: ${colors.greyTitle};
  padding: 0px 4px;
  border-radius: 16px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  transition: all ease 0.2s;

  &:hover {
    background-color: ${colors.greyTiny};
  }
`;

export const TagItemLoading = styled.span`
  padding: 0px 20px;
  margin-right: 20px;
  border-radius: 50px;
  border: 1px solid ${colors.greyBackgroud};
  transition: all ease 0.2s;
  height: 25px;
  width: 100px;
  background-image: linear-gradient(
    to right,
    #f6f7f8 0%,
    #edeef1 20%,
    #f6f7f8 40%,
    #f6f7f8 100%
  );
  background-size: 80rem 14rem;
  animation: placeholderShimmer 3s linear infinite forwards;

  @keyframes placeholderShimmer {
    0% {
      background-position: -40rem 0;
    }
    100% {
      background-position: 40rem 0;
    }
  }
`;
