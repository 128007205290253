import * as services from './services';
import * as validators from './validator';

import { Perfil } from './Perfil';
import { Filial } from './Filial';
import { Cliente } from './Cliente';
import { Motoristas } from './Motoristas';
import { Usuarios } from './Usuarios';

export const modelMonitoramento = {
  id: 1,
  title: 'Primeiros passos',
  steps: [
    {
      id: 1,
      actionTitle: 'Edite suas informações de cadastro',
      tooltipAction: 'Tooltip action 1',
      button: null,
      tooltip:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      Component: params => <Perfil {...params} />,
      service: async data => services.saveInfos(data),
      validator: data => validators.validatorPerfil(data),
      hasSave: true,
      acessor: 'empresa',
    },
    {
      id: 2,
      actionTitle: 'Filiais',
      tooltipAction: 'Tooltip action 2',
      button: 'Cadastrar filiais',
      tooltip:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      Component: params => <Filial {...params} />,
      service: async data => services.saveFiliais(data),
      validator: null,
      hasSave: true,
      acessor: 'filiais',
    },
    {
      id: 3,
      actionTitle: 'Clientes',
      tooltipAction: 'Tooltip action 3',
      button: 'Cadastrar clientes',
      tooltip:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      Component: params => <Cliente {...params} />,
      service: async data => services.saveClientes(data),
      validator: null,
      hasSave: true,
      acessor: 'distribuidoras',
    },
    {
      id: 4,
      actionTitle: 'Usuários',
      tooltipAction: 'Tooltip action 5',
      button: 'Cadastrar usuários',
      tooltip:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      Component: params => <Usuarios {...params} />,
      service: null,
      validator: null,
      hasSave: false,
      acessor: 'usuarios',
    },
    {
      id: 5,
      tooltipAction: 'Tooltip action 4',
      button: 'Cadastrar motirstas',
      tooltip:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      Component: params => <Motoristas {...params} />,
      service: null,
      validator: null,
      hasSave: false,
      acessor: 'motoristas',
    },
  ],
};
