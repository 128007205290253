// Styles
import { useTheme } from 'styled-components';

// React
import { useEffect, useMemo } from 'react';

// Context Actions
import * as action from '../../context/actions';
import { generatorColumns } from '../../generators/columns';

export const useScope = ({
  data,
  sort,
  local,
  state,
  reset,
  loading,
  columns,
  dispatch,
  pageCount,
  hideLines,
  emptyFill,
  fixedItems,
  searchKeys,
  cachePerPage,
  setSelectedRows,
  selectedByDefault,
  loadingSelection,
}) => {
  const theme = useTheme();

  // -----------------------------------Datas-----------------------------------//
  const { generate, total } = useMemo(
    () =>
      generatorColumns({
        data,
        sort,
        state,
        theme,
        local,
        columns,
        loading,
        emptyFill,
        hideLines,
        searchKeys,
      }),
    [data, columns, sort, loading, state, local],
  );

  const lines = useMemo(() => {
    let remove = 0;
    const total = columns.reduce((acc, cur) => {
      if (cur?.width) {
        acc += cur?.width;
        remove += 1;
      }
      return acc;
    }, 0);

    return {
      columns: columns?.length - remove,
      acc: total,
    };
  }, [columns]);

  // -----------------------------------Effects-----------------------------------//
  useEffect(() => {
    if (!loading) {
      const pageLength = pageCount || total;
      dispatch(action._setCount(pageLength));
      const pages = Math.ceil(pageLength / state.pageSize);
      if (pages < state.pageIndex + 1)
        dispatch(action._setPage(pages - 1 > 0 ? pages - 1 : 0));
    }
  }, [
    hideLines,
    data,
    state.search,
    state.pageSize,
    state.filters,
    loading,
    pageCount,
  ]);

  useEffect(() => {
    if (!loading) {
      const defaulted = state?.selectedDefault || selectedByDefault;

      const filtered = state.cachePerPage
        ? data?.filter(item => !hideLines?.includes(item.id)).map(row => row.id)
        : generate[0]?.rows?.map(row => row.original);

      const existentsDefault =
        filtered?.map(item => {
          if (defaulted?.includes(item)) return item;
        }) || [];

      // WARNING: Insert this line goes persist the selected items after the call action
      // const existentsCache =
      //   state?.selected?.filter(item => filtered?.find(d => d === item)) || [];

      const existentsCache = [];

      const selection = [
        ...new Set([
          ...existentsDefault?.filter(item => item),
          ...existentsCache?.filter(item => item),
        ]),
      ];

      dispatch(action._setSelected(selection));
      setSelectedRows && setSelectedRows(selection);

      const restDefault = defaulted?.filter(item => !selection?.includes(item));

      dispatch(action._setSelectedDefault(restDefault));
    }
  }, [
    selectedByDefault,
    state.cachePerPage,
    state.sortBy,
    loading,
    loadingSelection.length,
  ]);

  useEffect(() => {
    const table = { cachePerPage };
    if (fixedItems) table.pageSize = fixedItems;
    dispatch(action._setTable(table));
  }, []);

  useEffect(() => {
    if (reset) {
      dispatch(
        action._setTable({
          pageIndex: 0,
          selected: [],
          selectedDefault: [],
          search: '',
          started: false,
        }),
      );
    }
  }, [reset]);

  return {
    generate,
    lines,
  };
};
