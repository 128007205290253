export const fildsRequired = [
  {
    label: 'Nome',
    required: true,
    type: 'input',
    placeholder: 'Nome',
    name: 'nome',
    xs: 12,
    sm: 12,
    rules: { required: 'Campo Nome obrigatório' },
  },
  {
    label: 'CPF',
    required: true,
    type: 'input',
    placeholder: 'CPF',
    name: 'cpf',
    xs: 6,
    sm: 6,
    mask: '999.999.999-99',
    rules: { required: 'Campo CPF obrigatório' },
  },
  {
    label: 'Matrícula',
    required: true,
    type: 'input',
    placeholder: 'Matrícula',
    name: 'matricula',
    xs: 6,
    sm: 6,
    rules: { required: 'Campo Matrícula obrigatório' },
  },
  {
    label: 'Gênero',
    required: true,
    type: 'select',
    placeholder: 'Gênero',
    name: 'genero',
    xs: 6,
    sm: 6,
    data: [
      { name: 'Feminino', value: 'Feminino' },
      { name: 'Masculino', value: 'Masculino' },
      { name: 'Outro', value: 'Outro' },
    ],
    rules: { required: 'Campo Gênero obrigatório' },
  },
  {
    label: 'Função',
    required: true,
    type: 'input',
    placeholder: 'Função',
    name: 'funcao',
    xs: 6,
    sm: 6,
    rules: { required: 'Campo Função obrigatório' },
  },
  {
    label: 'Celular',
    required: true,
    type: 'input',
    placeholder: 'Celular',
    name: 'celular',
    xs: 6,
    sm: 6,
    mask: '(99) 99999-9999',
    rules: { required: 'Campo Celular obrigatório' },
  },
  {
    label: 'Data de nascimento',
    required: true,
    type: 'input',
    placeholder: 'dd-mm-aaaa',
    name: 'data_de_nascimento',
    xs: 6,
    sm: 6,
    mask: '99-99-9999',
    rules: { required: 'Campo Data de nascimento obrigatório' },
  },
  {
    label: 'Data de admissão',
    required: true,
    type: 'input',
    placeholder: 'dd/mm/aaaa"',
    name: 'data_de_admissao',
    xs: 6,
    sm: 6,
    mask: '99-99-9999',
    rules: { required: 'Campo Data de admissão obrigatório' },
  },
  {
    label: 'Agregado',
    required: true,
    type: 'select',
    name: 'agregado',
    xs: 6,
    sm: 6,
    data: [
      { name: 'SIM', value: 'SIM' },
      { name: 'NÃO', value: 'NÃO' },
    ],
    rules: { required: 'Campo Agregado obrigatório' },
  },
];

export const fildsNotRequired = [
  {
    label: 'CNH',
    placeholder: 'CNH',
    required: false,
    type: 'input',
    name: 'cnh',
    xs: 6,
    sm: 6,
  },
  {
    label: 'Validade CNH',
    required: false,
    type: 'input',
    name: 'validade_cnh',
    placeholder: 'dd/mm/aaaa',
    xs: 6,
    sm: 6,
    mask: '99-99-9999',
  },
  {
    label: 'Primeira CNH',
    required: false,
    type: 'input',
    name: 'primeira_cnh',
    placeholder: 'dd/mm/aaaa',
    mask: '99-99-9999',
    xs: 6,
    sm: 6,
  },
  {
    label: 'Estado Civil',
    placeholder: 'Estado Civil',
    required: false,
    type: 'select',
    name: 'estado_civil',
    xs: 6,
    sm: 6,
    data: [
      { name: 'Solteiro(a)', value: 'Solteiro' },
      { name: 'Casado(a)', value: 'Casado' },
      { name: 'Separado(a)', value: 'Separado' },
      { name: 'Divorciado(a)', value: 'Divorciado' },
      { name: 'Viúvo(a)', value: 'Viúvo' },
      { name: 'União Estavel', value: 'União Estavel' },
    ],
  },
  {
    label: 'Fumante',
    placeholder: 'Fumante',
    required: false,
    type: 'select',
    name: 'fumante',
    xs: 6,
    sm: 6,
    data: [
      { name: 'SIM', value: 'SIM' },
      { name: 'NÃO', value: 'NÃO' },
    ],
  },
];
