// React
import React, { useState, useEffect, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { startOfWeek, endOfWeek } from 'date-fns';

// styles
import * as S from './styled';

// components custom
import { PeriodPicker } from 'pages/Estatisticas/Components/PeriodPicker';

const encontrarTarefasPorIntervalo = (dadosTarefas, intervaloDatas) => {
  const inicioIntervalo = new Date(intervaloDatas.initialDate);
  const fimIntervalo = new Date(intervaloDatas.finalDate);
  let tarefasEncontradas = [];

  dadosTarefas.forEach(mes => {
    mes.semanas.forEach(semana => {
      const inicioSemana = new Date(semana.inicio);
      const fimSemana = new Date(semana.final);

      if (
        (inicioSemana <= fimIntervalo && inicioSemana >= inicioIntervalo) ||
        (fimSemana >= inicioIntervalo && fimSemana <= fimIntervalo) ||
        (inicioSemana <= inicioIntervalo && fimSemana >= fimIntervalo)
      ) {
        tarefasEncontradas = [...tarefasEncontradas, ...semana.tarefas];
      }
    });
  });

  return tarefasEncontradas;
};

const MinhasTarefas = ({ data = [], handleCount }) => {
  const theme = useTheme();

  const [finalDate, setFinalDate] = useState(endOfWeek(new Date()));
  const [initialDate, setIntitalDate] = useState(startOfWeek(new Date()));
  const [tarefas, setTarefas] = useState([]);

  useEffect(() => {
    const tarefasEncontradas = encontrarTarefasPorIntervalo(data, {
      initialDate,
      finalDate,
    });
    setTarefas(tarefasEncontradas);
    handleCount(tarefasEncontradas.length);
  }, [finalDate, initialDate]);

  return (
    <S.Container>
      <div className="title">
        Minhas tarefas
        <div className="counter-task">{tarefas?.length || 0}</div>
      </div>

      <div className="divider" />

      <PeriodPicker
        finalDate={finalDate}
        initialDate={initialDate}
        period="week"
        onChange={date => {
          setFinalDate(date.finalDate);
          setIntitalDate(date.initialDate);
        }}
      />
      <div style={{ overflowY: 'scroll', maxHeight: 300 }}>
        {tarefas.length > 0 ? (
          tarefas.map(item => {
            return (
              <div className="item-container">
                <div style={{ opacity: item.status !== 'CONCLUIDO' ? 1 : 0.3 }}>
                  {item?.title} <span>{item?.evento}</span>
                  <div className="vencimento">
                    {new Date(item.data) > new Date() ? 'prazo' : `Venceu`} dia{' '}
                    {new Date(item.data).toLocaleDateString()}
                  </div>
                </div>
                {item.status !== 'CONCLUIDO' ? (
                  <div className="item-link">Resolver</div>
                ) : (
                  <div className="item-link feito">Feito</div>
                )}
              </div>
            );
          })
        ) : (
          <div className="non-task">Nenhuma tarefa encontrada no período.</div>
        )}
      </div>
    </S.Container>
  );
};

export default MinhasTarefas;
