import api from 'services/api';

// ------------------------ALL PAGES REQUESTS---------------------------//
export const requestEvaluation = async id => {
  const { data } = await api.get(`/evaluation-descarga/${id}`);
  return data;
};

export const viewEvaluation = async id => {
  try {
    const res = await api.put(`/view-evaluation-descarga/${id}`);
    return res;
  } catch (error) {
    return false;
  }
};

// -------------------INFORMAÇÕES GERAIS REQUESTS----------------------//
export const selectDistributors = async () => {
  const res = await api.get('/select-clients');
  return res.data;
};

export const updateHeaderEvaluation = async (id, data) => {
  const res = await api.put(`/evaluations-descarga/${id}`, data);
  return res;
};

// ------------------------FORMULÁRIO REQUESTS---------------------------//
export const requestAnswersEvaluation = async id => {
  const res = await api.get(`/answers-evaluation-descarga/${id}`);
  return res;
};

export const requestTypesQuestions = async () => {
  const res = await api.get('/types-questions-descarga');
  return res;
};

export const answerOneQuestion = async (id, data) => {
  try {
    const res = await api.put(`/evaluations-descarga/${id}`, data);
    return res;
  } catch (error) {
    return false;
  }
};

export const createNewInfraction = async data => {
  const res = await api.post('/infractions-create', data);
  return res.data;
};

// ------------------------ASSINATURAS REQUESTS---------------------------//
export const requestResponsibles = async id => {
  const res = await api.get(`/select-responsibles-descarga/${id}`);
  return res.data;
};

export const signatureEvaluation = async data => {
  try {
    const res = await api.put(`/evaluations-descarga/${data.id}`, data);
    return res.data;
  } catch (error) {
    return false;
  }
};

export const selectWitnessEvaluation = async data => {
  try {
    const res = await api.put(`/select-witness-evaluations-descarga`, data);
    return res;
  } catch (error) {
    return false;
  }
};

// ------------------------EVIDENCIAS REQUESTS---------------------------//
export const updateEvidences = async (id, data) => {
  const res = await api.put(`/evaluations-descarga/${id}`, data);
  return res;
};

// ------------------------DESVIOS REQUESTS---------------------------//
export const finishInfractions = async data => {
  // Finalizar
  const res = await api.put('/desvios/finalizar', data);
  return res;
};

export const deleteInfractions = async data => {
  // Deletar
  const res = await api.put('/desvios/delete', data);
  return res;
};

// ------------------------HISTORICO REQUESTS---------------------------//
export const requestLogs = async id => {
  const res = await api.get(`/evaluation-descarga/${id}/logs`);
  return res.data;
};

export const updateLogs = async (id, data) => {
  const res = await api.put(`/evaluations-descarga/${id}/logs`, data);
  return res;
};
