// React
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// services
import { usePlans } from 'hooks/usePlans';

// redux
import {
  exitFullScreenMode,
  fullScreenMode,
} from 'store/modules/header/actions';

// Styles
import * as S from './styled';

// icons header buttons
import { ReactComponent as IconAreaRisco } from 'images/home/header/icone-areas-de-risco.svg';
import { ReactComponent as IconFatorRisco } from 'images/home/header/icone-fatores-de-risco.svg';
import { ReactComponent as IconPendencias } from 'images/home/header/icone-pendencias.svg';
import { ReactComponent as IconTempoReal } from 'images/home/header/icone-tempo-real.svg';
import { ReactComponent as IconFullScreen } from 'images/home/header/icone-expandir.svg';
import { ReactComponent as IconFullScreenExit } from 'images/home/header/icone-recolher.svg';

// components custom
import FatorRisco from './FatorRisco';
import AreasRisco from './AreasRisco';
import MinhasTarefas from './MinhasTarefas';
import Rastreamento from './Rastreamento';
import MinhasTarefasProvider from './MinhasTarefas/indexProvider';

const Home = () => {
  const { isProvider, hasTelemetria, hasTorrePlus, hasTorre } = usePlans();

  const dispatch = useDispatch();
  const header = useSelector(state => state.header);

  // states
  const [isActive, setIsActive] = useState(hasTelemetria ? 1 : 2);
  const [countTask, setcountTask] = useState(0);

  useEffect(() => {
    const handleFullScreenChange = () => {
      const isFullScreen = !!document.fullscreenElement;
      if (isFullScreen) {
        dispatch(fullScreenMode({ fullScreenMode: true }));
      } else {
        dispatch(exitFullScreenMode({ fullScreenMode: false }));
      }
    };

    document.addEventListener('fullscreenchange', handleFullScreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
    };
  }, [dispatch]);

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
      dispatch(
        fullScreenMode({
          fullScreenMode: !header.fullScreenMode,
        }),
      );
    } else if (document.exitFullscreen) {
      dispatch(
        exitFullScreenMode({
          fullScreenMode: !header.fullScreenMode,
        }),
      );
      document.exitFullscreen();
    }
  };

  const handleActive = val => {
    setIsActive(val);
  };

  return (
    <S.Container>
      <S.Header>
        {hasTelemetria && (
          <div
            className={`buttonHeader ${isActive === 1 ? 'active' : ''}`}
            onClick={() => handleActive(1)}
          >
            <IconTempoReal />
            <span>Rastreamento ativo</span>
          </div>
        )}
        <div
          className={`buttonHeader ${isActive === 2 ? 'active' : ''}`}
          onClick={() => handleActive(2)}
        >
          <IconFatorRisco />
          <span>Fatores de risco</span>
        </div>
        {hasTelemetria && (
          <div
            className={`buttonHeader ${isActive === 3 ? 'active' : ''}`}
            onClick={() => handleActive(3)}
          >
            <IconAreaRisco />
            <span>Áreas de risco</span>
          </div>
        )}
        <div
          className={`buttonHeader ${isActive === 4 ? 'active' : ''}`}
          onClick={() => handleActive(4)}
        >
          <IconPendencias />
          <span
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
            }}
          >
            Tarefas em aberto{' '}
            <span className="counter-task">
              {countTask > 10 ? '10+' : countTask}
            </span>
          </span>
        </div>
        <div className="buttonFullScreen" onClick={toggleFullScreen}>
          {!header.fullScreenMode ? <IconFullScreen /> : <IconFullScreenExit />}
        </div>
      </S.Header>
      <S.Content>
        {isActive === 1 && <Rastreamento />}
        {isActive === 2 && <FatorRisco />}
        {isActive === 3 && <AreasRisco />}
        {isProvider ? (
          <MinhasTarefasProvider
            handleCount={setcountTask}
            hidden={isActive !== 4}
          />
        ) : (
          <MinhasTarefas handleCount={setcountTask} hidden={isActive !== 4} />
        )}
      </S.Content>
    </S.Container>
  );
};

export default Home;
