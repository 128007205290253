import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const SpacedRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderActions = styled.div`
  display: flex;
  gap: 1rem;
`;

export const ColumnWrapper = styled.div`
  ${({ theme }) => css`
    background: ${theme.palette.system.overlay};
    border-radius: 4px;
    border: 1px solid ${theme.palette.brand.primary.light}4d;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 25px;

    .imageArea {
      width: 50px;
      height: 50px;
      border: 2px solid ${theme.palette.brand.secondary.natural};
      border-radius: 50px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
        border-radius: 200px;
        padding: 1px;
      }

      .letters {
        width: 42px;
        height: 42px;
        border-radius: 42px;
        background-color: ${theme.palette.system.border};
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 900;
        color: ${theme.palette.words.title.natural};
        font-size: 19px;
      }
    }

    .distributorArea {
      margin-left: 10px;
      font-weight: 400;
      color: ${theme.palette.words.subtitle.natural};
      font-size: 14px;
    }
    .distributorName {
      margin-left: 10px;
      font-weight: 600;
      color: ${theme.palette.words.subtitle.natural};
    }
  `}
`;

export const StyledLogoWrapper = styled.div`
  ${({ theme }) => css`
    margin-right: 10px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid ${theme.palette.brand.secondary.natural};
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;

export const StyledLogo = styled.div`
  ${({ theme }) => css`
    height: 42px;
    width: 42px;
    border-radius: 50%;
    border: ${props => props.border};
    background-image: ${props => props.backgroundImage};
    background-color: ${theme.palette.semantics.feedback.unknown.light};
    display: flex;
    align-items: center;
    justify-content: center;

    h2 {
      font-size: 20px;
      color: ${theme.palette.brand.primary.natural};
      text-align: center;
    }
  `}
`;

export const DriverContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    border: 1px solid ${theme.palette.system.border};
    border-radius: 4px;
    padding: 8px;

    .info {
      width: 60%;
      display: flex;
    }

    .card {
      width: 40%;
      border-left: 1px solid ${theme.palette.system.border};
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
      color: ${theme.palette.words.subtitle.natural};

      span {
        font-weight: 900;
      }
    }
  `}
`;

export const StyledTextWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    h1 {
      font-size: 16px;
      color: ${theme.palette.words.title.natural};
      font-weight: 600;
      text-transform: capitalize;
      text-align: left;
    }
    p {
      color: ${theme.palette.words.subtitle.natural};
      font-size: 14px;
      font-weight: 500;
      text-align: left;
    }
  `}
`;
