import styled, { css } from 'styled-components';

export const TableName = styled.h3`
  ${({ theme, ...props }) => css`
    color: ${theme.palette.words.title.natural};
    text-transform: capitalize;
    font-weight: 600;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: ${props.center ? 'center' : 'start'};

    &.logoContent {
      justify-content: start;
      gap: 10px;
    }

    &.position {
      justify-content: space-around;
    }

    &.points {
      color: ${props.red
        ? theme.palette.semantics.feedback.attention.natural
        : theme.palette.semantics.feedback.success.natural};
      background-color: ${props.red
        ? theme.palette.semantics.feedback.attention.light
        : theme.palette.semantics.feedback.success.light};
      border-radius: 30px;
    }

    .logo {
      width: 38px;
      height: 38px;
      border: 1px solid ${theme.palette.brand.secondary.natural};
      border-radius: 50px;
      padding: 2px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .empty {
      background-color: ${theme.palette.system.border};
      min-width: 38px;
      height: 38px;
      border-radius: 50px;
      border: 1px solid ${theme.palette.brand.secondary.natural};
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 900;
      color: ${theme.palette.words.title.natural};
    }

    .table-col {
      display: flex;
      flex-direction: column;
    }

    .svg {
      transform: scale(0.3);
    }
  `}
`;
