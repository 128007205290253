import api from 'services/api';

const requestCaronas = async query => {
  const res = await api.get(`/provider/caronas-autorizadas`, { params: query });
  return res;
};

const requestExcel = async query => {
  const res = await api.get('/excel-provider/caronas', { params: query });
  return res;
};

export { requestCaronas, requestExcel };
