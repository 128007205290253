/* eslint-disable import/order */

// Styled
import * as S from './styled';
import colors from 'styles/colors';

// React
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import Tag from '../Tag';
import { EvaluationView } from './Evaluation';
import GhostButton from 'components/Buttons/Ghost';
import DefaultButton from 'components/Buttons/Default';
import { MediaView } from '../MediaView';

// Material UI
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import DoorFrontOutlinedIcon from '@mui/icons-material/DoorFrontOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import OfflineBoltOutlinedIcon from '@mui/icons-material/OfflineBoltOutlined'; // Quiz
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'; // Teste

// Utils
import { formatModule } from './constants';
import { formatStatus } from '../../actions';
import OnisysLogo from 'images/Onisys_Simbolo.png';

const img =
  'https://firebasestorage.googleapis.com/v0/b/onisysweb.appspot.com/o/capacitacao%2Finfo%2Fcapas%2F1673467756358%2F2023-01-11_17-09-19_Capa_Conteudo_Capacitacao.jpg?alt=media&token=3ca2cd3b-f1c8-438a-a277-88580f7cbdbd';

const PreviewModal = ({ open, onClose, pathname, backPath }) => {
  // Navigate
  const navigate = useNavigate();

  const content = useSelector(state => state.conteudoProvider);
  const [currentModule, setCurrentModule] = useState(null);
  const [currentElement, setCurrentElement] = useState(null);
  const [totalIndex, setTotalIndex] = useState(0);
  const [visibleTab, setVisibleTab] = useState(true);
  const [is_quiz, setIsQuiz] = useState(false);

  useEffect(() => {
    let midias = content?.midias?.length;
    let avaliacao = content?.avaliacao?.length ? 1 : 0;
    setTotalIndex(midias + avaliacao - 1);
    setIsQuiz(content?.avaliacao?.length && content.avaliacao[0].is_quiz);
  }, [content]);
  // ----------------------Modal Controller---------------------------------//
  const [visible, setVisible] = useState('100vh');
  const [slide, setSlide] = useState(0);

  useEffect(() => {
    if (open) {
      openModal();
    }
  }, [open]);

  const openModal = () => {
    setTimeout(() => {
      setVisible('0px');
    }, 150);
  };

  const backModal = () => {
    setVisible('100vh');
    setTimeout(() => {
      onClose();
    }, 250);
  };

  // Controll back arrow in window when modal is opened in same tab (BETA VERSION)
  useEffect(() => {
    if (backPath) {
      window.onpopstate = event => {
        event.preventDefault();
        navigate(backPath);
      };
    }
  }, [backPath]);

  // --------------------------------------------------------------------------------------//

  useEffect(() => {
    let midias = content?.midias?.length;
    if (currentModule !== null && currentModule < midias) {
      setCurrentElement(content.midias[currentModule]);
    } else if (currentModule !== null) {
      setCurrentElement(content.avaliacao);
    }
  }, [currentModule]);

  const handleNextModule = () => {
    if (currentModule < totalIndex) {
      setSlide(300);
      setCurrentModule(currentModule + 1);
      setTimeout(() => {
        setSlide(0);
        let element = document.getElementById('content-over-scroll-box');
        element.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      }, 150);
    } else {
      backModal();
    }
  };

  const handlePrevModule = () => {
    if (currentModule > 0) {
      setSlide(300);
      setCurrentModule(currentModule - 1);
      setTimeout(() => {
        setSlide(0);
        let element = document.getElementById('content-over-scroll-box');
        element.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      }, 150);
    } else {
      setCurrentModule(null);
    }
  };

  const handleByBar = index => {
    setSlide(300);
    setCurrentModule(index);
    setTimeout(() => {
      setSlide(0);
      let element = document.getElementById('content-over-scroll-box');
      element.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }, 150);
  };

  const formatSizeTitle = title => {
    if (title.length > 40) {
      return `${title.slice(0, 40)}...`;
    }
    return title;
  };

  return (
    <S.BigModal open={open} disableEscapeKeyDown visible={visible}>
      <S.Cover>
        <S.Header visible={visibleTab}>
          <div className="left">
            <h1>{formatSizeTitle(content?.geral?.titulo)}</h1>
          </div>
          <div className="medium">
            <img src={OnisysLogo} />
          </div>
          <div className="right">
            <span className="stArea">
              {formatStatus(content?.geral?.status)}
              {/* <MenuOpenIcon
                className="menu"
                onClick={() => setVisibleTab(!visibleTab)}
              /> */}
            </span>
            <CancelRoundedIcon
              htmlColor={colors.greyTiny}
              onClick={() => backModal()}
              style={{ cursor: 'pointer' }}
            />
          </div>
        </S.Header>
        <S.Body>
          <S.Content id="content-over-scroll-box">
            <S.ZeroSelect
              background={content?.geral?.capa || img}
              view={currentModule !== null}
            >
              <div className="titleArea">
                <h1 className="title">{content?.geral?.titulo}</h1>
                <div className="tags">
                  {content?.geral?.tags?.map((tag, index) => (
                    <Tag key={index}>{tag}</Tag>
                  ))}
                </div>
              </div>
              <div className="background">
                <div className="gradient">
                  <h1 className="title">{content?.geral?.titulo}</h1>
                  <div className="description">{content?.geral?.descricao}</div>
                </div>
              </div>
              {currentModule == null && (
                <div className="descriptionArea">
                  <div className="descriptionTab">Descrição</div>
                  <div className="descriptionText">
                    {content?.geral?.descricao}
                  </div>
                </div>
              )}
            </S.ZeroSelect>
            <S.BoxView slide={slide}>
              {currentModule !== null && currentElement?.tipo && (
                <MediaView item={currentElement} />
              )}
              {currentModule !== null && !currentElement?.tipo && (
                <EvaluationView item={currentElement} is_quiz={is_quiz} />
              )}
            </S.BoxView>
            {currentModule !== null && (
              <S.Footer>
                <GhostButton
                  children="Voltar"
                  onClick={() => handlePrevModule()}
                />
                <DefaultButton
                  children={currentModule < totalIndex ? 'Próximo' : 'Concluir'}
                  onClick={() => handleNextModule()}
                />
              </S.Footer>
            )}

            {currentModule === null && (
              <S.Footer>
                <DefaultButton
                  children={totalIndex >= 0 ? 'Iniciar' : 'Concluir'}
                  onClick={
                    totalIndex >= 0
                      ? () => setCurrentModule(0)
                      : () => backModal()
                  }
                />
              </S.Footer>
            )}
          </S.Content>
          <S.Bar visible={visibleTab}>
            <div className="iconAbs" onClick={() => setVisibleTab(!visibleTab)}>
              <ArrowForwardIosOutlinedIcon />
            </div>
            <S.LineModule
              onClick={() => setCurrentModule(null)}
              active={currentModule === null}
            >
              <div className="left">
                <div className="type">
                  <DoorFrontOutlinedIcon />
                  <div className="text">Começar: Introdução</div>
                </div>
                <div className="status">Informacões iniciais</div>
              </div>
              <div className="rigth">
                <PlayCircleFilledIcon />
              </div>
            </S.LineModule>
            {content?.midias?.map((midia, index) => {
              let { Icon: View, label: text } = formatModule(midia.tipo);
              return (
                <S.LineModule
                  key={index}
                  onClick={() => handleByBar(index)}
                  active={currentModule === index}
                >
                  <div className="left">
                    <div className="type">
                      <View />
                      <div className="text">Conteúdo: {text}</div>
                    </div>
                    <div className="status">Iniciar</div>
                  </div>
                  <div className="rigth">
                    <PlayCircleFilledIcon />
                  </div>
                </S.LineModule>
              );
            })}

            {content?.avaliacao?.length > 0 && (
              <S.LineModule
                onClick={() => handleByBar(totalIndex)}
                active={currentModule === totalIndex}
              >
                <div className="left">
                  <div className="type">
                    {is_quiz ? (
                      <OfflineBoltOutlinedIcon />
                    ) : (
                      <CheckCircleOutlineOutlinedIcon />
                    )}
                    <div className="text">
                      Perguntas: {is_quiz ? 'Quiz' : 'Teste Online'}
                    </div>
                  </div>
                  <div className="status">Iniciar</div>
                </div>
                <div className="rigth">
                  <PlayCircleFilledIcon />
                </div>
              </S.LineModule>
            )}
          </S.Bar>
        </S.Body>
      </S.Cover>
    </S.BigModal>
  );
};

export default PreviewModal;
