import {
  getTiposDesvios,
  getMotoristas,
  getVeiculos,
} from 'constants/_SERVICES/provider';

// Pages
import RankingDirecaoProvider from 'pages/Provider/RankingDirecao';
import RankingDescargaProvider from 'pages/Provider/RankingDescarga';

export default {
  '/ranking-direcao': {
    date: true,
    title: 'Ranking de Direção',
    defaults: ['empresas', 'operations'],
    pageFilterName: 'filterDirecaoRankingProvider',
    filters: [
      {
        name: 'desvio',
        placeholder: 'Filtrar por Desvio',
        section: 'Desvio, Veículo e Motorista',
        mode: 'single',
        data: async () => getTiposDesvios(),
      },
      {
        name: 'veiculo',
        placeholder: 'Filtrar por Veículo',
        section: 'Desvio, Veículo e Motorista',
        mode: 'single',
        data: async () => getVeiculos(),
      },
      {
        name: 'motoristas',
        placeholder: 'Filtrar por Tipo de Motorista',
        section: 'Desvio, Veículo e Motorista',
        mode: 'single',
        data: async () => getMotoristas(),
      },
    ],
    page: <RankingDirecaoProvider />,
  },

  '/ranking-descarga': {
    date: true,
    title: 'Ranking de Descarga',
    defaults: ['empresas', 'operations'],
    pageFilterName: 'filterDescargaRankingProvider',
    filters: [
      {
        name: 'desvio',
        placeholder: 'Filtrar por Desvio',
        section: 'Desvio, Veículo e Motorista',
        mode: 'single',
        data: async () => getTiposDesvios(),
      },
      {
        name: 'veiculo',
        placeholder: 'Filtrar por Veículo',
        section: 'Desvio, Veículo e Motorista',
        mode: 'single',
        data: async () => getVeiculos(),
      },
      {
        name: 'motoristas',
        placeholder: 'Filtrar por Tipo de Motorista',
        section: 'Desvio, Veículo e Motorista',
        mode: 'single',
        data: async () => getMotoristas(),
      },
    ],
    page: <RankingDescargaProvider />,
  },
};
