import { Logs } from 'components/Logs';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { requestLogs } from '../services';

const Historico = () => {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getLogs = async () => {
      let pathName = window.location.pathname;
      pathName = pathName.split('/');
      pathName = pathName[pathName.length - 1];

      setLoading(true);
      const res = await requestLogs(pathName);
      setLoading(false);
      if (res.success) {
        setLogs(res.logs);
      } else {
        toast.error(res.message);
      }
    };
    getLogs();
  }, []);

  return <Logs logs={logs} loading={loading} />;
};

export default Historico;
