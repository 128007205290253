import React, { useState, useEffect, useRef } from 'react';
import Header from '../components';
import * as S from './styled';
import Andamento from './andamento';
import Concluido from './completados';
import Reprovados from './reporvacoes';

const Aprendizado = () => {
  const [tabSelected, setTabSelect] = useState(1);
  const [info, setInfo] = useState({});

  return (
    <>
      <h1>Aprendizado</h1>
      <S.Container>
        <Header nome={info?.nome} foto={info?.foto} />
        <S.containerBanner>
          <div className="tabs">
            <div className="title-tab" onClick={() => setTabSelect(1)}>
              <div className="container-title">Conteúdos em Andamento</div>
              {tabSelected === 1 ? (
                <div className="indicator" />
              ) : (
                <div style={{ marginTop: 15 }} />
              )}
            </div>
            <div className="title-tab" onClick={() => setTabSelect(2)}>
              <div className="container-title">Completados</div>
              {tabSelected === 2 ? (
                <div className="indicator" />
              ) : (
                <div style={{ marginTop: 15 }} />
              )}
            </div>
            <div className="title-tab" onClick={() => setTabSelect(3)}>
              <div className="container-title">Reprovados</div>
              {tabSelected === 3 ? (
                <div className="indicator" />
              ) : (
                <div style={{ marginTop: 15 }} />
              )}
            </div>
          </div>
        </S.containerBanner>
        <div>
          {tabSelected === 1 && <Andamento setInfo={val => setInfo(val)} />}
          {tabSelected === 2 && <Concluido setInfo={val => setInfo(val)} />}
          {tabSelected === 3 && <Reprovados setInfo={val => setInfo(val)} />}
        </div>
      </S.Container>
    </>
  );
};

export default Aprendizado;
