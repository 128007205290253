import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CircleIcon from '@mui/icons-material/Circle';
import { useTheme } from 'styled-components';
import { Tooltip } from '@mui/material';
import * as S from './styled';

export const columns = [
  {
    header: 'Posição',
    id: 'posicao',
    accessor: d => (d.posicao ? parseInt(d.posicao) : '-'),
    sort: true,
    width: 100,
    align: 'center',
    children: (value, item) => {
      const theme = useTheme();
      if (item.posicao_anterior != null) {
        if (item.posicao_anterior > item.posicao)
          return (
            <S.TableName center className="position">
              <Tooltip
                title={`Posição anterior: ${item.posicao_anterior}`}
                arrow
                placement="top"
              >
                <ArrowDropUpIcon
                  htmlColor={theme.palette.brand.secondary.natural}
                />
              </Tooltip>
              <span>{item.posicao}</span>
            </S.TableName>
          );
        if (item.posicao_anterior < item.posicao)
          return (
            <S.TableName center className="position">
              <Tooltip
                title={`Posição anterior: ${item.posicao_anterior}`}
                arrow
                placement="top"
              >
                <ArrowDropDownIcon
                  htmlColor={theme.palette.semantics.feedback.attention.natural}
                />
              </Tooltip>
              <span>{item.posicao}</span>
            </S.TableName>
          );
        if (item.posicao_anterior == item.posicao)
          return (
            <S.TableName center className="position">
              <CircleIcon
                className="svg"
                htmlColor={theme.palette.semantics.feedback.success.natural}
              />
              <span>{item.posicao}</span>
            </S.TableName>
          );
      } else {
        return <S.TableName center>{item.posicao}</S.TableName>;
      }
    },
  },
  {
    header: 'Empresa',
    id: 'nome',
    sort: true,
    tooltip: true,
    type: 'string',
    picture: 'foto',
    width: 230,
  },
  {
    header: 'Pontos/Km',
    id: 'pontos_km',
    sort: true,
    type: 'number',
    align: 'center',
    value: value =>
      Number(value).toLocaleString(undefined, {
        maximumFractionDigits: 2,
      }),
    conditional: [
      {
        condition: (value, item) => !item.isMedia,
        style: theme => ({
          backgroundColor: theme.palette.semantics.feedback.attention.light,
          color: theme.palette.semantics.feedback.attention.natural,
          textAlign: 'center',
        }),
      },
      {
        condition: (value, item) => item.isMedia,
        style: theme => ({
          backgroundColor: theme.palette.semantics.feedback.success.light,
          color: theme.palette.semantics.feedback.success.natural,
          textAlign: 'center',
        }),
      },
    ],
  },
  {
    header: 'Km',
    id: 'km_viagem',
    sort: true,
    type: 'number',
    align: 'center',
    value: value =>
      Number(value).toLocaleString(undefined, {
        maximumFractionDigits: 2,
      }),
  },
  {
    header: 'Total de Pontos',
    id: 'pontos',
    sort: true,
    type: 'number',
    align: 'center',
  },
  {
    header: 'Viagens',
    id: 'viagens',
    sort: true,
    type: 'number',
    align: 'center',
  },
  {
    header: 'Hrs Viagem',
    id: 'horas_viagem',
    sort: true,
    type: 'number',
    align: 'center',
  },
  {
    header: 'Pontos Telemetria',
    id: 'desvios_telemetria',
    sort: true,
    type: 'number',
    align: 'center',
  },
  {
    header: 'Pontos Cerca Eletronica',
    id: 'desvios_cerca',
    sort: true,
    type: 'number',
    align: 'center',
  },
  {
    header: 'Pontos Excesso Velocidade',
    id: 'desvios_velocidade',
    sort: true,
    type: 'number',
    align: 'center',
  },
  {
    Header: 'Ações',
    id: 'acoes',
    disableSortBy: true,
    accessor: d => (d.acoes ? String(d.acoes) : '-'),
    show: true,
  },
];

export const fields = [
  {
    label: 'ID',
    value: 'id',
    selected: true,
    default: true,
  },
  {
    label: 'Empresa',
    value: 'nome',
    selected: true,
    default: true,
  },
  {
    label: 'Filial',
    value: 'filial',
    selected: false,
    default: false,
  },
  {
    label: 'Pontos/Km',
    value: 'pontos_km',
    selected: true,
    default: true,
  },
  {
    label: 'Km',
    value: 'km_viagem',
    selected: true,
    default: true,
  },
  {
    label: 'Total de Pontos',
    value: 'pontos',
    selected: true,
    default: true,
  },
  {
    label: 'Viagens',
    value: 'viagens',
    selected: true,
    default: true,
  },
  {
    label: 'Hrs Viagem',
    value: 'horas_viagem',
    selected: true,
    default: true,
  },
  {
    label: 'Pontos Telemetria',
    value: 'desvios_telemetria',
    selected: true,
    default: true,
  },
  {
    label: 'Pontos Cerca Eletrônica',
    value: 'desvios_cerca',
    selected: true,
    default: true,
  },
  {
    label: 'Pontos Excesso Velocidade',
    value: 'desvios_velocidade',
    selected: true,
    default: true,
  },
];

export const resetExcelFields = fields => {
  return fields.map(i => {
    if (!i.default) i.selected = false;
    return i;
  });
};
