import produce from 'immer';
import * as types from './types';

const INITIAL_STATE = {
  executor: '',
  status: '',
  aprovador: '',
  base: '',
  tmc: '',
  estado: '',
  tipo_cliente: '',
  client: '',
  empresas: '',
};

export default function filterLayout(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case types.SET_FILTER_LAYOUT: {
        draft.executor = action.payload.query.executor;
        draft.status = action.payload.query.status;
        draft.aprovador = action.payload.query.aprovador;
        draft.base = action.payload.query.base;
        draft.tmc = action.payload.query.tmc;
        draft.estado = action.payload.query.estado;
        draft.tipo_cliente = action.payload.query.tipo_cliente;
        draft.client = action.payload.query.client;
        draft.empresas = action.payload.query.empresas;
        break;
      }
      default:
    }
  });
}
