import { formatNewDate } from 'utils/dates';
import * as S from './styled';

export const fequencyModule = cont => {
  const inicio = cont?.data_inicio;
  const final = cont?.data_fim;

  let finalModule = null;

  if (final) {
    finalModule = (
      <S.PreviewDate>
        Previsão de encerramento:{' '}
        <span className="raw">{formatNewDate(final)}</span>
      </S.PreviewDate>
    );
  } else {
    finalModule = (
      <S.PreviewDate>
        Previsão de encerramento: <span className="raw">Nunca encerrar</span>
      </S.PreviewDate>
    );
  }

  const initialDate = (
    <S.PreviewDate>
      Data de início: <span className="raw">{formatNewDate(inicio)}</span>
    </S.PreviewDate>
  );

  return (
    <>
      {initialDate}
      {finalModule}
    </>
  );
};

const getDay = day => {
  const objDays = [
    { text: 'SEGUNDA', item: 'segunda-feira', preFix: 'toda' },
    { text: 'TERCA', item: 'terça-feira', preFix: 'toda' },
    { text: 'QUARTA', item: 'quarta-feira', preFix: 'toda' },
    { text: 'QUINTA', item: 'quinta-feira', preFix: 'toda' },
    { text: 'SEXTA', item: 'sexta-feira', preFix: 'toda' },
    { text: 'SABADO', item: 'sábado', preFix: 'todo' },
    { text: 'DOMINGO', item: 'domingo', preFix: 'todo' },
  ];

  for (const i in objDays) {
    if (objDays[i].text === day) {
      return `${objDays[i].preFix} ${objDays[i].item}`;
    }
  }
};
