import styled, { css } from 'styled-components';

export const Container = styled.form`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
`;

export const Search = styled.input`
  ${({ theme, isLoading, transparent }) => css`
    height: 40px;
    width: 264px;
    border-radius: 5px;
    border: 2px solid ${theme.palette.system.border};
    background-color: ${transparent
      ? theme.palette.system.white
      : theme.palette.brand.primary.background};
    padding-left: 35px;
    font-size: 18px;
    font-weight: 500;
    color: ${theme.palette.words.text.natural};
    transition: all 0.2s ease-in-out;

    @media (max-width: 868px) {
      width: 200px;
    }

    ${isLoading &&
    css`
      opacity: 0.7;
      border: 2px solid ${theme.palette.system.border};
    `}

    &::placeholder {
      font-size: 18px;
      color: ${theme.palette.words.text.light};
    }

    ${!isLoading &&
    css`
      &:hover {
        border: 2px solid ${theme.palette.words.text.natural};
      }

      &:focus {
        border: 2px solid ${theme.palette.brand.secondary.natural};
      }
    `}
  `}
`;

export const IconContainer = styled.div`
  ${({ theme }) => css`
    position: absolute;
    padding: 5px;
    pointer-events: none;

    .icon {
      color: ${theme.palette.words.text.light};
      margin-bottom: -5px;
    }
  `}
`;
