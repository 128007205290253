import React from 'react';
import * as S from './styled';
import { Skeleton } from '@mui/material';

export const Card = ({ type, direction = 'row', title, subtitle, loading }) => {
  return (
    <S.Card direction={direction}>
      <div className="info">
        <div>Tempo médio de tratativa</div>
        {!!subtitle && <div className={`subtitle ${type}`}>{subtitle}</div>}
      </div>
      {loading ? (
        <div className={`title  ${type}`}>
          <Skeleton
            variant="rounded"
            animation="wave"
            width="50%"
            height={24}
          />
        </div>
      ) : (
        <div className={`title  ${type}`}>{title}</div>
      )}
    </S.Card>
  );
};
