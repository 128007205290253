/* eslint-disable import/order */

// Styles
import * as S from './styled';
import colors from 'styles/colors';

// React
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect, memo } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { setConteudo } from 'store/modules/provider/capacitacaoConteudo/actions';

// Components
import { EmptyContent } from './Empty';
import Loading from 'components/Loading';
import { DraggableItems } from './DraggableList';
import ConfirmModal from 'components/ConfirmModal';
import { MediaCard } from '../../components/MediaCard';

// Material UI
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

// Utils
import { validation } from './actions';
import { mediaType } from './constants';
import * as services from '../services';
import { moreMedia, minusMedia } from './actions';

export const Midias = ({
  setChanged,
  trySave,
  setTrySave,
  setCurrentStep,
  isEdit,
  isPublished,
  steps,
}) => {
  // redux
  const dispatch = useDispatch();

  const { id, midias } = useSelector(state => state.conteudoProvider);
  const [errors, setErrors] = useState(null);
  const [counters, setCounters] = useState({
    text: 0,
    media: 0,
    slide: 0,
    video: 0,
    videoUploading: 0,
  });

  const [media, setMedia] = useState([]);
  const [currentItem, setCurrentItem] = useState(null);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (midias && midias?.length > 0) {
      setMedia(JSON.parse(JSON.stringify(midias)));
      const copyCounters = { ...counters };

      midias.map(item => {
        switch (item.tipo) {
          case 'TEXTO':
            copyCounters.text++;
            break;
          case 'IMAGEM':
            copyCounters.media++;
            break;
          case 'PDF':
            copyCounters.slide++;
            break;
          case 'VÍDEO':
            copyCounters.video++;
            break;
          case 'VÍDEO_UPLOADING':
            copyCounters.videoUploading++;
            break;
        }
      });

      setCounters(copyCounters);
      setChanged(false);
    } else {
      setMedia([]);
      setChanged(true);
    }
  }, [midias]);

  // -----------------------------DRAG AND DROP--------------------------------//

  useEffect(() => {
    if (trySave) {
      if (!media || media?.length === 0) {
        setTrySave(false);
        toast.error('Adicione ao menos uma mídia no conteúdo');
        return;
      }

      const errors = validation(media);
      if (errors?.length > 0) {
        setErrors(errors);
        toast.error('Verifique os campos obrigatórios!');
        let smallIndex = errors[0].index;
        let firstElementError = document.getElementById(
          `card-section-content-${smallIndex}`,
        );
        firstElementError?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
        setTrySave(false);
      } else {
        setErrors(null);
        sendRequest(media);
      }
    }
  }, [trySave]);

  const sendRequest = async data => {
    setLoading(true);

    let oderedData = data.map((item, index) => {
      return {
        ...item,
        ordem: index,
        draggableId: index,
      };
    });

    const res = await services.EditInfos(id, {
      pagina: 'midia',
      midias: oderedData,
    });

    if (res.success) {
      setChanged(false);
      setTrySave(false);
      setLoading(false);
      !isEdit && setCurrentStep(steps[2]);
      dispatch(
        setConteudo(
          res.data.midias
            .sort((a, b) => a.ordem - b.ordem)
            .map(item => ({ ...item, draggableId: item.ordem })),
          'midias',
        ),
      );
      toast.success('Informações salvas com sucesso!');
    } else {
      toast.error(res.message);
      setTrySave(false);
      setLoading(false);
    }
  };

  const reorder = (list, startIndex, endIndex) => {
    setErrors(null);
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    setChanged(true);
    return result;
  };

  const onDragEnd = ({ destination, source }) => {
    if (!destination) return;
    const reordered = reorder(media, source.index, destination.index);
    setMedia(reordered);
  };

  const DraggableList = memo(({ items, onDragEnds }) => {
    return (
      <DragDropContext onDragEnd={onDragEnds}>
        <Droppable droppableId="droppable-list">
          {provided => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {items.map((item, index) => (
                <DraggableItems
                  item={item}
                  index={index}
                  key={String(item.ordem)}
                  media={media}
                  setMedia={setMedia}
                  setChanged={setChanged}
                  counters={counters}
                  setCounters={setCounters}
                  errors={errors}
                  setErrors={setErrors}
                  isPublished={isPublished}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  });

  // -----------------------------------ACTIONS---------------------------------//

  const handleMinus = (key, type, skip) => {
    minusMedia({
      key,
      type,
      block: false,
      counters,
      setCounters,
      setMedia,
      media,
      skip,
      setCurrentItem,
      setOpenConfirmDelete,
      errors,
      setErrors,
      blockAction: () => {
        setCurrentItem({ key, type });
        setOpenConfirmDelete(true);
      },
    });
  };

  const handleMore = (key, type) => {
    moreMedia({
      key,
      type,
      block: false,
      counters,
      setCounters,
      setMedia,
      media,
      errors,
      setErrors,
    });
  };

  // -----------------------------------RENDER---------------------------------//

  return (
    <>
      {!loading && (
        <>
          <S.Container>
            <S.Square>
              {mediaType.map((item, key) => (
                <MediaCard
                  key={key}
                  content={item}
                  counters={counters}
                  pressMore={(key, type) => handleMore(key, type)}
                  pressMinus={(key, type) => handleMinus(key, type)}
                />
              ))}
            </S.Square>
          </S.Container>
          <div style={{ marginTop: 20 }} />

          <div
            style={{
              position: 'relative',
              zIndex: 1,
            }}
          >
            <h1>Preencha o conteúdo</h1>
            {media.length > 0 && (
              <S.Container>
                <DraggableList items={media} onDragEnds={onDragEnd} />
              </S.Container>
            )}
            {media.length === 0 && <EmptyContent />}
          </div>

          <ConfirmModal
            open={openConfirmDelete}
            handleClose={() => setOpenConfirmDelete(false)}
            title="Tem certeza que deseja excluir essa mídia?"
            subtitle="Todos os campos preenchidos no item serão apagados."
            titleIcon={
              <HighlightOffIcon
                sx={{ color: colors.redDanger }}
                fontSize="medium"
              />
            }
            buttonText="Confirmar"
            onClick={() =>
              handleMinus(currentItem?.key, currentItem?.type, true)
            }
          />
        </>
      )}
      {loading && (
        <S.LoadingBox>
          <Loading />
        </S.LoadingBox>
      )}
    </>
  );
};
