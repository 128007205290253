import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setFilterProvider } from 'store/modules/provider/filterDesvioDescargaProvider/actions';
import { useLocation, useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';
import { SaveAlt, Check } from '@mui/icons-material';
import { Divider, Grid } from '@mui/material';

import { formatNameDate, formatNewDate } from 'utils/dates';
import ExportToExcel from 'utils/exportToCvs';

import Tabs from 'components/Tabs';
import GhostButton from 'components/Buttons/Ghost';
import Card from 'components/Cards/Indicador';
import ExcelModal from 'components/ExcelModal';

import { ReactComponent as file } from 'images/icons/sidebar/file.svg';
import { statusTabs, columns2, fields, resetExcelFields } from './constants';

import { requestInfractions, requestCards, requestExcel } from './services';

import * as S from './styled';
import { getRouterQueryParams } from '../../../utils/router-query-params';
import { DefaultTable } from 'components/_Table/templates/default';
import { useQuery } from 'react-query';

const DesviosDescarga = () => {
  // Redux e hooks
  const filter = useSelector(state => state.filterProvider);
  const filterDesvio = useSelector(state => state.filterDesvioDescargaProvider);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedInfractions, setSelectedInfractions] = useState([]);

  const [query, setQuery] = useState(null);
  const [loadingTab, setLoadingTab] = useState(false);
  const [resetTable, setResetTable] = useState(false);
  const [loadingLines, setLoadingLines] = useState([]);

  // Excel Fields
  const [openExcelModal, setOpenExcelModal] = useState(false);
  const [excelFields, setExcelFields] = useState(fields);
  const [loadingExcel, setLoadingExcel] = useState(false);

  const getExpirationDate = row => {
    const parameters = resData?.data?.parametros;
    if (parameters) {
      let newDate = new Date(row.createdAt);
      newDate.setDate(newDate.getDate() + +parameters.valor);
      return formatNewDate(newDate);
    }

    return '-';
  };

  // ********************  Funções da página *****************************

  const {
    refetch: fetchData,
    isFetching,
    isLoading: loadingInfractions,
    data: resData,
  } = useQuery(
    ['infractions-descarga-index', query],
    () => query && requestInfractions(query),
    {
      refetchOnWindowFocus: false,
      onSuccess: () => {
        setLoadingLines([]);
        setLoadingTab(false);
        resetTable && setResetTable(false);
      },
    },
  );

  const handleColumns = () => {
    const col = [...columns2].map(item => {
      if (item.id === 'createdAt') {
        return {
          ...item,
          subRow: {
            prefix: 'Vencimento:',
            value: (_, item) => getExpirationDate(item),
          },
        };
      }
      return item;
    });

    if (filterDesvio.status === 'FINALIZADO')
      col[5] = {
        header: 'Data Conclusão',
        id: 'data_encerramento',
        type: 'date',
        sort: true,
      };
    else if (filterDesvio.status === 'DELETADO')
      col[5] = {
        header: 'Data Exclusão',
        id: 'data_exclusao',
        type: 'date',
        sort: true,
      };

    return col;
  };

  // Atualiza cards de acordo com os filtros selecionados
  // Atualiza tabela após cads
  const { isLoading: loadingCards, data: resCards } = useQuery(
    ['infractions-descarga-provider-cards', { ...filter, ...filterDesvio }],
    () =>
      requestCards({
        ...filter,
        ...filterDesvio,
      }),
    {
      refetchOnWindowFocus: false,
    },
  );

  const mode = getRouterQueryParams({ location: useLocation }).get('mode');

  useEffect(() => {
    mode &&
      dispatch(
        setFilterProvider({
          status: mode,
        }),
      );
  }, [mode]);

  const initial = useRef(true);
  useEffect(() => {
    if (initial.current) {
      initial.current = false;
      return;
    }

    setResetTable(true);
  }, [filter, filterDesvio, selectedCard]);

  // Troca do tab (filterDesvio.status)
  const handleStatusTab = async (event, newValue) => {
    setSelectedCard(null);
    setLoadingTab(true);
    dispatch(
      setFilterProvider({
        status: newValue,
      }),
    );
  };

  // Só habilita clique no card quando nao há nada carregando
  // Verifica se card clicado há valor valido: nao nulo e maior que 0.
  // Se sim: seleciona ou desseleciona ele
  const handleClickCard = type => {
    if (!loadingCards && !loadingInfractions) {
      const cards = resCards?.data?.data || [];
      const card = cards.find(item => item.type === type);
      if (card.value) {
        if (type === selectedCard) setSelectedCard(null);
        else setSelectedCard(type);
      }
    }
  };

  const handleRequestExcel = async () => {
    setLoadingExcel(true);

    const newFields = excelFields.filter(item => item.selected === true);
    const formatedDate = formatNameDate(new Date());

    const newQuery = {
      ...filter,
      ...filterDesvio,
      excelFields: newFields,
      ids: selectedInfractions,
    };

    let { initialDate } = filter;
    let { finalDate } = filter;
    initialDate = Date.parse(initialDate);
    finalDate = Date.parse(finalDate);

    if (finalDate - initialDate > 7889400000 && !selectedInfractions.length) {
      toast.error('Não é possível exportar períodos maiores que 3 meses');
      setLoadingExcel(false);
      return;
    }

    const res = await requestExcel(newQuery);
    if (res.data && res.data?.data?.excel) {
      ExportToExcel({
        excel: res.data.data.excel,
        name: `desvios-descarga_${formatedDate}`,
      });
      toast.success(res.data.message);
    } else if (res.data.message) toast.error(res.data?.message);

    setExcelFields(resetExcelFields(fields));
    setLoadingExcel(false);
    setOpenExcelModal(false);
  };

  // ********************  Actions *****************************

  const handleOpenNewTab = id => {
    return window.open(`/desvio-descarga/${id}`);
  };

  const handleOpenSameTab = id => {
    return navigate(`/desvio-descarga/${id}`);
  };

  const actions = [
    {
      title: 'Abrir em nova guia',
      function: handleOpenNewTab,
    },
    {
      title: 'Ver detalhe',
      function: handleOpenSameTab,
    },
  ];

  // Renderiza cards por status: ABERTO, PENDENTE, FINALIZADO, DELETADO
  const renderCards = () => {
    const cards = resCards?.data?.data || [];
    return (
      <Grid container spacing={2} marginBottom="25px">
        {cards &&
          cards.map(card => {
            return (
              <Grid item key={card.type} xs={12} md={4} xl={4}>
                <Card
                  value={card.value}
                  icon={card.icon}
                  text={card.text}
                  handleClick={() => handleClickCard(card.type)}
                  selected={selectedCard === card.type}
                  loading={loadingCards}
                  disabled={isFetching}
                />
              </Grid>
            );
          })}
      </Grid>
    );
  };

  return (
    <>
      <S.Main>
        <Grid
          marginBottom={1}
          item
          md={12}
          display="flex"
          justifyContent="space-between"
        >
          <h1>Desvios de Descarga</h1>

          <GhostButton
            startIcon={<SaveAlt />}
            size="medium"
            onClick={() => setOpenExcelModal(true)}
            style={{ marginLeft: '10px' }}
          >
            EXPORTAR
          </GhostButton>
        </Grid>

        <Grid marginBottom={0} item md={12} display="flex">
          <Tabs
            value={filterDesvio.status}
            items={statusTabs}
            onChange={handleStatusTab}
            disabled={loadingCards || loadingInfractions}
            last
          />
        </Grid>

        <Grid marginBottom={3} item md={12}>
          <Divider />
        </Grid>

        {renderCards()}

        <DefaultTable
          data={resData?.data?.rows || []}
          columns={handleColumns()}
          setSelectedRows={setSelectedInfractions}
          loading={loadingInfractions || loadingTab}
          pageCount={resData?.data?.total || 0}
          visualizedKey="visto"
          local={false}
          actions={actions}
          reset={resetTable}
          onClickRow={handleOpenSameTab}
          loadingSelection={loadingLines}
          setQuery={q =>
            setQuery({ ...q, ...filter, ...filterDesvio, card: selectedCard })
          }
          sortBy={{ id: 'data_desvio', order: 'DESC' }}
          placeholder="Buscar por ID, Placa ou Motorista"
          empty={{
            title: 'Ops! Você não tem nenhum desvio disponível.',
            description: 'Verifique os filtros aplicados!',
          }}
        />
      </S.Main>

      {openExcelModal && (
        <ExcelModal
          onClick={handleRequestExcel}
          open={openExcelModal}
          handleClose={() => setOpenExcelModal(false)}
          title="Selecionar os campos de Excel"
          titleIcon={file}
          subtitle="Selecionar abaixo:"
          setData={setExcelFields}
          data={excelFields}
          loading={loadingExcel}
        />
      )}
    </>
  );
};

export default DesviosDescarga;
