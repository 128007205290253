import React from 'react';

import * as S from './styled';

const BarChartVertical = ({ data = [], loading = false, meta = null }) => {
  const colors = ['#00bcd4', '#0000FF', '#ffa726', '#e57373'];

  return (
    <S.ChartContainer>
      {meta && <S.Meta>Meta: {`${meta}%`}</S.Meta>}
      <S.BarChart>
        {data.map((item, index) => (
          <S.ContainerBarChart key={item.name}>
            <S.Label color={colors[index]}>{item.name}</S.Label>
            <S.BarContainer>
              <S.Bar value={item.value} color={colors[index]} />
              <S.Value color={colors[index]}>{item.value}%</S.Value>
            </S.BarContainer>
          </S.ContainerBarChart>
        ))}
      </S.BarChart>
    </S.ChartContainer>
  );
};
export default BarChartVertical;
