import api from 'services/api';

const requestInfractions = async query => {
  const res = await api.get('/acoes_motoristas', { params: query });
  return res;
};

const fetchInfraction = async (id, query) => {
  const res = await api.get(`/acoes_motoristas/${id}`, { params: query });
  if (res.data.success)
    return {
      ...res.data?.data,
      previous: res.data?.previous,
      next: res.data?.next,
      violations: res.data?.violations,
    };
  throw res.data.message;
};

const requestHistory = async id => {
  const res = await api.get(`/acoes_motoristas/${id}/logs`);
  return res;
};

const requestExcel = async query => {
  const res = await api.get('/excel/telematics_actions_drivers', {
    params: query,
  });
  return res;
};

const saveInfractions = async data => {
  const res = await api.put(`/acoes_motoristas`, data);
  return res;
};

export {
  requestInfractions,
  fetchInfraction,
  requestExcel,
  requestHistory,
  saveInfractions,
};
