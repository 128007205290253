import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import TextInput from 'components/Inputs/TextField';
import DropZoneArchives from 'components/Inputs/DropZoneCustom';
import InputAttachedFile from 'components/Inputs/InputAttachedFile2';
import * as S from './styled';

export const Evidencias = ({
  evaluation,
  setChanged,
  setData,
  disabled,
  saveImg,
  setSaveImg,
}) => {
  const [obs, setObs] = useState(
    evaluation.observacoes ? evaluation.observacoes : '',
  );
  const [archive, setArchive] = useState(
    evaluation.arquivo ? evaluation.arquivo : null,
  );
  const [addImages, setAddImages] = useState(null);
  const [editImages, setEditImages] = useState(null);
  const [deleteImages, setDeleteImages] = useState(null);

  useEffect(() => {
    setData({
      observacoes: obs,
      arquivo: archive,
      addImages,
      editImages,
      deleteImages,
    });
  }, [obs, archive, addImages, editImages, deleteImages]);

  const changeMonitoring = (value, func) => {
    if (!value && !func) {
      setChanged(true);
      return;
    }

    if (value?.length >= 1024) return;
    func(value);
    setChanged(true);
  };

  // -------------------------IMAGES CONTROLL-------------------------------//
  const addImage = item => {
    setAddImages(item);
  };

  const deleteImagem = item => {
    setDeleteImages(item);
  };

  const editImagem = item => {
    setEditImages(item);
  };

  return (
    <S.Container>
      <Grid item xs={12} sm={12}>
        <InputAttachedFile
          idInput="input_file_detalhe_avaliacaodescarga"
          label="Anexar Arquivo"
          inputLabel="Anexar Arquivo"
          fileUrl={archive}
          setFileUrl={value => changeMonitoring(value, setArchive)}
          fileDir={`avaliacao/${evaluation.id}`}
          disabled={evaluation.status !== 'PENDENTE'}
        />
      </Grid>
      <Grid item xs={12} paddingBottom="10px" marginTop="20px">
        <DropZoneArchives
          data={evaluation.imagens}
          multiple
          fileDir={`avaliacao/imagens/${evaluation?.id}`}
          addFunction={addImage}
          deleteFunction={deleteImagem}
          editFunction={editImagem}
          saveNow={saveImg}
          setSaveNow={setSaveImg}
          changeMonitoring={changeMonitoring}
          haveLegend
          label="Adicionar Imagens"
          titleButton="Adicionar Imagens"
          typesString="jpeg, jpg, png, bmp, pdf"
          types={['image/*', 'application/pdf']}
          onlyRead={evaluation.status !== 'PENDENTE'}
        />
      </Grid>
      <TextInput
        label="Observações"
        placeholder="Insira sua observação"
        value={obs}
        onChange={e => changeMonitoring(e.target.value, setObs)}
        InputProps={{ multiline: true }}
        multiline
        rows={6}
        disabled={disabled}
        variant={disabled ? 'filled' : 'outlined'}
      />
      <div className="counter">{`${obs ? obs.length : 0}/1023`}</div>
    </S.Container>
  );
};
