import styled, { css } from 'styled-components';
import { Modal } from '@mui/material';

export const ModalType = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Background = styled.div`
  width: 540px;
  height: 580px;
  background-color: ${({ theme }) => theme.palette.system.overlay};
  border-radius: 4px;
  overflow: hidden;
`;

export const Header = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    height: 75px;

    .right {
      display: flex;
      justify-items: center;
      align-items: center;

      label {
        cursor: pointer;
        border: 1px solid ${theme.palette.words.title.natural};
        color: ${theme.palette.words.title.natural};
        font-weight: 800;
        font-size: 14px;
        padding: 10px 15px;
        border-radius: 5px;
        text-transform: uppercase;
        margin-right: 10px;

        input {
          display: none;
        }

        &:hover {
          background-color: ${theme.palette.brand.primary.background};
          border: 1px solid ${theme.palette.brand.secondary.natural};
          transition: all ease 0.2s;
        }
      }
    }
  `}
`;

export const LeftHeader = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled.h2`
  margin-left: 10px;
`;

export const ImagesArea = styled.div`
  ${({ theme }) => css`
    width: 100%;
    background-color: ${theme.palette.system.black};
    height: 350px;
    position: relative;
    display: flex;
    align-items: center;

    .arrow {
      background-color: ${theme.palette.system.white};
      position: absolute;
      width: 52px;
      height: 52px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 52px;
      cursor: pointer;
      z-index: 3;
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }

    .back {
      left: 0;
      margin-left: 19px;
    }

    .next {
      right: 0;
      margin-right: 19px;
    }
  `}
`;

export const Slider = styled.div`
  height: 100%;
  display: flex;
  width: calc(${props => props.width} * 540px);
  margin-left: -${props => props.margin}px;
  transition: all ease 0.5s;
`;

export const Sliders = styled.div`
  ${({ theme }) => css`
    width: 540px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${theme.palette.system.black};

    embed {
      height: 100%;
      width: auto;
      max-width: 100%;
    }
  `}
`;

export const LegendArea = styled.div`
  padding: 0px 25px;
  margin-top: ${props => (props.haveLegend ? '25px' : '80px')};
`;

export const ButtonsArea = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  padding: 0px 25px;
`;

export const ButtonDelete = styled.button`
  ${({ theme }) => css`
    border: none;
    border: 2px solid ${theme.palette.semantics.feedback.attention.natural};
    color: ${theme.palette.semantics.feedback.attention.natural};
    font-weight: 900;
    border-radius: 4px;
    font-size: 16px;
    padding: 5px 25px;
    background-color: ${theme.palette.system.transparent};
  `}
`;
