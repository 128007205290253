import api from 'services/api';

export const createRoute = async data => {
  const res = await api.post('/rotograma', data);
  return res;
};

export const editRoute = async data => {
  const res = await api.put(`/rotograma/${data.id}`, data);
  return res;
};

export const deleteRotograma = async data => {
  const res = await api.delete(`/rotograma/${data.id}`, data);
  return res;
};

export const getCards = async query => {
  const res = await api.get('/rotograma-cards', { params: query });
  return res;
};

export const getRotogramas = async query => {
  const res = await api.get('/rotograma', { params: query });
  return res.data;
};

export const requestExcel = async query => {
  const res = await api.get('/excel/rotograma', { params: query });
  return res;
};

export const requestLogs = async id => {
  const res = await api.get(`/rotograma-logs/${id}`);
  return res.data;
};
