import React, { useCallback, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { Controller, useFieldArray } from 'react-hook-form';
import { Divider, Grid, Tooltip } from '@mui/material';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';

import AddIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import SelectCreatableCustom from 'components/Inputs/SelectCreatableCustom';
import { DashedButton } from 'components/Buttons/Dashed';
import TextInput from 'components/Inputs/TextField';
import Switch from '../components/Switch';
import * as services from '../../services';
import Criticidade from '../components/Criticidade';
import Collapse from '../components/Collapse';

const Manuais = ({
  control,
  setValue,
  errors,
  disableForm,
  customDisableForm,
  watch,
  isProvider,
}) => {
  const theme = useTheme();
  const [deleteTipoDesvioError, setDeleteTipoDesvioError] = useState(false);
  const [tiposDesvios, setTiposDesvios] = useState([]);

  const { data: _tiposDesvios = [], refetch: refetchTiposDesvios } = useQuery(
    ['tipos-desvios-manuais'],
    () => services.getTiposDesviosManuais(),
    {
      refetchOnWindowFocus: false,
    },
  );

  const { fields, append, remove } = useFieldArray({
    name: 'desvios_tipos',
    control,
  });

  useEffect(() => {
    if (_tiposDesvios.length != tiposDesvios.length)
      setTiposDesvios(
        _tiposDesvios.map(item => ({
          text: item.titulo,
          value: item.id,
          id: item.id,
          isDisponivel: item.isDisponivel,
          removable: item.isDisponivel,
          disabled: !item.isDisponivel,
        })),
      );
  }, [_tiposDesvios]);

  useEffect(() => {
    const subscription = watch((values, { name }) => {
      if (name?.includes('id_desvio_tipo_padrao')) {
        const idsEmUso = values.desvios_tipos
          .map(dt => dt.id_desvio_tipo_padrao)
          .filter(i => i);
        setTiposDesvios(state =>
          state.map(item => ({
            ...item,
            removable: item.isDisponivel && !idsEmUso.includes(item.id),
            disabled: !item.isDisponivel || idsEmUso.includes(item.id),
          })),
        );
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const handleAddTipoDesvio = useCallback(async (titulo, name) => {
    try {
      const req = await services.createTipoDesvioManual(titulo);
      if (req?.success) {
        toast.success(req.message);
        refetchTiposDesvios();
        if (req.id) setValue(name, req.id);
      } else toast.error(req.message);
      setAddTipoDesvioModal(null);
    } catch (error) {
      console.warn('Erro ao salvar.', error);
    }
  }, []);

  const handleDeleteTipoDesvio = useCallback(async id => {
    try {
      const req = await services.desactivateTipoDesvioManual(id);

      if (req.success) {
        toast.success(req.message);
        refetchTiposDesvios();
      } else if (req.status === 406) {
        setDeleteTipoDesvioError(
          'Existe uma ou mais faixas que utilizam esta ação, desative a faixa para poder excluir a ação.',
        );
      } else {
        toast.error(req.message);
      }
    } catch (error) {
      console.warn('Erro ao salvar.', error);
    }
  }, []);

  return (
    <>
      <br />
      <Divider />

      {fields.map((field, index) => {
        const canRemove = !field.createdAt && fields.length > 1;
        return (
          <Collapse
            key={field.id}
            handleRemove={() => remove(index)}
            canRemove={!disableForm && canRemove}
            last={index + 1 === fields.length}
            title={`Desvio ${index > 0 ? `(${index + 1})` : ''}
             ${field?.titulo ? ` - ${field.titulo}` : ''}
            `}
            switchProps={{
              control,
              name: `desvios_tipos[${index}].ativo`,
              disabled: disableForm,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={3} sm={1.5} sx={{ marginTop: '2.5rem' }}>
                <Switch
                  control={control}
                  name={`desvios_tipos[${index}].ativo`}
                  disabled={disableForm || customDisableForm}
                />
              </Grid>

              <Grid item xs={9} sm={4.5}>
                <Controller
                  rules={{
                    required: { value: true, message: 'Campo obrigatório.' },
                  }}
                  control={control}
                  name={`desvios_tipos[${index}].id_desvio_tipo_padrao`}
                  render={({ field: { onChange, name, ref, value } }) => (
                    <SelectCreatableCustom
                      required
                      disabled={disableForm || customDisableForm}
                      ref={ref}
                      name={name}
                      value={value}
                      handleChange={onChange}
                      error={
                        !!errors.desvios_tipos?.[index]?.id_desvio_tipo_padrao
                      }
                      message={
                        errors.desvios_tipos?.[index]?.id_desvio_tipo_padrao
                          ?.message
                      }
                      data={tiposDesvios}
                      label="Selecione o desvio"
                      searchPlaceHolder="Buscar ou adicionar desvio"
                      confirm
                      handleNewOption={text => handleAddTipoDesvio(text, name)}
                      handleRemoveOption={id => handleDeleteTipoDesvio(id)}
                      deletErrorMessage={deleteTipoDesvioError}
                      setDeleteErrorMessage={setDeleteTipoDesvioError}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Controller
                  rules={{
                    required: { value: true, message: 'Campo obrigatório.' },
                  }}
                  control={control}
                  name={`desvios_tipos[${index}].titulo`}
                  render={({ field }) => (
                    <TextInput
                      required
                      disabled={disableForm || customDisableForm}
                      error={!!errors.desvios_tipos?.[index]?.titulo}
                      message={errors.desvios_tipos?.[index]?.titulo?.message}
                      label="Título do desvio"
                      placeholder="Insira um título"
                      {...field}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <Controller
                  rules={{
                    required: { value: true, message: 'Campo obrigatório.' },
                  }}
                  control={control}
                  name={`desvios_tipos[${index}].pontos`}
                  render={({ field }) => (
                    <TextInput
                      required
                      disabled={disableForm}
                      type="number"
                      inputProps={{ min: 0 }}
                      error={!!errors.desvios_tipos?.[index]?.pontos}
                      message={errors.desvios_tipos?.[index]?.pontos?.message}
                      label="Pontuação"
                      placeholder="Insira a pontuação"
                      {...field}
                      onChange={({ target }) =>
                        field.onChange(
                          target.value ? parseInt(target.value) : '',
                        )
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Criticidade
                  name={`desvios_tipos[${index}].criticidade`}
                  control={control}
                  errors={errors}
                  disableForm={disableForm}
                />
                {errors.desvios_tipos?.[index]?.criticidade?.message && (
                  <span
                    style={{
                      color: theme.palette.semantics.feedback.attention.dark,
                      margin: '10px 0',
                    }}
                  >
                    {errors.desvios_tipos?.[index]?.criticidade?.message}
                  </span>
                )}
              </Grid>
            </Grid>
          </Collapse>
        );
      })}

      <DashedButton
        type="button"
        disabled={disableForm || customDisableForm}
        onClick={() => append({ ativo: true, plano_de_acao: '' })}
      >
        <AddIcon />
        ADICIONAR DESVIO
      </DashedButton>
    </>
  );
};

export default Manuais;
