import styled, { css } from 'styled-components';
import { Button } from '@mui/material';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  .filter-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: #95aac9;
  margin: 20px 0;
`;

const StyledButton = styled(Button)`
  justify-content: center;
  align-items: center;
  text-transform: none;
  color: ${props => props.textcolor};
  background: white;
  border: 1px solid #95aac94d;
  font-weight: 400;
  font-size: 16px;
  font-style: normal;
  height: 3.125rem;
  padding: 5px 15px;
  transition: opacity 300ms;
  width: 6.25rem;
`;

export const HeaderDesvios = styled.div`
  .headerText {
    display: flex;
    flex: 1;
    flex-direction: column;
    border-bottom: 1px solid #edf2f9;
    padding-bottom: 0.5rem;
  }
  .main-header {
    font-size: 24px;
    font-weight: 900;
    color: #4b5166;
  }
  .sub-header {
    font-size: 14px;
    font-weight: normal;
  }
  .container {
    display: flex;
    width: 100%;
    align-items: center;
  }
  .subContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: 1.875rem;
  }
  .filters {
    flex: 1;
    flex-direction: column;
  }
  margin-bottom: 2.5rem;
`;

const ContainerExport = styled.div`
  flex: 1;
  margin-top: 2rem;
  margin-bottom: 5rem;
  align-self: flex-end;
`;

export { Container, Divider, StyledButton, ContainerExport };
