export const fields = {
  1: [
    {
      name: 'imagem_cae',
      required: false,
    },
  ],
  4: [
    {
      name: 'imagem_cae',
      required: true,
    },
  ],
};
