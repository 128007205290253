import { colors } from 'styles/colors';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 5px 40px;
`;

export const MediaArchive = styled.div`
  margin-top: 8px;

  .ck-content {
    padding: 10px;
  }

  .ck-reset {
    border: 2px solid
      ${props => (props.isError ? colors.redDanger : colors.greyTiny)};
    border-radius: 4px;
  }
`;

export const Label = styled.label`
  font-weight: 400;
  color: ${colors.greySubtitle};
`;
