import api from 'services/api';
import { writeFileXLSX, utils } from 'xlsx';

export const requestEstatisticas = async query => {
  const res = await api.get(`/telematics_dashboard/tratativa_de_desvio`, {
    params: query,
  });
  return res;
};

export const exportToExcel = (data, fileName) => {
  if (typeof data !== 'object') return;

  const wb = utils.book_new();
  if (data instanceof Array) {
    const ws = utils.json_to_sheet(data);
    utils.book_append_sheet(wb, ws, 'Data');
  } else
    Object.keys(data).forEach(key => {
      const ws = utils.json_to_sheet(data[key]);
      utils.book_append_sheet(wb, ws, key);
    });
  writeFileXLSX(wb, `${fileName}.xlsx`);
};
