import produce from 'immer';
import * as types from './types';

const INITIAL_STATE = {
  responsavel: '',
  carga: '',
  evaluationTab: 0,
};

export default function filterAvaliacoes(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case types.SET_FILTER_AVALIACOES: {
        draft.responsavel = action.payload.query.responsavel;
        draft.carga = action.payload.query.carga;
        break;
      }
      case '@evaluations/CHANGE_TAB_EVALUATION': {
        draft.evaluationTab = action.payload.tab;
        break;
      }
      default:
    }
  });
}
