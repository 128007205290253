import api from 'services/api';

const requestDriversRanking = async query => {
  const res = await api.get(`/telematics_dashboard/ranking`, { params: query });
  return res;
};

const requestExcel = async query => {
  const res = await api.get('/excel/telematics_ranking', { params: query });
  return res;
};

export { requestDriversRanking, requestExcel };
