import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { isValid, parseISO } from 'date-fns';
import { useQuery } from 'react-query';

import { toast } from 'react-toastify';
import { SaveAlt } from '@mui/icons-material';
import { Grid } from '@mui/material';

import { DefaultTable } from 'components/_Table/templates/default';
import GhostButton from 'components/Buttons/Ghost';
import Card from 'components/Cards/Indicador';
import ExcelModal from 'components/ExcelModal';
import ExportToExcel from 'utils/exportToCvs';
import { formatNameDate } from 'utils/dates';
import { ReactComponent as file } from 'images/icons/sidebar/file.svg';

import { setFilter } from 'store/modules/filter/actions';
import { setFilter as setFilterAcoesSuspensoes } from 'store/modules/filterAcoesSuspensoes/actions';
import FinishModal from './FinishModal';
import { columns, fields, resetExcelFields } from './constants';
import { requestInfractions, requestExcel, saveInfractions } from './services';
import * as S from './styled';

const getStatus = status => {
  switch (status) {
    case 'BLOQUEADO':
      return 'SUSPENSO';
    default:
      return status;
  }
};

const AcoesSuspensoes = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const initial = useRef(true);

  // Redux e hooks
  const filter = useSelector(state => {
    return state.filter;
  });

  const filterAcoesSuspensoes = useSelector(state => {
    return state.filterAcoesSuspensoes;
  });

  const dispatch = useDispatch();
  const [query, setQuery] = useState({});
  const [selectedInfractions, setSelectedInfractions] = useState([]);
  const [loadingLines, setLoadingLines] = useState([]);

  const [finishModal, setFinishModal] = useState({
    isSuspensao: false,
    ids: [],
    open: false,
  });
  const [loadingModal, setLoadingModal] = useState(false);

  // Excel Fields
  const [openExcelModal, setOpenExcelModal] = useState(false);
  const [excelFields, setExcelFields] = useState(fields);
  const [loadingExcel, setLoadingExcel] = useState(false);

  const navigate = useNavigate();

  // -------------------------- REQUESTS ------------------------------------------//
  const [resetTable, setResetTable] = useState(false);

  const { isLoading: loadingInfractions, data: resData } = useQuery(
    ['infractions-actions', query],
    () => query && fetchData(query, resData),
    {
      refetchOnWindowFocus: false,
      onSuccess: () => {
        setLoadingLines([]);
        resetTable && setResetTable(false);
      },
      onError: () => {},
      enabled: !initial.current,
    },
  );

  // -------------------------- Requisições e controles --------------------------
  // Requisição da lista de desvios. Chamada com alteracao de updated:
  // filter, filterAcoesSuspensoes e selectedCard
  const fetchData = async (fetchQuery, _data) => {
    // Evita conflito de status na api
    if (
      _data &&
      ((_data.data?.totalSuspensos === 0 &&
        fetchQuery.dashboard === 'suspensos') ||
        (_data.data?.totalProximosSuspensao === 0 &&
          fetchQuery.dashboard === 'proximos_suspensos') ||
        (_data.data?.totalProximosLiberacao === 0 &&
          fetchQuery.dashboard === 'proximos_liberados'))
    ) {
      return {
        data: {
          data: [],
          total: 0,
          totalProximoSuspensao: 0,
          totalProximosLiberacao: 0,
          totalSuspensos: 0,
        },
      };
    }

    const isReport = params.get('isReport');
    const res = requestInfractions({
      ...fetchQuery,
      isReport: isReport == '1' || isReport == 'true',
    });
    return res;
  };

  // Set filter by queryparams
  useEffect(() => {
    const newfilter = {};
    const initialDate = params.get('initialDate');
    const finalDate = params.get('finalDate');
    if (initialDate && isValid(parseISO(initialDate)))
      newfilter.initialDate = parseISO(initialDate);
    if (finalDate && isValid(parseISO(finalDate)))
      newfilter.finalDate = parseISO(finalDate);

    const newfilterAcoesSuspensoes = {};
    const dashboard = params.get('dashboard');
    const status = params.get('status');
    const faixa = params.get('idSuspensao');
    const acaoDisciplinar = params.get('idAcaoDisciplinar');

    if (
      status &&
      ['PENDENTE', 'LIBERADO', 'APROVADO', 'REPROVADO', 'BLOQUEADO'].includes(
        status.toUpperCase(),
      )
    )
      newfilterAcoesSuspensoes.status = status.toUpperCase();
    if (
      dashboard &&
      ['suspensos', 'proximos_liberados', 'proximos_suspensos'].includes(
        dashboard.toLowerCase(),
      )
    )
      newfilterAcoesSuspensoes.dashboard = dashboard.toLowerCase();
    if (faixa && !isNaN(faixa)) newfilterAcoesSuspensoes.faixa = Number(faixa);
    if (acaoDisciplinar && !isNaN(acaoDisciplinar))
      newfilterAcoesSuspensoes.acaoDisciplinar = Number(acaoDisciplinar);

    dispatch(setFilter(newfilter));
    dispatch(setFilterAcoesSuspensoes(newfilterAcoesSuspensoes));
  }, []);

  // Atualiza tabela após filtros
  // Atrasa a primeira consulta para aplicar o filtro de query params
  useEffect(() => {
    if (initial.current) {
      initial.current = false;
      return;
    }

    setResetTable(true);
  }, [filter]);

  const handleClickCard = type => {
    setResetTable(true);
    if (type === filterAcoesSuspensoes.dashboard)
      dispatch(setFilterAcoesSuspensoes({ dashboard: '' }));
    else dispatch(setFilterAcoesSuspensoes({ dashboard: type }));
  };

  const handleRequestExcel = async () => {
    setLoadingExcel(true);

    const newFields = excelFields.filter(item => item.selected === true);
    const formatedDate = formatNameDate(new Date());

    const newQuery = {
      ...filter,
      idSuspensao: filterAcoesSuspensoes.faixa,
      idAcaoDisciplinar: filterAcoesSuspensoes.acaoDisciplinar,
      status: filterAcoesSuspensoes.status,
      excelFields: newFields,
    };

    const res = await requestExcel(newQuery);
    if (res?.data.data?.excel) {
      ExportToExcel({
        excel: res.data.data.excel,
        name: `acoes_motorista_${formatedDate}`,
      });
      toast.success(res.data.message);
    } else if (res.data.message) toast.error(res.data?.message);

    setExcelFields(resetExcelFields(fields));
    setLoadingExcel(false);
    setOpenExcelModal(false);
  };

  // ********************  ACTIONS / BULKACTIONS *****************************
  // Funções das ações
  const handleFinalizar = async data => {
    setLoadingModal(true);

    const payload = {
      ...data,
      status: 'LIBERADO',
    };

    const res = await saveInfractions(payload);
    if (res.data?.success) {
      toast.success(res.data?.message);
    } else {
      if (res.data?.message) toast.error(res.data.message);
      if (res.data?.bulkResponse) {
        // TODO: resposta individual
      }
    }
    setResetTable(true);
    setLoadingModal(false);
    setFinishModal({ isSuspensao: false, ids: [], open: false });
  };

  const verifyIdsFinishModal = useCallback(
    ids => {
      const selectedItems = resData?.data?.rows.filter(item =>
        ids.includes(item.id),
      );
      if (selectedItems.some(item => item.status === 'LIBERADO')) {
        toast.error('Selecione apenas motoristas ainda não liberados.');
        return;
      }
      if (selectedItems.every(item => getStatus(item.status) === 'SUSPENSO')) {
        setFinishModal({ isSuspensao: true, ids, open: true });
      } else if (
        selectedItems.every(item => getStatus(item.status) !== 'SUSPENSO')
      ) {
        setFinishModal({ isSuspensao: false, ids, open: true });
      } else {
        toast.error(
          'Selecione apenas motoristas suspensos ou apenas não suspensos.',
        );
      }
    },
    [resData?.data?.rows],
  );

  const handleOpenDetails = id => {
    navigate(`/telemetria/acoes-suspensao/${id}`);
  };

  const bulkActions = [
    {
      title: 'Finalizar',
      function: ids => verifyIdsFinishModal(ids),
    },
  ];

  const actions = [
    {
      title: 'Ver Detalhes',
      function: handleOpenDetails,
    },
  ];

  //  ------------------   RENDERIZACAO --------------------------------
  // Renderiza cards por status: ABERTO, PENDENTE, FINALIZADO, DELETADO
  const renderCards = () => {
    return (
      <Grid container spacing={2} marginBottom="25px">
        <Grid item xs={12} md={4} xl={4}>
          <Card
            value={resData?.data?.totalSuspensos ?? 0}
            icon="motorista_suspenso.svg"
            text="Motoristas suspensos"
            handleClick={() => handleClickCard('suspensos')}
            selected={filterAcoesSuspensoes.dashboard === 'suspensos'}
          />
        </Grid>
        <Grid item xs={12} md={4} xl={4}>
          <Card
            value={resData?.data?.totalProximosLiberacao ?? 0}
            icon="motorista_proximo_liberacao.svg"
            text="Motoristas próximos da liberação"
            handleClick={() => handleClickCard('proximos_liberados')}
            selected={filterAcoesSuspensoes.dashboard === 'proximos_liberados'}
          />
        </Grid>
        <Grid item xs={12} md={4} xl={4}>
          <Card
            value={resData?.data?.totalProximoSuspensao ?? 0}
            icon="motorista_proximo_suspensao.svg"
            text="Motoristas próximos de suspensão"
            handleClick={() => handleClickCard('proximos_suspensos')}
            selected={filterAcoesSuspensoes.dashboard === 'proximos_suspensos'}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <S.Main>
        <Grid
          marginBottom={1}
          item
          md={12}
          display="flex"
          justifyContent="space-between"
        >
          <h1>Ações e Suspensões</h1>

          <GhostButton
            startIcon={<SaveAlt />}
            size="medium"
            onClick={() => setOpenExcelModal(true)}
            style={{ marginLeft: '10px' }}
          >
            EXPORTAR
          </GhostButton>
        </Grid>

        {renderCards()}

        <DefaultTable
          data={resData?.data?.rows || []}
          columns={columns}
          setSelectedRows={setSelectedInfractions}
          loading={loadingInfractions}
          pageCount={resData?.data?.total || 0}
          local={false}
          bulk={bulkActions}
          actions={actions}
          reset={resetTable}
          onClickRow={handleOpenDetails}
          loadingSelection={loadingLines}
          setQuery={q =>
            setQuery({
              ...q,
              ...filter,
              idSuspensao: filterAcoesSuspensoes.faixa,
              idAcaoDisciplinar: filterAcoesSuspensoes.acaoDisciplinar,
              status: filterAcoesSuspensoes.status,
              dashboard: filterAcoesSuspensoes.dashboard,
            })
          }
          sortBy={{ id: 'data_inicio', ORDER: 'DESC' }}
          placeholder="Buscar por ID, Placa ou Motorista"
          empty={{
            title: 'Ops! Você não tem nenhuma ação/suspensão disponível.',
            description: 'Verifique os filtros selecionados.',
          }}
        />
      </S.Main>

      {openExcelModal && (
        <ExcelModal
          onClick={handleRequestExcel}
          open={openExcelModal}
          handleClose={() => setOpenExcelModal(false)}
          title="Selecionar os campos de Excel"
          titleIcon={file}
          subtitle="Selecionar abaixo:"
          setData={setExcelFields}
          data={excelFields}
          loading={loadingExcel}
        />
      )}

      {finishModal?.open && (
        <FinishModal
          handleConfirm={handleFinalizar}
          ids={finishModal?.ids}
          handleClose={() => setFinishModal({ open: false, ids: [] })}
          loading={loadingModal}
          isSuspensao={finishModal?.isSuspensao}
        />
      )}
    </>
  );
};

export default AcoesSuspensoes;
