import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setFilter } from 'store/modules/filterViolacao/actions';

import { toast } from 'react-toastify';
import { SaveAlt, Check } from '@mui/icons-material';
import { Divider, Grid } from '@mui/material';
import { trackEvent } from 'utils/mixpanel';

import { formatNameDate, formatNewDate } from 'utils/dates';
import ExportToExcel from 'utils/exportToCvs';
import { useTheme } from 'styled-components';

import Tabs from 'components/Tabs';
import InfoCard from 'components/Cards/InfoCard';
import GhostButton from 'components/Buttons/Ghost';
import Table from 'components/Table';
import Card from 'components/Cards/Indicador';
import ExcelModal from 'components/ExcelModal';
import ConfirmModal from 'components/ConfirmModal';

import { ReactComponent as file } from 'images/icons/sidebar/file.svg';
import { openModalInfo } from '../../store/modules/header/actions';

import {
  emptyState,
  infoMessage,
  statusTabs,
  columns,
  fields,
  resetExcelFields,
} from './constants';

import {
  requestViolations,
  requestCards,
  requestExcel,
  finishViolations,
  sendPadViolations,
} from './services';

import * as S from './styled';

const Violacoes = () => {
  const theme = useTheme();
  // Redux e hooks
  const filter = useSelector(state => {
    return state.filter;
  });
  const filterViolacao = useSelector(state => {
    return state.filterViolacao;
  });
  const user = useSelector(state => {
    return state.auth?.user?.user;
  });

  const header = useSelector(state => {
    return state.header;
  });

  const dispatch = useDispatch();

  const userLevel = user.nivel;

  const [data, setData] = useState([]);
  const [col, setCol] = useState(columns);
  const [cards, setCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const [updated, setUpdated] = useState(false);
  const [selectedViolations, setSelectedViolations] = useState([]);
  const [countViolations, setCountViolations] = useState(null);
  const [loadingViolations, setLoadingViolations] = useState(false);
  const [loadingCards, setLoadingCards] = useState(false);

  const [idsFinishModal, setIdsFinishModal] = useState(null);
  const [idsPadModal, setIdsPadModal] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);

  // Excel Fields
  const [openExcelModal, setOpenExcelModal] = useState(false);
  const [excelFields, setExcelFields] = useState(fields);
  const [loadingExcel, setLoadingExcel] = useState(false);

  // ********************  Funções da página *****************************
  // Requisição da lista de violacoes. Chamada com alteracao de updated:
  // filter, filterViolacao e selectedCard
  const fetchData = async query => {
    if (query.search) trackEvent(user, 'Busca Violações', null, query.search);
    setLoadingViolations(true);
    const res = await requestViolations(query);
    if (res.data?.success) {
      setData(res.data.rows);
      setCountViolations(res.data.total);
      setUpdated(false);
    }
    setLoadingViolations(false);
  };

  useEffect(() => {
    dispatch(openModalInfo());
  }, []);

  // Altera coluna de posicao 5 com o STATUS

  // Atualização da tabela com parametro card novo
  useEffect(() => {
    setUpdated(true);
  }, [selectedCard]);

  // Atualiza cards de acordo com os filtros selecionados
  // Atualiza tabela após cads
  useEffect(() => {
    const fetchCards = async () => {
      setLoadingCards(true);
      const newCardQuery = {
        ...filter,
        ...filterViolacao,
      };
      const res = await requestCards(newCardQuery);
      if (res.data?.data) {
        const newCards = res.data.data;

        // Reseta selectedCard caso não tenho um com o mesmo type e com valor nao nulo
        if (newCards?.length > 0 && selectedCard) {
          const findedCard = newCards.find(
            item => item.type === selectedCard && item.value,
          );
          if (!findedCard) setSelectedCard(null);
        }

        setCards(newCards);
      } else if (res.data?.message) toast.error(res.data.message);

      setUpdated(true);

      // Aguarda 1 segundo para liberar nova alteração (Anti usuário nervoso)
      setTimeout(() => {
        setLoadingCards(false);
      }, 1000);
    };
    fetchCards();
  }, [filter, filterViolacao]);

  // Troca do tab (filterViolacao.status)
  const handleStatusTab = async (event, newValue) => {
    dispatch(
      setFilter({
        status: newValue,
      }),
    );
  };

  // Só habilita clique no card quando nao há nada carregando
  // Verifica se card clicado há valor valido: nao nulo e maior que 0.
  // Se sim: seleciona ou desseleciona ele
  const handleClickCard = type => {
    if (!loadingCards && !loadingViolations) {
      const card = cards.find(item => item.type === type);
      if (card.value) {
        if (type === selectedCard) setSelectedCard(null);
        else setSelectedCard(type);
      }
    }
  };

  const handleRequestExcel = async () => {
    setLoadingExcel(true);

    const newFields = excelFields.filter(item => item.selected === true);
    const formatedDate = formatNameDate(new Date());

    const newQuery = { ...filter, ...filterViolacao, excelFields: newFields };

    const res = await requestExcel(newQuery);
    if (res.data && res.data?.data?.excel) {
      ExportToExcel({
        excel: res.data.data.excel,
        name: `violacoes_${formatedDate}`,
      });
      toast.success(res.data.message);
    } else if (res.data.message) toast.error(res.data?.message);

    setExcelFields(resetExcelFields(fields));
    setLoadingExcel(false);
    setOpenExcelModal(false);
  };

  // ********************  ACTIONS / BULKACTIONS *****************************
  const handleFinalizar = async () => {
    setLoadingModal(true);

    const data = { ids: idsFinishModal.map(id => parseInt(id)) };
    const res = await finishViolations(data);
    if (res.data?.success) {
      toast.success(res.data?.message);
    } else {
      if (res.data?.message) toast.error(res.data.message);
      if (res.data?.bulkResponse) {
        // TODO: resposta individual
      }
    }
    setUpdated(true);
    setLoadingModal(false);
    setIdsFinishModal(null);
  };

  const handleSendPad = async () => {
    setLoadingModal(true);

    const data = { ids: idsPadModal.map(id => parseInt(id)) };
    const res = await sendPadViolations(data);
    if (res.data?.success) {
      toast.success(res.data?.message);
    } else {
      if (res.data?.message) toast.error(res.data.message);
      if (res.data?.bulkResponse) {
        // TODO: resposta individual
      }
    }
    setUpdated(true);
    setLoadingModal(false);
    setIdsPadModal(null);
  };

  const handleOpenNewTab = id => {
    return window.open(`/violacao/${id}`);
  };

  // Atualiza bulkActions de acordo com o status selecionado
  // Os itens selecionados podem alterar o a lista de ações
  const getBulkActions = () => {
    let actions = [];

    switch (filterViolacao.status) {
      case 'PENDENTE':
        actions = [
          {
            title: 'Finalizar',
            function: ids => {
              trackEvent(user, 'FINALIZAR VIOLAÇÕES');
              setIdsFinishModal(ids);
            },
          },
          // DEVE APARECER EM BREVE
          // userLevel < 3 && {
          //   title: "Excluir",
          //   function: (ids) => setIdsDeleteModal(ids)
          // },
        ];
        break;

      case 'FINALIZADO':
        actions = [
          {
            title: 'Enviar para PAD',
            function: ids => {
              trackEvent(user, 'ENVIAR PARA PAD EM MASSA');
              setIdsPadModal(ids);
            },
          },
        ];
        break;

      default:
        break;
    }
    return actions.filter(Boolean);
  };

  // Atualiza ações de acordo com o status selecionado
  const getActions = () => {
    let actions = [{ name: 'null', text: 'Sem ações' }];

    switch (filterViolacao.status) {
      case 'PENDENTE':
        actions = [
          {
            name: 'abrir',
            text: 'Abrir em nova guia',
            action: handleOpenNewTab,
          },
          // TODO: IMPRIMIr
          // {
          //   name: "imprimir",
          //   text: "Impimir",
          //   action: handleImprimir
          // },
          {
            name: 'finalizar',
            text: 'Finalizar',
            action: id => {
              trackEvent(user, 'FINALIZAR VIOLAÇÃO');
              setIdsFinishModal([id]);
            },
          },
          // DEVE APARECER EM BREVE
          // userLevel < 3 && {
          //   name: "excluir",
          //   text: "Excluir",
          //   action: (id) => setIdsDeleteModal([id])
          // },
        ];
        break;

      case 'FINALIZADO':
        actions = [
          {
            name: 'abrir',
            text: 'Abrir em nova guia',
            action: handleOpenNewTab,
          },
          // TODO: IMPRIMIR
          // {
          //   name: "imprimir",
          //   text: "Impimir",
          //   action: handleImprimir
          // },
          {
            name: 'pad',
            text: 'Enviar para o PAD',
            action: id => {
              trackEvent(user, 'ENVIAR PARA PAD');
              setIdsPadModal([id]);
            },
          },
        ];
        break;

      case 'DELETADO':
        actions = [
          {
            name: 'abrir',
            text: 'Abrir em nova guia',
            action: handleOpenNewTab,
          },
          // TODO: IMPRIMIR
          // {
          //   name: "imprimir",
          //   text: "Impimir",
          //   action: handleImprimir
          // }
        ];
        break;

      default:
        break;
    }
    return actions.filter(Boolean);
  };

  // Renderiza cards por status: ABERTO, PENDENTE, FINALIZADO, DELETADO
  const renderCards = () => {
    return (
      <Grid container spacing={2} marginBottom="25px">
        {cards &&
          cards.map(card => {
            return (
              <Grid item key={card.type} xs={12} md={4} xl={4}>
                <Card
                  value={card.value}
                  icon={card.icon}
                  text={card.text}
                  handleClick={() => handleClickCard(card.type)}
                  selected={selectedCard === card.type}
                />
              </Grid>
            );
          })}
      </Grid>
    );
  };

  return (
    <>
      <S.Main isOpenInfo={header.showMsgInfo}>
        <Grid
          marginBottom={1}
          item
          md={12}
          display="flex"
          justifyContent="space-between"
        >
          <h1>Violações</h1>

          <GhostButton
            startIcon={<SaveAlt />}
            size="medium"
            onClick={() => {
              trackEvent(user, 'EXPORTAR EXCEL VIOLAÇÃO');
              setOpenExcelModal(true);
            }}
            style={{ marginLeft: '10px' }}
          >
            EXPORTAR
          </GhostButton>
        </Grid>

        <Grid marginBottom={0} item md={12} display="flex">
          <Tabs
            value={filterViolacao.status}
            items={statusTabs}
            onChange={handleStatusTab}
            disabled={loadingCards || loadingViolations}
            last
          />
        </Grid>

        <Divider />

        <div style={{ padding: '15px 0px' }}>
          <InfoCard message={infoMessage} key="info" />
        </div>

        {renderCards()}

        <Table
          inputLabel="Buscar por ID, Placa ou Motorista"
          data={data}
          columns={col}
          setSelectedData={setSelectedViolations}
          pageCount={countViolations}
          loading={loadingViolations}
          fetchData={fetchData}
          updated={updated}
          query={{ ...filter, ...filterViolacao, card: selectedCard }}
          permitIsSortedOccur
          initialSortBy={[{ id: 'data_violacao', desc: true }]}
          actions={getActions()}
          bulkOptions={getBulkActions()}
          onClickRow={handleOpenNewTab}
          empty={emptyState}
        />
      </S.Main>

      {openExcelModal && (
        <ExcelModal
          onClick={handleRequestExcel}
          open={openExcelModal}
          handleClose={() => setOpenExcelModal(false)}
          title="Selecionar os campos de Excel"
          titleIcon={file}
          subtitle="Selecionar abaixo:"
          setData={setExcelFields}
          data={excelFields}
          loading={loadingExcel}
        />
      )}

      {idsFinishModal && (
        <ConfirmModal
          handleClose={() => setIdsFinishModal(null)}
          open={Boolean(idsFinishModal)}
          title={
            idsFinishModal.length > 1
              ? `Deseja finalizar as violações selecionadas? (${idsFinishModal.length})`
              : 'Deseja finalizar a violação selecionada?'
          }
          titleIcon={
            <Check
              sx={{ color: theme.palette.brand.secondary.natural }}
              fontSize="medium"
            />
          }
          subtitle={
            idsFinishModal.length > 1
              ? 'As violações serão movidas para Finalizados. As alterações serão aplicadas em TODAS as violações selecionadas.'
              : 'A violação será movida para aba Finalizados.'
          }
          buttonText="Confirmar"
          onClick={handleFinalizar}
          loading={loadingModal}
        />
      )}

      {idsPadModal && (
        <ConfirmModal
          handleClose={() => setIdsPadModal(null)}
          open={Boolean(idsPadModal)}
          title={
            idsPadModal.length > 1
              ? `Deseja enviar violações ao PAD? (${idsPadModal.length})`
              : 'Deseja enviar violação ao PAD?'
          }
          subtitle="As violações serão enviadas como ocorrências para o PAD"
          titleIcon={
            <Check
              sx={{ color: theme.palette.brand.secondary.natural }}
              fontSize="medium"
            />
          }
          buttonText="Confirmar"
          onClick={handleSendPad}
          loading={loadingModal}
        />
      )}
    </>
  );
};

export default Violacoes;
