export const columns = [
  {
    header: 'Nome',
    id: 'nome',
    type: 'string',
    sort: false,
  },
  {
    header: 'Celular',
    id: 'celular',
    type: 'string',
    sort: false,
    align: 'center',
  },
  {
    header: 'Filial',
    id: 'filial.nome',
    type: 'string',
    sort: false,
    align: 'center',
  },
  {
    header: 'Função',
    id: 'funcao',
    type: 'string',
    sort: true,
    align: 'center',
  },
  {
    header: 'Matrícula',
    id: 'matricula',
    type: 'string',
    sort: true,
    align: 'center',
  },
];
