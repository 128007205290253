/* eslint-disable import/order */

// Styled
import * as S from './styled';
import { useTheme } from 'styled-components';

// React
import { useDispatch } from 'react-redux';
import { Fragment, useEffect, useState } from 'react';
import { initializeData } from 'store/modules/provider/capacitacaoConteudo/actions';
import { toast } from 'react-toastify';

// Components
import Tag from '../Tag';
import Empty from '../Empty';
import Search from '../Search';
import SliderTag from '../SliderTag';
import ContentCard from '../ContentCards';
import PreviewModal from '../ModalPreview';

// Material UI
import QueueOutlinedIcon from '@mui/icons-material/QueueOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';

// Utils
import * as services from '../../services';

// React Slick
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useNavigate } from 'react-router-dom';

// Timer (Controll page, is local, but need do this for see the diff beteewn tags and search)
let timer = null;

const ModalTemplates = ({ open, onClose, tags, templates }) => {
  const theme = useTheme();
  // Slider Config

  const NextArrow = props => {
    const { className, style, onClick } = props;

    return (
      <ArrowForwardIosOutlinedIcon
        className={className}
        style={{
          ...style,
          display: 'block',
          color: theme.palette.words.title.natural,
          fontSize: 25,
        }}
        onClick={onClick}
      />
    );
  };

  const BackArrow = props => {
    const { className, style, onClick } = props;

    return (
      <ArrowBackIosNewOutlinedIcon
        className={className}
        style={{
          ...style,
          display: 'block',
          color: theme.palette.words.title.natural,
          fontSize: 25,
        }}
        onClick={onClick}
      />
    );
  };

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    nextArrow: <NextArrow />,
    prevArrow: <BackArrow />,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1130,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 790,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // General States
  const [query, setQuery] = useState({
    tag: 'Todos',
    search: '',
  });
  const [inSearch, setInSearch] = useState(false);
  const [firstCharge, setFirstCharge] = useState(true);
  const [filteredTemplates, setFilteredTemplates] = useState(templates || []);
  const [openPreview, setOpenPreview] = useState(false);
  const [loadingCard, setLoadingCard] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // -------------------------------------FILTER AREA-------------------------------------------//
  const handleQuery = object => {
    if (object?.card == query.card && query.card) {
      setQuery({ ...query, card: null });
    } else {
      setQuery({ ...query, ...object });
    }
  };

  // -------------------------------------PAGE CONTROLLER---------------------------------------//

  const previewContent = async id => {
    setLoadingCard(true);
    let res = await services.GetConteudo(id);

    if (res.success) {
      const cont = res?.data?.content;

      const id = cont.id;

      const geral = {
        titulo: cont.titulo,
        descricao: cont.descricao,
        capa: cont.imagem_capa,
        data_inicio: cont.data_inicio,
        data_fim: cont.data_fim,
        tags: cont.tags,
        status: cont.status,
      };

      const midias = cont.midias.map(m => {
        return {
          id: m.id,
          titulo: m.titulo,
          descricao: m.descricao,
          link: m.link,
          draggableId: m.ordem,
          ordem: m.ordem,
          tipo: m.tipo,
        };
      });

      const avaliacao = cont.perguntas;

      dispatch(
        initializeData({
          id,
          geral,
          midias,
          avaliacao,
        }),
      );
      setOpenPreview(true);
    } else {
      toast.error(res.message);
    }
    setLoadingCard(false);
  };

  const useByTemplate = async id => {
    setLoadingCard(true);
    let res = await services.UseTemplate(id);

    if (res.success) {
      const cont = res?.data;

      const template = cont[0].id_do_template;

      const geral = {
        titulo: cont[0].titulo,
        descricao: cont[0].descricao,
        capa: cont[0].imagem_capa,
        tags: cont[0].tags.map(item => item.tag),
        desvios_tipos_padrao: cont[0].desvios_tipos_padrao,
        status: 'Rascunho',
      };

      const midias = cont[1].midias.map(m => {
        return {
          id: m.id,
          titulo: m.titulo,
          descricao: m.descricao,
          link: m.link,
          draggableId: m.ordem,
          ordem: m.ordem,
          tipo: m.tipo,
        };
      });

      const avaliacao = cont[2].perguntas;

      const publico = cont[3].turmas.map(item => {
        return {
          id: item.id_empresa,
        };
      });

      dispatch(
        initializeData({
          id: null,
          geral,
          midias,
          avaliacao,
          publico,
          template,
        }),
      );

      navigate('/capacitacao/criar?mode=template');
    } else {
      toast.error(res.message);
    }
    setLoadingCard(false);
  };

  useEffect(() => {
    let hasFilter = [];
    const temps = templates;
    if (query.search) {
      hasFilter = temps.filter(temp => {
        if (
          temp.titulo
            .toLowerCase()
            .includes(query.search.toLocaleLowerCase()) ||
          temp.tags
            .toString()
            .toLocaleLowerCase()
            .includes(query.search.toLocaleLowerCase())
        ) {
          return temp;
        }
      });
    }

    if (query.tag !== 'Todos') {
      const hasSearch = query.search ? hasFilter : temps;
      hasFilter = hasSearch.filter(temp => temp.tags.includes(query.tag));
    }

    if (firstCharge) {
      setFilteredTemplates(null);
      setFilteredTemplates(templates);
      setFirstCharge(false);
      return;
    }

    setInSearch(true);
    clearTimeout(timer);

    timer = setTimeout(() => {
      if (!query.search && query.tag === 'Todos') {
        setFilteredTemplates(templates);
      } else {
        setFilteredTemplates(hasFilter);
      }
      setInSearch(false);
    }, 400);
  }, [query, templates]);

  return (
    <>
      <S.ModalMui open={open} onClose={onClose}>
        <S.Container inPrev={openPreview}>
          <S.Header>
            <div className="left">
              <QueueOutlinedIcon
                htmlColor={theme.palette.brand.secondary.natural}
              />
              <span className="modalTitle">Criar a partir de template</span>
            </div>
            <div className="right">
              <CloseOutlinedIcon
                htmlColor={theme.palette.words.text.light}
                onClick={onClose}
                style={{ cursor: 'pointer' }}
              />
            </div>
          </S.Header>
          <S.SerchLine>
            <SliderTag width="70%">
              {tags?.map((item, key) => (
                <Tag
                  children={item}
                  key={key}
                  onClick={() => handleQuery({ tag: item })}
                  active={item === query.tag}
                  disabled={item === query.tag}
                />
              ))}
            </SliderTag>
            <Search
              value={query.search}
              setValue={value => handleQuery({ search: value })}
              placeholder="Buscar conteúdo"
            />
          </S.SerchLine>

          <S.Body inSearch={inSearch || openPreview}>
            <p className="title-section">{query.tag}</p>
            {filteredTemplates?.length > 0 && (
              <div className="templates">
                {!openPreview && (
                  <Slider {...settings}>
                    {filteredTemplates?.map((temp, key) => (
                      <Fragment key={key}>
                        <ContentCard
                          key={key}
                          template={temp}
                          isTemplate
                          closeModalTemplates={onClose}
                          noTags
                          onClickView={() => previewContent(temp.id)}
                          onClickTemplate={() => useByTemplate(temp.id)}
                          loadingCard={loadingCard}
                        />
                      </Fragment>
                    ))}
                  </Slider>
                )}
              </div>
            )}
            {filteredTemplates?.length <= 0 && (
              <Empty query={query} inSearch={inSearch} width="200px" />
            )}
          </S.Body>
        </S.Container>
      </S.ModalMui>
      {openPreview && (
        <PreviewModal
          open={openPreview}
          onClose={() => {
            setOpenPreview(false);
          }}
          backPath="/capacitacao"
        />
      )}
    </>
  );
};

export default ModalTemplates;
