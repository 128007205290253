import styled, { css } from 'styled-components';
import { Modal } from '@mui/material';

export const ModalMUI = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.palette.system.overlay};
    border-radius: 5px;
    border: 1px solid ${theme.palette.system.border};
  `}
`;

export const Header = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;

    .left {
      display: flex;
      align-items: center;

      .title {
        font-weight: bold;
        color: ${theme.palette.words.title.natural};
        font-size: 20px;
        margin-left: 5px;
      }
    }
  `}
`;

export const Body = styled.label`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;

    .text {
      margin-right: 10px;
      font-weight: 500;
      color: ${theme.palette.words.title.natural};
    }
  `}
`;

export const Footer = styled.div`
  display: flex;
  justify-content: end;
  padding: 15px;
`;
