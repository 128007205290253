import { AddCircleOutline } from '@mui/icons-material';
import DefaultButton from 'components/Buttons/Default';
import * as S from './styled';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import Loading from '../Loading';

const Empty = ({ query, setModalAdd, inSearch, width = '400px' }) => {
  const img = require(`images/empty/desvio.png`);
  const user = useSelector(state => {
    return state.auth?.user?.user;
  });

  const handleConvidado = () => {
    toast.error('Você não tem permissão para executar essa ação!');
  };

  const formatEmptyText = () => {
    if (query.search && query.tag !== 'Todos') {
      return (
        <h3>{`Ops! Nenhum formulário "${query.search}" em: "${query.tag}".`}</h3>
      );
    }
    if (query.search) {
      return <h3>{`Ops! Nenhum formulário "${query.search}".`}</h3>;
    }
    if (query.tag) {
      return <h3>{`Ops! Nenhum formulário em: "${query.tag}".`}</h3>;
    }
    return <h3>Ops! você não tem nenhum formulário disponível.</h3>;
  };

  return (
    <S.Container width={width}>
      {!inSearch && (
        <>
          <S.ImageArea>
            <img src={img} alt="Formulário vazio" />
          </S.ImageArea>
          <S.ContentArea>
            <span className="textArea">
              {formatEmptyText()}
              <h5>
                Verifique o filtro aplicado ou clique em "Novo formulário" e
                comece agora mesmo!
              </h5>
            </span>
            {setModalAdd && (
              <span className="buttonArea">
                <DefaultButton
                  children="Novo Formulário"
                  onClick={() =>
                    user?.nivel !== 4 ? setModalAdd(true) : handleConvidado()
                  }
                  startIcon={<AddCircleOutline />}
                />
              </span>
            )}
          </S.ContentArea>
        </>
      )}
      {inSearch && <Loading />}
    </S.Container>
  );
};

export default Empty;
