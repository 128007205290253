import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme, ...props }) => css`
    display: ${props.visible ? 'flex' : 'none'};
    opacity: ${props.opacity};
    transition: all ease 0.3s;
    position: absolute;
    z-index: 999;
    background-color: ${theme.palette.system.white};
    border-radius: 5px;
    padding: 5px 10px;
    min-width: 100px;
    min-height: 40px;
    justify-content: center;
    align-items: center;
    margin-left: ${props.left}px;
    margin-right: ${props.right}px;
    margin-top: ${props.top}px;
    margin-bottom: ${props.bottom}px;

    ${props.pos === 'top' &&
    `
        box-shadow: 0px -3px 12px 4px rgba(122,122,122,0.15);

        &::before {
            content: "";
            position: absolute;
            transform: translateX(-50%);
            border: 15px solid;
            left: 50%;
            top: -50%;
            border-color: #0000 #0000 #Fff #0000;
        }
    `}

    ${props.pos === 'bottom' &&
    `
    box-shadow: 0px 2px 12px 4px rgba(122,122,122,0.15);

    &::before {
        content: "";
        position: absolute;
        transform: translateX(-50%);
        border: 15px solid;
        border-color: #FFF #0000 #0000 #0000;
        left: 50%;
        top: 85%;
    }
    `}

    ${props.pos === 'left' &&
    `
    box-shadow: -2px 2px 12px 4px rgba(122,122,122,0.15);

    &::before {
        content: "";
        position: absolute;
        transform: translateX(-50%);
        border: 15px solid;
        border-color: #0000 #FFF #0000 #0000;
        left: -10%;
        top: 15%;
    }
    `}

    ${props.pos === 'right' &&
    `
    box-shadow: 2px 2px 12px 4px rgba(122,122,122,0.15);

    &::before {
        content: "";
        position: absolute;
        transform: translateX(-50%);
        border: 15px solid;
        left: 110%;
        top: 15%;
        border-color: #0000 #0000 #0000 #FFF;
    }
    `}

    .box--controller {
      display: flex;
      justify-content: center;
      align-items: center;

      .svg {
        width: 20px;
        margin-right: 5px;
      }

      span {
        font-size: 15px;
        color: ${theme.palette.words.label.natural};
        font-weight: 700;
      }
    }
  `}
`;
