import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/auth';
import { getToken, getMessaging, onMessage } from 'firebase/messaging';
import { firebaseConfig } from './config';

firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const firestorage = firebase.storage();
export const firestorageVideos = firebase
  .app()
  .storage(`gs://${firebaseConfig.storageBucketVideos}`);

const messaging = getMessaging(firebase.app());

export const getOrRegisterServiceWorker = async () => {
  if ('serviceWorker' in navigator) {
    return window.navigator.serviceWorker
      .getRegistration('/firebase-push-notification-scope')
      .then(serviceWorker => {
        if (serviceWorker) return serviceWorker;
        return window.navigator.serviceWorker.register(
          '/firebase-messaging-sw.js',
          {
            scope: '/firebase-push-notification-scope',
          },
        );
      });
  }
  throw new Error('The browser doesn`t support service worker.');
};

export const getReauthenticationCredential = (email, senhaAtual) => {
  return firebase.auth.EmailAuthProvider.credential(email, senhaAtual);
};

export const getFirebaseToken = () =>
  getOrRegisterServiceWorker().then(serviceWorkerRegistration => {
    const token = getToken(messaging, {
      vapidKey:
        process.env.REACT_APP_ENV === 'production'
          ? process.env.REACT_APP_VAPID_KEY_PROD
          : process.env.REACT_APP_VAPID_KEY_DEV,
      serviceWorkerRegistration,
    });
    return token;
  });

export const onForegroundMessage = () =>
  new Promise(resolve => onMessage(messaging, payload => resolve(payload)));
