const fields = [
  {
    label: 'ID',
    value: 'id',
    selected: true,
    default: true,
  },
  {
    label: 'Posto',
    value: 'nome',
    selected: true,
    default: true,
  },
  {
    label: 'Base',
    value: 'base.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Executor',
    value: 'executor.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Aprovador',
    value: 'aprovador.nome',
    selected: true,
    default: true,
  },
  {
    label: 'IBM',
    value: 'ibm',
    selected: true,
    default: true,
  },
  {
    label: 'Status',
    value: 'status',
    selected: true,
    default: true,
  },
  {
    label: 'Endereço',
    value: 'endereco',
    selected: true,
    default: true,
  },
  {
    label: 'Estado',
    value: 'estado',
    selected: true,
    default: true,
  },
  {
    label: 'Contato',
    value: 'contato',
    selected: true,
    default: true,
  },
  {
    label: 'Telefone',
    value: 'telefone_contato',
    selected: true,
    default: true,
  },
  {
    label: 'Janela horário',
    value: 'janela_horario',
    selected: true,
    default: true,
  },
  {
    label: 'Tamanho máximo',
    value: 'tamanho_maximo',
    selected: true,
    default: true,
  },
  {
    label: 'Link croqui',
    value: 'arquivo_croqui',
    selected: true,
    default: true,
  },
  {
    label: 'Observações',
    value: 'observacoes',
    selected: true,
    default: true,
  },
  {
    label: 'Aprovado em',
    value: 'data_assinatura_aprovador',
    selected: true,
    default: true,
  },
  {
    label: 'Atualizado em',
    value: 'data_assinatura_executor',
    selected: true,
    default: true,
  },
  {
    label: 'Criado em',
    value: 'createdAt',
    selected: true,
    default: true,
  },
];

const caeFields = [
  {
    label: 'ID',
    value: 'id',
    selected: true,
    default: true,
  },
  {
    label: 'Cliente',
    value: 'posto.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Tipo',
    value: 'questao.abreviacao',
    selected: true,
    default: true,
  },
  {
    label: 'Aprovador',
    value: 'aprovador.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Executor',
    value: 'usuario.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Data ocorrência',
    value: 'data_ocorrencia',
    selected: true,
    default: true,
  },
  {
    label: 'Motorista',
    value: 'motorista.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Caminhão',
    value: 'caminhao.placa',
    selected: true,
    default: true,
  },
  {
    label: 'Imagem',
    value: 'imagem_cae',
    selected: true,
    default: true,
  },
  {
    label: 'Observação',
    value: 'observacao_cae',
    selected: true,
    default: true,
  },
  {
    label: 'Status',
    value: 'status',
    selected: true,
    default: true,
  },
  {
    label: 'Criada em',
    value: 'createdAt',
    selected: true,
    default: true,
  },
  {
    label: 'Aprovada em',
    value: 'data_aprovacao',
    selected: true,
    default: true,
  },
  {
    label: 'Estado',
    value: 'posto.estado',
    selected: true,
    default: true,
  },
];

const resetExcelFields = fields => {
  return fields.map(i => {
    if (!i.default) i.selected = true;
    return i;
  });
};

const iconsCard = [
  'Grupo12752.svg',
  'Grupo12754.svg',
  'Grupo12753.svg',
  'Grupo12065.svg',
  'symbol.svg',
];

export { fields, resetExcelFields, caeFields, iconsCard };
