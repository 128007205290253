import { generate as gen } from './types';

export const order = (data, key, order, type) => {
  data = data?.map(line => {
    const item = { ...line };
    if (key?.includes('.')) {
      const keys = key.split('.');
      let value = item;
      keys?.forEach(k => {
        value = value?.[k];
      });
      item[key] = value;
    }

    return item;
  });

  return data?.sort((a, b) => {
    if (order === 'ASC') {
      if (gen(type, a[key]) < gen(type, b[key])) return -1;
      if (gen(type, a[key]) > gen(type, b[key])) return 1;
      if (gen(type, a[key]) === gen(type, b[key])) return a.id - b.id;
    } else if (order === 'DESC') {
      if (gen(type, a[key]) > gen(type, b[key])) return -1;
      if (gen(type, a[key]) < gen(type, b[key])) return 1;
      if (gen(type, a[key]) === gen(type, b[key])) return a.id - b.id;
    } else {
      return 0;
    }
    return 0;
  });
};
