import * as types from './types';

export function setContent(data) {
  return {
    type: types.SET_DATA,
    payload: { data },
  };
}

export function saveMedia(data) {
  return {
    type: types.SET_MEDIA,
    payload: { data },
  };
}

export function setIDTamplete(data) {
  return {
    type: types.IS_TAMPLETE,
    payload: { data },
  };
}

export const changeStateSave = isValid => {
  return {
    type: types.CHANGE_STATE_SAVE,
    payload: { isValid },
  };
};

export function ResetState() {
  return {
    type: types.RESET_STATE,
  };
}
