import React, { forwardRef } from 'react';
import { Header } from '../Header';

import * as S from './styled';
import { RoundedTabs } from 'components/RoundedTabs';
import { tabs } from '../../constants';
import { usePlans } from 'hooks/usePlans';
import { Estatisticas } from '../Estatisticas';
import { useSelector } from 'react-redux';
import { TableToPrint } from 'components/TableToPrint';
import {
  AcoesSuspensoes,
  Desvios,
  Formularios,
  avaliacaoDescarga,
  avaliacaoDirecao,
} from './constants';
import { Faixas } from '../../components/Faixas';

const dateMap = {
  '30DIAS': '30 dias',
  '3MESES': '3 meses',
  '6MESES': '6 meses',
  '12MESES': '12 meses',
};

export const PDF = forwardRef(
  (
    {
      id,
      filters,
      period,
      printTabs = [],
      setLoadingData,
      dataDriversExports = [],
    },
    ref,
  ) => {
    const { planosAtivos } = usePlans();

    const selects = useSelector(state => state.selects);

    const filial = filters?.filial?.length
      ? filters?.filial.map(
          f =>
            selects.empresas
              .find(empresa => empresa.id === +id)
              ?.filiais?.find(i => i.id === +f)?.nome,
        ) || null
      : null;

    const removerAtributo = (arr, atributo) => {
      return arr.map(obj => {
        const { [atributo]: _, ...resto } = obj;
        return resto;
      });
    };

    return (
      <S.HiddenContainer>
        <S.Container ref={ref}>
          <Header id={id} print />
          <br />
          {printTabs.includes('estatisticas') && (
            <>
              <RoundedTabs
                items={tabs.filter(
                  tab =>
                    !tab.plans ||
                    planosAtivos.some(plan =>
                      tab.plans.includes(plan.id_do_plano),
                    ),
                )}
                value="estatisticas"
              />
              <S.FilterContainer>
                {period && (
                  <S.FilterCard>Últimos {dateMap[period]}</S.FilterCard>
                )}
                {filial && <S.FilterCard>{filial}</S.FilterCard>}
              </S.FilterContainer>
              <Estatisticas
                filters={filters}
                period={period}
                setLoadingData={setLoadingData}
                print
              />
            </>
          )}
          {printTabs.includes('avaliacoes') && (
            <>
              {printTabs.length > 1 && <div className="page-break" />}
              <RoundedTabs
                items={tabs.filter(
                  tab =>
                    !tab.plans ||
                    planosAtivos.some(plan =>
                      tab.plans.includes(plan.id_do_plano),
                    ),
                )}
                value="avaliacoes"
              />
              <S.FilterContainer>
                {period && (
                  <S.FilterCard>Últimos {dateMap[period]}</S.FilterCard>
                )}
                {filial && <S.FilterCard>{filial}</S.FilterCard>}
              </S.FilterContainer>
              <TableToPrint
                columns={avaliacaoDirecao}
                data={dataDriversExports?.avaliacaoDirecao || []}
              />
            </>
          )}
          {printTabs.includes('avaliacoes_descarga') && (
            <>
              {printTabs.length > 1 && <div className="page-break" />}
              <RoundedTabs
                items={tabs.filter(
                  tab =>
                    !tab.plans ||
                    planosAtivos.some(plan =>
                      tab.plans.includes(plan.id_do_plano),
                    ),
                )}
                value="avaliacoes_descarga"
              />
              <S.FilterContainer>
                {period && (
                  <S.FilterCard>Últimos {dateMap[period]}</S.FilterCard>
                )}
                {filial && <S.FilterCard>{filial}</S.FilterCard>}
              </S.FilterContainer>
              <TableToPrint
                columns={avaliacaoDescarga}
                data={dataDriversExports?.avaliacaoDescarga || []}
              />
            </>
          )}
          {printTabs.includes('desvios') && (
            <>
              {printTabs.length > 1 && <div className="page-break" />}
              <RoundedTabs
                items={tabs.filter(
                  tab =>
                    !tab.plans ||
                    planosAtivos.some(plan =>
                      tab.plans.includes(plan.id_do_plano),
                    ),
                )}
                value="desvios"
              />
              <S.FilterContainer>
                {period && (
                  <S.FilterCard>Últimos {dateMap[period]}</S.FilterCard>
                )}
                {filial && <S.FilterCard>{filial}</S.FilterCard>}
              </S.FilterContainer>
              <TableToPrint
                columns={Desvios}
                data={removerAtributo(
                  dataDriversExports?.desvios || [],
                  'analises',
                )}
              />
            </>
          )}
          {printTabs.includes('formularios') && (
            <>
              {printTabs.length > 1 && <div className="page-break" />}
              <RoundedTabs
                items={tabs.filter(
                  tab =>
                    !tab.plans ||
                    planosAtivos.some(plan =>
                      tab.plans.includes(plan.id_do_plano),
                    ),
                )}
                value="formularios"
              />
              <S.FilterContainer>
                {period && (
                  <S.FilterCard>Últimos {dateMap[period]}</S.FilterCard>
                )}
                {filial && <S.FilterCard>{filial}</S.FilterCard>}
              </S.FilterContainer>
              <TableToPrint
                columns={Formularios}
                data={dataDriversExports?.forms}
              />
            </>
          )}
          {printTabs.includes('acoes') && (
            <>
              {dataDriversExports?.acaoSuspensao &&
                dataDriversExports?.acaoSuspensao.map((item, key) => (
                  <div key={key}>
                    {printTabs.length > 1 && <div className="page-break" />}
                    <RoundedTabs
                      items={tabs.filter(
                        tab =>
                          !tab.plans ||
                          planosAtivos.some(plan =>
                            tab.plans.includes(plan.id_do_plano),
                          ),
                      )}
                      value="acoes"
                    />
                    <S.FilterContainer>
                      {period && (
                        <S.FilterCard>Últimos {dateMap[period]}</S.FilterCard>
                      )}
                      {filial && <S.FilterCard>{filial}</S.FilterCard>}
                    </S.FilterContainer>

                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                      }}
                    >
                      <div style={{ display: 'flex', width: '30%' }}>
                        <Faixas
                          faixas={item?.faixa}
                          idFaixaAtual={item?.driverData?.id}
                          pontos={item?.driverData?.pontos}
                          title={item?.item}
                        />
                      </div>
                      <div style={{ display: 'flex', width: '70%' }}>
                        <TableToPrint
                          columns={AcoesSuspensoes}
                          data={item?.acoesData || []}
                        />
                      </div>
                    </div>
                    <div className="page-break" />
                  </div>
                ))}
            </>
          )}
        </S.Container>
      </S.HiddenContainer>
    );
  },
);
