import styled, { css } from 'styled-components';

import { styled as stMuiUI } from '@mui/material/styles';

const Space = styled.div`
  margin-top: ${props => (!props.h ? `10px` : `10px`)};
`;

export const Container = styled.div`
  flex-wrap: wrap;
  flex-direction: column;
`;

export const ButtonContianer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Controls = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    width: 20%;
    border-bottom: 1px solid ${theme.palette.system.border};

    .left {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      height: 40px;
      border-radius: 4px 0px 0px 0px;
      border-color: ${theme.palette.brand.primary.light}4d;
      border-style: solid;
      border-width: 1px 0px 0px 1px;
      user-select: none;
      transition: all ease 0.1s;
    }
    .right {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      height: 40px;
      border-radius: 0px 4px 0px 0px;
      border-color: ${theme.palette.brand.primary.light}4d;
      border-style: solid;
      border-width: 1px 1px 0px 1px;
      user-select: none;
      transition: all ease 0.1s;
    }
  `}
`;

export const TableContainer = styled.div`
  width: 100%;
`;

export const Paragraph = styled.p`
  ${({ theme, ...props }) => css`
    display: flex;
    justify-content: center;
    text-align: center;

    font-size: ${props.fontsize ? props.fontsize : '16px'};
    color: ${props.color ? props.color : theme.palette.brand.primary.light};
    font-weight: ${props.fontweight ? props.fontweight : 400};
    min-width: ${props.minwidth};

    &.status {
      font-weight: bold;
      color: ${props.color};
      background-color: ${props.bg};
      text-align: center;
      border-radius: 100px;
    }
  `}
`;

export const ColDriver = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-transform: capitalize;
    ${({ pontuacao }) =>
      pontuacao &&
      `
    h1{
      color: ${theme.palette.semantics.feedback.warning.natural} !important;
    }
  `}

    h1 {
      font-size: 14px;
      font-weight: 600;
      color: ${theme.palette.words.title.natural};
    }

    .container-img-name {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    img {
      width: 41px;
      height: 41px;
      border: 1px solid ${theme.palette.system.border};
      border-radius: 100px;
    }
  `}
`;
