import { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useTheme } from 'styled-components';
import { useNavigate } from 'react-router-dom';

// Components
import Podium from 'components/Podium';
import Loading from 'components/Loading';
import GhostButton from 'components/Buttons/Ghost';
import ExcelModal from 'components/ExcelModal';
import EmptyCard from 'components/Cards/EmptyDataCard';
import { DefaultTable } from 'components/_Table/templates/default';
import { ReactComponent as file } from 'images/icons/sidebar/file.svg';

// Components Material UI
import { Tooltip } from '@mui/material';
import { SaveAlt } from '@mui/icons-material';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

// Utils
import { formatNameDate } from 'utils/dates';
import ExportToExcel from 'utils/exportToCvs';

import * as S from './styled';
import * as services from './services';
import { columns, fields, resetExcelFields } from './constants';

const TelemetriaRankingProvider = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { filterProvider: filter, filterTelemetriaRankingProvider } =
    useSelector(state => state);
  const [data, setData] = useState([]);
  const [podium, setPodium] = useState([]);
  const [loading, setLoading] = useState(false);

  const [openExcelModal, setOpenExcelModal] = useState(false);
  const [excelFields, setExcelFields] = useState(fields);
  const [loadingExcel, setLoadingExcel] = useState(false);

  const sortBy = [
    {
      id: 'posicao',
      desc: false,
    },
  ];

  useEffect(() => {
    getRanking();
  }, [filter, filterTelemetriaRankingProvider]);

  const getRanking = async () => {
    setLoading(true);
    const res = await services.getList({
      ...filter,
      filial: filter.filial.split(',').filter(i => i),
      client: filter.empresas.split(',').filter(i => i),
      operation: filterTelemetriaRankingProvider.operation ?? null,
      idDesvio: filterTelemetriaRankingProvider.desvio ?? null,
      idVeiculo: filterTelemetriaRankingProvider.veiculo ?? null,
      motoristas: filterTelemetriaRankingProvider.motoristas ?? null,
      carregado: filterTelemetriaRankingProvider.carregado ?? null,
      pista_molhada: filterTelemetriaRankingProvider.pista_molhada ?? null,
    });
    if (res.success) {
      let totalPoints = res.data.reduce((acc, cur) => {
        return acc + cur.pontos_km;
      }, 0);

      const mediaPoints = totalPoints / res.data.length;

      const rows = res.data.map((item, index) => ({
        ...item,
        posicao: index + 1,
        isMedia: item.pontos_km <= mediaPoints,
      }));
      setData(rows);

      // Format itens to send to Podium component
      const formatedPodium = [];
      if (rows.length) {
        for (let i = 0; i <= 2; i++) {
          if (rows.length > i) {
            formatedPodium.push({
              nameOne: rows[i].filial,
              nameTwo: rows[i].nome,
              position: rows[i].posicao,
              oldPosition: i.posicao_anterior
                ? i.posicao_anterior > i.posicao
                  ? 'UP'
                  : i.posicao_anterior < i.posicao
                  ? 'DOWN'
                  : 'EQUAL'
                : null,
              logo: rows[i].foto,
              hours: null,
              km: Number(rows[i].km_viagem).toLocaleString(undefined, {
                maximumFractionDigits: 1,
              }),
              points: rows[i].pontos,
              pontos_km: Number(rows[i].pontos_km).toLocaleString(undefined, {
                maximumFractionDigits: 3,
              }),
            });
          }
        }
      }
      setPodium(formatedPodium);
    } else {
      toast.error(res.message);
    }
    setLoading(false);
  };

  // -------------------------- EXCEL ------------------------------------------//
  const handleRequestExcel = async () => {
    setLoadingExcel(true);

    const newFields = excelFields.filter(item => item.selected === true);
    const formatedDate = formatNameDate(new Date());

    const newQuery = {
      ...filter,
      filial: filter.filial.split(',').filter(i => i),
      client: filter.empresas.split(',').filter(i => i),
      operation: filterTelemetriaRankingProvider.operation ?? null,
      idDesvio: filterTelemetriaRankingProvider.desvio ?? null,
      idVeiculo: filterTelemetriaRankingProvider.veiculo ?? null,
      motoristas: filterTelemetriaRankingProvider.motoristas ?? null,
      carregado: filterTelemetriaRankingProvider.carregado ?? null,
      pista_molhada: filterTelemetriaRankingProvider.pista_molhada ?? null,
      orderBy: sortBy.desc ? 'DESC' : 'ASC',
      orderByColumn: sortBy.id != 'posicao' ? sortBy.id : null,
      excelFields: newFields,
    };

    const res = await services.requestExcel(newQuery);
    if (res.data && res.data?.data?.excel)
      ExportToExcel({
        excel: res.data.data.excel,
        name: `ranking_telemetria_${formatedDate}`,
      });
    else if (res.data.message) toast.error(res.data?.message);

    setExcelFields(resetExcelFields(fields));
    setLoadingExcel(false);
    setOpenExcelModal(false);
  };

  // -----------------------TOTAL INFORMATIONS---------------------------------//
  useEffect(() => getInformations(), [data, filter]);

  const [totalBox, setTotalBox] = useState(null);

  const getInformations = () => {
    let pontos_km = 0;
    let km_viagem = 0;
    let pontos = 0;
    let viagens = 0;
    let horas_viagem = 0;
    let desvios_telemetria = 0;
    let desvios_cerca = 0;
    let desvios_velocidade = 0;

    for (const i in data) {
      data[i].km_viagem && (km_viagem += Number(data[i].km_viagem));
      data[i].pontos && (pontos += Number(data[i].pontos));
      data[i].viagens && (viagens += Number(data[i].viagens));
      data[i].horas_viagem && (horas_viagem += Number(data[i].horas_viagem));
      data[i].desvios_telemetria &&
        (desvios_telemetria += Number(data[i].desvios_telemetria));
      data[i].desvios_cerca && (desvios_cerca += Number(data[i].desvios_cerca));
      data[i].desvios_velocidade &&
        (desvios_velocidade += Number(data[i].desvios_velocidade));
    }

    pontos_km = km_viagem ? pontos / km_viagem : pontos;

    setTotalBox([
      {
        title: 'Pontos/Km',
        count: pontos_km.toLocaleString(undefined, {
          maximumFractionDigits: 3,
        }),
      },
      {
        title: 'Quilômetros',
        count: km_viagem.toLocaleString(undefined, {
          maximumFractionDigits: 3,
        }),
      },
      {
        title: 'Total de Pontos',
        count: pontos.toLocaleString(undefined, {
          maximumFractionDigits: 0,
        }),
      },
      {
        title: 'Viagens',
        count: viagens.toLocaleString(undefined, {
          maximumFractionDigits: 0,
        }),
      },
      {
        title: 'Horas de viagem',
        count: horas_viagem.toLocaleString(undefined, {
          maximumFractionDigits: 0,
        }),
      },
      {
        title: 'Pontos Telemetria',
        count: desvios_telemetria.toLocaleString(undefined, {
          maximumFractionDigits: 0,
        }),
      },
      {
        title: 'Pontos Cerca Eletrônica',
        count: desvios_cerca.toLocaleString(undefined, {
          maximumFractionDigits: 0,
        }),
      },
      {
        title: 'Pontos Excesso de Velocidade',
        count: desvios_velocidade.toLocaleString(undefined, {
          maximumFractionDigits: 0,
        }),
      },
    ]);
  };

  const handleVer = id => {
    if (id) navigate(`/empresas/${id}`);
    else toast.error('Não foi possível acessar os detalhes desta empresa.');
  };

  const formatColumns = useCallback(() => {
    return columns.map(col => {
      if (
        col.id === 'nome' &&
        !!filter.empresas &&
        filter.empresas.split(',').length === 1
      )
        return {
          ...col,
          subRow: {
            prefix: 'Filial:',
            value: (_, item) => item?.filial,
          },
        };
      return col;
    });
  }, [filter]);

  return (
    <>
      {!loading && (
        <>
          <S.TitleWrapper>
            <h1>Ranking</h1>

            <div>
              <GhostButton
                startIcon={<SaveAlt />}
                size="medium"
                onClick={() => {
                  setOpenExcelModal(true);
                }}
              >
                EXPORTAR
              </GhostButton>
            </div>
          </S.TitleWrapper>

          <Podium data={podium} loading={loading} />

          {data?.length > 0 ? (
            <DefaultTable
              loading={loading}
              data={data || []}
              columns={formatColumns()}
              searchKeys={['posicao', 'nome']}
              placeholder="Buscar por Empresa ou Posição"
              sortBy={{ id: 'posicao', order: 'ASC' }}
            />
          ) : (
            <EmptyCard
              image="motorista.png"
              title="Ops! Não foram encontrados motoristas para essa busca."
              subtitle="Altere os filtros ou faça o cadastro do novo motorista!"
            />
          )}

          <S.TotalTitle>
            <span>Resumo</span>
            <Tooltip title="Resumo de todos os valores disponíveis na tabela">
              <HelpOutlineOutlinedIcon
                htmlColor={theme.palette.semantics.feedback.unknown.natural}
                style={{ marginLeft: '10px', cursor: 'pointer' }}
              />
            </Tooltip>
          </S.TotalTitle>
          <S.TotalContentBox>
            {totalBox?.map((item, key) => (
              <div className="boxItem" key={key}>
                <div className="title">{item.title}</div>
                <div className="count">{item.count}</div>
              </div>
            ))}
          </S.TotalContentBox>
        </>
      )}
      {loading && (
        <div>
          <Loading />
        </div>
      )}
      {openExcelModal && (
        <ExcelModal
          onClick={handleRequestExcel}
          open={openExcelModal}
          handleClose={() => setOpenExcelModal(false)}
          title="Selecionar os campos de Excel"
          titleIcon={file}
          subtitle="Selecionar abaixo:"
          setData={setExcelFields}
          data={excelFields}
          loading={loadingExcel}
        />
      )}
    </>
  );
};

export default TelemetriaRankingProvider;
