import { Collapse } from '@mui/material';
import styled from 'styled-components';
import colors from 'styles/colors';

export const Container = styled.div``;

export const Box = styled.div`
  margin-top: 10px;
  padding: 10px 35px;

  .line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6px;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    .title {
      font-weight: 600;
      color: ${colors.greySubtitle};
    }

    .MuiSvgIcon-root {
      font-size: 18px;

      &.arrow {
        color: ${colors.greySubtitle};
      }
    }
  }
`;

export const Bar = styled.div`
  width: 100%;
  height: 1px;
  background: ${colors.greyTiny};
  margin-top: 5px;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  width: 100%;
  border: 2px dashed ${colors.grey};
  border-radius: 5px;
  padding: 13px;
  color: ${colors.greySubtitle};
  font-size: 17px;
  font-weight: 700;
  transition: all ease 0.25s;

  span {
    margin-left: 10px;
  }

  &:hover {
    background: ${colors.greyBackgroud};
  }
`;

export const CollapseMu = styled(Collapse)``;

export const SampleTable = styled.div`
  width: 100%;
  height: 100%;
`;

export const HeaderTable = styled.div`
  height: 60px;
  width: 100%;
  background-color: ${colors.greyBorder};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
  font-weight: 600;
  color: ${colors.grey};
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  cursor: pointer;

  .left {
    display: flex;
    align-items: center;
    justify-content: start;
    flex: 1;

    &.space {
      flex: 2;
    }

    .arrows {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-left: 5px;

      .top {
        margin-bottom: -7px;
        font-size: 22px;
        opacity: 0.5;
      }

      .bottom {
        margin-top: -7px;
        font-size: 22px;
        opacity: 0.5;
      }

      .active {
        color: ${colors.blueInfo};
        opacity: 1;
      }
    }
  }

  .right {
    flex: 1;

    &.center {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const BodyTable = styled.div`
  border: 1px solid ${colors.greyBorder};
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  border-top: none;
  width: 100%;
  height: 100%;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  background-color: ${colors.white};
`;

export const LineTable = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
  height: 60px;

  p {
    font-size: 18px;
    font-weight: 600;
    color: ${colors.greySubtitle};
    text-transform: capitalize;
  }

  .prh {
    flex: 1;

    &.center {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.pointer {
      cursor: pointer;
    }
  }

  .left {
    display: flex;
    align-items: center;
    justify-content: start;
    flex: 2;

    .boxImage {
      width: 40px;
      height: 40px;
      border-radius: 40px;
      background-color: ${colors.greyBorder};
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid ${colors.greenMain};
      margin-right: 10px;
      padding: 3px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 40px;
      }

      .letter {
        font-size: 20px;
        color: ${colors.greySubtitle};
        font-weight: 600;
      }
    }
  }
`;
