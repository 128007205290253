import { formatNewDate, formatTime } from 'utils/dates';

const howToColor = (criticality, theme) => {
  switch (criticality) {
    case 'MODERADA':
      return theme?.palette.semantics.feedback.warning.natural;
    case 'GRAVE':
      return theme?.palette.semantics.feedback.attention.natural;
    case 'GRAVÍSSIMA':
      return theme?.palette.semantics.feedback.attention.natural;
    default:
      return theme?.palette.semantics.feedback.information.natural;
  }
};

const getStatusColor = (status, theme) => {
  switch (status) {
    case 'PENDENTE':
      return {
        color: theme?.palette.semantics.feedback.warning.natural,
        background: theme?.palette.semantics.feedback.warning.light,
      };
    case 'ABERTO':
      return {
        color: theme?.palette.semantics.feedback.warning.natural,
        background: theme?.palette.semantics.feedback.warning.light,
      };
    case 'FINALIZADO':
      return {
        color: theme?.palette.semantics.feedback.success.natural,
        background: theme?.palette.semantics.feedback.success.light,
      };
    case 'DELETADO':
      return {
        color: theme?.palette.semantics.feedback.attention.natural,
        background: theme?.palette.semantics.feedback.attention.light,
      };
  }
};

const emptyState = {
  title: 'Ops! Você não tem nenhum desvio disponível.',
  subtitle: 'Verifique os filtros aplicados!',
};

const getExpirationDate = row => {
  const newDate = new Date(row.data_pendente);
  newDate.setDate(newDate.getDate() + 10);
  return formatNewDate(newDate);
};

const infoMessage = 'Desvios de direção...';

const statusTabs = [
  { value: 'PENDENTE', label: 'Pendentes' },
  { value: 'FINALIZADO', label: 'Finalizados' },
  { value: 'DELETADO', label: 'Deletados' },
];

const fields = [
  {
    label: 'ID',
    value: 'id',
    selected: true,
    default: true,
  },
  {
    label: 'Transportadora',
    value: 'motorista.filial.empresa.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Filial',
    value: 'motorista.filial.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Placa',
    value: 'caminhao.placa',
    selected: true,
    default: true,
  },
  {
    label: 'Tipo de desvio',
    value: 'tipo.descricao',
    selected: true,
    default: true,
  },
  {
    label: 'Criticidade',
    value: 'tipo.criticidade',
    selected: true,
    default: true,
  },
  {
    label: 'Pontuação',
    value: 'tipo.peso',
    selected: true,
    default: true,
  },
  {
    label: 'Objeto',
    value: 'objeto',
    selected: false,
    default: false,
  },
  {
    label: 'Status',
    value: 'status',
    selected: true,
    default: true,
  },
  {
    label: 'Status Embarcadora',
    value: 'status_embarcadora',
    selected: true,
    default: true,
  },
  {
    label: 'Data do desvio',
    value: 'data_desvio',
    selected: true,
    default: true,
  },

  {
    label: 'Disponibilizado em',
    value: 'createdAt',
    selected: true,
    default: true,
  },
  {
    label: 'Movido para pendente em',
    value: 'data_pendente',
    selected: false,
    default: false,
  },
  {
    label: 'Plano de ação',
    value: 'plano_de_acao',
    selected: true,
    default: true,
  },
  {
    label: 'Previsão de conclusão',
    value: 'data_previsao',
    selected: true,
    default: true,
  },

  {
    label: 'Finalizado em',
    value: 'data_encerramento',
    selected: true,
    default: true,
  },
  {
    label: 'Responsavel',
    value: 'responsavel.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Comentário',
    value: 'comentario',
    selected: false,
    default: false,
  },
  {
    label: 'Observação',
    value: 'observacao',
    selected: false,
    default: false,
  },
  {
    label: 'Criado por',
    value: 'criador.nome',
    selected: true,
    default: true,
  },

  {
    label: 'Aprovado exclusão',
    value: 'aprovado_exclusao',
    selected: true,
    default: true,
  },

  {
    label: 'Data envio revisão',
    value: 'data_exclusao',
    selected: false,
    default: false,
  },

  {
    label: 'Data revisão distribuidora',
    value: 'data_revisao_distribuidora',
    selected: true,
    default: true,
  },

  {
    label: 'Vencimento distribuidora',
    value: 'vencimento_distribuidora',
    selected: true,
    default: true,
  },

  {
    label: 'Usuário distribuidora',
    value: 'revisor.nome',
    selected: true,
    default: true,
  },

  {
    label: 'Local',
    value: 'avaliacao.local',
    selected: false,
    default: false,
  },

  {
    label: 'Justificativa exclusão',
    value: 'motivo_exclusao',
    selected: true,
    default: true,
  },

  {
    label: 'Observação exclusão',
    value: 'observacao_exclusao',
    selected: true,
    default: true,
  },

  {
    label: 'Status Embarcadora',
    value: 'aprovado_distribuidora',
    selected: true,
    default: true,
  },

  {
    label: 'Observações distribuidora',
    value: 'observacoes_distribuidora',
    selected: true,
    default: true,
  },
];

const resetExcelFields = fields => {
  return fields.map(i => {
    if (!i.default) i.selected = false;
    return i;
  });
};

const objetos = [
  { name: 'BEBIDA', value: 'BEBIDA' },
  { name: 'ALIMENTO', value: 'ALIMENTO' },
  { name: 'CIGARRO', value: 'CIGARRO' },
  { name: 'OUTRO', value: 'OUTRO' },
];

export {
  emptyState,
  howToColor,
  getStatusColor,
  infoMessage,
  statusTabs,
  fields,
  objetos,
  resetExcelFields,
};

export const columns2 = [
  {
    header: 'Id',
    id: 'id',
    type: 'number',
    sort: true,
    width: 100,
  },
  {
    header: 'Empresa/Filial',
    id: 'motorista.filial.empresa.nome',
    type: 'string',
    tooltip: true,
    sort: false,
    subRow: {
      prefix: 'FIlial:',
      value: (_, item) => item?.motorista?.filial?.nome,
    },
  },
  {
    header: 'Placa',
    id: 'caminhao.placa',
    type: 'string',
    sort: true,
    width: 100,
    style: {
      textTransform: 'uppercase',
    },
  },
  {
    header: 'Desvio',
    id: 'tipo.descricao',
    type: 'string',
    sort: true,
    tooltip: true,
    subRow: {
      value: (_, item) => (item.objeto ? `Objeto: ${item.objeto}` : ''),
    },
  },
  {
    header: 'Data Desvio',
    id: 'data_desvio',
    type: 'string',
    sort: true,
    value: (_, item) => formatNewDate(item?.data_desvio),
    subRow: {
      prefix: 'Horário:',
      value: (_, item) => formatTime(item?.data_desvio, true),
    },
  },
  {
    header: 'Disponibilizado em',
    id: 'createdAt',
    type: 'date',
    sort: true,
    width: 200,
    subRow: {
      prefix: 'Vencimento:',
      value: (_, item) => formatNewDate(item?.createdAt),
    },
  },
  {
    header: 'Responsável',
    id: 'responsavel.nome',
    type: 'string',
    sort: true,
    tooltip: true,
  },
  {
    header: 'Criticidade',
    id: 'tipo.criticidade',
    type: 'string',
    sort: true,
    style: {
      textTransform: 'uppercase',
    },
    conditional: [
      {
        condition: value => true,
        style: theme => ({
          color: theme?.palette?.semantics?.feedback?.information?.natural,
          backgroundColor:
            theme?.palette?.semantics?.feedback?.information?.light,
          textAlign: 'center',
        }),
      },
      {
        condition: value => value === 'MODERADA',
        style: theme => ({
          color: theme?.palette?.semantics?.feedback?.warning?.natural,
          backgroundColor: theme?.palette?.semantics?.feedback?.warning?.light,
          textAlign: 'center',
        }),
      },
      {
        condition: value => value === 'GRAVÍSSIMA' || value === 'GRAVE',
        style: theme => ({
          color: theme?.palette?.semantics?.feedback?.attention?.natural,
          backgroundColor:
            theme?.palette?.semantics?.feedback?.attention?.light,
          textAlign: 'center',
        }),
      },
    ],
  },
];
