import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin: 2rem 0;
  h2 {
    font-weight: 400;
    font-size: 20px;
    color: ${({ theme }) => theme.palette.words.title.natural};

    span {
      font-weight: 900;
    }
  }
`;

export const BarContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 0.25rem;
  align-items: center;
  height: 3rem;
`;

export const Item = styled.div`
  height: 2.5rem;
  border: 2px solid #ffffff;

  -moz-transition: height 200ms ease;
  -webkit-transition: height 200ms ease;
  -o-transition: height 200ms ease;
  transition: height 200ms ease;

  ${({ percentage, color, theme }) => css`
    background-color: ${color ?? theme.palette.brand.primary.background};
    width: ${percentage ? `${percentage}%` : '0%'};
  `}
  :hover {
    height: 3rem;
  }
`;

export const PopContainer = styled.div`
  padding: 1rem;
  font-size: 14px;
  line-height: 18px;
  color: #4b5166;

  .title {
    font-weight: 900;
  }
`;
