import styled, { css } from 'styled-components';

export const Input = styled.input`
  ${({ theme }) => css`
    width: 100%;
    padding: 10px;
    outline: none;
    border: none;
    border: 1px solid ${theme.palette.brand.primary.light};
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 3px;
    font-weight: 600;
    color: ${theme.palette.words.subtitle.natural};
  `}
`;
