import * as types from './types';

const INITIAL_STATE = {
  tipo: '',
  criticidade: '',
};

export default function filterEstatisticasDesviosProvider(
  state = INITIAL_STATE,
  action,
) {
  switch (action.type) {
    case types.SET_FILTER_ESTATISTICAS_PROVIDER: {
      return { ...state, ...action.payload };
    }
    case types.RESET_FILTER_ESTATISTICAS_PROVIDER: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
}
