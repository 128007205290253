// Styled

// React
import { useEffect, useState } from 'react';

// Components

// Components MUI
import { Divider } from '@mui/material';

// Date Library
import TextField from '@mui/material/TextField';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { changeText } from '../../actions';
import { WithQuestion } from '..';
import * as S from './styled';

export const ResponseHora = ({
  field,
  secoes,
  setSecoes,
  sectionId,
  errosObrigatorios,
  preview,
  approveView,
}) => {
  const redirectFunction = (texto, idCampo) => {
    setCleanField(true);
    changeText(texto, idCampo, setSecoes, secoes, sectionId);
  };

  // ------------------------------------ERROS-------------------------------------------//
  const [hasErrorObrigatorio, setHasErrorObrigatorio] = useState(false);
  const [cleanField, setCleanField] = useState(false);

  useEffect(() => {
    // Reset fields
    setHasErrorObrigatorio(false);
    setCleanField(false);
    if (approveView) return null;

    if (errosObrigatorios) {
      for (const i in errosObrigatorios) {
        const erros = errosObrigatorios[i];
        if (sectionId === erros.idSecao && field.id === erros.idCampo) {
          setHasErrorObrigatorio(true);
        }
      }
    }
  }, [errosObrigatorios]);

  return (
    <>
      <S.QuestionBox
        hasError={hasErrorObrigatorio && !cleanField}
        className={`fieldClass-${field.id}`}
        preview={preview}
      >
        <WithQuestion
          field={field}
          secoes={secoes}
          setSecoes={setSecoes}
          sectionId={sectionId}
          hasError={hasErrorObrigatorio && !cleanField}
          preview={preview}
          approveView={approveView}
        />
        <S.ResponseArea>
          <S.BlockInput preview={preview}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="pt-BR"
            >
              <TimePicker
                label="Selecione um horário"
                value={field.resposta ? field.resposta : null}
                onChange={newValue => redirectFunction(newValue, field.id)}
                renderInput={params => (
                  <TextField
                    {...params}
                    error={hasErrorObrigatorio && !cleanField}
                  />
                )}
                disabled={approveView}
              />
            </LocalizationProvider>
          </S.BlockInput>
        </S.ResponseArea>
      </S.QuestionBox>
      <Divider />
    </>
  );
};
