import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { PlayCircle, Close, OpenInFull } from '@mui/icons-material';
import { useTheme } from 'styled-components';
import ImageModal from 'components/ImageModal';
import { firestorageVideos } from 'utils/firebase';
import * as S from './styled';

const ImageDesvio = ({ infraction, onlyImage }) => {
  const theme = useTheme();
  const [videoUrl, setVideoUrl] = useState('');
  const [videoActive, setVideoActive] = useState(false);
  const [expandedImage, setExpandedImage] = useState(false);

  useEffect(() => {
    // Monta o link do vídeo no momento do desvio
    // Se o período não for encontrado, o vídeo não será exibido!
    const getVideoData = () => {
      if (infraction.video_ocorrencia) {
        // Obs: homolog não tem vídeos. valor mocado para REACT_APP_ENV = 'development'

        const caminho =
          process.env.REACT_APP_ENV !== 'production'
            ? 'Transpedrosa/Moveit Transpedrosa/10207#ADRIANO DOUGLAS LOPES/2022-39/10207#ADRIANO DOUGLAS LOPES_2022-39.mp4'
            : infraction.video_ocorrencia || '';

        if (caminho.includes('http://') || caminho.includes('https://')) {
          setVideoUrl(caminho);
        } else if (caminho) {
          firestorageVideos
            .ref(caminho)
            .getDownloadURL()
            .then(fireBaseUrl => {
              if (fireBaseUrl) {
                setVideoUrl(fireBaseUrl);
              }
            })
            .catch(err => console.log(err));
        }
      } else {
        // Obs: homolog não tem vídeos. valor mocado para REACT_APP_ENV = 'development'
        const caminho =
          process.env.REACT_APP_ENV !== 'production'
            ? 'Transpedrosa/Moveit Transpedrosa/10207#ADRIANO DOUGLAS LOPES/2022-39/10207#ADRIANO DOUGLAS LOPES_2022-39.mp4'
            : infraction.avaliacao?.caminho_cloud || '';
        if (caminho) {
          firestorageVideos
            .ref(caminho)
            .getDownloadURL()
            .then(fireBaseUrl => {
              if (fireBaseUrl) {
                if (
                  infraction.frame_pos !== null &&
                  infraction.frame_pos !== undefined
                ) {
                  let pos_inicial = infraction.frame_pos - 5;
                  if (pos_inicial < 0) pos_inicial = 0;
                  setVideoUrl(
                    `${fireBaseUrl}#t=${infraction.frame_pos},${
                      infraction.frame_pos + 20
                    }`,
                  );
                }
                // Descomentar para visualizar vídeo mesmo sem período específico
                // else setVideoUrl(fireBaseUrl)
              }
            })
            .catch(err => console.log(err));
        }
      }
    };
    getVideoData();
  }, [infraction]);

  return (
    <>
      {!infraction.video_ocorrencia && (!videoActive || onlyImage) && (
        <S.ImageContainer>
          <img src={infraction.img_ocorrencia} alt="imagem_desvio" />

          {videoUrl && (
            <div className="playVideo" onClick={() => setVideoActive(true)}>
              <PlayCircle
                sx={{ color: theme.palette.brand.primary.light, fontSize: 50 }}
              />
            </div>
          )}
          {infraction.img_ocorrencia && (
            <div className="expandImage" onClick={() => setExpandedImage(true)}>
              <OpenInFull
                sx={{
                  color: theme.palette.brand.secondary.natural,
                  fontSize: 40,
                }}
              />
            </div>
          )}
        </S.ImageContainer>
      )}
      {(infraction.video_ocorrencia || videoActive) &&
        videoUrl &&
        !onlyImage && (
          <S.VideoContainer>
            <ReactPlayer
              url={videoUrl}
              controls
              playing
              width="100%"
              height="100%"
            />
            {!infraction.video_ocorrencia && (
              <span onClick={() => setVideoActive(false)}>
                <Close
                  sx={{
                    color: theme.palette.brand.secondary.natural,
                    fontSize: 40,
                  }}
                />
              </span>
            )}
          </S.VideoContainer>
        )}

      <ImageModal
        open={expandedImage}
        handleClose={() => setExpandedImage(false)}
        image={infraction.img_ocorrencia}
      />
    </>
  );
};

export default ImageDesvio;
