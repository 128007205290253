import styled, { css } from 'styled-components';
import { Modal } from '@mui/material';

export const ModalMui = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  ${({ theme }) => css`
    max-width: 80%;
    max-height: 90%;
    overflow-y: scroll;
    background-color: ${theme.palette.system.overlay};
    border-radius: 5px;
    border: 1px solid ${theme.palette.system.border};
    position: relative;
  `}
`;

export const Wrapper = styled.div`
  padding: 20px;
`;

export const Header = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;

    .titleModal {
      display: flex;
      justify-content: center;
      align-items: center;

      .text {
        font-weight: 900;
        font-size: 22px;
        color: ${theme.palette.words.title.natural};
        margin-left: 20px;
      }
    }
  `}
`;

export const Body = styled.div`
  display: flex;
  gap: 30px;
`;

export const LeftContent = styled.div`
  width: 50%;

  .content-two-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }

  .leftBoxArea {
    margin-top: 10px;
  }

  .buttonArea {
    margin-top: 20px;
    display: flex;
    justify-content: end;
    align-items: center;
    position: absolute;
    right: 0;
    bottom: 0;
    margin-right: 20px;
    margin-bottom: 10px;
  }

  .content-one-grid {
    margin-top: 15px;
  }
`;

export const RightContent = styled.div`
  width: 50%;

  .leftBoxArea {
    margin-top: 10px;
  }

  .content-two-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;

    .boxFlex {
      margin-top: 20px;
      display: flex;
      align-items: center;
    }
  }
`;

export const StyledLogoWrapper = styled.div`
  ${({ theme }) => css`
    margin-right: 10px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid ${theme.palette.brand.secondary.natural};
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;

export const StyledLogo = styled.div`
  ${({ theme, ...props }) => css`
    height: 42px;
    width: 42px;
    border-radius: 50%;
    border: ${props.border};
    background-image: ${props.backgroundImage};
    background-color: ${theme.palette.semantics.feedback.unknown.light};
    display: flex;
    align-items: center;
    justify-content: center;

    h2 {
      font-size: 20px;
      color: ${theme.palette.brand.primary.natural};
      text-align: center;
    }
  `}
`;

export const StyledTextWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;

    h1 {
      font-size: 16px;
      color: ${theme.palette.words.title.natural};
      font-weight: 600;
      text-transform: capitalize;
      text-align: left;
    }
    p {
      color: ${theme.palette.words.subtitle.natural};
      font-size: 14px;
      font-weight: 500;
      text-align: left;
    }
  `}
`;
