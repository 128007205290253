import styled, { css } from 'styled-components';
import { Paper } from '@mui/material';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Card = styled(Paper)`
  padding: 20px;
  min-height: 400px;
  border-radius: 5px;
`;

const Header = styled.div`
  padding: 22px 0px;
  display: flex;
  justify-content: space-between;
`;

const Title = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;

    h1 {
      font: normal normal 900 24px/30px Texta;
      color: ${theme.palette.brand.primary.natural};
    }

    h2 {
      font: normal normal 400 22px/30px Texta;
      color: ${theme.palette.brand.primary.natural};
      margin-left: 5px;
    }
  `}
`;

const CardContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    position: relative;

    h3 {
      font: normal normal 400 18px/25px Texta;
      color: ${theme.palette.brand.primary.natural};
      margin-right: 15px;
    }
  `}
`;

const TextContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: flex-start;
    position: relative;
    flex-direction: column;
    padding: 15px 20px;
    background: ${theme.palette.brand.primary.background};
    border-radius: 4px;
    margin: 5px 20px;
    border: 1px solid #b0c3d329;
  `}
`;

export { Wrapper, Card, Header, Title, CardContent, TextContent };
