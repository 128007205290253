import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.palette.system.highlight};
  width: 100%;
  min-height: 100%;
  margin-top: 10px;
`;

export const WeekBox = styled.div`
  display: flex;
  width: calc(100% * ${props => props.total});
  margin-left: calc(-100% * ${props => props.semana});
  transition: all ease 0.5s;
`;

export const Slide = styled.div`
  width: calc(100% / ${props => props.total});
  transition: opacity 0.7s ease-out;
  display: flex;
  justify-content: center;

  &.visible {
    opacity: 1;
  }

  &.invisible {
    opacity: 0.4;
  }
`;

export const Col = styled.div`
  border-right: 2px solid ${({ theme }) => theme.palette.system.border};
  flex: 1;

  &:first-child {
    border-left: 2px solid ${({ theme }) => theme.palette.system.border};
  }
`;

export const Header = styled.div`
  border-bottom: 2px solid ${({ theme }) => theme.palette.system.border};
  border-top: 2px solid ${({ theme }) => theme.palette.system.border};
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: ${({ theme }) => theme.palette.words.title.natural};

  .day {
    margin-right: 7px;
  }

  .number {
    background-color: ${({ theme, ...props }) =>
      props.active
        ? theme.palette.brand.secondary.natural
        : theme.palette.system.overlay};
    color: ${({ theme, ...props }) =>
      props.active
        ? theme.palette.words.title.contrast
        : theme.palette.words.title.natural};
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    border-radius: 25px;
  }
`;

export const TasksBox = styled.div`
  height: 400px;
  overflow-y: auto;

  @media (min-width: 2000px) {
    height: 620px;
  }
`;

export const BoxItem = styled.div`
  width: 100%;
  height: 48px;
  border-bottom: 2px solid ${({ theme }) => theme.palette.system.border};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
`;

export const Task = styled.div`
  padding: 3px 10px;
  border-radius: 4px;
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: start;
  align-items: center;
  font-size: 15px;

  &:hover {
    opacity: 0.8;
  }

  .icon {
    margin-left: 5px;
  }

  span {
    margin-left: 4px;
  }
`;

export const PopoverBox = styled.div`
  border-top: 5px solid ${props => props.color};
  padding: 10px 20px;
  min-width: 400px;

  .topArea {
    display: flex;
    align-items: center;

    .textArea {
      margin-left: 20px;
      display: flex;
      flex-direction: column;

      .desc {
        color: ${({ theme }) => theme.palette.words.title.natural};
        font-weight: 500;
      }
      .data {
        color: ${({ theme }) => theme.palette.words.subtitle.natural};
        font-size: 14px;
      }
    }
  }

  .bottomArea {
    display: flex;
    justify-content: end;
    margin-top: 10px;
  }
`;

export const FinishButton = styled.button`
  border: none;
  background-color: ${({ theme }) => theme.palette.brand.primary.natural};
  color: ${({ theme }) => theme.palette.words.title.natural};
  font-weight: 500;
  padding: 2px 20px;
  border-radius: 20px;
`;
