import styled, { css } from 'styled-components';

export const TextAnswers = styled.input`
  ${({ theme, ...props }) => css`
    border: none;
    outline: none;
    margin-left: 30px;
    margin-right: 30px;
    border-bottom: 1px solid ${theme.palette.system.transparent};
    font-size: 16px;
    width: 80%;
    padding: 2px 5px;
    transition: all ease 0.2s;
    cursor: ${props.hasDisabled ? 'default' : 'text'};
    pointer-events: ${props.hasDisabled ? 'none' : 'all'};

    &::placeholder {
      font-size: 16px;
    }

    &:focus {
      border-bottom: 1px solid
        ${theme.palette.semantics.feedback.unknown.natural};
    }
  `}
`;
