/* eslint-disable import/order */

// Styles
import * as S from './styled';
import colors from 'styles/colors';
import OnisysLogo from 'images/Onisys_Simbolo.png';

// React
import ReactPlayer from 'react-player';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import DefaultButton from 'components/Buttons/Default';

// Components MUI
import { Tooltip } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ForkRightRoundedIcon from '@mui/icons-material/ForkRightRounded';
import FullscreenRoundedIcon from '@mui/icons-material/FullscreenRounded';
import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined';
import FullscreenExitRoundedIcon from '@mui/icons-material/FullscreenExitRounded';
import ThumbDownOffAltOutlinedIcon from '@mui/icons-material/ThumbDownOffAltOutlined';
import WorkspacePremiumOutlinedIcon from '@mui/icons-material/WorkspacePremiumOutlined';

export const View = ({
  guide,
  open,
  handleClose,
  preview,
  callGuide,
  callPage,
  execute,
  forcedClose,
  isLast,
  initialStep,
  evaluate,
}) => {
  // Navigate
  const navigate = useNavigate();

  // User Infos
  const { user } = useSelector(state => state.auth.user);

  // Transition Effects
  const [full, setFull] = useState(false);
  const [transitionFull, setTransitionFull] = useState(true);
  const [transitionSmall, setTransitionSmall] = useState(true);
  const [transitionFinish, setTransitionFinish] = useState(true);
  const [screen, setScreen] = useState(0);
  const [evaluation, setEvaluation] = useState({
    nota: null,
    comentario: '',
  });

  // ---------------------------FORMATE---------------------------//

  const basePercentage = () => {
    const hasCapa = data[0]?.is_capa ? 1 : 0;
    const totalSteps = data.length - hasCapa;
    const crStep = currentStep - hasCapa;
    return Math.round(((crStep + 1) / totalSteps) * 100);
  };

  const baseStep = () => {
    const hasCapa = data[0]?.is_capa ? 1 : 0;
    const totalSteps = data.length - hasCapa;
    const crStep = currentStep - hasCapa;
    return `${crStep + 1}/${totalSteps}`;
  };

  const textNextButton = () => {
    return currentStep === data.length - 1 ? 'Finalizar' : 'Próximo';
  };

  const textBackButton = () => {
    return currentStep === 0 ? '' : 'Anterior';
  };

  const initialButton = () => {
    const hasCapa = data && data[0]?.is_capa ? 1 : 0;
    const totalSteps = data.length - hasCapa;
    return totalSteps ? 'Começar Tour' : 'Começar a usar';
  };

  const calcSize = () => {
    const item = data && data[currentStep];
    const link = item?.link ? 245 : 0;

    const descLength = Math.ceil(
      (item?.descricao ? item.descricao.length : 0) / 60,
    );

    const description = item?.descricao ? 32 * descLength : 0;

    return link + description;
  };

  const calcSizeSmall = () => {
    const item = data && data[currentStep];
    const link = item?.link ? 205 : 0;

    const descLength = Math.ceil(
      (item?.descricao ? item.descricao.length : 0) / 60,
    );
    const description = item?.descricao ? 19 * descLength : 0;

    return link + description;
  };

  // ---------------------------SCREEN EFFECTS--------------------------- //
  const handleFull = () => {
    setTransitionFull(true);
    setTimeout(() => {
      setFull(false);
      setTimeout(() => {
        setTransitionSmall(false);
      }, 150);
    }, 300);
  };

  const handleSmall = () => {
    setTransitionSmall(true);
    setTimeout(() => {
      setFull(true);
      setTimeout(() => {
        setTransitionFull(false);
      }, 50);
    }, 300);
  };

  const handleCloseModal = (close, forced) => {
    setTransitionSmall(true);
    setTransitionFull(true);
    setTransitionFinish(true);
    setTimeout(() => {
      setFull(false);
      setTimeout(() => {
        (!close && isLast) ||
        (!close && !forced && callGuide && guide.habilitar_review)
          ? setTransitionFinish(false)
          : handleClose();
      }, 250);
    }, 300);

    // Forced Close
    forcedClose && forced && forcedClose(guide?.id, currentStep);
    forced && setScreen(1);
  };

  // ---------------------------INITIALIZE DATA --------------------------- //
  const [data, setData] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  useEffect(() => {
    const steps = guide?.etapas || [];
    const capa = guide?.capa;
    const activeCapa =
      capa?.ativa &&
      (capa?.niveis.includes(user?.nivel) || preview) &&
      ((callGuide && capa.mostrar_guia) ||
        (callPage && capa.mostrar_rota) ||
        preview);

    const unify = activeCapa ? [{ ...capa, is_capa: true }, ...steps] : steps;
    const activeSteps = unify?.filter(
      step =>
        step?.ativa &&
        (step?.niveis.includes(user?.nivel) || preview) &&
        ((callGuide && step.mostrar_guia) ||
          (callPage && step.mostrar_rota) ||
          preview),
    );

    if (activeSteps.length) {
      setTransitionFinish(true);
      setTimeout(() => {
        setScreen(!guide.habilitar_review ? 1 : 0);
      }, 700);

      setData(activeSteps);
      setCurrentStep(initialStep || 0);
      activeCapa && !initialStep && setFull(true);
      setTimeout(() => {
        activeCapa && !initialStep
          ? setTransitionFull(false)
          : setTransitionSmall(false);
      }, 50);
    } else {
      handleCloseModal(true);
    }
  }, [guide]);

  // ---------------------------HANDLE STEPPER---------------------------//

  const handleNext = () => {
    let title = document.getElementById('titleFull');
    title.classList.add('up');
    setTimeout(() => {
      title.classList.remove('up');
    }, 600);
    setTimeout(() => {
      if (currentStep < data?.length - 1) {
        !currentStep && !callGuide && handleFull();
        execute && execute(guide?.id, currentStep, false, guide.id_secao);
        setCurrentStep(currentStep + 1);
      } else {
        execute && execute(guide?.id, currentStep, true, guide.id_secao);
        handleCloseModal(preview);
      }
    }, 100);
  };

  const handleBack = () => {
    let title = document.getElementById('titleFull');
    title.classList.add('down');
    setTimeout(() => {
      title.classList.remove('down');
    }, 600);
    setTimeout(() => {
      if (currentStep > 0) {
        setCurrentStep(currentStep - 1);
      }
    }, 100);
  };

  const handleEvaluate = (key, value) => {
    setEvaluation({ ...evaluation, [key]: value });
  };

  const sendEvaluation = () => {
    evaluate && evaluate(guide?.id, evaluation);
    callGuide ? handleCloseModal(true) : setScreen(1);
  };

  // ---------------------------RENDER---------------------------//
  return (
    <>
      {open && (
        <>
          <S.Overlay isFull={full || preview} />
          {full && transitionFinish && (
            <S.ContainerFull inTransition={transitionFull}>
              <S.Header>
                <div className="texts">
                  <div className="icon">
                    <img src={OnisysLogo} alt="Onisys" />
                  </div>
                  <h1 className="title" id="titleFull">
                    {data && data[currentStep]?.titulo}
                  </h1>
                </div>
                <div className="actions">
                  {full && !callGuide && (
                    <Tooltip title="Minimizar" placement="top">
                      <FullscreenExitRoundedIcon onClick={() => handleFull()} />
                    </Tooltip>
                  )}
                  <Tooltip title="Fechar" placement="top">
                    <CloseRoundedIcon
                      onClick={() => handleCloseModal(preview, true)}
                    />
                  </Tooltip>
                </div>
              </S.Header>

              <S.Fixed>
                <S.ContainerMidia
                  totalWidth={data?.length}
                  current={currentStep}
                  setWIdth={calcSize()}
                >
                  {data?.map((item, index) => (
                    <S.Box key={index}>
                      {item.link && (
                        <S.Midia>
                          <>
                            {item.tipo === 'VIDEO' && (
                              <ReactPlayer
                                width="100%"
                                height="100%"
                                controls
                                playing={index === currentStep}
                                url={item.link}
                                light={item?.imagem_capa}
                              />
                            )}
                            {item.tipo === 'IMAGEM' && <img src={item.link} />}
                          </>
                        </S.Midia>
                      )}
                      {item.descricao && (
                        <S.Description>{item.descricao}</S.Description>
                      )}
                    </S.Box>
                  ))}
                </S.ContainerMidia>
              </S.Fixed>

              <S.Footer>
                {data && data[currentStep]?.is_capa && (
                  <DefaultButton
                    children={initialButton()}
                    icon={<RocketLaunchOutlinedIcon />}
                    style={{ width: '100%' }}
                    onClick={() => handleNext()}
                  />
                )}
                {data && !data[currentStep]?.is_capa && (
                  <S.CustomFooter percentage={() => basePercentage()}>
                    <div className="counter">
                      <div className="bar">
                        <div className="filled" />
                      </div>
                      <span className="number">{baseStep()}</span>
                    </div>
                    <div className="area">
                      <button
                        disabled={currentStep === 0}
                        onClick={() => handleBack()}
                      >
                        {textBackButton()}
                      </button>
                      <button onClick={() => handleNext()}>
                        {textNextButton()}
                      </button>
                    </div>
                  </S.CustomFooter>
                )}
              </S.Footer>
            </S.ContainerFull>
          )}

          {!full && transitionFinish && (
            <S.ContainerSmall inTransition={transitionSmall}>
              <S.Header className="small">
                <div className="texts">
                  <div className="icon">
                    <img src={OnisysLogo} alt="Onisys" />
                  </div>
                  <h1 className="title" id="titleFull">
                    {data && data[currentStep]?.titulo}
                  </h1>
                </div>
                <div className="actions">
                  {!full && (
                    <Tooltip title="Maximizar" placement="top">
                      <FullscreenRoundedIcon onClick={() => handleSmall()} />
                    </Tooltip>
                  )}
                  <Tooltip title="Fechar" placement="top">
                    <CloseRoundedIcon
                      onClick={() => handleCloseModal(preview, true)}
                    />
                  </Tooltip>
                </div>
              </S.Header>

              <S.Fixed>
                <S.ContainerMidia
                  className="small"
                  totalWidth={data?.length}
                  current={currentStep}
                  setWIdth={calcSizeSmall()}
                >
                  {data?.map((item, index) => (
                    <S.Box key={index}>
                      {item.link && (
                        <S.Midia className="small">
                          <>
                            {item.tipo === 'VIDEO' && (
                              <ReactPlayer
                                width="100%"
                                height="100%"
                                controls
                                playing={index === currentStep}
                                url={item.link}
                                light={item?.imagem_capa}
                              />
                            )}
                            {item.tipo === 'IMAGEM' && <img src={item.link} />}
                          </>
                        </S.Midia>
                      )}
                      {item.descricao && (
                        <S.Description className="small">
                          {item.descricao}
                        </S.Description>
                      )}
                    </S.Box>
                  ))}
                </S.ContainerMidia>
              </S.Fixed>

              <S.Footer className="small">
                {data && data[currentStep]?.is_capa && (
                  <DefaultButton
                    children={initialButton()}
                    icon={<RocketLaunchOutlinedIcon />}
                    style={{ width: '100%' }}
                    onClick={() => handleNext()}
                  />
                )}
                {data && !data[currentStep]?.is_capa && (
                  <S.CustomFooter
                    className="small"
                    percentage={
                      Math.round((currentStep + 1) * 100) / data?.length
                    }
                  >
                    <div className="counter">
                      <div className="bar">
                        <div className="filled" />
                      </div>
                    </div>
                    <div className="area">
                      <button
                        disabled={currentStep === 0}
                        onClick={() => handleBack()}
                      >
                        {textBackButton()}
                      </button>
                      <button onClick={() => handleNext()}>
                        {textNextButton()}
                      </button>
                    </div>
                  </S.CustomFooter>
                )}
              </S.Footer>
            </S.ContainerSmall>
          )}

          <S.ContainerSmall
            inTransition={transitionFinish}
            screen={screen}
            className="final"
          >
            <div className="sliderContainer">
              <div className="screen">
                <S.Header className="small">
                  <div className="texts">
                    <div className="icon">
                      <WorkspacePremiumOutlinedIcon />
                    </div>
                    <h1 className="title" id="titleFull">
                      O que achou do conteúdo?
                    </h1>
                  </div>
                  <div className="actions">
                    <Tooltip title="Fechar" placement="top">
                      <CloseRoundedIcon
                        onClick={() =>
                          screen === 0 && !callGuide
                            ? setScreen(1)
                            : handleCloseModal(true)
                        }
                      />
                    </Tooltip>
                  </div>
                </S.Header>

                <S.BodyEvaluation>
                  <div className="evaluation">
                    <div
                      className={evaluation.nota === 0 ? 'box active' : 'box'}
                      onClick={() => handleEvaluate('nota', 0)}
                    >
                      <ThumbDownOffAltOutlinedIcon
                        htmlColor={colors.redDanger}
                      />
                      <span>Não curti</span>
                    </div>

                    <div
                      className={evaluation.nota === 1 ? 'box active' : 'box'}
                      onClick={() => handleEvaluate('nota', 1)}
                    >
                      <ThumbUpOutlinedIcon htmlColor={colors.greenSucces} />
                      <span>Curti</span>
                    </div>
                  </div>
                  <label className="comment">
                    Comentário
                    <input
                      placeholder="Comente aqui"
                      value={evaluation.comentario}
                      onChange={e =>
                        handleEvaluate(
                          'comentario',
                          e.target.value.slice(0, 50),
                        )
                      }
                    />
                  </label>
                </S.BodyEvaluation>

                <S.Footer className="small right">
                  <DefaultButton
                    children="Enviar avaliação"
                    onClick={() => sendEvaluation()}
                    className="final"
                  />
                </S.Footer>
              </div>
              <div className="screen">
                <S.Header className="small">
                  <div className="texts">
                    <div className="icon">
                      <img src={OnisysLogo} alt="Onisys" />
                    </div>
                    <h1 className="title" id="titleFull">
                      Quer saber mais sobre o Onisys?
                    </h1>
                  </div>
                  <div className="actions">
                    <Tooltip title="Fechar" placement="top">
                      <CloseRoundedIcon
                        onClick={() => handleCloseModal(true)}
                      />
                    </Tooltip>
                  </div>
                </S.Header>

                <S.Footer className="small">
                  <DefaultButton
                    children="Acesse o guia"
                    icon={<ForkRightRoundedIcon />}
                    style={{ width: '100%' }}
                    onClick={() => navigate('/guia-usuario')}
                    className="final"
                  />
                </S.Footer>
              </div>
            </div>
          </S.ContainerSmall>
        </>
      )}
      {!open && <></>}
    </>
  );
};
