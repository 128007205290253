import { Skeleton } from '@mui/material';
import React from 'react';
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';

const getDataMax = datamax => {
  console.log(datamax);
  return datamax > 100
    ? Math.ceil(datamax / 100) * 100
    : datamax > 10
    ? Math.ceil(datamax / 10) * 10
    : datamax > 5
    ? Math.ceil(datamax / 5) * 5
    : 5;
};

const CustomLabel = props => {
  const { x, y, width, height, value, percentage, name } = props;
  const valueText = `${value}`;
  const percentageText = `  (${percentage}%)`;
  const valueLengthEstimate = valueText.length * 9;

  return (
    <g>
      <text
        x={x + 5}
        y={y - 10}
        fill="#4B5166"
        textAnchor="start"
        dominantBaseline="middle"
      >
        {name}
      </text>
      <text
        x={x + width + 5}
        y={y + height / 2}
        fill="#4B5166"
        textAnchor="start"
        dominantBaseline="middle"
      >
        {`${value}`}
      </text>
      <text
        x={x + width + 5 + valueLengthEstimate}
        y={y + height / 2}
        fill="#95AAC9"
        textAnchor="start"
        dominantBaseline="middle"
      >
        {percentageText}
      </text>
    </g>
  );
};

export const BarGraph = ({ height, data = [], loading }) => {
  return (
    <ResponsiveContainer width="100%" height={height}>
      {loading ? (
        <Skeleton
          style={{ marginTop: '1rem' }}
          height={height}
          animation="wave"
          variant="rounded"
        />
      ) : (
        <BarChart
          data={data}
          margin={{
            top: 20,
            right: 50,
            left: 0,
            bottom: 0,
          }}
          layout="vertical"
        >
          <YAxis dataKey="motivo_exclusao" type="category" hide />
          <XAxis dataKey="total" type="number" domain={[0, getDataMax]} hide />
          <Bar
            barSize={20}
            dataKey="total"
            fill="#95AAC9"
            radius={2}
            label={({ x, y, width, height, index }) => (
              <CustomLabel
                key={index}
                x={x}
                index={index}
                y={y}
                width={width}
                height={height}
                value={data[index].total}
                percentage={parseFloat(data[index].porcentagem)}
                name={data[index].motivo_exclusao}
              />
            )}
          />
        </BarChart>
      )}
    </ResponsiveContainer>
  );
};
