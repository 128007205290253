import api from 'services/api';

export const getGuia = async provider => {
  const response = await api.get(
    `${provider ? '/provider' : ''}/guide-steps-status`,
  );
  return response.data;
};

export const responseGuia = async (provider, data) => {
  const response = await api.post(
    `${provider ? '/provider' : ''}/guide-steps/${data.id}`,
    data,
  );
  return response.data;
};

export const evaluateGuia = async (provider, id, data) => {
  const response = await api.put(
    `${provider ? '/provider' : ''}/guide-evaluate/${id}`,
    data,
  );
  return response.data;
};
