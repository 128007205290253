/* eslint-disable import/order */

// Styles
import * as S from './styled';

// React
import { useState } from 'react';

// Components MUI
import { Popover, MenuItem } from '@mui/material';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';

export const Actions = ({ id, disabled, options = [], item = {} }) => {
  const [anchor, setAnchor] = useState(null);

  return (
    <S.Container>
      <S.Button
        disabled={disabled}
        onClick={e => setAnchor(e.currentTarget)}
        data-testid="test-actions-table"
      >
        <MoreHorizRoundedIcon />
      </S.Button>
      <Popover
        id={anchor ? 'popover' : undefined}
        open={Boolean(anchor)}
        anchorEl={anchor}
        onClose={() => setAnchor(null)}
        onClick={() => setAnchor(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        {options?.map((option, idx) => {
          const hasItem = Object?.keys(item)?.length;
          const visible =
            option?.visible && hasItem ? option?.visible(item) : true;

          if (visible) {
            return (
              <MenuItem
                key={idx}
                disabled={disabled}
                aria-hidden={!anchor}
                onClick={() => option?.function(id, item)}
                data-testid="test-actions-items-popover"
              >
                <S.Option>{option?.title}</S.Option>
              </MenuItem>
            );
          }
        })}
      </Popover>
    </S.Container>
  );
};
