import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { subHours } from 'date-fns';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { formatNameDate, formatNewDate } from 'utils/dates';

// Actions
import {
  setFilter,
  setGroupBy,
} from 'store/modules/filterTelemetriaFatoresRisco/actions';

// Componentes
import { Grid, SvgIcon } from '@mui/material';
import { SaveAlt } from '@mui/icons-material';
import Graph from 'components/Graphs/ComposedChart';
import Tab from 'components/Tabs';
import Select from 'components/Inputs/Select';
import GhostButton from 'components/Buttons/Ghost';
import { ReactComponent as filterIco } from 'images/icons/filter.svg';

// Service
import * as S from '../styled';
import { requestEstatisticas, exportToExcel } from './services';
import Filters from './Filters';

const valuesSelect = [
  { value: 'QUANTIDADE', name: 'Quantidade de Desvios' },
  { value: 'PONTOS', name: 'Total de Pontos' },
];

const getDayOfWeek = day => {
  switch (day) {
    case 0:
      return 'Domingo';
    case 1:
      return 'Segunda';
    case 2:
      return 'Terça';
    case 3:
      return 'Quarta';
    case 4:
      return 'Quinta';
    case 5:
      return 'Sexta';
    case 6:
      return 'Sábado';
    default:
      return '';
  }
};

const FatoresRisco = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { user } = useSelector(prevFilter => prevFilter.auth.user);
  const userLevel = user?.nivel;
  const isProvider = user?.provider;
  const [filter, filterRisco, groupBy] = useSelector(state => {
    return [
      isProvider ? state.filterProvider : state.filter,
      state.filterTelemetriaFatoresRisco.filter,
      state.filterTelemetriaFatoresRisco.groupBy,
    ];
  });

  const countFilter = Object.getOwnPropertyNames(filterRisco).length;
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingExcel, setLoadingExcel] = useState(false);
  const [graphFilter, setGraphFilter] = useState({
    graphId: null,
    value: null,
  });

  const [topFive, setTopFive] = useState([]);
  const [data, setData] = useState([]);
  const [topFiveTab, setTopFiveTab] = useState('drivers');

  const [riscos, setRiscos] = useState(null);
  const [riscosTab, setRiscosTab] = useState('drivers');

  const [daysOfWeek, setDaysOfWeek] = useState(null);
  const [periods, setPeriods] = useState(null);

  const setFilterRisco = newState => {
    dispatch(setFilter(newState));
  };

  const valuesTopFive = useMemo(
    () => [
      isProvider
        ? { value: 'drivers', label: 'EMPRESAS' }
        : { value: 'drivers', label: 'MOTORISTAS' },
      { value: 'trucks', label: 'PLACAS' },
    ],
    [isProvider],
  );

  const valuesRiscos = [
    { value: 'drivers', label: 'MOTORISTAS' },
    { value: 'trucks', label: 'VEÍCULOS' },
  ];

  const formatArrayFilter = useCallback(
    filters => {
      const _filters = {};
      Object.keys(filters).forEach(key => {
        _filters[key] = filters[key].split(',');
      });

      // Se nivel 2 ou 3 sempre filtra filial
      if (userLevel > 1) _filters.filiais = [user?.id_da_filial];
      return _filters;
    },
    [userLevel, user],
  );

  // aplica o filtro de coluna
  const filterData = useCallback(
    (graphId, data, graphFilter) => {
      const _data = [...data];
      if (!graphFilter.graphId || graphFilter.graphId === graphId) return _data;

      if (graphFilter.graphId === 'topFive') {
        if (isProvider && topFiveTab === 'drivers')
          return _data.filter(item => item.nome_empresa === graphFilter.value);
        if (!isProvider && topFiveTab === 'drivers')
          return _data.filter(
            item => item.nome_motorista === graphFilter.value,
          );
        if (topFiveTab === 'trucks')
          return _data.filter(item => item.placa === graphFilter.value);
      }

      if (graphFilter.graphId === 'riscos') {
        if (riscosTab === 'drivers')
          return _data.filter(
            item => item.tipo_motorista === graphFilter.value,
          );
        if (riscosTab === 'trucks')
          return _data.filter(item => item.tipo_veiculo === graphFilter.value);
      }

      if (graphFilter.graphId === 'daysOfWeek') {
        return _data.filter(item => item.dia_semana === graphFilter.value);
      }

      if (graphFilter.graphId === 'periods') {
        return _data.filter(item => item.dia_periodo === graphFilter.value);
      }

      return _data;
    },
    [data, groupBy, isProvider, topFiveTab, riscosTab],
  );

  // obtem os dados dos graficos
  const reduceData = useCallback(
    (graphId, _data, property, graphFilter) => {
      const total = _data.length;
      const totalPontos = _data.reduce((acc, objeto) => {
        return acc + (objeto.pontos ?? 0);
      }, 0);

      const groupedData = _data.reduce((acc, objeto) => {
        const pontos = objeto.pontos ?? 0;
        const key = objeto[property] ?? 'Indefinido';
        if (acc[key]) {
          acc[key].pontos += pontos;
          acc[key].quantidade += 1;
        } else {
          acc[key] = { pontos, quantidade: 1 };
        }
        return acc;
      }, {});

      const formatted = Object.entries(groupedData).map(
        ([name, { quantidade, pontos }]) => {
          const value = groupBy === 'QUANTIDADE' ? quantidade : pontos;
          const _total = groupBy === 'QUANTIDADE' ? total : totalPontos;
          return {
            name,
            value,
            percentual:
              graphFilter.graphId !== graphId && graphFilter.value
                ? null
                : ((value / _total) * 100).toFixed(2),
            selected:
              graphFilter.graphId === graphId && graphFilter.value === name,
          };
        },
      );
      formatted.sort((a, b) => b.value - a.value);
      return formatted;
    },
    [groupBy],
  );

  // Fetch data
  const fetchData = async query => {
    setLoading(true);
    const res = await requestEstatisticas(query);
    if (res.data?.success) {
      if (res.data?.data instanceof Array) {
        setData(
          res.data?.data.map(item => ({
            ...item,
            dia_semana: getDayOfWeek(item.dia_semana),
          })),
        );
      }
    } else if (res.data?.message) toast.error(res.data.message);
    setLoading(false);
  };

  useEffect(() => {
    const query = {
      ...filter,
      initialDate: subHours(new Date(filter.initialDate), 3),
      finalDate: subHours(new Date(filter.finalDate), 3),
      ...formatArrayFilter(filterRisco),
    };

    fetchData(query);
  }, [filter, filterRisco]);

  // Set TopFive
  useEffect(() => {
    if (data.length) {
      const filtered = filterData('topFive', data, graphFilter);
      const property =
        topFiveTab === 'drivers'
          ? isProvider
            ? 'nome_empresa'
            : 'nome_motorista'
          : 'placa';
      const formatted = reduceData('topFive', filtered, property, graphFilter);

      setTopFive(formatted.slice(0, 5));
    } else setTopFive([]);
  }, [data, groupBy, topFiveTab, graphFilter]);

  // Set riscos/motorista|tipo_veiculo
  useEffect(() => {
    if (data.length) {
      const filtered = filterData('riscos', data, graphFilter);
      const property =
        riscosTab === 'drivers' ? 'tipo_motorista' : 'tipo_veiculo';

      const formatted = reduceData('riscos', filtered, property, graphFilter);

      setRiscos(formatted);
    } else setRiscos([]);
  }, [data, groupBy, riscosTab, graphFilter]);

  // Set daysOfWeek
  useEffect(() => {
    if (data.length) {
      const filteredDays = filterData('daysOfWeek', data, graphFilter);
      const formattedDays = reduceData(
        'daysOfWeek',
        filteredDays,
        'dia_semana',
        graphFilter,
      );
      setDaysOfWeek(formattedDays);

      const filteredPeriods = filterData('periods', data, graphFilter);
      const formattedPeriods = reduceData(
        'periods',
        filteredPeriods,
        'dia_periodo',
        graphFilter,
      );
      setPeriods(formattedPeriods);
    } else {
      setDaysOfWeek([]);
      setPeriods([]);
    }
  }, [data, groupBy, graphFilter]);

  const handleChangeGroup = e => {
    dispatch(setGroupBy(e.target.value));
  };

  const handleChangeGraphFilter = (graphId, value) => {
    if (!graphFilter.graphId || graphFilter.graphId === graphId)
      !graphFilter.value || graphFilter.value !== value
        ? setGraphFilter({ graphId, value })
        : setGraphFilter({ graphId: null, value: null });
  };

  // Exportção excel
  const handleExcel = async () => {
    try {
      const nameTopFive = valuesTopFive.find(
        item => item.value === topFiveTab,
      ).label;
      const nameRiscos = valuesRiscos.find(
        item => item.value === riscosTab,
      ).label;
      const excelData = {
        'TOP 5 Ofensores': topFive.map(item => ({
          [nameTopFive]: item.name,
          [groupBy]: item.value,
          'PERCENTUAL DO TODO %': Number(item.percentual),
          'DATA INICIAL': formatNewDate(
            subHours(new Date(filter.initialDate), 3),
          ),
          'DATA FINAL': formatNewDate(subHours(new Date(filter.finalDate), 3)),
        })),
        'Riscos por Motorista': riscos.map(item => ({
          [nameRiscos]: item.name,
          [groupBy]: item.value,
          'PERCENTUAL DO TODO %': Number(item.percentual),
          'DATA INICIAL': formatNewDate(
            subHours(new Date(filter.initialDate), 3),
          ),
          'DATA FINAL': formatNewDate(subHours(new Date(filter.finalDate), 3)),
        })),
        'Riscos por Dias da Semana': daysOfWeek.map(item => ({
          DIA: item.name,
          [groupBy]: item.value,
          'PERCENTUAL DO TODO %': Number(item.percentual),
          'DATA INICIAL': formatNewDate(
            subHours(new Date(filter.initialDate), 3),
          ),
          'DATA FINAL': formatNewDate(subHours(new Date(filter.finalDate), 3)),
        })),
        'Riscos por Periodo do Dia': periods.map(item => ({
          PERÍODO: item.name,
          [groupBy]: item.value,
          'PERCENTUAL DO TODO %': Number(item.percentual),
          'DATA INICIAL': formatNewDate(
            subHours(new Date(filter.initialDate), 3),
          ),
          'DATA FINAL': formatNewDate(subHours(new Date(filter.finalDate), 3)),
        })),
      };
      const formatedDate = formatNameDate(new Date());

      exportToExcel(excelData, `fatores_de_risco_telemetria_${formatedDate}`);
      toast.success('Arquivo gerado com sucesso!');
    } catch (err) {
      toast.error('Falha ao gerar arquivo.');
    }
  };

  // renders
  const renderHeader = () => (
    <S.MapHeader>
      <S.MapFilters>
        <Select
          style={{ height: '44px', marginTop: '4px' }}
          data={valuesSelect}
          value={groupBy}
          handleChange={handleChangeGroup}
          disabled={loading}
        />
        <S.StyledButton
          textcolor={theme.palette.words.text.natural}
          backgroundcolor="transparent"
          startIcon={<SvgIcon component={filterIco} />}
          height="45px"
          style={{ marginTop: '3px' }}
          onClick={event => setAnchorEl(event.currentTarget)}
          disabled={loading}
        >
          Filtros
          <S.Count count={!!countFilter}>{countFilter}</S.Count>
        </S.StyledButton>
      </S.MapFilters>
      <S.MapActions>
        <GhostButton
          startIcon={<SaveAlt />}
          loading={loadingExcel}
          size="medium"
          onClick={() => handleExcel()}
          style={{ marginLeft: '10px' }}
        >
          EXPORTAR
        </GhostButton>
      </S.MapActions>
    </S.MapHeader>
  );

  const rightYAxisMax = datamax =>
    datamax > 100
      ? Math.ceil(datamax / 100) * 100
      : Math.ceil(datamax / 10) * 10;

  // renders
  const renderTopFiveGraph = () => (
    <Graph
      data={topFive}
      barSeries={['value']}
      barLabel={[groupBy]}
      barColors="default"
      rightYAxisMax={rightYAxisMax}
      lineYAxis
      leftYAxisMax={100}
      lineSeries={['percentual']}
      lineLabel={['Percentual do todo']}
      lineColors="status"
      linePostFix="%"
      title="Top 5 Ofensores"
      loading={loading}
      tooltip
      legend
      onBarClick={data => handleChangeGraphFilter('topFive', data.name)}
      tabComponent={
        <div
          style={{
            display: 'flex',
            justifyContent: 'end',
          }}
        >
          <Tab
            width="200px"
            value={topFiveTab}
            items={valuesTopFive}
            onChange={(e, value) => {
              setTopFiveTab(value);
            }}
          />
        </div>
      }
    />
  );

  // renders
  const renderRiscosGraph = () => (
    <Graph
      data={riscos}
      barSeries={['value']}
      barLabel={[groupBy]}
      barColors="default"
      rightYAxisMax={rightYAxisMax}
      lineYAxis
      leftYAxisMax={100}
      lineSeries={['percentual']}
      lineLabel={['Percentual do todo']}
      lineColors="status"
      linePostFix="%"
      title="Riscos por Motorista e Veículo"
      loading={loading}
      tooltip
      legend
      onBarClick={data => handleChangeGraphFilter('riscos', data.name)}
      tabComponent={
        <div
          style={{
            display: 'flex',
            justifyContent: 'end',
          }}
        >
          <Tab
            width="230px"
            value={riscosTab}
            items={valuesRiscos}
            onChange={(e, value) => {
              setRiscosTab(value);
            }}
          />
        </div>
      }
    />
  );

  // renders
  const renderDaysOfWeekGraph = () => (
    <Graph
      data={daysOfWeek}
      barSeries={['value']}
      barLabel={[groupBy]}
      barColors="default"
      rightYAxisMax={rightYAxisMax}
      lineYAxis
      leftYAxisMax={100}
      lineSeries={['percentual']}
      lineLabel={['Percentual do todo']}
      lineColors="status"
      linePostFix="%"
      title="Riscos por Dias da semana"
      loading={loading}
      tooltip
      legend
      onBarClick={data => handleChangeGraphFilter('daysOfWeek', data.name)}
    />
  );

  // renders
  const renderPeriodsGraph = () => (
    <Graph
      data={periods}
      barSeries={['value']}
      barLabel={[groupBy]}
      barColors="default"
      rightYAxisMax={rightYAxisMax}
      lineYAxis
      leftYAxisMax={100}
      lineSeries={['percentual']}
      lineLabel={['Percentual do todo']}
      lineColors="status"
      linePostFix="%"
      title="Riscos por Período do dia"
      loading={loading}
      tooltip
      legend
      onBarClick={data => handleChangeGraphFilter('periods', data.name)}
    />
  );

  const renderMain = () => (
    <>
      <S.Main>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} xl={12}>
            {renderHeader()}
          </Grid>
          <Grid item xs={12} md={6} xl={6}>
            {renderTopFiveGraph()}
          </Grid>
          <Grid item xs={12} md={6} xl={6}>
            {renderRiscosGraph()}
          </Grid>
          <Grid item xs={12} md={6} xl={6}>
            {renderDaysOfWeekGraph()}
          </Grid>
          <Grid item xs={12} md={6} xl={6}>
            {renderPeriodsGraph()}
          </Grid>
        </Grid>
      </S.Main>

      <Filters
        id="simple-popover"
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        filter={filterRisco}
        setFilter={setFilterRisco}
      />
    </>
  );

  return renderMain();
};

export default FatoresRisco;
