import styled, { css } from 'styled-components';

export const Box = styled.div`
  ${({ theme }) => css`
    width: 100%;
    background-color: ${theme.palette.system.overlay};
    border: 2px solid ${theme.palette.system.border};
    border-radius: 4px;
    padding: 30px;
    margin-top: 20px;

    .userArea {
      display: flex;
      align-items: center;
      justify-content: start;
      margin-top: 35px;

      .imageArea {
        width: 50px;
        height: 50px;
        border: 2px solid ${theme.palette.brand.secondary.natural};
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 100%;
          border-radius: 200px;
          padding: 1px;
        }

        .letters {
          width: 42px;
          height: 42px;
          border-radius: 42px;
          background-color: ${theme.palette.semantics.feedback.unknown.light};
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 900;
          color: ${theme.palette.words.text.natural};
          font-size: 19px;
        }
      }

      .textArea {
        display: flex;
        flex-direction: column;
        margin-left: 20px;

        .userName {
          color: ${theme.palette.words.text.natural};
          font-weight: 700;
          font-size: 18px;
          text-transform: capitalize;

          &.error {
            color: ${theme.palette.semantics.feedback.attention.natural};
          }
        }
        .filialName {
          color: ${theme.palette.words.subtitle.natural};
          font-size: 14px;
        }
      }
    }

    .formArea {
      .counter {
        color: ${theme.palette.words.subtitle.natural};
        text-align: end;
        margin-top: -20px;
        margin-right: 8px;
        font-size: 12px;
        opacity: 0.8;
        font-weight: 600;
      }
    }
  `}
`;

export const StyledDistributorLogo = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.palette.brand.secondary.natural};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledLogo = styled.div`
  ${({ theme, ...props }) => css`
    height: 42px;
    width: 42px;
    border-radius: 50%;
    border: ${props.border};
    background-image: url(${props.backgroundImage});
    background-size: contain;
    background-color: ${theme.palette.semantics.feedback.unknown.light};
    display: flex;
    align-items: center;
    justify-content: center;

    h2 {
      font-size: 20px;
      color: ${theme.palette.brand.primary.natural};
      text-align: center;
    }
  `}
`;
