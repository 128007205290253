import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    background-color: ${theme.palette.system.overlay};
    border-radius: 4px;
    width: 100%;
    border: 1px solid ${theme.palette.system.divider};
    margin-top: 20px;
    height: 275px;

    .left {
      padding: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 49%;
    }
    .middle {
      border: 1px solid ${theme.palette.system.divider};
      height: 100%;
    }
    .right {
      padding: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 49%;
    }
    .info {
      padding: 15px;
    }
    .title {
      color: ${theme.palette.words.text.natural};
      font-weight: 900;
      margin-bottom: 10px;
    }
    .subtitle {
      color: ${theme.palette.system.scrollbar};
      margin-bottom: 10px;
    }
  `}
`;
