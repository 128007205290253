import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { useQuery } from 'react-query';

import { Icon, IconButton, Modal, Grid, Button } from '@mui/material';
import { EditOutlined } from '@mui/icons-material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { toast } from 'react-toastify';

import Input from 'components/Inputs/TextField';
import SelectInput from 'components/Inputs/Select';
import DefaultButton from 'components/Buttons/Default';
import GhostButton from 'components/Buttons/Ghost';
import ConfirmModal from 'components/ConfirmModal';
import * as S from './styled';

// Service
import { changeStatus, createTruck, updateTruck } from '../services';
import { getAllTecnologias, getDeviceStatus } from 'pages/Cadastro/services';
import { usePlans } from 'hooks/usePlans';

const ETenologiasTipo = Object.freeze({
  Monitoramento: 'Monitoramento',
  Jornada: 'Jornada',
  Fadiga: 'Fadiga',
  Telemetria: 'Telemetria',
});

const ModalAddFrota = ({ open, dataEdit, handleClose, setTriggerUpdate }) => {
  const [infoDevice, setInfoDevice] = useState(null);
  const theme = useTheme();
  const { hasFadiga, hasTelemetria, hasMonitoramento } = usePlans();

  const { filials, clients, cargas } = useSelector(state => {
    return state.selects;
  });

  const { data: tecnologias = [] } = useQuery(
    ['tecnologias'],
    () => getAllTecnologias(),
    {
      refetchOnWindowFocus: false,
    },
  );

  const [data, setData] = useState({
    placa: '',
    id_da_filial: '',
    modelo: '',
    cambio: '',
    departamento: '',
    propriedade: '',
    carregamento: '',
    id_da_carga: '',
    frota: '',
    id_da_distribuidora: '',
    empresa_terceira: '',
    dispositivo: '',
  });

  const fetchStatusDevice = async id => {
    const res = await getDeviceStatus(id);
    setInfoDevice(res);
  };
  // Carregas dados para editar
  // Dados originais ficam em dataEdit
  useEffect(() => {
    if (dataEdit && dataEdit.id) {
      fetchStatusDevice(dataEdit.id);
      setData({
        id: dataEdit.id || '',
        placa: dataEdit.placa || '',
        id_da_filial: dataEdit.id_da_filial || '',
        modelo: dataEdit.modelo || '',
        cambio: dataEdit.cambio || '',
        departamento: dataEdit.departamento || '',
        propriedade: dataEdit.propriedade || '',
        carregamento: dataEdit.carregamento || '',
        id_da_carga: dataEdit.id_da_carga || '',
        frota: dataEdit.frota || '',
        id_da_distribuidora: dataEdit.id_da_distribuidora || '',
        empresa_terceira: dataEdit.empresa_terceira || '',
        dispositivo: dataEdit.dispositivo || '',
        id_telemetria: dataEdit.id_telemetria || '',
        id_telemetria_camera: dataEdit.id_telemetria_camera || '',
        id_telemetria_fadiga: dataEdit.id_telemetria_fadiga || '',
      });
    }
  }, []);

  const [loading, setLoading] = useState(false);
  const [openConfirmSave, setOpenConfirmSave] = useState(false);
  const [openConfirmUpdate, setOpenConfirmUpdate] = useState(false);
  const [openConfirmChangeStatus, setOpenConfirmChangeStatus] = useState(false);

  // Tratamento dos inputs
  // Selects são são controlados por data =[...]
  // TextField é condicional
  const handleChange = e => {
    let { name, value } = e.target;
    if (name === 'placa') {
      value = value.replace(/\W|_/gi, '').toLocaleUpperCase().slice(0, 7);
    } else if (
      name === 'modelo' ||
      name === 'carregamento' ||
      name === 'frota' ||
      name === 'empresa_terceira'
    ) {
      value = value.slice(0, 30);
    } else if (name === 'dispositivo') {
      if (value.length > 42)
        toast.error('Esse campo tem o limite é 43 caracteres.');
      value = value.slice(0, 43);
    }
    setData({ ...data, [name]: value });
  };

  // Verifica se os campos estão preenchidos
  const validateInputs = () => {
    const notRequired = [
      'id_da_distribuidora',
      'frota',
      'empresa_terceira',
      'id_da_carga',
      'dispositivo',
      'id_telemetria',
      'id_telemetria_camera',
      'id_telemetria_fadiga',
    ];

    for (const item in data) {
      if (!notRequired.includes(item) && !data[item]) {
        toast.warning(`O campo ${item} é obrigatório`);
        const doc = document.getElementById(item);
        doc?.focus();
        return false;
      }
    }
    return true;
  };

  // Valida dados e abre janela de confirmação para add ou update
  const handleApply = async () => {
    const validated = validateInputs();
    if (!validated) return;
    if (dataEdit) setOpenConfirmUpdate(true);
    else setOpenConfirmSave(true);
  };

  // Finaliza adição
  // setTriggerUpdate(true) força atualização dos dados armazenados
  const handleAddVeiculo = async data => {
    setLoading(true);

    const res = await createTruck(data);
    if (res.data?.success) {
      toast.success(res.data.message);
      setTriggerUpdate(true);
      handleClose();
    } else if (res.data) {
      if (res.data.message) toast.error(res.data.message);
      if (res.data.errors) {
        toast.error(res.data.errors);
        res.data.errors.forEach(err => toast.error(err));
      }
    }

    setLoading(false);
    setOpenConfirmSave(false);
  };

  // Finaliza edição
  // setTriggerUpdate(true) força atualização dos dados armazenados
  const handleEditVeiculo = async data => {
    setLoading(true);

    const res = await updateTruck(data);
    if (res.data?.success) {
      toast.success(res.data.message);
      setTriggerUpdate(true);
      handleClose();
    } else if (res.data) {
      if (res.data.message) toast.error(res.data.message);
      if (res.data.errors) {
        toast.error(res.data.errors);
        res.data.errors.forEach(err => toast.error(err));
      }
    }

    setLoading(false);
    setOpenConfirmUpdate(false);
  };

  // Finaliza edição de status
  // setTriggerUpdate(true) força atualização dos dados armazenados
  const handleDesativarAtivar = async truckId => {
    setLoading(true);

    const res = await changeStatus(truckId);
    if (res.data?.success) {
      toast.success(res.data.message);
      setTriggerUpdate(true);
      handleClose();
    } else if (res.data?.message) toast.error(res.data.message);

    setLoading(false);
    setOpenConfirmChangeStatus(false);
  };

  const renderHelpIcon = () => {
    return (
      <S.CustumTooltip title="Código usado para identificar dispositivos ou sistemas de câmeras">
        <HelpOutlineIcon
          sx={{
            color: theme.palette.system,
            transform: 'translate(1px,3px)',
          }}
          fontSize="10"
        />
      </S.CustumTooltip>
    );
  };

  return (
    <>
      <Modal open={open} onClose={handleClose} disableEscapeKeyDown={false}>
        <S.Container>
          <S.Header>
            <div style={{ alignItems: 'center' }}>
              {dataEdit ? (
                <>
                  <EditOutlined
                    sx={{ color: theme.palette.brand.secondary.natural }}
                    fontSize="medium"
                  />
                  <h2>Editar Veículo</h2>
                </>
              ) : (
                <>
                  <Icon
                    sx={{ color: theme.palette.brand.secondary.natural }}
                    fontSize="medium"
                  >
                    add_circle_outline
                  </Icon>
                  <h2>Adicionar Novo Veículo</h2>
                </>
              )}
            </div>

            <IconButton size="small" onClick={handleClose}>
              <Icon sx={{ color: theme.palette.words.subtitle.natural }}>
                close
              </Icon>
            </IconButton>
          </S.Header>

          <S.Main>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <S.Section>INFORMAÇÕES PRINCIPAIS</S.Section>
              </Grid>

              <Grid item xs={6} sm={6}>
                <Input
                  required
                  label="Placa"
                  placeholder="Placa"
                  value={data.placa || ''}
                  onChange={handleChange}
                  name="placa"
                  id="placa"
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <SelectInput
                  required
                  handleChange={handleChange}
                  name="id_da_filial"
                  label="Filial"
                  data={filials.map(item => {
                    return { name: item.nome, value: item.id };
                  })}
                  value={data.id_da_filial}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <Input
                  required
                  label="Modelo"
                  placeholder="Modelo"
                  value={data.modelo || ''}
                  onChange={handleChange}
                  name="modelo"
                  id="modelo"
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <S.Section>STATUS DO DISPOSITIVO</S.Section>
              </Grid>

              {hasMonitoramento && (
                <Grid item xs={6} sm={6}>
                  <Input
                    label={
                      <span style={{ fontWeight: 'bold', fontSize: 14 }}>
                        CÂMERA:{' '}
                        <span style={{ fontWeight: 'normal' }}>
                          Último video disponível
                        </span>
                      </span>
                    }
                    placeholder="Modelo"
                    value={infoDevice?.camera || ' - '}
                    name="Câmera"
                    id="Câmera"
                    disabled
                  />
                </Grid>
              )}
              {hasTelemetria && (
                <Grid item xs={6} sm={6}>
                  <Input
                    label={
                      <span style={{ fontWeight: 'bold', fontSize: 14 }}>
                        TELEMETRIA:
                        <span style={{ fontWeight: 'normal' }}>
                          Último posição
                        </span>
                      </span>
                    }
                    placeholder=" - "
                    value={infoDevice?.telemetria || ' - '}
                    name="telemetria"
                    id="telemetria"
                    disabled
                  />
                </Grid>
              )}
              {hasFadiga && (
                <Grid item xs={6} sm={6}>
                  <Input
                    label={
                      <span style={{ fontWeight: 'bold', fontSize: 14 }}>
                        SENSOR FADIGA:
                        <span style={{ fontWeight: 'normal' }}>
                          Último evento
                        </span>
                      </span>
                    }
                    placeholder="Fadiga"
                    value={infoDevice?.fadiga || ' - '}
                    onChange={handleChange}
                    name="fadiga"
                    id="fadiga"
                    disabled
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={12}>
                <S.Section>INFORMAÇÕES COMPLEMENTARES</S.Section>
              </Grid>

              <Grid item xs={6} sm={6}>
                <SelectInput
                  required
                  handleChange={handleChange}
                  name="cambio"
                  label="Câmbio"
                  data={[
                    { name: 'Automático', value: 'AUTOMÁTICO' },
                    { name: 'Manual', value: 'MANUAL' },
                  ]}
                  value={data.cambio}
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <SelectInput
                  required
                  handleChange={handleChange}
                  name="departamento"
                  label="Departamento"
                  data={[
                    { name: 'Coleta', value: 'COLETA' },
                    { name: 'Entrega', value: 'ENTREGA' },
                    { name: 'Ambos', value: 'AMBOS' },
                  ]}
                  value={data.departamento}
                />
              </Grid>

              <Grid item xs={6} sm={6}>
                <SelectInput
                  required
                  handleChange={handleChange}
                  name="propriedade"
                  label="Propriedade"
                  data={[
                    { name: 'Agregado', value: 'AGREGADO' },
                    { name: 'Próprio', value: 'PRÓPRIO' },
                  ]}
                  value={data.propriedade}
                />
              </Grid>

              <Grid item xs={6} sm={6}>
                <SelectInput
                  handleChange={handleChange}
                  name="id_da_carga"
                  label="Carga"
                  data={cargas.map(item => {
                    return { name: item.nome, value: item.id };
                  })}
                  value={data.id_da_carga}
                />
              </Grid>

              {data.propriedade === 'AGREGADO' && (
                <Grid item xs={6} sm={6}>
                  <Input
                    label="Empresa Terceira"
                    placeholder="Empresa Terceira"
                    value={data.empresa_terceira || ''}
                    onChange={handleChange}
                    name="empresa_terceira"
                    id="empresa_terceira"
                  />
                </Grid>
              )}

              <Grid item xs={6} sm={6}>
                <Input
                  required
                  label="Carregamento"
                  placeholder="Carregamento"
                  value={data.carregamento || ''}
                  onChange={handleChange}
                  name="carregamento"
                  id="carregamento"
                />
              </Grid>

              <Grid item xs={6} sm={6}>
                <Input
                  label="ID Frota"
                  placeholder="Frota"
                  value={data.frota || ''}
                  onChange={handleChange}
                  name="frota"
                  id="frota"
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <Input
                  label="ID DVR"
                  placeholder="ID DVR"
                  value={data.dispositivo || ''}
                  onChange={handleChange}
                  labelIcon={renderHelpIcon()}
                  name="dispositivo"
                  id="dvr"
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <SelectInput
                  handleChange={handleChange}
                  name="id_da_distribuidora"
                  label="Cliente Exclusivo"
                  clearLabel="Limpar"
                  data={clients.map(item => {
                    return { name: item.nome, value: item.id };
                  })}
                  value={data.id_da_distribuidora}
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <SelectInput
                  handleChange={handleChange}
                  name="id_telemetria_camera"
                  label="Fornecedor Câmera"
                  clearLabel="Limpar"
                  data={tecnologias
                    .filter(
                      tecnologia =>
                        tecnologia.tipo === ETenologiasTipo.Monitoramento,
                    )
                    .map(item => {
                      return { ...item, value: item.id };
                    })}
                  value={data.id_telemetria_camera}
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <SelectInput
                  handleChange={handleChange}
                  name="id_telemetria"
                  label="Fornecedor Telemetria"
                  clearLabel="Limpar"
                  data={tecnologias
                    .filter(
                      tecnologia => tecnologia.tipo === ETenologiasTipo.Jornada,
                    )
                    .map(item => {
                      return { ...item, value: item.id };
                    })}
                  value={data.id_telemetria}
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <SelectInput
                  handleChange={handleChange}
                  name="id_telemetria_fadiga"
                  label="Fornecedor Câm. Fadiga"
                  clearLabel="Limpar"
                  data={tecnologias
                    .filter(
                      tecnologia => tecnologia.tipo === ETenologiasTipo.Fadiga,
                    )
                    .map(item => {
                      return { ...item, value: item.id };
                    })}
                  value={data.id_telemetria_fadiga}
                />
              </Grid>
            </Grid>
          </S.Main>

          <S.Footer>
            {dataEdit ? (
              <Button
                onClick={() => setOpenConfirmChangeStatus(true)}
                size="medium"
                variant="contained"
                style={{
                  color:
                    dataEdit.status === 'ATIVO'
                      ? theme.palette.semantics.feedback.attention.natural
                      : theme.palette.brand.secondary.natural,
                  background: theme.palette.system.overlay,
                  border:
                    dataEdit.status === 'ATIVO'
                      ? `1px solid ${theme.palette.semantics.feedback.attention.natural}`
                      : `1px solid ${theme.palette.brand.secondary.natural}`,
                  font: 'normal normal 900 14px/18px Texta',
                  height: '40px',
                }}
              >
                {dataEdit.status === 'ATIVO'
                  ? 'DESATIVAR VEÍCULO'
                  : 'REATIVAR VEÍCULO'}
              </Button>
            ) : (
              <div />
            )}
            <div>
              <GhostButton onClick={handleClose} size="medium">
                CANCELAR
              </GhostButton>

              <DefaultButton
                onClick={handleApply}
                size="medium"
                sx={{ marginLeft: '20px' }}
              >
                SALVAR
              </DefaultButton>
            </div>
          </S.Footer>
        </S.Container>
      </Modal>

      {/* Confirmação de adição */}
      <ConfirmModal
        open={openConfirmSave}
        handleClose={() => setOpenConfirmSave(false)}
        title="Deseja adicionar veículo?"
        titleIcon={
          <Icon
            sx={{ color: theme.palette.brand.secondary.natural }}
            fontSize="medium"
          >
            add_circle_outline
          </Icon>
        }
        subtitle="Atenção! Verifique todos os campos antes de confirmar a operação."
        buttonText="Confirmar"
        onClick={() => handleAddVeiculo(data)}
        loading={loading}
      />

      {/* Confirmação de edição */}
      <ConfirmModal
        open={openConfirmUpdate}
        handleClose={() => setOpenConfirmUpdate(false)}
        title="Deseja editar veículo?"
        titleIcon={
          <EditOutlined
            sx={{ color: theme.palette.brand.secondary.natural }}
            fontSize="medium"
          />
        }
        subtitle="Atenção! Verifique todos os campos antes de confirmar a operação."
        buttonText="Confirmar"
        onClick={() => handleEditVeiculo(data)}
        loading={loading}
      />

      {
        /* Confirmação de exclusão / reativação */
        dataEdit && (
          <ConfirmModal
            open={openConfirmChangeStatus}
            handleClose={() => setOpenConfirmChangeStatus(false)}
            title={
              dataEdit.status === 'ATIVO'
                ? 'Deseja desativar este veículo?'
                : 'Deseja reativar este veículo?'
            }
            titleIcon={
              <EditOutlined
                sx={{ color: theme.palette.brand.secondary.natural }}
                fontSize="medium"
              />
            }
            subtitle="Veículos inativos não geram avaliações e não são contabilizados na disponibilidade!"
            buttonText="Confirmar"
            onClick={() => handleDesativarAtivar(dataEdit.id)}
            loading={loading}
          />
        )
      }
    </>
  );
};

export default ModalAddFrota;
