import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';

// components custom
import { PeriodPicker } from 'pages/Estatisticas/Components/PeriodPicker';
import Tabs from 'components/Tabs';
// styles
import * as S from './styled';

const CustomTick = props => {
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="middle"
        fill="#95AAC9"
        transform="rotate(0)"
      >
        <tspan x={0} dy="20">
          {payload.value}
        </tspan>
      </text>
    </g>
  );
};

export const HistoryDrivers = ({
  tab = 'ptsHr',
  data = [],
  dates,
  handleDate,
  handleChangeTab = () => false,
  labelsTab = [],
}) => {
  const handleChange = value => {
    handleChangeTab(value);
  };

  return (
    <S.Container>
      <div className="container-filters">
        <div className="titletop">
          <div className="graphtitle">Histórico de pontos</div>
          <PeriodPicker
            finalDate={dates.finalDate}
            initialDate={dates.initialDate}
            period="lastYear"
            periodAdd="month"
            onChange={date => {
              handleDate(date);
            }}
          />
        </div>
        <div>
          <Tabs
            value={tab}
            onChange={(e, value) => handleChange(value)}
            items={labelsTab}
          />
        </div>
      </div>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          height={300}
          data={data}
          margin={{ top: 10, right: 40, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="date"
            height={70}
            tick={<CustomTick />}
            interval={0}
          />
          <YAxis />
          <Tooltip />

          <Line
            type="linear"
            dataKey="value_company"
            name="Empresa"
            stroke="#F64E60"
            dot={props => {
              const { index, payload } = props;
              const isLastDot = index === data.length - 1;
              return (
                <circle
                  cx={props.cx}
                  cy={props.cy}
                  r={5}
                  fill={isLastDot ? 'none' : '#F64E60'} // Se for o último ponto, não preenche
                  stroke={isLastDot ? '#F64E60' : 'none'} // Se for o último ponto, define a cor da borda
                  strokeWidth={isLastDot ? 2 : 0} // Se for o último ponto, define a largura da borda
                />
              );
            }}
          />

          <Line
            type="linear"
            dataKey="value_driver"
            name="Valor motorista"
            stroke="#656E8C"
            dot={props => {
              const { index, payload } = props;
              const isLastDot = index === data.length - 1; // Verifica se é o último ponto
              return (
                <circle
                  cx={props.cx}
                  cy={props.cy}
                  r={5}
                  fill={isLastDot ? 'none' : '#656E8C'}
                  stroke={isLastDot ? '#656E8C' : 'none'}
                  strokeWidth={isLastDot ? 2 : 0}
                />
              );
            }}
          />
        </LineChart>
      </ResponsiveContainer>
      <div className="legend-container">
        <div className="legend">
          <div className="format-point">
            <div className="point1" />
            Empresa
          </div>
          <div className="format-point">
            <div className="point2" />
            Motorista
          </div>
        </div>
      </div>
    </S.Container>
  );
};
