import * as S from './styled';
import { useTable } from '../../../context';
import DefaultButton from 'components/Buttons/Default';

export const Empty = ({
  title = 'Nenhum item encontrado',
  subtitle = 'Verifique os filtros aplicados',
  image = 'frota.png',
  action = null,
  fixedItems,
}) => {
  const { state } = useTable();
  const search = state.search;

  const limit = (text, limit = 10) => {
    return text?.length > limit ? `${text?.substring(0, limit)}...` : text;
  };

  const emptyTitle = search
    ? `Nenhum resultado para "${limit(search)}"`
    : title;
  const emptySubtitle = search ? 'Verifique os termos da sua busca' : subtitle;

  const img = require(`images/empty/${image}`);

  return (
    <S.Container height={fixedItems * 40}>
      <S.ImageContainer background={img} />
      <S.TextContainer>
        <S.Title>{emptyTitle}</S.Title>
        <S.Subtitle>{emptySubtitle}</S.Subtitle>
        {!search && action && (
          <DefaultButton onClick={action.function} icon={action.icon}>
            {action.title}
          </DefaultButton>
        )}
      </S.TextContainer>
    </S.Container>
  );
};
