import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
`;

export const Box = styled.div`
  ${({ theme, ...props }) => css`
    &.stepBox {
      display: flex;
      align-items: center;

      &.flex {
        flex: 1;
      }

      .infoArea {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .number {
          background-color: ${theme.palette.system.overlay};
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 15px;
          font-weight: ${props.active ? 800 : 600};
          width: 30px;
          height: 30px;
          border-radius: 20px;
          padding: 5px 10px;
          color: ${props.active
            ? theme.palette.semantics.feedback.success.natural
            : theme.palette.words.title.natural};
          border: 2px solid
            ${props.active
              ? theme.palette.semantics.feedback.success.natural
              : theme.palette.system.border};
          transition: all 0.9s;
        }

        .filled {
          border: none;
          padding: 0px;
        }

        .title {
          font-size: 14px;
          color: ${theme.palette.words.title.natural};
          font-weight: bold;
          margin-top: 5px;
        }
      }

      .stepBar {
        width: 100%;
        height: 20px;
        margin-top: -5px;
        margin-left: -38px;
        position: relative;

        .out {
          width: calc(100% + 22px);
          height: 3px;
          background-color: ${theme.palette.semantics.feedback.unknown.light};
          position: absolute;
          z-index: -2;
        }

        .in {
          width: ${props => (props.passed ? 'calc(100% + 22px)' : 0)};
          height: 3px;
          background-color: ${theme.palette.semantics.feedback.success.natural};
          position: absolute;
          transition: width 0.5s;
          z-index: -1;
        }
      }
    }
  `}
`;
