import React, { useState } from 'react';
import { BarChart, Bar, ResponsiveContainer, XAxis, LabelList } from 'recharts';
import { CustomizedSelect as SectionPeriod } from 'components/SectionPeriod';
import Skeleton from '@mui/material/Skeleton';
import * as S from './styled';

const BarChartHorizontal = ({
  title = '',
  data = [],
  handleDate = () => {},
  isLoading = false,
}) => {
  const handleChangeDate = period => {
    handleDate({
      initialDate: period,
      finalDate: new Date(),
    });
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 25,
      }}
    >
      <div style={{ width: '30%' }}>
        <div style={{ position: 'relative', top: -70, right: 20 }}>
          <SectionPeriod
            handleChangeDate={handleChangeDate}
            today={new Date()}
            width={180}
          />
        </div>

        <S.Title> {title}</S.Title>
      </div>
      {!isLoading ? (
        <ResponsiveContainer width="100%" height={200}>
          <BarChart data={data} margin={{ top: 60, right: 10, left: 10 }}>
            <XAxis dataKey="day" />
            <Bar barSize={20} dataKey="quantidade" fill="#95AAC9" radius={2}>
              <LabelList
                dataKey="porcentagem"
                position="top"
                offset={10}
                fill="#95AAC9"
                fontSize={11}
              />
              <LabelList
                dataKey="quantidade"
                position="top"
                offset={25}
                fill="#4B5166"
                fontSize={14}
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
          }}
        >
          {[1, 2, 3, 4, 5, 6, 7].map((_, k) => (
            <Skeleton
              key={k}
              height={Math.floor(Math.random() * (100 - 20 + 1)) + 20}
              width={20}
              style={{ marginRight: 15, transform: 'translateY(30px)' }}
            />
          ))}
        </div>
      )}
    </div>
  );
};
export default BarChartHorizontal;
