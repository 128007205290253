// Styles

// React
import React from 'react';

// Components
import SelectInput from 'components/Inputs/Select';

// Components MUI
import List from '@mui/material/List';
import { Popover } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import ListItemText from '@mui/material/ListItemText';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ListItemButton from '@mui/material/ListItemButton';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import * as S from './styled';

// Utils
import { getTiposVeiculos, getTiposPadrao } from '../../services';

const Filters = ({ id, open, onClose, anchorEl, filters, setFilters }) => {
  const [openCollapseTipo, setOpenCollapseTipo] = React.useState(false);
  const [openCollapseCliente, setOpenCollapseCliente] = React.useState(false);
  const [openCollapseDesvio, setOpenCollapseDesvio] = React.useState(false);

  const selects = useSelector(state => {
    return state.selects;
  });
  const { user } = useSelector(state => state.auth);
  const isProvider = user?.user?.provider;

  const { data: _tiposVeiculos = [] } = useQuery(
    ['tipos-veiculos-telemetria'],
    () => getTiposVeiculos(),
    { staleTime: Infinity },
  );

  const tiposVeiculos = _tiposVeiculos.map(tipo => ({
    name: tipo.tipo,
    value: tipo.id,
  }));

  const { data: _tiposDesvios = [] } = useQuery(
    ['desvios-padrao-telemetria', 'DESVIO', 'JORNADA'],
    () => getTiposPadrao({ categoria: ['DESVIO', 'JORNADA'] }),
    { staleTime: Infinity },
  );

  const tiposDesvios = _tiposDesvios
    .filter(tipo => tipo.id != 1 && tipo.id != 2)
    .map(tipo => ({
      name: tipo.tipo,
      value: tipo.id,
    }));

  const clientes =
    selects.clients?.map(i => {
      return { name: i.nome, value: i.id };
    }) || [];

  const handleChange = (e, b) => {
    setFilters(_filters => ({
      ..._filters,
      [e.target.name]: e.target.value,
    }));
  };

  const clearFilters = () => {
    setFilters(_filters => {
      const newFilters = {};
      Object.keys(_filters).forEach(key => {
        newFilters[key] = '';
      });
      return newFilters;
    });
    onClose();
  };

  return (
    <div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <S.Main>
          <S.Header>
            <h2>Filtros</h2>
            <S.ClearButton onClick={clearFilters}>Limpar filtros</S.ClearButton>
          </S.Header>

          <S.SelectsContainer>
            <List>
              {Object.keys(filters).includes('cliente') && !isProvider && (
                <>
                  <ListItemButton
                    onClick={() => {
                      setOpenCollapseCliente(!openCollapseCliente);
                      setOpenCollapseTipo(false);
                      setOpenCollapseDesvio(false);
                    }}
                  >
                    {openCollapseCliente ? <ExpandLess /> : <ExpandMore />}
                    <ListItemText primary="Filtrar por Cliente" />
                  </ListItemButton>
                  <Collapse
                    in={openCollapseCliente}
                    timeout="auto"
                    unmountOnExit
                  >
                    <S.CollapseContent>
                      <SelectInput
                        value={filters?.cliente}
                        handleChange={handleChange}
                        name="cliente"
                        label="Cliente"
                        data={[{ value: '', name: 'Todos' }, ...clientes]}
                      />
                    </S.CollapseContent>
                  </Collapse>
                </>
              )}

              {Object.keys(filters).includes('tipoVeiculo') && (
                <>
                  <ListItemButton
                    onClick={() => {
                      setOpenCollapseTipo(!openCollapseTipo);
                      setOpenCollapseCliente(false);
                      setOpenCollapseDesvio(false);
                    }}
                  >
                    {openCollapseTipo ? <ExpandLess /> : <ExpandMore />}
                    <ListItemText primary="Filtrar por Veículo" />
                  </ListItemButton>
                  <Collapse in={openCollapseTipo} timeout="auto" unmountOnExit>
                    <S.CollapseContent>
                      <SelectInput
                        value={filters?.tipoVeiculo}
                        handleChange={handleChange}
                        name="tipoVeiculo"
                        label="Tipo de veículo"
                        placeholder="Filtrar por veículo"
                        data={[{ value: '', name: 'Todos' }, ...tiposVeiculos]}
                      />
                    </S.CollapseContent>
                  </Collapse>
                </>
              )}
              {Object.keys(filters).includes('tipoDesvio') && (
                <>
                  <ListItemButton
                    onClick={() => {
                      setOpenCollapseTipo(false);
                      setOpenCollapseCliente(false);
                      setOpenCollapseDesvio(!openCollapseDesvio);
                    }}
                  >
                    {openCollapseDesvio ? <ExpandLess /> : <ExpandMore />}
                    <ListItemText primary="Filtrar por Desvio" />
                  </ListItemButton>
                  <Collapse
                    in={openCollapseDesvio}
                    timeout="auto"
                    unmountOnExit
                  >
                    <S.CollapseContent>
                      <SelectInput
                        value={filters?.tipoDesvio}
                        handleChange={handleChange}
                        name="tipoDesvio"
                        label="Tipo de desvio"
                        placeholder="Filtrar por desvio"
                        data={[{ value: '', name: 'Todos' }, ...tiposDesvios]}
                      />
                    </S.CollapseContent>
                  </Collapse>
                </>
              )}
            </List>
          </S.SelectsContainer>
        </S.Main>
      </Popover>
    </div>
  );
};

export default Filters;
