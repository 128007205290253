import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { startOfMonth, subMonths } from 'date-fns';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';

import { DefaultTable } from 'components/_Table/templates/default';

import { getAcidentes } from '../services';
import { usePlans } from 'hooks/usePlans';
import { trackEvent } from 'utils/mixpanel';
import { columns } from './constants';

export const Acidentes = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { isProvider } = usePlans();
  const [query, setQuery] = useState(null);
  const [resetTable, setResetTable] = useState(false);
  const [filter, setFilter] = useState({
    finalDate: new Date(),
    initialDate: startOfMonth(subMonths(new Date(), 11)),
  });

  const user = useSelector(state => {
    return state.auth?.user?.user;
  });

  const { isLoading, data: resData } = useQuery(
    ['acidentes', params?.id, query],
    () => query && getAcidentes(params?.id, query),
    {
      refetchOnWindowFocus: false,
      onSuccess: () => {
        resetTable && setResetTable(false);
      },
    },
  );

  const initial = useRef(true);
  useEffect(() => {
    if (initial.current) {
      initial.current = false;
      return;
    }

    setResetTable(true);
  }, []);

  const handleOpenDetails = id => {
    const url = `/acidentes/${id}`;
    navigate(url);
  };

  const actions = [
    {
      title: 'Ver detalhe',
      function: handleOpenDetails,
    },
    {
      title: 'Editar',
      function: id => navigate(`/acidentes/editar/${id}`),
      visible: item =>
        (isProvider && item.provider) || (!isProvider && !item.provider),
    },
  ];

  return (
    <DefaultTable
      data={resData?.data || []}
      columns={columns}
      loading={isLoading}
      pageCount={resData?.count || 0}
      local={false}
      actions={actions}
      reset={resetTable}
      loadingSelection={[]}
      onClickRow={handleOpenDetails}
      setQuery={q => setQuery({ ...q, ...filter })}
      searchEvent={search => trackEvent(user, 'Buscar Acidente', null, search)}
      sortBy={{ id: 'data_acidente', order: 'DESC' }}
      placeholder="Busca por motorista, tipo e local"
      empty={{
        title: 'Ops! Você não tem nenhum acidente disponível.',
        description: 'Verifique os filtros aplicados!',
      }}
    />
  );
};
