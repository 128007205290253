import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    width: 100vw;
    height: 100%;
    min-height: 100vh;
    padding-top: 6rem;
    overflow-x: hidden;
    background: ${theme.palette.brand.primary.background};
    z-index: 0;
  `}
`;
