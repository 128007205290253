import OfflineBoltOutlinedIcon from '@mui/icons-material/OfflineBoltOutlined'; // Quiz
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'; // Teste
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined'; // Texto
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined'; // Imagem
import PlayLessonOutlinedIcon from '@mui/icons-material/PlayLessonOutlined'; // Pdf
import SlideshowOutlinedIcon from '@mui/icons-material/SlideshowOutlined'; // Video
import BackupOutlinedIcon from '@mui/icons-material/BackupOutlined'; // Video Upload

export const formatModule = type => {
  switch (type) {
    case 'VÍDEO_UPLOADING':
      return {
        Icon: params => <BackupOutlinedIcon {...params} />,
        label: 'Vídeo',
      };
    case 'VÍDEO':
      return {
        Icon: params => <SlideshowOutlinedIcon {...params} />,
        label: 'Vídeo',
      };
    case 'PDF':
      return {
        Icon: params => <PlayLessonOutlinedIcon {...params} />,
        label: 'PDF',
      };
    case 'TEXTO':
      return {
        Icon: params => <ArticleOutlinedIcon {...params} />,
        label: 'Texto',
      };
    case 'IMAGEM':
      return {
        Icon: params => <ImageOutlinedIcon {...params} />,
        label: 'Imagem',
      };
  }
};

export const formatEval = type => {
  if (type) {
    return {
      Icon: params => <OfflineBoltOutlinedIcon {...params} />,
      label: 'Quiz',
    };
  }

  return {
    Icon: params => <CheckCircleOutlineOutlinedIcon {...params} />,
    label: 'Teste Online',
  };
};
