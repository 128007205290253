import React from 'react';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

import * as S from './styled';
import { ReactComponent as IconAcoes } from 'images/icons/cards/acoes-suspensoes.svg';

const ListItem = ({ active, title, description, color }) => {
  return (
    <S.ListItem active={active}>
      <div className="flex">
        <div className="icon">
          <ArrowRightIcon />
        </div>
        <div style={{ width: '100%' }}>
          Faixa
          <div className="flexcenter">
            <div className="title textnowrap" title={title} style={{ color }}>
              {title}
            </div>
            <div className="textnowrap">{description}</div>
          </div>
        </div>
      </div>
    </S.ListItem>
  );
};

export const Faixas = ({
  faixas = [],
  idFaixaAtual = null,
  pontos = 0,
  title = '',
}) => {
  return (
    <S.Container>
      <S.Header>
        <S.Title>
          <S.IconContainer>
            <IconAcoes />
          </S.IconContainer>
          <span>{pontos} ptos.</span>
        </S.Title>
        <span>{title}</span>
      </S.Header>
      <S.ListContainer>
        {faixas.map(item => (
          <ListItem
            key={item.id}
            title={item.titulo}
            description={
              item.max
                ? `${item.min} a ${item.max} pontos`
                : `Acima de ${item.min}`
            }
            color={item.cor || '#0C12F2'}
            active={item.id === idFaixaAtual}
          />
        ))}
      </S.ListContainer>
    </S.Container>
  );
};
