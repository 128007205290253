/* eslint-disable import/order */

// Style
import * as S from './styled';
import { useTheme } from 'styled-components';

// React
import { useQuery } from 'react-query';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeHeaderStatus } from 'store/modules/header/actions';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  setConteudo,
  initializeData,
} from 'store/modules/provider/capacitacaoConteudo/actions';

// Components
import Stepper from 'components/Stepper';
import Loading from 'components/Loading';
import { Sidebar } from '../components/Sidebar';
import GhostButton from 'components/Buttons/Ghost';
import ConfirmModal from 'components/ConfirmModal';
import DefaultButton from 'components/Buttons/Default';
import PreviewModal from '../components/ModalPreview';

// Components MUI
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import DesktopWindowsOutlinedIcon from '@mui/icons-material/DesktopWindowsOutlined';

// Utils
import { steps } from './constants';
import * as services from './services';

// Timer
let timerRoute = null;

const DetalhesConteudoCapacitacao = () => {
  const theme = useTheme();
  // -- Hooks
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  // --Controll
  const [skip, setSkip] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [changed, setChanged] = useState(false);
  const [trySave, setTrySave] = useState(false);
  const [finalMode, setFinalMode] = useState(false);
  const [ocultSkip, setOcultSkip] = useState(false);
  const [isPublished, setIsPublished] = useState(false);
  const [currentStep, setCurrentStep] = useState(steps[0]);
  const [review, setReview] = useState([]);

  // --Modal
  const [openPreview, setOpenPreview] = useState(false);
  const [openModalAddAluno, setOpenModalAddAluno] = useState(false);

  // --Contents
  const [tags, setTags] = useState([]);
  const [allTurmas, setAllTurmas] = useState([]);

  // -------------------------------INITIAL REQUEST--------------------------------//
  const {
    id,
    geral,
    avaliacao: evalCont,
  } = useSelector(state => state.conteudoProvider);
  const searchParams = new URLSearchParams(location.search);
  const conteudo_search = Number(searchParams.get('conteudo'));
  const mode = searchParams.get('mode');
  const qsStep = Number(searchParams.get('step'));

  const { isFetching: loadingQuery, refetch } = useQuery(
    [`conteudo${params.id || conteudo_search}`],
    () =>
      (location.pathname.includes('editar') || conteudo_search) &&
      services.GetConteudo(params.id || conteudo_search),
    {
      refetchOnWindowFocus: false,
      onSuccess: data => initializeContent(data),
      onError: data => toast.error(data.message),
    },
  );

  const initializeContent = async data => {
    const isTemplate = mode === 'template';
    if (!data && !isTemplate) dispatch(setConteudo());

    const res = await services.GetTags();
    if (res.success) {
      setTags(res.data);
    } else {
      toast.error(res.message);
    }

    const turmas = await services.GetPublic();
    if (turmas.success) {
      setAllTurmas(turmas.data);
    } else {
      toast.error(turmas.message);
    }

    if (isTemplate) {
      setChanged(true);
      setOcultSkip(!!evalCont?.length);
    }

    if (!data) {
      setLoading(false);
      return;
    }

    const cont = data?.data?.content;

    const id = cont.id;

    if (cont.status === 'Rascunho' || cont.status === 'Agendado')
      setIsPublished(false);
    else setIsPublished(true);

    const geral = {
      titulo: cont.titulo,
      descricao: cont.descricao,
      capa: cont.imagem_capa,
      data_inicio: cont.data_inicio,
      data_fim: cont.data_fim,
      tags: cont.tags,
      desvios_tipos_padrao: cont.desvios_tipos_padrao,
      status: cont.status,
    };

    const midias = cont.midias.map(m => {
      return {
        id: m.id,
        titulo: m.titulo,
        descricao: m.descricao,
        link: m.link,
        draggableId: m.ordem,
        ordem: m.ordem,
        tipo: m.tipo,
      };
    });

    const avaliacao = cont.perguntas;
    setOcultSkip(!!avaliacao?.length);
    const publico = cont.turmas;

    const configuracoes = {
      coeficiente_minimo: cont.coeficiente_minimo,
      comentarios_habilitados: cont.comentarios_habilitados,
      tentativas: cont.tentativas,
      obrigatorio: cont.obrigatorio,
      gera_certificado: cont.gera_certificado,
      vencimento_certificado: cont.vencimento_certificado,
      habilitar_template: cont.habilitar_template,
    };

    setReview(cont.review);

    dispatch(
      initializeData({ id, geral, midias, avaliacao, publico, configuracoes }),
    );

    setLoading(false);

    if (isEdit && cont.status === 'Rascunho') {
      navigate(`/capacitacao/criar?conteudo=${id}`, { replace: true });
    }

    if (!isEdit && cont.status !== 'Rascunho') {
      navigate(`/capacitacao/editar/${id}`, { replace: true });
    }
  };

  // -------------------------------CONTROLLER INITIAL--------------------------------//

  useEffect(() => {
    const { pathname } = location;
    if (pathname.includes('criar')) {
      setIsEdit(false);
    } else if (pathname.includes('editar')) {
      setIsEdit(true);
      if (+qsStep <= 6 && +qsStep >= 0) {
        setCurrentStep(steps[qsStep]);
      }
    }
  }, [location]);

  useEffect(() => {
    if (isEdit) {
      window.history.replaceState({}, {}, `/capacitacao/editar/${id}`);
    }
  }, [isEdit, id]);

  // Controll back arrow in window when modal is opened in same tab (BETA VERSION)
  useEffect(() => {
    timerRoute = setTimeout(() => {
      if (!openPreview) {
        window.onpopstate = event => {
          event.preventDefault();
        };
      }
    }, 100);

    return function cleanup() {
      clearTimeout(timerRoute);
    };
  }, [openPreview]);

  // --------------------CONTROLLER PAGE (Functions)--------------------------------//
  const handleBackPage = () => {
    if (currentStep.step !== 1) {
      setCurrentStep(steps[currentStep.value - 1]);
      setChanged(false);
    } else {
      navigate(-1, { replace: true });
    }
  };

  const handleNext = () => {
    if (currentStep.step < steps.length) {
      setCurrentStep(steps[currentStep.value + 1]);
      setChanged(false);
      setSkip(false);
    }
  };

  // -------------------------CONTROLLER FOOTER BUTTONS----------------------------//
  const saveButton = () => {
    let valid = false;
    if (changed) {
      valid = true;
    }

    if (valid) {
      return (
        <DefaultButton onClick={() => handleSave()} loading={loading}>
          {isEdit ? 'Salvar' : 'Salvar e Avançar'}
        </DefaultButton>
      );
    }
  };

  const nextButton = () => {
    let valid = false;

    if (!changed && currentStep.step !== 6) {
      valid = true;
    }

    if (valid) {
      return (
        <DefaultButton onClick={() => handleNext()} /* disabled={!isEdit} */>
          Avançar
        </DefaultButton>
      );
    }
  };

  const skipStep = () => {
    if (currentStep.step == 3 && !ocultSkip && !isEdit) {
      return (
        <GhostButton
          onClick={!changed ? () => handleNext() : () => setSkip(true)}
          style={{
            marginRight: '20px',
          }}
        >
          Pular etapa
        </GhostButton>
      );
    }
  };

  // -------------------------------DEFINE COMPONENT--------------------------------//
  const changePage = () => {
    const { Component } = currentStep;
    return (
      <Component
        setChanged={setChanged}
        trySave={trySave}
        setTrySave={setTrySave}
        setCurrentStep={setCurrentStep}
        isEdit={isEdit}
        tags={tags}
        isPublished={isPublished}
        handleSaveMedia={() => {}}
        openModalAddAluno={openModalAddAluno}
        setOpenModalAddAluno={setOpenModalAddAluno}
        allTurmas={allTurmas}
        finalMode={finalMode}
        review={review}
      />
    );
  };

  const handleSave = status => {
    // This state is used for start a request in sub components
    // They have a useEffect to verify true or false and dispatch request
    setFinalMode(status);
    setTrySave(true);
  };

  // -----------------------------------STATUS STYLE-----------------------------//
  useEffect(() => {
    const status = geral?.status;

    switch (status) {
      case 'Ativo':
        dispatch(
          changeHeaderStatus({
            status,
            color: theme.palette.brand.secondary.natural,
            background: theme.palette.brand.secondary.background,
          }),
        );
        break;
      case 'Agendado':
        dispatch(
          changeHeaderStatus({
            status,
            color: theme.palette.semantics.feedback.information.natural,
            background: theme.palette.semantics.feedback.information.light,
          }),
        );
        break;
      case 'Finalizado':
        dispatch(
          changeHeaderStatus({
            status,
            color: theme.palette.semantics.feedback.unknown.natural,
            background: theme.palette.brand.primary.background,
          }),
        );
        break;
      default:
        return {};
    }
  }, [id]);

  // -------------------------------RETURN COMPONENT--------------------------------//

  return (
    <S.Container>
      {!loading && (
        <>
          {isEdit && (
            <Sidebar
              page={currentStep?.step - 1 || 0}
              setPage={setCurrentStep}
              setChanged={setChanged}
            />
          )}
          <S.TemplatePage className={isEdit ? 'isEditTemplate' : ''}>
            {!isEdit && (
              <Stepper
                steps={steps.slice(0, 6)}
                currentStep={currentStep.step}
              />
            )}

            <S.HeaderPage className={isEdit ? 'isEditHeader' : ''}>
              <div className="titlePage">
                {!isEdit ? currentStep.pageTitle : currentStep.page}
              </div>
              <div className="headerButtons">
                {currentStep.step === 6 && (
                  <S.ButtonPrev onClick={() => setOpenPreview(true)}>
                    <span>Visualizar página</span>
                    <DesktopWindowsOutlinedIcon />
                  </S.ButtonPrev>
                )}

                {currentStep.step === 4 && (
                  <DefaultButton
                    children="Adicionar Turma"
                    startIcon={<AddCircleOutlineIcon />}
                    style={{ marginRight: '10px' }}
                    onClick={() => setOpenModalAddAluno(true)}
                  />
                )}
              </div>
            </S.HeaderPage>

            <div style={{ marginBottom: '60px' }}>
              {!loading && !loadingQuery && changePage()}
            </div>

            {currentStep.step <= 6 && (
              <S.ControllerArea>
                <div className="statusArea">
                  {changed && 'Alterações não salvas'}
                  {!changed && 'Informações Atualizadas'}
                </div>

                <div className="buttonsArea">
                  {skipStep()}

                  <GhostButton
                    className="backArea"
                    onClick={() => handleBackPage()}
                  >
                    Voltar
                  </GhostButton>

                  {saveButton()}
                  {nextButton()}

                  {currentStep.step == 6 && !isEdit && (
                    <>
                      <DefaultButton onClick={() => handleSave('draft')}>
                        Salvar como rascunho
                      </DefaultButton>
                      <DefaultButton
                        onClick={() => handleSave('public')}
                        style={{ marginLeft: '10px' }}
                      >
                        Publicar
                      </DefaultButton>
                    </>
                  )}
                </div>
              </S.ControllerArea>
            )}
          </S.TemplatePage>

          {openPreview && (
            <PreviewModal
              open={openPreview}
              onClose={() => setOpenPreview(false)}
              pathname={isEdit}
              backPath={
                isEdit
                  ? `/capacitacao/editar/${id}`
                  : `/capacitacao/criar?conteudo=${id}`
              }
            />
          )}
        </>
      )}

      {(loading || loadingQuery) && (
        <S.LoadingBox>
          <Loading />
        </S.LoadingBox>
      )}

      <ConfirmModal
        handleClose={() => setSkip(false)}
        open={skip}
        title="Tem certeza que deseja pular essa etapa?"
        subtitle="Todas as informações não salvas serão perdidas."
        titleIcon={
          <WarningAmberRoundedIcon
            htmlColor={theme.palette.semantics.feedback.attention.natural}
          />
        }
        buttonText="Confirmar"
        onClick={() => handleNext()}
      />
    </S.Container>
  );
};

export default DetalhesConteudoCapacitacao;
