import { Modal } from '@mui/material';
import styled from 'styled-components';
import colors from 'styles/colors';

export const ModalMui = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 600px;
  height: 90%;
  max-height: 560px;
  background-color: ${colors.white};
  border-radius: 5px;
  overflow: hidden;

  .slider {
    display: flex;
    width: 200%;
    height: 100%;
    overflow: hidden;
    margin-left: ${props => (props.success ? '-100%' : '0%')};
    transition: margin-left 0.3s ease-in-out;

    .step1 {
      padding: 25px;
      width: 50%;
      display: flex;
      flex-direction: column;
    }
    .step2 {
      width: 50%;
      display: flex;
      flex-direction: column;

      .header,
      .footer {
        padding: 25px;
      }
    }
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  h2 {
    margin-left: 15px;
  }

  p {
    font-size: 14px;
    color: ${colors.greySubtitle};
    font-weight: 500;
    margin-left: 15px;
  }
`;

export const Center = styled.div`
  height: 70%;
  max-height: 250px;
  width: 100%;
  margin-top: 20px;
  border: 3px dashed
    ${props => (props.isError ? colors.redDanger : colors.greyTiny)};
  background-color: ${props =>
    props.isError ? colors.redBackground : colors.white};
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

export const Description = styled.div`
  margin: 20px 0px;
  font-weight: 500;
  color: ${colors.greySubtitle};
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 10px;
  margin-top: 30px;

  &.footer {
    justify-content: flex-end;
  }

  input {
    display: none;
  }
`;

export const Erros = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  padding: 20px;
  overflow-y: auto;

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;

    h3 {
      margin-left: 10px;
      font-weight: 900;
      color: ${colors.greyTitle};
      font-size: 18px;
    }
  }

  ul {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    li {
      margin-bottom: 10px;
      font-size: 16px;
      color: ${colors.greySubtitle};
      display: flex;
      align-items: center;
      font-weight: 600;
      color: ${colors.greySubtitle};
    }
  }
`;

export const SampleTable = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`;

export const HeaderTable = styled.div`
  height: 60px;
  width: 100%;
  background-color: ${colors.greyBorder};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
  font-weight: 600;
  color: ${colors.grey};
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  cursor: pointer;

  .left {
    display: flex;
    align-items: center;
    justify-content: start;
    flex: 1;

    &.space {
      flex: 2;
    }

    .arrows {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-left: 5px;

      .top {
        margin-bottom: -7px;
        font-size: 22px;
        opacity: 0.5;
      }

      .bottom {
        margin-top: -7px;
        font-size: 22px;
        opacity: 0.5;
      }

      .active {
        color: ${colors.blueInfo};
        opacity: 1;
      }
    }
  }

  .right {
    flex: 1;

    &.center {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const BodyTable = styled.div`
  border-top: none;
  width: 100%;
  height: 100%;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  background-color: ${colors.white};
`;

export const LineTable = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
  height: 60px;

  p {
    font-size: 16px;
    font-weight: 600;
    color: ${colors.greySubtitle};
  }

  .prh {
    flex: 1;

    &.center {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.pointer {
      cursor: pointer;
    }
  }

  .left {
    display: flex;
    align-items: center;
    justify-content: start;
    flex: 2;

    .boxImage {
      width: 40px;
      height: 40px;
      border-radius: 40px;
      background-color: ${colors.greyBorder};
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid ${colors.greenMain};
      margin-right: 10px;
      padding: 3px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 40px;
      }

      .letter {
        font-size: 20px;
        color: ${colors.greySubtitle};
        font-weight: 600;
      }
    }
  }
`;
