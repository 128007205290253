import styled, { css } from 'styled-components';

const ButtonModifier = {
  default: ({ theme }) => css`
    background-color: ${theme.palette.brand.secondary.natural};
    color: ${theme.palette.words.text.contrast};
  `,
  ghost: ({ theme }) => css`
    border: 1px solid ${theme.palette.brand.primary.natural};
    border-radius: 4px;
    color: ${theme.palette.brand.primary.natural};
  `,
  hover: () => css`
    opacity: 0.8;
  `,
};

export const Button = styled.button`
  ${({ theme, variant }) => css`
    border: none;
    background-color: transparent;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    min-width: 129px;
    border-radius: 4px;
    font-weight: 900;
    font-size: 14px;
    text-transform: uppercase;

    ${ButtonModifier[variant]({ theme })}

    &:hover {
      ${ButtonModifier.hover()}
    }
  `}
`;
