export const columns = [
  {
    Header: 'Id',
    identifier: 'id',
    id: 'id',
    accessor: d => Number(d.id),
    sortType: 'basic',
    show: true,
    disableSortBy: false,
  },
  {
    Header: 'Titulo',
    identifier: 'titulo',
    id: 'titulo',
    accessor: d => String(d.titulo),
    sortType: 'basic',
    show: true,
    disableSortBy: false,
  },
  {
    Header: 'Responsável',
    identifier: 'responsavel',
    id: 'responsavel.nome',
    accessor: d => String(d.responsavel.nome),
    sortType: 'basic',
    show: true,
  },
  {
    Header: 'Cidade',
    identifier: 'cidade',
    id: 'cidade',
    accessor: d => d.cidade,
    sortType: 'basic',
    show: true,
    disableSortBy: false,
  },
  {
    Header: 'Expira em',
    identifier: 'expira',
    id: 'expira_em',
    accessor: d => String(d.expira_em),
    sortType: 'basic',
    show: true,
    disableSortBy: false,
    centered: true,
  },
  {
    Header: 'Status',
    identifier: 'status',
    id: 'status',
    accessor: d => String(d.status),
    sortType: 'basic',
    show: true,
    disableSortBy: true,
    centered: true,
  },
  {
    Header: 'Candidatos',
    identifier: 'candidatos',
    id: 'n_candidatos',
    accessor: d => String(d.n_curriculos),
    sortType: 'basic',
    show: true,
    disableSortBy: false,
    centered: true,
  },
  {
    Header: 'Ações',
    identifier: 'acoes',
    accessor: 'acoes',
    show: true,
    disableSortBy: true,
  },
];
