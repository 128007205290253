// Styles
import * as S from './styled';
import 'react-date-range/dist/styles.css';
import { useTheme } from 'styled-components';
import 'react-date-range/dist/theme/default.css';

// Libs
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

// Components
import GhostButton from 'components/Buttons/Ghost';
import DefaultButton from 'components/Buttons/Default';

// React
import React, { useMemo, useState } from 'react';
import { DateRangePicker } from 'react-date-range';

// Components MUI
import { Popover } from '@mui/material';
import { ArrowForward, ArrowDropDown, ArrowDropUp } from '@mui/icons-material';

// Constants
import { defaultStaticRanges } from './defined';

export const DatePicker = ({ initialize, select, setCurrentSelect }) => {
  // Theme
  const theme = useTheme();

  const initial = new Date(initialize[0]);
  const final = new Date(initialize[1]);

  const [range, setRange] = useState([
    {
      startDate: initial,
      endDate: final,
      value: initialize,
      key: 'selection',
    },
  ]);

  const ranges = useMemo(
    () =>
      select.map(item => {
        const start = new Date(item[0]);
        const end = new Date(item[1]);

        const initial = new Date(item[0]);
        const datesBetween = [];

        for (
          let date = initial;
          date <= end;
          date.setDate(date.getDate() + 1)
        ) {
          datesBetween.push(date.getTime());
        }

        return {
          startDate: start,
          endDate: end,
          value: item,
          datesBetween,
        };
      }),
    [],
  );

  const invalidDays = useMemo(() => {
    const max = new Date(select[0][0]);
    const min = new Date(select[select.length - 1][1]);

    const total = [];

    for (
      let date = new Date(min);
      date <= new Date(max);
      date.setDate(date.getDate() + 1)
    ) {
      total.push(date.getTime());
    }

    const intervals = [];

    select.map(item => {
      const start = new Date(item[0]);
      const end = new Date(item[1]);

      for (
        let date = new Date(start);
        date <= new Date(end);
        date.setDate(date.getDate() + 1)
      ) {
        intervals.push(date.getTime());
      }
    });

    const invalid = total.filter(item => !intervals.includes(item));
    return invalid;
  }, []);

  const handleChange = item => {
    const time = item.startDate.getTime();
    let found = ranges.find(range => range.datesBetween.includes(time));
    if (!found) {
      found = item;
    }
    setRange([
      {
        startDate: found?.startDate,
        endDate: found?.endDate,
        value: found?.value,
        key: 'selection',
      },
    ]);

    setCurrentSelect(found?.value);
  };

  return (
    <div>
      <S.Main>
        <DateRangePicker
          maxDate={ranges[ranges.length - 1].startDate}
          minDate={ranges[0].endDate}
          onChange={item => handleChange(item.selection)}
          showMonthAndYearPickers={false}
          showMonthArrow // default
          showDateDisplay={false}
          showPreview // default
          dayDisplayFormat="d" // default
          weekdayDisplayFormat="EEEEEE"
          dateDisplayFormat="MMM d, yyyy" // default
          monthDisplayFormat="MMMM"
          rangeColors={[theme.palette.brand.secondary.natural]}
          dragSelectionEnabled={false}
          focusedRange={[0, 0]}
          disabledDates={invalidDays.map(item => new Date(item))}
          weekStartsOn={1}
          locale={ptBR}
          months={2}
          ranges={range}
          direction="horizontal"
          preventSnapRefocus
          calendarFocus="backwards"
          inputRanges={[]}
          staticRanges={defaultStaticRanges(ranges)}
        />
      </S.Main>
    </div>
  );
};
