import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';

// COMPONENTS
import Select from 'components/Inputs/Select';
import InfoCard from 'components/Cards/InfoCard';
import ConfirmModal from 'components/ConfirmModal';
import DefaultButton from 'components/Buttons/Default';

// MATERIAL UI
import { Divider } from '@mui/material';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';

// UTILS
import { useSelector } from 'react-redux';
import { formatNewDate } from 'utils/dates';
import * as services from '../services';
import * as S from './styled';

// #TODO Exibição de imagem de perfil **Está fixa na primeira letra

export const Assinaturas = ({ evaluation, setRecharge, disabled }) => {
  // User Info
  const { user } = useSelector(state => {
    return state.auth.user;
  });

  // Variables for conditional checks
  const { avaliador } = evaluation;
  const { testemunha } = evaluation;
  const assTestemunha = evaluation.assinatura_testemunha_em;
  const { motorista } = evaluation;
  const assMotorista = evaluation.assinada_em;

  // Witness Controler
  const [witness, setWitness] = useState(null);
  const [witnessModal, setWitnessModal] = useState(false);
  const [wtName, setWtName] = useState(testemunha ? testemunha.nome : '');
  const [currentWt, setCurrentWt] = useState(testemunha ? testemunha.id : '');

  // Overwrite Avaliador (Modal)
  const [overWriteModal, setOverWriteModal] = useState(false);

  // ---------------------------REQUESTS----------------------------------//
  useEffect(() => {
    const getWitness = async () => {
      const formatedWt = [];
      const res = await services.requestResponsibles(evaluation.id);
      const wt = res.data;
      for (const i in wt) {
        formatedWt.push({
          value: wt[i].id,
          name: wt[i].nome,
        });
      }
      setWitness(formatedWt);
    };
    getWitness();
  }, []);

  // ASS TESTEMUNHA
  const hadleSignatureWitness = async () => {
    const body = {
      event: 'witnessSignature',
      data: {},
      id: evaluation.id,
    };

    const res = await services.signatureEvaluation(body);
    if (res.success) {
      toast.success(res.message);
      setRecharge(true);
    } else {
      toast.error(res.message);
    }
  };

  // ASS AVALIADOR
  const handleSignature = async () => {
    const body = {
      event: 'aproveEvaluation',
      data: {},
      id: evaluation.id,
    };

    const res = await services.signatureEvaluation(body);
    if (res.success) {
      toast.success(res.message);
      setRecharge(true);
    } else {
      toast.error(res.message);
    }
  };

  // FALL ASS AVALIADOR
  const handleOutSignature = async () => {
    const body = {
      event: 'fallEvaluation',
      data: {},
      id: evaluation.id,
    };

    const res = await services.signatureEvaluation(body);
    if (res.success) {
      toast.success(res.message);
      setRecharge(true);
    } else {
      toast.error(res.message);
    }
  };

  // ---------------------------CONTROLL WITNESS MODAL----------------------------------//
  const handleWitnessModal = value => {
    setCurrentWt(value);
    setWitnessModal(true);
    for (const i in witness) {
      if (value === witness[i].value) setWtName(witness[i].name);
    }
  };

  const clearModalWitness = () => {
    setCurrentWt(testemunha ? testemunha.id : '');
    setWitnessModal(false);
    setWtName(testemunha ? testemunha.nome : '');
  };

  const handleSelectWitnessEvaluation = async () => {
    if (!currentWt) {
      toast.error('Selecione uma testemunha');
      return;
    }

    if (avaliador?.id === currentWt) {
      toast.error('O Avaliador e testemunha não podem ser iguais.');
      return;
    }

    const body = {
      event: 'bulk',
      data: {
        id_da_testemunha: currentWt,
        avaliacoes: [evaluation.id],
      },
    };

    const res = await services.selectWitnessEvaluation(body);
    if (res.data.success) {
      toast.success('Sucesso ao selecionar testemunha da avaliação!');
      setRecharge(true);
    } else {
      toast.error(res.data.message);
    }
  };

  return (
    <S.Container>
      <div className="avaliador">
        <div className="headerBox">
          <S.Title>Assinatura Avaliador</S.Title>
        </div>
        <S.signedBox>
          <div className="imageArea">
            {(avaliador?.foto || user?.foto) && (
              <img src={avaliador?.foto || user?.foto} />
            )}
            {!avaliador?.foto && !user?.foto && (
              <span className="letters">
                {avaliador ? avaliador.nome[0] : user.nome[0]}
              </span>
            )}
          </div>

          <div className="textArea">
            <span className="userName">
              {avaliador ? avaliador.nome : user.nome}
            </span>
            <span className="status">
              {avaliador
                ? formatNewDate(evaluation.data_finalizacao)
                : 'Aguardando Assinatura'}
            </span>
          </div>
        </S.signedBox>
        {!assMotorista && !avaliador && (
          <DefaultButton
            style={{ width: '100%' }}
            onClick={() => handleSignature()}
            // disabled={disabled}
          >
            <ModeEditOutlineOutlinedIcon />
            Assinar e Salvar
          </DefaultButton>
        )}

        {
          /*! assMotorista && */ avaliador && (
            <DefaultButton
              style={{ width: '100%' }}
              onClick={() => setOverWriteModal(true)}
            >
              <ModeEditOutlineOutlinedIcon />
              Derrubar Assinatura
            </DefaultButton>
          )
        }
      </div>
      <Divider style={{ margin: '20px 0 20px 0 ' }} />
      <div className="testemunha">
        <div className="headerBox">
          <S.Title>Assinatura Testemunha</S.Title>
        </div>
        {testemunha && (
          <S.signedBox>
            <div className="imageArea">
              {testemunha?.foto && <img src={testemunha.foto} />}
              {testemunha?.foto === null && (
                <span className="letters">{testemunha.nome[0]}</span>
              )}
            </div>
            <div className="textArea">
              <span className="userName">{testemunha.nome}</span>
              <span className="status">
                {assTestemunha
                  ? formatNewDate(assTestemunha)
                  : 'Aguardando Assinatura'}
              </span>
            </div>
          </S.signedBox>
        )}
        {disabled && !testemunha && !assTestemunha && !assMotorista && (
          <InfoCard message="Para selecionar uma testemunha é necessário derrubar a assinatura do avaliador!" />
        )}
        {!assTestemunha && !assMotorista && (
          <Select
            data={witness || []}
            value={witness ? currentWt : ''}
            label="Selecionar Testemunha"
            handleChange={e => handleWitnessModal(e.target.value)}
            markselected
            disabled={disabled}
          />
        )}

        {!assTestemunha && assMotorista && (
          <S.signedBox>
            <span className="outWitness">
              Nenhuma testemunha assinou essa avaliação!
            </span>
          </S.signedBox>
        )}

        {!assTestemunha && testemunha?.id === user.id && (
          <DefaultButton
            style={{ width: '100%', marginTop: '20px' }}
            onClick={() => hadleSignatureWitness()}
            // disabled={disabled}
          >
            <ModeEditOutlineOutlinedIcon />
            Assinar e Salvar
          </DefaultButton>
        )}
      </div>
      <Divider style={{ margin: '20px 0 20px 0 ' }} />
      <div className="motirista">
        <div className="headerBox">
          <S.Title>Assinatura Motorista</S.Title>
        </div>
        <S.signedBox>
          <div className="imageArea">
            {motorista?.foto && <img src={motorista.foto} />}
            {motorista?.foto === null && (
              <span className="letters">{motorista.nome[0]}</span>
            )}
          </div>
          <div className="textArea">
            <span className="userName">{motorista.nome.toLowerCase()}</span>
            <span className="status">
              {assMotorista
                ? formatNewDate(assMotorista)
                : 'Aguardando Assinatura'}
            </span>
          </div>
        </S.signedBox>
      </div>

      {/*  Modal Area */}
      {avaliador && (
        <ConfirmModal
          handleClose={() => setOverWriteModal(false)}
          open={overWriteModal}
          title="Tem certeza que deseja DERRUBAR assinatura?"
          subtitle="A assinatura do AVALIADOR, TESTEMUNHA e MOTORISTA serão anuladas e a avaliação será movida para PENDENTE"
          buttonText="Confirmar"
          onClick={() => handleOutSignature()}
        />
      )}

      {currentWt && (
        <ConfirmModal
          handleClose={() => clearModalWitness()}
          open={witnessModal}
          title={
            testemunha
              ? 'Tem certeza que deseja alterar testemunha?'
              : 'Tem certeza que deseja adicionar testemunha?'
          }
          subtitle={
            testemunha
              ? `A testemunha será alterada de ${testemunha.nome} para ${wtName}`
              : `A testemunha ${wtName} será adicionada na avaliação`
          }
          buttonText="Confirmar"
          onClick={() => handleSelectWitnessEvaluation()}
        />
      )}
    </S.Container>
  );
};
