import api from '../../services/api';

const updateFleet = async data => {
  const res = await api.put(`/fleet`, data);
  return res;
};

const checkUpload = async data => {
  const res = await api.post(`/frota/checkUpload`, data);
  return res;
};

const createTruck = async data => {
  const res = await api.post('/frota', data);
  return res;
};

const updateTruck = async data => {
  const res = await api.put(`/frota/${data.id}`, data);
  return res;
};

const changeStatus = async id => {
  const res = await api.put(`/frota-change-status/${id}`);
  return res;
};

const changeClient = async data => {
  const res = await api.put('/frota-change-client', data);
  return res;
};

const changeStatusMany = async data => {
  const res = await api.put(`/frota-change-status-many`, data);
  return res;
};

const createMany = async data => {
  const res = await api.post(`/frota/storeMany`, data);
  return res;
};

const requestAvailable = async query => {
  const res = await api.get('/available-videos', { params: query });
  return res;
};

const createAvailable = async data => {
  const res = await api.post('/available-videos', data);
  return res;
};

const requestLogs = async id => {
  const res = await api.get(`/frota-logs/${id}`);
  return res;
};

const requestExcel = async query => {
  const res = await api.get('/excel/frota', { params: query });
  return res;
};

export {
  updateFleet,
  checkUpload,
  createTruck,
  createMany,
  updateTruck,
  changeStatus,
  changeStatusMany,
  requestAvailable,
  createAvailable,
  requestLogs,
  requestExcel,
  changeClient,
};
