import {
  getClients,
  getEmpresas,
  getOperations,
  getResponsaveis,
} from 'constants/_SERVICES/user';

// Pages
import Rotograma from 'pages/Rotograma';
import RotogramaHistorico from 'pages/Rotograma/Historico';

export default {
  '/rotograma': {
    defaults: [],
    pageFilterName: 'filterRotograma',
    filters: [
      {
        name: 'client',
        placeholder: 'Filtrar por Cliente',
        mode: 'multiple',
        data: async () => getClients(),
      },
      {
        name: 'empresas',
        placeholder: 'Filtrar por Empresas',
        mode: 'single',
        data: async () => getEmpresas(),
        dependency: [
          {
            type: 'user',
            name: 'client',
            clear: true,
            visible: true,
            maxSelect: 1,
            value: 1,
          },
        ],
      },
      {
        name: 'operation',
        placeholder: 'Filtrar por Operação',
        mode: 'single',
        data: async () => getOperations(),
      },
      {
        name: 'responsavel',
        placeholder: 'Filtrar por Responsável',
        mode: 'single',
        data: async () => getResponsaveis(),
      },
    ],
    page: <Rotograma />,
  },

  '/rotograma/historico/:id': {
    back: -1,
    title: 'Rotograma',
    id: true,
    defaults: [],
    page: <RotogramaHistorico />,
  },

  '/rotograma/criar': {
    pageFilterName: 'filterRotograma',
    defaults: [],
    filters: [
      {
        name: 'client',
        placeholder: 'Filtrar por Cliente',
        mode: 'multiple',
        data: async () => getClients(),
      },
      {
        name: 'empresas',
        placeholder: 'Filtrar por Empresas',
        mode: 'single',
        data: async () => getEmpresas(),
        dependency: [
          {
            type: 'user',
            name: 'client',
            clear: true,
            visible: true,
            maxSelect: 1,
            value: 1,
          },
        ],
      },
      {
        name: 'operation',
        placeholder: 'Filtrar por Operação',
        mode: 'single',
        data: async () => getOperations(),
      },
      {
        name: 'responsavel',
        placeholder: 'Filtrar por Responsável',
        mode: 'single',
        data: async () => getResponsaveis(),
      },
    ],
    page: <Rotograma />,
  },
};
