import produce from 'immer';
import * as types from './types';

const INITIAL_STATE = {
  departamento: '',
  propriedade: '',
  status: '',
  carga: '',
};

export default function filterFrota(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case types.SET_FILTER_FROTA: {
        draft.departamento = action.payload.query.departamento;
        draft.propriedade = action.payload.query.propriedade;
        draft.status = action.payload.query.status;
        draft.carga = action.payload.query.carga;
        break;
      }
      default:
    }
  });
}
