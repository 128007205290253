// Styled
import { useEffect, useState } from 'react';
import { Divider } from '@mui/material';
import NumberFormat from 'react-number-format';
import * as S from './styled';

// React

// Components
import { WithQuestion } from '..';

// Components MUI

// Actions
import { changeText } from '../../actions';

// Lib

export const ResponsePorcentagem = ({
  field,
  secoes,
  setSecoes,
  sectionId,
  errosObrigatorios,
  preview,
  approveView,
}) => {
  const redirectFunction = (texto, idCampo) => {
    setCleanField(true);
    changeText(texto, idCampo, setSecoes, secoes, sectionId);
  };

  // ------------------------------------ERROS-------------------------------------------//
  const [hasErrorObrigatorio, setHasErrorObrigatorio] = useState(false);
  const [cleanField, setCleanField] = useState(false);

  useEffect(() => {
    // Reset fields
    setHasErrorObrigatorio(false);
    setCleanField(false);
    if (approveView) return null;

    if (errosObrigatorios) {
      for (const i in errosObrigatorios) {
        const erros = errosObrigatorios[i];
        if (sectionId === erros.idSecao && field.id === erros.idCampo) {
          setHasErrorObrigatorio(true);
        }
      }
    }
  }, [errosObrigatorios]);

  return (
    <>
      <S.QuestionBox
        hasError={hasErrorObrigatorio && !cleanField}
        className={`fieldClass-${field.id}`}
      >
        <WithQuestion
          field={field}
          secoes={secoes}
          setSecoes={setSecoes}
          sectionId={sectionId}
          hasError={hasErrorObrigatorio && !cleanField}
          preview={preview}
          approveView={approveView}
        />
        <S.ResponseArea hasError={hasErrorObrigatorio && !cleanField}>
          <NumberFormat
            value={field.resposta}
            suffix="%"
            decimalScale={2}
            decimalSeparator="."
            displayType="input"
            type="text"
            allowNegative={false}
            onValueChange={e => redirectFunction(e.value.slice(0, 4), field.id)}
            placeholder="%"
            disabled={approveView}
          />
        </S.ResponseArea>
      </S.QuestionBox>
      <Divider />
    </>
  );
};
