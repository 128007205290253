import styled, { css } from 'styled-components';

export const TotalContentBox = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.palette.system.background};
    margin: 2rem 0;
    display: flex;
    border-radius: 0.25rem;
    overflow-x: scroll;
    cursor: grab;

    &:active {
      cursor: grabbing;
    }

    ::-webkit-scrollbar {
      height: 8px;
    }
    ::-webkit-scrollbar-track {
      background-color: ${theme.palette.brand.primary.background};
    }

    .boxItem {
      flex: 1;
      min-width: 180px;
      border: 2px solid ${theme.palette.system.border};
      border-right: none;
      :last-child {
        border-right: 2px solid ${theme.palette.system.border};
      }

      .title {
        padding: 1rem;
        height: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: ${theme.palette.brand.primary.background};
        text-align: center;
        font-weight: 600;
        color: ${theme.palette.words.subtitle.natural};
        border-bottom: 2px solid ${theme.palette.system.border};
      }

      .value {
        padding: 1rem;
        text-align: center;
        font-weight: 500;
        color: ${theme.palette.words.title.natural};
        background-color: ${theme.palette.system.overlay};
      }
    }
  `}
`;

export const TotalTitle = styled.div`
  ${({ theme }) => css`
    font-size: 25px;
    width: 100%;
    text-align: center;
    font-weight: 700;
    color: ${theme.palette.words.title.natural};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  `}
`;
