/* eslint-disable import/order */

// Styles
import * as S from './styled';
import * as C from '../styled';
import colors from 'styles/colors';

// React
import { useEffect, useState } from 'react';

// Components
import RightModal from 'components/RightModal';
import { Switch } from 'components/Inputs/Switch';
import TextInput from 'components/Inputs/TextField';
import DefaultButton from 'components/Buttons/Default';

// Components MUI
import { Tooltip, Grid } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

// Utils
import emptyImage from 'images/empty/frota.png';

export const Filial = ({
  title,
  tooltip,
  button,
  setChanged,
  setCurrentData,
  data,
  preview,
}) => {
  // --------------------------------------TABLE---------------------------------------//
  const [ordenad, setOrdenad] = useState(0);
  const [filiais, setFiliais] = useState(
    data?.map((item, k) => ({ ...item, id_local: data?.length - k })),
  );

  const getOrdenad = () => {
    switch (ordenad) {
      case 0:
        return [...filiais.sort((a, b) => b.id_local - a.id_local)];
      case 1:
        return [...filiais.sort((a, b) => `${a.nome}`.localeCompare(b.nome))];
      case 2:
        return [...filiais.sort((a, b) => `${b.nome}`.localeCompare(a.nome))];
    }
  };

  const handleOrdenad = () => {
    if (ordenad == 0) {
      setOrdenad(1);
    } else if (ordenad == 1) {
      setOrdenad(2);
    } else {
      setOrdenad(0);
    }
  };

  const handleStatus = idx => {
    setChanged(true);
    const copyFiliais = [...filiais];
    copyFiliais[idx].status = !copyFiliais[idx].status;
    setFiliais(copyFiliais);
  };

  useEffect(() => {
    setCurrentData(filiais);
  }, [filiais]);

  // --------------------------------------MODAL---------------------------------------//
  const [collapse, setCollapse] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [filiaisInModal, setFiliaisInModal] = useState(['']);

  useEffect(() => {
    const opned = [];
    for (const i in filiaisInModal) {
      opned.push(!!i);
    }
    setCollapse(opned);
  }, []);

  const handleCollapese = (idx, isFocus) => {
    const copyCollapse = [...collapse];
    for (const i in copyCollapse) {
      if (i != idx) copyCollapse[i] = false;
    }

    if (!isFocus) {
      if (copyCollapse[idx] == true) {
        copyCollapse[idx] = false;
      } else {
        copyCollapse[idx] = true;
      }
    } else {
      copyCollapse[idx] = true;
    }

    setCollapse(copyCollapse);
  };

  const handleText = (value, idx) => {
    const copyFiliaisInModal = [...filiaisInModal];
    copyFiliaisInModal[idx] = value;
    setFiliaisInModal(copyFiliaisInModal);
  };

  const addFilialInModal = () => {
    const copyFiliaisInModal = [...filiaisInModal];
    copyFiliaisInModal.push('');
    setFiliaisInModal(copyFiliaisInModal);
    setTimeout(() => {
      handleCollapese(copyFiliaisInModal?.length - 1, true);
    }, 100);
  };

  const deleteFilialInModal = idx => {
    const copyFiliaisInModal = [...filiaisInModal];
    copyFiliaisInModal.splice(idx, 1);
    setFiliaisInModal(copyFiliaisInModal);
    handleCollapese(idx, true);
  };

  const handleAddFiliais = () => {
    setChanged(true);
    const copyFiliais = filiais.filter(item => item.id);
    for (const i in filiaisInModal) {
      if (filiaisInModal[i] != '') {
        copyFiliais.unshift({
          id_local: copyFiliais?.length + 1,
          nome: filiaisInModal[i],
          status: true,
        });
      }
    }
    setFiliais(copyFiliais);
    setOrdenad(0);
  };

  const childrenModal = () => {
    return (
      <>
        {filiaisInModal.map((item, key) => (
          <Grid container spacing={2} key={key}>
            <Grid item xs={12} sm={12}>
              <S.Box>
                <div className="line">
                  <div className="title" onClick={() => handleCollapese(key)}>
                    Filial {key + 1} {item && `- ${item}`}
                  </div>
                  <div className="icons">
                    <DeleteOutlinedIcon
                      onClick={() => deleteFilialInModal(key)}
                      htmlColor={colors.redDanger}
                      style={{
                        cursor: 'pointer',
                      }}
                    />
                    <ArrowForwardIosIcon
                      onClick={() => handleCollapese(key)}
                      className="arrow"
                      style={{
                        transform: collapse[key]
                          ? 'rotate(90deg)'
                          : 'rotate(0deg)',
                        transition: 'all ease .5s',
                        marginLeft: '20px',
                        cursor: 'pointer',
                      }}
                    />
                  </div>
                </div>

                <S.CollapseMu in={collapse[key]} unmountOnExit>
                  <TextInput
                    required
                    label="Nome da Filial"
                    placeholder=""
                    value={item}
                    onChange={e => handleText(e.target.value, key)}
                    name="nome"
                    id="nome"
                  />
                </S.CollapseMu>
              </S.Box>
              <S.Bar />
            </Grid>
          </Grid>
        ))}

        <S.Box>
          <S.Button onClick={() => addFilialInModal()}>
            <AddCircleOutlineOutlinedIcon />
            <span>Adicionar Filial</span>
          </S.Button>
        </S.Box>
      </>
    );
  };

  // --------------------------------------RENDER---------------------------------------//
  return (
    <S.Container>
      <C.HeaderStep>
        <div className="left">
          <div className="title">{title}</div>
          {tooltip && (
            <Tooltip title={tooltip}>
              <HelpOutlineOutlinedIcon className="iconTooltip" />
            </Tooltip>
          )}
        </div>
        {button && (
          <DefaultButton
            children={button}
            icon={<AddCircleOutlineOutlinedIcon />}
            onClick={() => setOpenModal(true)}
            disabled={preview}
          />
        )}
      </C.HeaderStep>

      {filiais?.length > 0 ? (
        <S.SampleTable>
          <S.HeaderTable onClick={() => handleOrdenad()}>
            <div className="left">
              <p>NOME</p>
              <div className="arrows">
                <ArrowDropUpOutlinedIcon
                  className={ordenad == 1 ? 'top active' : 'top'}
                />
                <ArrowDropDownOutlinedIcon
                  className={ordenad === 2 ? 'bottom active' : 'bottom'}
                />
              </div>
            </div>
            <div className="right">
              <p>STATUS</p>
            </div>
          </S.HeaderTable>
          <S.BodyTable>
            {getOrdenad().map((item, key) => (
              <S.LineTable key={key}>
                <p>{item.nome}</p>
                <Switch
                  check={!!item.status}
                  setCheckbox={() => handleStatus(key)}
                />
              </S.LineTable>
            ))}
          </S.BodyTable>
        </S.SampleTable>
      ) : (
        <C.Empty>
          <img className="image" src={emptyImage} />
          <div className="title">Adicione Filiais</div>
          <div className="subtitle">Adicione as filiais da sua empresa</div>
        </C.Empty>
      )}

      <RightModal
        open={openModal}
        handleClose={() => setOpenModal(false)}
        children={childrenModal()}
        title="Adicionar Filial"
        icon={() => (
          <AddCircleOutlineOutlinedIcon htmlColor={colors.greenMain} />
        )}
        textButton="Adicionar"
        onConfirm={() => handleAddFiliais()}
      />
    </S.Container>
  );
};
