import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { SubdirectoryArrowRight } from '@mui/icons-material';
import { Logs } from 'components/Logs';
import GhostButton from 'components/Buttons/Ghost';
import * as S from '../styled';
import { requestHistory } from '../services';

const LogDesvio = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getLogs = async () => {
      const res = await requestHistory(id);
      if (res.data?.data) setLogs(res.data.data);
      setLoading(false);
    };
    getLogs();
  }, []);

  return (
    <S.Main>
      <S.SpacedRow>
        <div />
        <GhostButton
          startIcon={
            <SubdirectoryArrowRight sx={{ transform: 'rotate(180deg)' }} />
          }
          size="medium"
          onClick={() => navigate(`/violacao/${id}`)}
        >
          Voltar para violação
        </GhostButton>
      </S.SpacedRow>

      <Logs logs={logs} loading={loading} />
    </S.Main>
  );
};

export default LogDesvio;
