import styled from 'styled-components';
import colors from 'styles/colors';

export const Container = styled.div`
  border: 2px solid ${colors.greyTiny};
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
  background-color: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
`;

export const Title = styled.div`
  display: flex;
  align-items: center;

  .type {
    font-size: 25px;
    color: ${colors.greyTitle};
    font-weight: 600;
    margin-left: 10px;

    &::first-letter {
      text-transform: uppercase;
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: end;
  gap: 20px;
`;
