import api from '../../../services/api';

export const readOneNotification = async data => {
  const res = await api.put(`/read-notification`, data);
  return res;
};

export const readAllNotifications = async data => {
  const res = await api.post(`/notificacoes/view`, { ids: data });
  return res;
};
