import styled from 'styled-components';
import colors from 'styles/colors';

export const Container = styled.div`
  background-color: #fff;
  width: 230px;
  height: calc(100vh - 60px);
  position: fixed;
  top: 0;
  left: 0;
  margin-left: 60px;
  margin-top: 60px;
  border-right: 2px solid rgba(0, 0, 0, 0.1);
  box-shadow: 9px 3px 12px -4px rgba(0, 0, 0, 0.1);
  position: fixed;
  z-index: 997;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;

  .navBarItem {
    width: 100%;
    font-size: 16px;
    color: ${colors.greyTitle};
    font-weight: 600;
    opacity: 0.9;
    text-align: start;
    border-right: 4px solid #0000;
    padding: 13px 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: all ease 0.3s;

    span {
      margin-left: 10px;
    }
  }

  .active {
    border-right: 4px solid ${colors.greenMain};
    background-color: ${colors.greyBorder};
  }

  .titleSec {
    text-align: start;
    width: 100%;
    padding: 0px 20px;
    margin-bottom: 10px;
    margin-top: 10px;
    text-transform: uppercase;
    font-weight: 600;
    color: ${colors.greySubtitle};
    font-size: 14px;
  }
`;
