import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

// Components
import { Modal, Grid, IconButton, SvgIcon } from '@mui/material';
import Icon from 'components/Icons';
import Select from 'components/Inputs/Select';
import SelectList from 'components/Inputs/SelectList';
import GhostButton from 'components/Buttons/Ghost';
import DefaultButton from 'components/Buttons/Default';

import { ReactComponent as filterIco } from 'images/icons/filter.svg';
import {
  getTiposPadrao,
  getTiposVeiculos,
} from 'pages/FadigaConfiguracoes/services';
import * as S from './styled';

const FilterModal = ({ filter, setFilter, open, handleClose, groupBy }) => {
  const theme = useTheme();
  const [prevFilter, setPrevFilter] = useState({ ...filter });
  const [filiaisFilter, setFiliaisFilter] = useState([]);
  const [selectedEmpresas, setSelectedEmpresas] = useState(false);
  const { user } = useSelector(state => state.auth.user);
  const userLevel = user?.nivel;
  const isProvider = user?.provider;

  const selects = useSelector(state => state.selects);

  useEffect(() => {
    setPrevFilter({ ...filter });
  }, [filter]);

  const { data: _tiposVeiculos = [] } = useQuery(
    ['tipos-veiculos-fadiga'],
    () => getTiposVeiculos(),
    { staleTime: Infinity },
  );

  const { data: tiposPadrao = [] } = useQuery(
    ['desvios-padrao-fadiga', 'FADIGA'],
    () => getTiposPadrao({ categoria: ['FADIGA'] }),
    { staleTime: Infinity },
  );

  const tiposVeiculos = _tiposVeiculos.map(tipo => ({
    value: tipo.tipo,
    id: tipo.id,
  }));

  const tiposDesvios = tiposPadrao.map(tipo => ({
    value: tipo.tipo,
    id: tipo.id,
  }));

  const operations = [
    {
      id: 'PRIMARIA',
      value: 'Primária',
    },
    {
      id: 'SECUNDARIA',
      value: 'Secundária',
    },
    {
      id: 'ACUCAR',
      value: 'Açúcar',
    },
    {
      id: -1,
      value: 'Não Informado',
    },
  ];

  const clientes =
    selects.clients?.map(i => {
      return { value: i.nome, id: i.id };
    }) || [];

  const filiais =
    selects.filials?.map(i => {
      return { value: i.nome, id: i.id };
    }) || [];

  useEffect(() => {
    if (isProvider) {
      if (prevFilter?.empresas) {
        const count = prevFilter?.empresas.split(',').length;

        if (count === 1) {
          setSelectedEmpresas(true);
          const empresa = prevFilter?.empresas;
          const newFiliais = selects.empresas
            .find(company => company.id === Number(empresa))
            .filiais?.map(i => ({ id: i.id, value: i.nome }));
          setFiliaisFilter(newFiliais);
        } else {
          setSelectedEmpresas(false);
          setPrevFilter(_prevFilter => ({ ..._prevFilter, filial: '' }));
        }
      } else {
        setSelectedEmpresas(false);
        setPrevFilter(_prevFilter => ({ ..._prevFilter, filial: '' }));
      }
    }
  }, [prevFilter?.empresas, selects.empresas]);

  const handleChange = (e, key) => {
    setPrevFilter(_prevFilter => {
      return { ..._prevFilter, [key]: e };
    });
  };

  const aplyFilter = () => {
    const _prevFilter = { ...prevFilter };
    Object.keys(_prevFilter).forEach(key => {
      if (!_prevFilter[key]) delete _prevFilter[key];
    });
    setFilter(() => ({ ..._prevFilter }));
    handleClose();
  };

  const reset = () => {
    setPrevFilter({ ...filter });
  };

  // Limpa TODOS os valores do estado e foca no botao de aplicar
  const handleClear = () => {
    setPrevFilter({});
    const buttonApply = document.getElementById('aplicar');
    buttonApply?.focus();
  };

  const body = (
    <S.Container>
      <S.Header>
        <div style={{ alignItems: 'center' }}>
          <div>
            <SvgIcon component={filterIco} />
            <h2>Filtros</h2>
          </div>
        </div>

        <IconButton size="small" onClick={handleClose}>
          <Icon
            sx={{ color: theme.palette.words.subtitle.natural }}
            name="close"
          />
        </IconButton>
      </S.Header>
      <S.Action>
        <S.ClearButton onClick={handleClear}>Limpar filtros</S.ClearButton>
      </S.Action>
      <S.Main>
        <Grid container spacing={2}>
          {isProvider && groupBy != 'empresas' ? (
            <>
              <Grid item xs={12} sm={12}>
                {groupBy != 'filial' ? (
                  <SelectList
                    value={prevFilter?.empresas}
                    handleChange={handleChange}
                    fieldName="empresas"
                    label="Filtrar por Empresas"
                    data={selects.empresas}
                    selectAll
                  />
                ) : (
                  <Select
                    data={selects.empresas.map(company => ({
                      value: company.id,
                      name: company.value,
                    }))}
                    value={prevFilter?.empresas}
                    onChange={e =>
                      setPrevFilter(_prevFilter => ({
                        ..._prevFilter,
                        empresas: String(e.target.value),
                      }))
                    }
                    name="empresas"
                    label="Selecione a empresa"
                  />
                )}
              </Grid>
              {groupBy != 'filial' && selectedEmpresas && (
                <Grid item xs={12} sm={12}>
                  <SelectList
                    value={prevFilter?.filial}
                    handleChange={handleChange}
                    fieldName="filial"
                    label="Filtrar por Filial"
                    data={filiaisFilter}
                    selectAll
                  />
                </Grid>
              )}
            </>
          ) : (
            <>
              {!isProvider && groupBy != 'filial' && (
                <Grid item xs={12} sm={12}>
                  <SelectList
                    value={prevFilter?.filial}
                    handleChange={handleChange}
                    fieldName="filial"
                    label="Filtrar por Filial"
                    disabled={userLevel > 1}
                    data={filiais}
                    selectAll
                  />
                </Grid>
              )}
              {!isProvider && groupBy != 'client' && (
                <Grid item xs={12} sm={12}>
                  <SelectList
                    value={prevFilter?.client}
                    handleChange={handleChange}
                    fieldName="client"
                    label="Filtrar por Cliente"
                    data={clientes}
                    selectAll
                  />
                </Grid>
              )}
            </>
          )}
          {groupBy != 'operacoes' && (
            <Grid item xs={12} sm={12}>
              <SelectList
                value={prevFilter?.operation}
                handleChange={handleChange}
                fieldName="operation"
                label="Filtrar por Operação"
                data={operations}
                selectAll
              />
            </Grid>
          )}
          {groupBy != 'desvio' && (
            <Grid item xs={12} sm={12}>
              <SelectList
                data={tiposDesvios}
                value={prevFilter?.idDesvio}
                label="Filtrar por Tipo de Desvio"
                fieldName="idDesvio"
                handleChange={handleChange}
                selectAll
                labelBackgroundColor={theme.palette.system.overlay}
              />
            </Grid>
          )}
          {groupBy != 'tipo_veiculo' && (
            <Grid item xs={12} sm={12}>
              <SelectList
                value={prevFilter?.idVeiculo}
                handleChange={handleChange}
                fieldName="idVeiculo"
                label="Filtrar por Tipo de Veiculo"
                data={tiposVeiculos}
                selectAll
                labelBackgroundColor={theme.palette.system.overlay}
              />
            </Grid>
          )}
          {groupBy != 'tipo_motorista' && (
            <Grid item xs={12} sm={12}>
              <SelectList
                value={prevFilter?.agregado}
                handleChange={handleChange}
                fieldName="agregado"
                label="Filtrar por Tipo de Motorista"
                data={[
                  { id: 'true', value: 'Agregados' },
                  { id: 'false', value: 'Próprios' },
                  { id: -1, value: 'Não Informado' },
                ]}
                selectAll
                labelBackgroundColor={theme.palette.system.overlay}
              />
            </Grid>
          )}
        </Grid>
      </S.Main>

      <S.Footer>
        <GhostButton
          onClick={() => {
            reset();
            handleClose();
          }}
          size="medium"
        >
          Cancelar
        </GhostButton>

        <DefaultButton
          onClick={aplyFilter}
          size="medium"
          sx={{ marginLeft: '20px' }}
          id="aplicar"
        >
          APLICAR
        </DefaultButton>
      </S.Footer>
    </S.Container>
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {body}
    </Modal>
  );
};

export default FilterModal;
