import {
  sub,
  add,
  format,
  isToday,
  isTomorrow,
  isYesterday,
  startOfWeek,
  endOfWeek,
  setISOWeek,
  differenceInCalendarWeeks,
  isValid,
} from 'date-fns';

import { utcToZonedTime } from 'date-fns-tz';

export const formatNewDate = date => {
  try {
    if (date && date !== null) {
      const timeZone = 'GMT';
      const formattedDate = utcToZonedTime(date, timeZone);
      return format(formattedDate, 'dd/MM/yyyy');
    }
    return '';
  } catch (error) {
    return '';
  }
};

export const formatTime = (date, seconds = false) => {
  if (date && date !== null) {
    const timeZone = 'GMT';
    const formattedDate = utcToZonedTime(date, timeZone);
    if (seconds) return format(formattedDate, 'HH:mm:ss');
    return format(formattedDate, 'HH:mm');
  }
  return '';
};

export const formatNameDate = date => {
  return format(date, 'yyyy-MM-dd');
};

// Para dados ja exitentes
export const formatNewHour = (date, seconds) => {
  if (date && date !== null) {
    const timeZone = '-03';
    const formattedDate = utcToZonedTime(new Date(date), timeZone);
    return !seconds
      ? format(formattedDate, 'dd/MM/yyyy - HH:mm')
      : format(formattedDate, 'dd/MM/yyyy - HH:mm:ss');
  }
  return '';
};

export const formatNewTime = (date, seconds = false) => {
  if (date && date !== null) {
    const timeZone = '-03';
    const formattedDate = utcToZonedTime(new Date(date), timeZone);
    if (seconds) return format(formattedDate, 'HH:mm:ss');
    return format(formattedDate, 'HH:mm');
  }
  return '';
};

// Para dados ja exitentes (sem -3 hrs)
export const formatNewHourClean = (date, seconds) => {
  if (date && date !== null) {
    const timeZone = '-00';
    const formattedDate = utcToZonedTime(new Date(date), timeZone);
    return !seconds
      ? format(formattedDate, 'dd/MM/yyyy - HH:mm')
      : format(formattedDate, 'dd/MM/yyyy - HH:mm:ss');
  }
  return '';
};

// Para dados nao existentes
export const formatEmptyHour = () => {
  const formattedDate = utcToZonedTime(new Date());
  return format(formattedDate, 'dd/MM/yyyy - HH:mm');
};

// Formato de data para salvar em arquivos
export const getDatetimeString = () => {
  const date = new Date();
  return format(date, 'yyyy-MM-dd_HH-mm-ss');
};

const DaysInYear = year => {
  return (year % 4 === 0 && year % 100 > 100) || year % 400 === 0 ? 366 : 365;
};

export const getDateGivenWeek = (value, addTime = false) => {
  // get the day and the week from the given week
  const splittedWeek = value.split('-');
  const year = parseInt(splittedWeek[0], 10);
  const week = parseInt(splittedWeek[1], 10);

  // inicializa uma data qualquer
  let date = new Date(2022, 5, 1);

  // Modifica ano e semana desta data
  date.setFullYear(year);
  date = setISOWeek(date, week);

  const startWeek = startOfWeek(date);
  const endWeek = endOfWeek(date);
  return addTime
    ? `${format(add(startWeek, { days: 1 }), 'dd/MM/yyyy HH:mm')} ~ ${format(
        add(endWeek, { days: 1 }),
        'dd/MM/yyyy HH:mm',
      )}`
    : `${format(add(startWeek, { days: 1 }), 'dd/MM/yyyy')} ~ ${format(
        add(endWeek, { days: 1 }),
        'dd/MM/yyyy',
      )}`;
};

export const formatShortDate = date => {
  if (date && date !== null) {
    return format(new Date(date), 'dd/MM');
  }
  return '';
};

export const removeTimeZone = date => {
  if (date && date !== null) {
    const data = String(date).split('T')[0].split('-');
    const hora = String(date).split('T')[1].split('.')[0];

    const dia = data[2];
    const mes = data[1];
    const ano = data[0];

    return String(`${dia}/${mes}/${ano} ${hora}`);
  }
  return '';
};

export const weekNumber = date => {
  let week = differenceInCalendarWeeks(
    date,
    new Date(date.getFullYear(), 0, 1),
  );
  return `${date.getFullYear()}-${(week + 1).toString().padStart(2, '0')}`;
};

export const formatRelativeDate = data => {
  if (!data) {
    return '';
  }
  if (typeof data === 'string') data = new Date(data);
  if (!isValid(data)) {
    return 'Data inválida.';
  }
  // Verifica se a data é hoje
  if (isToday(data)) {
    return `Hoje às ${format(data, 'HH:mm')}`;
  }
  // Verifica se a data é ontem
  if (isYesterday(data)) {
    return `Ontem às ${format(data, 'HH:mm')}`;
  }
  // Verifica se a data é amanhã
  if (isTomorrow(data)) {
    return `Amanhã às ${format(data, 'HH:mm')}`;
  }
  // Caso contrário, retorna a data formatada normalmente
  return format(data, 'dd/MM/yyyy HH:mm');
};

export const ptMonths = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

export const ptDays = [
  'Domingo',
  'Segunda-feira',
  'Terça-feira',
  'Quarta-feira',
  'Quinta-feira',
  'Sexta-feira',
  'Sabado',
];
