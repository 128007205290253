import React from 'react';

import { Grid } from '@mui/material';
import TextInput from 'components/Inputs/TextField';
import MapDesvio from 'pages/Desvios/components/MapDesvio';

import { formatNewHourClean } from 'utils/dates';
import { usePlans } from 'hooks/usePlans';
import * as S from '../styled';

const Info = ({ acidente }) => {
  const { isOpLogistico, isProvider } = usePlans();
  const hasImage = acidente?.foto_motorista ?? false;

  return (
    <S.ColumnWrapper>
      <Grid container columnSpacing={2} rowSpacing={1}>
        <Grid
          item
          xs={12}
          sm={12}
          display="flex"
          justifyContent="center"
          position="relative"
        >
          <MapDesvio
            position={
              acidente?.latitude
                ? {
                    lat: parseFloat(acidente.latitude),
                    lng: parseFloat(acidente.longitude),
                  }
                : null
            }
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <S.DriverContainer>
            <div className="info">
              <S.StyledLogoWrapper>
                <S.StyledLogo backgroundImage={hasImage}>
                  {!hasImage && (
                    <h2>
                      {!isProvider || isOpLogistico
                        ? acidente.motorista[0]
                        : acidente.empresa[0]}
                    </h2>
                  )}
                </S.StyledLogo>
              </S.StyledLogoWrapper>

              <S.StyledTextWrapper>
                <h1>
                  {!isProvider || isOpLogistico
                    ? acidente.motorista?.toLowerCase()
                    : acidente.empresa?.toLowerCase()}
                </h1>
                {!isProvider && <p>Filial: {acidente.filial}</p>}
              </S.StyledTextWrapper>
            </div>
            <div className="card">
              Fatalidade:{' '}
              <span
                style={{
                  color: acidente?.fatalidade === 'SIM' ? '#F64E60' : '#1BC5BD',
                }}
              >
                {acidente?.fatalidade}
              </span>
            </div>
          </S.DriverContainer>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextInput
            id="tipo_acidente"
            label="Tipo de acidente"
            value={acidente.tipo_acidente ?? '-'}
            inputProps={{ readOnly: true }}
            variant="filled"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextInput
            id="data_desvio"
            label="Data do acidente"
            value={formatNewHourClean(acidente.data_acidente, true)}
            inputProps={{ readOnly: true }}
            variant="filled"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextInput
            id="agregado"
            label="Agregado"
            value={acidente.agregado ? 'Sim' : 'Não'}
            inputProps={{ readOnly: true }}
            variant="filled"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInput
            id="placa"
            label="Placa"
            value={acidente.placa ?? '-'}
            inputProps={{ readOnly: true }}
            variant="filled"
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <TextInput
            id="localizacao"
            label="Localização"
            value={acidente.localizacao ?? '-'}
            inputProps={{ readOnly: true }}
            variant="filled"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextInput
            id="latitude"
            label="Latitude"
            value={acidente.latitude ?? '-'}
            inputProps={{ readOnly: true }}
            variant="filled"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextInput
            id="longitude"
            label="Longitude"
            value={acidente.longitude ?? '-'}
            inputProps={{ readOnly: true }}
            variant="filled"
          />
        </Grid>

        <Grid item xs={12}>
          <TextInput
            id="causa"
            label="Causa do acidente"
            value={acidente.causa ?? '-'}
            inputProps={{ readOnly: true }}
            variant="filled"
          />
        </Grid>

        <Grid item xs={12}>
          <TextInput
            id="estado_fisico"
            label="Estado físico"
            value={acidente.estado_fisico ?? '-'}
            inputProps={{ readOnly: true }}
            variant="filled"
          />
        </Grid>
      </Grid>
    </S.ColumnWrapper>
  );
};

export default Info;
