import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    background-color: ${theme.palette.system.overlay};
    border: 2px solid ${theme.palette.system.border};
    border-radius: 4px;
    padding: 30px;
    margin-top: 20px;

    .counter {
      color: ${theme.palette.words.subtitle.natural};
      text-align: end;
      margin-top: -20px;
      margin-right: 8px;
      font-size: 12px;
      opacity: 0.8;
      font-weight: 600;
    }
  `}
`;
