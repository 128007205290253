import api from '../../services/api';

const requestGraphData = async (graph, query) => {
  const res = await api.get(`/estatiticas-avaliacoes-direcao/${graph}`, {
    params: query,
  });
  return res;
};

const requestGraphDescarga = async (graph, query) => {
  const res = await api.get(`/estatiticas-avaliacoes-descarga/${graph}`, {
    params: query,
  });
  return res;
};

const requestGraphDesvio = async (graph, query) => {
  const res = await api.get(`/estatisticas-desvios-direcao/${graph}`, {
    params: query,
  });
  return res;
};

const requestDesvioDescarga = async (graph, query) => {
  const res = await api.get(`/estatisticas-desvios-descarga/${graph}`, {
    params: query,
  });
  return res;
};

const requestViolacoes = async (graph, query) => {
  const res = await api.get(`/estatisticas-desvios-violacao/${graph}`, {
    params: query,
  });
  return res;
};

const requestFrota = async query => {
  const res = await api.get(`/estatisticas-frota`, { params: query });
  return res;
};

const requestPad = async (graph, query) => {
  const res = await api.get(`/estatisticas-pad/${graph}`, { params: query });
  return res;
};

const requestLayout = async (graph, query) => {
  const res = await api.get(`/estatisticas-layout/${graph}`, { params: query });
  return res;
};

export {
  requestGraphData,
  requestGraphDescarga,
  requestGraphDesvio,
  requestDesvioDescarga,
  requestViolacoes,
  requestFrota,
  requestPad,
  requestLayout,
};
