import {
  getDesviosTelemetria,
  getVeiculos,
  getCriticidade,
  getCondicoes,
  getCarregamentos,
} from 'constants/_SERVICES/provider';

// Pages
import TelemetriaEstatisticas from 'pages/TelemetriaEstatisticas';
import TelemetriaConfiguracoes from 'pages/TelemetriaConfiguracoes';
import TelemetriaDesviosProvider from 'pages/Provider/TelemetriaDesvios';
import TelemetriaRankingProvider from 'pages/Provider/TelemetriaRanking';
import EditDesvio from 'pages/TelemetriaConfiguracoes/ConfigDesvios/EditDesvio';
import LogTelemetriaConfiguracoes from 'pages/TelemetriaConfiguracoes/Historico';
import LogTelemetriaDesvioProvider from 'pages/Provider/TelemetriaDesvios/Historico';
import DetalheTelemetriaDesviosProvider from 'pages/Provider/TelemetriaDesvios/Detalhe';
import EditVelocidade from 'pages/TelemetriaConfiguracoes/ConfigVelocidades/EditVelocidade';
import EditCercaEletronica from 'pages/TelemetriaConfiguracoes/ConfigCercaEletronica/EditCercaEletronica';

export default {
  '/telemetria/desvios': {
    date: true,
    defaults: ['empresas', 'filials', 'motoristas'],
    pageFilterName: 'filterTelemetriaDesviosProvider',
    filters: [
      {
        name: 'desvio',
        placeholder: 'Filtrar por Desvio',
        mode: 'single',
        data: async () => getDesviosTelemetria(),
      },
      {
        name: 'criticidade',
        placeholder: 'Filtrar por Criticidade',
        mode: 'multiple',
        data: async () => getCriticidade(),
      },
    ],
    page: <TelemetriaDesviosProvider />,
  },

  '/telemetria/desvios/:id': {
    id: true,
    status: true,
    defaults: [],
    title: 'Desvio',
    back: '/telemetria/desvios',
    page: <DetalheTelemetriaDesviosProvider />,
  },

  '/logs/telemetria/desvios/:id': {
    back: -1,
    id: true,
    defaults: [],
    title: 'Histórico do desvio',
    page: <LogTelemetriaDesvioProvider />,
  },

  '/telemetria/ranking': {
    date: true,
    defaults: ['empresas', 'operations', 'filials', 'motoristas'],
    pageFilterName: 'filterTelemetriaRankingProvider',
    filters: [
      {
        name: 'desvio',
        placeholder: 'Filtrar por Desvio',
        mode: 'single',
        section: 'Desvio e Veículo',
        data: async () => getDesviosTelemetria(),
      },
      {
        name: 'veiculo',
        placeholder: 'Filtrar por Tipo de Veículo',
        mode: 'single',
        section: 'Desvio e Veículo',
        data: async () => getVeiculos(),
      },
      {
        name: 'pista_molhada',
        placeholder: 'Filtrar por Condições da Pista',
        mode: 'single',
        section: 'Condições',
        data: async () => getCondicoes(),
      },
      {
        name: 'carregado',
        placeholder: 'Filtrar por Carregamento',
        mode: 'single',
        section: 'Condições',
        data: async () => getCarregamentos(),
      },
    ],
    page: <TelemetriaRankingProvider />,
  },

  '/telemetria/configuracoes': {
    back: -1,
    defaults: [],
    page: <TelemetriaConfiguracoes />,
  },

  '/telemetria/configuracoes/desvios': {
    back: -1,
    defaults: [],
    page: <TelemetriaConfiguracoes />,
  },

  '/telemetria/configuracoes/desvios/criar': {
    back: -1,
    title: 'Adicionar Desvio',
    defaults: [],
    page: <EditDesvio novo />,
  },

  '/telemetria/configuracoes/desvios/:id': {
    back: -1,
    title: 'Editar Desvio',
    defaults: [],
    page: <EditDesvio />,
  },

  '/telemetria/configuracoes/velocidades': {
    back: -1,
    defaults: [],
    page: <TelemetriaConfiguracoes />,
  },

  '/telemetria/configuracoes/velocidades/criar': {
    back: -1,
    title: 'Adicionar Velocidade',
    defaults: [],
    page: <EditVelocidade novo />,
  },

  '/telemetria/configuracoes/velocidades/:id': {
    back: -1,
    title: 'Editar Velocidade',
    defaults: [],
    page: <EditVelocidade />,
  },

  '/telemetria/configuracoes/cerca-eletronica': {
    back: -1,
    defaults: [],
    page: <TelemetriaConfiguracoes />,
  },

  '/telemetria/configuracoes/cerca-eletronica/criar': {
    back: -1,
    defaults: [],
    title: 'Adicionar Cerca Eletrônica',
    page: <EditCercaEletronica novo />,
  },

  '/telemetria/configuracoes/cerca-eletronica/:id': {
    back: -1,
    defaults: [],
    page: <EditCercaEletronica />,
  },

  '/logs/telemetria/configuracoes/desvios/:id': {
    back: -1,
    title: 'Histórico da Configuração do Desvio',
    id: true,
    defaults: [],
    page: <LogTelemetriaConfiguracoes />,
  },

  '/logs/telemetria/configuracoes/velocidades/:id': {
    back: -1,
    title: 'Histórico da Configuração de Velocidade',
    id: true,
    defaults: [],
    page: <LogTelemetriaConfiguracoes />,
  },

  '/logs/telemetria/configuracoes/cerca-eletronica/:id': {
    back: -1,
    title: 'Histórico da Configuração de Cerca Eletrônica',
    id: true,
    defaults: [],
    page: <LogTelemetriaConfiguracoes />,
  },

  '/telemetria/estatisticas': {
    date: true,
    defaults: [],
    page: <TelemetriaEstatisticas />,
  },

  '/telemetria/estatisticas/desempenho-geral': {
    date: true,
    defaults: [],
    page: <TelemetriaEstatisticas />,
  },

  '/telemetria/estatisticas/areas-de-risco': {
    date: true,
    defaults: [],
    page: <TelemetriaEstatisticas />,
  },

  '/telemetria/estatisticas/fatores-de-risco': {
    date: true,
    defaults: [],
    page: <TelemetriaEstatisticas />,
  },

  '/telemetria/estatisticas/tratativa-de-desvios': {
    date: true,
    defaults: [],
    page: <TelemetriaEstatisticas />,
  },
};
