import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { SaveAlt } from '@mui/icons-material';
import { Divider, Grid } from '@mui/material';

// Utils
import { formatNameDate } from 'utils/dates';
import ExportToExcel from 'utils/exportToCvs';

// Components
import GhostButton from 'components/Buttons/Ghost';
import * as S from './styled';

// Modal
import CaronaAutorizadaModal from './Detalhe';

import { columns2 } from './constants';

import { requestCaronas, requestExcel } from './services';
import { DefaultTable } from 'components/_Table/templates/default';
import { useQuery } from 'react-query';

const CaronasAutorizadas = () => {
  // Redux e hooks
  const filter = useSelector(state => {
    return state.filterProvider;
  });

  // TODO Verificar nivel p/exibição
  // const user = useSelector(state => { return state.auth?.user?.user })
  // const userLevel = user.nivel

  const [query, setQuery] = useState(null);
  const [resetTable, setResetTable] = useState(false);
  const [selectedCaronas, setSelectedCaronas] = useState([]);

  // Modal detalhe da Carona
  const [caronaDetalhe, setCaronaDetalhe] = useState(false);
  const [openModalCarona, setOpenModalCarona] = useState(false);

  // -------------------------- Requisições e controles --------------------------

  const initial = useRef(true);
  useEffect(() => {
    if (initial.current) {
      initial.current = false;
      return;
    }

    setResetTable(true);
  }, [filter]);

  const { isLoading, data: resData } = useQuery(
    ['caronas-auto-index', { ...query }],
    () => query && requestCaronas(query),
    {
      refetchOnWindowFocus: false,
      onSuccess: () => {
        resetTable && setResetTable(false);
      },
    },
  );

  const handleRequestExcel = async () => {
    const formatedDate = formatNameDate(new Date());

    const newQuery = { ...filter };

    const res = await requestExcel({ ...newQuery, ids: selectedCaronas });
    if (res.data && res.data?.data?.excel) {
      ExportToExcel({
        excel: res.data.data.excel,
        name: `caronas_autorizadas_${formatedDate}`,
      });
      toast.success(res.data.message);
    } else if (res.data.message) toast.error(res.data?.message);
  };

  // ********************  Actions *****************************
  const handleOpenDetailModal = async id => {
    const data = resData?.data?.data || [];
    const carona = data.filter(carona => carona.id === id)[0];
    setCaronaDetalhe(carona);
    setOpenModalCarona(true);
  };

  const handleCloseDetailModal = () => {
    setCaronaDetalhe(null);
    setOpenModalCarona(false);
  };

  //  ------------------   RENDERIZACAO --------------------------------

  return (
    <>
      <S.Main>
        <Grid
          marginBottom={1}
          item
          md={12}
          display="flex"
          justifyContent="space-between"
        >
          <h1>Caronas Autorizadas</h1>

          <GhostButton
            startIcon={<SaveAlt />}
            size="medium"
            onClick={() => handleRequestExcel}
            style={{ marginLeft: '10px' }}
          >
            EXPORTAR
          </GhostButton>
        </Grid>

        <Grid marginBottom={3} item md={12}>
          <Divider />
        </Grid>

        <DefaultTable
          data={resData?.data?.data || []}
          columns={columns2}
          loading={isLoading}
          pageCount={resData?.data?.total || 0}
          local={false}
          reset={resetTable}
          onClickRow={handleOpenDetailModal}
          setQuery={q =>
            setQuery({
              ...q,
              ...filter,
            })
          }
          sortBy={{ id: 'data_ocorrencia', order: 'DESC' }}
          placeholder="Buscar ID, placa..."
          empty={{
            title: 'Ops! Você não tem nenhuma carona registrada.',
            description: 'Verifique os filtros selecionados.',
          }}
        />

        {openModalCarona && (
          <CaronaAutorizadaModal
            carona={caronaDetalhe}
            setCloseModal={handleCloseDetailModal}
          />
        )}
      </S.Main>
    </>
  );
};

export default CaronasAutorizadas;
