export const avaliacaoDirecao = [
  { header: 'Período', id: 'periodo' },
  {
    header: 'Status',
    id: 'status',
  },
  {
    header: 'QTDE Total',
    id: 'value',
  },
];

export const avaliacaoDescarga = [
  { header: 'Período', id: 'periodo' },
  {
    header: 'Status',
    id: 'status',
  },
  {
    header: 'QTDE Total',
    id: 'value',
  },
];

export const Desvios = [
  { header: 'Período', id: 'periodo' },
  {
    header: 'Desvio',
    id: 'desvio',
  },
  {
    header: 'Qtde Total',
    id: 'total',
  },
  {
    header: 'Qtde Abertos',
    id: 'abertos',
  },
  {
    header: 'Qtde Pendentes',
    id: 'pendente',
  },
  {
    header: 'Qtde Finalizados',
    id: 'finalizados',
  },
  {
    header: 'Qtde Excluídos',
    id: 'deletados',
  },
];

export const Formularios = [
  { header: 'Período', id: 'periodo' },
  {
    header: 'Status',
    id: 'status',
  },
  {
    header: 'QTDE Total',
    id: 'value',
  },
];

export const AcoesSuspensoes = [
  { header: 'Período', id: 'periodo' },
  {
    header: 'Status',
    id: 'status',
  },
  {
    header: 'QTDE Total',
    id: 'value',
  },
];
