import api from 'services/api';

export const getParametrizacoes = async () => {
  const res = await api.get('/provider/configuracoes/parametrizacoes');
  return res.data;
};

export const updateParametrizacao = async data => {
  const res = await api.put(
    `/provider/configuracoes/update-parametrizacoes`,
    data,
  );
  return res.data;
};

export const updateSubParametrizacaoProvider = async data => {
  const res = await api.put(
    `provider/configuracoes/update-sub-parametrizacoes`,
    data,
  );
  return res.data;
};

export const requestLogs = async data => {
  const res = await api.get('/provider/configuracoes/log-parametrizacoes');
  return res.data;
};
