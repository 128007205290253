import colors from 'styles/colors';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WorkspacePremiumOutlinedIcon from '@mui/icons-material/WorkspacePremiumOutlined';

export default [
  {
    title: 'Parabéns! Você foi aprovado(a)!',
    Icon: () => <CheckCircleIcon htmlColor={colors.greenSucces} />,
  },
  {
    title: 'Infelizmente você foi reprovado(a)!',
    Icon: () => <CancelIcon htmlColor={colors.redDanger} />,
  },
  {
    title: 'Conteúdo concluído com sucesso!',
    Icon: () => <WorkspacePremiumOutlinedIcon htmlColor={colors.greenMain} />,
  },
];
