import styled, { css } from 'styled-components';

export const Card = styled.div`
  ${({ theme }) => css`
    width: 100%;
    min-width: 15rem;
    max-width: 90%;
    margin: 0 auto;
    text-align: left;
    display: flex;
    flex-direction: column;
    background: ${theme.palette.system.overlay};
    border: solid 1px ${theme.palette.system.border};
    border-radius: 4px;
    padding: 2rem;

    p {
      font: normal normal 400 18px/25px Texta;
      color: ${theme.palette.words.subtitle.natural};
      flex: 1;
      margin-bottom: 1rem;
    }
  `}
`;

export const Title = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    font: normal normal 900 24px/33px Texta;
    color: ${theme.palette.words.title.natural};

    img {
      width: 2rem;
      height: 2rem;
      margin-right: 1rem;
    }
  `}
`;
