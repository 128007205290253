// Styled

// React
import { useEffect, useState } from 'react';

// Components

// Components MUI
import { Divider } from '@mui/material';
import SelectOne from 'components/Inputs/SelectOne';
import { OutQuestion } from '..';
import * as S from './styled';

// Utils
import { changeId } from '../../actions';

export const SelectSearch = ({
  field,
  secoes,
  setSecoes,
  sectionId,
  errosObrigatorios,
  preview,
  previewWeb,
  selects,
}) => {
  const redirectFunction = (executable, idCampo, texto) => {
    setCleanField(true);
    const resp = selects[field.id_tipo_campo].find(item => item.id == texto);
    executable(texto, idCampo, setSecoes, secoes, sectionId, resp.nome);
  };

  const [label, setLabel] = useState('');
  useEffect(() => {
    if (field) {
      switch (field?.id_tipo_campo) {
        case 19:
          setLabel('Selecione uma Placa');
          break;
        case 20:
          setLabel('Selecione uma Gestor');
          break;
        case 21:
          setLabel('Selecione um Motorista');
          break;
        case 25:
          setLabel('Selecione um Modelo de Veículo');
          break;
        case 26:
          setLabel('Selecione uma Frota');
          break;
        default:
          setLabel('Selecione uma opção');
      }
    }
  }, [field]);

  // ------------------------------------ERROS-------------------------------------------//
  const [hasErrorObrigatorio, setHasErrorObrigatorio] = useState(false);
  const [cleanField, setCleanField] = useState(false);

  useEffect(() => {
    // Reset fields
    setHasErrorObrigatorio(false);
    setCleanField(false);

    if (errosObrigatorios) {
      for (const i in errosObrigatorios) {
        const erros = errosObrigatorios[i];
        if (sectionId === erros.idSecao && field.id === erros.idCampo) {
          setHasErrorObrigatorio(true);
        }
      }
    }
  }, [errosObrigatorios]);

  return (
    <>
      <S.QuestionBox
        hasError={hasErrorObrigatorio && !cleanField}
        className={`fieldClass-${field.id}`}
      >
        <OutQuestion
          field={field}
          secoes={secoes}
          setSecoes={setSecoes}
          sectionId={sectionId}
          hasError={hasErrorObrigatorio && !cleanField}
          preview={preview}
        />
        <S.ResponseArea preview={preview}>
          <SelectOne
            value={field.id_resposta}
            data={
              !preview && !previewWeb
                ? selects[field.id_tipo_campo]?.map(item => ({
                    value: item.id,
                    text: item.nome,
                  }))
                : []
            }
            handleChange={value => redirectFunction(changeId, field.id, value)}
            label={label}
            required={field.obrigatorio}
            error={hasErrorObrigatorio && !cleanField}
          />
        </S.ResponseArea>
      </S.QuestionBox>
      <Divider />
    </>
  );
};
