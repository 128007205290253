// Icons
import { ReactComponent as item2 } from 'images/capacitacao/publico.svg';
import { ReactComponent as item3 } from 'images/capacitacao/gerais.svg';
import { ReactComponent as user } from 'images/icons/sidebar/user.svg';
import { ReactComponent as assinatura } from 'images/icons/avaliacao/assinatura.svg';
import { ReactComponent as desvios } from 'images/icons/avaliacao/desvios.svg';
import { ReactComponent as historico } from 'images/icons/avaliacao/historico.svg';
import { ReactComponent as formulario } from 'images/formulario/formulario.svg';

// Components
import { Gerais } from '../Gerais';
import { Local } from '../Local';
import { Formulario } from '../Formulario';
import { Evidencias } from '../Evidencias';
import { Assinatura } from '../Assinatura';
import { Cae } from '../Cae';
import { Historico } from '../Historico';

export const itensBar = [
  {
    id: 0,
    page: 'Informações Gerais',
    linkTo: '',
    icon: user,
    Component: params => Gerais(params),
  },
  {
    id: 1,
    page: 'Local e Tanques',
    linkTo: '',
    icon: item3,
    Component: params => Local(params),
  },
  {
    id: 2,
    page: 'Checklist',
    linkTo: '',
    icon: formulario,
    Component: params => Formulario(params),
  },
  {
    id: 3,
    page: 'Evidências',
    linkTo: '',
    icon: item2,
    Component: params => Evidencias(params),
  },
  {
    id: 4,
    page: 'Assinaturas',
    linkTo: '',
    icon: assinatura,
    Component: params => Assinatura(params),
  },
  {
    id: 5,
    page: 'CAE',
    linkTo: '',
    icon: desvios,
    Component: params => Cae(params),
  },
  {
    id: 6,
    page: 'Histórico de Alterações',
    linkTo: '',
    icon: historico,
    Component: params => Historico(params),
  },
];
