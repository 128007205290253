import styled from 'styled-components';

const Container = styled.div`
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
    display: flex;
    align-items: center;
  }
`;

const Label = styled.p`
  color: ${({ theme, color }) => color || theme.palette.words.label.natural};
  font: normal medium 14px/20px Texta;
  margin-bottom: 5px;
`;

const Required = styled.span`
  font-size: 20px;
  font-weight: 500;
  color: ${({ theme, ...props }) =>
    props.active
      ? theme.palette.semantics.feedback.attention.dark
      : 'transparent'};
`;

export { Label, Required, Container };
