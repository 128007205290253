import { formatedOperation } from './constants';

export const columns = [
  {
    header: 'Empresa',
    id: 'filial.nome',
    type: 'string',
    sort: true,
    tooltip: true,
  },
  {
    header: 'Cliente',
    id: 'distribuidora.nome',
    type: 'string',
    tooltip: true,
    sort: false,
  },
  {
    header: 'Operação',
    id: 'id_tipo_operacao',
    type: 'string',
    tooltip: true,
    value: value => formatedOperation(value),
    sort: false,
  },
  {
    header: 'Origem',
    id: 'origem',
    type: 'string',
    sort: true,
    tooltip: true,
  },
  {
    header: 'Destino',
    id: 'destino',
    type: 'string',
    sort: true,
    tooltip: true,
  },
  {
    header: 'Responsável',
    id: 'usuario.nome',
    type: 'string',
    tooltip: true,
    sort: true,
  },
  {
    header: 'Observação',
    id: 'observacao',
    type: 'string',
    sort: true,
    tooltip: true,
  },
  {
    header: 'Modificado em',
    id: 'updatedAt',
    type: 'date',
    sort: true,
    align: 'center',
  },
  {
    header: 'Vencimento',
    id: 'updatedAt',
    type: 'date',
    sort: true,
    align: 'center',
    value: (_, item) => {
      const date = new Date(item?.updatedAt);
      date.setFullYear(date.getFullYear() + 1);
      return new Date(date).toLocaleDateString('pt-BR');
    },
    conditional: [
      {
        condition: value => {
          const today = new Date().getTime();
          const date = new Date(value);
          const updated = new Date(
            date?.setFullYear(date?.getFullYear() + 1),
          ).getTime();
          return today <= updated;
        },
        style: theme => ({
          color: theme.palette.semantics.feedback.success.natural,
          backgroundColor: theme.palette.semantics.feedback.success.light,
          textAlign: 'center',
        }),
      },
      {
        condition: value => {
          const today = new Date().getTime();
          const date = new Date(value);
          const updated = new Date(
            date?.setFullYear(date?.getFullYear() + 1),
          ).getTime();
          return today > updated;
        },
        style: theme => ({
          color: theme?.palette?.semantics?.feedback?.attention?.natural,
          backgroundColor:
            theme?.palette?.semantics?.feedback?.attention?.light,
          textAlign: 'center',
        }),
      },
    ],
  },
];
