import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { toast } from 'react-toastify';
import * as API from '../services';

// components
import { DeleteOutline } from '@mui/icons-material';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { IconButton } from '@mui/material';
import Divider from '@mui/material/Divider';
import GhostButton from 'components/Buttons/Ghost';
import DefaultButton from 'components/Buttons/Default';
import ConfirmModal from 'components/ConfirmModal';
import { Switch } from 'components/Inputs/Switch';
import Select from './components/SelectMultiple';
import SelectDesvios from './components/SelectMultipleDesvios';

// styles
import * as S from './styled';
import { styled } from '@mui/material/styles';

// icons
import { ReactComponent as BtnAdd } from 'images/icons/configuracoes/icone-adicionar.svg';
import { ReactComponent as EditarIcon } from 'images/icons/configuracoes/icone-editar.svg';
import { ReactComponent as HistoricoIcon } from 'images/icons/sidebar/historico.svg';
import { ReactComponent as IconSub } from 'images/escalation-list/icone-subtrair.svg';
import { ReactComponent as IconOnisys } from 'images/escalation-list/marca-onisys.svg';
import { ReactComponent as IconEmail } from 'images/escalation-list/icone-email.svg';
import { ReactComponent as IconDeslocamentos } from 'images/escalation-list/icone-lista-deslocamentos.svg';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { requestNiveis } from 'pages/Configuracoes/Usuarios/services';

const initialStep = {
  prazo: 0,
  plataforma: false,
  push: false,
  email: false,
  ids_usuarios: [],
};

const CustomStepIcon = styled('div')(({ ownerState, steps }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor:
    ownerState.index === 0 || ownerState.index === steps.length - 1
      ? '#4B5166'
      : '#EDF2F9',
  color:
    ownerState.index === 0 || ownerState.index === steps.length - 1
      ? '#ffff'
      : '#4B5166',
  width: 33,
  fontWeight: 900,
  height: 33,
  borderRadius: '50%',
  transform: 'translateX(-4px)',
}));

const CustomStepIconComponent = props => {
  const { steps, active, completed, className, icon, handleRemoveStep } = props;

  return (
    <>
      <div style={{ position: 'absolute', transform: 'translate(-50px, 0px)' }}>
        {steps.length > 1 && icon - 1 === steps.length - 1 && (
          <IconButton onClick={handleRemoveStep}>
            <IconSub />
          </IconButton>
        )}
      </div>
      <CustomStepIcon
        ownerState={{ completed, active, index: icon - 1 }}
        className={className}
        steps={steps}
      >
        {icon}
      </CustomStepIcon>
    </>
  );
};

export const NovaLista = () => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();

  const isDefault = location.state.item?.is_default ?? false;
  const [activeStep, setActiveStep] = useState(0);
  const [title, setTitle] = useState('');
  const [status, setStatus] = useState('ATIVO');
  const [risco, setRisco] = useState('');
  const [grupos, setGrupos] = useState([]);
  const [desvioTipoPadrao, setDesvioTipoPadrao] = useState([]);
  const [desvioTipo, setDesvioTipo] = useState([]);
  const [desviosOptions, setDesviosOptions] = useState([]);
  const [errors, setErrors] = useState({});
  const [deleteModal, setDeleteModal] = useState(null);
  const [selectDesviosOptions, setSelectDesviosOptions] = useState([]);
  const [sending, setSending] = useState(false);

  const [steps, setSteps] = useState([initialStep]);

  const selects = useSelector(state => state.selects);
  const users = selects.users.map(user => ({
    id: user.id,
    value: user.nome,
    grupo: user.nivel,
  }));

  const handleExluir = async () => {
    if (location?.state?.item?.id) {
      const res = await API.deleteEscalation(location.state.item.id);

      if (res?.success) toast.success(res.message);
      else {
        toast.error(res?.message ?? 'Falha ao excluir.');
        return;
      }
    }
    navigate(-1);
  };

  const checkValid = data => {
    setErrors({});
    const _errors = {};
    if (!data.is_default && !data.titulo) {
      _errors.titulo = 'Campo obrigatório.';
    }
    if (!data.ids_desvios?.length) {
      if (data.is_default)
        toast.error(
          'Necessário ter configuração de desvios com tipos de desvios padrões altos ou altíssimos.',
        );
      _errors.ids_desvios = 'Campo obrigatório.';
    }

    for (const index in data.etapas) {
      if (index > 0 && !data.etapas[index].prazo) {
        _errors[`etapas[${index}].prazo`] = 'Campo obrigatorio';
      }
      if (!data.etapas[index].ids_usuarios?.length) {
        _errors[`etapas[${index}].ids_usuarios`] = 'Campo obrigatorio';
      }
    }

    if (Object.getOwnPropertyNames(_errors).length) {
      setErrors(_errors);
      return false;
    }
    return true;
  };

  const handleSave = async () => {
    const payload = {
      ...location.state.item,
      titulo: title,
      is_default: isDefault,
      risco,
      ids_desvios: selectDesviosOptions,
      etapas: steps,
      status,
    };

    if (checkValid(payload)) {
      setSending(true);
      const res = await API.saveEscalation(payload);
      if (res.status === 200) toast.success('Lista salva com sucesso');
      setSending(false);
      navigate(-1);
    }
  };

  useQuery(['niveis-usuario'], () => requestNiveis(), {
    onSuccess: (data = []) => {
      setGrupos(data.map(item => ({ value: item.id, label: item.titulo })));
    },
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (location?.state) {
      setDesvioTipoPadrao(location.state.desviosTiposPadroes);
      setDesvioTipo(location.state.desviosTipos);
      let desvioOptions = location.state.desviosTipos;
      desvioOptions = desvioOptions.map(item => {
        return {
          id: item.id,
          criticidade: item.criticidade,
          value: item?.titulo || '',
        };
      });
      setDesviosOptions(desvioOptions);

      if (location.state.item) {
        const { titulo, risco, status, ids_desvios, is_default, etapas } =
          location.state.item || '';
        setRisco(risco);
        setTitle(titulo);
        setStatus(status ?? 'ATIVO');

        setSelectDesviosOptions(
          is_default
            ? location.state.desviosTiposPadroes.map(dt => dt.id)
            : ids_desvios,
        );
        if (etapas?.length) setSteps([...etapas]);
      }
    }
  }, [location]);

  const formatArrayString = (isDefault, array, ids) => {
    if (isDefault) {
      let tmp = array;
      tmp = tmp.map(item => item.titulo);

      tmp.toString();
      tmp = tmp.join(' , ');
      tmp = tmp.replaceAll(',', '/');
      return `${tmp}.`;
    }
    if (array && ids) {
      let result = array.filter(item => ids && !ids.includes(item.id));
      result.toString();
      result = result.map(item => item.titulo);
      result = result.join(' , ');
      result = result.replaceAll(',', '/');
      return `${result}.`;
    }
  };

  const filterArray = (array, ids) => {
    let result = array.filter(item => ids && ids.includes(item.id));
    return result || [];
  };

  const handleAddStep = () => {
    setSteps(prevSteps => [...prevSteps, { ...initialStep }]);
  };

  const handleRemoveStep = () => {
    setSteps(prevSteps => prevSteps.slice(0, -1));
  };

  const handleRemoveDesvio = id => {
    setSelectDesviosOptions(ids => ids.filter(item => item !== id));
  };

  const handleRemoveUsuario = (index, id) => {
    const newSteps = steps.map((step, stepIndex) =>
      stepIndex === index
        ? {
            ...step,
            ids_usuarios: step.ids_usuarios.filter(item => item !== id),
          }
        : step,
    );
    setSteps(newSteps);
  };

  const handlePeopleChange = (index, selectedPeople) => {
    const newSteps = steps.map((step, stepIndex) =>
      stepIndex === index ? { ...step, ids_usuarios: selectedPeople } : step,
    );
    setSteps(newSteps);
  };

  const handleNotificationTypeChange = (index, notificationType) => {
    const newSteps = steps.map((step, stepIndex) => {
      if (stepIndex === index) {
        return { ...step, [notificationType]: !step[notificationType] };
      }
      return step;
    });
    setSteps(newSteps);
  };

  const handleTimeChange = (index, prazo) => {
    const newSteps = steps.map((step, stepIndex) =>
      stepIndex === index ? { ...step, prazo } : step,
    );
    setSteps(newSteps);
  };

  return (
    <>
      <S.Container>
        <>
          <div className="container-header">
            {isDefault ? (
              <div className="risco-title">
                Onisys -{' '}
                <span
                  className={
                    risco !== null
                      ? risco === 'ALTÍSSIMO'
                        ? `altissimo`
                        : 'alto'
                      : ''
                  }
                >
                  {title}
                </span>
              </div>
            ) : (
              <div className="new-list">
                <input
                  className="input-title"
                  type="text"
                  placeholder="Nova Lista"
                  value={title}
                  onChange={e => setTitle(e.target.value)}
                />
                <EditarIcon />
                {!!errors.titulo && <span>{errors.titulo}</span>}
              </div>
            )}
            {!!location?.state?.item?.id && (
              <div>
                <GhostButton
                  startIcon={<HistoricoIcon />}
                  size="medium"
                  onClick={() =>
                    navigate(
                      `/logs/configuracoes/escalation-list/${location.state.item.id}`,
                    )
                  }
                  sx={{ marginRight: '1rem' }}
                >
                  HISTÓRICO DE ALTERAÇÕES
                </GhostButton>
              </div>
            )}
          </div>
          <Divider />
          <div>
            <div style={{ marginTop: 15 }}>
              <Switch
                check={status === 'ATIVO'}
                setCheckbox={value => setStatus(value ? 'ATIVO' : 'INATIVO')}
                disabled={false}
                textOn="Lista ativa"
                textOff="Lista ativa"
              />
            </div>
            <div className="select-header">
              <div className="title-header">Emitir alertas para:</div>
              {!isDefault ? (
                <>
                  <SelectDesvios
                    width="350px"
                    data={desviosOptions}
                    value={selectDesviosOptions}
                    handleChange={setSelectDesviosOptions}
                    error={!!errors.ids_desvios}
                    message={errors.ids_desvios}
                  />

                  {filterArray(desvioTipo, selectDesviosOptions).map(item => (
                    <div key={item.id} className="label-hilight">
                      {item.titulo}{' '}
                      <span onClick={() => handleRemoveDesvio(item.id)}>X</span>
                    </div>
                  ))}
                </>
              ) : (
                <div className="desvios-padroes">
                  Para o rastreamento de deslocamentos de risco, são analisados
                  dados referentes aos desvios que são os maiores geradores de
                  acidentes, incluindo{' '}
                  <span>
                    {' '}
                    {formatArrayString(
                      isDefault,
                      isDefault ? desvioTipo : desvioTipoPadrao,
                      location?.state?.item.ids_desvios,
                    )}
                  </span>
                </div>
              )}
            </div>
          </div>
          <Divider />
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((step, index) => (
              <Step key={index}>
                <StepLabel
                  StepIconComponent={props => (
                    <CustomStepIconComponent
                      steps={steps}
                      handleRemoveStep={handleRemoveStep}
                      {...props}
                    />
                  )}
                >
                  <>
                    {index > 0 && (
                      <div className="container-step">
                        <div className="label-step">
                          Caso não seja resolvido em até
                        </div>

                        <div className="select-time">
                          <div className="container-btn-time">
                            <button
                              className="btn-time"
                              onClick={() =>
                                handleTimeChange(index, step.prazo + 1)
                              }
                              disabled={step.prazo >= 60}
                            >
                              +
                            </button>
                            <button
                              className="btn-time"
                              onClick={() =>
                                handleTimeChange(index, step.prazo - 1)
                              }
                              disabled={step.prazo <= 0}
                            >
                              -
                            </button>
                          </div>
                          <div>
                            <input
                              className={`display-time ${
                                !!errors[`etapas[${index}].prazo`] && 'error'
                              }`}
                              value={step.prazo}
                              readOnly
                            />{' '}
                            min
                          </div>
                          {!!errors[`etapas[${index}].prazo`] && (
                            <span>{errors[`etapas[${index}].prazo`]}</span>
                          )}
                        </div>
                      </div>
                    )}
                    <div className="container-step">
                      <div className="label-step">Alerta é enviado para</div>
                      <div>
                        <Select
                          fieldName="Atribuir Responsável"
                          grupos={grupos}
                          data={users}
                          value={step.ids_usuarios}
                          handleChange={selectedPeople =>
                            handlePeopleChange(index, selectedPeople)
                          }
                          error={!!errors[`etapas[${index}].ids_usuarios`]}
                          message={errors[`etapas[${index}].ids_usuarios`]}
                        />
                      </div>
                      <div
                        className={`btn-config-push ${
                          step.plataforma ? 'active' : ''
                        }`}
                        onClick={() =>
                          handleNotificationTypeChange(index, 'plataforma')
                        }
                      >
                        <IconOnisys />
                        Plataforma
                      </div>
                      <div
                        className={`btn-config-push ${
                          step.push ? 'active' : ''
                        }`}
                        onClick={() =>
                          handleNotificationTypeChange(index, 'push')
                        }
                      >
                        <IconDeslocamentos />
                        Push
                      </div>
                      <div
                        className={`btn-config-push ${
                          step.email ? 'active' : ''
                        }`}
                        onClick={() =>
                          handleNotificationTypeChange(index, 'email')
                        }
                      >
                        <IconEmail />
                        E-mail
                      </div>
                    </div>
                    <div style={{ display: 'flex', gap: 8, marginTop: 5 }}>
                      {filterArray(users, step.ids_usuarios).map(p => (
                        <div key={p.id} className="label-hilight">
                          {p.grupo
                            ? `(${
                                grupos.find(g => g.value === p.grupo)?.label
                              })`
                            : ''}
                          {p.value}{' '}
                          <span
                            onClick={() => handleRemoveUsuario(index, p.id)}
                          >
                            X
                          </span>
                        </div>
                      ))}
                    </div>
                  </>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </>
        <div className="container-actions">
          <IconButton onClick={handleAddStep}>
            <BtnAdd />
          </IconButton>
          <div className="btn-add" onClick={handleAddStep}>
            Adicionar etapa
          </div>
        </div>
        <Divider sx={{ marginTop: 5 }} />
        <div style={{ marginTop: 20, display: 'flex', justifyContent: 'end' }}>
          <GhostButton
            size="medium"
            onClick={() => setDeleteModal(true)}
            sx={{ marginRight: '1rem' }}
          >
            EXCLUIR
          </GhostButton>
          <DefaultButton size="medium" onClick={handleSave} loading={sending}>
            SALVAR{!location.state.item?.id ? ` E ATIVAR` : ''}
          </DefaultButton>
        </div>
      </S.Container>
      {!!deleteModal && (
        <ConfirmModal
          open={!!deleteModal}
          handleClose={() => setDeleteModal(null)}
          title="Tem certeza que deseja remover essa lista?"
          titleIcon={
            <DeleteOutline
              sx={{
                color: theme.palette.semantics.feedback.attention.natural,
              }}
              fontSize="medium"
            />
          }
          buttonText="Confirmar"
          onClick={() => {
            handleExluir();
            setDeleteModal(null);
          }}
        />
      )}
    </>
  );
};
