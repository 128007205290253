import React from 'react';
import { Tabs } from '@mui/material';
import { Info } from '@mui/icons-material';
import * as S from './styled';

// items = [ {value: any, label: string}, ... ]
const ColorTabs = ({
  handleInfo,
  value,
  onChange,
  items,
  width,
  last,
  disabled,
  ...props
}) => {
  return (
    <S.Wrapper width={width}>
      <Tabs value={value} onChange={onChange} {...props}>
        {items &&
          items.map((i, idx) => (
            <S.StyledTab
              key={i.value}
              value={i.value}
              disabled={disabled}
              className={last && items.length - 1 === idx ? 'last' : ''}
              label={
                !handleInfo ? (
                  <S.Span>
                    <h3>{i.label}</h3>
                  </S.Span>
                ) : (
                  <S.Span>
                    <h3>{i.label}</h3>
                    <S.Div />
                    <Info onClick={() => handleInfo(i)} />
                  </S.Span>
                )
              }
            />
          ))}
      </Tabs>
    </S.Wrapper>
  );
};

export default ColorTabs;
