import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';

// COMPONENTS
import Select from 'components/Inputs/Select';
import ConfirmModal from 'components/ConfirmModal';
import DefaultButton from 'components/Buttons/Default';

// MATERIAL UI
import { Divider } from '@mui/material';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';

// UTILS
import { useSelector } from 'react-redux';
import { formatNewDate } from 'utils/dates';

import * as S from './styled';
import * as services from '../services';

export const Assinatura = ({
  disabled,
  posto,
  aprovadores,
  setLoading,
  setPosto,
  id,
}) => {
  // User Info
  const { user } = useSelector(state => state.auth.user);

  // Variables for conditional checks
  const executor = posto?.executor;
  const aprovador = posto?.aprovador;

  const assExecutor = posto?.data_assinatura_executor;
  const assAprovador = posto?.data_assinatura_aprovador;

  const [approveModal, setApproveModal] = useState(false);
  const [apName, setApName] = useState(aprovador ? aprovador.nome : '');
  const [currentAp, setCurrentAp] = useState(aprovador ? aprovador.id : '');

  // ---------------------------REQUESTS----------------------------------//

  // ASS APROVADOR
  const handleSignatureApprove = async () => {
    if (!assExecutor) {
      toast.error(
        'Não é possível assinar como aprovador sem a assinatura do executor',
      );
      return;
    }

    setLoading(true);
    const res = await services.signAprovador(posto?.id);
    setLoading(false);
    if (res.success) {
      toast.success(res.message);
      setPosto({ ...posto, ...res?.posto?.[0] });
    } else {
      toast.error(res.message);
    }
  };

  // ASS EXECUTOR
  const handleSignature = async () => {
    setLoading(true);
    const res = await services.signExecutor(posto?.id);
    setLoading(false);
    if (res.success) {
      toast.success(res.message);
      setPosto({ ...posto, ...res?.posto, executor: user });
    } else {
      toast.error(res.message);
    }
  };

  // ---------------------------CONTROL APPROVE MODAL----------------------------------//
  const handleApproveModal = value => {
    setApproveModal(true);
    const ap = aprovadores.find(i => value === i.id);
    setApName(ap);
  };

  const clearModalApprove = () => {
    setApproveModal(false);
    setApName(aprovador ? aprovador.nome : '');
  };

  const handleSelectApproverEvaluation = async () => {
    if (!apName) {
      toast.error('Selecione um aprovador');
      return;
    }

    if (!assExecutor) {
      toast.error(
        'Não é possível selecionar um aprovador sem a assinatura do executor',
      );
      return;
    }

    if (executor?.id === apName?.id) {
      toast.error('O Avaliador e o aprovador não podem ser iguais.');
      return;
    }

    setLoading(true);
    setApproveModal(false);
    const res = await services.saveUpdate(
      {
        ...posto,
        id_do_aprovador: apName?.id,
      },
      id,
    );
    setLoading(false);

    if (res.success) {
      toast.success(res.message);
      setPosto({ ...posto, ...res?.posto });
    } else {
      toast.error(res.message);
    }
  };

  return (
    <S.Container>
      <div className="avaliador">
        <div className="headerBox">
          <S.Title>Assinatura Executor</S.Title>
        </div>
        <S.signedBox>
          <div className="imageArea">
            {(executor?.foto || (user?.foto && !executor)) && (
              <img src={executor?.foto || user?.foto} />
            )}
            {!executor && !user?.foto && (
              <span className="letters">{user.nome[0]}</span>
            )}
            {executor && !executor?.foto && (
              <span className="letters">{executor.nome[0]}</span>
            )}
          </div>

          <div className="textArea">
            <span className="userName">
              {executor ? executor.nome : user.nome}
            </span>
            <span className="status">
              {assExecutor
                ? formatNewDate(assExecutor)
                : 'Aguardando Assinatura'}
            </span>
          </div>
        </S.signedBox>
        {!assExecutor && (
          <DefaultButton
            style={{ width: '100%' }}
            onClick={() => handleSignature()}
          >
            <ModeEditOutlineOutlinedIcon />
            Assinar e Salvar
          </DefaultButton>
        )}

        {assExecutor && (
          <DefaultButton style={{ width: '100%' }} disabled>
            <ModeEditOutlineOutlinedIcon />
            Layout assinado
          </DefaultButton>
        )}
      </div>
      <Divider style={{ margin: '20px 0 20px 0 ' }} />
      <div className="testemunha">
        <div className="headerBox">
          <S.Title>Assinatura Aprovador</S.Title>
        </div>
        {aprovador && (
          <S.signedBox>
            <div className="imageArea">
              {aprovador?.foto && <img src={aprovador.foto} />}
              {aprovador?.foto === null && (
                <span className="letters">{aprovador.nome[0]}</span>
              )}
            </div>
            <div className="textArea">
              <span className="userName">{aprovador.nome}</span>
              <span className="status">
                {assAprovador
                  ? formatNewDate(assAprovador)
                  : 'Aguardando Assinatura'}
              </span>
            </div>
          </S.signedBox>
        )}

        {!assAprovador && (
          <Select
            data={aprovadores?.map(i => ({ value: i.id, name: i.value })) || []}
            value={currentAp || aprovador?.id || ''}
            label="Selecionar Aprovador"
            handleChange={e => handleApproveModal(e.target.value)}
            markselected
            disabled={disabled}
          />
        )}

        {!assAprovador && aprovador?.id === user.id && (
          <DefaultButton
            style={{ width: '100%', marginTop: '20px' }}
            onClick={() => handleSignatureApprove()}
          >
            <ModeEditOutlineOutlinedIcon />
            Assinar e Salvar
          </DefaultButton>
        )}
      </div>

      {/*  Modal Area */}
      {approveModal && (
        <ConfirmModal
          handleClose={() => clearModalApprove()}
          open={approveModal}
          title={
            aprovador
              ? 'Tem certeza que deseja alterar o aprovador?'
              : 'Tem certeza que deseja adicionar um aprovador?'
          }
          subtitle={
            aprovador
              ? `O aprovador será alterado de ${aprovador?.nome} para ${apName?.value}`
              : `O aprovador ${apName?.value} será adicionada para o layout`
          }
          buttonText="Confirmar"
          onClick={() => handleSelectApproverEvaluation()}
        />
      )}
    </S.Container>
  );
};
