import { useEffect, useState } from 'react';
import * as S from './styled';
import * as api from './services';

// Components
import Tabs from 'components/TabsSlider';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import SelectMultiple from './components/SelectMultiple';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Switch } from 'components/Inputs/Switch';
import Loading from 'components/Loading';

export const Notificacoes = () => {
  // Notifications
  const [allNotificationsPermissions, setAllNotificationsPermissions] =
    useState([]);
  const [tabs, setTabs] = useState([]);
  const [currentTab, setCurrentTab] = useState(null);
  const [currentSelection, setCurrentSelection] = useState([]);
  const [loading, setLoading] = useState(false);

  const [switchStates, setSwitchStates] = useState({});

  const fetchPermissionsNotifications = async () => {
    setLoading(true);
    const res = await api.getNotifications();
    setAllNotificationsPermissions(res?.data);
    const t = Object.keys(res?.data || []).map(item => ({
      label: item,
      value: item,
    }));
    setCurrentTab(t[0]?.value);
    setTabs(t);
    setCurrentSelection(res?.data[t[0]?.value]);
    setLoading(false);
  };

  useEffect(() => {
    fetchPermissionsNotifications();
  }, []);

  useEffect(() => {
    setCurrentSelection(allNotificationsPermissions[currentTab]);
  }, [currentTab]);

  const handlePageTab = (event, newValue) => {
    setCurrentTab(newValue);
  };

  function hexToRGBA(hex, opacity) {
    hex = hex.replace('#', '');

    if (hex.length === 3) {
      hex = hex
        .split('')
        .map(char => char + char)
        .join('');
    }

    let bigint = parseInt(hex, 16);
    let r = (bigint >> 16) & 255;
    let g = (bigint >> 8) & 255;
    let b = bigint & 255;

    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }

  const handleSwitchChange = async (id, type, value) => {
    setSwitchStates(prevState => ({
      ...prevState,
      [id]: {
        ...prevState[id],
        [type]: value,
      },
    }));

    const dataToSend = {
      id,
      plataforma:
        type === 'valor_plataforma'
          ? value
          : switchStates[id]?.valor_plataforma || false,
      push:
        type === 'valor_push' ? value : switchStates[id]?.valor_push || false,
      ids_formularios:
        type === 'ids_formularios'
          ? value
          : switchStates[id]?.ids_formularios || [],
    };

    if (dataToSend) {
      await api.updateNotificationPermission(dataToSend);
    }
  };

  const getDefaultValue = value => (value === null ? false : Boolean(value));

  if (loading) return <Loading />;

  return (
    <S.Container>
      <Tabs
        value={currentTab}
        items={tabs}
        onChange={handlePageTab}
        width="700px"
      />
      <div className="boxBody">
        {currentSelection &&
          currentSelection.map((i, k) => (
            <Accordion
              key={k}
              sx={{
                boxShadow: 'none',
                border: 'none',
                backgroundColor: 'transparent',
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div className="notifyItem" key={k}>
                  <div className="notifyControls">
                    <Switch
                      check={
                        switchStates[i.id]?.valor_plataforma ??
                        getDefaultValue(i.valor_plataforma)
                      }
                      setCheckbox={value =>
                        handleSwitchChange(i.id, 'valor_plataforma', value)
                      }
                      textOn=""
                      textOff=""
                    />
                    <div style={{ textAlign: 'left' }}>
                      <div className="formatTextCheck">
                        {i.titulo}{' '}
                        {i.titulo === 'Desvio Venceu' ? (
                          <div
                            style={{
                              color: i.cor,
                              background: hexToRGBA(i.cor, '0.1'),
                            }}
                            className="rotulo"
                          >
                            {i?.descricao}
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <span className="notifyText">
                        {`${i.plataforma === 1 ? 'Plataforma' : ''}`}{' '}
                        {`${i.push === 1 && i.plataforma === 1 ? '/' : ''}`}{' '}
                        {`${i.push === 1 ? 'Push' : ''}`}
                      </span>
                    </div>
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails sx={{ paddingLeft: 10 }}>
                <div className="subtitle">
                  Escolha onde você irá receber notificações:
                </div>

                <div className="notifyControls space">
                  {i.plataforma === 1 && (
                    <Switch
                      check={
                        switchStates[i.id]?.valor_plataforma ??
                        getDefaultValue(i.valor_plataforma)
                      }
                      setCheckbox={value =>
                        handleSwitchChange(i.id, 'valor_plataforma', value)
                      }
                      textOn="Notificações da plataforma Onisys."
                      textOff="Notificações da plataforma Onisys."
                    />
                  )}
                </div>

                <div className="notifyControls space">
                  {i.push === 1 && (
                    <Switch
                      check={
                        switchStates[i.id]?.valor_push ??
                        getDefaultValue(i.valor_push)
                      }
                      setCheckbox={value =>
                        handleSwitchChange(i.id, 'valor_push', value)
                      }
                      textOn="Notificações push enviadas diretamente ao seu dispositivo."
                      textOff="Notificações push enviadas diretamente ao seu dispositivo."
                    />
                  )}
                </div>

                <div className="space" style={{ width: '80%' }}>
                  {i?.valor_formulario && (
                    <SelectMultiple
                      label="Escolha os formulários:"
                      value={i.ids_formularios}
                      data={i?.valor_formulario.map(item => {
                        return {
                          id: item.id,
                          value: item.titulo,
                        };
                      })}
                      handleChange={values =>
                        handleSwitchChange(i.id, 'ids_formularios', values)
                      }
                    />
                  )}
                </div>
              </AccordionDetails>
            </Accordion>
          ))}
      </div>
    </S.Container>
  );
};
