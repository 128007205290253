import styled, { css } from 'styled-components';

export const Table = styled.table`
  ${({ theme }) => css`
    margin-top: 1rem;
    width: 100%;
    border-bottom: 1px solid ${theme.palette.system.border};
  `};
`;

export const THead = styled.thead``;

export const Th = styled.th`
  ${({ theme, align }) => css`
    flex: 1;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    font: normal normal 900 13px/14px Texta;
    letter-spacing: 0px;
    color: ${theme.palette.words.subtitle.light};
    text-transform: uppercase;
    text-align: ${align ?? 'left'};
    opacity: 1;
    border-bottom: 1px solid ${theme.palette.system.border};
  `};
`;

export const TBody = styled.tbody``;

export const Tr = styled.tr``;

export const Td = styled.td`
  ${({ theme, align }) => css`
    flex: 1;
    padding-top: 4px;
    padding-bottom: 4px;
    text-align: left;
    font: normal normal bold 14px/24px Texta;
    letter-spacing: 0px;
    color: ${theme.palette.words.title.natural};
    text-align: ${align ?? 'left'};
    opacity: 1;
    white-space: nowrap;
  `}
`;
