import styled, { css, keyframes } from 'styled-components';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

export const containerBanner = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.palette.system.overlay};
    padding-bottom: 20px;
    .avatar {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 80px;
    }

    label {
      color: ${theme.palette.words.title.natural};
      text-align: left;
      font: normal normal normal 16px;
    }
    img {
      filter: brightness(40%);
    }
    .divider {
      width: 100%;
      height: 2px;
      background-color: ${theme.palette.system.divider};
      border-radius: 20%;
    }
  `}
`;
