import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: auto;
`;

export const SpacedRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

export const Footer = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: end;
  gap: 1rem;
`;

export const CollapseContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
`;

export const ListItemButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 1rem 0;

  h2 {
    font-size: 24px;
    font-weight: 900;
    color: ${({ theme }) => theme.palette.words.subtitle.natural};

    span {
      color: ${({ theme, color }) =>
        color
          ? theme.palette.semantics.feedback[color].natural
          : theme.palette.words.subtitle.natural};
    }
  }
`;

export const QuizContainer = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: space-between;

  font-size: 14px;
  color: ${({ theme }) => theme.palette.words.subtitle.natural};

  h2 {
    font-size: 24px;
    font-weight: 900;
    color: ${({ theme }) => theme.palette.words.title.natural};

    span {
      font-weight: 600;
      color: ${({ theme }) =>
        theme.palette.semantics.feedback.attention.natural};
    }
  }
`;

export const OptionsContainer = styled.div`
  display: flex;
  gap: 1rem;
  width: 50%;
  max-width: 400px;
  align-items: center;
`;

export const Option = styled.button`
  ${({ theme, disabled, active }) => css`
    width: 100%;
    height: 40px;
    padding: 0 0.5rem;
    color: ${theme.palette.system.white};
    font-weight: normal;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    background: ${theme.palette.words.subtitle.natural};
    transition: color 0.2s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    opacity: ${active ? 1 : 0.2};

    &:hover {
      opacity: ${disabled ? 0.2 : 1};
    }
  `}
`;
