import {
  SUSPENSAO_ID,
  getStatus,
} from 'pages/AcoesSuspensoes/AcoesSuspensoes/ListAcoes/constants';

export const iconsCards = {
  acoes_pendentes: 'acoes_pendentes.svg',
  acoes_finalizadas: 'acoes_finalizadas.svg',
};

export const columns = [
  {
    header: 'Id',
    id: 'id',
    type: 'number',
    sort: true,
    width: 100,
  },
  {
    header: 'Pontuacao',
    id: 'pontos',
    type: 'number',
    sort: true,
    subRow: {
      prefix: 'Faixa:',
      key: 'faixa',
      type: 'string',
    },
  },
  {
    header: 'Data Alcance de Faixa',
    id: 'data_inicio',
    type: 'date',
    sort: true,
  },
  {
    header: 'Plano de ação',
    id: 'acao_disciplinar',
    type: 'string',
  },
  {
    header: 'Conteúdo',
    id: 'conteudo',
    type: 'string',
  },
  {
    header: 'Status',
    id: 'status',
    type: 'string',
    sort: true,
    width: 200,
    align: 'center',
    style: {
      textTransform: 'uppercase',
    },
    value: (value, item) =>
      getStatus(item.status, item.id_acao_disciplinar === SUSPENSAO_ID),
    conditional: [
      {
        condition: value =>
          ['LIBERADO', 'APROVADO', 'CONCLUÍDO'].includes(value),
        style: theme => ({
          color: theme.palette.semantics.feedback.success.natural,
          backgroundColor: theme.palette.semantics.feedback.success.light,
          textAlign: 'center',
        }),
      },
      {
        condition: value => value === 'PENDENTE',
        style: theme => ({
          color: theme.palette.semantics.feedback.warning.natural,
          backgroundColor: theme.palette.semantics.feedback.warning.light,
          textAlign: 'center',
        }),
      },
      {
        condition: value => value === 'REPROVADO',
        style: theme => ({
          color: theme.palette.semantics.feedback.warning.dark,
          backgroundColor: theme.palette.semantics.feedback.warning.light,
          textAlign: 'center',
        }),
      },
      {
        condition: value => ['BLOQUEADO', 'SUSPENSO'].includes(value),
        style: theme => ({
          color: theme.palette.semantics.feedback.attention.dark,
          backgroundColor: theme.palette.semantics.feedback.attention.light,
          textAlign: 'center',
        }),
      },
    ],
  },
];
