import styled, { css } from 'styled-components';

export const icon = {
  icon: {
    cursor: 'pointer',
    margin: '7px',
    zIndex: 999,
    transform: 'scale(1)',
    transition: '0.3s ease-out',
    '&:hover': {
      transform: 'scale(1.3)',
    },
  },
};

export const Container = styled.div`
  width: ${props => (props.width ? props.width : '470px')};
  height: ${props => (props.height ? props.height : '250px')};
  position: relative;
`;

export const DropContainer = styled.div`
  ${({ theme, ...props }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    width: ${props.width ? props.width : '100%'};
    height: ${props.height ? props.height : '250px'};
    border-radius: 4px;
    background-color: ${theme.palette.semantics.feedback.unknown.light};
    position: relative;

    input {
      display: none;
    }
  `}
`;

export const Message = styled.div`
  ${({ theme }) => css`
    text-align: center;

    p {
      color: ${theme.palette.words.text.natural};
      font-weight: 900;
      font-size: 16px;
      width: 182px;
    }
  `}
`;

export const Image = styled.img`
  height: 50px;
  width: 56px;
  margin-bottom: 5px;
`;

export const ImageURL = styled.img`
  width: ${props => (props.width ? props.width : '100%')};
  height: ${props => (props.height ? props.height : '250px')};
  border-radius: 4px;
`;

export const ImageType = styled.p`
  font-weight: 500;
  font-size: 12px;
  margin-top: 5px;
`;

export const IconDivClose = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: 0;
    right: 0;
    width: 25px;
    height: 25px;
    margin-right: -10px;
    cursor: pointer;
    background: ${theme.palette.system.overlay};
    border-radius: 100px;
  `}
`;
