const columns = [
  {
    header: 'Nome',
    id: 'titulo',
    type: 'string',
    sort: true,
  },
  {
    header: 'Conclusão',
    id: 'conclusao',
    type: 'string',
    sort: true,
    align: 'center',
  },
  {
    header: 'Avaliações',
    id: 'avaliacao',
    type: 'string',
    sort: true,
    align: 'center',
  },
  {
    header: 'Tentativas',
    id: 'tentativas',
    type: 'number',
    sort: true,
    align: 'center',
  },
  {
    header: 'Prazo',
    id: 'prazo',
    type: 'string',
    sort: true,
    align: 'center',
  },
  {
    header: 'Status',
    id: 'status',
    type: 'string',
    sort: true,
    align: 'center',
    conditional: [
      {
        condition: value => value === 'Aprovado',
        style: theme => ({
          color: theme.palette.semantics.feedback.success.natural,
          backgroundColor: theme.palette.semantics.feedback.success.light,
          textAlign: 'center',
        }),
      },
      {
        condition: value => value === 'Reprovado',
        style: theme => ({
          color: theme.palette.semantics.feedback.warning.natural,
          backgroundColor: theme.palette.semantics.feedback.warning.light,
          textAlign: 'center',
        }),
      },
    ],
  },
];

export { columns };
