export const fields = [
  {
    label: 'ID',
    value: 'id',
    selected: true,
    default: true,
  },
  {
    label: 'Cliente',
    value: 'distribuidora.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Empresa',
    value: 'empresa.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Filial',
    value: 'filial.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Origem',
    value: 'origem',
    selected: true,
    default: true,
  },
  {
    label: 'Destino',
    value: 'destino',
    selected: true,
    default: true,
  },
  {
    label: 'Responsável',
    value: 'usuario.nome',
    selected: true,
    default: true,
  },
  {
    label: 'Arquivo',
    value: 'arquivo',
    selected: true,
    default: true,
  },
  {
    label: 'Criado em',
    value: 'createdAt',
    selected: true,
    default: true,
  },
  {
    label: 'Modificado em',
    value: 'updatedAt',
    selected: true,
    default: true,
  },
];

export const resetFields = fields => {
  return fields.map(i => {
    if (!i.default) i.selected = false;
    return i;
  });
};

export const formatedOperation = operation => {
  switch (operation) {
    case 1: {
      return 'Primária';
    }
    case 2: {
      return 'Secundária';
    }
    case 3: {
      return 'Açucar';
    }
    case 4: {
      return 'Ambas';
    }
    default: {
      return '-';
    }
  }
};

export const limitDate = (valid, theme) => {
  if (valid) {
    return {
      color: theme?.palette?.semantics?.feedback?.success?.natural,
      backgroundColor: theme?.palette?.semantics?.feedback?.success?.light,
    };
  }
  return {
    color: theme?.palette?.semantics?.feedback?.attention?.natural,
    backgroundColor: theme?.palette?.semantics?.feedback?.attention?.light,
  };
};
