export const columns = [
  {
    Header: 'Nome',
    id: 'nome',
    accessor: d => String(d.nome),
    sortType: 'basic',
    show: true,
    disableSortBy: false,
  },
  {
    Header: 'Celular',
    id: 'celular',
    accessor: d => String(d.celular),
    show: true,
    disableSortBy: true,
  },
  {
    Header: 'Cidade',
    id: 'cidade',
    accessor: d => String(d.cidade),
    show: true,
    disableSortBy: true,
  },
  {
    Header: 'Curriculo',
    id: 'curriculo',
    accessor: d => String(d.curriculo),
    show: true,
    disableSortBy: false,
    centered: true,
  },
];
