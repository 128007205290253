import React, { useState, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';

import { Modal, IconButton, Icon, Grid } from '@mui/material';

import InfoCard from 'components/Cards/InfoCard';
import GhostButton from 'components/Buttons/Ghost';
import DefaultButton from 'components/Buttons/Default';
import TextInput from 'components/Inputs/TextField';
import Select from 'components/Inputs/Select';
import Calendar from 'components/Inputs/Calendar';
import * as S from './styled';

const ApproveModal = ({
  ids,
  handleClose,
  handleConfirm,
  titleIconName = 'check',
  loading,
}) => {
  const theme = useTheme();
  const selects = useSelector(state => {
    return state.selects;
  });
  const user = useSelector(state => {
    return state.auth?.user?.user;
  });

  const userLevel = user.nivel;
  const userFilials = user.usuario_filiais;

  const responsaveis = useMemo(() => {
    // Nivel 1 lista todos  || nivel > 1 apenas users da mesma filial
    const resps = selects.users?.filter(
      item => userLevel === 1 || userFilials.includes(item.id_da_filial),
    );
    return (
      resps.map(item => {
        return { value: item.id, name: item.nome };
      }) || []
    );
  }, [selects.users]);

  const title = useMemo(() => {
    return ids.length > 1
      ? `Deseja aprovar os desvios selecionados? (${ids.length})`
      : 'Deseja aprovar o desvio selecionado?';
  }, [ids]);

  const subtitle = useMemo(() => {
    return ids.length > 1
      ? `Os desvios serão movidos para Pendentes. As alterações serão aplicadas em TODOS os desvios selecionados.`
      : 'O desvio será movido para aba Pendentes.';
  }, [ids]);

  const responsavelInicial = useMemo(
    () => responsaveis.find(item => item.value === user.id),
    [responsaveis],
  );

  // Icone do header variável com titleIconName
  const titleIcon = (
    <Icon
      sx={{ color: theme.palette.brand.secondary.natural }}
      fontSize="medium"
    >
      {titleIconName}
    </Icon>
  );

  // Objeto de dados a serem preenchidos
  const [data, setData] = useState({
    plano_de_acao: '',
    id_responsavel: responsavelInicial ? responsavelInicial.value : '',
    data_previsao: '',
  });

  const handleChange = (name, value) => {
    setData(prev => {
      return { ...prev, [name]: value };
    });
  };

  // Bloqueia botão de confirmar quando há item sem preencher
  const checkReady = () => {
    let ready = true;
    if (data.plano_de_acao.length < 2) ready = false;
    else if (!data.id_responsavel) ready = false;
    else if (!data.data_previsao) ready = false;
    return ready;
  };

  const handleClickConfirm = () => {
    const req = {
      ...data,
      ids,
    };
    handleConfirm(req);
  };

  const open = Boolean(ids);

  return (
    <Modal open={open}>
      <S.Paper>
        <S.Header>
          <S.Title>
            {titleIcon}
            <h1>{title}</h1>
          </S.Title>

          <IconButton size="small" onClick={handleClose}>
            <Icon sx={{ color: theme.palette.words.subtitle.natural }}>
              close
            </Icon>
          </IconButton>
        </S.Header>

        <InfoCard message={subtitle} />

        <Grid container rowSpacing={0} columnSpacing={2} marginY={3}>
          <Grid item xs={12} sm={12}>
            <TextInput
              required
              label="Plano de Ação"
              value={data.plano_de_acao}
              onChange={e => handleChange('plano_de_acao', e.target.value)}
              name="plano_de_acao"
            />
          </Grid>

          <Grid item xs={8} sm={8}>
            <Select
              required
              data={responsaveis}
              value={data.id_responsavel}
              onChange={e => handleChange('id_responsavel', e.target.value)}
              name="id_responsavel"
              label="Responsável"
            />
          </Grid>

          <Grid item xs={4} sm={4}>
            <Calendar
              value={data.data_previsao}
              onChange={newDate => handleChange('data_previsao', newDate)}
              futureDate
              pastDate={false}
              label="Data de previsão"
              required
            />
          </Grid>
        </Grid>

        <S.Footer>
          <GhostButton size="medium" onClick={handleClose}>
            Cancelar
          </GhostButton>

          <DefaultButton
            onClick={handleClickConfirm}
            size="medium"
            style={{ marginLeft: '10px' }}
            disabled={!checkReady()}
            loading={loading}
          >
            Confirmar
          </DefaultButton>
        </S.Footer>
      </S.Paper>
    </Modal>
  );
};

export default ApproveModal;
