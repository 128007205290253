import api from 'services/api';

export const requestRanking = async query => {
  const res = await api.get(`/pad/ranking`, { params: query });
  return res.data;
};

export const requestNiveis = async () => {
  const res = await api.get(`/pad-nivel`);
  return res.data;
};
