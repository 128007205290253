import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import ThumbDownOffAltOutlinedIcon from '@mui/icons-material/ThumbDownOffAltOutlined';

import colors from 'styles/colors';

export const tabs = [
  {
    value: 'like',
    label: 'Curtiu',
  },
  {
    value: 'deslike',
    label: 'Não curtiu',
  },
  {
    value: 'comentarios',
    label: 'Comentários',
  },
  {
    value: 'view',
    label: 'Visualizações',
  },
];

export const text = {
  like: 'curtiu',
  deslike: 'não curtiu',
  comentarios: 'comentarios',
  view: 'visualizou',
};

export const icons = {
  like: <ThumbUpOutlinedIcon htmlColor={colors.greenSucces} />,
  deslike: <ThumbDownOffAltOutlinedIcon htmlColor={colors.redDanger} />,
  comentarios: <CommentOutlinedIcon htmlColor={colors.greyTiny} />,
  view: <RemoveRedEyeOutlinedIcon htmlColor={colors.greyTiny} />,
};
