import styled from 'styled-components';
import colors from 'styles/colors';

export const Container = styled.div`
  background-color: ${colors.white};
  height: 160px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border: 1px solid ${colors.greyBorder};
  box-shadow: 0px 2px 2px ${colors.grey};
  border-radius: 5px;
  position: relative;
  padding: 10px;

  .responseBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 915px) {
      flex-direction: row;
    }

    @media (max-width: 860px) {
      flex-direction: column;
    }
  }

  @media (max-width: 915px) {
    height: 100px;
  }
`;

export const BoxLoading = styled.div`
  opacity: ${props => (props.loading ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
  border-radius: 5px;
  height: 100%;
  width: 100%;
  position: absolute;
  background-image: linear-gradient(
    to right,
    #f4f6f0 0%,
    #edeef1 20%,
    #f4f6f0 40%,
    #f4f6f0 100%
  );
  background-size: 80rem 14rem;
  animation: placeholderShimmer 2s linear infinite forwards;

  @keyframes placeholderShimmer {
    0% {
      background-position: -40rem 0;
    }
    100% {
      background-position: 40rem 0;
    }
  }
`;

export const Value = styled.h1`
  @media (max-width: 915px) {
    font-size: 15px;
    margin: 10px;
  }
`;

export const Title = styled.div`
  font-weight: 500;
  color: ${colors.greySubtitle};
  font-size: 16px;
  text-align: center;
  height: 50px;

  @media (max-width: 1100px) {
    font-size: 14px;
  }

  @media (max-width: 915px) {
    font-size: 12px;
  }

  @media (max-width: 610px) {
    font-size: 10px;
  }
`;

export const PrevArea = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  background-color: ${props =>
    props.positive ? colors.greenBackground : colors.redBackground};
  padding: 5px 10px;
  border-radius: 10px;
  font-weight: 600;

  @media (max-width: 1100px) {
    font-size: 12px;
  }

  .value {
    color: ${props => (props.positive ? colors.greenSucces : colors.redDanger)};
  }

  .text {
    margin-right: 5px;
    color: ${colors.greySubtitle};
  }

  @media (max-width: 990px) {
    font-size: 10px;
    padding: 1px 5px;
  }

  @media (max-width: 860px) {
    display: none;
  }
`;
