import api from 'services/api';

const requestDriversRanking = async query => {
  const res = await api.get(`/drivers-ranking`, { params: query });
  return res;
};

const requestExcel = async query => {
  const res = await api.get('/excel/driversRanking', { params: query });
  return res;
};

export { requestDriversRanking, requestExcel };
