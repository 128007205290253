import styled from 'styled-components';

export const Tooltip = styled.div`
  opacity: 0;
  background-color: white;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
  color: #4b5166;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 100000;
  font-size: 14px;
  left: 50%;
  margin-left: -60px;
  transform: translate(-0px, 70px);
  transition: opacity 0.4s ease-in-out;
  padding: 5px 10px 5px 10px;
  span {
    font-weight: 900;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  .selector {
    width: 30%;
  }
`;
// Componente de container Styled
export const ContainerBar = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
`;

export const BarEmpty = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  background-color: #eee;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 2px;
  opacity: 0.8;
  justify-content: center;
  align-items: center;
  color: rgb(75, 81, 102);
  font-weight: 400;
`;
// Componente de barra Styled que recebe a cor e o percentual como props
export const Bar = styled.div`
  width: ${props => props.percentual}%;
  background-color: ${props => props.backgroundColor};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => (props.backgroundColor === '#F64E60' ? 'black' : 'white')};
  opacity: 0.4;
  transition: transform 0.5s ease-in-out;

  &:hover {
    transform: scale(1.1);
    opacity: 1;
    z-index: 1;
    ${Tooltip} {
      opacity: 1; // Mostrar o tooltip no hover
      transition: opacity 0.2s ease-in-out;
    }
  }
`;
