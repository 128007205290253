import styled, { css } from 'styled-components';
import { Card } from '@mui/material';

const Main = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 50px;
`;

const StyledCard = styled(Card)`
  width: ${props => props.width || '100%'};
  min-height: 200px;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 35px 25px;

  img {
    width: 125px;
    height: 150px;
    margin-right: 25px;
  }
`;

const TextContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    flex-direction: column;

    h2 {
      font: normal normal 900 20px/43px Texta;
      color: ${theme.palette.words.title.natural};
    }

    h3 {
      font: normal normal 400 16px/24px Texta;
      color: ${theme.palette.words.subtitle.natural};
      margin-bottom: 15px;
    }
  `}
`;

const DividerVertical = styled.div`
  ${({ theme }) => css`
    border-left: 2px solid ${theme.palette.system.border};
    height: 70%;
    width: 2px;
    align-self: center;
  `}
`;

const DividerHorizontal = styled.div`
  ${({ theme }) => css`
    border-top: 2px solid ${theme.palette.system.border};
    height: 2px;
    width: 80%;
    align-self: center;
  `}
`;

const UploadContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    flex-direction: column;
    padding: 30px;

    img {
      width: 125px;
      height: 140px;
    }

    h1 {
      color: ${theme.palette.words.title.natural};
      font: normal normal 900 42px/60px Texta;
    }

    h2 {
      font: normal normal 900 24px/43px Texta;
      color: ${theme.palette.words.title.natural};
    }

    h3 {
      font: normal normal 400 16px/24px Texta;
      color: ${theme.palette.words.subtitle.natural};
    }
  `}
`;

const InputFile = styled.input`
  display: none;
`;

const FileLoaded = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    height: 36px;
    padding: 0px 10px;
    justify-content: space-between;
    border: 1px solid ${theme.palette.brand.secondary.natural};
    border-radius: 1000px;
    margin-top: 15px;

    p {
      margin-right: 15px;
    }
  `}
`;

const ErrorContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 900px;
    min-height: 150px;
    max-height: 500px;
    margin-top: 15px;
    padding: 20px 60px;
    background-color: ${theme.palette.semantics.feedback.attention.light};
    border: 1px solid ${theme.palette.semantics.feedback.attention.natural};
    border-radius: 4px;

    p {
      color: ${theme.palette.words.title.natural};
      font: normal normal normal 16px/24px Proxima Nova;
      display: list-item;
    }
  `}
`;

const ErrorHeader = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    h1 {
      margin-left: 15px;
      color: ${theme.palette.words.title.natural};
      font: normal normal 900 24px/60px Texta;
    }
  `}
`;

const SuccessContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    width: 400px;
    height: 50px;
    margin-top: 15px;
    padding: 10px 20px;
    background-color: ${theme.palette.brand.primary.background};
    border: 1px solid ${theme.palette.semantics.feedback.success.natural};
    border-radius: 4px;

    h3 {
      color: ${theme.palette.words.subtitle.natural};
      font: normal normal 600 16px/23px Texta;
    }
  `}
`;

export {
  Main,
  TitleWrapper,
  StyledCard,
  Content,
  TextContent,
  DividerVertical,
  DividerHorizontal,
  UploadContent,
  InputFile,
  FileLoaded,
  ErrorContainer,
  ErrorHeader,
  SuccessContainer,
};
