import { formatNewHour } from 'utils/dates';
import Loading from 'components/Loading';
import EmptyCard from 'components/Cards/EmptyDataCard';

// Icones dos logs
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import HourglassEmptyOutlinedIcon from '@mui/icons-material/HourglassEmptyOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined';
import TrendingDownOutlinedIcon from '@mui/icons-material/TrendingDownOutlined';
import RestorePageOutlinedIcon from '@mui/icons-material/RestorePageOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import PreviewOutlinedIcon from '@mui/icons-material/PreviewOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined';
import DisabledByDefaultOutlinedIcon from '@mui/icons-material/DisabledByDefaultOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import FileOpenOutlinedIcon from '@mui/icons-material/FileOpenOutlined';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

import { useTheme } from 'styled-components';
import * as S from './styled';

export const Logs = ({ logs, loading }) => {
  const theme = useTheme();
  const icons = id_do_evento => {
    switch (id_do_evento) {
      case 2:
        return DownloadOutlinedIcon;
      case 1:
        return HourglassEmptyOutlinedIcon;
      case 3:
        return AddCircleOutlineOutlinedIcon;
      case 4:
        return BorderColorOutlinedIcon;
      case 5:
        return Inventory2OutlinedIcon;
      case 6:
      case 28:
        return EditOutlinedIcon;
      case 20:
        return CheckCircleOutlineOutlinedIcon;
      case 7:
        return AddPhotoAlternateOutlinedIcon;
      case 8:
        return DeleteOutlineOutlinedIcon;
      case 9:
        return CommentOutlinedIcon;
      case 10:
        return FindInPageOutlinedIcon;
      case 11:
        return TrendingDownOutlinedIcon;
      case 12:
        return RestorePageOutlinedIcon;
      case 16:
        return CancelOutlinedIcon;
      case 13:
        return HourglassEmptyOutlinedIcon;
      case 14:
      case 27:
        return CheckCircleOutlineOutlinedIcon;
      case 15:
        return FindInPageOutlinedIcon;
      case 17:
        return PreviewOutlinedIcon;
      case 18:
        return DescriptionOutlinedIcon;
      case 21:
        return DocumentScannerOutlinedIcon;
      case 19:
        return DisabledByDefaultOutlinedIcon;
      case 25:
        return CheckBoxOutlinedIcon;
      case 26:
        return ImageOutlinedIcon;
      case 22:
        return FileOpenOutlinedIcon;
      case 23:
        return UploadFileOutlinedIcon;
      case 24:
        return SettingsOutlinedIcon;

      default:
        return EditOutlinedIcon;
    }
  };

  return (
    <div>
      {!loading && (
        <>
          {logs && logs.length > 0 && (
            <S.Box>
              {logs.map((log, idx) => {
                let Icon = icons(log?.id_do_evento);
                return (
                  <S.Log key={idx}>
                    <div className="icons">
                      <div className="circleIcon">
                        <Icon htmlColor={theme.palette.words.title.natural} />
                      </div>
                      {idx !== logs.length - 1 && <div className="bar" />}
                    </div>
                    <div className="content">
                      <div className="information">
                        <span className="topArea">
                          {`[${log.evento}] `}
                          {log.descricao}
                        </span>
                        <span className="bottomArea">
                          {formatNewHour(log.data)}
                        </span>
                      </div>
                    </div>
                  </S.Log>
                );
              })}
            </S.Box>
          )}
          {logs && logs.length <= 0 && (
            <EmptyCard
              image="frota.png"
              title="Nenhum registro foi encontrado"
            />
          )}
        </>
      )}
      {loading && (
        <S.LoadingBox>
          <Loading />
        </S.LoadingBox>
      )}
    </div>
  );
};
