/* eslint-disable import/order */

// Styles
import * as S from './styled';

// React
import { useDebounce } from 'use-debounce';
import { useEffect, useState } from 'react';

// Components MUI
import SearchIcon from '@mui/icons-material/Search';

// Generators
import { limitText } from '../../../generators/limit';

// Context
import { useTable } from '../../../context';
import { _setSearch } from '../../../context/actions';

let timer = null;

export const Input = ({
  placeholder = 'Buscar',
  Icon = SearchIcon,
  loading,
  local = true,
  searchEvent,
  ...props
}) => {
  const { dispatch } = useTable();

  const [value, setValue] = useState('');
  const [initial, setInitial] = useState(true);
  const [eventValue] = useDebounce(value, 1000);

  const handleSearch = val => {
    setValue(val);
  };

  useEffect(() => {
    if (!initial) {
      const time = local ? 0 : 2000;
      clearTimeout(timer);

      timer = setTimeout(() => {
        dispatch(_setSearch(value));
      }, time);
    } else {
      setInitial(false);
    }
  }, [value]);

  useEffect(() => {
    searchEvent && eventValue.length >= 3 && searchEvent(eventValue);
  }, [eventValue]);

  return (
    <S.Container
      onSubmit={e => {
        e.preventDefault();
        clearTimeout(timer);
        dispatch(_setSearch(value));
      }}
    >
      <S.IconContainer>
        <Icon className="icon" data-testid="icon" />
      </S.IconContainer>
      <S.Search
        data-testid="input"
        placeholder={limitText(placeholder, 25)}
        value={value}
        onChange={e => !loading && handleSearch(e.target.value)}
        isLoading={loading}
        {...props}
      />
    </S.Container>
  );
};
