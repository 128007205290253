import api from 'services/api';

export const getContent = async provider => {
  const res = await api.get(`${provider ? '/provider' : ''}/catalog-data`);
  return res.data;
};

export const responseGuia = async (provider, data) => {
  const response = await api.post(
    `${provider ? '/provider' : ''}/guide-steps/${data.id}`,
    data,
  );
  return response.data;
};
