import React, { useState } from 'react';

import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SvgIcon from '@mui/material/SvgIcon';
import { useTheme } from 'styled-components';
import { ReactComponent as inicio } from 'images/icons/sidebar/inicio.svg';
import * as S from './styled';

import { itemsConfigTransportadora } from './constants';

const SidebarDrivers = ({ page, setPage }) => {
  const theme = useTheme();
  const itemsConfig = itemsConfigTransportadora;
  const [openTab, setOpenTab] = useState({
    dashboard: false,
    avaliacoes: false,
    desvios: false,
  });
  const handleClick = name => {
    const oldValue = openTab[name];
    setOpenTab({ ...openTab, [name]: !oldValue });
  };

  return (
    <Grid container>
      <S.Wrapper>
        <List style={{ display: 'flex' }}>
          {itemsConfig.map((item, index) => {
            const selected = index == page;
            const itemListSelected = {
              fontSize: '16px',
              color: theme.palette.system.overlay,
              transition: '0.3s ease-out',
              opacity: 1,
              backgroundColor: theme.palette.brand.primary.background,
              borderRight: `5px solid ${theme.palette.brand.secondary.natural}`,
              borderRadius: 'none',
              '&:hover': {
                opacity: 1,
              },
              '&:active': {
                opacity: 1,
              },
            };

            const itemList = {
              fontSize: '16px',
              transition: '0.3s ease-out',
              opacity: 0.7,
              '&:hover': {
                opacity: 1,
              },
              '&:active': {
                opacity: 1,
              },
            };
            return (
              <ListItem
                onClick={() => setPage(index)}
                disableTouchRipple
                selected={selected}
                button
                key={item.name}
                style={selected ? itemListSelected : itemList}
              >
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <ListItemIcon>
                    <SvgIcon
                      component={item.icon}
                      style={{
                        color: selected
                          ? theme.palette.brand.secondary.natural
                          : theme.palette.words.subtitle.natural,
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    style={{
                      marginLeft: '-17px',
                      color: selected
                        ? theme.palette.brand.primary.natural
                        : theme.palette.words.subtitle.natural,
                      font: 'normal normal medium 16px/24px Texta',
                    }}
                    primary={item.text}
                  />
                </div>
              </ListItem>
            );
          })}
        </List>
      </S.Wrapper>
    </Grid>
  );
};

export default SidebarDrivers;
