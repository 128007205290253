import { useTheme } from 'styled-components';

export const statusColor = status => {
  const theme = useTheme();
  switch (status.toUpperCase()) {
    case 'ATIVA':
      return {
        color: theme.palette.semantics.feedback.success.natural,
        background: theme.palette.semantics.feedback.success.light,
      };
    case 'AGENDADO':
      return {
        color: theme.palette.semantics.feedback.information.natural,
        background: theme.palette.semantics.feedback.information.light,
      };
    case 'RASCUNHO':
      return {
        color: theme.palette.semantics.feedback.warning.natural,
        background: theme.palette.semantics.feedback.warning.light,
      };
    case 'INATIVA':
      return {
        color: theme.palette.semantics.feedback.attention.natural,
        background: theme.palette.semantics.feedback.attention.light,
      };
  }
};
