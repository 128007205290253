// React
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

// Components
import GhostButton from 'components/Buttons/Ghost';
import DefaultButton from 'components/Buttons/Default';
import SelectList from 'components/Inputs/SelectList';

// Components MUI
import List from '@mui/material/List';
import { Popover } from '@mui/material';

import * as S from './styled';
import { getResponsaveis } from 'constants/_SERVICES/provider';

// Utils

const Filters = ({ id, open, onClose, anchorEl, filter, setFilter }) => {
  const [prevFilter, setPrevFilter] = useState({ ...filter });

  const { data: responsaveisProvider = [] } = useQuery(
    ['responsaveis-provider'],
    () => getResponsaveis(),
    {
      refetchOnWindowFocus: false,
    },
  );

  const responsaveis = responsaveisProvider.map(user => ({
    value: user.label,
    id: user.value,
  }));

  useEffect(() => {
    setPrevFilter({ ...filter });
  }, [filter]);

  const handleChangeMultiple = (e, key) => {
    setPrevFilter(_prevFilter => {
      return { ..._prevFilter, [key]: e };
    });
  };

  const aplyFilter = () => {
    const _prevFilter = { ...prevFilter };
    Object.keys(_prevFilter).forEach(key => {
      if (!_prevFilter[key]) delete _prevFilter[key];
    });
    setFilter({ ..._prevFilter });
    onClose();
  };

  const reset = () => {
    setPrevFilter({ ...filter });
  };

  // Limpa TODOS os valores do estado e foca no botao de aplicar
  const handleClear = () => {
    setPrevFilter({});
    const buttonApply = document.getElementById('aplicar');
    buttonApply?.focus();
  };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <S.Main>
        <S.Header>
          <h2>Filtros</h2>
          <S.ClearButton onClick={handleClear}>Limpar filtros</S.ClearButton>
        </S.Header>

        <S.SelectsContainer>
          <List>
            <S.CollapseContent>
              <SelectList
                value={prevFilter?.responsavel}
                handleChange={handleChangeMultiple}
                fieldName="responsavel"
                label="Filtrar por Responsável"
                data={responsaveis}
                selectAll
              />
            </S.CollapseContent>
          </List>
        </S.SelectsContainer>
      </S.Main>
      <S.Footer>
        <GhostButton
          onClick={() => {
            reset();
            onClose();
          }}
          size="medium"
        >
          Cancelar
        </GhostButton>

        <DefaultButton
          onClick={aplyFilter}
          size="medium"
          sx={{ marginLeft: '20px' }}
          id="aplicar"
        >
          APLICAR
        </DefaultButton>
      </S.Footer>
    </Popover>
  );
};

export default Filters;
