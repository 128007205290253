/* eslint-disable no-prototype-builtins */
/* EM CASO DE DUVIDAS SOBRE O FUNCIONAMENTO ACESSE O ARQUIVO .DOC */

// React
import { useQuery } from 'react-query';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { setQueue } from 'store/modules/guia/actions';
import { useSelector, useDispatch } from 'react-redux';
import { setConcluded } from 'store/modules/guia/actions';

// Components
import _ from 'underscore';
import { View } from '../View';

// Services
import * as services from './services';

export const NavigationGuide = () => {
  // Redux
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth.user);
  const { queue } = useSelector(state => state.guiaQueue);
  const { currentPlan } = useSelector(state => state.pageMap);

  // INFO STATES
  const [data, setData] = useState(null); // ==> Armazena os dados da req FORMATADOS
  const [lastRoute, setLastRoute] = useState(null); // ==> Armazena a ultima rota
  const [cleanResponse, setCleanResponse] = useState(null); // ==> Armazena os dados da req LIMPOS
  const [guideOnDisplay, setGuideOnDisplay] = useState(null); // ==> Armazena o guia em exibição
  const [inEval, setInEval] = useState(false); // ==> Armazena se o guia está em avaliação (Modal final)

  // Rota
  const location = useLocation();

  // ------------------------------ REQUESTS ---------------------------------
  useQuery(
    ['guia-usuario-navigation', 1],
    () => services.getGuia(user.provider),
    {
      refetchOnWindowFocus: false,
      onSuccess: data => {
        data.conclusao_total_guias &&
          dispatch(setConcluded(data.conclusao_total_guias));
        formatInitialData(data.data);
      },
    },
  );

  /* Formatação dos elementos para serem reconhecidos dentro do componente VIEW */
  const formatInitialData = (res, blocked, prevQueue) => {
    setCleanResponse(res);
    const module = res && JSON.parse(JSON.stringify(res));
    for (let i in module) {
      const guides = module[i].filter(guide => {
        const steps = guide.etapas.filter(step => {
          if (
            step.ativa &&
            step.niveis.split(',').includes(user.nivel.toString()) &&
            step.mostrar_guia
          ) {
            if (step.is_capa) {
              guide.capa = step;
              return false;
            }
            return true;
          }
        });

        if (
          (steps.length > 0 || (guide.capa && guide.capa.ativa)) &&
          guide.ativa
        ) {
          guide.etapas = steps;
          return true;
        }
      });

      if (guides.length > 0) {
        module[i] = guides;
      }
    }

    setData(module);
    !blocked && controlGuide(module, prevQueue);
  };

  // ------------------------------ CONTROLE ---------------------------------

  // -- CASES 1 E 2
  const controlGuide = (module, prevQueue) => {
    if (!data && !module) return;
    let _QUEUE = prevQueue || JSON.parse(JSON.stringify(queue));
    const ambiente = user.provider ? 1 : currentPlan;
    const pathname = location.pathname;

    // ------------------------------ CASE 2---------------------------------
    // --- Se existir uma ultima rota e ela for diferente da atual
    if (lastRoute && lastRoute !== pathname) {
      // --- Se o guia não estiver sendo exibido OU não tiver sido iniciado, remove da fila
      const guideToRemove = _QUEUE.filter(
        item => item.path === lastRoute && (!item.step || !item.exibindo),
      );

      const idsToRemove = guideToRemove.map(item => item.id);
      _QUEUE = _QUEUE.filter(item => !idsToRemove.includes(item.id));

      if (idsToRemove?.includes(guideOnDisplay?.id)) {
        setGuideOnDisplay(null);
      }
    }

    // ------------------------------ CASE 1---------------------------------

    const planEviroments = module || data || [];

    const sharedPlanEnvironment = planEviroments.hasOwnProperty(0)
      ? planEviroments[0]
      : [];
    const currentPlanEnvironment = planEviroments.hasOwnProperty(ambiente)
      ? planEviroments[ambiente]
      : [];

    const guides =
      [...sharedPlanEnvironment, ...currentPlanEnvironment]?.filter(item => {
        if (item.rota.includes('?')) {
          return (
            item.rota === `${location.pathname}${location.search}` &&
            !item.concluido &&
            item.mostrar
          );
        }
        return (
          item.rota === location.pathname &&
          !item.concluido &&
          item.mostrar !== false
        );
      }) || [];

    // --- Ids dos guias que estão na fila
    const idsQueue = _QUEUE.map(item => item.id);

    // --- Estruturar elemento para o redux
    const newQueue = guides?.map((item, key) => {
      // const lastConcluded = item.etapas.findIndex(
      //   etapa => etapa.concluido === true,
      // );

      // -- Se o guia já estiver na fila, não inclui
      if (idsQueue.includes(item.id)) return false;

      // -- Se o guia não estiver na fila, inclui
      return {
        id: item.id,
        path: item.rota,
        step: /* lastConcluded + 1 */ 0,
        exibindo: !_QUEUE.length && key === 0, // --Se for vazio, inclui item já exibindo
        guia: item,
      };
    });

    _QUEUE = [..._QUEUE, ...newQueue.filter(item => item)];

    // Update redux
    dispatch(setQueue(_QUEUE));

    // Atualiza a ultima rota no final de toda a operação
    setLastRoute(pathname);
  };

  useEffect(() => {
    if (guideOnDisplay && inEval) {
      disableGuide(guideOnDisplay.id, false, true);
    } else {
      controlGuide();
    }
  }, [location.pathname, location.search]);

  // -- CASE 3
  const execute = async (id, step, isLast) => {
    setInEval(isLast);

    let currentGuide = null;
    for (let i in cleanResponse) {
      cleanResponse[i].forEach(guide => {
        if (guide.id === id) {
          currentGuide = guide;
        }
      });
    }
    const currentStep = currentGuide.etapas[step];
    services
      .responseGuia(user.provider, {
        id: currentStep.id,
        guia: currentStep.id_guia_conteudo,
      })
      .then(res => {
        res?.conclusao_total_guias &&
          dispatch(setConcluded(res.conclusao_total_guias));
      });

    const copyQueue = JSON.parse(JSON.stringify(queue));
    if (!isLast) {
      const updateGuide = copyQueue.filter(item => {
        if (item.id === id) {
          item.step = step + 1;
          return item;
        }
        return item;
      });

      dispatch(setQueue(updateGuide));
    } else {
      const updateGuide = copyQueue.filter(item => {
        if (item.id === id) {
          item.finished = true;
          return item;
        }
        return item;
      });

      dispatch(setQueue(updateGuide));
    }
  };

  // -- CASE 4
  const forcedClose = async (id, step) => {
    setInEval(true);

    let currentGuide = null;
    for (let i in cleanResponse) {
      cleanResponse[i].forEach(guide => {
        if (guide.id === id) {
          currentGuide = guide;
        }
      });
    }
    const currentStep = currentGuide.etapas[step];
    services.responseGuia(user.provider, {
      id: currentStep.id,
      guia: currentStep.id_guia_conteudo,
      forcado: true,
    });
  };

  // ---------------------  FINALIZAÇÃO DO GUIA ------------------------------
  // Função chamada sempre que um guia é fechado ou finalizado
  const disableGuide = (id, forced, finished) => {
    setInEval(false);
    /* Remove o guia finalizado e coloca em exibição o proximo caso haja */

    let _QUEUE = JSON.parse(JSON.stringify(queue));
    _QUEUE = _QUEUE.filter(item => item.id !== id);
    if (_QUEUE.length) {
      // Exibe o proximo guia
      _QUEUE[0].exibindo = true;
    } else {
      // Limpa o state de exibição caso não haja mais guias
      setGuideOnDisplay(null);
    }

    for (let i in cleanResponse) {
      const guides = cleanResponse[i].map(guide => {
        if (guide.id === id) {
          return {
            ...guide,
            mostrar: false,
            concluido: !forced,
          };
        }
        return guide;
      });

      cleanResponse[i] = guides;
    }

    !finished && dispatch(setQueue(_QUEUE));
    formatInitialData(cleanResponse, !finished, finished ? _QUEUE : false);
  };

  const sendEvaluation = (id, evaluation) => {
    services.evaluateGuia(user.provider, id, evaluation);
  };

  // ------------------------------ EXIBIÇÃO ---------------------------------
  /* Responsável pela atualização do item que esta sendo exibido sempre que a listagem mudar */
  useEffect(() => {
    const _QUEUE = JSON.parse(JSON.stringify(queue));
    // Atualiza o item em exibição CASO SEJA DIFERENTE DO ATUAL
    const firstGuide = _QUEUE.find(item => item.exibindo && !item.finished);

    if (firstGuide && firstGuide.id !== guideOnDisplay?.id) {
      setGuideOnDisplay(firstGuide);
    }
  }, [queue]);

  // ------------------------------ RENDERIZAÇÃO ---------------------------------
  return (
    <>
      {guideOnDisplay && (
        <View
          open={!!guideOnDisplay}
          guide={guideOnDisplay?.guia}
          initialStep={guideOnDisplay?.step}
          handleClose={() => disableGuide(guideOnDisplay?.id)}
          callPage
          execute={execute}
          forcedClose={forcedClose}
          isLast={queue?.length === 1}
          evaluate={sendEvaluation}
        />
      )}
    </>
  );
};
