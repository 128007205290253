import { useState, useEffect } from 'react';
import { useTheme } from 'styled-components';

function useColor(name) {
  const theme = useTheme();
  const [color, setColor] = useState('');

  useEffect(() => {
    if (name && name.toLowerCase().trim() === 'primária') {
      setColor(theme.palette.semantics.feedback.information.natural);
    } else if (
      (name && name.toLowerCase().trim() === 'moderada') ||
      name.toLowerCase().trim() === 'analise' ||
      name.toLowerCase().trim() === 'aprovação pendente'
    ) {
      setColor(theme.palette.semantics.feedback.warning.natural);
    } else if (name && name.toLowerCase().trim() === 'grave') {
      setColor(theme.palette.semantics.feedback.attention.natural);
    } else if (
      name === 'BAIXA' ||
      name === 'AGUARDANDO' ||
      name.toLowerCase().trim() === 'preenchida' ||
      name.toLowerCase().trim() === 'iniciado'
    ) {
      setColor(theme.palette.semantics.feedback.information.natural);
    } else if (
      name === 'MÉDIA' ||
      name === 'ABERTO' ||
      name === 'ABERTO' ||
      name === 'FINALIZADO'
    ) {
      setColor(theme.palette.semantics.feedback.success.natural);
    } else if (
      name === 'DELETADO' ||
      name.toLowerCase().trim() === 'deletada'
    ) {
      setColor(theme.palette.semantics.feedback.unknown.natural);
    } else if (
      name.toLowerCase().trim() === 'assinada' ||
      name.toLowerCase().trim() === 'concluído'
    ) {
      setColor(theme.palette.semantics.feedback.success.natural);
    } else if (name.toLowerCase().trim() === 'arquivada') {
      setColor(theme.palette.words.subtitle.natural);
    } else if (name === 'Assinatura Pendente') {
      setColor(theme.palette.semantics.feedback.attention.natural);
    } else {
      setColor(theme.palette.semantics.feedback.attention.natural);
    }
  }, [name]);

  return color;
}

export default useColor;
