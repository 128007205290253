/* eslint-disable prettier/prettier */
// Styles
import colors from 'styles/colors';

// React
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

// Components
import Button from 'components/Buttons/Default';
import InfoCard from 'components/Cards/InfoCard';
import { DefaultTable } from 'components/_Table/templates/default';

// Components MUI
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import EditTurma from './Modal/EditTurma';
import ModalTransportadoras from './Transportadoras';
import * as S from './style.js';

// Utils
import { columnsView } from './columns';
import { getTurma, deleteTransportadoraTurma } from './services';
import { trackEvent } from 'utils/mixpanel';

const ViewTurma = () => {
  const user = useSelector(state => state.auth?.user?.user);

  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [openModalAddAluno, setOpenModalAddAluno] = useState(false);
  const [openModalEditAluno, setOpenModalEditAluno] = useState(false);

  const [foto, setFoto] = useState('');
  const [turmaName, setTurmaName] = useState('');
  const [infoGroup, setinfoGroup] = useState({});

  const [selectedRow, setSelectedRow] = useState('');
  const [data, setData] = useState([]);

  const fetchTurma = () =>
    getTurma(id).then(res => {
      setTurmaName(res.data.data.nome);
      setFoto(res.data.data.foto);
      setinfoGroup({
        id: res.data.data.id,
        nome: res.data.data.nome,
        foto: res.data.data.foto,
        id_relacao_turma: res.data.data.id_relacao_turma,
        status: res.data.data.ativa,
        para_motoristas: res.data.data.para_motoristas,
        para_usuarios: res.data.data.para_usuarios,
      });
      setData(res.data.data.transportadoras);
      setLoading(false);
    });

  useEffect(() => {
    setLoading(true);
    fetchTurma();
  }, []);

  const getAcron = str => {
    const matches = str.match(/\b(\w)/g);
    const acronym = matches.join('');
    return acronym.toUpperCase();
  };

  const bulkActions = [
    {
      title: 'Excluir',
      function: () => handleExcluir(),
    },
  ];

  const handleExcluir = async (id = null) => {
    const transportadorasToDelete = [];
    data.map(item => {
      if (id === null && selectedRow.includes(item.id)) {
        transportadorasToDelete.push(item.id);
      } else if (Number(id) === Number(item.id)) {
        transportadorasToDelete.push(item.id);
      }
    });

    const dataToDelte = {
      id_turma: infoGroup.id,
      transportadoras: transportadorasToDelete,
    };
    const res = await deleteTransportadoraTurma(dataToDelte);
    if (res.data.success) {
      toast.success(res.data.message);
      fetchTurma();
    } else {
      toast.error(res.data.message);
    }
  };
  const actions = [
    {
      function: id => handleExcluir(id),
      title: 'Excluir',
    },
  ];
  return (
    <S.Container>
      <EditTurma
        open={openModalEditAluno}
        dataEdit={infoGroup}
        handleClose={() => setOpenModalEditAluno(false)}
        fetchData={fetchTurma}
      />
      {/* TODO olhar fortemente tipoUser -> pegar id das transportadoras */}
      <ModalTransportadoras
        openModalAluno={openModalAddAluno}
        id_turma={id}
        tipoUser={
          infoGroup.para_motoristas === false &&
          infoGroup.para_usuarios === false
            ? {
                para_motoristas: 1,
                para_usuarios: 1,
                existentIds: data.map(item => item.id),
              }
            : {
                para_motoristas: infoGroup.para_motoristas ? 1 : 0,
                para_usuarios: infoGroup.para_usuarios ? 1 : 0,
                existentIds: data.map(item => item.id),
              }
        }
        behaviorCloseModal={() => setOpenModalAddAluno(false)}
        fetchData={fetchTurma}
      />
      <S.ButtonContianer>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignContent: 'center',
          }}
        >
          <Avatar src={foto} sx={{ marginRight: 1 }}>
            {turmaName.length > 0 && turmaName[0].toUpperCase()}
          </Avatar>
          <h1 className="titleTurma">{turmaName}</h1>
        </div>
        <div>
          <IconButton onClick={() => setOpenModalEditAluno(true)}>
            <SettingsOutlinedIcon />
          </IconButton>
          <Button
            style={{ marginLeft: 10 }}
            startIcon={<AddCircleOutlineIcon />}
            onClick={() => setOpenModalAddAluno(true)}
          >
            <span style={{ fontSize: 14 }}>Adicionar Transportadoras</span>
          </Button>
        </div>
      </S.ButtonContianer>
      <div style={{ marginTop: '20px' }}>
        <InfoCard message="Gerencie as transportadoras que fazem parte desta turma." />
      </div>
      <S.TableContainer>
        <DefaultTable
          data={data || []}
          columns={columnsView}
          searchKeys={['nome']}
          loading={loading}
          placeholder="Buscar Cliente"
          sortBy={{ id: 'id', order: 'DESC' }}
          bulk={bulkActions}
          actions={actions}
          setSelectedRows={setSelectedRow}
          searchEvent={search => trackEvent(user, 'Busca turma', null, search)}
          empty={{
            title: 'Buscar cliente',
            description: `Seus clientes aparecerão aqui".`,
          }}
        />
      </S.TableContainer>
    </S.Container>
  );
};

export default ViewTurma;
