/* eslint-disable import/order */

// Styles
import * as S from './styled';
import colors from 'styles/colors';

// React
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setConteudo } from 'store/modules/provider/capacitacaoConteudo/actions';

// Components
import { MediaView } from './MediaView';
import Loading from 'components/Loading';
import { EvaluationView } from './EvaluationView';
import ConfirmModal from 'components/ConfirmModal';
import DefaultButton from 'components/Buttons/Default';

// Material UI
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';

// Utils
import * as service from '../services';
import { options } from '../Avaliacao/constants';

// Capa default Header
const img =
  'https://firebasestorage.googleapis.com/v0/b/onisysweb.appspot.com/o/capacitacao%2Finfo%2Fcapas%2F1673467756358%2F2023-01-11_17-09-19_Capa_Conteudo_Capacitacao.jpg?alt=media&token=3ca2cd3b-f1c8-438a-a277-88580f7cbdbd';

export const Publicar = ({ trySave, finalMode, setTrySave, isEdit }) => {
  // navigate
  const navigate = useNavigate();

  // redux
  const dispatch = useDispatch();
  const content = useSelector(state => state.conteudoProvider);
  const geral = content?.geral;
  const midias = content?.midias;
  const avaliacao = content?.avaliacao;
  const is_quiz = avaliacao && avaliacao[0]?.is_quiz;
  const Icon = is_quiz ? options[0].Icon : options[1].Icon;

  // General States
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [description, setDescription] = useState('');
  const [saveModal, setSaveModal] = useState(false);

  useEffect(() => {
    if (trySave) {
      setSaveModal(true);
      if (finalMode === 'public') {
        setMessage('Tudo pronto para a sua viagem?');
        setDescription(
          'A partir de agora, você poderá acessar os relatórios e acompanhar de perto como tem sido a aderência do conteúdo.',
        );
      } else if (finalMode === 'draft') {
        setMessage('Tem certeza que deseja salvar como rascunho?');
        setDescription('O conteúdo não será disponibilizado para as empresas');
      }
    }
  }, [trySave]);

  const saveRequest = async () => {
    if (finalMode === 'draft') {
      dispatch(setConteudo());
      navigate('/capacitacao/conteudos', { replace: true });
    } else if (finalMode === 'public') {
      setLoading(true);
      const res = await service.PublishContent(content.id);
      if (res.success) {
        setTrySave(false);
        setSaveModal(false);
        toast.success(res.message);
        !isEdit &&
          navigate(`/capacitacao/editar/${content.id}?step=6`, {
            replace: true,
          });
      } else {
        toast.error(res.message);
      }
      setLoading(false);
    }
  };

  return (
    <>
      {!loading && (
        <>
          <S.ScrollArea>
            <S.Header background={geral?.capa || img}>
              <div className="gradient">
                <div className="title">{geral?.titulo}</div>
                <div className="description">{geral?.descricao}</div>
              </div>
            </S.Header>

            <S.Box>
              {midias?.map((item, index) => (
                <MediaView key={index} item={item} />
              ))}
              {avaliacao?.length > 0 && (
                <S.EvaluationBox>
                  <div className="headerBox">
                    <Icon htmlColor={colors.greenMain} />
                    <p className="typeEval">
                      {is_quiz ? 'Quiz' : 'Teste Online'}
                    </p>
                  </div>
                  {avaliacao?.map((item, index) => (
                    <EvaluationView key={index} item={item} />
                  ))}
                </S.EvaluationBox>
              )}
              <S.BoxButton>
                <DefaultButton
                  children="Finalizar Conteúdo"
                  style={{ width: '100%' }}
                  disabled
                />
              </S.BoxButton>
            </S.Box>
          </S.ScrollArea>
          <ConfirmModal
            open={saveModal}
            title={message}
            subtitle={description}
            titleIcon={
              <CheckBoxOutlinedIcon
                sx={{ color: colors.greenMain }}
                fontSize="medium"
              />
            }
            buttonText="Confirmar"
            onClick={() => saveRequest()}
            loading={loading}
            handleClose={() => {
              setSaveModal(false);
              setTrySave(false);
            }}
          />
        </>
      )}
      {loading && (
        <S.LoadingBox>
          <Loading />
        </S.LoadingBox>
      )}
    </>
  );
};
