import React from 'react';
import { useTheme } from 'styled-components';
import { Modal, IconButton, Icon } from '@mui/material';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import HeadsetMicOutlinedIcon from '@mui/icons-material/HeadsetMicOutlined';
import { toast } from 'react-toastify';
import * as S from './styled';

const UpgradeModal = ({ open, handleClose }) => {
  const theme = useTheme();
  const body = (
    <S.Paper>
      <S.Header>
        <S.Title>
          <StarBorderOutlinedIcon
            sx={{
              color: theme.palette.brand.secondary.natural,
              marginRight: '10px',
            }}
            fontSize="medium"
          />
          <h1>
            Você atingiu o limite de motoristas contratados. Para seguir com o
            cadastro de novos motoristas, favor entrar em contato com o time
            comercial
          </h1>
        </S.Title>

        <IconButton size="small" onClick={() => handleClose(false)}>
          <Icon sx={{ color: theme.palette.words.subtitle.natural }}>
            close
          </Icon>
        </IconButton>
      </S.Header>

      <S.Main>
        <S.StyledToast>
          <div
            className="box"
            onClick={() => {
              navigator.clipboard.writeText('+55 31 99210-8028');
              toast.success('Telefone Copiado');
            }}
          >
            <div className="svgArea">
              <HeadsetMicOutlinedIcon />
            </div>
            <div className="textArea">
              Fale com consultor
              <br />
              por telefone
            </div>
            <div className="clickArea">+55 31 99210-8028</div>
          </div>
          <div
            className="box"
            onClick={() => {
              navigator.clipboard.writeText('comercial@onisys.com.br');
              toast.success('Email Copiado');
            }}
          >
            <div className="svgArea">
              <HeadsetMicOutlinedIcon />
            </div>
            <div className="textArea">
              Envie um email
              <br />
              para nosso comercial
            </div>
            <a className="clickArea" href="mailto:upgrade@onisys.com.br">
              comercial@onisys.com.br
            </a>
          </div>
        </S.StyledToast>
      </S.Main>
    </S.Paper>
  );

  return (
    <div>
      <Modal open={open} onClose={() => handleClose(false)}>
        {body}
      </Modal>
    </div>
  );
};

export default UpgradeModal;
