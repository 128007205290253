import React from 'react';
import * as S from './styled';

const Bar = ({ quantities }) => {
  const total = Object.values(quantities).reduce(
    (sum, value) => sum + value,
    0,
  );

  const percentages = Object.entries(quantities).map(
    ([key, value], index, array) => {
      const isLeftmost = index === 0;
      const isRightmost = index === array.length - 1;
      return {
        key,
        percentage: total === 0 ? 0 : (value / total) * 100,
        color: getColor(key),
        isLeftmost,
        isRightmost,
      };
    },
  );

  function getColor(key) {
    switch (key) {
      case 'qtde_GRAVE':
        return '#F64E60';
      case 'qtde_GRAVISSIMA':
        return '#FFA801';
      case 'qtde_MODERADA':
        return '#0C12F2';
      case 'qtde_PRIMARIA':
        return '#1BC5BD';
      default:
        return '#000';
    }
  }
  return (
    <S.Container>
      {percentages.map(
        ({ key, percentage, color, isLeftmost, isRightmost }) => (
          <div
            key={key}
            className={`bar ${isLeftmost ? 'leftmost' : ''} ${
              isRightmost ? 'rightmost' : ''
            }`}
            style={{
              width: `${percentage}%`,
              backgroundColor: color,
            }}
          />
        ),
      )}
    </S.Container>
  );
};

export default Bar;
