import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { Skeleton } from '@mui/material';
import { AddCircleOutline } from '@mui/icons-material';
import DefaultButton from 'components/Buttons/Default';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import * as S from './styled';
import { Filters } from './components/Filters';
import { UserCard } from './components/UserCard';

import { objectGet } from 'utils/helpers';
import { requestUsersData } from './services';

export const Equipe = () => {
  const navigate = useNavigate();
  const [usuarios, setUsuarios] = useState([]);
  const [filters, setFilters] = useState({
    status: 'ATIVO',
    search: '',
    filial: '',
  });
  const [sortBy, setSortBy] = useState('ultima_tratativa');
  const [items, setItems] = useState(10);

  const { isFetching, data } = useQuery(
    ['minha-equipe'],
    () => requestUsersData(),
    {
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    if (data && data.length) {
      // Filter data
      const filteredData = data.filter(
        item =>
          item.status === filters.status &&
          (!filters.filial ||
            item.filiais.some(filial => filial.id === filters.filial)) &&
          (!filters.search ||
            item.nome.toLowerCase().includes(filters.search.toLowerCase())),
      );

      // Sort data
      const sortedData = [...filteredData].sort((a, b) => {
        if (sortBy === 'ultima_tratativa') {
          return new Date(b[sortBy]) - new Date(a[sortBy]);
        }
        if (
          sortBy === 'desvios.TOTAL' ||
          sortBy === 'tempo_medio' ||
          sortBy === 'nivel'
        ) {
          return b[sortBy] - a[sortBy];
        }
        return 0;
      });

      setUsuarios(sortedData);
      return;
    }
    setUsuarios([]);
  }, [data, filters, sortBy]);

  return (
    <>
      <S.Header>
        <h1>Minha equipe</h1>

        <DefaultButton
          startIcon={<AddCircleOutline />}
          size="medium"
          onClick={() => navigate('/configuracoes/usuarios/criar')}
        >
          Criar novo usuário
        </DefaultButton>
      </S.Header>

      <Filters
        filters={filters}
        sortBy={sortBy}
        setFilters={setFilters}
        setSortBy={setSortBy}
      />
      {isFetching ? (
        <>
          <Skeleton
            style={{ marginTop: '1rem' }}
            height={174}
            animation="wave"
            variant="rounded"
          />
          <Skeleton
            style={{ marginTop: '1rem' }}
            height={174}
            animation="wave"
            variant="rounded"
          />
        </>
      ) : (
        usuarios.map((usuario, k) => (
          <> {k < items && <UserCard key={usuario.id} data={usuario} />}</>
        ))
      )}

      {usuarios.length > items && (
        <S.SeeMore onClick={() => setItems(items + 10)}>
          <span className="textSeeMore">Ver Mais</span>
          <div className="divIcon">
            <KeyboardArrowDownIcon className="icon" />
          </div>
        </S.SeeMore>
      )}
    </>
  );
};
