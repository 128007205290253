/* eslint-disable import/order */
// Styles
import * as S from './styled';

// React
import { useState } from 'react';
import { useSelector } from 'react-redux';

// Components
import InfoCard from 'components/Cards/InfoCard';
import SelectInput from 'components/Inputs/Select';
import GhostButton from 'components/Buttons/Ghost';
import DefaultButton from 'components/Buttons/Default';
import SelectClient from 'components/Inputs/SelectCliente';

// Components MUI
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export const ModalCliente = ({ open, handleClose }) => {
  const navigate = useNavigate();
  const [cliente, setCliente] = useState('');

  // Mocked para exibir somente ipiranga e raizen caso exista na relação
  const clients = useSelector(state =>
    state.selects.clients.filter(i => (i?.id === 1 || i?.id === 4) && i.ativo),
  );

  const handleCreate = () => {
    if (!cliente) {
      toast.error('Selecione um cliente para criar um layout');
      return;
    }
    handleClose();
    navigate(`/layout/criar?client=${cliente}`);
  };

  return (
    <S.ModalMUI open={open} onClose={handleClose}>
      <S.Container>
        <S.Header>
          <div className="left">
            <AssignmentOutlinedIcon />
            <p className="title">Selecionar Cliente</p>
          </div>
          <div className="right">
            <CloseRoundedIcon onClick={handleClose} />
          </div>
        </S.Header>
        <S.Body>
          <SelectClient
            distributors={clients}
            value={cliente}
            setValue={setCliente}
            label="Selecione um cliente"
            required
          />
          <InfoCard message="O layout será criado com base nas informações do cliente selecionado." />
        </S.Body>
        <S.Footer>
          <GhostButton children="Cancelar" onClick={handleClose} />
          <DefaultButton children="Criar layout" onClick={handleCreate} />
        </S.Footer>
      </S.Container>
    </S.ModalMUI>
  );
};
