import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Rectangle,
} from 'recharts';
import { Tooltip as MuiTooltip } from '@mui/material';

const CustomBarShape = props => {
  const { fill, x, y, width, height } = props;

  return (
    <Rectangle
      x={x}
      y={y}
      width={width}
      height={height}
      radius={[2, 2, 2, 2]}
      fill={fill}
    />
  );
};

const CustomYAxisTick = ({ x, y, payload }) => {
  const { value } = payload;
  const displayValue =
    value.length > 14 ? `${value.substring(0, 14)}...` : value;
  return (
    <MuiTooltip title={value} placement="top">
      <text x={x} y={y} textAnchor="end" fill="#666" fontSize={14} dy={5}>
        {displayValue}
      </text>
    </MuiTooltip>
  );
};

const BarChartVertical = ({ data = [] }) => {
  let _data = [...data];
  if (_data.length > 5)
    _data = [
      ..._data.slice(0, 4),
      {
        id: 'outros',
        name: 'Outros',
        value: _data.slice(5).reduce((tot, curr) => (tot += curr.value), 0),
      },
    ];

  return (
    <ResponsiveContainer width="100%" height={192}>
      <BarChart
        data={_data}
        layout="vertical"
        margin={{ right: 30, top: 0, bottom: 20, left: 80 }}
      >
        <XAxis dataKey="value" type="number" hide />
        <YAxis
          dataKey="name"
          type="category"
          axisLine={false}
          tickLine={false}
          tick={CustomYAxisTick}
        />
        <Bar
          dataKey="value"
          fill="#95AAC9"
          barSize={20}
          shape={<CustomBarShape />}
          label={{ position: 'right' }}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarChartVertical;
