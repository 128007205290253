import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Tabs from 'components/Tabs';
import { Divider } from '@mui/material';
import * as S from './styled';

// Components
import ConfigDesvios from './ConfigDesvios';
import ConfigVelocidades from './ConfigVelocidades';
import ConfigCercaEletronica from './ConfigCercaEletronica';
import ConfigAcoesSuspensao from './ConfigAcoesSuspensao';

const TelemetriaConfiguracoes = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [tab, setTab] = useState('');
  const { user } = useSelector(state => state.auth.user);

  let pageTabs = [
    { value: 'desvios', label: 'Desvios' },
    { value: 'velocidades', label: 'Velocidades' },
    {
      value: 'cerca-eletronica',
      label: 'Cerca Eletrônica',
    },
  ];

  if (!user.provider) {
    pageTabs.push({
      value: 'acoes-suspensao',
      label: 'Ações e Suspensões',
    });
  }

  const handlePageTab = (event, newValue) => {
    navigate(`/telemetria/configuracoes/${newValue}`);
  };

  useEffect(() => {
    const pathname = location.pathname;
    if (pathname === '/telemetria/configuracoes') {
      navigate('/telemetria/configuracoes/desvios');
    } else {
      const tab = pathname.replace('/telemetria/configuracoes/', '');
      setTab(tab);
    }
  }, [location.pathname]);

  return (
    <S.Main>
      <S.TitleWrapper>
        <h1>Configurações</h1>
      </S.TitleWrapper>

      <Tabs value={tab} items={pageTabs} onChange={handlePageTab} />
      <Divider />

      {tab === 'desvios' && (
        <S.Container>
          <ConfigDesvios />
        </S.Container>
      )}
      {tab === 'velocidades' && (
        <S.Container>
          <ConfigVelocidades />
        </S.Container>
      )}
      {tab === 'cerca-eletronica' && (
        <S.Container>
          <ConfigCercaEletronica />
        </S.Container>
      )}
      {tab === 'acoes-suspensao' && (
        <S.Container>
          <ConfigAcoesSuspensao />
        </S.Container>
      )}
    </S.Main>
  );
};

export default TelemetriaConfiguracoes;
