export const SUSPENSAO_ID = 4;

export const getStatus = (status, isSuspensao = false) => {
  switch (status) {
    case 'BLOQUEADO':
      return 'SUSPENSO';
    case 'LIBERADO':
      return isSuspensao ? 'LIBERADO' : 'CONCLUÍDO';
    default:
      return status;
  }
};

export const columns = [
  {
    header: 'Empresa/Filial',
    id: 'nome',
    type: 'string',
    tooltip: true,
    sort: true,
    subRow: {
      prefix: 'Filial: ',
      value: () => 'Todas',
    },
  },
  {
    header: 'Ações Pendentes',
    id: 'acoes_pendentes',
    type: 'number',
    sort: false,
    align: 'center',
  },
  {
    header: 'Ações Concluídas',
    id: 'acoes_concluidas',
    type: 'number',
    sort: false,
    align: 'center',
  },
  {
    header: 'Motoristas Suspensos',
    id: 'motoristas_suspensos',
    type: 'number',
    sort: false,
    align: 'center',
  },
  {
    header: 'Motoristas Liberados (suspensão)',
    id: 'motoristas_liberados',
    type: 'number',
    sort: false,
    align: 'center',
  },
];

export const columnsTorrePlus = [
  {
    header: 'Id',
    id: 'id',
    type: 'number',
    sort: true,
    width: 100,
  },
  {
    header: 'Motorista/Tranps',
    id: 'nome_motorista',
    type: 'string',
    sort: true,
    subRow: {
      prefix: ' Transp.:',
      key: 'nome_empresa',
      type: 'string',
    },
  },
  {
    header: 'Faixa',
    id: 'faixa',
    type: 'string',
    sort: true,
    conditional: [
      {
        condition: _ => true,
        style: (theme, item) => ({
          color: item.cor_faixa,
          textTransform: 'uppercase',
        }),
      },
    ],
  },
  {
    header: 'Ação disciplinar',
    id: 'acao_disciplinar',
    type: 'string',
    attention: (_, item) => {
      if (item.is_obrigatorio)
        return {
          text: 'Suspensão/Capacitação Obrigatório',
        };
    },
  },
  {
    header: 'Pontuação',
    id: 'pontos',
    type: 'number',
    sort: true,
    align: 'center',
  },
  {
    header: 'Data Alcance de Faixa',
    id: 'data_inicio',
    type: 'date',
    sort: true,
    subRow: {
      value: (value, item) =>
        item.data_previsao &&
        getStatus(item.status, item.id_acao_disciplinar === SUSPENSAO_ID) ===
          'SUSPENSO'
          ? `Liberação: ${formatNewDate(item.data_previsao)}`
          : '',
    },
  },
  {
    header: 'Status',
    id: 'status',
    type: 'string',
    sort: true,
    width: 200,
    align: 'center',
    style: {
      textTransform: 'uppercase',
    },
    value: (value, item) =>
      getStatus(item.status, item.id_acao_disciplinar === SUSPENSAO_ID),
    conditional: [
      {
        condition: value =>
          ['LIBERADO', 'APROVADO', 'CONCLUÍDO'].includes(value),
        style: theme => ({
          color: theme.palette.semantics.feedback.success.natural,
          backgroundColor: theme.palette.semantics.feedback.success.light,
          textAlign: 'center',
        }),
      },
      {
        condition: value => value === 'PENDENTE',
        style: theme => ({
          color: theme.palette.semantics.feedback.warning.natural,
          backgroundColor: theme.palette.semantics.feedback.warning.light,
          textAlign: 'center',
        }),
      },
      {
        condition: value => value === 'REPROVADO',
        style: theme => ({
          color: theme.palette.semantics.feedback.warning.dark,
          backgroundColor: theme.palette.semantics.feedback.warning.light,
          textAlign: 'center',
        }),
      },
      {
        condition: value => ['BLOQUEADO', 'SUSPENSO'].includes(value),
        style: theme => ({
          color: theme.palette.semantics.feedback.attention.dark,
          backgroundColor: theme.palette.semantics.feedback.attention.light,
          textAlign: 'center',
        }),
      },
    ],
  },
];

export const fieldsEmpresas = [
  {
    label: 'Empresa',
    value: 'nome',
    selected: true,
    default: true,
  },
  {
    label: 'Ações Pendentes',
    value: 'acoes_pendentes',
    selected: true,
    default: true,
  },
  {
    label: 'Ações Concluídas',
    value: 'acoes_concluidas',
    selected: true,
    default: true,
  },
  {
    label: 'Motoristas Suspensos',
    value: 'motoristas_suspensos',
    selected: true,
    default: true,
  },
  {
    label: 'Motoristas Liberados (suspensão)',
    value: 'motoristas_liberados',
    selected: true,
    default: true,
  },
];
