export const fields = [
  {
    label: 'Ativo',
    value: 'ativo',
    selected: true,
    default: true,
  },
  {
    label: 'Título',
    value: 'titulo',
    selected: true,
    default: true,
  },
  {
    label: 'Pontuação inicial',
    value: 'limiar',
    selected: true,
    default: true,
  },
  {
    label: 'Pontuação final',
    value: 'valor_maximo',
    selected: true,
    default: true,
  },
  {
    label: 'Ação Disciplinar',
    value: 'acao.nome',
    selected: true,
    default: false,
  },
  {
    label: 'Capacitacao',
    value: 'capacitacao.titulo',
    selected: true,
    default: false,
  },
];

export const resetExcelFields = fields => {
  return fields.map(i => {
    if (!i.default) i.selected = true;
    return i;
  });
};
